import { CommunicationPreferences } from 'components/EventTemplateForm/CommunicationPreferences';
import React, { FC, useState } from 'react';
import {
  Alert, Button, Card, Col, Form, Row,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { IOptions } from 'components/Inputs/Select';
import { RootState } from 'types/rootState';
import { Select } from 'components/FormControls';
import { ControlledTimePicker } from 'components/FormControls/TimePicker';
import { selectGroups } from 'reducers/Groups';
import { IUserInfoState } from 'reducers/IUserInfoState';
import {
  EventTemplateMode, IEvent, ScheduleMode, TemplateAndEvent,
} from 'types/IEvent';
import CommunityNameWarningModal from 'pages/Templates/CommunityNameWarningModal';
import { selectEventsLoading, selectEventsValidating } from 'reducers/Events';
import { IAutomaticMessage } from './IAutomaticMessage';

type Props = {
  data: IAutomaticMessage
  saveEvent: (eventData: IEvent) => void
  isNew: boolean
}

export const AutomaticEventForm: FC<Props> = ({ data, saveEvent, isNew }) => {
  const history = useHistory();
  const userInfo = useSelector((state: RootState): IUserInfoState => state.UserInfo);
  const complianceLifeGroups = useSelector(selectGroups(false, '', [2]));
  const defaultInputLanguage = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.InputLanguageDefaultValue);
  const GROUP_OPTIONS: IOptions[] = [];
  const complianceStrings: string[] = ['30', '60', '90', 'Due Date']
  const DEFAULT_GROUP_NAMES: string[] = [
    'CD1 30',
    'CD1 60',
    'CD1 90',
    'CD1 Due Date',
    'CD2 30',
    'CD2 60',
    'CD2 90',
    'CD2 Due Date',
    'CD3 30',
    'CD3 60',
    'CD3 90',
    'CD3 Due Date',
    'CD4 30',
    'CD4 60',
    'CD4 90',
    'CD4 Due Date',
    'CD5 30',
    'CD5 60',
    'CD5 90',
    'CD5 Due Date',
    'CD6 30',
    'CD6 60',
    'CD6 90',
    'CD6 Due Date',
    'CD7 30',
    'CD7 60',
    'CD7 90',
    'CD7 Due Date',
    'CD8 30',
    'CD8 60',
    'CD8 90',
    'CD8 Due Date',
    'LD1',
    'LD2',
  ];

  const sourceLanguage = isNew ? defaultInputLanguage : data.SourceLanguage
  complianceLifeGroups.forEach((group) => {
    // exclude the unmapped ones from the list
    if (DEFAULT_GROUP_NAMES.indexOf(group.Name) === -1) {
      GROUP_OPTIONS.push({ value: group.Id.toString(), label: group.Name })
    }
  })
  GROUP_OPTIONS.sort((a, b) => a.label.localeCompare(b.label))
  const methods = useForm<TemplateAndEvent>({
    mode: 'onChange',
    defaultValues: {
      AutomaticEvent: data.GroupId,
      SendTime: data.SendTime,
      voiceSelected: data.voiceSelected,
      VoiceContent: data.VoiceContent,
      emailSelected: data.emailSelected,
      EmailContent: data.EmailContent,
      smsSelected: data.smsSelected,
      SMSContent: data.SMSContent,
      SourceLanguage: sourceLanguage,
    },
  });

  const {
    control,
    errors,
    getValues,
    handleSubmit,
    reset,
  } = methods;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<TemplateAndEvent>(null);
  const isSaving = useSelector(selectEventsLoading);
  const isValidating = useSelector(selectEventsValidating);

  const communityName = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.CommunityName);

  const getShowCommunityNameWarning = (formData: TemplateAndEvent) => {
    const voiceContentValue = formData.VoiceContent?.Content;
    const smsContentValue = formData.SMSContent?.Content
    const emailContentValue = formData.EmailContent?.Content
    const decodeEmailContentValue = emailContentValue ? atob(emailContentValue) : '';

    if (((voiceContentValue && voiceContentValue?.indexOf('$$CommunityName$$') !== -1)
      || (smsContentValue && smsContentValue?.indexOf('$$CommunityName$$') !== -1)
      || (decodeEmailContentValue && decodeEmailContentValue?.indexOf('$$CommunityName$$') !== -1))
      && (communityName === null || communityName === '')) {
      return true;
    }
    return false;
  };

  const onSubmit = (formData: TemplateAndEvent): void => {
    if (getShowCommunityNameWarning(formData)) {
      setTemplateData(formData)
      setShowModal(true)
    } else {
      onSubmitForm(formData);
    }
  };

  const onSubmitForm = (formData: TemplateAndEvent): void => {
    const selectedGroupName = complianceLifeGroups.filter(
      (g) => g.Id === Number(getValues('AutomaticEvent')),
    )[0].Name;
    const eventName = `${selectedGroupName}_event`
    const newFormData = {
      ...formData,
      Name: eventName,
      Category: 'Activity',
      GroupIds: [getValues('AutomaticEvent')],
      SendMode: ScheduleMode.Schedule,
      RRule: 'RRULE:FREQ=DAILY;INTERVAL=1',
      StartDate: moment().tz(userInfo.accountTimezone).format('YYYY-MM-DD'),
      EventType: complianceStrings.some((x) => selectedGroupName.includes(x)) ? 'compliance' : 'lifedate',
    }
    saveEvent(newFormData);
  };

  const removeVoiceContent = () => {
    const currentFormData = getValues();
    const dataNoVoiceContent = {
      ...currentFormData,
      VoiceContent: null,
      voiceSelected: false,
      smsSelected: currentFormData.SMSContent != null,
      emailSelected: currentFormData.EmailContent != null,
    };
    // todo: should probably be doing this with setvalue and not reset.
    reset(dataNoVoiceContent);
  };
  const removeSmsContent = () => {
    const currentFormData = getValues();
    const dataNoSmsContent = {
      ...currentFormData,
      SMSContent: null,
      voiceSelected: currentFormData.VoiceContent != null,
      smsSelected: false,
      emailSelected: currentFormData.EmailContent != null,
    };
    // todo: should probably be doing this with setvalue and not reset.
    reset(dataNoSmsContent);
  };
  const removeEmailContent = () => {
    const currentFormData = getValues();
    const dataNoEmailContent = {
      ...currentFormData,
      EmailContent: null,
      voiceSelected: currentFormData.VoiceContent != null,
      smsSelected: currentFormData.SMSContent != null,
      emailSelected: false,
    };
    // todo: should probably be doing this with setvalue and not reset.
    reset(dataNoEmailContent);
  }

  return (
    <div>
      {(errors?.voiceSelected || errors?.smsSelected || errors?.emailSelected) && (
        <Alert variant="danger">Please select at least one channel</Alert>
      )}

      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className=""
        >
          <Card>
            <Card.Header as="h4">Schedule new Automatic Event</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <p className="small">
                    Automatic Events are events where Icon automatically decides
                    who should receive the message you create at the time you specified.
                  </p>
                  <p className="small">
                    Examples of uses for Automatic Events are Birthday / Anniversary
                    greetings and license reminders. For example, for an Automatic Birthday
                    Event, Icon will review all community member’s birthdays
                    (information in community member’s Profile) and send a message to those
                    community members who have a birthday that day.
                  </p>
                </Col>
              </Row>
              <Row style={{ alignItems: 'flex-end' }}>
                <Col xs={4}>
                  <Select
                    style={{ marginBottom: 0 }}
                    id="AutomaticEvent"
                    name="AutomaticEvent"
                    label="Event"
                    placeholder="Select event type"
                    control={control}
                    options={GROUP_OPTIONS}
                    errors={errors.AutomaticEvent && 'group is required'}
                    rules={{
                      required: true,
                      validate: (val: any) => val > 0,
                    }}
                  />
                </Col>
                <Col xs={2} className="pl-5 pb-3">
                  <strong style={{ height: 45, lineHeight: '45px' }}>Daily send time: </strong>
                </Col>
                <Col className="event-template-preview pb-3">
                  <ControlledTimePicker
                    name="SendTime"
                    validate={(val: any) => val !== null}
                  />
                </Col>
                <Col style={{ display: 'flex', height: 62, alignItems: 'center' }} className="pb-3">
                  <Button className="d-block float-right ml-2" type="submit" disabled={isSaving || isValidating}>
                    Save
                  </Button>
                  <Button
                    className="float-right"
                    type="button"
                    variant="btn mr-2 btn-link"
                    onClick={() => history.push('/events/automatic')}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
          <Card className="overflow-visible">
            <Card.Body>
              <CommunicationPreferences
                isNew={isNew}
                removeVoice={removeVoiceContent}
                removeSms={removeSmsContent}
                removeEmail={removeEmailContent}
                eventTemplateMode={EventTemplateMode.Event}
              />
            </Card.Body>
          </Card>
        </Form>
      </FormProvider>
      <CommunityNameWarningModal
        showModal={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        onSave={() => {
          setShowModal(false);
          onSubmitForm(templateData);
        }}
        disableSavingButton={isSaving}
      />
    </div>
  );
}
