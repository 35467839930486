import React, { ReactElement, useCallback } from 'react';

import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { EventsTable } from './EventsTable';

const EventsIndexPage = ():ReactElement => {
  const history = useHistory();
  const callback = useCallback((id) => (
    <Link to={`/events/${id}/edit`}>Edit</Link>
  ), []);
  return (
    <div>
      <Helmet>
        <title>Broadcast Messaging Events</title>
      </Helmet>
      <div className="header-bar" />
      <EventsTable
        showActions
        rowActionItem={callback}
        filterTitle="Event"
        eventType="Announcements"
        onRowClick={(id) => history.push(`/events/${id}/edit`)}
      />
    </div>
  );
};
export default EventsIndexPage;
