import React, { ReactElement } from 'react';

import { Helmet } from 'react-helmet';

function LoggedOutPage(): ReactElement {
  return (
    <>
      <Helmet>
        <title>Logged Out</title>
      </Helmet>

      <div className="header-bar">
        <h3>For your security we have logged you out from Icon</h3>
      </div>
      <p>
        Please login again.

      </p>
    </>
  );
}

export default LoggedOutPage;
