import React from 'react';
import { ISurveyQuestion } from 'types/ISurveyQuestion';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { Select } from 'components/FormControls';
import { IOptions } from 'components/Inputs/Select';

type Props = {
  data: ISurveyQuestion
  surveyCallId: number
  isComplete: boolean
  telephonyStatus: string
  index: number
};

export const SurveyQuestionForm: React.FC<Props> = ({
  data, surveyCallId, isComplete, index, telephonyStatus,
}) => {
  const {
    register, watch, control,
  } = useFormContext();
  const callTypeWatch = watch('CallType');
  const getResponseOptions = (): IOptions[] => {
    if (data.ResponseType === 'Binary') {
      return [
        {
          label: '--',
          value: '',
        },
        {
          label: 'Yes',
          value: 'Yes',
        },
        {
          label: 'No',
          value: 'No',
        },
      ];
    }
    if (data.ResponseType === 'Rating') {
      return [
        {
          label: '--',
          value: '',
        },
        {
          label: '1',
          value: '1',
        },
        {
          label: '2',
          value: '2',
        },
        {
          label: '3',
          value: '3',
        },
        {
          label: '4',
          value: '4',
        },
        {
          label: '5',
          value: '5',
        },
      ];
    }
    return [{
      label: '--',
      value: '',
    }]
  }
  return (
    <>
      <Row>
        <Col xs={10}>
          {data.MessageContent === '' && (
            <strong>{data.Name}</strong>
          )}
          {data.MessageContent !== '' && (
            <strong>{data.MessageContent}</strong>
          )}
        </Col>

        <Col xs={2}>
          <div className="surveyAnswerCell">
            {(telephonyStatus === 'Complete') && (
              <label>
                {data.SurveyResponse}
                <input
                  name={`SurveyCallContent[${index}].SurveyResponse`}
                  type="hidden"
                  ref={register}
                  value={data.SurveyResponse == null ? '' : data.SurveyResponse}
                />
              </label>
            )}
            {(!isComplete && telephonyStatus === 'Incomplete') && (
              <Select
                name={`SurveyCallContent[${index}].SurveyResponse`}
                id={`SurveyCallContent[${index}].SurveyResponse${surveyCallId}`}
                style={{ height: '30px', width: '80px' }}
                control={control}
                options={getResponseOptions()}
                disabled={callTypeWatch === 'Auto'}
              />
            )}
          </div>
        </Col>
      </Row>
      <input
        name={`SurveyCallContent[${index}].SurveyCallId`}
        type="hidden"
        ref={register}
        value={surveyCallId}
      />
      <input
        name={`SurveyCallContent[${index}].ContentId`}
        type="hidden"
        ref={register}
        value={data.ContentId}
      />
    </>
  );
};
