import React from 'react';
import format from 'date-fns/format';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSWRConfig } from 'swr';

import { createEvent, stopEvent, deleteEvent } from 'reducers/Events';
import { EventTemplateMode, ScheduleMode, IEventExtendo } from 'types/IEvent';
import { EventTemplateForm } from 'components/EventTemplateForm/EventTemplateForm';
import { useEventQuery } from 'components/hooks/useEventQuery';
import { EventTransformer } from 'transformers/Event';

/**
 * @desc usage: when editing all type of events except for occurence, automatic and survey
 */
export default function EditEventPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { mutate } = useSWRConfig();
  const { id: eventId } = useParams<{ id: string, type:string }>();

  const [data, setData] = React.useState<IEventExtendo|null>(null);

  useEventQuery(+eventId, {
    onSuccess: (eventToEdit) => {
      if (!eventToEdit) {
        return;
      }

      setData({
        ...eventToEdit,
        StartDate: moment(eventToEdit.StartDate, 'yyyy-MM-DD').format('MM-DD-yyyy'),
      });
    },
  });

  React.useEffect(() => {
    if (!eventId) {
      history.push('/calendar');
    }
  }, [eventId, history]);

  const handleEditEvent = (formData: IEventExtendo) => {
    const onSuccess = async (receivedEvent) => {
      toast.success('Event successfully updated');
      history.push('/calendar');
      await Promise.all([
        mutate(
          ['event', +eventId],
          undefined,
          {
            revalidate: false,
            populateCache: true,
          },
        ),
        mutate(
          ['event', receivedEvent.ID],
          receivedEvent,
          {
            revalidate: false,
            populateCache: true,
          },
        ),
      ]);
    }
    // We're treating events as immutable -
    // so we will create a new event rather than edit the current one.
    const newEvent = {
      ...formData,
      ID: 0,
    };

    if (formData.SendMode !== ScheduleMode.Once) {
      dispatch(stopEvent(data, format(new Date(), 'yyyy-MM-dd')));
    } else {
      dispatch(deleteEvent(data.ID));
    }
    dispatch(
      createEvent(
        EventTransformer.toAPI(newEvent),
        onSuccess,
      ),
    );
  };

  const isLoading = !data;

  return (
    <div>
      {isLoading ? (
        <div
          className="loading-text d-flex align-items-center justify-content-center"
          role="status"
        >
          <Spinner
            animation="border"
            as="span"
            className="mr-2"
            style={{ width: '1.66em', height: '1.66em' }}
          />
          <span style={{ fontSize: '1.25em' }}>
            Loading...
          </span>
        </div>
      ) : (
        <EventTemplateForm
          eventTemplateMode={EventTemplateMode.Event}
          data={data}
          setData={setData}
          onSubmit={handleEditEvent}
          onCancel={() => {
            history.push('/calendar')
          }}
          enableGenerateSmartContent
        />
      )}
    </div>
  );
}
