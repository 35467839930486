import React, { useEffect, useState, ReactElement } from 'react';

import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { IProfile } from 'reducers/IProfile';
import { postProfileRelationships, profilesAddFamilySuccess } from 'reducers/Profiles';
import { useDispatch } from 'react-redux';
import { SearchInput, Select } from 'components/FormControls';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';
import _capitalize from 'lodash/capitalize';

interface IProps {
  show: boolean
  toggle: () => void
  familyProfiles: IProfile[]
  baseProfileId: string
}

interface IFamilySearch {
  searchField: string
  searchValue: string
}

const AssociationModal = ({
  show, toggle, familyProfiles, baseProfileId,
}: IProps): ReactElement | null => {
  const [familyProfileList, setFamilyProfileList] = useState<IProfile[]>([]);
  const [selectedFamily, setSelectedFamily] = useState<string[]>([]);
  const dispatch = useDispatch();

  const translateUserType = useUserTypeTranslation();
  // This capitalization is needed - display family user type alias in the title of searching profile textbox
  const formatUserType = (ut: string) => _capitalize(translateUserType(ut));

  // remove 'Members' text when there is no family alias feat/COMMS-604
  const familyTypeName = `Search ${formatUserType('family')} ${formatUserType('family') === 'Family' ? 'Members' : ''}`;

  useEffect(() => {
    setFamilyProfileList(familyProfiles)
  }, [familyProfiles]);

  const { handleSubmit, control } = useForm<IFamilySearch>();
  if (!show) return null;

  const onSearchSubmit = (data: IFamilySearch): void => {
    switch (data.searchField) {
      case 'LastName':
        setFamilyProfileList(familyProfiles.filter(
          (prof) => prof.LastName.toLowerCase().includes(data.searchValue.toLowerCase()),
        ));
        break;
      case 'FirstName':
        setFamilyProfileList(familyProfiles.filter(
          (prof) => prof.FirstName.toLowerCase().includes(data.searchValue.toLowerCase()),
        ));
        break;
      case 'FullName':
        setFamilyProfileList(familyProfiles.filter(
          (prof) => prof.FirstName.concat(' ', prof.LastName).toLowerCase()
            .includes(data.searchValue.toLowerCase()),
        ));
        break;
    }
  };

  const saveSelectedFamily = (): void => {
    dispatch(postProfileRelationships(baseProfileId, selectedFamily))
    dispatch(profilesAddFamilySuccess());
    setSelectedFamily([]);
    toggle();
  }

  return (
    <Modal className="AssociationModal" size="lg" centered show={show} onHide={toggle}>
      <Modal.Header closeButton />
      <Modal.Body className="modal-logo">
        <form id="form" onSubmit={handleSubmit(onSearchSubmit)}>
          <div className="input-group search-input">
            <Select
              id="searchField"
              control={control}
              label="Search By"
              name="searchField"
              defaultValue="FirstName"
              className="mr-0 mr-lg-2"
              options={[
                { label: 'Last Name', value: 'LastName' },
                { label: 'First Name', value: 'FirstName' },
                { label: 'Full Name', value: 'FullName' },
              ]}
            />
            <SearchInput
              id="searchValue"
              label={familyTypeName}
              placeholder="I am looking for..."
              name="searchValue"
              control={control}
            />
          </div>
        </form>

        <div className="family-members">
          {familyProfileList.map((prof) => (
            <div key={prof.CustomerProfileID} className="family-member">
              <label htmlFor={`${prof.CustomerProfileID}-check`}>
                <input
                  type="checkbox"
                  checked={selectedFamily.includes(prof.CustomerProfileID)}
                  onChange={(e) => {
                    if (selectedFamily.includes(prof.CustomerProfileID)) {
                      setSelectedFamily(selectedFamily.filter((pid) => pid !== prof.CustomerProfileID));
                    } else {
                      setSelectedFamily([...selectedFamily, prof.CustomerProfileID])
                    }
                  }}
                  id={`${prof.LastName}-check`}
                />
                {`${prof.FirstName} ${prof.LastName}`}
              </label>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => toggle()}>Close</button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => saveSelectedFamily()}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssociationModal;
