import ReactDOM from 'react-dom';
import { Button, Modal } from 'react-bootstrap';
import React from 'react';

const UserConfirmation = (message: string, callback:(any)=>any):void => {
  const textObj = JSON.parse(message);
  const container = document.createElement('div');
  container.setAttribute('custom-confirmation-navigation', '');
  document.body.appendChild(container);
  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(callbackState);
  };
  ReactDOM.render(
    <Modal show>
      <Modal.Header>
        <Modal.Title>{textObj.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{textObj.messageText}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={() => {
            closeModal(false)
          }}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          className="ml-2"
          onClick={() => {
            closeModal(true)
          }}
        >
          Abandon&nbsp;changes
        </Button>
      </Modal.Footer>
    </Modal>,
    container,
  );
};
export default UserConfirmation;
