import React, { ReactElement } from 'react';

import { Helmet } from 'react-helmet';

import Calendar from 'components/FullCalendar';

function CalendarIndexPage(): ReactElement {
  return (
    <div className="CalendarIndex">
      <Helmet>
        <title>Calendar</title>
      </Helmet>

      <Calendar />
    </div>
  );
}

export default CalendarIndexPage;
