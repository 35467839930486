import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import clsx from 'clsx';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

import { Label } from './Label';

const getIsOverflowed = (el: HTMLElement) => {
  if (el.childElementCount < 2) {
    return false;
  }
  // Using getBoundingClientRect, instead of scrollWidth and clientWidth, to get width with fractional accuracy
  const clientWidth = el.getBoundingClientRect().width

  el.setAttribute('style', 'overflow:visible;width:auto;');
  const contentWidth = el.getBoundingClientRect().width
  el.removeAttribute('style');

  const extraWidth = el.classList.contains('overflown') ? 35 : 0;

  return contentWidth > clientWidth + extraWidth;
}

export const LabelsCell = ({ cell: { value: labels = [] } }) => {
  const [isOverflowed, setIsOverflowed] = React.useState(false);
  const overflowRef = React.useRef<HTMLDivElement|null>(null);

  useUpdateEffect(() => {
    setIsOverflowed(getIsOverflowed(overflowRef.current));
  }, [labels]);

  const observer = React.useRef<ResizeObserver>();
  const containerRef = React.useCallback((node) => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new ResizeObserver(() => {
      setIsOverflowed(getIsOverflowed(overflowRef.current))
    })

    if (node) {
      observer.current.observe(node);
    }
  }, []);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content className="more-btn-popover">
        {labels?.map((label) => (
          <Label
            name={label.Name}
            key={label.EntityLabelId}
            showDelete={false}
            onDelete={() => {}}
            noTooltip
          />
        ))}
      </Popover.Content>
    </Popover>
  );

  return (
    <div
      className="labels"
      ref={containerRef}
    >
      <div
        className={clsx('overflow', isOverflowed && 'overflown')}
        ref={overflowRef}
      >
        {labels?.map((label) => (
          <Label
            name={label.Name}
            key={label.EntityLabelId}
            showDelete={false}
            onDelete={() => {}}
          />
        ))}
      </div>
      {isOverflowed && (
        <OverlayTrigger
          placement="top"
          flip
          overlay={popover}
        >
          <span className="more-btn">{labels.length}</span>
        </OverlayTrigger>
      )}
    </div>
  )
}
