import React, {
  useContext, ElementType, ReactNode, ReactElement,
} from 'react';

import Card from 'react-bootstrap/Card';
import AccordionContext from 'react-bootstrap/AccordionContext';
import clsx from 'clsx';

type IToggleProps = {
  as?: ElementType<any>
  eventKey: string
  className?: string
  setActiveKey: (key: string) => void
  children: ReactNode
};

const ToggleHeader = ({
  as,
  eventKey,
  setActiveKey,
  className,
  children,
}: IToggleProps): ReactElement => {
  const currentEventKey = useContext(AccordionContext);

  const isCurrentEventKey = currentEventKey === eventKey;
  const onClick = (): void => {
    if (isCurrentEventKey) {
      setActiveKey('-1');
    } else {
      setActiveKey(eventKey);
    }
  };

  return (
    <Card.Header
      as={as}
      className={clsx('cursor-pointer', className)}
      onClick={onClick}
    >
      {children}
    </Card.Header>
  );
};

export default ToggleHeader;
