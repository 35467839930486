import log from 'lib/logging';
import API from './API';

interface Input {
  name: string
  description: string
}

export const getSmartContent = async (
  channel: 'voice'|'sms'|'emailcontent'|'emailsubject',
  { name, description }: Input,
): Promise<ReadableStream<Uint8Array>> => {
  try {
    const response = await fetch(`/api/v2/smartcontent/${channel}/stream`, {
      body: JSON.stringify({
        name,
        description,
      }),
      headers: {
        ...API.defaults.headers.common,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.body;
  } catch (error) {
    log.error(error);
    throw new Error('Unable to retrieve smart content. Please try again later.');
  }
}
