import { IEvent, ITemplateExtendo } from 'types/IEvent';
import { ILibraryContent } from 'types/ILibraryContent';

export enum TrackingTypeEnum {
  ForgotPassword = 'Forgot Password',
  CreateEvent='Create Event',
  CalendarFilter = 'Calendar Filter',
  CalendarContextMenu = 'Calendar Context Menu',
  CreateGroup='Create Group',
  CreateSubGroup='Create Sub Group',
  DeleteGroup ='Delete Group',
  EditGroup = 'Edit Group',
  UpdateLibrary='Update Library',
  CreateLibrary ='Create Library',
  EditProfile='Edit Profile',
  CreateProfile = 'Create Profile',
  DeleteProfile='Delete Profile',
  ProfileSearch='Profile Search',
  UpdateTemplate='Update Template',
  CreateTemplate = 'Create Template',
  HelpLink ='Help Link',
  DischargedPatientCreate = 'Discharged Patient Create',
  DischargedPatientEdit = 'Discharged Patient Edit',
  DischargedPatientAddCall = 'Discharged Patient Add Call',
  DischargedPatientEditCall = 'Discharged Patient Edit Call',
  DischargedPatientReviewCall = 'Discharged Patient Review Call',
  APIErrors = 'API Call Error',
}

export interface ITrackProp{
  userName?:string
  accountId?:string
}
export interface ICalendarFilterTrackProp extends ITrackProp{
  startDate: string
  endDate: string
  profiles: number
  groups: number
}
export interface ICalendarContextMenuTrackProp extends ITrackProp{
  menuType: string
}
export interface IProfileTrackProp extends ITrackProp{
  profileId: string
}
export interface IGroupTrackProp extends ITrackProp{
  groupName: string
}

export const trackActions = {
  forgotPassword: () => <const>{
    key: TrackingTypeEnum.ForgotPassword,
    data: {},
  },
  createEvent: (data: IEvent) => <const>{
    key: TrackingTypeEnum.CreateEvent,
    data: {
      email: data.emailSelected,
      voice: data.voiceSelected,
      sms: data.smsSelected,
      profiles: data.ProfileIds?.length,
      groups: data.GroupIds?.length,
      scheduleType: data.SendMode,
      frequency: data.RRule,
    },
  },
  calendarFilter: (data: ICalendarFilterTrackProp) => <const>{
    key: TrackingTypeEnum.CalendarFilter,
    data,
  },
  calendarContextMenu: (data: ICalendarContextMenuTrackProp) => <const>{
    key: TrackingTypeEnum.CalendarContextMenu,
    data,
  },
  createGroup: () => <const>{
    key: TrackingTypeEnum.CreateGroup,
    data: {},
  },
  createSubGroup: () => <const>{
    key: TrackingTypeEnum.CreateSubGroup,
    data: {},
  },
  deleteGroup: () => <const>{
    key: TrackingTypeEnum.DeleteGroup,
    data: {},
  },
  editGroup: () => <const>{
    key: TrackingTypeEnum.EditGroup,
    data: {},
  },
  updateLibrary: (data: ILibraryContent) => <const>{
    key: TrackingTypeEnum.UpdateLibrary,
    data: { type: data.Type },
  },
  createLibrary: (data: ILibraryContent) => <const>{
    key: TrackingTypeEnum.CreateLibrary,
    data: { type: data.Type },
  },
  editProfile: () => <const>{
    key: TrackingTypeEnum.EditProfile,
    data: {},
  },
  createProfile: () => <const>{
    key: TrackingTypeEnum.CreateProfile,
    data: {},
  },
  deleteProfile: () => <const>{
    key: TrackingTypeEnum.DeleteProfile,
    data: {},
  },
  profileSearch: () => <const>{
    key: TrackingTypeEnum.ProfileSearch,
    data: {},
  },
  updateTemplate: (data: ITemplateExtendo) => <const>{
    key: TrackingTypeEnum.UpdateTemplate,
    data: {
      email: data.emailSelected,
      voice: data.voiceSelected,
      sms: data.smsSelected,
      profiles: data.ProfileIds?.length,
      groups: data.GroupIds?.length,
    },
  },
  createTemplate: (data: ITemplateExtendo) => <const>{
    key: TrackingTypeEnum.CreateTemplate,
    data: {
      email: data.emailSelected,
      voice: data.voiceSelected,
      sms: data.smsSelected,
      profiles: data.ProfileIds?.length,
      groups: data.GroupIds?.length,
    },
  },
  helpLink: () => <const>{
    key: TrackingTypeEnum.HelpLink,
    data: {},
  },
  // TODO: update the mapping if need
  createDischargedPatient: () => <const>{
    key: TrackingTypeEnum.DischargedPatientCreate,
    data: {},
  },
  editDischargedPatient: () => <const>{
    key: TrackingTypeEnum.DischargedPatientEdit,
    data: {},
  },
  addDischargedPatientCall: () => <const>{
    key: TrackingTypeEnum.DischargedPatientAddCall,
    data: {},
  },
  editDischargedPatientCall: () => <const>{
    key: TrackingTypeEnum.DischargedPatientEditCall,
    data: {},
  },
  apiErrors: (data: any) => <const>{
    key: TrackingTypeEnum.APIErrors,
    data: {
      url: data.url,
      params: data.params,
      message: data.message,
    },
  },
};

export type ActionType<
  TActions extends { [key: string]: (...args: any) => any }
> = ReturnType<TActions[keyof TActions]>;
export type TrackAction = ActionType<typeof trackActions>;

export const segmentAnalyticsTrack = ({ key, data }: TrackAction) => {
  window.analytics.track(key, data);
};
