import log from 'lib/logging';

export async function getFileBlobFromUrl(url: string): Promise<Blob> {
  return fetch(url).then((file) => file.blob());
}

export function getFileBlobFromB64Data(b64Data: string, contentType: string = null, sliceSize = 512): Blob {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  if (contentType) {
    return new Blob(byteArrays, { type: contentType });
  }
  return new Blob(byteArrays);
}

export async function getB64DataFromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    try {
      if (file) {
        reader.readAsDataURL(file);
      }
    } catch (e) {
      log.info(e);
    }
    reader.onload = () => {
      let encoded = reader.result?.toString().replace(/^data:(.*,)?/, '');
      if (encoded && encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}

export async function getB64StringFromFile(file: File): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    try {
      if (file) {
        reader.readAsDataURL(file);
      }
    } catch (e) {
      log.info(e);
    }
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}
