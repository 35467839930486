import React from 'react';
import { Switch, Route } from 'react-router-dom';

import TemplatesCreatePage from 'pages/Templates/New';
import TemplatesIndexPage from 'pages/Templates/Index';
import TemplatesEditPage from 'pages/Templates/Edit';
import TemplatesClonePage from 'pages/Templates/Clone';

function TemplateRoutes() {
  return (
    <Switch>
      <Route path="/templates/new" component={TemplatesCreatePage} />
      <Route path="/templates/clone" component={TemplatesClonePage} />
      <Route path="/templates/blueprint" component={TemplatesClonePage} />
      <Route path="/templates/:templateId/edit/:type" component={TemplatesEditPage} />
      <Route path="/templates" component={TemplatesIndexPage} />
    </Switch>
  );
}

export default TemplateRoutes;
