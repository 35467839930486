import React, {
  FC, useState, useMemo, useEffect,
} from 'react'

import { useFormContext } from 'react-hook-form';
import _last from 'lodash/last';
import _times from 'lodash/times';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import { TransitionActionEnum } from 'types/IDischargedPatient';
import { selectDischargedPatientConfig } from 'reducers/DischargedPatients';
import { Select } from 'components/FormControls';

const Transitions: FC<{ states: string[], readMode: boolean }> = ({ states, readMode }) => {
  const {
    control, errors, getValues,
  } = useFormContext();
  const config = useSelector(selectDischargedPatientConfig);
  const [rows, setRows] = useState<number[]>([0, 1]);

  useEffect(() => {
    if (!config) {
      return;
    }
    setRows(_times(Object.keys(config.ConfigurationData?.StatusConfiguration?.Transitions).length));
  }, [config, readMode]);

  const transitionOptions = useMemo(() => [
    '',
    '*',
    ...states,
  ].map((item) => ({
    label: item,
    value: item,
  })), [states]);

  const actionOptions = useMemo(() => [
    {
      label: '',
      value: '',
    },
    ...Object.values(TransitionActionEnum)
      .map((item: string) => ({
        label: item,
        value: item,
      })),
  ], []);

  return (
    <div>
      <h6 className="h6">
        Transitions
      </h6>
      {rows.map((key, index) => (
        <Row className="align-items-center" key={key}>
          <Col>
            <Row>
              <Col className="pr-0" xs={4} md={12} xl={4}>
                <Select
                  id={`Transitions.${key}.TransitionFrom`}
                  name={`Transitions.${key}.TransitionFrom`}
                  options={transitionOptions}
                  label="Transition From"
                  control={control}
                  defaultValue=""
                  rules={{
                    validate: (val: string) => {
                      const values = getValues();
                      if (!val && (values.Transitions?.[key]?.TransitionTo || values.Forbidden?.[key]?.Action)) {
                        return 'Transition From is required';
                      }

                      if (!val && values.Transitions
                        .filter((item) => item.TransitionTo && item.TransitionFrom && item.Action)
                        .length < 2
                      ) {
                        return 'Transition From is required';
                      }

                      if (val !== '' && val !== '*' && values.Transitions?.[key]?.TransitionTo === val) {
                        return 'Transition From can not be the same as Transition To'
                      }

                      return true;
                    },
                  }}
                  errors={errors.Transitions?.[key]?.TransitionFrom?.message}
                  readOnly={readMode}
                />
              </Col>
              <Col className="pr-0" xs={4} md={12} xl={4}>
                <Select
                  id={`Transitions.${key}.TransitionTo`}
                  name={`Transitions.${key}.TransitionTo`}
                  options={transitionOptions}
                  label="Transition To"
                  control={control}
                  defaultValue=""
                  rules={{
                    validate: (val: string) => {
                      const values = getValues();
                      if (!val && (values.Transitions?.[key]?.TransitionFrom || values.Transitions?.[key]?.Action)) {
                        return 'Transition To is required';
                      }

                      if (!val && values.Transitions
                        .filter((item) => item.TransitionTo && item.TransitionFrom && item.Action)
                        .length < 2
                      ) {
                        return 'Transition From is required';
                      }

                      if (val !== '' && val !== '*' && values.Transitions?.[key]?.TransitionFrom === val) {
                        return 'Transition To can not be the same as Transition From'
                      }

                      return true;
                    },
                  }}
                  errors={errors.Transitions?.[key]?.TransitionTo?.message}
                  readOnly={readMode}
                />
              </Col>
              <Col className="pr-0" xs={4} md={12} xl={4}>
                <Select
                  id={`Transitions.${key}.Action`}
                  name={`Transitions.${key}.Action`}
                  options={actionOptions}
                  label="Action"
                  control={control}
                  defaultValue=""
                  rules={{
                    validate: (val: string) => {
                      const values = getValues();
                      if (!val
                        && (values.Transitions?.[key]?.TransitionFrom || values.Transitions?.[key]?.TransitionTo)
                      ) {
                        return 'Action is required';
                      }

                      if (!val && values.Transitions
                        .filter((item) => item.TransitionTo && item.TransitionFrom && item.Action)
                        .length < 2
                      ) {
                        return 'Action is required';
                      }

                      return true;
                    },
                  }}
                  errors={errors.Transitions?.[key]?.Action?.message}
                  readOnly={readMode}
                />
              </Col>
            </Row>
          </Col>
          <Col className="flex-grow-0" style={{ flexBasis: '45.75px', marginTop: '12px' }}>
            <FontAwesomeIcon
              icon="trash-alt"
              style={{
                cursor: (rows.length === 2 || readMode) ? 'default' : 'pointer',
                opacity: (rows.length === 2 || readMode) ? 0.5 : 1,
                fontSize: '18px',
              }}
              color={(rows.length === 2 || readMode) ? 'gray' : '#FF7052'}
              onClick={() => {
                if (rows.length === 2 || readMode) {
                  return;
                }
                setRows([
                  ...rows.slice(0, index),
                  ...rows.slice(index + 1),
                ]);
              }}
            />
          </Col>
        </Row>
      ))}
      <div className="d-flex justify-content-end">
        <FontAwesomeIcon
          icon={faPlusSquare}
          className="mb-3"
          color="#0F4CDB"
          size="2x"
          style={{
            cursor: readMode ? 'default' : 'pointer', fontSize: 24,
          }}
          onClick={() => {
            if (readMode) {
              return;
            }
            setRows([...rows, _last(rows) + 1]);
          }}
        />
      </div>
    </div>
  )
}

export default Transitions;
