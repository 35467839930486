import React, {
  FC, ReactElement, useMemo,
} from 'react';
import { BulkActions } from 'components/BulkActions';
import Table from 'components/Table';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectAccountTz } from 'reducers/UserInfo';
import { CellProps } from 'react-table';
import useSWR from 'swr';
import {
  deleteTriggeredEventById, getEbfAccountEventsConfig, getTriggeredEvents,
} from 'api/BroadcastFormAPI';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { EbfAccountEventsConfig } from 'types/BroadcastForm';

type EbfGeneratorTriggerEventsTableProps = {
  generatorId: number
  showActions?: boolean
  filterTitle?:string
};

const formatDateSentCell = (acctTz: string) => (props: CellProps<Record<string, unknown>>) => {
  const { cell: { value } } = props;
  if (!value) return 'Not Processed';

  // Split the value and construct a date without time zone adjustments
  const [year, month, day] = value.split('-').map(Number);
  const localDate = new Date(year, month - 1, day); // month is 0-indexed in JS

  return format(localDate, 'MMM d, yyyy');
}

const formatLocalTime = () => (props: CellProps<Record<string, unknown>>) => {
  const { cell: { value } } = props;

  try {
    const [hours, minutes] = value.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  } catch (ex) {
    return 'Invalid';
  }
}

const formatTriggerField = (eventConfig: EbfAccountEventsConfig) => (props: CellProps<Record<string, unknown>>) => {
  const { cell: { value } } = props;
  return eventConfig?.ConfiguredEventDates?.[value as string] ?? value;
}

export const useGeneratorTriggeredEvents = (generatorId: number) => useSWR(
  ['EbfGeneratorTriggeredEvents', generatorId],
  async () => {
    const response = await getTriggeredEvents(generatorId, {
      ignoreGlobalCatch: true,
    });
    return response;
  },
  {
    shouldRetryOnError: false,
  },
)

export const useEbfAccountEventsConfig = () => useSWR(
  ['EbfAccountEventsConfig'],
  async () => {
    const response = await getEbfAccountEventsConfig({
      ignoreGlobalCatch: true,
    });
    return response;
  },
  {
    shouldRetryOnError: false,
    dedupingInterval: 60000, // consider fresh for 1 minute
    revalidateOnFocus: false, // Do not revalidate when the window gets focus
    revalidateOnReconnect: false, // Do not revalidate when reconnecting
  },
)

export const EbfGeneratorTriggeredEventsTable: FC<EbfGeneratorTriggerEventsTableProps> = ({
  generatorId,
  showActions,
  filterTitle,
}) => {
  const { data, isLoading, mutate } = useGeneratorTriggeredEvents(generatorId);

  const accountTz = useSelector(selectAccountTz);
  const history = useHistory();

  const handleDelete = async (OneTimeEventId: number) => {
    try {
      await deleteTriggeredEventById(OneTimeEventId);
      toast.success('Automatic Event deleted');
      mutate();
    } catch (ex) {
      const errMsg = ex?.response?.data.Message || 'Uknown error: Failed to delete the automatic event';
      toast.error(errMsg);
    }
  };

  const { data: eventsConfig, isLoading: eventsConfigLoading } = useEbfAccountEventsConfig();
  const hasWarnings = (eventsConfig?.WarningMessages?.length ?? 0) > 0;
  const warningMessages = useMemo(() => eventsConfig?.WarningMessages?.join(', '), [eventsConfig?.WarningMessages]);

  const columns = useMemo(() => [
    {
      Header: 'Local Send Time',
      accessor: 'SendTimeLocal',
      Cell: formatLocalTime(),
      sortable: false,
      showToolTip: true,
    },
    {
      Header: 'Message', accessor: 'Message', sortable: false, showToolTip: true,
    },
    {
      Header: 'Event Type',
      accessor: 'TriggerField',
      Cell: formatTriggerField(eventsConfig),
      sortable: false,
      showToolTip: true,
    },
    {
      Header: 'Day Offset', accessor: 'TriggerDayOffset', sortable: false, showToolTip: true,
    },
    {
      Header: 'Last Processed',
      accessor: 'LastSentDate',
      Cell: formatDateSentCell(accountTz),
      sortable: false,
      showToolTip: true,
    },
  ].filter(Boolean), [eventsConfig, accountTz]);

  const items = useMemo(() => data ?? [], [data]);

  const rowBulkActionItems = (entityId: number): ReactElement => {
    const actionItems = [];
    actionItems.push({
      label: 'Edit',
      handler: () => {
        history.push(`/internal/ebfgenerators/${generatorId}/automaticEvent/${entityId}`);
      },
    });
    actionItems.push({
      label: 'Delete',
      handler: () => handleDelete(entityId),
      className: 'delete-action',
    });
    return (
      <BulkActions
        className="ml-3"
        items={actionItems}
      />
    );
  };

  return (
    <div>
      {hasWarnings ? <p className="mt-4 text-danger">{warningMessages}</p> : null}
      <Table
        columns={columns}
        enableCheck={false}
        data={items}
        selectButton={undefined}
        rowIDAccessor="TriggeredEventId"
        isLoading={isLoading || eventsConfigLoading}
        rowActionItems={showActions ? rowBulkActionItems : undefined}
        className="Template-Table"
      />
    </div>
  );
};
