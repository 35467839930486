import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDetail, selectDefaultEmailFields } from 'reducers/UserDetail';
import { Row } from 'react-bootstrap';

import { ChannelSelectedCheckbox } from 'components/EventTemplateForm/CommunicationPreferences';
import { LanguageSelect } from 'components/EventTemplateForm/LanguageSelect';
import { EmailContent } from 'components/EventTemplateForm/EmailContent';
import { TemplateOrEvent } from 'types/IEvent';

interface MarketingFieldsProps {
  data?: TemplateOrEvent
  isNew: boolean
}

export const MarketingFields: React.FC<MarketingFieldsProps> = ({ data, isNew }) => {
  const dispatch = useDispatch();
  const { setValue } = useFormContext();

  React.useEffect(() => {
    if (isNew) {
      dispatch(fetchUserDetail())
    }
  }, [dispatch, isNew]);

  const defaultEmailFields = useSelector(selectDefaultEmailFields);

  React.useEffect(() => {
    if (isNew && defaultEmailFields && !data?.EmailContent) {
      const { ReplyToEmailAddress, SenderEmailAddress, SenderDisplayName } = defaultEmailFields;
      setValue('EmailContent.Display', SenderDisplayName, { shouldDirty: false });
      setValue('EmailContent.ReplyTo', ReplyToEmailAddress, { shouldDirty: false });
      setValue('EmailContent.From', SenderEmailAddress, { shouldDirty: false });
    }
  }, [defaultEmailFields, isNew, setValue, data]);

  return (
    <>
      <ChannelSelectedCheckbox
        channelName="Email"
        checkName="emailSelected"
        checked
        onToggleCheck={() => {}}
      />

      <Row className="align-items-end">
        <LanguageSelect />
      </Row>

      <EmailContent
        emailContent={data?.EmailContent}
        isMarketing
      />
    </>
  )
}
