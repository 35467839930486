import { Switch, Route } from 'react-router-dom';
import React from 'react';
import DischargedPatient from 'pages/Administration/DischargedPatient/DischargedPatient';

function AdministrationRoutes() {
  return (
    <Switch>
      <Route path="/discharged-patient" component={DischargedPatient} />
    </Switch>
  );
}

export default AdministrationRoutes;
