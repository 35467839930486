import React, { Dispatch, SetStateAction, ReactElement } from 'react';
import preval from 'preval.macro';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import { useHasFeatures } from 'hooks/useHasFeatures';
import { selectUserRoles } from 'reducers/UserInfo';
import { ReactComponent as Logo } from 'styles/images/goicon-logo.svg';
import { BroadcastModal } from 'components/BroadcastModal/BroadcastModal';
import PlusCircle from 'styles/images/plus-circle.png';
import VerticalNav from './VerticalNav';
import MainNavItems from '../lib/MainNavItems';
import AccountDropDownSelector from './TopBar/AccountDropDownSelector';
import UserMenu from './TopBar/UserMenu';

interface IProps {
  open: boolean
  openMenuHandler: Dispatch<SetStateAction<boolean>>
}

function SideBar({ open, openMenuHandler }: IProps): ReactElement {
  const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`;
  const roles = useSelector(selectUserRoles);
  const [showBroadcastModal, setShowBroadcastModal] = React.useState(false);
  const { hasFeature: hasQuickBroadcastFeature } = useHasFeatures('quick-broadcast');

  return (
    <div className={`SideBar light-text ${open ? 'open' : ''}`}>
      <button
        type="button"
        className="close"
        onClick={() => openMenuHandler(!open)}
      >
        <FontAwesomeIcon icon="times" />
      </button>

      <Link className="logo" to="/">
        <Logo />
      </Link>

      {hasQuickBroadcastFeature && (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-md mb-4"
            id="broadcast-btn"
            onClick={() => {
              setShowBroadcastModal(true);
            }}
          >
            <img src={PlusCircle} alt="plus" />
            New Broadcast
          </button>
          <BroadcastModal
            onHide={() => setShowBroadcastModal(false)}
            show={showBroadcastModal}
          />
        </>
      )}

      <VerticalNav navItems={MainNavItems} openMenuHandler={openMenuHandler} />

      <AccountDropDownSelector />
      <UserMenu />
      {roles.indexOf('Super') > -1 && (
        <div className="build-info">
          <span>
            <div>
              <a
                title={process.env.REACT_APP_COMMIT_HASH}
                href={`https://github.com/VoiceFriend/Frontend/commit/${process.env.REACT_APP_COMMIT_HASH}`}
              >
                Build
              </a>
            </div>
            <div>
              Built at:
              {' '}
              {dateTimeStamp}
            </div>
          </span>
        </div>
      )}
      <div className="bottom">
        <Link to="/">Help</Link>
        &nbsp;
        <span className="pipe">|</span>
        &nbsp;
        <a
          href="https://goicon.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        <span className="label">
          &copy;
          {new Date().getFullYear()}
          {' '}
          VoiceFriend LLC. All rights reserved.
        </span>
      </div>
    </div>
  );
}

export default SideBar;
