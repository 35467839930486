import useSWR, { SWRConfiguration, Fetcher } from 'swr';

import { IEvent } from 'types/IEvent';
import * as EventsApi from 'api/EventsAPI';

export const useEventQuery = (
  eventId: number|undefined,
  {
    enabled = !!eventId,
    ...options
  }: SWRConfiguration<IEvent, any, Fetcher<IEvent>> & { enabled?: boolean } = {},
) => useSWR<IEvent>(
  enabled
    ? ['event', eventId]
    : null,
  ([, id]) => EventsApi.getEventById(id),
  options,
)
