import React, { FC, useState, useMemo } from 'react';
import {
  Modal, Row, Col, Form,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { SurveyContentResponseTypeEnum } from 'types/IDischargedPatient';

import {
  ISurveyQuestionListItem, SurveyQuestionListItemContentTypeEnum,
} from 'types/ISurveyQuestionList';
import {
  createSurveyQuestionListItem, selectItemCreateLoading, getSurveyQuestionListItemIndex, selectQuestions,
} from 'reducers/SurveyQuestionList';
import { Select, Textarea } from 'components/FormControls';
import { getAllLibraryContent } from 'reducers/LibraryContent';
import { ILibraryContent, LibraryContentType } from 'types/ILibraryContent';
import SubmitButton from 'components/FormControls/SubmitButton';

export interface ICreateItemModalProps {
  onHide(): void
  show: boolean
}

type IFormData = Omit<ISurveyQuestionListItem, 'ID' | 'Ordinal'>

export const CreateItemModal: FC<ICreateItemModalProps> = ({ show, onHide }) => {
  const [showTextarea, setShowTextarea] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { id: listId } = useParams<{ id: string }>();

  const createLoading = useSelector(selectItemCreateLoading);

  const questions: ISurveyQuestionListItem[] = useSelector(selectQuestions);
  const libraryData: ILibraryContent[] = useSelector(getAllLibraryContent);

  const handleSave = (formData) => {
    const data = {
      ...formData,
      ContentType: SurveyQuestionListItemContentTypeEnum.Question,
      ContentId: formData.ContentId ? +formData.ContentId : null,
      Ordinal: questions.length + 1,
    }

    dispatch(createSurveyQuestionListItem(+listId, [data], () => {
      dispatch(getSurveyQuestionListItemIndex(+listId));
      toast.success('Question successfully created');
      onHide();
    }));
  }

  const {
    control, errors, handleSubmit,
  } = useForm<IFormData>({
    mode: 'onBlur',
  });

  const surveyResponseTypeOptions = [
    { label: 'Binary', value: SurveyContentResponseTypeEnum.Binary },
    { label: 'Rating', value: SurveyContentResponseTypeEnum.Rating },
  ]

  const libraryContentOptions = useMemo(() => {
    if (!libraryData.length) {
      return [];
    }

    return libraryData
      .filter((item) => item.Type === LibraryContentType.VOICE)
      .map((item) => {
        const content = item.VoiceContent?.Content ?? '';

        return {
          label: `${item.Name} - ${content}`,
          value: item.ID,
        }
      });
  }, [libraryData]);

  return (
    <Modal
      show={show}
      keyboard={false}
      onHide={() => {}}
    >
      <Modal.Header>
        <Modal.Title className="w-100">
          New Question
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <hr />
        <Form onSubmit={handleSubmit(handleSave)}>
          <Row>
            <Col>
              <label className="mb-0">
                Survey Response Type
              </label>
              <Select
                id="ResponseType"
                name="ResponseType"
                control={control}
                options={surveyResponseTypeOptions}
                defaultValue={SurveyContentResponseTypeEnum.Binary}
                className="mt-1"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="mb-0">
                Response Pattern
              </label>
              <Textarea
                control={control}
                id="SurveyContentResponsePattern"
                name="SurveyContentResponsePattern"
                defaultValue=""
                rules={{ required: 'SurveyContentResponsePattern is required' }}
                errors={errors.SurveyContentResponsePattern?.message}
                rows={3}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="mb-0">
                SMS Content
              </label>
              <Textarea
                control={control}
                id="SMSContent"
                name="SMSContent"
                defaultValue=""
                rules={{ required: 'SMS Content is required' }}
                errors={errors.SMSContent?.message}
                placeholder="Type SMS content"
                rows={3}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <label className="mb-0">
                Voice Content
              </label>
              <div>
                <button
                  type="button"
                  className={clsx('tab-control', showTextarea && 'active')}
                  onClick={() => {
                    setShowTextarea(true);
                  }}
                >
                  Custom
                </button>
                &nbsp;
                {' | '}
                &nbsp;
                <button
                  type="button"
                  className={clsx('tab-control', !showTextarea && 'active')}
                  onClick={() => {
                    setShowTextarea(false)
                  }}
                >
                  Library Content
                </button>
              </div>
              {showTextarea ? (
                <Textarea
                  id="VoiceContent"
                  control={control}
                  name="VoiceContent"
                  defaultValue=""
                  rules={{ required: 'Voice Content is required' }}
                  errors={errors.VoiceContent?.message}
                  placeholder="Type Voice content"
                  rows={3}
                />
              ) : (
                <Select
                  id="ContentId"
                  name="ContentId"
                  placeholder="Select library content"
                  control={control}
                  options={libraryContentOptions}
                  errors={errors.ContentId?.message}
                  rules={{ required: 'Voice Content is required' }}
                  className="mt-1"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <SubmitButton
                label="Save"
                savingLabel="Saving..."
                className="btn btn-primary right"
                saveComplete={!createLoading}
              />
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={onHide}
              >
                Cancel
              </button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
