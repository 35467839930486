import React, {
  forwardRef, RefObject, useEffect, useState,
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Row } from 'react-bootstrap';
import { format, parse } from 'date-fns';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DatePicker from 'react-datepicker';

import { CommunicationTypeEnum } from 'types/IDischargedPatient';
import { IOptions } from 'components/Inputs/Select';
import { updateDischargedPatient } from 'reducers/DischargedPatients';
import { IPatientFormData } from 'types/IPatientFormData';
import { Input, Select } from 'components/FormControls';
import { formatPhoneNumberString } from 'lib/formatters/phoneNumber';

interface IProps{
  data: IPatientFormData
}

export function DischargedPatientViewAndEdit({
  data,
}:IProps):React.ReactElement {
  const [showForm, setShowForm] = useState(false);
  const methods = useForm<IPatientFormData>({ mode: 'onBlur' });
  const {
    reset, handleSubmit, control, errors, register,
  } = methods;
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);
  const onSubmitClick = (formData: IPatientFormData): void => {
    dispatch(updateDischargedPatient(formData.dischargedPatient, (dcp) => {
      const conflictedKeys = Object.keys(formData.dischargedPatient).filter((item) => ([
        'AttendingPhysician',
        'DischargeDate',
        'MRN',
        'Facility',
        'DischargeTo',
        'AdmittingDiagnosis',
        'AdditionalPhoneNumber',
        'Notes',
      ].includes(item) && formData.dischargedPatient[item] !== dcp[item] ? item : null));
      const stringData = conflictedKeys.reduce(
        (aggregator, currentValue) => `${aggregator}\n${currentValue}: ${formData.dischargedPatient[currentValue]}`,
        '',
      );
      conflictedKeys.length && toast.error(
        (
          <>
            Another team member has updated this patient.
            <CopyToClipboard
              text={stringData}
            >
              <Button style={{ fontSize: '.75rem' }}>Click to copy your work to the clipboard</Button>
            </CopyToClipboard>
            Click edit to re-enter your work.
          </>
        ),
      )
    }));
    setShowForm(false);
  };

  const phoneRegEx = /^\(?[2-9][0-9]{2}\)?\s?[2-9][0-9]{2}-?[0-9]{4}$/;
  const communicationTypeOptions: IOptions[] = [
    { label: 'Voice', value: CommunicationTypeEnum.Voice },
    { label: 'SMS', value: CommunicationTypeEnum.SMS },
  ];

  const ref2 = React.createRef<HTMLInputElement>();
  const StyledDatePickerInput = forwardRef(({ onClick, value, onChange }: any, ref: RefObject<HTMLInputElement>) => (

    <input
      ref={ref}
      onClick={onClick}
      className="form-control"
      onChange={onChange}
      value={value}
      type="text"
      id="datepicker"
    />
  ));
  return (
    <>
      <div
        className="section-header col-md-12 d-flex justify-content-between align-items-center"
      >
        <h6>
          Discharge Information
        </h6>
        {!showForm && (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              setShowForm(true);
            }}
          >
            Edit
          </button>
        )}
      </div>
      {showForm
            && (
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(onSubmitClick)}
                  className="form ProfilesForm col-12 pb-2"
                >
                  <input type="hidden" name="Version" ref={register} />
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <div
                        className="Input form-group floating-label active"
                      >
                        <label htmlFor="datepicker">
                          Discharge Date
                        </label>
                        <Controller
                          control={control}
                          name="dischargedPatient.DischargeDate"
                          defaultValue={format(new Date(), 'yyyy-MM-dd')}
                          errors={errors?.dischargedPatient?.DischargeDate && 'Discharge Date is required'}
                          rules={{ required: true }}
                          render={({ onChange, onBlur, value }) => (
                            <DatePicker
                              className="datePicker"
                              onBlur={onBlur}
                              customInput={<StyledDatePickerInput ref={ref2} />}
                              selected={
                                value
                                  ? parse(value, 'yyyy-MM-dd', new Date())
                                  : new Date()
                              }
                              onChange={(date: Date) => {
                                onChange(format(date, 'yyyy-MM-dd'));
                              }}
                              isClearable={false}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <Input
                        id="dischargedPatient.DischargeTo"
                        name="dischargedPatient.DischargeTo"
                        label="Discharge To"
                        type="text"
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <Input
                        id="dischargedPatient.Facility"
                        name="dischargedPatient.Facility"
                        label="Facility"
                        type="text"
                        control={control}
                      />
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <Input
                        id="dischargedPatient.MRN"
                        name="dischargedPatient.MRN"
                        label="MRN"
                        type="text"
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <Input
                        id="dischargedPatient.AttendingPhysician"
                        name="dischargedPatient.AttendingPhysician"
                        label="Attending Physician"
                        type="text"
                        control={control}
                      />
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <Input
                        id="dischargedPatient.AdmittingDiagnosis"
                        name="dischargedPatient.AdmittingDiagnosis"
                        label="Admitting Diagnosis"
                        type="text"
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="row">

                    <div className="col-xs-12 col-md-6">
                      <Input
                        id="dischargedPatient.AdditionalPhoneNumber"
                        name="dischargedPatient.AdditionalPhoneNumber"
                        label="Additional Phone Number"
                        type="text"
                        control={control}
                        mask="(999) 999-9999"
                        errors={errors?.dischargedPatient?.AdditionalPhoneNumber && 'Invalid Phone Number'}
                        rules={{
                          required: false,
                          validate: (val: any) => {
                            if (val === null || val === undefined || val === '') {
                              return true;
                            }
                            return phoneRegEx.test(val);
                          },
                        }}
                      />
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <Select
                        id="dischargedPatient.CommunicationType"
                        name="dischargedPatient.CommunicationType"
                        label="Communication"
                        defaultValue={data?.dischargedPatient?.CommunicationType}
                        control={control}
                        options={communicationTypeOptions}
                        errors={errors?.dischargedPatient?.CommunicationType?.message}
                        rules={{ required: 'CommunicationType is required' }}
                      />
                    </div>
                  </div>
                  <Input
                    id="dischargedPatient.DischargePatientId"
                    name="dischargedPatient.DischargePatientId"
                    label=""
                    type="hidden"
                    control={control}
                  />
                  <Input
                    id="dischargedPatient.ProfileId"
                    name="dischargedPatient.ProfileId"
                    label=""
                    type="hidden"
                    control={control}
                  />
                  <Input
                    id="dischargedPatient.ContactProfileId"
                    name="dischargedPatient.ContactProfileId"
                    label=""
                    type="hidden"
                    control={control}
                  />
                  <Input
                    id="dischargedPatient.NextCallDate"
                    name="dischargedPatient.NextCallDate"
                    label=""
                    type="hidden"
                    control={control}
                  />
                  <Input
                    id="dischargedPatient.LastCallDate"
                    name="dischargedPatient.LastCallDate"
                    label=""
                    type="hidden"
                    control={control}
                  />
                  <Button type="submit">Save</Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      reset(data);
                      setShowForm(false)
                    }}
                  >
                    Cancel

                  </Button>
                </form>
              </FormProvider>
            )}
      {!showForm && (
        <>
          <Row>
            <div className="col-xs-12 col-md-6">
              <strong>Discharge Date:</strong>
              {' '}
              {data?.dischargedPatient?.DischargeDate}
            </div>
            <div className="col-xs-12 col-md-6">
              <strong>Discharged To:</strong>
              {' '}
              {data?.dischargedPatient?.DischargeTo}
            </div>
          </Row>
          <Row>
            <div className="col-xs-12 col-md-6">
              <strong>Facility:</strong>
              {' '}
              {data?.dischargedPatient?.Facility}
            </div>
            <div className="col-xs-12 col-md-6">
              <strong>MRN:</strong>
              {' '}
              {data?.dischargedPatient?.MRN}
            </div>
          </Row>
          <Row>
            <div className="col-xs-12 col-md-6">
              <strong>Attending Physician:</strong>
              {' '}
              {data?.dischargedPatient?.AttendingPhysician}
            </div>
            <div className="col-xs-12 col-md-6">
              <strong>Admitting Diagnosis:</strong>
              {' '}
              {data?.dischargedPatient?.AdmittingDiagnosis}
            </div>
          </Row>
          <Row>
            <div className="col-xs-12 col-md-6">
              <strong>Additional Phone Number:</strong>
              {' '}
              {formatPhoneNumberString((data?.dischargedPatient?.AdditionalPhoneNumber))}
            </div>
            <div className="col-xs-12 col-md-6">
              <strong>Communication Type:</strong>
              {' '}
              {data?.dischargedPatient?.CommunicationType?.charAt(0).toUpperCase()}
              {data?.dischargedPatient?.CommunicationType?.slice(1)}
            </div>
          </Row>
        </>
      )}
    </>
  );
}
