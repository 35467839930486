import React, { ReactElement } from 'react';

import { CellProps } from 'react-table';

export function formatPhoneNumberString(phoneNumber: string | undefined): string {
  let formatted = '';
  if (!phoneNumber) return formatted;

  const cleaned = phoneNumber.replace(/\D/g, '');
  const grouped = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (grouped) {
    formatted = `(${grouped[1]}) ${grouped[2]}-${grouped[3]}`;
  }

  return formatted;
}

export function formatPhoneNumberCell(props: CellProps<Record<string, unknown>>): ReactElement {
  const { cell: { value } } = props;
  return (
    <>{formatPhoneNumberString(value)}</>
  );
}
