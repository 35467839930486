import React from 'react';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

export const useCentering = (
  targetRef: React.RefObject<HTMLElement|null>,
  containerRef: React.RefObject<HTMLElement|null>,
  deps: any[],
) => {
  // centers loader
  const handleCentering = React.useCallback(() => {
    if (!targetRef.current) {
      return;
    }

    const leftPosition = `${containerRef.current.offsetWidth / 2 + containerRef.current.scrollLeft}px`;
    // eslint-disable-next-line
    targetRef.current.style.left = leftPosition;
  }, [targetRef, containerRef]);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(handleCentering);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    }
  }, [containerRef, handleCentering]);

  useUpdateEffect(
    handleCentering,
    deps,
  );

  const containerProps = { onScroll: handleCentering };
  return containerProps;
}
