import React, {
  useMemo, useEffect, useState, ReactElement,
} from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'types/rootState';
import { getGroupsIndex, groupsSetSelectedId } from 'reducers/Groups';
import { getAllProfiles } from 'reducers/Profiles';
import { IGroup } from 'types/IGroup';
import { SearchInput } from 'components/Inputs/SearchInput';
import { ReactComponent as Plus } from 'styles/images/plus.svg';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';
import GroupMembers from './components/GroupMembers';
import GroupsList from './components/GroupsList';
import AddGroupActionButton from './components/AddGroupActionButton';

const groupSort = (a: IGroup, b: IGroup) => {
  if (a.Name.toLowerCase() < b.Name.toLowerCase()) { return -1; }
  if (a.Name.toLowerCase() > b.Name.toLowerCase()) { return 1; }
  return 0;
}
const typeSort = (a: IGroup, b: IGroup) => {
  if (a.GroupType === 1) { return -1; }
  if (a.GroupType === 0) { return 1; }
  return 0;
}

const masterSort = (a: IGroup, b: IGroup) => {
  if (a.GroupType === 1) {
    if (a.Name.toLowerCase() < b.Name.toLowerCase()) { return -1; }
    if (a.Name.toLowerCase() > b.Name.toLowerCase()) { return 0; }
  }
  if (a.GroupType === 0) { return 1; }
  return 0;
}

function GroupMainPage(): ReactElement {
  const dispatch = useDispatch();
  const groupsLookup = useSelector((state: RootState) => state.Groups.byIds);
  const groupData = useMemo(
    // groupType - 0: normal groups, 1: master groups, 2: compliance and event groups
    () => Object.values(groupsLookup).filter((g) => g.GroupType !== 2), [groupsLookup],
  );
  const allGroupData = useMemo(() => Object.values(groupsLookup), [groupsLookup]);
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [allGroups, setAllGroups] = useState<IGroup[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [groupSearchValue, setGroupSearchValue] = useState('');
  const [showAddGroupModal, setShowAddGroupModal] = useState<boolean>(false);
  const profilesLoading = useSelector((state: RootState) => state.Profiles.loading)
  const groupsLoading = useSelector((state: RootState) => state.Groups.loading)
  const itemsLoading = profilesLoading || groupsLoading;
  const translateUserType = useUserTypeTranslation();

  useEffect(() => {
    dispatch(getGroupsIndex());
    dispatch(getAllProfiles());
  }, [dispatch]);

  const onGroupSelect = (groupId: string) => {
    if (groupId !== '0') {
      dispatch(groupsSetSelectedId(parseInt(groupId, 10)));
    }
    if (editMode) {
      setEditMode(false);
    }
  }

  const groupIsMatch = (value, group) => value === ''
    || translateUserType(group?.Name).toLowerCase().includes(value.toLowerCase());

  useEffect(() => {
    const initialGroups = groupData?.sort(groupSort).filter((group) => group.ParentGroupID === 0
      && groupIsMatch(groupSearchValue, group));

    const aliasesGroups = initialGroups.map((g) => ({ ...g, Name: translateUserType(g.Name) }));

    const sortedGroups = aliasesGroups.sort(typeSort).sort(masterSort); // sort by group type and then sort master groups by alphabetical
    setGroups(sortedGroups);
  }, [groupData, groupSearchValue]);

  useEffect(() => {
    const sortedGroups = allGroupData?.sort(groupSort).sort(typeSort);
    setAllGroups(sortedGroups);
  }, [allGroupData]);

  return (
    <Container>
      <Row>
        <Col>
          <div className="pb-4">
            <Button
              type="button"
              variant="warning"
              className="btn-lg"
              onClick={() => {
                setShowAddGroupModal(true)
              }}
            >
              <Plus
                width="24"
                height="24"
                fill="currentColor"
                style={{ marginRight: '10px' }}
              />
              Add Group
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={5}>
          <Container>
            <Row>
              <Col>

                <div>
                  <div className="d-flex align-items-end mb-2" style={{ maxWidth: '100%' }}>
                    <SearchInput
                      label="Search Groups by Name"
                      placeholder="I am looking for..."
                      name="searchValue"
                      className="mb-0"
                      value={groupSearchValue}
                      onChange={(e) => {
                        setGroupSearchValue(e.target.value);
                      }}
                      onKeyDown={(evt) => {
                        if (evt.key === 'Enter') {
                          evt.preventDefault();
                        }
                      }}
                      style={{ maxWidth: '100%' }}
                    />
                  </div>

                  <div className="index-filter__item-count mb-2">
                    {itemsLoading && (<div className="itemCount loading-text">Loading...</div>)}
                    {!itemsLoading && (
                      <div className="itemCount">
                        {groups.length}
                        {' '}
                        Group(s) In Total
                      </div>
                    )}
                  </div>
                </div>

                <div className="listGroupMain">
                  <table className="table table-striped" style={{ marginBottom: '0rem' }}>
                    <thead>
                      <tr>
                        <th style={{ paddingLeft: '2.5rem' }}>
                          <b>Group Name</b>
                        </th>
                        <th>
                          <b>Actions</b>
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <GroupsList
                    groups={groups}
                    setEditMode={setEditMode}
                    onGroupSelect={onGroupSelect}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col xs={7}>
          <GroupMembers groups={allGroups} editMode={editMode} setEditMode={setEditMode} />
        </Col>
      </Row>

      <AddGroupActionButton
        showAddGroupModal={showAddGroupModal}
        setShowAddGroupModal={setShowAddGroupModal}
        setEditMode={setEditMode}
      />
    </Container>
  )
}

export default GroupMainPage;
