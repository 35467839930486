/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useCallback, useEffect, useState, FC, useMemo, useRef, ReactElement,
} from 'react';
import useSWRInfinite from 'swr/infinite';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card, Row, Col, ListGroup, Accordion, Form,
} from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import _chunk from 'lodash/chunk';
import _flatten from 'lodash/flatten';
import _times from 'lodash/times';
import _uniqBy from 'lodash/uniqBy';
import log from 'lib/logging';
import {
  getManyGroupsMembers,
  getGroupsIndex,
  getGroupByIds,
  selectGroupsDelegate,
} from 'reducers/Groups';
import _groupBy from 'lodash/groupBy';
import * as GroupsAPI from 'api/GroupsAPI';
import { IProfilesPayload } from 'types/IProfilesPayload';
import { RootState } from 'types/rootState';
import { IProfile } from 'reducers/IProfile';
import { SearchField } from 'types/IProfilesIndexParams';
import { ProfileSearchFilter } from 'components/ProfileSearchFilter';
import ToggleHeader from 'components/ToggleHeader';
import Api from 'api/API';
import * as ProfilesAPI from 'api/ProfilesAPI';
import { GroupSearchFilter } from 'components/GroupSearchFilter';
import { ICaremergeCategory, IGroup } from 'types/IGroup';
import { useRefSelector } from 'hooks/useRefSelector';
import { useIsSyncedCustomer } from 'hooks/useIsSyncedCustomer';
import useSWR from 'swr/immutable';
import { useUpdateEffect } from 'react-use';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';

const GroupCard: FC<{ validate: boolean, filterGroupType?: number[] }> = ({ validate, filterGroupType }) => {
  const { trigger, control } = useFormContext();
  const translateUserType = useUserTypeTranslation();
  const [searchQuery, setSearchQuery] = useState<undefined|string>(undefined);
  const allGroups = useSelector(selectGroupsDelegate(false,
    (groupName, query) => translateUserType(groupName).toLowerCase().includes(query),
    searchQuery,
    filterGroupType));
  const [activeKey, setActiveKey] = useState<string>('0');
  const [categoryActiveKey, setCategoryActiveKey] = useState<string>('0');
  const allprofilesbyids = useSelector((state: RootState) => state.Profiles.byIds)
  const allgroupmembersbyids = useSelector((state: RootState) => state.Groups.membersByIds)
  const profilesLoading = useSelector((state: RootState) => state.Profiles.loading)
  // Order groups by name
  const parentGroups = allGroups.filter((x) => x.ParentGroupID === 0);
  parentGroups.sort((a, b) => ((a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 1));
  const childGroups = allGroups.filter((x) => x.ParentGroupID !== 0);
  childGroups.sort((a, b) => ((a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 1));
  const groups = [];
  // For each parent group, get and add subgroups
  parentGroups.forEach((g) => {
    groups.push(g);
    const children = childGroups.filter((x) => x.ParentGroupID === g.Id);
    children.forEach((c) => {
      groups.push(c);
    });
  });

  const getRowColor = (index: number): string => {
    if (index % 2 === 1) {
      return 'oddGroupItem';
    }
    return 'evenGroupItem';
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGroupsIndex());
  }, [dispatch]);

  const expandGroup = (groupId: any): void => {
    if (allgroupmembersbyids[groupId]?.CustomerProfileIds === undefined) {
      dispatch(getManyGroupsMembers([groupId]));
    }
    if (activeKey !== groupId.toString()) {
      setActiveKey(groupId.toString());
    } else {
      setActiveKey('0');
    }
  }
  const expandCategory = (categoryId: any): void => {
    if (categoryActiveKey !== categoryId.toString()) {
      setCategoryActiveKey(categoryId.toString());
    } else if (categoryActiveKey === categoryId.toString()) {
      setCategoryActiveKey('');
    } else {
      setCategoryActiveKey('0');
    }
  }
  const onGroupClick = (value, onChange, groupId): void => {
    if (value.includes(groupId)) {
      onChange(value.filter((gid) => gid !== groupId));
    } else {
      onChange([...value, groupId]);
      if (allgroupmembersbyids[groupId]?.CustomerProfileIds === undefined) {
        dispatch(getManyGroupsMembers([groupId]));
      }
    }
    trigger('ProfileIds');
  }
  const isSyncedCustomer = useIsSyncedCustomer();
  const { data: caremergeCategories } = useSWR(
    isSyncedCustomer ? 'allCaremergeCategories' : null,
    GroupsAPI.getAllCaremergeCategories,
  );
  const programLevelCategorizedGroups: [ICaremergeCategory|null, IGroup[]][] = useMemo(() => {
    const programCategories = groups.filter((g) => g.ProgramLevelId > 0);
    if (!groups || !programCategories) {
      return [];
    }

    const groupsByProgramId = _groupBy(
      programCategories,
      (group) => (group.ProgramLevelId > 0),
    );
    return Object.entries(groupsByProgramId)
      .reduce((acc, [programId, programGroups]) => [[
        { CaremergeCategoryId: -1, CategoryName: 'Program Level' },
        programGroups,
      ], ...acc], [])
  }, [groups]);

  const serviceLevelCategorizedGroups: [ICaremergeCategory|null, IGroup[]][] = useMemo(() => {
    const serviceCategories = groups.filter((g) => g.ServiceLevelId > 0);
    if (!groups || !serviceCategories) {
      return [];
    }

    const groupsByServiceId = _groupBy(
      serviceCategories,
      (group) => (group.ServiceLevelId > 0),
    );
    return Object.entries(groupsByServiceId)
      .reduce((acc, [serviceId, serviceGroups]) => [[
        { CaremergeCategoryId: -2, CategoryName: 'Service Level' },
        serviceGroups,
      ], ...acc], [])
  }, [groups]);

  const categorizedGroups: [ICaremergeCategory|null, IGroup[]][] = useMemo(() => {
    if (!groups || !caremergeCategories) {
      return [];
    }

    const groupsByCategoryId = _groupBy(
      groups,
      (group) => (!group.CaremergeCategoryId ? 0 : group.CaremergeCategoryId),
    );
    const mainCaremergeCategories = Object.entries(groupsByCategoryId)
      .reduce((acc, [categoryId, categoryGroups]) => {
        let ccFindResult: ICaremergeCategory = caremergeCategories.find((cat) => `${cat.CaremergeCategoryId}` === categoryId);
        if (categoryId === '0') {
          const normalGroup = categoryGroups.filter((cg) => (cg.ServiceLevelId ?? 0) === 0 && (cg.ProgramLevelId ?? 0) === 0);
          return [...acc, [null, normalGroup]];
        }
        if (!ccFindResult && categoryId !== '0') {
          ccFindResult = {
            CaremergeCategoryId: +categoryId,
            CategoryName: 'Unknown',
          }
        }
        return [[
          ccFindResult,
          categoryGroups,
        ], ...acc]
      }, [])

    return [...serviceLevelCategorizedGroups, ...programLevelCategorizedGroups, ...mainCaremergeCategories];
  }, [caremergeCategories, programLevelCategorizedGroups, serviceLevelCategorizedGroups, groups]);
  const getCaremergeCategories = (caremergeCategoryId): ICaremergeCategory => {
    let tmpCategories: ICaremergeCategory;
    categorizedGroups.map(([cat, categoryGroups], catIndex) => {
      const catId = cat?.CaremergeCategoryId.toString() ?? '0';
      if (catId === caremergeCategoryId) {
        const tmpGroupIds = [];
        categoryGroups?.map((g, index) => {
          tmpGroupIds.push(g.Id)
        });
        tmpCategories = {
          CaremergeCategoryId: caremergeCategoryId,
          CategoryName: cat?.CategoryName,
          GroupIds: tmpGroupIds,
        }
      }
    });
    return tmpCategories;
  }

  const onCategoryClick = (value, onChange, caremergeCategoryId): void => {
    const categories = getCaremergeCategories(caremergeCategoryId);
    const categoryGroupIds = categories?.GroupIds;
    let isAllInclude = false;
    if (categories?.GroupIds !== null) {
      isAllInclude = categoryGroupIds.every((v) => value.includes(v));
      if (isAllInclude) {
        onChange(value.filter((gid) => !categoryGroupIds.includes(gid)));
      } else {
        const filteredValue = value.filter((gid) => !categoryGroupIds.includes(gid));
        onChange([...filteredValue, ...categoryGroupIds]);
      }
    }
    trigger('ProfileIds');
  }
  const getIsCategorySelected = (value, categoryId):boolean => {
    const categories = getCaremergeCategories(categoryId);
    const groupIds = categories?.GroupIds;
    if (groupIds && groupIds.length > 0) {
      for (const groupId of groupIds) {
        if (!value.includes(groupId)) return false;
      }
    } else { return false; }

    return true;
  }
  const renderGroupsOrCategories = ({ onChange, onBlur, value }): ReactElement | undefined => {
    if (isSyncedCustomer) {
      return (
        <ListGroup className="group-recipients">
          <Accordion
            activeKey={categoryActiveKey}
            onSelect={(e: any) => setCategoryActiveKey(e)}
          >
            {categorizedGroups.map(([category, categoryGroups], catIndex) => {
              const isCategoryEmpty = !categoryGroups?.length;
              const categoryId = category?.CaremergeCategoryId.toString() ?? '0';
              return (
                <Card className="rounded-0" key={categoryId}>
                  <ToggleHeader
                    className={getRowColor(catIndex)}
                    eventKey={categoryId}
                    setActiveKey={() => {}}
                  >
                    <Row>
                      {categoryActiveKey !== categoryId && (
                        <Col
                          style={{ paddingRight: '0.6rem' }}
                          xs={1}
                          onClick={() => {
                            expandCategory(categoryId);
                          }}
                        >
                          <FontAwesomeIcon
                            style={{ cursor: 'pointer !important' }}
                            icon="caret-right"
                          />
                        </Col>
                      )}
                      {categoryActiveKey === categoryId && (
                        <Col
                          xs={1}
                          onClick={() => {
                            expandCategory(categoryId);
                          }}
                        >
                          <FontAwesomeIcon
                            style={{ cursor: 'pointer !important' }}
                            icon="caret-down"
                          />
                        </Col>
                      )}
                      <Col
                        className="col-PaddingLeft-0 ellipsis"
                        onClick={() => onCategoryClick(value, onChange, categoryId)}
                      >
                        <input
                          type="checkbox"
                          data-testid="recipient-checkbox"
                          checked={getIsCategorySelected(value, categoryId)}
                          disabled={isCategoryEmpty}
                        />
                          &nbsp;
                        <span
                          title={category?.CategoryName}
                          style={{ lineHeight: '1rem' }}
                        >
                          {category?.CategoryName ?? 'Uncategorized'}
                        </span>
                        {isCategoryEmpty && (
                          <span style={{
                            marginLeft: 8,
                            fontSize: '0.875rem',
                            color: '#8D8D8D',
                            fontWeight: 500,
                          }}
                          >
                            (empty)
                          </span>
                        )}
                      </Col>
                    </Row>
                  </ToggleHeader>
                  <Accordion.Collapse eventKey={categoryId}>
                    <Accordion
                      activeKey={activeKey}
                      onSelect={(e: any) => setActiveKey(e)}
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.125)',
                        marginTop: -1,
                        borderBottom: 0,
                      }}
                    >
                      {categoryGroups?.map((categoryGroup, gindex) => {
                        const group = allGroups?.find((g) => g.Id.toString() === categoryGroup.Id.toString());
                        if (group !== null && group !== undefined) {
                          return (
                            <div
                              key={group.Id}
                            >
                              <ToggleHeader
                                className={`rounded-0 ${getRowColor(gindex)}`}
                                eventKey={group.Id.toString()}
                                setActiveKey={() => {}}
                              >
                                <Row>
                                  <Col xs={1} />
                                  {activeKey !== group.Id.toString() && (
                                    <Col
                                      style={{ paddingRight: '0.6rem' }}
                                      xs={1}
                                      onClick={() => {
                                        expandGroup(group.Id);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        style={{ cursor: 'pointer !important' }}
                                        icon="caret-right"
                                      />
                                    </Col>
                                  )}
                                  {activeKey === group.Id.toString() && (
                                    <Col
                                      xs={1}
                                      onClick={() => {
                                        expandGroup(group.Id);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        style={{ cursor: 'pointer !important' }}
                                        icon="caret-down"
                                      />
                                    </Col>
                                  )}
                                  <Col
                                    className="col-PaddingLeft-0 ellipsis"
                                    onClick={() => onGroupClick(value, onChange, group.Id)}
                                  >
                                    <input
                                      type="checkbox"
                                      data-testid="recipient-checkbox"
                                      checked={value.includes(group.Id)}
                                    />
                          &nbsp;
                                    <span
                                      title={translateUserType(group.Name)}
                                      style={{ lineHeight: '1rem' }}
                                    >
                                      {translateUserType(group.Name)}
                                    </span>
                                  </Col>
                                </Row>
                              </ToggleHeader>
                              <Accordion.Collapse eventKey={group.Id.toString()}>
                                <ListGroup
                                  className="rounded-0"
                                >
                                  {allgroupmembersbyids[
                                    group.Id
                                  ]?.CustomerProfileIds?.map((cpid) => {
                                    if (cpid !== null) {
                                      return (
                                        <ListGroup.Item
                                          className="groupMemberItem pl-4"
                                          key={cpid}
                                        >
                                          {profilesLoading ? (
                                            <span className="loading-text">
                                              Loading...
                                            </span>
                                          ) : (
                                            <>
                                              {' '}
                                              {allprofilesbyids[cpid]?.FirstName}
                                              {' '}
                                              {allprofilesbyids[cpid]?.LastName}
                                            </>
                                          )}
                                        </ListGroup.Item>
                                      );
                                    }
                                    return null;
                                  })}
                                </ListGroup>
                              </Accordion.Collapse>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </Accordion>
                  </Accordion.Collapse>
                </Card>
              )
            })}
          </Accordion>
        </ListGroup>
      );
    }
    return (
      <ListGroup className="group-recipients">
        <Accordion
          activeKey={activeKey}
          onSelect={(e: any) => setActiveKey(e)}
        >
          {groups.map((group, index) => (
            <Card className="rounded-0" key={group.Id}>
              <ToggleHeader
                className={getRowColor(index)}
                eventKey={group.Id.toString()}
                setActiveKey={() => {}}
              >
                <Row>
                  {group.ParentGroupID !== 0 && <Col xs={1} />}

                  {activeKey !== group.Id.toString() && (
                    <Col
                      style={{ paddingRight: '0.6rem' }}
                      xs={1}
                    >
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer !important' }}
                        icon="caret-right"
                        onClick={() => {
                          expandGroup(group.Id);
                        }}
                      />
                    </Col>
                  )}
                  {activeKey === group.Id.toString() && (
                    <Col
                      xs={1}
                    >
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer !important' }}
                        icon="caret-down"
                        onClick={() => {
                          expandGroup(group.Id);
                        }}
                      />
                    </Col>
                  )}
                  <Col
                    className="col-PaddingLeft-0 ellipsis"
                    onClick={() => onGroupClick(value, onChange, group.Id)}
                  >
                    <input
                      type="checkbox"
                      data-testid="recipient-checkbox"
                      checked={value.includes(group.Id)}
                    />
                          &nbsp;
                    <span
                      title={translateUserType(group.Name)}
                      style={{ lineHeight: '1rem' }}
                    >
                      {translateUserType(group.Name)}
                            &nbsp;
                      {group.GroupType === 2 && <FontAwesomeIcon icon="calendar-check" className="float-right" />}
                    </span>
                  </Col>
                </Row>
              </ToggleHeader>
              <Accordion.Collapse eventKey={group.Id.toString()}>
                <ListGroup className="rounded-0">
                  {allgroupmembersbyids[
                    group.Id
                  ]?.CustomerProfileIds?.map((cpid) => {
                    if (cpid !== null) {
                      return (
                        <ListGroup.Item
                          className="groupMemberItem"
                          key={cpid}
                        >
                          {profilesLoading && (
                            <span className="loading-text">
                              Loading...
                            </span>
                          )}
                          {' '}
                          {allprofilesbyids[cpid]?.FirstName}
                          {' '}
                          {allprofilesbyids[cpid]?.LastName}
                        </ListGroup.Item>
                      );
                    }
                    return null;
                  })}
                </ListGroup>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </ListGroup>
    );
  }

  return (
    <Controller
      control={control}
      name="GroupIds"
      rules={
        validate
          ? {
            required: true,
            validate: (val: string[]) => Boolean(val.length > 0),
          }
          : undefined
      }
      defaultValue={[]}
      render={({ onChange, onBlur, value }) => (
        <>
          <Row className="group-recipients-header">
            <Col>
              <table
                className="table table-striped"
                style={{ marginBottom: '0rem' }}
              >
                <thead>
                  <tr>
                    <th>
                      <GroupSearchFilter
                        searchVal={searchQuery}
                        onSubmit={setSearchQuery}
                      />
                    </th>
                  </tr>
                </thead>
              </table>
            </Col>
          </Row>
          {renderGroupsOrCategories({ onChange, onBlur, value })}
        </>
      )}
    />
  );
};

type PofileCardProps = {
  validate: boolean
  setSelectedProfiles: (profiles: IProfile[]| ((oldProfiles: IProfile[]) => IProfile[])) => void
  selectedProfiles: IProfile[]
};

const ProfileCard: FC<PofileCardProps> = ({
  validate,
  setSelectedProfiles,
  selectedProfiles,
}) => {
  const {
    trigger, setValue, watch, control,
  } = useFormContext();
  const dataToPopulateCacheWithRef = useRef<null|any[]>(null);
  const selectedProfileIds = watch('ProfileIds');
  const [searchQuery, setSearchQuery] = useState<undefined|string>(undefined);
  const [filterGroup, setFilterGroup] = useState<undefined|string>(undefined);
  const [isFetchingAllProfiles, setIsFetchingAllProfiles] = useState(false);

  const getKey = (pageIndex, previousPageData) => {
    const params = {
      filterGroup: (filterGroup || undefined),
      searchField: (searchQuery ? SearchField.FULLNAME : undefined),
      searchValue: (searchQuery || undefined),
      page: pageIndex + 1,
      perpage: 50,
    };

    if (previousPageData && (
      previousPageData.Pagination.TotalPages === 0
      || previousPageData.Pagination.TotalPages === previousPageData.Pagination.Page
    )) {
      return null;
    }

    return `/api/v2/Profiles?${qs.stringify(params)}`;
  }

  const fetcher = async (key) => {
    if (dataToPopulateCacheWithRef.current) {
      if (dataToPopulateCacheWithRef.current[0]) {
        const itemToReturn = dataToPopulateCacheWithRef.current[0];

        dataToPopulateCacheWithRef.current = dataToPopulateCacheWithRef.current.slice(1);
        if (dataToPopulateCacheWithRef.current.length === 0) {
          dataToPopulateCacheWithRef.current = null;
        }

        return itemToReturn;
      }

      dataToPopulateCacheWithRef.current = null;
    }

    return (await Api.get<IProfilesPayload>(key)).data;
  }

  const {
    data, setSize, size,
  } = useSWRInfinite<IProfilesPayload>(
    getKey,
    fetcher,
    { revalidateOnFocus: false, revalidateFirstPage: false, persistSize: false },
  );

  const isLoadingMore = (size > 0 && data && typeof data[size - 1] === 'undefined');
  const hasMore = useMemo(() => {
    if (!data?.length) {
      return false;
    }

    const lastPage = data[data.length - 1];
    return lastPage.Pagination.TotalPages !== 0 && lastPage.Pagination.TotalPages !== lastPage.Pagination.Page
  }, [data])
  const isLoading = !data || isLoadingMore;

  const observer = useRef<IntersectionObserver>();
  const lastProfileRef = useCallback((node) => {
    if (isLoading) {
      return;
    }
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setSize((oldSize) => oldSize + 1)
      }
    })
    if (node) {
      observer.current.observe(node);
    }
  }, [isLoading, hasMore, setSize]);

  const fetchedProfiles: IProfile[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.reduce((acc, page) => [...acc, ...page.Profiles], [])
  }, [data]);

  const isAllProfilesChecked = useMemo(() => (
    fetchedProfiles.every((prof) => selectedProfileIds.includes(prof.CustomerProfileID))
  ), [selectedProfileIds, fetchedProfiles]);

  const getRowColor = (index: number): string => {
    if (index % 2 === 1) {
      return 'oddGroupItem';
    }
    return 'evenGroupItem';
  }

  const onCheckAllChange = async (checked: boolean): Promise<void> => {
    const setter = (profs: IProfile[]) => {
      setValue('ProfileIds', profs.map((prof) => prof.CustomerProfileID));
      setSelectedProfiles(profs);
    }

    if (!data || !data[0]) {
      return;
    }

    const lastFetchedPage = data[data.length - 1].Pagination?.Page;
    const totalPages = data[data.length - 1].Pagination?.TotalPages;
    const totalItems = data[data.length - 1].Pagination?.TotalItems;

    if (!lastFetchedPage || !totalPages || !totalItems) {
      return;
    }

    if (lastFetchedPage === totalPages) {
      if (checked) {
        // when checking
        const profilesToAdd: IProfile[] = [];
        fetchedProfiles.forEach((profile) => {
          if (!selectedProfileIds.includes(profile.CustomerProfileID)) {
            profilesToAdd.push(profile)
          }
        });
        setter([...selectedProfiles, ...profilesToAdd]);
        return;
      }
      // when unchecking
      const filteredProfiles = selectedProfiles.filter(
        (prof) => !fetchedProfiles.some((fetchedProf) => fetchedProf.CustomerProfileID === prof.CustomerProfileID),
      );
      setter(filteredProfiles);
      return;
    }

    const itemsToFetchAtOnce = 999;
    const fetchablePages = Math.ceil(totalItems / itemsToFetchAtOnce);
    const pageToStartFetchingAfter = Math.floor(lastFetchedPage * 50 / itemsToFetchAtOnce);
    setIsFetchingAllProfiles(true);

    await Promise.all(
      _times(
        fetchablePages - pageToStartFetchingAfter,
        (i) => i + 1 + pageToStartFetchingAfter,
      ).map((pageToFetch) => {
        const params = {
          filterGroup: (filterGroup || undefined),
          searchField: (searchQuery ? SearchField.FULLNAME : undefined),
          searchValue: (searchQuery || undefined),
          page: pageToFetch,
          perpage: itemsToFetchAtOnce,
        };
        return Api.get<IProfilesPayload>(`/api/v2/Profiles?${qs.stringify(params)}`)
          .then((res) => res.data);
      }),
    ).then(async (restDataArr) => {
      const flatRestProfilesArr = _flatten(restDataArr.map((item) => item.Profiles));
      const lastFetchedProfile = fetchedProfiles[fetchedProfiles.length - 1];
      const lastFetchedProfileIndexInRestArr = flatRestProfilesArr.findIndex(
        (item) => item.CustomerProfileID === lastFetchedProfile.CustomerProfileID,
      );
      const restProfilesArrWithoutExisting = lastFetchedProfileIndexInRestArr === -1
        ? flatRestProfilesArr
        : flatRestProfilesArr.slice(lastFetchedProfileIndexInRestArr + 1);

      const dataToPopulateCacheWith = _chunk(restProfilesArrWithoutExisting, 50)
        .map((profilesChunk, i) => ({
          Profiles: profilesChunk,
          Pagination: {
            Page: lastFetchedPage + i + 1,
            PerPage: 50,
            TotalItems: totalItems,
            TotalPages: totalPages,
          },
        }));

      dataToPopulateCacheWithRef.current = dataToPopulateCacheWith;
      setIsFetchingAllProfiles(false);
      setSize(dataToPopulateCacheWith.length + data.length);

      if (checked) {
        // when checking
        setter(_uniqBy([...selectedProfiles, ...fetchedProfiles, ...flatRestProfilesArr], 'CustomerProfileID'));
        return;
      }
      // when unchecking
      const fetchedProfilesAndRest = [...fetchedProfiles, ...flatRestProfilesArr];
      const filteredProfiles = selectedProfiles.filter(
        (prof) => !fetchedProfilesAndRest.some(
          (fetchedProf) => fetchedProf.CustomerProfileID === prof.CustomerProfileID,
        ),
      );
      setter(filteredProfiles);
    }).catch((err) => {
      log.error(err);
    });
  }

  const onAvailableProfileChecked = useCallback((profile: IProfile): void => {
    if (selectedProfileIds.includes(profile.CustomerProfileID)) {
      setValue('ProfileIds', selectedProfileIds.filter((pid) => pid !== profile.CustomerProfileID));
      setSelectedProfiles(selectedProfiles.filter(({ CustomerProfileID }) => (
        CustomerProfileID !== profile.CustomerProfileID
      )));
    } else {
      setValue('ProfileIds', [...selectedProfileIds, profile.CustomerProfileID]);
      setSelectedProfiles([...selectedProfiles, profile]);
    }
    trigger('ProfileIds');
  }, [selectedProfileIds, setValue, trigger, selectedProfiles, setSelectedProfiles]);

  // We need to make sure that either one group or one recipient is selected.
  // const shouldSkipValidation = Boolean(watch('GroupIds')?.length);
  const Profiles = useCallback(({
    index, style,
  }) => {
    if (isLoading && index === fetchedProfiles.length - 1 + 1) {
      return (
        <div
          className="loading-text d-flex text-center justify-content-center"
          key="loading"
          style={style}
          role="status"
        >
          Loading...
        </div>
      )
    }

    const rowProps = {
      key: fetchedProfiles[index].CustomerProfileID,
      style,
      className: 'no-overflow-x cursor-pointer',
      ref: index === fetchedProfiles.length - 1 ? lastProfileRef : undefined,
    }

    return (
      <div {...rowProps}>
        <Row
          style={{ overflowX: 'hidden' }}
          className={getRowColor(index)}
          onClick={() => onAvailableProfileChecked(fetchedProfiles[index])}
        >
          <Col xs={1}>
            <input
              type="checkbox"
              readOnly
              checked={selectedProfileIds.includes(fetchedProfiles[index]?.CustomerProfileID)}
              data-testid="recipient-checkbox"
              className="align-middle ml-3"
            />
          </Col>
          <Col className="align-middle ml-1 ellipsis">
            <span title={`${fetchedProfiles[index]?.FirstName} ${fetchedProfiles[index]?.LastName}`}>
              {fetchedProfiles[index]?.FirstName}
              {' '}
              {fetchedProfiles[index]?.LastName}
            </span>
          </Col>
        </Row>
      </div>
    )
  }, [fetchedProfiles, onAvailableProfileChecked, selectedProfileIds, isLoading, lastProfileRef]);

  return (
    <ListGroup style={{ lineHeight: '0.7rem' }}>
      <Row>
        <Col>
          <table className="table table-striped" style={{ marginBottom: '0rem' }}>
            <thead>
              <tr>
                <th>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    paddingLeft: '0.25rem',
                  }}
                  >
                    <input
                      type="checkbox"
                      checked={isAllProfilesChecked}
                      onChange={(e) => {
                        onCheckAllChange(e.target.checked);
                      }}
                      disabled={isLoading || isFetchingAllProfiles}
                    />
                    <span>
                      Profiles
                    </span>
                    <ProfileSearchFilter
                      searchVal={searchQuery}
                      groupVal={filterGroup}
                      onSubmit={(_searchQuery, _filterGroup) => {
                        setSearchQuery(_searchQuery);
                        setFilterGroup(_filterGroup);
                      }}
                    />
                  </div>
                </th>

              </tr>
            </thead>
          </table>
        </Col>
      </Row>

      <Controller
        control={control}
        name="ProfileIds"
        defaultValue={[]}
        rules={
          validate
            ? {
              required: true,
              validate: (val: string[]) => Boolean(val.length)
              ,
            }
            : undefined
        }
        render={({ onChange, value }) => (
          <div>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: '1 1 auto', height: '600px', lineHeight: '2.2rem' }}>
                <AutoSizer>
                  {({ height, width }) => (
                    <FixedSizeList
                      height={height}
                      width={width}
                      itemSize={35}
                      itemCount={fetchedProfiles.length + (isLoading ? 1 : 0)}
                      rowWidth={width}
                    >
                      {Profiles}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </div>
            </div>
          </div>
        )}
      />
    </ListGroup>
  );
};

export type ShowGroups = 'all'|'basic'|'special'|boolean
type Props = {
  disableValidation?: boolean
  showProfiles?: boolean
  showGroups?: ShowGroups
};

export const DistributionList: FC<Props> = ({
  disableValidation = false,
  showProfiles = true,
  showGroups = true,
}) => {
  const {
    watch, register, getValues, setValue,
  } = useFormContext();
  const selectedGroupIds: number[] = watch('GroupIds') || [];
  const allCategories = useSelector((state: RootState) => state.Groups.categories)

  const equalityCheck = (left: IGroup[], right: IGroup[]):
  boolean => left?.length === right?.length && JSON.stringify(left) === JSON.stringify(right)

  const selectedGroups = useRefSelector(
    getGroupByIds(selectedGroupIds),
    equalityCheck,
  );

  const [selectedProfiles, setSelectedProfiles] = useState<IProfile[]>([]);
  useMemo(() => selectedProfiles?.sort(
    (a, b) => a.LastName.localeCompare(b.LastName)),
  [selectedProfiles]);

  useMemo(() => selectedGroups?.sort(
    (a, b) => a.Name.localeCompare(b.Name)),
  [selectedGroups]);

  useEffect(() => {
    const selectedProfileIds = getValues('ProfileIds');
    if (selectedProfileIds?.length) {
      Promise.all(
        _chunk<string>(selectedProfileIds, 1000)
          .map((profileIdsChunk) => ProfilesAPI.profilesGetByIds(profileIdsChunk)),
      ).then((profiles) => {
        setSelectedProfiles(_flatten(profiles).filter(Boolean));
      }).catch((err) => {
        log.error(err);
      });
    }
  }, [getValues]);

  const profileIds = watch('ProfileIds');
  useUpdateEffect(() => {
    if (profileIds?.length === 0) {
      setSelectedProfiles([]);
    }
  }, [profileIds?.length]);

  const hiddenProfileGroupCheck = true;
  const [filterGroupType, setFilterGroupType] = useState<number[]>(undefined);
  const translateUserType = useUserTypeTranslation();
  useEffect(() => {
    if (showGroups === 'basic') {
      setFilterGroupType([0, 1]);
    } else if (showGroups === 'special') {
      setFilterGroupType([2]);
    }
  }, [showGroups]);
  const getCategoryNameByGroupId = (
    groupId: number,
  ): string => {
    const category = allCategories.find((c) => c.GroupIds.indexOf(groupId) > -1);
    return (category && category.CaremergeCategoryId > 0) ? `(${category.CategoryName}) ` : '';
  }
  register('selectedGroupAndProfiles');

  const onRemoveProfile = (prof: IProfile) => {
    setValue(
      'ProfileIds',
      profileIds.filter((pid) => pid !== prof.CustomerProfileID),
    );
    setSelectedProfiles(selectedProfiles.filter(({ CustomerProfileID }) => (
      CustomerProfileID !== prof.CustomerProfileID
    )));
  }

  const onRemoveGroup = (gr: IGroup) => {
    setValue('GroupIds', selectedGroupIds.filter((gid) => gid !== gr.Id));
  }

  return (
    <Card className="DistributionList overflow-visible">
      <Card.Body>
        <Row>
          <Col xs={7} className="GroupsAndProfilesCol">
            <Card className="GroupsAndProfilesCard">
              <Card.Body>
                <Row>
                  {showProfiles && (
                    <Col xs={6}>
                      <ProfileCard
                        validate={!disableValidation}
                        setSelectedProfiles={setSelectedProfiles}
                        selectedProfiles={selectedProfiles}
                      />
                    </Col>
                  )}
                  <Col xs={showProfiles ? 6 : 12}>
                    <GroupCard
                      validate={!disableValidation}
                      filterGroupType={filterGroupType}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={5} className="MessageRecipientsCol">
            <div className="pt-1">
              <table className="table table-striped" style={{ marginBottom: '0rem' }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>
                      <b>Message Recipients</b>
                    </th>
                  </tr>
                </thead>
              </table>
              <div style={{ height: '628.5px', overflowY: 'auto' }}>
                <ListGroup>
                  {selectedGroups.map((group) => (
                    <ListGroup.Item
                      key={group.Id}
                      className="tableListItem"
                      data-testid="message-recipient"
                    >
                      <FontAwesomeIcon icon="users" />
                    &nbsp;
                      {getCategoryNameByGroupId(group.Id)}
                      <button
                        className="px-1 remove-recipient-btn"
                        type="button"
                        onClick={() => {
                          onRemoveGroup(group);
                        }}
                      >
                        <FontAwesomeIcon
                          icon="times"
                          size="sm"
                        />
                      </button>
                      {translateUserType(group.Name)}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <ListGroup>
                  {selectedProfiles.map((prof) => (
                    <ListGroup.Item
                      key={prof.CustomerProfileID}
                      data-testid="message-recipient"
                      className="tableListItem"
                    >
                      {prof.FirstName}
                      {' '}
                      {prof.LastName}
                      <button
                        className="px-1 remove-recipient-btn"
                        type="button"
                        onClick={() => {
                          onRemoveProfile(prof);
                        }}
                      >
                        <FontAwesomeIcon
                          icon="times"
                          size="sm"
                        />
                      </button>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
              <Form.Check
                className="d-none"
                name="selectedGroupAndProfiles"
                id="selectedGroupAndProfiles"
                type="checkbox"
                label="hiddenSelectedGroupAndProfiles"
                inline
                defaultChecked={selectedGroupIds?.length > 0 || selectedProfiles.length > 0}
                hidden={hiddenProfileGroupCheck}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
