import React from 'react';
import { useFormContext } from 'react-hook-form';
import Select from 'react-select';
import {
  Form, Col,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { RootState } from 'types/rootState';
import { LanguageNames, LANGUAGES } from 'lib/CommonSelect';

export const LanguageSelect = () => {
  const {
    watch, setValue, control, register,
  } = useFormContext();
  const selectedLanguage = watch('SourceLanguage');
  const selectedLanguageObj = LANGUAGES.find((l) => l.value === selectedLanguage);
  const allowInputLanguage = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.AllowInputLanguageSelection);

  return (
    <div className="languageSelection">
      <input
        type="hidden"
        name="SourceLanguage"
        ref={register({ required: true })}
      />
      {(selectedLanguage !== LanguageNames.English || allowInputLanguage) && (
        <>
          <Form.Group as={Col} className="inputLanguageLabel">
            <label
              htmlFor="select-language"
            >
              Input Language:
            </label>
          </Form.Group>
          <Form.Group as={Col}>
            <Select
              id="select-language"
              name="select-language"
              isDisabled={!allowInputLanguage}
              label="Language"
              placeholder="Select language"
              defaultValue={{ label: selectedLanguageObj?.label, value: selectedLanguageObj?.value }}
              options={LANGUAGES}
              control={control}
              onChange={(e) => setValue('SourceLanguage', e.value, { shouldDirty: true })}
              rules={{ required: true }}
            />
          </Form.Group>
        </>
      )}
    </div>
  )
}
