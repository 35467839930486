import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { IErrorState } from 'reducers/IErrorState';

function useErrors(profileErrors: IErrorState): void {
  useEffect(() => {
    if (profileErrors?.error) {
      toast.error(profileErrors?.error?.response?.data?.Message)
    }
  }, [profileErrors, profileErrors.error]);
}

export default useErrors
