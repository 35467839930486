import { SurveyContentResponseTypeEnum } from './IDischargedPatient'

export interface ISurveyQuestionList {
  SurveyQuestionListId: number
  Name: string
}

// these new values correspond to the enums used for the former wellnesscheck
export enum SurveyQuestionListItemContentTypeEnum {
  Greeting = 'Greeting',
  Question = 'Question',
  Farewell = 'Farewell'
}

export interface ISurveyQuestionListItem {
  SurveyQuestionContentId: number
  SurveyQuestionListId: number
  Ordinal: number
  ContentId?: number
  VoiceContent?: string
  SMSContent?: string
  ContentType: SurveyQuestionListItemContentTypeEnum
  ResponseType: SurveyContentResponseTypeEnum
  SurveyContentResponsePattern: string
}
