import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TextMessageIcon(): ReactElement {
  return (
    <span style={{
      backgroundColor: 'rgb(248 130 37 / 19%)',
      padding: '5px 7px 5px 0px',
      marginLeft: '5px',
      borderRadius: '5px',
    }}
    >
      <span
        className="fa-layers fa-fw pl-2 fa-1x"
        title="Text message"
      >
        <FontAwesomeIcon icon="sms" transform="right-5" />
      </span>
    </span>
  );
}

export default TextMessageIcon;
