import { useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import { SurveyConfig } from 'reducers/IUserInfoState';

export function useSurveyConfig(): SurveyConfig {
  const config = useSelector(
    (state: RootState) => state.UserInfo.userInfo?.SurveyStatusConfiguration,
  );
  return config;
}
