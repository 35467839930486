import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Alert } from 'react-bootstrap';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GroupsValidator = () => {
  const {
    watch, errors, trigger,
  } = useFormContext();

  const groupIds = watch('GroupIds');

  useUpdateEffect(() => {
    trigger('GroupIds');
  }, [groupIds]);

  if (!errors.GroupIds) {
    return null;
  }

  return (
    <Alert variant="danger" className="mb-2">
      <FontAwesomeIcon icon="info-circle" className="mr-2" />
      Please select at least one group to continue.
    </Alert>
  )
}
