import React, { useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Switch } from 'components/Inputs/Switch';
import { ReactSortable } from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
// import { Swap } from 'sortablejs/modular/sortable.core.esm';

import { IUserInfoState } from 'reducers/IUserInfoState';
import { useSelector } from 'react-redux';
import { RootState } from 'types/rootState';

export interface CustomizeModalProps {
  onHide(): void
  show: boolean
  displayedColumns: string[] | undefined
  columnOrder: string[] | undefined
  columns: any
  setDisplayedColumns(updatedDisplayedColumns: string[]): void
  setColumnOrder(updatedColumnOrder: string[]): void
  onReset(): void
  saveAsDefault(): void
}

// Sortable.mount(new Swap());

export const CustomizeModal: React.FC<CustomizeModalProps> = ({
  onHide,
  show,
  setDisplayedColumns,
  setColumnOrder,
  displayedColumns,
  columnOrder,
  columns,
  onReset,
  saveAsDefault,
}) => {
  const handleHide = (column, showColumn) => {
    if (showColumn) {
      setDisplayedColumns([...displayedColumns, column]);
    } else {
      setDisplayedColumns(displayedColumns.filter((item) => item !== column));
    }
  }
  const userInfo = useSelector((state: RootState) : IUserInfoState => state.UserInfo);
  const isAdmin = () => userInfo.roles.includes('Super') || userInfo.roles.includes('Admin')
  const isLoading = !columnOrder || !displayedColumns;

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="profile-customize-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="w-100">
          Customize
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <hr />
        {isLoading ? null : (
          <ReactSortable
            list={columnOrder.map((item) => ({ id: item }))}
            setList={(updatedList) => {
              setColumnOrder(updatedList.map((item) => item.id))
            }}
            ghostClass="profile-customize-modal__list__item--ghost"
            animation={150}
            handle=".profile-customize-modal__list__item__draggable"
            draggable=".profile-customize-modal__list__item"
            tag="ul"
            className="profile-customize-modal__list"
            // swap
          >
            {columnOrder.map((accessor) => (
              <ListItem
                key={accessor}
                id={accessor}
                hide={handleHide}
                checked={displayedColumns.includes(accessor)}
                columns={columns}
              />
            ))}
          </ReactSortable>
        )}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'flex-start' }}>
        <Button
          type="button"
          variant="danger"
          onClick={onReset}
          className="m-0"
        >
          Reset to default
        </Button>
        {isAdmin() && (
          <Button
            type="button"
            variant="primary"
            onClick={saveAsDefault}
            className="ml-2"
          >
            Save as default
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

const ListItem = ({
  id, checked, hide, columns,
}) => {
  const label = useMemo(() => {
    const col = columns.find((item) => item.accessor === id);
    if (!col) {
      return id;
    }
    return col.Header;
  }, [columns, id]);

  return (
    <li className="profile-customize-modal__list__item">
      <FontAwesomeIcon
        icon={faArrowsAlt}
        className="profile-customize-modal__list__item__draggable"
      />
      <Switch
        checked={checked}
        id={id}
        onChange={(e) => {
          hide(id, e.target.checked);
        }}
        className="profile-customize-modal__switch"
      />
      {label}
    </li>
  )
};
