import React, {
  useMemo, ReactElement, useCallback,
} from 'react';

import { Pagination } from 'react-bootstrap';

interface IProps {
  currentPage: number
  totalPages: number
  pageDelta?: number
  onPageChange: (page: number) => void
  className?: string
  style?: React.CSSProperties
}

function VfPagination({
  currentPage,
  totalPages: _totalPages,
  pageDelta = 2,
  onPageChange,
  className,
  style = {},
}: IProps): ReactElement {
  React.useEffect(() => {
    if (_totalPages === 0 && currentPage !== 1) {
      onPageChange(1);
    }
  }, [currentPage, _totalPages])

  const totalPages = Math.max(_totalPages, 1);

  const pages = useMemo((): number[] => {
    const res = [1];

    if (totalPages <= 1) {
      return res;
    }

    if (currentPage - pageDelta > 2) {
      res.push(-1);
    }

    for (let i = currentPage - pageDelta; i <= currentPage + pageDelta; i++) {
      if (i > 1 && i < totalPages) {
        res.push(i);
      }
    }

    if (currentPage + pageDelta < totalPages - 1) {
      res.push(-1);
    }

    res.push(totalPages);

    return res;
  }, [currentPage, pageDelta, totalPages]);

  const renderPaginationItems = useCallback(() => pages.map((page, i) => {
    if (page !== -1) {
      return (
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => onPageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    return (
      <Pagination.Ellipsis disabled>
        ...
      </Pagination.Ellipsis>
    );
  }), [currentPage, pages, onPageChange]);

  return (
    <Pagination
      data-testid="Pagination"
      className={className}
      style={style}
    >
      <button
        type="button"
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        Previous
      </button>

      <div
        className="pagination__items"
        data-testid="Pagination-items"
      >
        {renderPaginationItems()}
      </div>

      <button
        type="button"
        disabled={currentPage >= totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        Next
      </button>
    </Pagination>
  );
}

export default VfPagination;
