import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from 'reducers/UserInfo';
import { useIdleTimer } from 'react-idle-timer'
import { fetchUserDetail } from 'reducers/UserDetail';
import { RootState } from 'types/rootState';
import { isTokenExpired, isTokenHalfwayExpired } from 'lib/helpers/ExpiredToken';
import { useThrottle } from './hooks/useThrottle';

export const TokenExpiryTimer = memo((): null => {
  const { jwt } = useSelector((state: RootState) => state.UserInfo);
  const dispatch = useDispatch();

  const handleOnAction = useThrottle(() => {
    if (isTokenExpired(jwt)) {
      dispatch(logout({ redirectTo: window.location.pathname, reason: 'timeout' }));
    } else if (isTokenHalfwayExpired(jwt)) {
      // This is a bit of a hack until we have proper refresh tokens implemented.
      // Relies on server functionality that automatically refreshes tokens that are halfway expired
      // when an api request is made. Therefore by fetching user info we can refresh the token
      dispatch(fetchUserDetail());
    }
  }, [dispatch, jwt], 10000, { leading: true, trailing: false });

  useIdleTimer({
    onAction: handleOnAction,
  });

  return null;
});
