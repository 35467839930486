import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import { dischargedPatientSetSelected, getDischargedPatientsIndex, selectPaginationData }
  from 'reducers/DischargedPatients';
import { IDischargedPatient, CommunicationTypeEnum, IDischargedPatientFilterParams } from 'types/IDischargedPatient';
import {
  Col, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import Pagination from 'components/Pagination';
import { ReactComponent as Plus } from 'styles/images/plus.svg';
import { NumberParam, useQueryParam } from 'use-query-params';
import { DischargedPatientsFilterControl } from './DischargedPatientsFilterControl';
import { DischargedPatientsList } from './DischargedPatientsList';
import { PatientDetailsPage } from './PatientDetails';
import { ISurveyCall } from '../../types/ISurveyCall';
import { ConfirmationServiceProvider, useConfirmation } from '../../components/ConfirmationService';

interface IProps {
  match: { params: { id: string, callId: string } }
}

const DischargedPatientsIndexPage: React.FC<IProps> = ({ match }: IProps) => {
  const dispatch = useDispatch();
  const [selectedPatientId, setSelectedPatientId] = useState<number>(undefined);
  const [selectedCallId, setSelectedCallId] = useState<number>(undefined);
  const canCreateDischargedPatients = useHasPermissions('Discharged Patients:Create');
  const [isAdmin, isSuper]: boolean[] = useHasRoles(['Admin', 'Super']);
  const canCreatePatient = canCreateDischargedPatients || isAdmin || isSuper;

  const selectedDischargedPatient = useSelector(
    (state: RootState) => state.DischargedPatients.selectedDischargedPatient,
  );

  const [currentPage, setCurrentPage] = useQueryParam('page', NumberParam);
  const [prevSearchParams, setPrevSearchParams] = useState<IDischargedPatientFilterParams>(null);
  const [prevSortParams, setPrevSortParams] = useState<IDischargedPatientFilterParams>(null);
  const paginationData = useSelector(selectPaginationData);

  const dischargedPatientIdFromUrl = match.params.id;
  useEffect(() => {
    if (dischargedPatientIdFromUrl !== 'new') {
      setShowPatientInfoForm(false)
    }
  }, [dischargedPatientIdFromUrl])

  const confirm = useConfirmation();

  const onStateChange = (acceptedFunction, rejectedFunction) => {
    confirm({
      variant: 'danger',
      catchOnCancel: true,
      title: 'Discharge Patient Notes Changes',
      description: 'You have unsaved changes, are you sure you want to leave?',
    })
      .then(() => { acceptedFunction(); setHasNoteChanged(false); })
      .catch(rejectedFunction);
  };

  const patientList = useSelector((state:RootState) => state.DischargedPatients.byIds);
  const listLoading = useSelector((state:RootState) => state.DischargedPatients.loading);
  const [showPatientInfoForm, setShowPatientInfoForm] = useState<boolean>(false);
  const [hasNoteChanged, setHasNoteChanged] = useState<boolean>();

  useEffect(() => {
    const listNotLoading = !listLoading;
    let patient: IDischargedPatient;
    if (listNotLoading) {
      if (selectedPatientId) {
        patient = patientList[selectedPatientId];
      } else {
        patient = {
          DischargePatientId: 0,
          ProfileId: '',
          ContactProfileId: '',
          DischargeDate: format(new Date(), 'yyyy-MM-dd'),
          NextAutoCallDate: format(new Date(), 'yyyy-MM-dd'),
          NextManualCallDate: format(new Date(), 'yyyy-MM-dd'),
          LastCallDate: format(new Date(), 'yyyy-MM-dd'),
          ProgramStatus: 'In Program',
          Facility: '',
          MRN: '',
          DischargeTo: '',
          AttendingPhysician: '',
          AdmittingDiagnosis: '',
          LastResponse: '',
          LastCallResult: '',
          ReviewRequired: false,
          LastCallType: '',
          NextCallType: '',
          LastCallTransactionCount: 0,
          IncompleteCallCount: 0,
          CompleteCallCount: 0,
          TotalCalls: 0,
          LastCallId: 0,
          SurveyCalls: [],
          Notes: '',
          AdditionalPhoneNumber: '',
          Tags: [],
          Version: undefined,
          CommunicationType: CommunicationTypeEnum.Voice,
        };
      }
      dispatch(dischargedPatientSetSelected(patient));
    }
  }, [dispatch, listLoading, patientList, selectedPatientId, showPatientInfoForm]);

  const [selectedSort, setSelectedSort] = useState<string>('LastName');
  const [sortDirection, setSortDirection] = useState<string>('asc');
  const sortKeys = {
    FirstName: {
      label: 'First Name',
      value: 'FirstName',
    },
    LastName: {
      label: 'Last Name',
      value: 'LastName',
    },
    DischargeDate: {
      label: 'Discharge Date',
      value: 'DischargeDate',
    },
    Facility: {
      label: 'Facility',
      value: 'Facility',
    },
    NextAutoCallDate: {
      label: 'Next Auto Call',
      value: 'NextAutoCallDate',
    },
    NextManualCallDate: {
      label: 'Next Manual Call',
      value: 'NextManualCallDate',
    },
    LastCallDate: {
      label: 'Last Call',
      value: 'LastCallDate',
    },
  };

  const onSearchParamsChange = useCallback((params) => {
    if (prevSearchParams?.searchField !== params.searchField
      || prevSearchParams?.searchValue !== params.searchValue) {
      setPrevSearchParams(params);
      if (currentPage) {
        setCurrentPage(1);
      }
    }
    setSelectedPatientId(null);
    const page = currentPage || 1
    const perpage = paginationData.PerPage

    const parameters = { ...params, page, perpage };
    dispatch(getDischargedPatientsIndex(parameters));
  }, [currentPage,
    dispatch,
    paginationData.PerPage,
    prevSearchParams?.searchField,
    prevSearchParams?.searchValue,
    setCurrentPage]);

  const onSortParamsChange = useCallback((params) => {
    if (prevSortParams?.sortField !== params.sortField
        || prevSortParams?.sortDirection !== params.sortDirection) {
      setPrevSortParams(params);
      if (currentPage) {
        setCurrentPage(1);
      }
    }
    setSelectedPatientId(null);
    const page = currentPage || 1
    const perpage = paginationData.PerPage
    const parameters = { ...params, page, perpage };
    dispatch(getDischargedPatientsIndex(parameters));
  }, [currentPage,
    dispatch,
    paginationData.PerPage,
    prevSortParams?.sortField,
    prevSortParams?.sortDirection,
    setCurrentPage]);
  return (
    <ConfirmationServiceProvider>
      <div className="discharged-patients">
        <Helmet>
          <title>Survey Calls</title>
        </Helmet>
        <Row className="mb-4">
          <Col>
            <h1>Activities &amp; Status</h1>
          </Col>
        </Row>
        <Row>
          <Col md={9} className="d-flex align-items-center">
            <DischargedPatientsFilterControl
              setCurrentPage={setCurrentPage}
              onStateChange={onStateChange}
              onSearchParamsChange={onSearchParamsChange}
              onSortParamsChange={onSortParamsChange}
              hasNoteChanged={hasNoteChanged}
              {...{
                sortKeys, selectedSort, setSelectedSort, sortDirection, setSortDirection,
              }}
            />
          </Col>
          <Col className="d-flex align-items-start">
            <Link
              to="/dischargedpatients-dashboard"
              style={{ display: 'inline-block', marginTop: 20, marginRight: 20 }}
            >
              Dashboard
            </Link>
            {canCreatePatient && (
              <button
                type="button"
                className="btn btn-warning btn-lg "
                onClick={() => {
                  setShowPatientInfoForm(true);
                  setSelectedPatientId(null);
                }}
              >
                <Plus
                  width="24"
                  height="24"
                  fill="currentColor"
                  style={{ marginRight: '10px' }}
                />
                Add New Patient
              </button>
            )}
          </Col>

        </Row>
        <Row>
          <Col md={5} style={{ height: '100vh' }}>
            <DischargedPatientsList
              setSelectedPatient={(patient:IDischargedPatient) => {
                if (hasNoteChanged) {
                  onStateChange(() => {
                    if (patient.DischargePatientId === selectedPatientId) {
                      setSelectedPatientId(null)
                      dispatch(dischargedPatientSetSelected(null))
                      setShowPatientInfoForm(false);
                    } else {
                      setSelectedPatientId(patient.DischargePatientId)
                      dispatch(dischargedPatientSetSelected(patient))
                      setShowPatientInfoForm(false);
                    }
                  }, () => {
                  })
                }
                if (!hasNoteChanged) {
                  if (patient.DischargePatientId === selectedPatientId) {
                    setSelectedPatientId(null)
                    dispatch(dischargedPatientSetSelected(null))
                    setShowPatientInfoForm(false);
                  } else {
                    setSelectedPatientId(patient.DischargePatientId)
                    dispatch(dischargedPatientSetSelected(patient))
                    setShowPatientInfoForm(false);
                  }
                }
              }}
              sortField={selectedSort}
              sortDirection={sortDirection}
            />
            <div className="overflow-hidden">
              <Pagination
                currentPage={currentPage || 1}
                totalPages={paginationData.TotalPages}
                pageDelta={1}
                onPageChange={(page: number) => setCurrentPage(page, 'pushIn')}
                style={{ marginTop: 20 }}
              />
            </div>
          </Col>
          {selectedDischargedPatient && (
            <Col md={7} className="result-pane">
              <PatientDetailsPage
                showBack={false}
                selectedCallId={selectedCallId}
                selectedDischargedPatient={selectedDischargedPatient}
                showPatientInfoForm={showPatientInfoForm}
                setShowPatientInfoForm={setShowPatientInfoForm}
                setSelectedPatient={(patient:IDischargedPatient) => {
                  if (!patient) {
                    setSelectedPatientId(0);
                  } else {
                    setSelectedPatientId(patient.DischargePatientId)
                  }
                }}
                hasNoteChanged={hasNoteChanged}
                startExpanded={false}
                setHasNoteChanged={setHasNoteChanged}
                setSelectedCall={(patient:IDischargedPatient,
                  surveyCall:ISurveyCall) => {
                  if (selectedCallId === surveyCall?.SurveyCallId) {
                    setSelectedCallId(null);
                  } else if (surveyCall) {
                    setSelectedCallId(surveyCall.SurveyCallId)
                  } else {
                    setSelectedCallId(null);
                  }
                }}
              />
            </Col>
          )}
        </Row>
      </div>
    </ConfirmationServiceProvider>
  )
};

const confirmationIndexPage: React.FC<IProps> = ({ match }: IProps) => (
  <ConfirmationServiceProvider>
    <DischargedPatientsIndexPage match={match} />
  </ConfirmationServiceProvider>
);

export default confirmationIndexPage;
