import React, { useState } from 'react';
import {
  Button, Form, Spinner, Alert,
} from 'react-bootstrap';
import { createSession, PbiSessionModel } from 'api/PowerBiApi';

const CreatePbiSession: React.FC = () => {
  const [accountIds, setAccountIds] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<PbiSessionModel | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setResponse(null);

    const accountIdsArray = accountIds.split(',').map((id) => parseInt(id.trim(), 10)).filter((id) => !isNaN(id));

    if (accountIdsArray.length === 0) {
      setError('Please enter one or more valid account IDs.');
      setLoading(false);
      return;
    }

    try {
      const data = await createSession(accountIdsArray);
      console.log(data);
      setResponse(data);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while creating the session.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <h1>Create PowerBi Session</h1>
      <p>
        Creates a powerbi session with the specified information.
        The returned session id can be used to view reports with access granted to the resources you provide.
      </p>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="accountIds">
          <Form.Label>Accessible Account Ids</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter comma separated account ids"
            value={accountIds}
            onChange={(e) => setAccountIds(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Create Session'}
        </Button>
      </Form>

      {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
      {response && (
        <Alert variant="success" className="mt-3">
          <h4>Session Created Successfully!</h4>
          <p>
            <strong>
              PbiSessionId:
              {` ${response.PbiSessionId}`}
            </strong>
          </p>

          <p>Please copy this value and store it in a safe location.</p>
        </Alert>
      )}
    </div>
  );
};

export default CreatePbiSession;
