export enum UserType {
  Staff = 'Staff',
  Family = 'Family',
  Seniors = 'Member',
}

export interface IProfile {
  CustomerProfileID: string
  UserType: UserType | string
  FirstName: string
  LastName: string
  Address: string
  City: string
  State: string
  ZipCode: string
  RoomNumber: string
  LandLine: string
  LandLineOptIn: boolean
  MobilePhone: string
  MobilePhoneOptIn: boolean
  OnDemand: string
  OnDemandOptIn: boolean
  Email: string
  EmailOptIn: boolean
  SMSOptIn: boolean
  ComplianceDate1: string
  ComplianceDate2: string
  ComplianceDate3: string
  ComplianceDate4: string
  ComplianceDate5: string
  ComplianceDate6: string
  ComplianceDate7: string
  ComplianceDate8: string
  LifeDate1: string
  LifeDate2: string
  IsInactive: boolean
  InactiveStartDate: string
  InactiveEndDate: string
  Language: string
  Groups: number[]
  RelatedProfiles: string[]
  JobClassification: string
  Role: string
  BlockBeginTime: string
  BlockEndTime: string
  CustomFields: {[key: string]: string}
  CaremergeUserId: number
}

export interface IProfileExport extends Omit<IProfile, 'Groups'> {
  GroupNames: string[]
}
