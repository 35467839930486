import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'components/Inputs/Select';
import { updateDischargedPatientStatus } from 'reducers/DischargedPatients';
import { Button, Modal } from 'react-bootstrap';
import { IPatientFormData } from 'types/IPatientFormData';
import { useStatusOptions } from './useStatusOptions';
import { useSurveyConfig } from './useSurveyConfig';

interface IProps {
  data?: IPatientFormData
  allSurveyCallsCompleted?: boolean
}

export function StatusDropDown({ data, allSurveyCallsCompleted }: IProps): React.ReactElement {
  const dispatch = useDispatch();
  const statusOptions = useStatusOptions(data.dischargedPatient.ProgramStatus);
  const config = useSurveyConfig();
  const isEndTransition = useCallback((current, next) => {
    const exact = `${current}:${next}`;
    const to = `*:${next}`;
    const from = `${current}:*`;
    for (const t of [exact, to, from]) {
      if (t in config.Transitions) {
        if (config.Transitions[t] === 'End') {
          return true;
        }
      }
    }
    return false;
  }, [config]);

  const [showModal, setShowModal] = useState<{ value: string }>(null);
  const handleModalSave = () => {
    setShowModal(null);
    if (showModal?.value) {
      dispatch(updateDischargedPatientStatus(
        data.dischargedPatient.DischargePatientId, showModal.value,
      ));
    }
  };
  const handleModalShow = (value: string) => {
    setShowModal({ value });
  };

  const handleModalClose = () => {
    setShowModal(null);
  };

  return (
    <>
      <Modal
        show={showModal != null}
      >
        <Modal.Header>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>This will cancel all upcoming calls</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleModalSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Select
        id="dischargedPatient.Status"
        name="dischargedPatient.Status"
        label="Status"
        defaultValue={data.dischargedPatient.ProgramStatus}
        options={statusOptions}
        onChange={(val) => {
          if (!allSurveyCallsCompleted && isEndTransition(data.dischargedPatient.ProgramStatus, val)) {
            handleModalShow(val);
          } else {
            dispatch(updateDischargedPatientStatus(
              data.dischargedPatient.DischargePatientId, val,
            ));
          }
        }}
      />
    </>
  );
}
