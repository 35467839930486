import React from 'react';
import moment from 'moment';
import {
  Alert, Card, Col, Row,
} from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { DateInput } from 'components/FormControls';
import SubmitButton from 'components/FormControls/SubmitButton'
import { useSelector } from 'react-redux';
import {
  EventType, ScheduleMode, TemplateOrEvent, VoiceMessageTypeEnum,
} from 'types/IEvent';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { RootState } from 'types/rootState';
import { IUserInfoState } from 'reducers/IUserInfoState';
import { CustomRRuleGenerator } from 'components/EventTemplateForm/CustomRRuleGenerator';
import { ControlledTimePicker } from 'components/FormControls/TimePicker';
import 'moment-timezone';

type Props = {
  data: TemplateOrEvent
};

const scheduleModeOptions = [
  { label: 'Once', value: ScheduleMode.Once },
  { label: 'More than once', value: ScheduleMode.Schedule },
];

const EventScheduler = ({
  data,
}: Props): JSX.Element => {
  const {
    control, errors, getValues, watch, setValue,
  } = useFormContext();
  const formValues = {
    ...data,
    ...getValues(),
  };
  const onDemand = (formValues.VoiceMessageType === VoiceMessageTypeEnum.Both
    || formValues.VoiceMessageType === VoiceMessageTypeEnum.OnDemand);
  const showScheduleMode = ScheduleMode.Schedule === watch('SendMode');

  const userInfo = useSelector((state: RootState): IUserInfoState => state.UserInfo);
  const acctDateTimeNow = moment().tz(userInfo.accountTimezone);
  const saving = useSelector((state: RootState) => state.Events.loading);
  const isUrlLinkSurvey: boolean = data.EventType === EventType.URLLINKSURVEY;

  React.useEffect(() => {
    if (showScheduleMode && getValues('RRule') === '') {
      setValue('RRule', 'FREQ=DAILY')
    }
  }, [getValues, setValue, showScheduleMode])

  return (
    <div className="event-scheduler">
      {isUrlLinkSurvey && (
        <>
          {' '}
          <h3 className="pb-2">
            {formValues.Name}
          </h3>
          <p className="mb-2">{formValues.Description}</p>
        </>
      )}
      <p>{formValues.Location}</p>
      {(errors?.SendTime || errors?.StartDate) && (
        <Alert variant="danger">
          Please schedule event for a time in the future
        </Alert>
      )}
      {(errors?.RRule) && (
        <Alert variant="danger">
          Please select at least one day when repeating weekly
        </Alert>
      )}
      <div>
        <div className="mt-2">
          <Card>
            <Card.Header as="h4">
              Schedule
            </Card.Header>
            <Card.Body>
              <Row id="schedule">
                <Col className="col-md-2">
                  <strong>Send date/time:  </strong>
                </Col>
                <Col
                  className="d-flex col-md-7"
                >
                  <DateInput
                    id="StartDate"
                    control={control}
                    defaultValue={
                      acctDateTimeNow.format('yyyy-MM-dd')
                    }
                    format="yyyy-MM-DD"
                    name="StartDate"
                    rules={{
                      required: 'The start date is required.',
                      validate: (val: string) => moment(val, 'yyyy-MM-DD')
                        >= moment(acctDateTimeNow.format('yyyy-MM-DD')),
                    }}
                  />
                  <ControlledTimePicker
                    name="SendTime"
                    validate={(val: any) => {
                      if (moment(getValues('StartDate'), 'yyyy-MM-DD') > acctDateTimeNow) {
                        return true;
                      }
                      return val > moment().tz(userInfo.accountTimezone).format('HH:mm');
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-md-2">
                  <strong>Scheduling mode: </strong>
                </Col>
                <Col>
                  <Controller
                    control={control}
                    defaultValue={ScheduleMode.Once}
                    name="SendMode"
                    render={(props) => (
                      <RadioGroup row {...props}>
                        {scheduleModeOptions.map(({ label, value }) => (
                          <FormControlLabel
                            key={value}
                            value={value}
                            control={<Radio />}
                            label={label}
                          />
                        ))}
                      </RadioGroup>
                    )}
                    rules={{ required: true }}
                  />
                </Col>
              </Row>
              {showScheduleMode && (
                <Row>
                  <Col>
                    <div className="mt-2">
                      <CustomRRuleGenerator control={control} />
                    </div>
                  </Col>
                </Row>
              )}
              {onDemand && !showScheduleMode && !isUrlLinkSurvey && (
                <Row className="d-flex align-items-center">
                  <Col className="col-md-2">
                    <strong>All Day On Demand:</strong>
                  </Col>
                  <Col>
                    <Controller
                      control={control}
                      name="ExpirationTime"
                      render={({ onChange, onBlur, value }) => (
                        <input
                          type="checkbox"
                          id="ExpirationTime"
                          name="ExpirationTime"
                          onBlur={onBlur}
                          onChange={(e) => onChange(e.target.checked ? '23:59' : null)}
                          checked={value === '23:59'}
                        />
                      )}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col style={{ textAlign: 'right' }}>
                  <SubmitButton
                    label="Save"
                    savingLabel="Saving..."
                    className="ml-4"
                    saveComplete={!saving}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export { EventScheduler };
