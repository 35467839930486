import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import { IPatientFormData } from '../../types/IPatientFormData';
import { updateProfile } from '../../reducers/Profiles';
import DischargedPatientContactForm from './DischargedPatientContactForm';
import { formatPhoneNumberString } from '../../lib/formatters/phoneNumber';

interface IProps{
  data: IPatientFormData
}
export function PatientProfileViewAndEdit({ data }:IProps):React.ReactElement {
  const phonePlaceHolder = '(___) ___-____';
  const [showForm, setShowForm] = useState(false);
  const methods = useForm<IPatientFormData>({ mode: 'onBlur' });
  const {
    reset, handleSubmit,
  } = methods;
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);
  const onSubmitClick = (formData: IPatientFormData): void => {
    segmentAnalyticsTrack(trackActions.editDischargedPatient());
    const patientProfile = {
      ...formData.patientProfile,
      LandLine: phonePlaceHolder === formData.patientProfile.LandLine ? '' : formData.patientProfile.LandLine,
      MobilePhone: phonePlaceHolder === formData.patientProfile.MobilePhone ? '' : formData.patientProfile.MobilePhone,
    }
    dispatch(updateProfile(patientProfile, () => {
      setShowForm(false);
    }))
  };
  return (
    <>
      <div
        className="section-header col d-flex justify-content-between align-items-center"
      >
        <h6>
          Patient Information
        </h6>
        {!showForm && (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              setShowForm(true);
            }}
          >
            Edit
          </button>
        )}
      </div>
      {showForm
            && (
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(onSubmitClick)}
                  className="form ProfilesForm col-12 pb-2"
                >
                  <DischargedPatientContactForm data={data} formPrefix="patientProfile" />
                  <Button type="submit">Save</Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      reset(data);
                      setShowForm(false)
                    }}
                  >
                    Cancel

                  </Button>
                </form>
              </FormProvider>
            )}
      {!showForm && (
        <>
          <div className="col">
            <strong>Patient Landline:</strong>
            {' '}
            {formatPhoneNumberString((data?.patientProfile?.LandLine))}
          </div>
          <div className="col">
            <strong>Patient Mobile:</strong>
            {' '}
            {formatPhoneNumberString((data?.patientProfile?.MobilePhone))}
          </div>
          <div className="col">
            <strong>Patient Language:</strong>
            {' '}
            {`${data?.patientProfile?.Language?.charAt(0).toUpperCase()}${data?.patientProfile?.Language?.slice(1)}`}
          </div>
        </>
      )}
    </>
  );
}
