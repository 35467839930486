import React, {
  useState, useEffect, useRef, forwardRef, FC, ReactElement, CSSProperties, useCallback, useMemo,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import FullCalendar from '@fullcalendar/react';
import { CalendarApi, EventApi } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { Link } from 'react-router-dom';
import { clearEventListeners, getPositionFromEvent } from 'lib/helpers/dom';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import { ICalendarEvent } from 'types/ICalendarEvent';
import { ScheduleMode } from 'types/IEvent';
import useClickOutside from 'use-click-outside';
import {
  selectFullCalendarEvents,
  deleteEventSeries,
  deleteEventInstance,
  getCalendarEvents,
  getEventInstanceSummary,
  selectEventSummary,
  setAllDayOnDemand,
  stopEventSeries,
  setCalendarViewPeriod,
  setCalendarViewType,
  clearCalendarFilterKeys,
  toggleCalendarFilterKey,
} from 'reducers/Calendar';
import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import {
  Button, Modal, OverlayTrigger, Popover,
} from 'react-bootstrap';
import {
  Controller, FormProvider, useForm,
} from 'react-hook-form';
import moment from 'moment';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { IUserInfoState } from 'reducers/IUserInfoState';
import { CalendarFilters } from 'types/ICalendarDay';
import {
  CalendarFilterKey, FullCalendarViewType, getFullCalendarViewName,
} from 'types/ICalendarView';
import { useRefSelector } from 'hooks/useRefSelector';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import Checkbox from 'components/Inputs/Checkbox';
import DateRangeInput from 'components/Inputs/DateRangeInput';
import _capitalize from 'lodash/capitalize';
import { DistributionList } from './EventTemplateForm/DistributionList';
import { DeleteActionModal } from './DeleteActionModal';
import { ViewButton } from './FullCalendarViewButton';
import { ExportPdfModal } from './ExportPdfModal';
import { useUserTypeTranslation } from '../hooks/useUserTypeTranslation';

const EVENT_TYPES: { [key: string]: string } = {
  Announcement: 'Announcements',
  Transactional: 'Transactional',
  Compliance: 'compliance',
  Lifedate: 'lifedate',
  SimpleSurvey: 'SimpleSurvey',
  UrlLinkSurvey: 'UrlLinkSurvey',
}

type CalendarPopoverProps = OverlayInjectedProps & {
  eventIndex: string
  datetime: string
};
const CalendarPopover = forwardRef<HTMLDivElement, CalendarPopoverProps>(
  ({
    popper, style, datetime, eventIndex, ...props
  }, ref) => {
    const eventsById = useSelector(
      (rootState: RootState) => rootState.Calendar.byIds,
    );
    const summaryByIds = useSelector(selectEventSummary);

    const eventObject = eventsById[eventIndex];
    const eventSummary = summaryByIds[eventIndex] || [];

    if (!eventObject) {
      return null;
    }

    return (
      <Popover ref={ref} id={eventIndex} style={style as any} {...props}>
        <Popover.Content>
          <p className="m-0">
            <strong>Event ID:</strong>
            {' '}
            {eventObject.EventId}
          </p>
          <p className="m-0">
            <strong>Event Name:</strong>
            {' '}
            {eventObject.EventName}
          </p>
          <p className="m-0">
            <strong>Time:</strong>
            {' '}
            {datetime}
          </p>
          <p className="m-0">
            <strong>Message Type:</strong>
            {' '}
            {eventObject.MessageType}
          </p>
          {eventObject.IsOndemand && (
            <p className="m-0">
              <strong>All Day On Demand:</strong>
              {' '}
              {eventObject.ExpirationTime === '23:59' ? 'Yes' : 'No'}
            </p>
          )}

          {eventSummary.map((summary) => (
            <p className="m-0">
              <strong>
                {summary.Channel}
                :
              </strong>
              {' '}
              sent:
              {summary.MessagesSent}
              /
              {summary.MessagesSent + summary.MessagesFailed}
            </p>
          ))}
        </Popover.Content>
      </Popover>
    );
  },
);

type FormProp = {
  GroupIds: number[]
  ProfileIds: string[]
};

/**
 * Calendar - Custom wrapper around @fullcalender/react FullCalendar component.
 *
 * This component re-implements the calendar header so we can hook into and
 * have full control over the button click actions. This allows the calendar
 * to play nicely with redux as the time when `onDateRangeChange` is called can
 * be controlled.
 *
 * The FullCalender `events` as a function prop is called on each and every
 * render, regardless if the date range changes and also expects a call to the
 * success callback. This pattern does not work well with redux since the store
 * should be the source of truth and should just provide an array of Events.
 *
 * If more buttons / custom calendar controls that can be done here. Providing
 * a callback to the `calendarEvent` function will give access to the FullCalendar
 * JS API for the current instance.
 */
const Calendar: FC = () => {
  const isLoading = useSelector((state: RootState) => state.Calendar.loading);
  const [showExportPdfModal, setShowExportPdfModal] = React.useState<boolean>(false);
  const [eventToExport, setEventToExport] = React.useState<ICalendarEvent & { ocurrenceDate: string } | null>(null);
  const [deleteHandler, deleteHandlerSetter] = useState<undefined|({handler:()=>Promise<void>})>(undefined);
  const canReadCalendar = useHasPermissions('Calendar:Read');
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);

  const canExportAsPdf = canReadCalendar || isAdmin || isSuper;

  const setDeleteHandler = (x:()=>Promise<void>) => {
    deleteHandlerSetter({
      handler: async () => {
        await x();
        deleteHandlerSetter(undefined);
      },
    });
  };

  const calendarFilter = useSelector(
    (rootState: RootState) => rootState.Calendar.calendarFilters,
  );

  const calendarView = useSelector(
    (rootState: RootState) => rootState.Calendar.calendarView,
  );
  const initialDate = useMemo(
    () => moment().endOf(calendarView.fcViewPeriod as 'day' | 'week' | 'month').toDate(),
    [calendarView.fcViewPeriod],
  );

  const dispatch = useDispatch();
  const fcViewName = getFullCalendarViewName(calendarView, isMobile);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const calendarRef = useRef<FullCalendar | null>(null);
  const [calendarTitle, setCalendarTitle] = useState([
    moment().startOf(calendarView.fcViewPeriod as 'day' | 'week' | 'month').format('MMM D, YYYY'),
    moment().endOf(calendarView.fcViewPeriod as 'day' | 'week' | 'month').format('MMM D, YYYY'),
  ].join(' - '));

  const [currentEvent, setCurrentEvent] = useState<{
    eventId: string
    date: string
    top: number
    left: number
    event: ICalendarEvent
  }>();

  const eventsById = useSelector(
    (rootState: RootState) => rootState.Calendar.byIds,
  );

  const formMethods = useForm<FormProp>({
    shouldUnregister: false,
    defaultValues: {
      ProfileIds: calendarFilter?.profileIds || [],
      GroupIds: calendarFilter?.groupIds || [],
    },
  });

  // useRefSelector to maintain same array ref when event values haven't changed
  const events = useRefSelector(
    selectFullCalendarEvents(calendarView?.filterKeys),
    (left, right) => left?.length === right?.length && JSON.stringify(left) === JSON.stringify(right),
  );

  const disableNavigation = isLoading;
  // const calendarTitle = calendarRef.current?.getApi()?.view?.title;

  useEffect(() => {
    function removeMenuOnScroll() {
      window.removeEventListener('scroll', removeMenuOnScroll);
      setCurrentEvent(null);
    }
    window.addEventListener('scroll', removeMenuOnScroll, true);
    return () => window.removeEventListener('scroll', removeMenuOnScroll);
  }, []);

  const calendarEvent = useCallback((event?: (calendar: CalendarApi) => void): void => {
    if (!calendarRef.current) return;
    const calendar = calendarRef.current.getApi();
    if (event) {
      event(calendar);
    }
    const { view } = calendar;
    dispatch(
      getCalendarEvents({
        startDate: view.activeStart.toISOString(),
        endDate: view.activeEnd.toISOString(),
        groupIds: calendarFilter?.groupIds,
        profileIds: calendarFilter?.profileIds,
      }),
    );
  }, [calendarFilter?.groupIds, calendarFilter?.profileIds, dispatch]);

  useEffect(() => {
    calendarEvent();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    calendarEvent((calendar) => {
      calendar.changeView(fcViewName);
    });
  }, [calendarEvent, fcViewName]);

  const menuRef = useRef<HTMLUListElement>();
  useClickOutside(menuRef, () => {
    setCurrentEvent(null);
  });

  const [deleteEventMode, setDeleteEventMode] = useState<string>();
  const [deleteOrEventMode, setDeleteOrEventMode] = useState<string>('delete');
  const [deleteButtonName, setDeleteButtonName] = useState<string>('Delete');
  const userInfo = useSelector((state: RootState) : IUserInfoState => state.UserInfo);
  const acctTimezone = userInfo.accountTimezone;
  const isUrlLinkSurvey = currentEvent?.event?.EventType === EVENT_TYPES.UrlLinkSurvey;
  const onContextOpen = useCallback((mouseEvent: MouseEvent, eventObject: EventApi) => {
    mouseEvent.preventDefault();
    const dimension = getPositionFromEvent(mouseEvent);
    Object.defineProperty(dimension, 'top', { writable: true });

    const event = eventsById[
      `${eventObject.id}-${moment(eventObject.start).tz(acctTimezone).format('YYYY-MM-DD')}`
    ];
    dimension.top = calculatePositionY(event, mouseEvent, dimension.top);

    setCurrentEvent({
      eventId: eventObject.id,
      left: dimension.left,
      date: moment(eventObject.start).tz(acctTimezone).format('YYYY-MM-DD'),
      top: dimension.top,
      event,
    });
  }, [acctTimezone, eventsById]);

  const calculatePositionY = (event: ICalendarEvent, mouseEvent: MouseEvent, top: number) : number => {
    const menuHeight = getContextMenuHeight(event);
    const heightGap = window.innerHeight - mouseEvent.clientY - menuHeight;

    return heightGap < 0 ? top - Math.abs(heightGap) : top;
  }

  const getContextMenuHeight = (event: ICalendarEvent) => {
    const heightPerItem = 40;
    let menuItemCount = 0;

    if (event?.SendMode === ScheduleMode.Schedule
      && event?.EventType === EVENT_TYPES.Announcement) {
      menuItemCount += 1; // edit entire series
      if (!isPastEvent(event?.EventSendDate)
        && event?.EventType === EVENT_TYPES.Announcement) {
        menuItemCount += 1; // Edit this Occurrence
      }
      if (!isPastEvent(currentEvent?.event?.EventSendDate)) {
        menuItemCount += 1; // Delete this occurrence
      }
      menuItemCount += 1; // stop this series
    }

    if (event?.SendMode === ScheduleMode.Once) {
      if (shouldAllowEditOrDelete(event?.EventSendDate,
        event?.EventType)) {
        menuItemCount += 1; // edit
      }
      if (shouldAllowEditOrDelete(event?.EventSendDate,
        event?.EventType)) {
        menuItemCount += 1; // delete this occurence
      }
    }

    if (event) {
      if (isPastEvent(event?.EventSendDate)) {
        menuItemCount += 1; // view report
      }

      menuItemCount += 1; // View Message Content

      if (canExportAsPdf && isPastEvent(event?.EventSendDate)) {
        menuItemCount += 1; // export as PDF
      }
    }

    if (event?.IsOndemand && event?.SendMode === ScheduleMode.Once) {
      menuItemCount += 1; // turn on / off all day ondemand
    }

    return menuItemCount * heightPerItem;
  }

  const shouldAllowEditOrDelete = (
    eventSendDate: string, eventType: string,
  ) => !isPastEvent(eventSendDate) && eventType !== EVENT_TYPES.SimpleSurvey && eventType !== EVENT_TYPES.UrlLinkSurvey

  let contextMenuClass = 'calendar-event-menu';
  const contextMenuStyles: CSSProperties = {
    padding: '0px',
    margin: '0px',
  };
  if (currentEvent) {
    contextMenuStyles.top = `${currentEvent.top}px`;
    contextMenuStyles.left = `${currentEvent.left}px`;
  } else {
    contextMenuClass += ' d-none';
  }

  const isPastEvent = (eventSendDate: string): boolean => {
    const eventDate = moment(eventSendDate);
    const dtNow = moment().tz(acctTimezone);
    // use new datetime now to do the comparison instead of using the currentDate from component,
    // since it will be reset after use selected date from the calendar control.
    if (eventDate.format() <= dtNow.format()) {
      return true;
    }
    return false;
  };
  const renderDeleteOrStopAction = (): ReactElement => (
    <>
      {!isPastEvent(currentEvent?.event?.EventSendDate) && (
        <li
          role="presentation"
          onClick={() => {
            setDeleteEventMode('occurrence')
            trackingEventContextMenu('delete occurrence')
            setDeleteHandler(async () => {
              await dispatch(
                deleteEventInstance(
                  Number(currentEvent.eventId),
                  currentEvent.date,
                ),
              );
              setCurrentEvent(null);
            });
          }}
        >
          <Button className="p-0 text-primary" variant="link">
            Delete this occurrence
          </Button>
        </li>
      )}

      <li
        role="presentation"
        onClick={() => {
          setDeleteOrEventMode('stop')
          setDeleteButtonName('Stop')
          setDeleteEventMode('series')
          trackingEventContextMenu('stop series')
          setDeleteHandler(async () => {
            await dispatch(stopEventSeries(Number(currentEvent.eventId),
              moment(currentEvent.event.EventSendDate).toDate(),
              () => {}));
            const { ProfileIds, GroupIds } = formMethods.getValues();
            dispatch(
              getCalendarEvents({
                startDate: calendarFilter?.startDate,
                endDate: calendarFilter?.endDate,
                groupIds: GroupIds,
                profileIds: ProfileIds,
              }),
            );
          })
          setCurrentEvent(null);
        }}
      >
        <Button className="p-0 text-primary" variant="link">
          Stop this series
        </Button>
      </li>
    </>
  );

  const renderRemoveFilter = (): ReactElement => (
    <div className="d-flex pt-2 pb-2 float-right">
      <span className="filter-result">Events are filtered.</span>
      {' '}
      &nbsp;
      <a
        className="ml-1"
        href="/calendar"
        onClick={(e) => {
          e.preventDefault();
          formMethods.setValue('GroupIds', []);
          formMethods.setValue('ProfileIds', []);
          dispatch(clearCalendarFilterKeys());
          dispatch(
            getCalendarEvents({
              startDate: calendarFilter?.startDate,
              endDate: calendarFilter?.endDate,
              groupIds: [],
              profileIds: [],
            }),
          );
        }}
      >
        Remove Filter
      </a>
    </div>
  );

  const hasFilter = Boolean(
    calendarFilter?.groupIds?.length
      || calendarFilter?.profileIds?.length
      || calendarView?.filterKeys?.length > 0,
  );

  // let initialDate: string;
  // if (calendarFilter?.startDate && calendarFilter?.endDate) {
  //   const startDate = Date.parse(calendarFilter.startDate);
  //   const endDate = Date.parse(calendarFilter.endDate);
  //   const midpoint = new Date((startDate + endDate) / 2);
  //   initialDate = midpoint.toISOString();
  // }

  const trackingEventContextMenu = (item: string) => {
    segmentAnalyticsTrack(trackActions.calendarContextMenu({
      menuType: item,
    }));
  };

  const contentOverlayCallback = useCallback((args) => {
    const eventIndex = `${args.event.id}-${
      moment(args.event.start).tz(acctTimezone).format('YYYY-MM-DD')
    }`;
    const eventObject = eventsById[eventIndex];
    const eventType = eventObject.EventType?.toLocaleLowerCase();
    const tryFetchingSummary = () => {
      const isEventPast = moment(eventObject.EventSendDate) < moment().tz(acctTimezone);
      if (isEventPast) {
        dispatch(
          getEventInstanceSummary(
            eventObject.EventId,
            moment(eventObject.EventSendDate).format('YYYY-MM-DD'),
          ),
        );
      }
    };
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={(
          <CalendarPopover
            eventIndex={eventIndex}
            datetime={moment(args.event.start).tz(acctTimezone).format('MM/DD/yyyy h:mm a')}
          />
        )}
      >
        {({ ref, ...triggerHandler }) => (
          <div
            ref={ref}
            {...triggerHandler}
            onMouseOver={(event) => {
              tryFetchingSummary();
              (triggerHandler as any)?.onMouseOver(event);
            }}
            onFocus={(event) => {
              tryFetchingSummary();
              (triggerHandler as any)?.onFocus(event);
            }}
          >
            {(eventType === EVENT_TYPES.Compliance || eventType === EVENT_TYPES.Lifedate)
              && <FontAwesomeIcon icon="calendar-check" className="float-left" />}
            {args.event.title}
          </div>
        )}
      </OverlayTrigger>
    );
  }, [acctTimezone, dispatch, eventsById]);

  const calendarComponent = useMemo(() => (
    <FullCalendar
      initialView={fcViewName}
      initialDate={initialDate}
      plugins={[timeGridPlugin, dayGridPlugin, listPlugin, momentTimezonePlugin]}
      slotDuration="00:15:00"
      slotLabelFormat={{ hour: 'numeric', minute: '2-digit', meridiem: 'short' }}
      allDaySlot={false}
      scrollTime="09:00:00"
      expandRows
      timeZone={acctTimezone}
      displayEventTime={false}
      slotLabelInterval="00:30"
      defaultTimedEventDuration="00:15"
      headerToolbar={false}
      nowIndicator
      events={events}
      ref={(element) => {
        calendarRef.current = element;
      }}
      eventContent={contentOverlayCallback}
      eventDidMount={(eventProps) => {
        eventProps.el.addEventListener('contextmenu', (contextEvent) => {
          onContextOpen(contextEvent, eventProps.event);
        });
      }}
      eventWillUnmount={(eventProps) => {
        clearEventListeners(eventProps.el);
      }}
    />
  ), [fcViewName, initialDate, acctTimezone, events, contentOverlayCallback, onContextOpen]);
  const calendarGoDate = (dateStr: string) => {
    setCalendarTitle(dateStr);
    const [, endDateStr] = dateStr.split(' - ');
    if (endDateStr) {
      const endDate = moment(endDateStr, 'MMM D, YYYY');
      if (endDate.isValid() && endDate.year() >= 1970) {
        calendarEvent((calendar) => {
          calendar.gotoDate(moment(endDateStr, 'MMM D, YYYY').format('YYYY-MM-DD'));
        });
      }
    }
  };

  const translateUserType = useUserTypeTranslation();

  return (
    <div className={`Calendar ${isMobile ? 'mobile' : ''}`}>
      <ExportPdfModal
        show={showExportPdfModal}
        onHide={() => {
          setShowExportPdfModal(false);
          setEventToExport(null);
        }}
        events={eventToExport}
      />
      <DeleteActionModal
        isOpen={deleteHandler !== undefined}
        onCancel={() => { deleteHandlerSetter(undefined); }}
        onSuccess={() => { deleteHandler.handler() }}
        title={`${_capitalize(deleteOrEventMode)}?`}
        buttonName={deleteButtonName}
      >
        Are you sure you wish to
        {' '}
        {deleteOrEventMode}
        {' '}
        this
        {' '}
        {deleteEventMode}
        ?
      </DeleteActionModal>
      <div className="calendar-header">
        <DateRangeInput
          value={calendarTitle}
          onChange={(dateStr) => {
            calendarGoDate(dateStr);
          }}
          format="MMM D, YYYY"
          startDateOffset={(d) => d.startOf(calendarView.fcViewPeriod as 'day' | 'week' | 'month')}
          endDateOffset={(d) => d.endOf(calendarView.fcViewPeriod as 'day' | 'week' | 'month')}
          navigationUnit={calendarView.fcViewPeriod as 'day' | 'week' | 'month'}
          id="StartDate"
          name="calendarRange"
          className="mb-0"
          accountTimezone={acctTimezone}
        />
        <Button
          className="p-2"
          variant="link"
          onClick={() => {
            const unit = calendarView.fcViewPeriod as 'day' | 'week' | 'month';
            const dateStr = [
              moment().tz(acctTimezone).startOf(unit).format('MMM D, YYYY'),
              moment().tz(acctTimezone).endOf(unit).format('MMM D, YYYY'),
            ].join(' - ');
            calendarGoDate(dateStr);
          }}
        >
          Today
        </Button>
        <div className="calendar-view">
          <div className="btn-group">
            {['day', 'week', 'month'].map((view) => (
              <ViewButton
                key={view}
                viewName={view}
                viewValue={view}
                currentView={calendarView.fcViewPeriod}
                disableNavigation={disableNavigation}
                onViewClick={() => {
                  dispatch(setCalendarViewPeriod(view));
                  const [, endDateStr] = calendarTitle.split(' - ');
                  if (endDateStr) {
                    const newCalendarTitle = [
                      moment(endDateStr, 'MMM D, YYYY').startOf(view as 'day' | 'week' | 'month').format('MMM D, YYYY'),
                      moment(endDateStr, 'MMM D, YYYY').endOf(view as 'day' | 'week' | 'month').format('MMM D, YYYY'),
                    ].join(' - ');
                    setCalendarTitle(newCalendarTitle);
                    const endDate = moment(endDateStr, 'MMM D, YYYY');
                    if (endDate.isValid() && endDate.year() >= 1970) {
                      calendarEvent((calendar) => {
                        calendar.gotoDate(moment(endDateStr, 'MMM D, YYYY').format('YYYY-MM-DD'));
                      });
                    }
                  }
                }}
              />
            ))}
          </div>
          <button
            type="button"
            className="calendar-button ml-2"
            onClick={() => setIsFilterModalOpen(true)}
          >
            <span className="Calendar__filter-icon">
              <FontAwesomeIcon icon="filter" />
              {hasFilter && <FontAwesomeIcon icon="plus" />}
            </span>
          </button>
        </div>
      </div>
      {hasFilter && renderRemoveFilter()}

      <Modal
        show={isFilterModalOpen}
        onHide={() => setIsFilterModalOpen(false)}
        className="DeleteActionButtonModal"
        backdrop="static"
        centered
        size="xl"
      >
        <FormProvider {...formMethods}>
          <DistributionList
            disableValidation
            showProfiles
            showGroups="all"
          />
          <Modal.Body>
            <Button
              type="button"
              variant="secondary"
              className="mt-4 mr-4"
              onClick={() => {
                setIsFilterModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="secondary"
              className="mt-4 mr-4"
              onClick={() => {
                formMethods.setValue('GroupIds', []);
                formMethods.setValue('ProfileIds', []);
              }}
            >
              Clear Filters
            </Button>
            <Button
              type="button"
              className="btn btn-primary mt-4"
              onClick={formMethods.handleSubmit((formData) => {
                setIsFilterModalOpen(false);
                segmentAnalyticsTrack(trackActions.calendarFilter({
                  startDate: calendarFilter?.startDate,
                  endDate: calendarFilter?.endDate,
                  groups: formData.GroupIds?.length,
                  profiles: formData.ProfileIds?.length,
                }));
                dispatch(
                  getCalendarEvents({
                    startDate: calendarFilter?.startDate,
                    endDate: calendarFilter?.endDate,
                    groupIds: formData.GroupIds,
                    profileIds: formData.ProfileIds,
                  }),
                );
              })}
            >
              Apply Filter
            </Button>
          </Modal.Body>

        </FormProvider>
      </Modal>

      <p className="pt-3 text-center loading-text">{!isLoading ? '\u00A0' : 'Loading...'}</p>
      <div className="CalendarFilter">
        <Controller
          control={formMethods.control}
          name="showListView"
          defaultValue={calendarView.fcViewType === FullCalendarViewType.List}
          render={({ onChange, onBlur, value }) => (
            <Checkbox
              className="mr-2 align-self-center"
              name="showListView"
              label="List View"
              checked={value}
              onBlur={onBlur}
              onChange={(e) => {
                onChange(e.target.checked);
                dispatch(setCalendarViewType(
                  e.target.checked
                    ? FullCalendarViewType.List
                    : FullCalendarViewType.Time,
                ));
              }}
            />
          )}
        />
        <div className="calendar-key">
          <span className="label">SHOW:</span>
          <Checkbox
            className="color-key emergency"
            name="showEmergency"
            label="Emergency"
            checked={!calendarView?.filterKeys?.includes(CalendarFilterKey.Emergency)}
            onChange={(e) => dispatch(toggleCalendarFilterKey(CalendarFilterKey.Emergency))}
          />
          <Checkbox
            className="color-key ondemand"
            name="showOnDemand"
            label="On Demand"
            checked={!calendarView?.filterKeys?.includes(CalendarFilterKey.OnDemand)}
            onChange={(e) => dispatch(toggleCalendarFilterKey(CalendarFilterKey.OnDemand))}
          />
          <Checkbox
            className="color-key automatic"
            name="showAutomatic"
            label="Automatic"
            checked={!calendarView?.filterKeys?.includes(CalendarFilterKey.Automatic)}
            onChange={(e) => dispatch(toggleCalendarFilterKey(CalendarFilterKey.Automatic))}
          />
          <Checkbox
            className="color-key staff"
            name="showStaff"
            label={translateUserType('staff')}
            checked={!calendarView?.filterKeys?.includes(CalendarFilterKey.Staff)}
            onChange={(e) => dispatch(toggleCalendarFilterKey(CalendarFilterKey.Staff))}
          />
          <Checkbox
            className="color-key seniors"
            name="showSeniors"
            label={translateUserType('member')}
            checked={!calendarView?.filterKeys?.includes(CalendarFilterKey.Senior)}
            onChange={(e) => dispatch(toggleCalendarFilterKey(CalendarFilterKey.Senior))}
          />
          <Checkbox
            className="color-key family"
            name="showFamily"
            label={translateUserType('family')}
            checked={!calendarView?.filterKeys?.includes(CalendarFilterKey.Family)}
            onChange={(e) => dispatch(toggleCalendarFilterKey(CalendarFilterKey.Family))}
          />
          <Checkbox
            className="color-key"
            name="showNoRecipients"
            label="No Recipients"
            checked={!calendarView?.filterKeys?.includes(CalendarFilterKey.NoRecipients)}
            onChange={(e) => dispatch(toggleCalendarFilterKey(CalendarFilterKey.NoRecipients))}
          />
        </div>
      </div>
      <div className="position-relative">
        {calendarComponent}
        <ul
          ref={menuRef}
          className={contextMenuClass}
          style={contextMenuStyles}
        >
          {currentEvent?.event?.SendMode === ScheduleMode.Schedule
            && (currentEvent?.event?.EventType === EVENT_TYPES.Announcement || isUrlLinkSurvey) && (
            <>
              {currentEvent?.event?.EventType === EVENT_TYPES.Announcement && (
                <li>
                  <Link
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      height: '100%',
                      padding: '.5rem',
                    }}
                    to={`/events/${currentEvent?.eventId}/edit`}
                    onClick={() => trackingEventContextMenu('edit entire Series')}
                  >
                    Edit entire Series
                  </Link>
                </li>
              )}
              {!isPastEvent(currentEvent?.event?.EventSendDate)
                && currentEvent?.event?.EventType === EVENT_TYPES.Announcement && (
                <li>
                  <Link
                    to={`/events/${currentEvent?.eventId}/occurrence/${currentEvent?.date}`}
                    onClick={() => trackingEventContextMenu('edit Occurrence')}
                  >
                    Edit this Occurrence
                  </Link>
                </li>
              )}
              {renderDeleteOrStopAction()}
            </>
          )}
          {currentEvent?.event?.SendMode === ScheduleMode.Once && (
            <>
              {shouldAllowEditOrDelete(currentEvent?.event?.EventSendDate,
                currentEvent?.event?.EventType) && (
                <li>
                  <Link
                    to={`/events/${currentEvent?.eventId}/edit`}
                    onClick={() => trackingEventContextMenu('edit')}
                  >
                    Edit
                  </Link>
                </li>
              )}
              {(shouldAllowEditOrDelete(currentEvent?.event?.EventSendDate,
                currentEvent?.event?.EventType) || isUrlLinkSurvey) && (
                <li
                  role="presentation"
                  onClick={() => {
                    setDeleteEventMode('occurrence')
                    trackingEventContextMenu('Delete')
                    setDeleteHandler(async () => {
                      await dispatch(deleteEventSeries(Number(currentEvent.eventId)));
                      setCurrentEvent(null);
                    });
                  }}
                >
                  <Button className="p-0 text-primary" variant="link">
                    Delete this occurrence
                  </Button>
                </li>
              )}
            </>
          )}

          {currentEvent?.event && (
            <>
              {isPastEvent(currentEvent?.event?.EventSendDate) && (
                <li>
                  <Link
                    to={`/events/${currentEvent?.eventId}/results/${currentEvent?.date}`}
                    onClick={() => trackingEventContextMenu('View Report')}
                  >
                    View Report
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to={`/events/${currentEvent?.eventId}/content/${currentEvent?.date}`}
                  onClick={() => trackingEventContextMenu('View Message Content')}
                >
                  View Message Content
                </Link>
              </li>
              {canExportAsPdf && isPastEvent(currentEvent?.event?.EventSendDate) && (
                <li>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setEventToExport({
                        ...currentEvent.event,
                        ocurrenceDate: currentEvent.date,
                      });
                      setShowExportPdfModal(true);
                    }}
                  >
                    Export as PDF
                  </Link>
                </li>

              )}
            </>
          )}
          {currentEvent?.event?.IsOndemand && currentEvent?.event?.SendMode === ScheduleMode.Once && (
            <AllDayOnDemandDisplay
              event={currentEvent.event}
              date={currentEvent.date}
              filters={{
                startDate: calendarFilter?.startDate,
                endDate: calendarFilter?.endDate,
                profileIds: formMethods.getValues('ProfileIds'),
                groupIds: formMethods.getValues('GroupIds'),
              }}
              closeContextMenu={() => setCurrentEvent(null)}
            />
          )}
        </ul>
      </div>
    </div>
  );
};

const AllDayOnDemandDisplay = ({
  event,
  date,
  filters,
  closeContextMenu,
}:{
  event: ICalendarEvent
  date: string
  filters: CalendarFilters
  closeContextMenu: () => void
}):React.ReactElement => {
  const dispatch = useDispatch();

  const {
    startDate,
    endDate,
    profileIds,
    groupIds,
  } = filters;

  const disableAllDayOnDemand = () => {
    dispatch(setAllDayOnDemand(false, event.EventId, date, {
      startDate,
      endDate,
      groupIds,
      profileIds,
    }));
    closeContextMenu();
  };

  const enableAllDayOnDemand = () => {
    dispatch(setAllDayOnDemand(true, event.EventId, date, {
      startDate,
      endDate,
      groupIds,
      profileIds,
    }));
    closeContextMenu();
  };

  return (
    <>
      {event.ExpirationTime && (
        <li>
          <Button onClick={disableAllDayOnDemand} className="p-0 text-primary" variant="link">
            Turn Off All Day On Demand
          </Button>
        </li>
      )}
      {!event.ExpirationTime && (
        <li>
          <Button onClick={enableAllDayOnDemand} className="p-0 text-primary" variant="link">
            Turn On All Day On Demand
          </Button>
        </li>
      )}
    </>
  )
};

export default Calendar;
