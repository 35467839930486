import React, {
  useState, useContext, FC, ReactElement,
} from 'react';
import ReactDropzone, { FileError } from 'react-dropzone';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import DeleteButton from 'components/DeleteButton';
import FileDragContext from 'lib/FileDragContext';

interface IDropzoneProps {
  onDropAccepted: (files: File[]) => void
  onFileRemove: (file: File) => void
  files: File[]
  rejectFiles?:IRejection[]
  multiple?: boolean
  accept?:string
  onDropRejected?:(e:any)=>void
  dragAndDropText: string
  children?:ReactElement
  validator?:(e:any)=>FileError
}
export interface IRejection{
  code: string
  message:string
}
export const Dropzone: FC<IDropzoneProps> = ({
  onDropAccepted,
  onFileRemove,
  files,
  rejectFiles,
  dragAndDropText,
  children,
  validator,
  ...props
}) => {
  const { isFileDraggedOnPage, setIsFileDraggedOnPage } = useContext(
    FileDragContext,
  );
  const [isFileOverZone, setIsFileOverZone] = useState<boolean>(false);
  let highlightStyle = { border: 'lightgray dotted' };
  if (isFileDraggedOnPage) {
    highlightStyle = { border: 'gray dotted' };
  }

  if (isFileOverZone) {
    highlightStyle = { border: 'black dotted' };
  }

  return (
    <div style={highlightStyle} className="text-center">
      <ReactDropzone
        onDropAccepted={(acceptedFiles) => {
          onDropAccepted(acceptedFiles);
          setIsFileOverZone(false);
          setIsFileDraggedOnPage(false);
        }}
        onDragEnter={() => {
          setIsFileOverZone(true);
        }}
        onDragOver={() => {
          setIsFileOverZone(true);
        }}
        onDragLeave={() => {
          setIsFileOverZone(false);
        }}
        validator={(file) => {
          const result = validator(file);
          if (result !== null) {
            return {
              code: result.code,
              message: result.message,
            };
          }
          return null;
        }}
        {...props}
      >
        {({ getRootProps, getInputProps }) => (
          <section className="dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <ul className="dropzone__file-list">
              {files.map((file) => (
                <li
                  key={`${file.name}`}
                >
                  <a
                    onClick={(event) => event.stopPropagation()}
                    download={file.name}
                    href={URL.createObjectURL(file)}
                    className="pr-4"
                  >
                    {file.name}

                  </a>
                  <DeleteButton
                    confirmationMessage="Are you sure you want to delete this attachment?"
                    handleDelete={() => onFileRemove(file)}
                    title="Delete this attachment?"
                    onClick={(e) => e.stopPropagation()}
                  />
                </li>
              ))}
              {rejectFiles?.map((file) => (
                <li
                  className="rejectFile"
                  key={`${file.code}`}
                >
                  {`File ${file.code} was rejected. `}
                  {file.message}
                </li>
              ))}
            </ul>
            <p>{dragAndDropText}</p>
            <Row className="justify-content-center">
              {children || (
                <Col md="auto">
                  <Button>Select Files</Button>
                </Col>
              )}
            </Row>
          </section>
        )}
      </ReactDropzone>
    </div>
  );
};
