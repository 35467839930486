import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getAllTags } from 'reducers/DischargedPatients';
import { RootState } from 'types/rootState';

const useTags = ():{tags:string[], loading:boolean, refreshTags:()=>void} => {
  const [shouldLoad, setShouldLoad] = useState<boolean>(true);
  const dispatch = useDispatch();
  const availableTags = useSelector((state:RootState) => state.DischargedPatients.tags);
  const loading = useSelector(
    (state:RootState) => state.DischargedPatients.loadingIndicators?.tags,
  );
  useEffect(() => {
    if (shouldLoad) {
      dispatch(getAllTags());
      setShouldLoad(false);
    }
  }, [availableTags, dispatch, shouldLoad]);
  return { tags: availableTags, loading, refreshTags: () => { dispatch(getAllTags()); } };
}

export default useTags;
