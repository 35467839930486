import { IProfile } from 'reducers/IProfile';
import { IDischargedPatient } from 'types/IDischargedPatient';

export function sortDischargedPatients(
  dischargedPatients: (IDischargedPatient & {wasInSearchResults?: boolean})[],
  profiles: { [key: string]: IProfile },
  sortDirection: string,
  property: string,
): (IDischargedPatient & {
    wasInSearchResults?: boolean
  })[] {
  if (dischargedPatients && dischargedPatients.length > 0 && profiles && Object.keys(profiles).length > 0) {
    const profileSort = ['FirstName', 'LastName'];
    const stringSort = ['Facility', 'DischargeDate'];
    const dateSort = ['NextAutoCallDate', 'NextManualCallDate', 'LastCallDate'];
    try {
      if (profileSort.includes(property)) {
        if (sortDirection === 'asc') {
          return dischargedPatients.sort(
            (a, b) => profiles[a.ProfileId][property].localeCompare(profiles[b.ProfileId][property]),
          );
        }
        return dischargedPatients.sort(
          (a, b) => profiles[b.ProfileId][property].localeCompare(profiles[a.ProfileId][property]),
        );
      }
      if (stringSort.includes(property)) {
        if (sortDirection === 'asc') {
          return dischargedPatients.sort(
            (a, b) => a[property].localeCompare(b[property]),
          );
        }
        return dischargedPatients.sort(
          (a, b) => b[property].localeCompare(a[property]),
        );
      }
      if (dateSort.includes(property)) {
        if (sortDirection === 'asc') {
          return dischargedPatients.sort(
            (a, b) => (a[property] <= b[property] || a[property] === null ? 1 : -1),
          );
        }
        return dischargedPatients.sort(
          (a, b) => (b[property] <= a[property] || a[property] === null ? 1 : -1),
        );
      }
    } catch (ex) {
      return dischargedPatients;
    }
  }
  return dischargedPatients;
}
