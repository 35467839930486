import React, { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet';
import { EventTemplateForm } from 'components/EventTemplateForm/EventTemplateForm';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { INITIAL_STATE, selectTemplateIsLoading } from 'reducers/Templates';
import { Spinner } from 'react-bootstrap';

import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { ITemplate, ITemplateExtendo } from 'types/IEvent';
import { useTemplateQuery } from 'components/hooks/useTemplateQuery';
import { RootState } from 'types/rootState';
import { useSaveTemplate } from 'hooks/useSaveTemplate';
import { useHasFeatures } from 'hooks/useHasFeatures';
import CommunityNameWarningModal from './CommunityNameWarningModal';
import { getTemplateClone } from './getTemplateClone';

export default function TemplatesCreatePage(): ReactElement {
  const [templateToSave, setTemplateToSave] = useState<ITemplateExtendo|null>(null);
  const [showCommunityWarningModal, setShowCommunityWarningModal] = useState<boolean>(false);
  const history = useHistory();
  const hasWriteMarketingPermission = useHasPermissions('Marketing:Edit');
  const { hasFeature: hasMarketingFeature, isLoading: isFeaturesLoading } = useHasFeatures('marketing-emails');
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const { pathname, state: { sourceTemplateId, sourceTemplateType, sourceTemplateAccountId } = {} } = useLocation
  <{ sourceTemplateId?: number, sourceTemplateType?: 'transactional'|'template', sourceTemplateAccountId?: number }>();

  const isMarketing = pathname.startsWith('/marketing-templates');

  const communityName = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.CommunityName);

  const sourceLangLoaded = useSelector(
    (state: RootState) => !!state.UserInfo?.userInfo?.AccountDetail?.InputLanguageDefaultValue)

  const isSaving = useSelector(selectTemplateIsLoading);

  const [sourceTemplate, setSourceTemplate] = React.useState<ITemplate|null>(null);

  useTemplateQuery(
    sourceTemplateId,
    sourceTemplateType,
    sourceTemplateAccountId,
    {
      onSuccess: (template) => {
        if (!template) {
          return;
        }
        setSourceTemplate(template);
      },
    },
  );

  const initialData = React.useMemo<ITemplate|null>(() => {
    if (!sourceTemplateId) {
      return isMarketing
        ? {
          ...INITIAL_STATE.selectedTemplate,
          IsMarketing: isMarketing,
        }
        : INITIAL_STATE.selectedTemplate;
    }

    if (!sourceTemplate) {
      return null;
    }

    return getTemplateClone(sourceTemplate);
  }, [sourceTemplate, sourceTemplateId, isMarketing]);

  const handleSaveTemplate = useSaveTemplate();

  const handleSubmit = (formData: ITemplateExtendo): void => {
    if (getShowCommunityNameWarning(formData)) {
      setTemplateToSave(formData);
      setShowCommunityWarningModal(true);
      return;
    }

    handleSaveTemplate(formData);
  };

  const getShowCommunityNameWarning = (formData:ITemplateExtendo) => {
    const {
      smsSelected, emailSelected, voiceSelected, submit,
    } = formData;

    if (submit === 'save-as-draft') {
      return false;
    }

    const voiceContentValue = formData.VoiceContent?.Content;
    const smsContentValue = formData.SMSContent?.Content
    const emailContentValue = formData.EmailContent?.Content
    const decodeEmailContentValue = emailContentValue ? atob(emailContentValue) : '';

    if (((voiceSelected && voiceContentValue && voiceContentValue?.indexOf('$$CommunityName$$') !== -1)
      || (smsSelected && smsContentValue && smsContentValue?.indexOf('$$CommunityName$$') !== -1)
      || (emailSelected && decodeEmailContentValue && decodeEmailContentValue?.indexOf('$$CommunityName$$') !== -1))
      && (communityName === null || communityName === '')) {
      return true;
    }
    return false;
  };

  const canWriteMarketing = (hasWriteMarketingPermission || isAdmin || isSuper) && hasMarketingFeature;

  if (isMarketing) {
    if (isFeaturesLoading) {
      return null;
    }
    if (!canWriteMarketing) {
      return <Redirect to="/" />
    }
  }

  const isLoading = !sourceLangLoaded || !initialData;
  const entityName = isMarketing
    ? 'Marketing Email'
    : 'Template';

  return (
    <div className="TemplatesEdit">
      <Helmet>
        <title>
          Create
          {' '}
          {entityName}
        </title>
      </Helmet>
      <div className="header-bar">
        <h1>
          Create
          {' '}
          {entityName}
        </h1>
      </div>
      {isLoading ? (
        <div
          className="loading-text d-flex align-items-center justify-content-center"
          role="status"
        >
          <Spinner
            animation="border"
            as="span"
            className="mr-2"
            style={{ width: '1.66em', height: '1.66em' }}
          />
          <span style={{ fontSize: '1.25em' }}>
            Loading...
          </span>
        </div>
      ) : (
        <>
          <EventTemplateForm
            isNew
            data={initialData}
            onSubmit={handleSubmit}
            onCancel={() => {
              history.push(isMarketing
                ? '/marketing-templates'
                : '/templates')
            }}
            allowPreview={false}
            enableGenerateSmartContent
          />
          <CommunityNameWarningModal
            entityName={entityName.toLowerCase()}
            showModal={showCommunityWarningModal}
            onHide={() => {
              setShowCommunityWarningModal(false);
            }}
            onSave={() => {
              setShowCommunityWarningModal(false);
              handleSaveTemplate(templateToSave);
            }}
            disableSavingButton={isSaving}
          />
        </>
      )}
    </div>
  );
}
