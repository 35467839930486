import React, { useEffect, useMemo, useState } from 'react';
import {
  Card, Row, Col, Form, Alert,
  Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  addTriggeredEvent, getGeneratorById, updateTriggeredEvent,
} from 'api/BroadcastFormAPI';
import { EnterpriseFormTriggeredEventModelEdit } from 'types/BroadcastForm';
import { Input, Select, TimeInput } from 'components/FormControls';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import TextArea from 'components/FormControls/Textarea';
import { IOptions } from 'components/Inputs/Select';
import { useEbfAccountEventsConfig, useGeneratorTriggeredEvents } from './EbfGeneratorTriggeredEventsTable';

const ModifyEbfTriggeredEventsPage: React.FC = () => {
  const history = useHistory();
  const { generatorId } = useParams<{ generatorId: string }>();
  const { eventId } = useParams<{ eventId: string }>();
  const maxContentLength = 400;

  const { data: triggeredEvents, isLoading: loadingEvents } = useGeneratorTriggeredEvents(parseInt(generatorId, 10));
  const existingEvent = triggeredEvents?.find((x) => `${x.TriggeredEventId}` === eventId);

  const { data: eventsConfig, isLoading: eventsConfigLoading } = useEbfAccountEventsConfig();
  const hasWarnings = (eventsConfig?.WarningMessages?.length ?? 0) > 0;
  const warningMessages = useMemo(() => eventsConfig?.WarningMessages?.join(', '), [eventsConfig?.WarningMessages]);

  const eventDateLabels: IOptions[] = useMemo(() => {
    if (!eventsConfig?.ConfiguredEventDates) return [];
    return Object.entries(eventsConfig.ConfiguredEventDates).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }, [eventsConfig?.ConfiguredEventDates]);

  const {
    handleSubmit, control, formState: { errors }, watch, setValue,
  } = useForm<EnterpriseFormTriggeredEventModelEdit>({
    defaultValues: {
      Message: existingEvent?.Message ?? 'Please complete this survey: $$SURVEY_URL$$',
      SendTimeLocal: existingEvent?.SendTimeLocal ?? '',
      TriggerField: existingEvent?.TriggerField ?? '',
      TriggerDayOffset: existingEvent?.TriggerDayOffset ?? 0,
    },
  });
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // Loading state

  const contentLength = watch('Message')?.length ?? 0;

  const onSubmit = async (formData: EnterpriseFormTriggeredEventModelEdit) => {
    if (!eventId) {
      // create new triggered event
      setIsSubmitting(true);
      try {
        await addTriggeredEvent(parseInt(generatorId, 10), formData);
        history.push(`/internal/ebfgenerators/${generatorId}/events?tab=automatic`)
        toast.success('Automatic event successfully updated');
      } catch (ex) {
        setError(ex?.response?.data.Message || 'Failed to update the automatic event.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      // update existing triggered event
      setIsSubmitting(true);
      try {
        await updateTriggeredEvent(parseInt(eventId, 10), formData);
        history.push(`/internal/ebfgenerators/${generatorId}/events?tab=automatic`)
        toast.success('Automatic event successfully updated');
      } catch (ex) {
        setError(ex?.response?.data.Message || 'Failed to update the automatic event.');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (existingEvent) {
      setValue('Message', existingEvent.Message);
      setValue('SendTimeLocal', existingEvent.SendTimeLocal);
      setValue('TriggerField', existingEvent.TriggerField);
      setValue('TriggerDayOffset', existingEvent.TriggerDayOffset);
    }
  }, [setValue, existingEvent, triggeredEvents]);

  const { data: generator, isLoading: loadingGenerator } = useSWR(
    ['EbfGenerator', generatorId],
    async () => {
      const response = await getGeneratorById(parseInt(generatorId, 10), {
        ignoreGlobalCatch: false,
      });
      return response;
    },
    {
      shouldRetryOnError: false,
    },
  );

  const title = !eventId
    ? `Create Automatic Event: ${generator?.SurveyName}`
    : `Update Automatic Event: ${generator?.SurveyName}`;

  const isPageLoading = loadingGenerator || loadingEvents || eventsConfigLoading;

  if (isPageLoading) {
    return (
      <div
        className="loading-text d-flex align-items-center justify-content-center"
        role="status"
      >
        <Spinner
          animation="border"
          as="span"
          className="mr-2"
          style={{ width: '1.66em', height: '1.66em' }}
        />
        <span style={{ fontSize: '1.25em' }}>
          Loading...
        </span>
      </div>
    );
  }

  if (eventId && !existingEvent) {
    return (
      <div className="EbfGeneratorEdit">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="header-bar">
          <h1>Not Found</h1>
        </div>
      </div>
    )
  }

  return (
    <div className="EbfGeneratorEdit">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="header-bar">
        <h1>{title}</h1>
      </div>
      <p>
        Automatically generate survey events for each subaccount
        and send to recipients based on Compliance/Life Date values.
        Recipients will be any profiles whose target date field = the current day + the day offset.
        Message Content must include $$SURVEY_URL$$.
      </p>
      {hasWarnings ? <p className="mt-4 text-danger">{warningMessages}</p> : null}
      {eventDateLabels.length === 0 ? (
        <p className="mt-4 text-danger">
          No Compliance or life dates have been configured
        </p>
      ) : null}
      <Card className="overflow-visible flex-direction-row">
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs="12">
                <Form.Group>

                  <TextArea
                    id="Message"
                    name="Message"
                    label="Message"
                    required
                    control={control}
                    maxLength={maxContentLength}
                    rules={{
                      required: 'Message is required',
                      maxLength: {
                        value: maxContentLength,
                        message: `Message must be ${maxContentLength} characters or less`,
                      },
                      validate: (val: string) => val.trim() !== '' || 'Message cannot be empty or whitespace',
                    }}
                    errors={errors.Message?.message}
                  />
                  <span
                    className="content-length"
                    style={{ float: 'right' }}
                  >
                    {`${contentLength}/${maxContentLength}`}
                  </span>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="6">
                <Form.Group>
                  <TimeInput
                    id="SendTimeLocal"
                    name="SendTimeLocal"
                    label="Local Send Time"
                    control={control}
                    rules={{
                      required: 'Send Time is required',
                    }}
                    errors={errors.SendTimeLocal?.message}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <Select
                    id="TriggerField"
                    name="TriggerField"
                    label="Automatic Event Type"
                    placeholder="Select Automatic event field"
                    required
                    control={control}
                    options={eventDateLabels}
                    errors={errors.TriggerField?.message}
                    rules={{
                      required: 'Automatic event type is required',
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs="12" md="12">
                <Form.Group>
                  <Input
                    id="TriggerDayOffset"
                    control={control}
                    type="number"
                    name="TriggerDayOffset"
                    label="Day offset (use negative value to send x days BEFORE Date, Positive to send AFTER)"
                    required
                    rules={{
                      required: 'Day Offset is required',
                      validate: (val: string) => {
                        const errMessage = 'Day offset must be a number between -365 and 365';
                        try {
                          const intVal = parseInt(val, 10);
                          if (intVal > 365 || intVal < -365) return errMessage;
                        } catch (e) {
                          return errMessage;
                        }
                        return null;
                      },
                    }}
                    errors={errors.TriggerDayOffset?.message}
                  />
                </Form.Group>
              </Col>
            </Row>
            <button type="submit" className="btn btn-primary mt-4 mb-4" disabled={isSubmitting}>
              {isSubmitting
                ? `${!eventId ? 'Creating' : 'Updating'}...`
                : `${!eventId ? 'Create' : 'Update'} Automatic Event`}
            </button>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ModifyEbfTriggeredEventsPage;
