import React, {
  ReactElement, useCallback, useState, useEffect,
} from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns/esm';
import { useHistory, useParams } from 'react-router-dom';

import { EventTemplateForm as EventStepper } from 'components/EventTemplateForm/EventTemplateForm';
import { EventSchedulerForm as EventScheduler } from 'components/EventScheduler';
import {
  selectSelectedEvent, getSelectedEvent, createEvent, stopEvent, deleteEvent,
} from 'reducers/Events';
import {
  IEvent, EventType, ScheduleMode, EventTemplateMode,
} from 'types/IEvent';

export default function SurveyEditPage(): ReactElement {
  const [showStepper, setShowStepper] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(getSelectedEvent(Number(id)));
  }, [dispatch, id]);

  const event = useSelector(selectSelectedEvent);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (!event?.Name) {
      return;
    }
    setData({
      ...event,
      EventType: EventType.SURVEY,
    });
  }, [event]);

  const saveData = useCallback((newData: Partial<IEvent>) => {
    setData((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }, [setData]);

  const handleSubmitStepper = useCallback((formData) => {
    const stepperData: Partial<IEvent> = {
      ...formData,
    };

    if (formData.VoiceContent) {
      const VoiceContent = {
        ...formData.VoiceContent,
        SurveyResponses: formData.VoiceContent?.SurveyResponses?.map((item, index) => ({
          Response: item.Response,
          Keypress: index === 9 ? 0 : index + 1,
        })).filter((item) => item.Response)
           ?? [],
      }
      Object.assign(stepperData, { VoiceContent });
    }

    setShowStepper(false);
    saveData(stepperData);
  }, [saveData]);

  const handleSubmitScheduler = useCallback((formData) => {
    const surveyEvent: IEvent = {
      ...data,
      ...formData,
    }

    const updateCb = () => {
      toast.success('Survey successfully updated');
      history.push('/events/survey');
    }

    if (formData.SendMode !== ScheduleMode.Once) {
      dispatch(
        stopEvent(event, format(new Date(), 'yyyy-MM-dd')),
      );
      dispatch(createEvent({ ...surveyEvent, ID: undefined }, updateCb));
    } else {
      dispatch(
        deleteEvent(event.ID),
      );
      dispatch(createEvent({ ...surveyEvent, ID: undefined }, updateCb));
    }
  }, [data, dispatch, history, event]);

  return (
    <div className="TemplatesEdit">
      <Helmet>
        <title>Edit Survey</title>
      </Helmet>
      <div className="header-bar">
        <h1>Edit Survey</h1>
      </div>
      {!data && <p>Loading...</p>}
      {!!data && showStepper && (
        <EventStepper
          eventTemplateMode={EventTemplateMode.Event}
          allowPreview={false}
          data={data}
          onSubmit={handleSubmitStepper}
          onCancel={() => history.push('/events/survey')}
        />
      )}
      {!!data && !showStepper && (
        <EventScheduler
          data={data}
          onSubmit={handleSubmitScheduler}
        />
      )}
    </div>
  );
}
