import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';

import { IProfile, UserType } from 'reducers/IProfile';
import { formatPhoneNumberString } from 'lib/formatters/phoneNumber';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';

interface ProfileInfoProps {
  selectedProfile: IProfile
}

interface InfoBlockProps {
  label: string
  value?: string
}

const InfoBlock: FC<InfoBlockProps> = ({ label, value = '' }) => (
  <div className="mb-4">
    <h6 className="mb-1">
      {label}
    </h6>
    {value}
  </div>
)

const Header = ({ children }) => (
  <h4 className="mb-4" style={{ fontWeight: 600 }}>
    {children}
  </h4>
)

const containerStyles = {
  marginTop: '52.5px',
  paddingLeft: '20px',
}

export const ProfileInfo: FC<ProfileInfoProps> = ({ selectedProfile }) => {
  const communicationPreference = [];

  if (selectedProfile.LandLineOptIn || selectedProfile.MobilePhoneOptIn) {
    communicationPreference.push('Voice')
  }

  if (selectedProfile.EmailOptIn) {
    communicationPreference.push('Email')
  }

  if (selectedProfile.SMSOptIn) {
    communicationPreference.push('SMS')
  }

  const translateUserType = useUserTypeTranslation();

  return (
    <div style={containerStyles}>
      <Header>
        {`${selectedProfile.FirstName} ${selectedProfile.LastName}`}
      </Header>

      <Row>
        <Col xs={4}>
          <InfoBlock label="Type:" value={translateUserType(selectedProfile.UserType)} />
        </Col>
        <Col xs={4}>
          <InfoBlock label="Language:" value={selectedProfile.Language} />
        </Col>
        {selectedProfile.UserType === UserType.Seniors
        && (
          <Col xs={4}>
            <InfoBlock label="Room Number:" value={selectedProfile.RoomNumber} />
          </Col>
        )}
      </Row>

      <Row>
        <Col xs={4}>
          <InfoBlock label="Other:" value="" />
        </Col>
      </Row>

      <Header>
        Contact Information
      </Header>

      <Row>
        <Col xs={4}>
          <InfoBlock label="Landline Phone:" value={formatPhoneNumberString(selectedProfile.LandLine)} />
        </Col>
        <Col xs={4}>
          <InfoBlock label="Mobile Phone:" value={formatPhoneNumberString(selectedProfile.MobilePhone)} />
        </Col>
        <Col xs={4}>
          <InfoBlock label="Email Address:" value={selectedProfile.Email} />
        </Col>
      </Row>

      <InfoBlock label="On Demand Caller ID:" value={formatPhoneNumberString(selectedProfile.OnDemand)} />

      <Header>
        Communications
      </Header>

      <Row>
        <Col xs={4}>
          <InfoBlock
            label="Do not call between:"
            value={`${selectedProfile.BlockBeginTime ?? '00:00'}-${selectedProfile.BlockEndTime ?? '00:00'}`}
          />
        </Col>
        <Col xs={8}>
          <InfoBlock label="Communication Preference:" value={communicationPreference.join(', ')} />
        </Col>
      </Row>
    </div>
  )
}
