import React, {
  ReactElement, useCallback, useEffect, useRef, useState,
} from 'react';
import { Helmet } from 'react-helmet';
import API from 'api/API';
import { Controller, useForm } from 'react-hook-form';
import {
  useAccordionToggle, Button, Spinner,
} from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Table from 'components/Table';
import Select from 'components/Inputs/Select';
import DateInput from 'components/Inputs/DateInput';
import TimeInput from 'components/Inputs/TimeInput';
import { ProfileTypeCell } from 'components/ProfileTypeCell';
import './Reports.scss';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';
import { Input, Select as FormControlSelect } from 'components/FormControls';

const useReportList = () => {
  const [reportState, setReportState] = useState([]);
  useEffect(() => {
    (async () => {
      const reports = await API.get('api/v2/report');
      const options = reports.data.map((g) => ({
        id: g,
        label: g,
        value: g,
      }));
      setReportState(options);
    })()
  }, []);
  return reportState;
}
const useReportUiData = (reportName:string): [Record<string, any>, boolean] => {
  const [reportState, setReportState] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      if (reportName) {
        setIsLoading(true);
        setReportState((await API.get(`api/v2/report/${reportName}`)).data);
        setIsLoading(false);
      }
    })();
  }, [reportName]);
  return [reportState, isLoading];
}

const ReportInput = ({
  parameterName, errors, control, showTimeInput = true,
}): ReactElement => (
  parameterName.toLowerCase().indexOf('date') >= 0
    ? (
      <Controller
        control={control}
        name={parameterName}
        defaultValue={moment().format('yyyy-MM-DD HH:mm:ss')}
        render={({ onChange, onBlur, value }) => {
          const dateValue = moment(value);

          return (
            <div className="row">
              <div className="col-3">
                {parameterName}
              </div>
              <div className="col-3">
                <DateInput
                  name=""
                  label=""
                  value={dateValue.format('yyyy-MM-DD')}
                  onChange={(e) => {
                    if (e) {
                      onChange(`${e} ${dateValue.format('HH:mm:ss')}`);
                    }
                  }}
                />
              </div>
              { showTimeInput && (
                <div className="col-3">
                  <TimeInput
                    name="Time"
                    label=""
                    value={dateValue.format('HH:mm:ss')}
                    onChange={(e) => {
                      if (e) {
                        onChange(`${dateValue.format('yyyy-MM-DD')} ${e.target.value}`);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          )
        }}
      />

    )
    : (
      <Input
        id={parameterName}
        rules={{ required: true }}
        control={control}
        errors={errors[parameterName]}
        name={parameterName}
        type="text"
        label={parameterName}
      />
    ))

function ReportPage(): ReactElement {
  const [selectedReport, setSelectedReport] = useState<string>(undefined);
  const reportState = useReportList();
  const [reportUiData, isReportUiDataLoading] = useReportUiData(selectedReport);
  const { control, handleSubmit, errors } = useForm();
  const [reportResults, setReportResults] = useState<Record<string, any>>();
  const [reportIsRunning, setReportIsRunning] = useState(false);
  const csvLink = useRef<CSVLink>();
  const [reportExportLoading, setReportExportLoading] = useState(false);

  const translateUserType = useUserTypeTranslation();

  const getColumns = () => {
    if (!reportResults?.Results?.length) {
      return [];
    }
    return Object.keys(reportResults.Results[0]).map((x) => {
      if (x === 'Type') {
        return ({ Header: translateUserType(x), accessor: x, Cell: ProfileTypeCell });
      }
      return ({ Header: translateUserType(x), accessor: x });
    });
  };

  const getCSVData = () => (
    reportResults?.Results?.map((row) => {
      // replace properties of json data object with aliases
      const newRow = Object.fromEntries<any>(Object.entries(row).map(([k, v]) => [translateUserType(k), v]));
      if (newRow.Type) {
        return { ...newRow, Type: translateUserType(newRow.Type) };
      }
      return { ...newRow };
    }) ?? []
  );

  const toggleAccordion = useAccordionToggle('0');
  const downloadHandler = async () => {
    if (reportExportLoading) return;
    setReportExportLoading(true);
    csvLink.current.link.click();
    setReportExportLoading(false);
  }
  const onSubmit = useCallback(async (data) => {
    const contextType = data.ContextType;
    const parameters = { ...data };
    delete parameters.ContextType;
    setReportIsRunning(true);
    const results:Record<string, any> = await API.post('api/v2/report', {
      ReportName: selectedReport,
      Parameters: parameters,
      ContextType: contextType,
    });
    setReportResults(results.data);
    setReportIsRunning(false);
    toggleAccordion(null);
  }, [selectedReport, toggleAccordion]);
  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <div className="container">
        <Select
          id="reporttorun"
          name="reporttorun"
          label="Report to run"
          className="mb-4"
          placeholder="Select your report"
          options={reportState}
          value={selectedReport}
          onChange={(e) => {
            setSelectedReport(e);
          }}
        />
        {!isReportUiDataLoading
        && reportUiData
        && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControlSelect
              id="ContextType"
              control={control}
              name="ContextType"
              defaultValue="Account"
              options={[{ label: 'Account', value: 'Account' },
                { label: 'Organization', value: 'Organization' }]}
              label="Context Type"
            />
            {
              reportUiData.Parameters.map((x) => (
                <ReportInput control={control} parameterName={x} key={x} errors={errors} />
              ))
            }
            <div className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <Button
                  style={{ width: '160px', display: 'flex', alignItems: 'center' }}
                  type="submit"
                  disabled={reportIsRunning}
                >
                  <span className="pr-1">
                    Run Report
                  </span>
                  {reportIsRunning && <Spinner className="pl-2" as="span" animation="border" />}
                </Button>

              </div>
              <div className="p-2 ">
                {!reportIsRunning && reportResults && (
                  <>
                    <button type="button" className="btn btn-link" onClick={async () => { await downloadHandler(); }}>
                      <FontAwesomeIcon icon="file-export" />
                      {' '}
                      { reportExportLoading
                        ? ('Exporting...' && <Spinner className="pl-2" as="span" animation="border" />)
                        : 'Export Data' }
                    </button>
                    <CSVLink
                      data={getCSVData()}
                      ref={csvLink}
                      filename="report.csv"
                      className="hidden"
                      target="_blank"
                      asyncOnClick
                    />
                  </>
                ) }
              </div>
            </div>
          </form>
        ) }
      </div>
      {!reportIsRunning
      && reportResults
      && (
        <Table
          columns={getColumns()}
          data={reportResults.Results || []}
          enableCheck={false}
        />
      )}
    </>
  );
}

export default ReportPage;
