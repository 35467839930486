import React from 'react';
import { Spinner } from 'react-bootstrap';

interface LoadingOverlayProps {
  isVisible: boolean
  loadingText: string
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ isVisible, loadingText }) => {
  if (!isVisible) return null;

  return (
    <div className="loading-overlay">
      <Spinner
        as="span"
        animation="border"
        variant="primary"
        role="status"
        className="mr-3 mb-3"
      />
      <span className="loading-text">{loadingText}</span>
    </div>
  );
};

export default LoadingOverlay;
