import React, { forwardRef, RefObject } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IPatientFormData } from 'types/IPatientFormData';
import { CommunicationTypeEnum } from 'types/IDischargedPatient';
import { Input, Select } from 'components/FormControls';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import DatePicker from 'react-datepicker';
import { IOptions } from 'components/Inputs/Select';

interface IProps {
  data?: IPatientFormData
}

function DischargedPatientForm({ data }: IProps): React.ReactElement {
  const {
    errors, control,
  } = useFormContext<IPatientFormData>();
  const phoneRegEx = /^\(?[2-9][0-9]{2}\)?\s?[2-9][0-9]{2}-?[0-9]{4}$/;
  const communicationTypeOptions: IOptions[] = [
    { label: 'Voice', value: CommunicationTypeEnum.Voice },
    { label: 'SMS', value: CommunicationTypeEnum.SMS },
  ];

  const ref2 = React.createRef<HTMLInputElement>();
  const StyledDatePickerInput = forwardRef(({ onClick, value, onChange }:any, ref:RefObject<HTMLInputElement>) => (
    <div
      className="Input form-group floating-label active"
    >
      <label htmlFor="datepicker">
        Discharge Date
      </label>
      <input
        ref={ref}
        onClick={onClick}
        className="form-control"
        onChange={onChange}
        value={value}
        type="text"
        id="datepicker"
      />
    </div>
  ));
  return (
    <>
      <style>
        {
          `
        .form-control.date{
            border-bottom-left-radius:0px;
            border-top-left-radius:0px;
        }
        .react-datepicker-wrapper{
            display:flex;
            flex-direction: row;
            align-items: stretch;
            flex-grow: 1;
        }
        .react-datepicker-wrapper div{
            display:flex;
            flex-direction: row;
            align-items: stretch;
            flex-grow: 1;
        }
        `
        }

      </style>
      <fieldset>
        <legend>Patient Information</legend>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Input
              id="patientProfile.FirstName"
              name="patientProfile.FirstName"
              label="First Name"
              type="text"
              control={control}
              errors={errors?.patientProfile?.FirstName && 'First Name is required'}
              rules={{ required: true }}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <Input
              id="patientProfile.LastName"
              name="patientProfile.LastName"
              label="Last Name"
              type="text"
              control={control}
              errors={errors?.patientProfile?.LastName && 'Last Name is required'}
              rules={{ required: true }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Controller
              control={control}
              name="dischargedPatient.DischargeDate"
              defaultValue={format(new Date(), 'yyyy-MM-dd')}
              errors={errors?.dischargedPatient?.DischargeDate && 'Discharge Date is required'}
              rules={{ required: true }}
              render={({ onChange, onBlur, value }) => (
                <DatePicker
                  className="datePicker"
                  onBlur={onBlur}
                  customInput={<StyledDatePickerInput ref={ref2} />}
                  selected={
                    value
                      ? parse(value, 'yyyy-MM-dd', new Date())
                      : new Date()
                  }
                  onChange={(date: Date) => {
                    onChange(format(date, 'yyyy-MM-dd'));
                  }}
                  isClearable={false}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Input
              id="dischargedPatient.Facility"
              name="dischargedPatient.Facility"
              label="Facility"
              type="text"
              control={control}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <Input
              id="dischargedPatient.MRN"
              name="dischargedPatient.MRN"
              label="MRN"
              type="text"
              control={control}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Input
              id="dischargedPatient.AttendingPhysician"
              name="dischargedPatient.AttendingPhysician"
              label="Attending Physician"
              type="text"
              control={control}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <Input
              id="dischargedPatient.AdmittingDiagnosis"
              name="dischargedPatient.AdmittingDiagnosis"
              label="Admitting Diagnosis"
              type="text"
              control={control}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Input
              id="dischargedPatient.DischargeTo"
              name="dischargedPatient.DischargeTo"
              label="Discharge To"
              type="text"
              control={control}
            />
          </div>

          <div className="col-xs-12 col-md-6">
            <Input
              id="dischargedPatient.AdditionalPhoneNumber"
              name="dischargedPatient.AdditionalPhoneNumber"
              label="Additional Phone Number"
              type="text"
              control={control}
              mask="(999) 999-9999"
              errors={errors?.dischargedPatient?.AdditionalPhoneNumber && 'Invalid Phone Number'}
              rules={{
                required: false,
                validate: (val: any) => {
                  if (val === null || val === undefined || val === '') {
                    return true;
                  }
                  return phoneRegEx.test(val);
                },
              }}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <Select
              id="dischargedPatient.CommunicationType"
              name="dischargedPatient.CommunicationType"
              label="Communication"
              placeholder="Select Communication Preference"
              defaultValue="voice"
              control={control}
              options={communicationTypeOptions}
              errors={errors?.dischargedPatient?.CommunicationType?.message}
              rules={{ required: 'CommunicationType is required' }}
            />
          </div>
        </div>

        <Input
          id="patientProfile.CustomerProfileID"
          name="patientProfile.CustomerProfileID"
          label=""
          type="hidden"
          control={control}
        />
        <Input
          id="patientProfile.UserType"
          name="patientProfile.UserType"
          label=""
          type="hidden"
          control={control}
        />
        <Input
          id="dischargedPatient.DischargePatientId"
          name="dischargedPatient.DischargePatientId"
          label=""
          type="hidden"
          control={control}
        />
        <Input
          id="dischargedPatient.ProfileId"
          name="dischargedPatient.ProfileId"
          label=""
          type="hidden"
          control={control}
        />
        <Input
          id="dischargedPatient.ContactProfileId"
          name="dischargedPatient.ContactProfileId"
          label=""
          type="hidden"
          control={control}
        />
        <Input
          id="dischargedPatient.NextCallDate"
          name="dischargedPatient.NextCallDate"
          label=""
          type="hidden"
          control={control}
        />
        <Input
          id="dischargedPatient.LastCallDate"
          name="dischargedPatient.LastCallDate"
          label=""
          type="hidden"
          control={control}
        />
      </fieldset>
    </>
  );
}

export default DischargedPatientForm;
