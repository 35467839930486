import { formatPhoneNumberString } from 'lib/formatters/phoneNumber';
import React, { useEffect, useState } from 'react';
import { RootState } from 'types/rootState';
import { useSelector } from 'react-redux';
import { IPatientFormData } from 'types/IPatientFormData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Collapse } from 'react-bootstrap';
import { IDischargedPatient } from 'types/IDischargedPatient';
import { forEach } from 'lodash';
import { StatusDropDown } from './StatusDropDown';
import { TagInterface } from './TagInterface';
import { DeleteButton } from './DeleteButton';
import { DischargedPatientViewAndEdit } from './DischargedPatientViewAndEdit';
import { ContactProfileViewAndEdit } from './ContactProfileViewAndEdit';
import { PatientProfileViewAndEdit } from './PatientProfileViewAndEdit';

interface IProps {
  data?: IPatientFormData
  setSelectedPatient: (patient: IDischargedPatient) => void
  startExpanded: boolean
}

function DischargedPatientView({ data, setSelectedPatient, startExpanded }: IProps): React.ReactElement {
  const [open, setOpen] = useState(startExpanded);

  const dischargedPatients = useSelector(
    (state:RootState) => state.DischargedPatients,
  );

  const [allCallsCompleted, setAllCallsCompleted] = useState<boolean>(false);

  useEffect(() => {
    forEach(dischargedPatients.surveyCallsByIds, (call) => {
      if (call.TelephonyStatus === 'Complete' && call.TaskStatus === 'Complete') {
        setAllCallsCompleted(true);
      } else {
        setAllCallsCompleted(false);
      }
    });
  }, [dischargedPatients.surveyCallsByIds, setAllCallsCompleted]);

  return (
    <Col>
      <fieldset>
        <a
          className="btn btn-link"
          style={{ color: '#2d2926', display: 'none' }}
          href={`/dischargedPatients/${data.dischargedPatient.DischargePatientId}`}
        >
          <FontAwesomeIcon icon="link" />
        </a>
        <div>
          <div className="row d-flex align-items-center">
            <div className="col-10">
              <Button
                variant="link"
                onClick={() => !startExpanded && setOpen(!open)}
                aria-expanded={open}
                className="d-flex pl-0"
              >

                <h3>
                  {data.patientProfile.FirstName}
                  {' '}
                  {data.patientProfile.LastName}
                </h3>
                <span className="pl-2">
                  {!startExpanded && !open && (
                    <span className="fa-stack">
                      <FontAwesomeIcon className="fa-stack-2x" icon="circle" />
                      <FontAwesomeIcon className="fa-stack-1x fa-inverse" icon="ellipsis-h" />
                    </span>
                  )}
                  {!startExpanded && open && (
                    <span className="fa-stack">
                      <FontAwesomeIcon className="fa-stack-2x" icon="circle" />
                      <FontAwesomeIcon className="fa-stack-1x fa-inverse" icon="chevron-down" />
                    </span>
                  )}
                </span>
              </Button>
            </div>
            <div className="col-2">
              <DeleteButton data={data} setSelectedPatient={setSelectedPatient} />
            </div>
          </div>
        </div>

        <Collapse in={!open}>
          <div id="example-fade-text">
            <DischargedPatientSummary data={data} />
          </div>
        </Collapse>
        <Collapse in={open}>
          <div id="example-collapse-text">
            <div className="row info-row">
              <div className="col-12">
                <DischargedPatientViewAndEdit data={data} />
              </div>
            </div>
            <div className="row contact-row">
              <div className="col">
                <PatientProfileViewAndEdit data={data} />
              </div>
              <div className="col">
                <ContactProfileViewAndEdit data={data} />
              </div>
            </div>
          </div>
        </Collapse>
        {data.dischargedPatient.DischargePatientId !== 0 && (
          <div className="row status-and-tags">
            <div className="col updateStatusSelect">
              <StatusDropDown
                data={data}
                allSurveyCallsCompleted={allCallsCompleted}
              />
            </div>
            <div className="col updateStatusSelect">
              <TagInterface data={data} />
            </div>
          </div>
        )}
      </fieldset>
    </Col>
  );
}

export default DischargedPatientView;

function DischargedPatientSummary({ data }) {
  return (
    <div className="row">
      <div className="col">
        <div>
          <strong>
            Facility:
          </strong>
          {data.dischargedPatient.Facility}
        </div>
        <div>
          <strong>
            Discharge Date:
          </strong>
          {data.dischargedPatient.DischargeDate}
        </div>
        <div>
          <strong>
            Admitting Diagnosis:
          </strong>
          {data.dischargedPatient.AdmittingDiagnosis}
        </div>
        <div>
          <strong>
            Discharged To:
          </strong>
          {data.dischargedPatient.DischargeTo}
        </div>
      </div>
      <div className="col">
        <div>
          <strong>
            Contact Landline:
          </strong>
          {formatPhoneNumberString(data.contactProfile.LandLine)}
        </div>
        <div>
          <strong>
            Contact Mobile:
          </strong>
          {formatPhoneNumberString(data.contactProfile.MobilePhone)}
        </div>
        <div>
          <strong>
            Contact Language:
          </strong>
          {`${data?.contactProfile?.Language?.charAt(0).toUpperCase()}${data?.contactProfile?.Language?.slice(1)}`}
        </div>
        <div>
          <strong>
            Communication Type:
          </strong>
          {data.dischargedPatient.CommunicationType}
        </div>
      </div>
    </div>
  );
}
