import React from 'react';
import moment from 'moment';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isIE } from 'react-device-detect';
import {
  Alert,
  Button,
  Card, Dropdown, SplitButton,
} from 'react-bootstrap';
import { format } from 'date-fns/esm';
import { useDispatch, useSelector } from 'react-redux';
import useLifecycles from 'react-use/lib/useLifecycles';
import useDebounce from 'react-use/lib/useDebounce';
import clsx from 'clsx';

import {
  getGroupByIds, getManyGroupsMembers, selectLoading as selectGroupLoading,
} from 'reducers/Groups';
import { getProfilesByCpids, getProfilesByIds, selectLoading as selectProfileLoading } from 'reducers/Profiles';
import { RootState } from 'types/rootState';
import { IUserInfoState } from 'reducers/IUserInfoState';
import { selectCommunityName } from 'reducers/UserInfo';
import { bin2string } from 'utils/stringUtils';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';
import { TemplateTransformer } from 'transformers/Template';
import { useSaveEvent } from 'hooks/useSaveEvent';
import { ITemplate } from 'types/IEvent';
import { AudioPlayer } from './AudioPlayer';
import { useTemplateQuery } from './hooks/useTemplateQuery';

type TemplateSendNowModalProps = {
  onHide: () => void
  templateId: number
}

export const TemplateSendNowModal: React.FC<TemplateSendNowModalProps> = ({
  templateId,
  onHide,
}) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState): IUserInfoState => state.UserInfo);
  const [showConfirmEmergencyModal, setShowConfirmEmergencyModal] = React.useState(false);
  const isGroupLoading = useSelector(selectGroupLoading);
  const isProfileLoading = useSelector(selectProfileLoading);

  const acctTimezone = userInfo.accountTimezone;

  const translateUserType = useUserTypeTranslation();
  const [template, setTemplate] = React.useState<null|ITemplate>(null);

  useTemplateQuery(
    templateId,
    undefined,
    undefined,
    {
      onSuccess: (fetchedTemplate) => {
        if (!fetchedTemplate) {
          return;
        }
        setTemplate(fetchedTemplate);
      },
    });

  React.useEffect(() => {
    if (template?.GroupIds?.length) {
      dispatch(getManyGroupsMembers(template.GroupIds));
    }
    // eslint-disable-next-line
  }, [dispatch, `${template?.GroupIds}`]);

  React.useEffect(() => {
    if (template?.ProfileIds?.length) {
      dispatch(getProfilesByCpids(template.ProfileIds));
    }
    // eslint-disable-next-line
  }, [dispatch, `${template?.ProfileIds}`]);

  const eventFromTemplate = React.useMemo(() => {
    if (!template) {
      return null;
    }

    return TemplateTransformer.toEvent(template, acctTimezone);
  }, [template, acctTimezone]);

  const communityName = useSelector(selectCommunityName);
  const selectedGroups = useSelector(getGroupByIds(eventFromTemplate?.GroupIds || []));
  const eventGroups = selectedGroups.map((group) => group.Name);
  const eventProfiles = useSelector(
    getProfilesByIds(eventFromTemplate?.ProfileIds || []),
  ).map((profile) => `${profile.FirstName} ${profile.LastName}`);
  const groupRecipientCount = selectedGroups.reduce(
    (sum, group) => sum + group.ProfileCount,
    0,
  );

  const handleSaveEvent = useSaveEvent();

  const generateDownloadLink = () => {
    const downloadUrl = eventFromTemplate.VoiceContent.VoiceRecording.DownloadUrl;
    if (downloadUrl) {
      return (
        <>
          <a href={downloadUrl}>Click here to download recording</a>
          {' '}
          <br />
        </>
      )
    }
    return null;
  }

  const shouldShowCommunityNameWarning = () => {
    const voiceContentValue = eventFromTemplate?.VoiceContent?.Content;
    const smsContentValue = eventFromTemplate?.SMSContent?.Content;
    const emailContentValue = eventFromTemplate?.EmailContent?.Content;
    const decodeEmailContentValue = emailContentValue ? atob(emailContentValue) : '';

    if (((voiceContentValue && voiceContentValue?.indexOf('$$CommunityName$$') !== -1)
      || (smsContentValue && smsContentValue?.indexOf('$$CommunityName$$') !== -1)
      || (decodeEmailContentValue && decodeEmailContentValue?.indexOf('$$CommunityName$$') !== -1))
      && (communityName === null || communityName === '')) {
      return true;
    }
    return false;
  };

  const saveEvent = (IsEmergency: boolean) => {
    handleSaveEvent({
      ...eventFromTemplate,
      StartDate: format(new Date(), 'MM-dd-yyyy'),
      SendTime: moment().tz(acctTimezone).add(1, 'minutes').format('HH:mm'),
      IsEmergency,
      IsSendNow: true,
    })
  };

  const onCancelHandler = () => {
    setShowConfirmEmergencyModal(false);
    onHide();
  }

  const onConfirmEmergencySend = () => {
    saveEvent(true);
    setShowConfirmEmergencyModal(false);
    onHide();
  }

  const onEmergencySend = () => {
    setShowConfirmEmergencyModal(true);
  }

  const onSend = () => {
    saveEvent(false);
    onHide();
  }

  const [isLoadingDebounced, setIsLoadingDebounced] = React.useState(true);
  const isLoading = !eventFromTemplate || isGroupLoading || isProfileLoading;

  const [, cancel] = useDebounce(
    () => {
      setIsLoadingDebounced(isLoading);
    },
    100,
    [isLoading],
  );

  useLifecycles(cancel, cancel);

  return (
    <>
      {!isLoadingDebounced && (
        <Modal
          show={!showConfirmEmergencyModal}
          onHide={onHide}
          className="template-send-now-modal"
        >
          <Modal.Header
            closeButton={false}
          >
            <Modal.Title>
              <div className="div-title">
                <Row className="pt-2">
                  <Col>
                    <div className="div-title-name">{eventFromTemplate?.Name}</div>
                  </Col>
                  <Col xs="auto">
                    <div>
                      <Button
                        type="button"
                        className="link"
                        variant="link"
                        onClick={onHide}
                      >
                        Cancel
                      </Button>
                      <SplitButton
                        key="Primary"
                        id="ddsSend"
                        variant="primary"
                        className="btn-send"
                        title="Send"
                        onClick={onSend}
                        onSelect={onEmergencySend}
                      >
                        <Dropdown.Item
                          key="send-as-emergency"
                          eventKey="send-as-emergency"
                        >
                          Send as Emergency
                        </Dropdown.Item>
                      </SplitButton>
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {shouldShowCommunityNameWarning() && (
              <Alert variant="warning">
                This
                {' '}
                {eventFromTemplate?.IsMarketing
                  ? 'marketing email'
                  : 'template'}
                {' '}
                is using the CommunityName merge field. This value needs to be set in Account Settings.
              </Alert>
            )}
            {eventFromTemplate?.Location && (
              <Row className="pt-2">
                <Col>
                  <strong>Location</strong>
                  <div>
                    {' '}
                    {eventFromTemplate?.Location}
                  </div>
                </Col>
              </Row>
            )}
            {eventFromTemplate?.Description && (
              <Row className="pt-2">
                <Col>
                  <strong>Description</strong>
                  <div>
                    {' '}
                    {eventFromTemplate?.Description}
                  </div>
                </Col>
              </Row>
            )}
            <Row className="pt-2">
              <Col>
                <Card>
                  <Card.Header as="h6">Message Recipients</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        {selectedGroups?.length === 0 ? (
                          <h6>No Groups selected</h6>
                        ) : (
                          <>
                            <h6>
                              Groups (
                              {groupRecipientCount}
                              {' '}
                              recipients)
                            </h6>
                            <div className="div-recipients">
                              {eventGroups.map((group, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <p className="m-0" key={index}>
                                  {translateUserType(group)}
                                </p>
                              ))}
                            </div>
                          </>
                        )}
                      </Col>
                      <Col>
                        {eventProfiles.length === 0 ? (
                          <h6>No Profiles selected</h6>
                        ) : (
                          <>
                            <h6>
                              Profiles (
                              {eventProfiles.length}
                              {' '}
                              recipients)
                            </h6>
                            <div className="div-recipients">
                              {eventProfiles.map((profile, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <p className="m-0" key={index}>
                                  {profile}
                                </p>
                              ))}
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {eventFromTemplate.VoiceContent && (
              <Row className="pt-2">
                <Col>
                  <Card
                    id="voice-summary"
                  >
                    <Card.Header as="h6">
                      <FontAwesomeIcon className="mr-1" icon="phone" />
                      {' '}
                      Voice Message
                    </Card.Header>
                    <Card.Body>
                      {eventFromTemplate.VoiceContent.Content && (
                        <pre
                          className="pt-3 wrapword"
                        >
                          {eventFromTemplate.VoiceContent.Content}
                        </pre>
                      )}
                      {eventFromTemplate.VoiceContent.VoiceRecording && isIE && (
                        generateDownloadLink()
                      )}
                      {eventFromTemplate.VoiceContent.VoiceRecording && !isIE && (
                        <AudioPlayer
                          src={`data:audio/wav;base64,${eventFromTemplate.VoiceContent.VoiceRecording.Data}`}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
            {eventFromTemplate.EmailContent && (
              <Row className="pt-2">
                <Col>
                  <Card className={eventFromTemplate.VoiceContent ? 'mt-4' : ''}>
                    <Card.Header as="h6">
                      <FontAwesomeIcon className="mr-1" icon="envelope-square" />
                      {' '}
                      Email Message
                    </Card.Header>
                    <Card.Body>
                      <p className="m-0">
                        <strong>Subject:</strong>
                        {' '}
                        {eventFromTemplate.EmailContent.Subject}
                      </p>
                      <p className="m-0">
                        <strong>From:</strong>
                        {' '}
                        {eventFromTemplate.EmailContent.From}
                        {' '}
                        {eventFromTemplate.EmailContent.Display ?? `(${eventFromTemplate.EmailContent.Display})`}
                      </p>
                      <p className="m-0">
                        <strong>Reply To:</strong>
                        {' '}
                        {eventFromTemplate.EmailContent.ReplyTo}
                      </p>
                      <p
                        className={clsx('pt-3', !eventFromTemplate.IsMarketing && 'fr-view')}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: eventFromTemplate.EmailContent.Content
                            ? bin2string(eventFromTemplate.EmailContent.Content)
                            : '',
                        }}
                      />
                      {eventFromTemplate.EmailContent.Attachments && (
                        <>
                          <p className="m-0">
                            <strong>Attachments:</strong>
                          </p>
                          {eventFromTemplate.EmailContent.Attachments.map(({ FileName, DocumentId }) => (
                            <p key={DocumentId}>{FileName}</p>
                          ))}
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
            {eventFromTemplate.SMSContent && (
              <Row className="pt-2">
                <Col>
                  <Card
                    className={
                      eventFromTemplate.VoiceContent || eventFromTemplate.EmailContent ? 'mt-4' : ''
                    }
                  >
                    <Card.Header as="h6">
                      <FontAwesomeIcon className="mr-1" icon="comment" />
                      {' '}
                      Text Message
                    </Card.Header>
                    <Card.Body>
                      <pre className="wrapword" style={{ fontFamily: 'sans-serif', fontSize: '1rem' }}>
                        {eventFromTemplate.SMSContent.Content}
                      </pre>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
          </Modal.Body>
        </Modal>
      )}

      <Modal
        show={showConfirmEmergencyModal}
        onHide={onCancelHandler}
        className="DeleteActionButtonModal"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header />
        <Modal.Body>
          <FontAwesomeIcon
            icon="exclamation-circle"
            size="4x"
            color="#EA5455"
          />
          <div className="message">
            <h4>Are you sure?</h4>
            <p>
              This message will be sent to recipients using all available methods of contact,
              regardless of their inactivity status, do not call settings and communication preferences.
              This should only be used in the case of an emergency.
            </p>
          </div>
          <button
            type="button"
            className="btn btn-block btn-danger"
            onClick={onConfirmEmergencySend}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-block btn-secondary"
            onClick={onCancelHandler}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>

      {isLoadingDebounced && (
        <Modal
          show={!showConfirmEmergencyModal}
          onHide={onCancelHandler}
          className="DiscardChangeModal"
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header />
          <Modal.Body>
            <FontAwesomeIcon
              icon="exclamation-circle"
              size="4x"
              color="#0F4CDB"
            />
            <div className="message">
              <h4>Validating template...</h4>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
