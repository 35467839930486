import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import { IPatientFormData } from '../../types/IPatientFormData';
import { updateProfile } from '../../reducers/Profiles';
import DischargedPatientContactForm from './DischargedPatientContactForm';
import { formatPhoneNumberString } from '../../lib/formatters/phoneNumber';

interface IProps{
  data:IPatientFormData
}

export function ContactProfileViewAndEdit({
  data,
}:IProps):React.ReactElement {
  const phonePlaceHolder = '(___) ___-____';
  const [showForm, setShowForm] = useState(false);
  const methods = useForm<IPatientFormData>({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const {
    reset, handleSubmit,
  } = methods;
  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);
  const onSubmitClick = (formData: IPatientFormData): void => {
    segmentAnalyticsTrack(trackActions.editDischargedPatient());
    const contactProfile = {
      ...formData.contactProfile,
      LandLine: phonePlaceHolder === formData.contactProfile.LandLine ? '' : formData.contactProfile.LandLine,
      MobilePhone: phonePlaceHolder === formData.contactProfile.MobilePhone ? '' : formData.contactProfile.MobilePhone,
    }

    dispatch(updateProfile(contactProfile, () => {
      setShowForm(false);
    }));
  };
  return (
    <>
      <div
        className="section-header col d-flex justify-content-between align-items-center"
      >
        <h6>
          Responsible Party
        </h6>
        {!showForm && (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              setShowForm(true);
            }}
          >
            Edit
          </button>
        )}
      </div>
      {showForm
            && (
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(onSubmitClick)}
                  className="form ProfilesForm col-12 pb-2"
                >
                  <DischargedPatientContactForm data={data} />
                  <Button type="submit">Save</Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      reset(data);
                      setShowForm(false)
                    }}
                  >
                    Cancel

                  </Button>
                </form>
              </FormProvider>
            )}
      {!showForm && (
        <>
          <div className="col">
            <strong>Contact Name:</strong>
            {' '}
            {data.contactProfile.FirstName}
            {' '}
            {data.contactProfile.LastName}
          </div>
          <div className="col">
            <strong>Contact Landline:</strong>
            {' '}
            {formatPhoneNumberString((data?.contactProfile?.LandLine))}
          </div>

          <div className="col">
            <strong>Contact Mobile:</strong>
            {' '}
            {formatPhoneNumberString((data?.contactProfile?.MobilePhone))}
          </div>
          <div className="col">
            <strong>Contact Language:</strong>
            {' '}
            {`${data?.contactProfile?.Language?.charAt(0).toUpperCase()}${data?.contactProfile?.Language?.slice(1)}`}
          </div>
        </>
      )}
    </>
  );
}
