import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Textarea from 'components/Inputs/Textarea';
import { EmojiPicker } from 'components/EmojiPicker';
import { IConversation } from 'types/IConversation';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { useHasRoles } from 'hooks/useHasRoles';

interface TextboxProps {
  onSend: (val: string) => void
  selectedConversation: IConversation
}

export const Textbox: React.FC<TextboxProps> = ({ onSend, selectedConversation }) => {
  const [text, setText] = useState<string>('');
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const textboxRef = useRef<HTMLTextAreaElement|null>(null);
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const hasSendPermission = useHasPermissions('Inbox:Create') || isAdmin || isSuper;
  const noProfiles = !selectedConversation.Profiles.length;
  const smsDisabledAllProfiles = selectedConversation.Profiles.every((item) => {
    if (!('SmsEnabled' in item)) {
      return false;
    }
    return !item.SmsEnabled;
  });

  const disabled = noProfiles || smsDisabledAllProfiles || !hasSendPermission;

  let warning: string|null = null;
  if (noProfiles) {
    warning = 'You can’t send to a number that has no associated profile';
  } else if (smsDisabledAllProfiles) {
    warning = 'This profile does not have the Text Message communication preference enabled';
  }

  const handleSend = () => {
    if (!text.trim()) {
      return;
    }
    onSend(text);
    setText('');
  }

  const handlePick = (textToInsert: string) => {
    if (!textboxRef.current) {
      return;
    }

    const { selectionStart, selectionEnd } = textboxRef.current;

    const newValue = text.slice(0, selectionStart) + textToInsert + text.slice(selectionEnd);

    setText(newValue);
    textboxRef.current.focus();
    timeoutRef.current = setTimeout(() => {
      textboxRef.current?.setSelectionRange(selectionStart + textToInsert.length, selectionStart + textToInsert.length);
    }, 0);
  }

  return (
    <div className="sms-inbox__chat-view__chat__textbox-container">
      <span
        className="sms-inbox__chat-view__chat__textbox-contentlength"
      >
        {`${text.length}/600`}
      </span>
      <div className="position-relative">
        <Textarea
          className="sms-inbox__chat-view__chat__textbox"
          value={text}
          onChange={(e) => {
            setText(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSend();
            }
          }}
          maxLength={600}
          disabled={disabled}
          ref={textboxRef}
        />
        <div className="sms-inbox__chat-view__chat__textbox-buttons">
          <EmojiPicker
            onPick={handlePick}
            disabled={disabled}
          />
          <button
            type="button"
            className="btn btn-primary btn-md square sms-inbox__chat-view__chat__send"
            style={{ minWidth: 117 }}
            onClick={handleSend}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            disabled={disabled}
          >
            Send
          </button>
        </div>
      </div>
      {warning && (
        <p className="sms-inbox__chat-view__chat__textbox-warning">
          <FontAwesomeIcon
            icon="exclamation-triangle"
            color="var(--orange)"
            title="You can’t send to a number that has no associated profile."
          />
          {warning}
        </p>
      )}
    </div>
  )
}
