import React from 'react';
import { useFormContext } from 'react-hook-form';

interface ContentLengthProps {
  name: string
  maxLength?: number
}

export const ContentLength: React.FC<ContentLengthProps> = ({ name, maxLength = 600 }) => {
  const { watch } = useFormContext();
  const contentLength = watch(name)?.length ?? 0;
  return (
    <span
      className="content-length"
      style={{ float: 'right', padding: '0.3rem' }}
    >
      {`${contentLength}/${maxLength}`}
    </span>
  )
}
