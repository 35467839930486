import { Switch, Route } from 'react-router-dom';
import React from 'react';

import LibraryContentEditPage from 'pages/LibraryContent/Edit';
import LibraryContentIndexPage from 'pages/LibraryContent/Index';
import LibraryContentNewEmailPage from 'pages/LibraryContent/NewEmail';
import LibraryContentNewSmsPage from 'pages/LibraryContent/NewSms';
import LibraryContentNewVoicePage from 'pages/LibraryContent/NewVoice';

function LibraryContentRoutes() {
  return (
    <Switch>
      <Route
        path="/librarycontent/newemail/"
        component={LibraryContentNewEmailPage}
      />
      <Route
        path="/librarycontent/newsms"
        component={LibraryContentNewSmsPage}
      />
      <Route
        path="/librarycontent/newvoice/:voiceType"
        component={LibraryContentNewVoicePage}
      />
      <Route
        path="/librarycontent/:id/edit"
        component={LibraryContentEditPage}
      />
      <Route path="/librarycontent" component={LibraryContentIndexPage} />
    </Switch>
  );
}

export default LibraryContentRoutes;
