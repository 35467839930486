import { IGroup } from 'types/IGroup';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { groupDeleteStart } from 'reducers/Groups';
import { toast } from 'react-toastify';
import React, { Dispatch, SetStateAction, ReactElement } from 'react';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';

interface IProps {
  group: IGroup
  showDeleteModal: boolean
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>
}

function DeleteGroupActionButton({
  group, showDeleteModal, setShowDeleteModal,
}: IProps): ReactElement {
  const dispatch = useDispatch();
  const deleteGroup = (): void => {
    segmentAnalyticsTrack(trackActions.deleteGroup());
    dispatch(groupDeleteStart(group.Id, () => {
      toast.success('Group successfully deleted');
    }));
    setShowDeleteModal(false);
  }
  return (
    <Modal
      show={showDeleteModal}
      onHide={() => setShowDeleteModal(false)}
      className="DeleteActionButtonModal"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header />
      <Modal.Body>
        <FontAwesomeIcon
          icon="trash-alt"
          size="4x"
          color="#FF7052"
        />
        <div className="message">
          <h4>Are you sure?</h4>
          <p>
            This action will delete
            {' '}
            {group?.Name}
          </p>
        </div>
        <button
          type="button"
          className="btn btn-block btn-danger"
          onClick={() => deleteGroup()}
        >
          Delete
        </button>
        <button
          type="button"
          className="btn btn-block btn-secondary"
          onClick={() => setShowDeleteModal(false)}
        >
          Cancel
        </button>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteGroupActionButton;
