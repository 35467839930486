import API from 'api/API';
import { AxiosResponse } from 'axios';
import { IDischargedPatientsPayload } from 'types/IDischargedPatientsPayload';
import { ISurveyCall } from 'types/ISurveyCall';
import { IFilterableIndexParams } from 'types/ITemplatesIndexParams';
import { IDischargedPatient, IDischargedPatientConfig, IDischargedPatientConfigGet } from '../types/IDischargedPatient';

export const API_PATH = '/api/v2/DischargedPatient';
export async function DischargedPatientsIndex({
  searchField = '',
  searchValue = {},
  sortField = '',
  sortDirection = '',
  page,
  perpage = 9999,
}: IFilterableIndexParams): Promise<IDischargedPatientsPayload> {
  const searchValues = Object.keys(searchValue).map((x) => `${x}=${searchValue[x]}`).join('&');
  const response = await API.get<IDischargedPatientsPayload>(
    `${API_PATH}?searchField=${searchField}&${searchValues}&page=${page}&perpage=${perpage}
      &sortField=${sortField}&sortDirection=${sortDirection}`,
  );
  return response.data;
}

// Discharged Patients
// Get
export async function dischargedPatientsGet(): Promise<IDischargedPatient[]> {
  const response = await API.get<IDischargedPatient[]>(`${API_PATH}`);
  return { ...response.data };
}

export async function dischargedPatientGet(id: number): Promise<IDischargedPatient> {
  const response = await API.get<IDischargedPatient>(`${API_PATH}/${id}`);
  return { ...response.data };
}

export async function dischargedPatientSurveyCallsGet(id: number): Promise<ISurveyCall[]> {
  const response = await API.get<ISurveyCall[]>(`${API_PATH}/${id}/calls`);
  return response.data;
}

export async function dischargedPatientConfigGet(): Promise<IDischargedPatientConfigGet> {
  const response = await API.get<IDischargedPatientConfigGet>(`${API_PATH}/Config`);
  return response.data;
}

// Post
export async function dischargedPatientCreate(
  patient: IDischargedPatient,
): Promise<IDischargedPatient[]> {
  const response = await API.post<IDischargedPatient[]>(API_PATH, [patient]);
  return response.data;
}

// Put
export async function dischargedPatientUpdate(
  patient: IDischargedPatient,
): Promise<IDischargedPatient> {
  const response = await API.put<IDischargedPatient>(`${API_PATH}/${patient.DischargePatientId}`, patient);
  return response.data;
}

export async function dischargedPatientConfigUpdate(data: IDischargedPatientConfig): Promise<IDischargedPatientConfig> {
  const response = await API.put<IDischargedPatientConfig>(`${API_PATH}/config`, data);

  return response.data;
}

// Delete
export async function dischargedPatientDelete(id: number): Promise<void> {
  await API.delete<void>(`${API_PATH}/${id}`);
}

export const getTagsForPatient = (
  id:number,
):Promise<AxiosResponse<string[]>> => API.get<string[]>(`/api/v2/dischargedpatient/${id}/tag`)
export const deleteTag = (
  id:number, name:string,
):Promise<AxiosResponse<any>> => API.delete(`/api/v2/dischargedpatient/${id}/tag?name=${name}`);
export const createTag = (id:number,
  name:string):Promise<AxiosResponse<any>> => API.post(`/api/v2/dischargedpatient/${id}/tag`, { name });

export const getTags = ():Promise<AxiosResponse<string[]>> => API.get<string[]>('/api/v2/dischargedpatient/tag');
