import React, { FC, useState } from 'react';
import {
  Modal, Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInput } from 'components/Inputs/SearchInput';
import { useIsSyncedCustomer } from 'hooks/useIsSyncedCustomer';

type Props = {
  onSubmit: (query: string) => void
  searchVal: string|undefined
};

export const GroupSearchFilter: FC<Props> = ({ onSubmit, searchVal }) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState(searchVal);
  const isSyncedCustomer = useIsSyncedCustomer();

  React.useEffect(() => {
    if (isFilterModalOpen) {
      setSearchQuery(searchVal);
    }
  }, [isFilterModalOpen, searchVal]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <span />
      <span>{isSyncedCustomer ? 'Categories and Groups' : 'Groups'}</span>
      <span>
        {!!searchVal && (
          <button
            type="button"
            className="filter-button ml-2"
            title="Remove filters?"
            onClick={() => {
              setSearchQuery(undefined);
              onSubmit(undefined);
            }}
          >
            <span className="">
              <FontAwesomeIcon icon="times" />
            </span>
          </button>
        )}
        <button
          type="button"
          className="filter-button ml-2"
          onClick={() => {
            setIsFilterModalOpen(true);
          }}
        >
          <span className="Calendar__filter-icon">
            <FontAwesomeIcon icon="filter" />
          </span>
        </button>
      </span>
      <Modal
        show={isFilterModalOpen}
        onHide={() => {
          setIsFilterModalOpen(false);
        }}
        dialogClassName="modal-20w"
        centered
      >
        <SearchInput
          label="Search by name"
          placeholder="I am looking for..."
          name="searchValue"
          className="w-100"
          value={searchQuery}
          onChange={(evt) => {
            setSearchQuery(evt.target.value);
          }}
          onKeyDown={(evt) => {
            if (evt.key === 'Enter') {
              evt.preventDefault();
              setIsFilterModalOpen(false);
              onSubmit(searchQuery);
            }
          }}
        />
        <Button onClick={() => {
          setIsFilterModalOpen(false);
          onSubmit(searchQuery);
        }}
        >
          Apply filter
        </Button>
      </Modal>
    </div>
  )
}
