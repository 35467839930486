import React from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { VoiceContentType } from 'types/ILibraryContent';

interface Props {
  setShowGenerateModal: (show: 'voice'|'sms'|'email') => void
  channel: 'voice'|'sms'|'email'
}

export const GenerateContentButton: React.FC<Props> = ({
  setShowGenerateModal,
  channel,
}) => {
  const { getValues } = useFormContext();

  const isNotTTS = getValues('VoiceContent.VoiceType') !== VoiceContentType.TTS;

  return (
    <Button
      type="button"
      size="sm"
      variant="success"
      className="mr-2"
      onClick={() => {
        setShowGenerateModal(channel);
      }}
      disabled={channel === 'voice' && isNotTTS}
    >
      Generate Smart Content
    </Button>
  )
}
