import { useCallback, useMemo } from 'react';
import { IOptions } from 'components/Inputs/Select';
import { useSurveyConfig } from './useSurveyConfig';

export function useStatusOptions(currentStatus: string): IOptions[] {
  const config = useSurveyConfig();
  const isValidTransition = useCallback((current, next) => {
    const exact = `${current}:${next}`;
    const to = `*:${next}`;
    const from = `${current}:*`;

    if (config && config.Forbidden.some((x) => x === exact || x === from || x === to)) {
      return false;
    }
    return true;
  }, [config]);

  const states = config?.States
  const statusOptions = useMemo((): IOptions[] => {
    const statusValues = states ?? [] as Array<string>;
    const options: IOptions[] = [];
    for (const status of statusValues) {
      options.push({
        label: status,
        value: status,
        disabled: !isValidTransition(currentStatus, status),
      });
    }
    return options;
  }, [states, currentStatus, isValidTransition]);
  return statusOptions;
}
