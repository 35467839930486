import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Row, Col, Modal } from 'react-bootstrap';

import Input from 'components/FormControls/Input';
import {
  createSurveyQuestionList, selectCreateLoading, createSurveyQuestionListItem,
} from 'reducers/SurveyQuestionList';
import SubmitButton from 'components/FormControls/SubmitButton'
import {
  ISurveyQuestionList, SurveyQuestionListItemContentTypeEnum,
} from 'types/ISurveyQuestionList';
import { SurveyContentResponseTypeEnum } from 'types/IDischargedPatient';

const SurveyQuestionListCreateModal: FC<{ show: boolean, onHide(): void }> = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const createLoading = useSelector(selectCreateLoading);

  const handleCreate = (formData) => {
    dispatch(createSurveyQuestionList(formData, (payload) => {
      dispatch(createSurveyQuestionListItem(payload.SurveyQuestionListId, [{
        SurveyQuestionListId: payload.SurveyQuestionListId,
        SurveyQuestionContentId: 0,
        Ordinal: 0,
        SMSContent: 'Default Greeting',
        VoiceContent: 'Default Greeting',
        ContentId: null,
        ContentType: SurveyQuestionListItemContentTypeEnum.Greeting,
        ResponseType: SurveyContentResponseTypeEnum.Binary,
        SurveyContentResponsePattern: null,
      }, {
        SurveyQuestionListId: payload.SurveyQuestionListId,
        SurveyQuestionContentId: 0,
        Ordinal: 1,
        SMSContent: 'Default Question',
        VoiceContent: 'Default Question',
        ContentId: null,
        ContentType: SurveyQuestionListItemContentTypeEnum.Question,
        ResponseType: SurveyContentResponseTypeEnum.Binary,
        SurveyContentResponsePattern: null,
      }, {
        SurveyQuestionListId: payload.SurveyQuestionListId,
        SurveyQuestionContentId: 0,
        Ordinal: 0,
        SMSContent: 'Default Farewell',
        VoiceContent: 'Default Farewell',
        ContentId: null,
        ContentType: SurveyQuestionListItemContentTypeEnum.Farewell,
        ResponseType: SurveyContentResponseTypeEnum.Binary,
        SurveyContentResponsePattern: null,
      }], () => {
        toast.success('Survey Question List successfully created!');
        history.push(`/surveyQuestionLists/${payload.SurveyQuestionListId}`);
      }));
    }));
  }

  const {
    control, errors, handleSubmit,
  } = useForm<Omit<ISurveyQuestionList, 'ID'>>(
    { mode: 'onBlur' },
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title className="w-100">
          New Survey Question List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <hr />
        <form onSubmit={handleSubmit(handleCreate)}>
          <Row>
            <Col>
              <Input
                id="Name"
                name="Name"
                label="Name"
                type="text"
                control={control}
                errors={errors.Name && errors.Name.message}
                rules={{
                  required: 'Name is required',
                  validate: (val: string) => val.length <= 50 || 'Name must not exceed 50 characters',
                }}
              />
            </Col>
          </Row>
          <Row className="mt-1">
            <Col className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onHide}
              >
                Cancel
              </button>
              <SubmitButton
                label="Save"
                savingLabel="Saving..."
                className="btn btn-primary ml-3"
                saveComplete={!createLoading}
              />
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default SurveyQuestionListCreateModal;
