import React, { FC } from 'react';
import {
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Input } from 'components/FormControls';

export const VoiceContentResponses: FC = () => {
  const {
    control,
    errors,
  } = useFormContext();

  return (
    <>
      <Row>
        <Col md={4}>
          <h4 className="h5" style={{ marginTop: '30px', marginBottom: '30px' }}>
            Keypress Answers
          </h4>
        </Col>
      </Row>
      <Row>
        <Col lg={8} sm={12} xs={12}>
          <Row>
            {[1, 2, 3].map((n, index) => (
              <Col sm={4} key={n}>
                <Form.Group>
                  <Input
                    id={`VoiceContent.SurveyResponses.${index}.Response`}
                    name={`VoiceContent.SurveyResponses.${index}.Response`}
                    type="text"
                    label={`${n}`}
                    control={control}
                    errors={errors?.VoiceContent?.SurveyResponses?.[index] && 'Invalid Keypress answer'}
                  />
                </Form.Group>
              </Col>
            ))}
          </Row>
          <Row>
            {[4, 5, 6].map((n, index) => (
              <Col sm={4} key={n}>
                <Form.Group>
                  <Input
                    id={`VoiceContent.SurveyResponses.${index + 3}.Response`}
                    name={`VoiceContent.SurveyResponses.${index + 3}.Response`}
                    type="text"
                    label={`${n}`}
                    control={control}
                    errors={errors?.VoiceContent?.SurveyResponses?.[index + 3] && 'Invalid Keypress answer'}
                  />
                </Form.Group>
              </Col>
            ))}
          </Row>
          <Row>
            {[7, 8, 9].map((n, index) => (
              <Col sm={4} key={n}>
                <Form.Group>
                  <Input
                    id={`VoiceContent.SurveyResponses.${index + 6}.Response`}
                    name={`VoiceContent.SurveyResponses.${index + 6}.Response`}
                    type="text"
                    label={`${n}`}
                    control={control}
                    errors={errors?.VoiceContent?.SurveyResponses?.[index + 6] && 'Invalid Keypress answer'}
                  />
                </Form.Group>
              </Col>
            ))}
          </Row>
          <Row style={{ justifyContent: 'center' }}>
            <Col sm={4}>
              <Form.Group>
                <Input
                  id="VoiceContent.SurveyResponses.9.Response"
                  name="VoiceContent.SurveyResponses.9.Response"
                  type="text"
                  label="0"
                  control={control}
                  errors={errors?.VoiceContent?.SurveyResponses?.[9] && 'Invalid Keypress answer'}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
