import React, { ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getProfilesByCpids } from 'reducers/Profiles';
import { getGroupsIndex } from 'reducers/Groups';
import { useEventOccurrenceQuery } from 'components/hooks/useEventOccurrenceQuery';
import { useEventQuery } from 'components/hooks/useEventQuery';
import { ContentView } from './components/ContentView';

export const EventContent = ():ReactElement => {
  const { id: eventId, date: occurrenceDate } = useParams<{id: string, date: string}>();

  const {
    data: eventOccurrence, isValidating: isEventOccurrenceFetching,
  } = useEventOccurrenceQuery(+eventId, occurrenceDate, {
    onSuccess: (eventData) => {
      if (eventData) {
        if (eventData.ProfileIds.length) {
          dispatch(getProfilesByCpids(eventData.ProfileIds));
        }
      }
    },
  });

  const { data: event } = useEventQuery(
    +eventId,
    {
      enabled: !eventOccurrence && !isEventOccurrenceFetching,
      onSuccess: (eventData) => {
        if (eventData) {
          if (eventData.ProfileIds.length) {
            dispatch(getProfilesByCpids(eventData.ProfileIds));
          }
        }
      },
    },
  );

  const eventDetails = eventOccurrence || event;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGroupsIndex());
  }, [dispatch]);

  if (!eventDetails) {
    return <div />;
  }

  return (
    <div>
      <ContentView
        data={eventDetails}
      />
    </div>
  );
}
