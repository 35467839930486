import React, { ReactElement, useCallback, useEffect } from 'react';

import { Helmet } from 'react-helmet'
import { postLibraryContent } from 'reducers/LibraryContent';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { fetchUserDetail } from 'reducers/UserDetail';
import { RootState } from 'types/rootState';
import { ILibraryContent } from 'types/ILibraryContent';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import EditLibraryContentForm from './components/EditLibraryContentForm';

function LibraryContentNewEmailPage(): ReactElement {
  const dispatch = useDispatch()
  const history = useHistory();

  const onSubmit = useCallback(
    async (data: ILibraryContent) => {
      segmentAnalyticsTrack(trackActions.createLibrary(data));
      await dispatch(postLibraryContent(data,
        () => {
          toast.success('Content successfully created');
          history.push('/librarycontent');
        },
      ));
    },
    [dispatch, history],
  );

  useEffect(() => {
    dispatch(fetchUserDetail())
  }, [dispatch]);
  const defaultData = useSelector((state: RootState) => state.UserDetail)

  const defaultInputLanguage = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.InputLanguageDefaultValue);

  if (!defaultData?.data) {
    return null
  }
  const newEmailContent: ILibraryContent = {
    ID: '0',
    Name: '',
    Description: '',
    Type: 'Email',
    VoiceContent: undefined,
    SMSContent: undefined,
    EmailContent: {
      Subject: '',
      Display: defaultData.data.SenderDisplayName,
      From: defaultData.data.SenderEmailAddress,
      ReplyTo: defaultData.data.ReplyToEmailAddress,
      Content: '',
      Attachments: [],
    },
    SourceLanguage: defaultInputLanguage,
  }
  return (
    <Container>
      <Helmet>
        <title>Library Content</title>
      </Helmet>
      <div className="header-bar">
        <h1>New Email Content</h1>
      </div>
      <EditLibraryContentForm data={newEmailContent} onSubmit={onSubmit} />
    </Container>
  )
}
export default LibraryContentNewEmailPage
