import React, { ReactElement, ChangeEvent } from 'react';

import { Controller } from 'react-hook-form';

import ControlledInput, { InputProps } from '../Inputs/Input';
import { IFormControlProps } from './IFormControlProps';

interface IProps extends Omit<IFormControlProps, 'defaultValue'>, Omit<InputProps, 'name'> {
  type: string
  mask?: string
  autoComplete?: string
  placeholder?: string
  required?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  id: string
}

function Input({
  name,
  control,
  rules,
  defaultValue = '',
  ...props
}: IProps): ReactElement {
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
      render={(renderProps) => (
        <ControlledInput
          {...renderProps}
          {...props}
        />
      )}
    />
  );
}

export default Input;
