import React, { ReactElement } from 'react';

import { Controller } from 'react-hook-form';

import ControlledSearchInput, { SearchInputProps } from '../Inputs/SearchInput';
import { IFormControlProps } from './IFormControlProps';

interface IProps extends Omit<IFormControlProps, 'defaultValue' | 'id' >, Omit<SearchInputProps, 'name'> {}

function SearchInput({
  name,
  control,
  rules,
  defaultValue = '',
  ...props
}: IProps): ReactElement {
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
      render={(renderProps) => (
        <ControlledSearchInput
          {...renderProps}
          {...props}
        />
      )}
    />
  );
}

export default SearchInput;
