import React, {
  ReactElement, useCallback, useRef, useState,
} from 'react';
import { Helmet } from 'react-helmet';
import API from 'api/API';
import { useForm } from 'react-hook-form';
import {
  useAccordionToggle, Button, Spinner,
} from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'components/Table';
import { DateInput } from 'components/FormControls';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import { Column } from 'react-table';

function EnterpriseReportPage(): ReactElement {
  const { control, handleSubmit } = useForm();
  const [reportResults, setReportResults] = useState<Record<string, any>>();
  const [reportIsRunning, setReportIsRunning] = useState(false);
  const csvLink = useRef<CSVLink>();
  const [reportExportLoading, setReportExportLoading] = useState(false);
  const userInfo = useSelector((state: RootState) => state.UserInfo);
  const acctTimezone = userInfo.accountTimezone;
  const acctNow = moment().tz(acctTimezone);
  const toggleAccordion = useAccordionToggle('0');
  const downloadHandler = async () => {
    if (reportExportLoading) return;
    setReportExportLoading(true);
    csvLink.current.link.click();
    setReportExportLoading(false);
  }

  const columns: Array<Column> = [{
    Header: 'Account',
    accessor: 'Account_Name',
    Cell: useCallback(
      ({ cell }) => <span title={cell.value}>{cell.value.substring(0, Math.min(15, cell.value.length))}</span>, [],
    ),
  },
  {
    Header: 'Name',
    accessor: 'eventname',
    Cell: useCallback(
      ({ cell }) => <span title={cell.value}>{cell.value.substring(0, Math.min(20, cell.value.length))}</span>,
      [],
    ),
  },
  {
    Header: 'Date',
    accessor: 'scheduledsenddatelocal',
    Cell: useCallback(
      ({ cell }) => <>{moment(cell.value).format('M/D/YYYY')}</>,
      [],
    ),
  },
  {
    Header: 'Time',
    accessor: 'sendtime',
    Cell: useCallback(
      ({ cell }) => <>{moment.utc(cell.value).tz(acctTimezone).format('h:mm A')}</>,
      [acctTimezone],
    ),
  },
  {
    Header: 'Voice Sent',
    accessor: 'voiceSent',
  },
  {
    Header: 'Voice Errors',
    accessor: 'voiceFailed',
  },
  {
    Header: 'SMS Sent',
    accessor: 'smsSent',
  },
  {
    Header: 'SMS Errors',
    accessor: 'smsFailed',
  },
  {
    Header: 'Email Sent',
    accessor: 'emailSent',
  },
  {
    Header: 'Email Errors',
    accessor: 'emailFailed',
  },
  ]
  const onSubmit = useCallback(async (data) => {
    setReportIsRunning(true);
    const start = `${data.startDate} 00:00:00`;
    const end = `${data.endDate} 23:59:59`
    const results:Record<string, any> = await API.post('api/v2/report', {
      ReportName: 'EnterpriseSummary',
      Parameters: {
        '@startDate': moment(start).format('yyyy-MM-DD HH:mm:ss'),
        '@endDate': moment(end).format('yyyy-MM-DD HH:mm:ss'),
        '@startDateUtc': moment.tz(start, acctTimezone).utc().format('yyyy-MM-DD HH:mm:ss'),
        '@endDateUtc': moment.tz(end, acctTimezone).utc().format('yyyy-MM-DD HH:mm:ss'),
      },
      ContextType: 'Organization',
    });
    setReportResults(results.data);
    setReportIsRunning(false);
    toggleAccordion(null);
  }, [acctTimezone, toggleAccordion]);
  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <div className="container reports">
        <h3>Enterprise Messages Report</h3>
        <p>Messages sent across all accounts</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-row mb-3">
            <div className="col-3">
              <DateInput
                id="startDate"
                label="Start"
                control={control}
                name="startDate"
                format="yyyy-MM-DD"
                defaultValue={acctNow.startOf('month').format('yyyy-MM-DD')}
              />
            </div>
            <div className="col-3">
              <DateInput
                id="endDate"
                label="End"
                control={control}
                name="endDate"
                format="yyyy-MM-DD"
                defaultValue={acctNow.endOf('month').format('yyyy-MM-DD')}
              />
            </div>
            <div className="p-2 align-self-end">
              <Button
                style={{ width: '160px', display: 'flex', alignItems: 'center' }}
                type="submit"
                disabled={reportIsRunning}
              >
                <span className="pr-1">
                  Run Report
                </span>
                {reportIsRunning && <Spinner className="pl-2" as="span" animation="border" />}
              </Button>

            </div>
            <div className="p-2 ">
              {!reportIsRunning && reportResults && (
                <>
                  <button type="button" className="btn btn-link" onClick={async () => { await downloadHandler(); }}>
                    <FontAwesomeIcon icon="file-export" />
                    {' '}
                    { reportExportLoading
                      ? ('Exporting...' && <Spinner className="pl-2" as="span" animation="border" />)
                      : 'Export Data' }
                  </button>
                  <CSVLink
                    data={reportResults?.Results || []}
                    ref={csvLink}
                    filename="report.csv"
                    className="hidden"
                    target="_blank"
                    asyncOnClick
                  />
                </>
              ) }
            </div>

          </div>
        </form>
      </div>
      {!reportIsRunning
      && reportResults
      && (
        <Table
          columns={(reportResults.Results.length > 0
            ? columns
            : []) as any}
          data={reportResults.Results || []}
          enableCheck={false}
        />
      )}
    </>
  )
}

export default EnterpriseReportPage;
