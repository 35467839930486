import React, {
  DetailedHTMLProps, InputHTMLAttributes, forwardRef,
} from 'react';
import clsx from 'clsx';

import { ReactComponent as Check } from 'styles/images/check.svg';

export interface ICheckboxProps
  extends DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
  > {
  name: string
  label?: string
  className?: string
}

const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  ({
    id, name, label, className = '', style = {}, ...props
  }, ref) => {
    const inputId = id;

    return (
      <div className={clsx('Checkbox form-check', className)} style={style}>
        <input
          type="checkbox"
          className="form-check-input"
          name={name}
          id={inputId}
          ref={ref}
          {...props}
        />
        <Check className="form-check-icon" width="10" height="10" />
        {label && (
          <label className="form-check-label" htmlFor={inputId}>
            {label}
          </label>
        )}
      </div>
    )
  },
);

export default Checkbox;
