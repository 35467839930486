import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ManualPhoneCallIcon(): ReactElement {
  return (
    <span style={{
      padding: '5px 7px 5px 0px',
      marginLeft: '5px',
      borderRadius: '5px',
    }}
    >
      <span
        className="fa-layers fa-fw"
      >
        <FontAwesomeIcon icon="phone" transform="right-6 down-3" />
        <FontAwesomeIcon icon="user" transform="shrink-6 left-3 up-3" />
      </span>
    </span>
  );
}
export default ManualPhoneCallIcon;
