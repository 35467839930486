import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, Redirect } from 'react-router-dom';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useQueryParam, NumberParam, StringParam, BooleanParam,
} from 'use-query-params';

import { useHasRoles } from 'hooks/useHasRoles';
import { useHasFeatures } from 'hooks/useHasFeatures';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { ViewLabels } from 'components/ViewLabels';
import { ReactComponent as Plus } from 'styles/images/plus.svg';
import { ReactComponent as CreateTemplateSvg } from 'styles/images/create-template.svg';
import IndexFilter, {
  ISearchQuery,
} from 'components/Inputs/IndexFilter_Id_Name';
import { TemplateTable } from 'components/Templates/TemplateTable';

const CreateTemplateModal: FC<{ onHide: ()=> void }> = ({ onHide }) => {
  const history = useHistory();
  const isMarketing = history.location.pathname.startsWith('/marketing-templates');
  let entityName;
  let basePath;
  let description;

  if (isMarketing) {
    entityName = 'Marketing Email';
    description = 'Marketing Emails let you share campaigns to your entire mailing list.';
    basePath = '/marketing-templates';
  } else {
    entityName = 'Template';
    basePath = '/templates';
    description = 'Templates let you broadcast content across multiple communication channels.';
  }

  return (
    <Modal
      show
      size="lg"
      onHide={onHide}
      centered
      className="create-template-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <CreateTemplateSvg />
          Create
          {' '}
          {entityName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="description">
          {description}
        </p>
        <div className="d-flex">
          <button
            type="button"
            onClick={() => {
              history.push(`${basePath}/new`)
            }}
          >
            <FontAwesomeIcon icon="folder-plus" color="var(--blue)" size="4x" />
            <span className="button-title">
              Blank
            </span>
            <span className="button-description">
              Start from scratch to create a new
              {' '}
              {isMarketing
                ? 'Marketing Email'
                : 'template'}
            </span>
          </button>

          <button
            type="button"
            onClick={() => {
              history.push(`${basePath}/blueprint`)
            }}
          >
            <FontAwesomeIcon icon="folder-open" color="var(--blue)" size="4x" />
            <span className="button-title">
              Blueprint
            </span>
            <span className="button-description">
              Speed up your workflow with one of Icon’s
              suggested default templates
            </span>
          </button>

          <button
            type="button"
            onClick={() => {
              history.push(`${basePath}/clone`)
            }}
          >
            <FontAwesomeIcon icon="clone" color="var(--blue)" size="4x" />
            <span className="button-title">
              Clone
            </span>
            <span className="button-description">
              Copy an existing
              {' '}
              {isMarketing && 'Marketing Email '}
              template from your account(s)
              and make it your own
            </span>

          </button>
        </div>

      </Modal.Body>
    </Modal>
  )
}

const TemplatesIndexPage: FC = () => {
  const [showChooseModal, setShowChooseModal] = React.useState(false);
  const history = useHistory();
  const [searchQueryParam, setSearchQueryParam] = useQueryParam('searchQuery', StringParam);
  const [, setPageParam] = useQueryParam('page', NumberParam);
  const [isDraftParam = false, setIsDraftParam] = useQueryParam('isDraft', BooleanParam);
  const [
    hasReadMarketingPermission, hasMarketingWritePermission,
  ] = useHasPermissions(['Marketing:Send', 'Marketing:Edit']);
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const { hasFeature: hasMarketingFeature, isLoading: isFeaturesLoading } = useHasFeatures('marketing-emails');

  const isMarketing = history.location.pathname.startsWith('/marketing-templates');
  const canReadMarketing = (hasReadMarketingPermission || isAdmin || isSuper) && hasMarketingFeature;
  const canWriteMarketing = (hasMarketingWritePermission || isAdmin || isSuper) && hasMarketingFeature;

  const searchQuery = React.useMemo((): ISearchQuery => {
    if (!searchQueryParam) {
      return {};
    }
    const [searchField, searchValue] = searchQueryParam.split(',');
    return { searchField, searchValue }
  }, [searchQueryParam]);

  const onResetFilters = () => {
    history.push(`${history.location.pathname}${isDraftParam ? '?isDraft=1' : ''}`);
  }

  const onSearch = (newSearchQuery: ISearchQuery): void => {
    if (newSearchQuery.searchField || newSearchQuery.searchValue) {
      setSearchQueryParam(
        `${newSearchQuery.searchField || ''},${
          newSearchQuery?.searchValue || ''
        }`,
        'pushIn');
    } else {
      setSearchQueryParam('', 'pushIn');
    }
    setPageParam(1, 'replaceIn');
  };

  if (isMarketing) {
    if (isFeaturesLoading) {
      return null;
    }
    if (!canReadMarketing) {
      return <Redirect to="/" />
    }
  }

  return (
    <div className="Templates">
      <Helmet>
        <title>
          {isMarketing
            ? 'Marketing Emails'
            : 'Templates'}

        </title>
      </Helmet>
      {isMarketing && (
        <>
          <h2 className="Templates__title">
            Marketing Emails
          </h2>
          <p className="Templates__description">
            These emails can be designed in a robust, rich format. They will also be sent with the option for recipients to unsubscribe or adjust notification settings.
          </p>
        </>
      )}
      <div className="header-bar">
        {(!isMarketing || canWriteMarketing) && (
          <button
            type="button"
            id="create-template"
            role="link"
            className="btn btn-lg btn-warning mb-2 mr-auto"
            onClick={() => setShowChooseModal(true)}
          >
            <Plus
              width="24"
              height="24"
              fill="currentColor"
              style={{ marginRight: '10px' }}
            />
            Add Template
          </button>
        )}

        <IndexFilter
          searchQuery={searchQuery}
          onSearch={onSearch}
          onResetFilters={onResetFilters}
          title={isDraftParam
            ? 'Draft'
            : 'Template'}
          showTotalItems={false}
          defaultField="name"
          options={[{ label: 'Label', value: 'label' }]}
        />
        <ViewLabels />
      </div>

      <Tabs
        activeKey={isDraftParam ? 'drafts' : 'templates'}
        onSelect={(newActiveKey) => {
          const newIsDraftParam = newActiveKey === 'drafts';
          if (newIsDraftParam !== isDraftParam) {
            setIsDraftParam(newIsDraftParam, 'pushIn');
            setPageParam(1, 'replaceIn');
          }
        }}
        className="vfTabs"
        id="template-drafts-tabs"
        mountOnEnter
      >
        <Tab eventKey="templates" title="Templates">
          <TemplateTable
            showActions
            templateFilterType="template"
          />
        </Tab>
        <Tab eventKey="drafts" title="Drafts">
          <TemplateTable
            showActions
            templateFilterType="template"
          />
        </Tab>
      </Tabs>

      {showChooseModal && (
        <CreateTemplateModal
          onHide={() => {
            setShowChooseModal(false);
          }}
        />
      )}
    </div>
  );
};

export default TemplatesIndexPage;
