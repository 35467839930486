import React, { useState, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { stopEventSeries } from 'reducers/Calendar';
import moment from 'moment';
import { getEventsIndex } from 'reducers/Events';

interface IProps {
  automaticId: string | number
  onSuccess?: () => void
}

function DeleteActionButton({ automaticId, onSuccess }: IProps): ReactElement {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [interactionDisabled, setInteractionDisabled] = useState<boolean>(false);

  const stopAutomaticEvent = (): void => {
    setInteractionDisabled(true);
    dispatch(stopEventSeries(Number(automaticId), moment().toDate(), () => {
      if (onSuccess) {
        onSuccess();
      }
      toast.success('Event successfully stopped');
    }));

    setInteractionDisabled(false);
    setShowModal(false);
  };

  return (
    <>
      <a
        href="/events/automatic"
        onClick={(e) => { e.preventDefault(); setShowModal(true) }}
      >
        Stop
      </a>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="DeleteActionButtonModal"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton={!interactionDisabled} />
        <Modal.Body>
          <FontAwesomeIcon
            icon="trash-alt"
            size="4x"
            color="#FF7052"
          />

          <div className="message">
            <h4>Are you sure?</h4>
            <p>
              This action will stop event
              {' '}
              {automaticId}
              .
            </p>
          </div>

          <button
            type="button"
            className="btn btn-block btn-danger"
            disabled={interactionDisabled}
            onClick={async () => {
              await stopAutomaticEvent();
              dispatch(getEventsIndex({ page: 1, perpage: 10, type: 'compliancelifedate' }));
            }}
          >
            Stop
          </button>
          <button
            type="button"
            className="btn btn-block btn-secondary"
            disabled={interactionDisabled}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteActionButton;
