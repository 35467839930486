/* eslint-disable no-param-reassign */
import React from 'react';
import escapeHtml from 'escape-html';
import _omit from 'lodash/omit';
import clsx from 'clsx';

import { getCursorPosition, setCursorPosition } from 'utils/contentEditable';

const selectContent = `(() => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(this);
    selection.removeAllRanges();
    selection.addRange(range);
  })()`

const parseToHtml = (text: string, highlights: string[]) => {
  if (!text) {
    return text;
  }

  const htmlString = highlights.reduce(
    (acc, highlight) => {
      const escapedHighlight = escapeHtml(highlight);
      return acc.replaceAll(
        escapedHighlight,
        `<strong onclick="${selectContent}">${escapedHighlight}</strong>`,
      );
    },
    escapeHtml(text),
  );

  return htmlString;
};

const removeNewlines = (text: string) => {
  const textWithoutNewlines = text.replace(/(\n|\r)+/g, '');
  return textWithoutNewlines;
}

interface ContentEditableSharedProps {
  value?: null|string
  highlights?: string[]
  onChange?: (...event: any[]) => void
  oneLine?: boolean
}

export type ContentEditableInputProps = ContentEditableSharedProps &
Omit<React.InputHTMLAttributes<HTMLDivElement>, 'value'|'onChange'>

export type ContentEditableTextareaProps = ContentEditableSharedProps &
Omit<React.TextareaHTMLAttributes<HTMLDivElement>, 'value'|'onChange'>

/**
   * @desc component for highlighting (bolding) text
  /*  */
export const ContentEditable = React.forwardRef<HTMLDivElement, ContentEditableInputProps&ContentEditableTextareaProps>(
  ({
    value,
    maxLength,
    disabled,
    readOnly,
    highlights,
    onChange,
    className,
    style,
    oneLine = false,
    placeholder,
    rows,
    onKeyDown,
    ...props
  },
  ref,
  ) => {
    const divRef = React.useRef<null|HTMLDivElement>(null);
    const lastValueRef = React.useRef<null|string>(null);

    const cleanValue = (
      oneLine
        ? removeNewlines(value ?? '')
        : (value ?? '').replace(/^\n$/, '')
    ).slice(0, maxLength);

    React.useLayoutEffect(() => {
    // sets initial html and handles value update from outside
      if (divRef.current && cleanValue !== lastValueRef.current) {
        divRef.current.innerHTML = parseToHtml(cleanValue, highlights);
        lastValueRef.current = cleanValue;
      }
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [cleanValue]);

    const defaultStyle = (!oneLine && rows)
      ? { height: rows * 24 + 28 }
      : null;

    return (
      <div
        tabIndex={0}
        role="textbox"
        ref={(node) => {
          divRef.current = node;

          if (typeof ref === 'function') {
            ref(node);
            return;
          }
          ref.current = node;
        }}
        contentEditable={!disabled && !readOnly}
        onKeyDown={(e) => {
          // to prevent inserting divs and brs
          if (e.key === 'Enter') {
            if (!oneLine) {
              document.execCommand('insertLineBreak')
            }
            e.preventDefault();
          }
          if (onKeyDown) {
            onKeyDown(e);
          }
        }}
        onInput={(e) => {
          const text = e.currentTarget.innerText;
          const newValue = (
            oneLine
              ? removeNewlines(text)
              : text.replace(/^\n$/, '')
          ).slice(0, maxLength);
          lastValueRef.current = newValue;

          const newHtml = parseToHtml(newValue, highlights);

          if (newHtml !== divRef.current.innerHTML) {
            // save selection
            const sel = window.getSelection();
            const node = sel.focusNode;
            const offset = sel.focusOffset;
            const pos = getCursorPosition(divRef.current, node, offset, { pos: 0, done: false });
            if (offset === 0) {
              pos.pos += 0.5;
            }

            divRef.current.innerHTML = newHtml;

            // restore selection
            if (newValue) {
              sel.removeAllRanges();
              const range = setCursorPosition(divRef.current, document.createRange(), {
                pos: pos.pos,
                done: false,
              });
              range.collapse(true);
              sel.addRange(range);
            }
          }

          onChange(newValue);
        }}
        className={clsx(className, !cleanValue.length && 'isBlank', oneLine && 'oneLine')}
        style={{ ...defaultStyle, ...style }}
        data-placeholder={placeholder}
        {..._omit(props, ['name'])}
      />
    )
  })
