import React, { ReactElement, MouseEvent as ReactMouseEvent } from 'react';
import TwoButtonPopover from './TwoButtonPopover';

const DeleteButton = ({
  handleDelete,
  title,
  confirmationMessage,
  onClick,
}: {
  confirmationMessage: string
  handleDelete: () => void
  title: string
  onClick?:(e:ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void
}): ReactElement => (
  <TwoButtonPopover
    id="DeleteButtonPopover"
    content={confirmationMessage}
    onPopoverComplete={handleDelete}
    title={title}
    onClick={onClick}
  />
)

export default DeleteButton;
