import React, { forwardRef } from 'react';
import Input, { InputProps } from './Input';

export interface SearchInputProps extends Omit<InputProps, 'type'> {
  type?: string
}

export const ColorInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({
    className, type = 'text', ...props
  }, ref) => (
    <div style={{ position: 'relative' }}>
      <Input
        className={className}
        type={type}
        ref={ref}
        {...props}
      />
      <input type="color" {...props} style={{ position: 'absolute', top: 9, right: 10 }} />
    </div>
  ),
);

export default ColorInput;
