import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const IdleTimeOutModal = ({
  showModal, handleClose, handleLogout, timeUntilLogout, setTimeUntilLogout,
}) => {
  useEffect(() => {
    if (timeUntilLogout > 0 && showModal) {
      setTimeout(() => {
        setTimeUntilLogout(timeUntilLogout - 1)
      }, 1000);
    } else if (timeUntilLogout === 0 && showModal) {
      setTimeout(() => {
        handleLogout()
      }, 1000);
    }
  }, [handleLogout, setTimeUntilLogout, showModal, timeUntilLogout]);

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header
        closeButton
      >
        <Modal.Title
          style={{ paddingLeft: '9rem', paddingRight: '7rem' }}
        >
          Session Timeout
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ textAlign: 'center', paddingLeft: '0rem', paddingRight: '0rem' }}
      >
        You will be logged out in
        {' '}
        {timeUntilLogout}
        {' '}
        seconds.
        <br />
        Do you want to stay logged in?
      </Modal.Body>
      <Modal.Footer
        style={{ alignSelf: 'center' }}
      >
        <Button variant="outline-primary" onClick={handleClose}>
          Yes, Stay Logged In
        </Button>
        <Button variant="primary" onClick={handleLogout}>
          No, Log Me Out
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
