import React, { FC } from 'react';

import {
  Form,
} from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import {
  TemplateAndEvent, TemplateOrEvent,
} from 'types/IEvent';
import { EventScheduler } from './EventScheduler';

type Props = {
  data: TemplateOrEvent
  onSubmit: (data: TemplateOrEvent) => void
};

export const EventSchedulerForm: FC<Props> = ({
  data,
  onSubmit,
}) => {
  const methods = useForm<TemplateAndEvent>({
    mode: 'onBlur',
    defaultValues: {
      RRule: data.RRule,
      StartDate: data.StartDate,
      SendTime: data.SendTime,
      SendMode: data.SendMode,
      ExpirationTime: data.ExpirationTime,
    },
  });
  const {
    handleSubmit,
    formState,
  } = methods;

  const onSubmitForm = handleSubmit(onSubmit);

  const messageObj = {
    title: 'Survey Changes',
    messageText: 'You have unsaved changes, are you sure you want to leave?',
  };
  return (
    <>
      <Prompt
        when={!formState.isSubmitting && !formState.isSubmitted}
        message={JSON.stringify(messageObj)}
      />
      <FormProvider {...methods}>
        <Form onSubmit={onSubmitForm}>
          <EventScheduler data={data} />
        </Form>
      </FormProvider>
    </>
  );
};
