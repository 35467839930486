import React, {
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import Input from 'components/Inputs/Input';
import {
  Col, Container, ListGroup, Row,
} from 'react-bootstrap';
import useSWR from 'swr';
import { getUserState, getAllAccounts as selectAllAccounts } from 'reducers/UserInfo';
import { useSelector } from 'react-redux';
import * as UserInfoAPI from 'api/UserInfoAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Prompt } from 'react-router-dom';
import { getB64StringFromFile } from 'utils/file';
import ColorInput from 'components/Inputs/ColorInput';
import Select from 'react-select';
import Checkbox from 'components/Inputs/Checkbox';

const domainPattern = /^[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/i;

function AccountSettingsPage(): ReactElement {
  const userState = useSelector(getUserState);
  const allAccounts = useSelector(selectAllAccounts);
  const accountId = userState.userInfo?.AccountDetail?.AccountID ?? '';

  const { data: dbPermittedDomains, mutate, isLoading } = useSWR(
    ['permitted-domains', accountId],
    () => UserInfoAPI.getAccountPermittedDomain(),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const messageObj = {
    title: 'Account Setting Changes',
    messageText: 'You have unsaved changes, are you sure you want to leave?',
  };

  const [domainName, setDomainName] = useState('');
  const [domainList, setDomainList] = useState<string[]>([]);

  const sortedDbDomains = useMemo(() => dbPermittedDomains?.sort((a, b) => a.localeCompare(b)), [dbPermittedDomains]);
  const sortedNewDomains = useMemo(() => domainList?.sort((a, b) => a.localeCompare(b)), [domainList]);
  // only show the save button when the new domain list is different from the orignial one in database.
  const saveEnabled = useMemo(
    () => JSON.stringify(sortedDbDomains) !== JSON.stringify(sortedNewDomains), [sortedDbDomains, sortedNewDomains]);
  const [isSaving, setIsSaving] = useState<boolean>();
  const saveDisabled = !saveEnabled || isSaving;
  const defaultParentAccountOption = { value: '0', label: 'No Parent Account Selected' };

  const accountsOptions = React.useMemo(() => {
    if (!allAccounts.length) {
      return [];
    }
    const options = [defaultParentAccountOption];
    allAccounts
      .map((account) => (options.push({
        value: `${account.Id}`,
        label: account.Name,
      })));
    return options;
  }, [allAccounts]);

  useEffect(() => {
    setDomainList(dbPermittedDomains);
  }, [dbPermittedDomains]);

  const addToDomainList = (domain): boolean => {
    if (domain.trim() === '') {
      return false;
    }

    if (domain.length > 253) {
      toast.error('The length of domain name should not longer than 253 characters.');
      return false;
    }

    if (!domainPattern.test(domain)) {
      toast.error(`'${domain}' is not a valid domain name.`);
      return false;
    }

    // deduplicate case insensetivily
    if (!domainList.some((d) => d.toLowerCase() == domain.toLowerCase())) {
      setDomainList(domainList.concat(domain));
    } else {
      toast.error(`'${domain}' has already been added to the list.`);
    }
    return true;
  };

  const onRemoveDomain = (domain) => {
    if (domainList.includes(domain)) {
      const newDomains = domainList.filter((d) => d !== domain);
      setDomainList(newDomains);
    }
  }

  const onPermittedDomainSave = async () => {
    setIsSaving(true);
    try {
      const savedDomains = await UserInfoAPI.setAccountPermittedDomain(domainList);
      setDomainList(savedDomains);
      mutate(savedDomains, {
        populateCache: true, // populate cache with saved domains
        revalidate: false, // do not refetch
      });
      toast.success('Permitted domain successfully saved.');
    } catch (error) {
      toast.error(error.response?.data?.Message ?? 'Something went wrong');
    } finally {
      setIsSaving(false);
    }
  }

  const renderDomainList = (): ReactElement => {
    if (isLoading || bfAccountCfgLoading) {
      return (
        <ListGroup>
          <ListGroup.Item>
            <label className="domain-name">Loading...</label>
          </ListGroup.Item>
        </ListGroup>
      )
    }

    return (
      <ListGroup>
        {
          domainList && domainList.map((domain) => (
            <ListGroup.Item
              key={domain}
            >
              <button
                className="px-1 remove-recipient-btn account-list"
                type="button"
                onClick={() => {
                  onRemoveDomain(domain);
                }}
              >
                <FontAwesomeIcon
                  icon="times"
                  size="sm"
                />
              </button>
              <label className="domain-name" title={domain}>{domain}</label>
            </ListGroup.Item>
          ),
          )
        }
      </ListGroup>
    )
  }

  const renderSavedPermittedDomains = (): ReactElement => {
    if (!isLoading && (!dbPermittedDomains || dbPermittedDomains.length === 0)) {
      return (
        <div className="empty-domain">No Permitted Domains have been set.</div>
      )
    }
    return (
      <ListGroup>
        {
          dbPermittedDomains && dbPermittedDomains.map((domain) => (
            <ListGroup.Item
              key={domain}
            >
              <label className="domain-name" title={domain}>{domain}</label>
            </ListGroup.Item>
          ),
          )
        }
      </ListGroup>
    )
  }

  const [senderName, setSenderName] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [replyEmail, setReplyEmail] = useState('');
  const [brandColor, setBrandColor] = useState('#0F4CDB');
  const [parentAccount, setParentAccount] = useState(defaultParentAccountOption);
  const [enabled, setEnabled] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#e0e0e0');
  // for displaying in image component
  // for uploading to backend
  const [brandLogoContent, setBrandLogoContent] = useState(null);
  const configModel = useMemo(() => {
    const config = {
      SenderDisplayName: senderName,
      SenderEmailAddress: senderEmail,
      ReplyEmailAddress: replyEmail,
      BrandColor: brandColor,
      BrandLogoContent: brandLogoContent,
      ParentAccountId: +(parentAccount?.value ?? 0),
      Enabled: enabled,
      BackgroundColor: backgroundColor,
    };
    return JSON.stringify(config);
  }, [senderName, senderEmail, replyEmail, brandColor, brandLogoContent, parentAccount, enabled, backgroundColor]);

  const { data: bfAccountConfigs, mutate: bfAccountCfgMutate, isLoading: bfAccountCfgLoading } = useSWR(
    ['broadcastforms-account-cfg', accountId],
    () => UserInfoAPI.getBroadcastFormsAccountConfig(),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const saveConfigEnabled = useMemo(
    () => JSON.stringify(bfAccountConfigs) !== configModel, [bfAccountConfigs, configModel]);
  const [isConfigSaving, setIsConfigSaving] = useState<boolean>();
  const saveConfigDisabled = !saveConfigEnabled || isConfigSaving;

  const setImageContent = (content) => {
    const img = document.getElementById('imgBrandLogo');
    if (content) {
      img?.setAttribute('src', content);
      img?.removeAttribute('hidden');
    } else {
      img.removeAttribute('src');
      img.setAttribute('hidden', 'hidden');
    }
  }

  const onUploadLogo = () => {
    const fileUploader = document.getElementById('fileUploader');
    fileUploader.click();
  }
  const onRemoveLogo = () => {
    setImageContent('');
    setBrandLogoContent(null);
  }

  useEffect(() => {
    if (bfAccountConfigs) {
      setSenderName(bfAccountConfigs.SenderDisplayName);
      setReplyEmail(bfAccountConfigs.ReplyEmailAddress);
      setSenderEmail(bfAccountConfigs.SenderEmailAddress);
      setBrandColor(bfAccountConfigs.BrandColor);
      setBackgroundColor(bfAccountConfigs.BackgroundColor);
      const selectedAccount = accountsOptions.find((account) => {
        if (+account.value === bfAccountConfigs.ParentAccountId) return account;
        return null;
      });
      setParentAccount(selectedAccount);
      if (bfAccountConfigs.BrandLogoContent) {
        setBrandLogoContent(bfAccountConfigs.BrandLogoContent);
        setImageContent(bfAccountConfigs.BrandLogoContent);
      }
      setEnabled(bfAccountConfigs.Enabled)
    }
  }, [accountsOptions, bfAccountConfigs]);

  const onBroadcastFormsAccountConfigSave = async () => {
    setIsConfigSaving(true);
    try {
      const data = {
        senderName,
        senderEmail,
        replyEmail,
        brandColor: brandColor ?? '#0F4CDB',
        brandLogo: brandLogoContent,
        parentAccountId: +parentAccount.value,
        enabled,
        backgroundColor: backgroundColor ?? '#e0e0e0',
      }
      if (!data.brandColor) {
        toast.error('The brand color is required.');
        setIsConfigSaving(false);
        return;
      }
      if (!data.backgroundColor) {
        toast.error('The background color is required.');
        setIsConfigSaving(false);
        return;
      }
      await UserInfoAPI.saveBroadcastFormsAccountConfig(data);
      bfAccountCfgMutate();
      toast.success('Broadcast Forms Account Configuration successfully saved.');
    } catch (error) {
      toast.error(error.response?.data?.Message ?? 'Something went wrong');
    } finally {
      setIsConfigSaving(false);
    }
  }

  return (
    <>
      <Prompt
        when={saveEnabled || saveConfigEnabled}
        message={JSON.stringify(messageObj)}
      />
      <Helmet>
        <title>Account Settings</title>
      </Helmet>
      <div className="container">
        <h3>Account Permitted Domains</h3>
        <p>
          Domains that have been DKIM registered have restricted usage. Add domains below to authorize this
          account to use a DKIM registered domain
        </p>
        <form>
          <Container fluid>
            <Row>
              <Col xs={5}>
                <Input
                  className="mb-0"
                  type="text"
                  label="Add authorized domain (enter to submit)"
                  placeholder="Email Domain"
                  value={domainName}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter') {
                      evt.preventDefault();
                      addToDomainList(domainName);
                      setDomainName('');
                    }
                  }}
                  onChange={(e) => {
                    setDomainName(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
              <Col xs={2}>
                <button
                  type="button"
                  className="btn btn-primary square mb-0 "
                  style={{ marginTop: 25 }}
                  onClick={() => {
                    if (addToDomainList(domainName)) {
                      setDomainName('');
                    }
                  }}
                >
                  Add
                </button>
              </Col>
            </Row>
            <Row>
              <Col xs={7} />
              <Col>
                <label
                  className="saved-caption"
                >
                  Currently saved permitted domains
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
                { renderDomainList() }
              </Col>
              <Col xs={2} />
              <Col>
                { renderSavedPermittedDomains() }
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col xs={5}>
                <button
                  type="button"
                  className="btn btn-primary square mb-0"
                  onClick={onPermittedDomainSave}
                  disabled={saveDisabled}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
      <hr className="broadcast-container" />
      <div className="container">
        <h3>Broadcast Forms Account Configuration</h3>
        <p>
          Enable feedback 360 module, configure the appearance, branding and default email sender (enterprise surveys)
          for any broadcast form content sent from this account.
        </p>
        <form>
          <Container fluid>
            <Row style={{ marginTop: 5 }}>
              <Col xs={10}>
                <Checkbox
                  id="Enabled"
                  name="Enabled"
                  label="Enable Feedback360 for this account"
                  checked={enabled}
                  onChange={(e) => {
                    setEnabled(e.target.checked);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={4}>
                <p><b>Brand Color</b></p>
                <ColorInput
                  type="text"
                  value={brandColor ?? '#0F4CDB'}
                  onChange={(e : any) => {
                    let hex = e.target.value;
                    if (hex.length > 7) {
                      e.preventDefault();
                      return;
                    }
                    if (hex.length === 6) {
                      hex = `#${e.target.value?.replace(/^#?/, '')}`;
                    }

                    setBrandColor(hex);
                  }}
                />
              </Col>
              <Col xs={4}>
                <p><b>Background Color</b></p>
                <ColorInput
                  type="text"
                  value={backgroundColor ?? '#e0e0e0'}
                  onChange={(e : any) => {
                    let hex = e.target.value;
                    if (hex.length > 7) {
                      e.preventDefault();
                      return;
                    }
                    if (hex.length === 6) {
                      hex = `#${e.target.value?.replace(/^#?/, '')}`;
                    }

                    setBackgroundColor(hex);
                  }}
                />
              </Col>
            </Row>
            <br />
            <p><b>Brand Logo</b></p>
            <Row>
              <Col xs={3}>
                <div className="broadcast-cell">
                  <img
                    alt="Brands logo"
                    id="imgBrandLogo"
                    hidden
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col xs={10}>
                <button
                  className="broadcast-button-upload"
                  type="button"
                  onClick={() => { onUploadLogo() }}
                >
                  <span style={{ color: '#595b5c' }}>Replace Logo</span>
                </button>
                <button
                  type="button"
                  className="broadcast-button-remove"
                  onClick={() => { onRemoveLogo() }}
                >
                  <span style={{ color: '#a60202' }}>Remove Logo</span>
                </button>
              </Col>
            </Row>
            <Input
              type="file"
              id="fileUploader"
              accept="image/png, image/jpeg, image/svg+xml"
              onChange={async (e) => {
                const result = await getB64StringFromFile(e.target.files[0]);
                setImageContent(result);
                setBrandLogoContent(result);
              }}
              hidden
            />
            <br />
            <p><b>Enterprise Survey Parent Account</b></p>
            <p>
              Optional: To enable multi-account surveys, select the parent account for this account.
              The parent account will then be able to send multi-account surveys to this account.
              Select &quot;No Parent Account Selected&quot; if this account should not receive enterprise surveys.
            </p>
            <Row>
              <Col xs={12} md={6}>
                <Select
                  id="ParentAccount"
                  name="ParentAccount"
                  value={parentAccount}
                  label="Enterprise Survey Parent Account"
                  placeholder="No Parent Account Selected"
                  className="selectInputPaddingRight"
                  options={accountsOptions}
                  onChange={(value) => {
                    setParentAccount(value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <p><b>Enterprise Survey Email Sender</b></p>
            <p>
              This is the email sender information used by this account when sending out surveys created
              by this accounts Enterprise survey parent account. If an enterprise survey parent account is
              specified, this value MUST be set.
            </p>
            <Row>
              <Col xs={3}>
                <Input
                  className="mb-0"
                  type="text"
                  label="Sender's Display Name"
                  name="SenderDisplayName"
                  maxLength={255}
                  value={senderName}
                  onChange={(e) => {
                    setSenderName(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
              <Col xs={3}>
                <Input
                  className="mb-0"
                  type="text"
                  name="SenderEmailAddress"
                  label="Sender's Email Address"
                  maxLength={64}
                  value={senderEmail}
                  onChange={(e) => {
                    setSenderEmail(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
              <Col xs={3}>
                <Input
                  className="mb-0"
                  type="text"
                  name="ReplyEmailAddress"
                  label="Reply-To Email Address"
                  maxLength={64}
                  value={replyEmail}
                  onChange={(e) => {
                    setReplyEmail(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={5}>
                <button
                  type="button"
                  className="btn btn-primary square mb-0"
                  disabled={saveConfigDisabled}
                  onClick={onBroadcastFormsAccountConfigSave}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  )
}

export default AccountSettingsPage;
