import React from 'react';
import {
  Row, Col, Form, Spinner,
} from 'react-bootstrap';
import {
  Controller, useForm, FormProvider, UseFormMethods,
} from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import { useSWRConfig } from 'swr';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import { EventTransformer } from 'transformers/Event';
import CommunityNameWarningModal from 'pages/Templates/CommunityNameWarningModal';
import { selectCommunityName, selectCustomFieldNames, selectAccountTz } from 'reducers/UserInfo';
import { Input } from 'components/FormControls';
import MergeFields from 'components/MergeFields';
import { ContentLength } from 'components/EventTemplateForm/ContentLength';
import Textarea from 'components/Inputs/Textarea';
import { DistributionList } from 'components/EventTemplateForm/DistributionList';
import { ReactComponent as SendArrowSvg } from 'styles/images/send-arrow.svg';
import { selectEventsLoading, createEvent } from 'reducers/Events';
import { BroadcastFormValues } from 'types/BroadcastFormValues';
import { getCalendarEvents, selectCalendarFilter } from 'reducers/Calendar';
import { EmailFields } from './EmailFields';
import { GroupsValidator } from './GroupsValidator';
import { ScheduleModal } from './ScheduleModal';
import { CommunityNameWarningValidator } from './CommunityNameWarningValidator';

const useShouldShowCommunityNameWarning = (
  getValues: UseFormMethods<BroadcastFormValues>['getValues'],
) => {
  const communityName = useSelector(selectCommunityName);

  const getter = () => {
    const content = getValues('Content');
    if ((communityName === null || communityName === '')
    && content?.includes('$$CommunityName$$')) {
      return true;
    }

    return false;
  }

  return getter;
}

const channelOptions = [{
  label: 'All',
  value: 'all',
}, {
  label: 'Email',
  value: 'email',
}, {
  label: 'SMS/Text',
  value: 'sms',
}, {
  label: 'Voice',
  value: 'voice',
}]

interface BroadcastFormProps {
  hideModal: () => void
  interactionEnabled: boolean
}

export const BroadcastForm = React.forwardRef<any, BroadcastFormProps>(
  ({ hideModal, interactionEnabled }, ref) => {
    const location = useLocation();
    const textboxRef = React.useRef<HTMLDivElement|null>(null);
    const accountTz = useSelector(selectAccountTz);
    const customFieldNames = useSelector(selectCustomFieldNames);
    const [showScheduleModal, setShowScheduleModal] = React.useState(false);
    const [showCommunityWarningModal, setShowCommunityWarningModal] = React.useState(false);
    const [data, setData] = React.useState<BroadcastFormValues|null>(null);
    const isSaving = useSelector(selectEventsLoading);
    const { mutate } = useSWRConfig();
    const dispatch = useDispatch();
    const calendarFilter = useSelector(selectCalendarFilter);

    const methods = useForm<BroadcastFormValues>({
      mode: 'onBlur',
      defaultValues: {
        Subject: '',
        Content: '',
        Channels: 'all,sms,voice,email',
        GroupIds: [],
      },
    });

    const {
      errors, handleSubmit, control, trigger, getValues, formState,
    } = methods;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useImperativeHandle(ref, () => formState, [formState.isDirty, formState.isSubmitted, formState.isSubmitting]);

    const getShouldShowCommunityNameWarning = useShouldShowCommunityNameWarning(getValues);

    const handleSave = (formData: BroadcastFormValues) => {
      const submitData = EventTransformer.broadcastToAPI(formData, accountTz);
      segmentAnalyticsTrack(trackActions.createEvent(submitData));

      const onSuccess = async (receivedEvent) => {
        setShowScheduleModal(false);
        hideModal();
        const m = moment(`${receivedEvent.StartDate} ${receivedEvent.SendTime}`, 'yyyy-MM-DD HH:mm');
        const msg = `Broadcast successfully scheduled for one time on ${m.calendar()}`;
        toast.success(msg);

        if (/^\/calendar\/?$/.test(location.pathname) && calendarFilter) {
          dispatch(getCalendarEvents(calendarFilter));
        }

        await mutate(
          ['event', receivedEvent.ID],
          receivedEvent,
          {
            revalidate: false,
            populateCache: true,
          },
        );
      }

      dispatch(createEvent(
        submitData,
        onSuccess,
      ));
    }

    const onSubmit = handleSubmit((formData) => {
      if (getShouldShowCommunityNameWarning()) {
        setShowCommunityWarningModal(true);
        setData(formData);
        return;
      }
      handleSave(formData);
    })

    const onScheduleAndSend = async () => {
      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      setShowScheduleModal(true);
    }

    return (
      <FormProvider {...methods}>
        <Form id="broadcast-form" onSubmit={onSubmit}>
          <Row>
            <Col className="left-half">
              <CommunityNameWarningValidator />
              <Input
                highlights={['<Replace Text Here>']}
                id="EmailContentSubject"
                name="Subject"
                label="Subject"
                type="text"
                control={control}
                maxLength={128}
                className="emailInput"
              />

              <Controller
                rules={{
                  required: 'Content Required',
                  validate: (val: any) => {
                    if (val === null || val === undefined || val.trim() === '') {
                      return 'Content Required';
                    }
                    const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
                    if (regex.test(val)) {
                      return 'Content can not include emojis';
                    }

                    const mergeFields = ['FirstName', 'LastName', 'PhoneNumber', 'EmailAddress', 'Mobile', 'Location', 'Date', 'CommunityName'];
                    let tempContent = val;
                    mergeFields.map((f) => {
                      tempContent = tempContent.replaceAll(`$$${f}$$`, '');
                    });
                    customFieldNames.map((f) => {
                      tempContent = tempContent.replaceAll(`$$${f}$$`, '');
                    });
                    if (tempContent.trim() === '') {
                      return 'Content cannot contain merge fields only'
                    }

                    return true;
                  },
                }}
                control={control}
                name="Content"
                render={({ ref: _ref, ...props }) => (
                  <>
                    <div className="personalization-container">
                      <ContentLength
                        name="Content"
                        maxLength={472}
                      />
                      <MergeFields
                        dropdownId="sms-content-mergefield"
                        type="voice"
                        textbox={textboxRef}
                        {...props}
                      />

                    </div>
                    <Textarea
                      required
                      {...props}
                      highlights={['<Replace Text Here>']}
                      ref={(node: HTMLDivElement) => {
                        textboxRef.current = node;
                        // eslint-disable-next-line no-param-reassign
                        _ref.current = node;
                      }}
                      label="Content"
                      id="voice-content"
                      maxLength={472}
                      rows={5}
                      errors={errors?.Content?.message}
                    />
                  </>
                )}
              />

              <Controller
                name="Channels"
                control={control}
                rules={{
                  required: 'Channel Required',
                }}
                render={({ value = '', onChange, onBlur }) => {
                  const renderVal = (arrVal: string[]) => {
                    if (!arrVal.length) {
                      return <span className="select-placeholder">Select</span>;
                    }

                    if (arrVal.includes('all')) {
                      return 'All';
                    }

                    return arrVal.map((v) => channelOptions.find((opt) => opt.value === v)?.label ?? v).join(', ')
                  };

                  const handleChange = (event: React.ChangeEvent<{ value: string[] }>, child) => {
                    let res = event.target.value.filter(Boolean);
                    if (child.key.includes('all')) {
                      if (res.includes('all')) {
                        channelOptions.forEach((option) => {
                          if (!res.includes(option.value)) {
                            res.push(option.value)
                          }
                        })
                      } else {
                        res = [];
                      }
                    } else if (res.includes('all')) {
                      res = res.filter((item) => item !== 'all');
                    } else if (res.length === 3) {
                      res.push('all')
                    }

                    onChange(res.join(','));
                  }

                  return (
                    <Form.Group>
                      <label htmlFor="channels-checkbox">Channel</label>
                      <span className="required-asterisk">*</span>
                      <Select
                        id="channels-checkbox"
                        displayEmpty
                        multiple
                        fullWidth
                        renderValue={renderVal}
                        value={value.split(',').filter(Boolean)}
                        onChange={handleChange}
                        onClose={onBlur}
                        className={clsx('form-control', errors?.Channels && 'invalid')}
                      >
                        {channelOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <Checkbox
                              color="primary"
                              checked={value.includes(option.value)}
                            />
                            <ListItemText primary={option.label} />
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.Channels && (
                        <span className="errors">{errors.Channels.message}</span>
                      )}
                    </Form.Group>
                  )
                }}
              />

              <EmailFields />

            </Col>
            <hr className="vertical-divider" />
            <Col className="right-half">
              <GroupsValidator />
              <DistributionList
                showProfiles={false}
                showGroups="all"
              />
            </Col>
          </Row>
          <div className="my-4 d-flex">
            <button
              type="button"
              className="btn btn-lg btn-secondary ml-auto"
              id="schedule-and-send-btn"
              onClick={onScheduleAndSend}
            >
              Schedule & Send
            </button>
            <button
              type="submit"
              className="btn btn-lg btn-primary ml-4"
              id="send-now-btn"
              disabled={!interactionEnabled || (isSaving && !showScheduleModal)}
            >
              Send Now
              {(isSaving && !showScheduleModal)
                ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    className="ml-2"
                  />
                )
                : <SendArrowSvg />}
            </button>
          </div>
          <ScheduleModal
            onHide={() => {
              setShowScheduleModal(false)
            }}
            show={showScheduleModal}
            onSubmit={onSubmit}
            interactionEnabled={interactionEnabled}
          />
          <CommunityNameWarningModal
            showModal={showCommunityWarningModal}
            onHide={() => {
              setShowCommunityWarningModal(false);
            }}
            onSave={() => {
              setShowCommunityWarningModal(false);
              handleSave(data);
            }}
            disableSavingButton={isSaving}
            entityName="broadcast"
          />
        </Form>
      </FormProvider>

    )
  })
