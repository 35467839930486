import React, {
  RefObject, useRef, useState, ReactElement,
} from 'react';

import { Overlay, Popover } from 'react-bootstrap';

const OverlayButton = ({
  id,
  createOverlayBody,
  createInteractionElement,
  onOverlayHide,
}: {
  id: string
  onOverlayHide?: () => void
  createOverlayBody: (open: (currentTarget: Element) => void,
    close: () => void, ref: RefObject<any>) => ReactElement
  createInteractionElement: (open: (currentTarget: Element) => void,
    close: () => void, ref: RefObject<any>) => ReactElement
}): ReactElement => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<EventTarget | null>(null);

  const ref = useRef<HTMLDivElement>(null);

  const open = (currentTarget: Element): void => {
    setShow(true);
    setTarget(currentTarget)
    if (onOverlayHide) {
      onOverlayHide();
    }
  };
  const close = (): void => {
    setShow(false);
    setTarget(null);
  };

  let overlayRender = null;
  if (target !== null) {
    overlayRender = (
      <Overlay
        show={show}
        target={ref.current}
        placement="bottom"
        container={ref.current ?? undefined}
        rootClose
        onHide={() => close()}
      >
        <Popover id={id}>
          {createOverlayBody(open, close, ref)}
        </Popover>
      </Overlay>
    )
  }

  return (
    <>
      {createInteractionElement(open, close, ref)}
      <div ref={ref}>
        {overlayRender}
      </div>
    </>
  );
}

export default OverlayButton;
