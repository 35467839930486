import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import { ISurveyCall } from 'types/ISurveyCall';
import { ISurveyCallsPayload } from 'types/ISurveyCallsPayload';
import { IFilterableIndexParams } from 'types/ITemplatesIndexParams';
import { RootState } from 'types/rootState';
import { AppThunk } from '../app/appThunk';
import { hasError } from './Error';
import { ISurveyCallState } from './ISurveyCallState';

export const INITIAL_STATE: ISurveyCallState = {
  loading: false,
  surveyCalls: [],
  selectedSurveyCall: undefined,
  byIds: {},
  pagedIds: {},
  pagination: {
    Page: 1,
    PerPage: 20,
    TotalItems: 0,
    TotalPages: 1,
  },

};

const surveyCallSchema = new schema.Entity('SurveyCalls', {}, { idAttribute: 'Id' });

const SurveyCallSlice = createSlice({
  name: 'SurveyCalls',
  initialState: INITIAL_STATE,
  reducers: {
    surveyCallRequestStarted(draftReducerState) {
      draftReducerState.loading = true;
    },
    surveyCallRequestFailed(draftReducerState) {
      draftReducerState.loading = false;
    },
    surveyCallRequestSuccess(draftReducerState, action: PayloadAction<ISurveyCall>) {
      draftReducerState.loading = false;
      draftReducerState.byIds[action.payload.SurveyCallId] = action.payload;
    },
    surveyCallCreatedSuccess(draftReducerState, action: PayloadAction<ISurveyCall>) {
      draftReducerState.loading = false;
      draftReducerState.byIds[action.payload.SurveyCallId] = action.payload;
    },
    surveyCallPayloadSuccess(draftReducerState, action: PayloadAction<ISurveyCall[]>) {
      draftReducerState.surveyCalls = action.payload || [];
      draftReducerState.loading = false;
    },
    surveyCallGetPayloadSuccess(draftReducerState, action: PayloadAction<ISurveyCall>) {
      draftReducerState.selectedSurveyCall = action.payload || undefined;
      draftReducerState.loading = false;
    },
    surveyCallIndexPayloadSuccess(
      draftReducerState,
      action: PayloadAction<ISurveyCallsPayload>,
    ) {
      const normalizedPayload = normalize(action.payload, {
        SurveyCalls: [surveyCallSchema],
      });
      const surveyCallIds = normalizedPayload.result.SurveyCalls;
      const {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      } = action.payload.Pagination;

      draftReducerState.byIds = {
        ...draftReducerState.byIds,
        ...normalizedPayload.entities.SurveyCalls,
      };
      draftReducerState.pagedIds = {
        ...draftReducerState.pagedIds,
        [Page]: surveyCallIds,
      };

      draftReducerState.pagination = {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      };

      draftReducerState.loading = false;
    },
    surveyCallDeleteSuccess(draftReducerState, action: PayloadAction<{ id: number }>) {
      const surveyCallId = action.payload.id;
      draftReducerState.loading = false;
      delete draftReducerState.byIds[surveyCallId];
    },
  },
});

export const getSurveyCallsIndex = (
  params: IFilterableIndexParams,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyCallRequestStarted());
  try {
    const surveyCallIndexResponse = await Api.SurveyCalls.SurveyCallsIndex(params);
    dispatch(surveyCallIndexPayloadSuccess(surveyCallIndexResponse));
  } catch (err) {
    dispatch(surveyCallRequestFailed());
    dispatch(hasError(err));
  }
};

export const getSurveyCall = (surveyCallId: number): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(surveyCallRequestStarted());
  try {
    const surveyCallGetResponse = await Api.SurveyCalls.surveyCallGet(surveyCallId);
    dispatch(surveyCallGetPayloadSuccess(surveyCallGetResponse));
  } catch (err) {
    dispatch(surveyCallRequestFailed());
    dispatch(hasError(err));
  }
};

export const getSurveyCalls = (): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyCallRequestStarted());
  try {
    const surveyCallGetResponse = await Api.SurveyCalls.surveyCallsGet();
    dispatch(surveyCallPayloadSuccess(surveyCallGetResponse));
  } catch (err) {
    dispatch(surveyCallRequestFailed());
    dispatch(hasError(err));
  }
};

export const AddSurveyCall = (surveyCallData: ISurveyCall,
  onSuccess?: (surveyCall: ISurveyCall) => void): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyCallRequestStarted());
  try {
    const surveyCallPostResponse = await Api.SurveyCalls.surveyCallCreate(surveyCallData);
    dispatch(surveyCallCreatedSuccess(surveyCallPostResponse));
    onSuccess?.(surveyCallPostResponse);
  } catch (err) {
    dispatch(surveyCallRequestFailed());
    dispatch(hasError(err));
  }
};

export const deleteSurveyCall = (
  surveyCallId: number,
  successCallback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyCallRequestStarted());
  try {
    await Api.SurveyCalls.surveyCallDelete(surveyCallId);
    dispatch(surveyCallDeleteSuccess({ id: surveyCallId }));
    successCallback();
  } catch (err) {
    dispatch(surveyCallRequestFailed());
    dispatch(hasError(err));
  }
}

export const {
  surveyCallRequestStarted,
  surveyCallRequestFailed,
  surveyCallPayloadSuccess,
  surveyCallGetPayloadSuccess,
  surveyCallRequestSuccess,
  surveyCallCreatedSuccess,
  surveyCallIndexPayloadSuccess,
  surveyCallDeleteSuccess,
} = SurveyCallSlice.actions;

export default SurveyCallSlice.reducer;

export const surveyCallsIsLoading = (state: RootState): boolean => state.SurveyCalls.loading;

export const selectSurveyCallById = (
  state: RootState, surveyCallId: number,
): ISurveyCall => state.SurveyCalls.byIds[surveyCallId];

export const selectPaginationData = (
  state: RootState,
): ISurveyCallState['pagination'] => state.SurveyCalls.pagination;

export const selectSurveyCallsPage = (pageNumber: number) => (
  state: RootState,
): ISurveyCall[] => {
  const surveyCallIds: number[] = state.SurveyCalls.pagedIds[pageNumber] || [];
  const surveyCalls: ISurveyCall[] = [];
  surveyCallIds.forEach((id) => {
    const surveyCall = state.SurveyCalls.byIds[id.toString()];
    if (surveyCall) {
      surveyCalls.push(surveyCall);
    }
  });
  return surveyCalls;
};
