import moment from 'moment';
import React, { ReactElement } from 'react';

import { Controller } from 'react-hook-form';

import ControlledDateInput, { IProps as DateInputProps } from '../Inputs/RawDateInput'
import { IFormControlProps } from './IFormControlProps';

function RawDateInput({
  id,
  name,
  label,
  control,
  rules,
  defaultValue = '',
  ...props
}: IFormControlProps & DateInputProps): ReactElement {
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      label={label}
      control={control}
      rules={{
        validate: (date) => {
          const isValid = date ? moment(date).isValid() : true;
          return isValid || 'Invalid Date';
        },
        ...rules,
      }}
      render={(renderProps) => (
        <ControlledDateInput
          label={label ?? ''}
          {...renderProps}
          {...props}
        />
      )}
    />
  );
}

export default RawDateInput;
