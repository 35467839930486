import React, {
  useState, FC,
} from 'react';
import {
  Col, Modal, Button, Row,
} from 'react-bootstrap';
import { SearchInput } from 'components/Inputs/SearchInput';
import Select from 'components/Inputs/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  onSubmit: (queryValue: string, queryField: string) => void
  searchVal: string
};

export const TemplateSearchFilter: FC<Props> = ({
  onSubmit, searchVal,
}) => {
  const [queryValue, setQueryValue] = useState(searchVal);
  const [queryField, setQueryField] = useState('name');
  const [isModalOpen, setIsModalOpen] = useState(false);

  React.useEffect(() => {
    if (isModalOpen) {
      setQueryValue(searchVal);
    }
  }, [isModalOpen, searchVal]);

  return (
    <>
      <span>
        {!!searchVal && (
          <button
            type="button"
            className="filter-button ml-2"
            title="Remove filters?"
            onClick={() => {
              setQueryValue('');
              setQueryField('name');
              onSubmit('', 'name');
            }}
          >
            <span className="">
              <FontAwesomeIcon icon="times" />

            </span>
          </button>
        )}
        <button
          type="button"
          id="profile-filter"
          className="filter-button ml-2"
          onClick={() => { setIsModalOpen(true) }}
        >
          <span className="Calendar__filter-icon">
            <FontAwesomeIcon icon="filter" />
          </span>
        </button>
      </span>

      <Modal
        show={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
        }}
        dialogClassName="modal-20w"
        centered
      >
        <Row>
          <Col xs={5}>
            <Select
              id="searchField"
              label="Search By"
              name="searchField"
              className="mb-3 filter-search_field"
              defaultValue="name"
              onChange={(v) => {
                setQueryField(v);
              }}
              value={queryField}
              options={[
                { label: 'Name', value: 'name' },
                { label: 'Description', value: 'desc' },
              ]}
            />
          </Col>

          <Col xs={7} className="align-self-end pl-0">
            <SearchInput
              placeholder="I am looking for..."
              name="searchValue"
              value={queryValue}
              onChange={(evt) => {
                setQueryValue(evt.target.value);
              }}
              onKeyDown={(evt) => {
                if (evt.key !== 'Enter') {
                  return;
                }
                evt.preventDefault();

                if (queryValue.trim() === '') {
                  return;
                }

                setIsModalOpen(false);
                onSubmit(queryValue, queryField);
              }}
            />
          </Col>
        </Row>
        <Button
          type="submit"
          onClick={() => {
            setIsModalOpen(false);
            onSubmit(queryValue, queryField);
          }}
          disabled={queryValue.trim() === ''}
        >
          Apply filter
        </Button>
      </Modal>
    </>
  );
};
