import React from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Button, Row, Col, Card,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import Select from 'components/Inputs/Select';
import { ITemplate } from 'types/IEvent';
import { getAllAccounts as selectAllAccounts } from 'reducers/UserInfo';
import { TemplatePicker } from 'components/TemplatePicker';
import { TemplateSummary } from 'components/TemplateSummary';
import { useHasFeatures } from 'hooks/useHasFeatures';

const TemplatesClonePage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [selectedTemplate, setSelectedTemplate] = React.useState<ITemplate|null>(null);
  const [selectedAccountId, setSelectedAccountId] = React.useState<string>('');

  const hasWriteMarketingPermission = useHasPermissions('Marketing:Edit');
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const { hasFeature: hasMarketingFeature, isLoading: isFeaturesLoading } = useHasFeatures('marketing-emails');

  const canWriteMarketing = (hasWriteMarketingPermission || isAdmin || isSuper) && hasMarketingFeature;

  const allAccounts = useSelector(selectAllAccounts);

  const accountsOptions = React.useMemo(() => {
    if (!allAccounts.length) {
      return [];
    }

    return allAccounts
      .map((account) => ({
        value: `${account.Id}`,
        label: account.Name,
      }))
  }, [allAccounts]);

  React.useEffect(() => {
    if (!accountsOptions.length) {
      return;
    }

    setSelectedAccountId(accountsOptions[0].value);
  }, [accountsOptions]);

  const isMarketing = pathname.startsWith('/marketing-templates');

  if (isMarketing) {
    if (isFeaturesLoading) {
      return null;
    }
    if (!canWriteMarketing) {
      return <Redirect to="/" />
    }
  }

  let basePath;
  let entityName;
  if (isMarketing) {
    basePath = '/marketing-templates';
    entityName = 'Marketing Email';
  } else {
    basePath = '/templates';
    entityName = 'Template';
  }

  const isBlueprint = pathname.includes('blueprint');
  const title = isBlueprint
    ? `Select Blueprint ${entityName}`
    : `Clone ${entityName}`;

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="header-bar">
        <h1>
          {title}
        </h1>
        <div
          className="action-bar"
          style={{ flexShrink: 0 }}
        >
          <div>
            <button
              type="button"
              role="link"
              className="btn btn-link"
              onClick={() => {
                history.push(basePath)
              }}
            >
              Cancel
            </button>
            <Button
              type="button"
              onClick={() => {
                history.push(
                  `${basePath}/new`,
                  {
                    sourceTemplateId: selectedTemplate.ID,
                    sourceTemplateType: selectedTemplate.Type,
                    sourceTemplateAccountId: isBlueprint
                      ? 0
                      : +selectedAccountId,
                  },
                )
              }}
              variant="primary"
              disabled={!selectedTemplate}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>

      {!isBlueprint && (
        <Row className="mt-3">
          <Col xs={4}>

            <Select
              id="chooseAccount"
              label="From Account"
              placeholder="Select"
              options={accountsOptions}
              name="chooseAccount"
              value={selectedAccountId}
              onChange={(newAccountId: string) => {
                setSelectedAccountId(newAccountId);
                setSelectedTemplate(null);
              }}
              disabled={!allAccounts.length}
            />
          </Col>
        </Row>
      )}

      {(isBlueprint || !!selectedAccountId) && (
        <Card>
          <Card.Body>
            <Row>
              <Col xs={4}>
                <TemplatePicker
                  selectedTemplate={selectedTemplate}
                  setSelectedTemplate={setSelectedTemplate}
                  selectedAccountId={+selectedAccountId}
                  isBlueprint={isBlueprint}
                />
              </Col>

              {selectedTemplate && (
                <Col xs={8}>
                  <TemplateSummary
                    templateId={selectedTemplate.ID}
                    accountId={+selectedAccountId}
                    isBlueprint={isBlueprint}
                  />
                </Col>
              )}

            </Row>
          </Card.Body>
        </Card>

      )}

    </div>
  )
}

export default TemplatesClonePage;
