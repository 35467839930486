import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTags } from 'api/DischargedPatientsAPI';
import BarChart from '../charts/bar-chart/BarChart';
import CustomCard from './custom-card';
import { DataToCompare } from './WrappedDateRangePicker';

interface IProps{
  dischargedPatientsTagSummary: DataToCompare
}

const getTagsFromData = (current, previous):string[] => {
  const tags = [];
  if (current?.Results) {
    tags.push(...current.Results.map((item) => item.tagname));
  }
  if (previous?.Results) {
    tags.push(...previous.Results.map((item) => item.tagname));
  }
  return [...new Set(tags)];
}

const normalizeTagData = (tags, current, previous) => {
  const data = [];
  for (const tag of tags) {
    const currentValue = current?.Results?.find((x) => x.tagname === tag);
    const previousValue = previous?.Results?.find((x) => x.tagname === tag);
    data.push({
      tag,
      currentValue: currentValue?.[''],
      previousValue: previousValue?.[''],
    })
  }
  return data;
}

export const TagSummary = ({ dischargedPatientsTagSummary }:IProps):React.ReactElement => {
  const getArrowIcon = (entry, previousValue) => {
    if (previousValue?.[''] !== entry?.['']) {
      return ((entry?.[''] ?? 0) > (previousValue?.[''] ?? 0)
        ? <FontAwesomeIcon style={{ color: 'green' }} icon="arrow-up" />
        : <FontAwesomeIcon style={{ color: 'red' }} icon="arrow-down" />
      );
    }

    return <FontAwesomeIcon icon="minus" />;
  };
  const [tags, setTags] = useState([]);

  useEffect(() => {
    (async () => {
      const apiTags = await getTags();
      setTags(apiTags.data);
    })();
  }, []);

  const tagsFromData = getTagsFromData(dischargedPatientsTagSummary?.current, dischargedPatientsTagSummary?.previous);
  const allTags = [...new Set([...tags, ...tagsFromData])];
  const normalizedData = normalizeTagData(
    allTags, dischargedPatientsTagSummary?.current, dischargedPatientsTagSummary?.previous,
  );

  const chartData = [
    {
      color: '#00b7d9',
      chartLabel: 'Previous',
      data: normalizedData.map((x) => ({ value: x.previousValue })),
    },
    {
      color: '#0F4CDB',
      chartLabel: 'Current',
      data: normalizedData.map((x) => ({ value: x.currentValue })),
    },
  ];

  return (dischargedPatientsTagSummary?.current || null) && (
    <Col>
      <CustomCard
        title="Tag Summary"
        body={(
          <table className="summary">
            <thead>
              <tr>
                <td>Tag</td>
                <td>Current</td>
                <td>Previous</td>
              </tr>
            </thead>
            <tbody>
              {allTags.map((item) => {
                const currentValue = dischargedPatientsTagSummary?.current?.Results?.find((x) => x.tagname === item);
                const previousValue = dischargedPatientsTagSummary?.previous?.Results?.find((x) => x.tagname === item);
                return (
                  <tr key={item}>
                    <td>{item}</td>
                    <td>{currentValue?.[''] ?? 0}</td>
                    <td>{previousValue?.[''] ?? 0}</td>
                    <td>{getArrowIcon(currentValue, previousValue)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        chart={(
          <BarChart
            labels={allTags}
            data={chartData}
          />
        )}
      />

    </Col>
  );
};
