import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _union from 'lodash/union';
import { EventInput } from '@fullcalendar/core';
import { isPresent } from 'ts-is-present';
import moment from 'moment';
import { format, parseISO } from 'date-fns/esm';
import { getFileBlobFromB64Data } from 'utils/file';
import { IEventSummary, VoiceMessageTypeEnum, IEvent } from 'types/IEvent';
import {
  ICalendarEvent,
  ICalendarEventReport,
  IEventReport,
  ICalendarEventSummary,
  ISurveyResponse,
} from 'types/ICalendarEvent';
import { RootState } from '../types/rootState';
import {
  ICalendarState,
} from './ICalendarState';
import { AppThunk } from '../app/appThunk';
import { CalendarFilters, ICalendarPayload } from '../types/ICalendarDay';
import { CalendarFilterKey, FullCalendarViewType } from '../types/ICalendarView';
import { hasError } from './Error';

const colors = {
  default: '#2D9CCC',
  ondemand: '#15B375',
  compliance: '#F88225',
  staff: '#f1c40f',
  broadcast: '#FFFFFF',
  family: '#ff9ff3',
  automatic: '#F88225',
}

const convertDateToYmdFormat = (date): string => {
  const formattedDate = format(parseISO(date), 'yyy-MM-dd');
  return formattedDate;
};

export const INITIAL_STATE: ICalendarState = {
  byIds: {},
  reportsByIds: {},
  allIds: [],
  loading: false,
  summaryByIds: {},
  calendarFilters: null,
  calendarView: {
    fcViewPeriod: 'week',
    fcViewType: FullCalendarViewType.Time,
  },
};

const CalendarSlice = createSlice({
  name: 'Calendar',
  initialState: INITIAL_STATE,
  reducers: {
    calendarRequestStarted(draftReducerState) {
      draftReducerState.loading = true;
    },
    calendarDeleteSingleEvent(
      draftReducerState,
      action: PayloadAction<{ id: number, date: string }>,
    ) {
      const calendarKey = `${action.payload.id}-${action.payload.date}`;
      delete draftReducerState.byIds[calendarKey];
      draftReducerState.allIds = draftReducerState.allIds.filter(
        (key) => key !== calendarKey,
      );
      draftReducerState.loading = false;
    },
    calendarDeleteEventSeries(
      draftReducerState,
      action: PayloadAction<{ id: number }>,
    ) {
      const allKeys = draftReducerState.allIds.filter(
        (key) => key.startsWith(String(action.payload.id)),
      );
      allKeys.forEach((key) => {
        delete draftReducerState.byIds[key];
      });
      draftReducerState.allIds = draftReducerState.allIds.filter(
        (key) => !key.startsWith(String(action.payload.id)),
      );
      draftReducerState.loading = false;
    },
    calendarSetEvent(draftReducerState, action: PayloadAction<ICalendarEvent>) {
      const event = action.payload;
      const formattedDate = convertDateToYmdFormat(event.EventSendDate);
      draftReducerState.byIds[`${event.EventId}-${formattedDate}`] = event;
    },
    calendarSuccess(
      draftReducerState,
      action: PayloadAction<ICalendarPayload>,
    ) {
      const allInstances = instancesFromPayload(action.payload);
      const eventsById: { [key: string]: ICalendarEvent } = {};

      allInstances.forEach((eventInstance) => {
        eventsById[
          `${eventInstance.EventId}-${convertDateToYmdFormat(
            eventInstance.EventSendDate,
          )}`
        ] = eventInstance;
      });

      draftReducerState.byIds = {
        ...eventsById,
      };
      draftReducerState.allIds = _union(Object.keys(eventsById));
      draftReducerState.loading = false;
    },
    calendarRequestFailed(draftReducerState): any {
      draftReducerState.loading = false;
    },
    calendarSetEventSummary(
      draftReducerState,
      action: PayloadAction<ICalendarEventSummary>,
    ) {
      const { eventId, summary, date } = action.payload;
      const eventIndex = `${eventId}-${convertDateToYmdFormat(date)}`;
      draftReducerState.summaryByIds[eventIndex] = summary;
    },
    calendarSetEventReport(
      draftReducerState,
      action: PayloadAction<ICalendarEventReport>,
    ) {
      const { eventId, report, date } = action.payload;
      draftReducerState.reportsByIds[
        `${eventId}-${convertDateToYmdFormat(date)}`
      ] = report;
    },
    setCalendarFilter(
      draftReducerState,
      action: PayloadAction<CalendarFilters>,
    ) {
      draftReducerState.calendarFilters = action.payload;
    },
    setCalendarViewPeriod(
      draftReducerState,
      action: PayloadAction<string>,
    ) {
      draftReducerState.calendarView.fcViewPeriod = action.payload;
    },
    setCalendarViewType(
      draftReducerState,
      action: PayloadAction<FullCalendarViewType>,
    ) {
      draftReducerState.calendarView.fcViewType = action.payload;
    },
    clearCalendarFilterKeys(
      draftReducerState,
    ) {
      draftReducerState.calendarView.filterKeys = [];
    },
    toggleCalendarFilterKey(
      draftReducerState,
      action: PayloadAction<CalendarFilterKey>,
    ) {
      const filterKeys = draftReducerState.calendarView.filterKeys || [];
      const keyIndex = filterKeys.indexOf(action.payload)
      if (keyIndex < 0) {
        filterKeys.push(action.payload);
      } else {
        filterKeys.splice(keyIndex, 1);
      }
      draftReducerState.calendarView.filterKeys = filterKeys;
    },
  },
});

function instancesFromPayload(payload: ICalendarPayload): ICalendarEvent[] {
  let eventInstances: ICalendarEvent[] = [];
  payload.forEach((dateEntry) => {
    eventInstances = eventInstances.concat(dateEntry.Events);
  });
  return eventInstances;
}

export const {
  calendarRequestStarted,
  calendarSuccess,
  calendarRequestFailed,
  calendarSetEventReport,
  calendarSetEvent,
  calendarDeleteSingleEvent,
  calendarDeleteEventSeries,
  calendarSetEventSummary,
  setCalendarFilter,
  setCalendarViewPeriod,
  setCalendarViewType,
  clearCalendarFilterKeys,
  toggleCalendarFilterKey,
} = CalendarSlice.actions;

export default CalendarSlice.reducer;

// Thunks

export const getCalendarEvents = (filters?: CalendarFilters): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(calendarRequestStarted());
  try {
    const calendarEventsGetResponse = await Api.Calendar.eventsGet(filters);
    dispatch(calendarSuccess(calendarEventsGetResponse));
    dispatch(setCalendarFilter(filters));
  } catch (err) {
    dispatch(calendarRequestFailed());
    dispatch(hasError(err));
  }
};

export const getEventInstanceSummary = (
  eventId: number,
  date: string,
): AppThunk => async (dispatch, getState, Api) => {
  const state = getState();
  const eventIndex = `${eventId}-${convertDateToYmdFormat(date)}`;
  if (state.Calendar.summaryByIds[eventIndex]) {
    return;
  }
  const summary = await Api.Events.getEventInstanceSummaryByIdAndDate(eventId, date);
  dispatch(
    calendarSetEventSummary({
      eventId,
      date,
      summary,
    }),
  );
};

export const deleteEventSeries = (id: number): AppThunk => async (dispatch, getState, Api) => {
  try {
    dispatch(calendarRequestStarted());
    await Api.Events.deleteEvent(id);
    dispatch(calendarDeleteEventSeries({ id }));
  } catch (err) {
    dispatch(calendarRequestFailed());
    dispatch(hasError(err));
  }
};

export const stopEventSeries = (id: number, stopDate: Date, successCallback: () => void): AppThunk => async (dispatch, _, Api) => {
  try {
    dispatch(calendarRequestStarted());
    await Api.Events.eventSeriesStop(id, stopDate);
    successCallback();
    // reload the calendar
  } catch (err) {
    dispatch(calendarRequestFailed());
    dispatch(hasError(err));
  }
};

export const setAllDayOnDemand = (
  allDay:boolean,
  id:number, date:string,
  filters: CalendarFilters,
):AppThunk => async (dispatch, _, Api) => {
  await Api.Events.eventExpirationTime(id, allDay ? '23:59' : null, date);
  dispatch(getCalendarEvents(filters));
}

export const deleteEventInstance = (
  id: number,
  date: string,
): AppThunk => async (dispatch, _, Api) => {
  try {
    dispatch(calendarRequestStarted());
    await Api.Events.deleteSingleEvent(id, date);
    dispatch(calendarDeleteSingleEvent({ id, date }));
  } catch (err) {
    dispatch(calendarRequestFailed());
    dispatch(hasError(err));
  }
};

export const downloadEventReportFile = (
  eventId: string,
  sendDate: string,
  eventName: string,
): AppThunk => async (dispatch, getState, Api) => {
  const reportbytes = await Api.Events.getEventReportFileBytesByIdAndDate(
    eventId,
    sendDate,
  );
  const fileBlob = getFileBlobFromB64Data(
    reportbytes,
    'application/octet-stream',
  );
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(fileBlob);
  const fileName = `${eventName}-${sendDate}`;
  link.download = `${fileName}.xlsx`;
  link.click();
};

export const getEventDetails = (
  eventId: number,
  date: string,
  cb?: (event: IEvent) => void,
): AppThunk => async (dispatch, getState, Api) => {
  const event = await Api.Events.getEventById(eventId);
  dispatch(
    calendarSetEvent({
      EventId: event.ID,
      EventName: event.Name,
      EventDescription: event.Description,
      PreStatus: '',
      Status: '',
      EventSendDate: date,
      EventSendTime: event.SendTime,
      CanDelete: null,
      EventLocation: null,
      InJob: null,
      MessageType: null,
      RecipientsType: null,
      IsEmergency: null,
      IsCompliance: null,
      IsOndemand: null,
      IsMarketing: event.IsMarketing,
      SendMode: event.SendMode,
      ExpirationTime: null,
      EventType: event.EventType,
      SurveyResponses: event?.VoiceContent?.SurveyResponses,
    }),
  );
  dispatch(getEventReport(eventId, date, event?.VoiceContent?.SurveyResponses));
  if (cb) {
    cb(event);
  }
};

export const getEventReport = (
  eventId: number,
  date: string,
  surveyResponses: ISurveyResponse[],
): AppThunk => async (dispatch, getState, Api) => {
  const eventDateYear = format(parseISO(date), 'yyyy');
  const eventDateMonth = format(parseISO(date), 'MM');
  const eventDateDay = format(parseISO(date), 'dd');
  const [summary, sms, emails, voice] = await Promise.all([
    Api.Events.getEventSummary(eventId, eventDateYear, eventDateMonth, eventDateDay),
    Api.Events.getEventSms(eventId, eventDateYear, eventDateMonth, eventDateDay),
    Api.Events.getEventEmails(eventId, eventDateYear, eventDateMonth, eventDateDay),
    Api.Events.getEventVoice(eventId, eventDateYear, eventDateMonth, eventDateDay),
  ]);

  dispatch(
    calendarSetEventReport({
      eventId,
      date,
      report: {
        summary: summary.map((item) => ({
          channel: item.Channel,
          status: item.Status,
          messagesFailed: item.MessagesFailed,
          messagesSent: item.MessagesSent,
          messagesDuplicated: item.Duplicate,
        })),
        sms: sms.map((item) => ({
          eventId: item.EventId,
          profileId: item.ProfileId,
          status: item.Status,
          firstName: item.FirstName,
          lastName: item.LastName,
          type: item.UserType,
          phoneNumber: item.PhoneNumber,
          fromNumber: item.FromNumber,
          note: !item.Error ? 'Sent' : item.Error, // item.Error??'Sent' doesn't work with empty string
          room: item.Room,
        })),
        voice: voice.map((item) => ({
          eventId: item.EventId,
          profileId: item.ProfileId,
          status: item.Status,
          firstName: item.FirstName,
          lastName: item.LastName,
          type: item.UserType,
          phoneNumber: item.PhoneNumber,
          duration: item.DurationSeconds,
          fromNumber: item.FromNumber,
          deliveryMethod: item.DeliveryMethod,
          keypress: surveyResponses
            ? surveyResponses.find((sr) => sr.Keypress === Number(item.Keypress))?.Response
            : item.Keypress,
          error: item.Error,
          room: item.Room,
        })),
        emails: emails.map((item) => ({
          eventId: item.EventId,
          profileId: item.ProfileId,
          status: item.Status,
          firstName: item.FirstName,
          lastName: item.LastName,
          type: item.UserType,
          toEmail: item.ToEmail,
          opened: item.Opened ? 'Yes' : 'No',
          error: item.Error,
          room: item.Room,
        })),
      },
    }),
  );
};

// Selectors

export const selectFullCalendarEvents = (
  filterKeys: Array<CalendarFilterKey>,
) => (state: RootState): EventInput[] => {
  const eventIds = state.Calendar.allIds;
  return eventIds
    .map((eventId) => {
      const event = state.Calendar.byIds[eventId];

      let eventClassName = event.RecipientsType;
      const eventType = event.EventType?.toLocaleLowerCase();

      if (event.IsEmergency) {
        eventClassName = 'Emergency';
      } else if (event.IsOndemand && event.MessageType !== VoiceMessageTypeEnum.Both) {
        eventClassName = 'OnDemand';
      } else if (event.IsCompliance || eventType === 'compliance' || eventType === 'lifedate') {
        eventClassName = 'Automatic';
      }

      if (filterKeys !== undefined && (filterKeys as any).includes(eventClassName)) {
        return null;
      }

      const startDate = moment(event.EventSendDate);

      // Check for 11:45 PM - 11:59 PM range.
      if (startDate.hours() === 23 && startDate.minutes() >= 45) {
        startDate.minutes(45);
      }

      return {
        id: String(event.EventId),
        start: startDate.format('yyyy-MM-DD HH:mm'),
        title: `${format(new Date(event.EventSendDate), 'h:mm')} - ${
          event.EventName
        }`,
        className: eventClassName,
        messageType: event.MessageType,
      };
    })
    .filter(isPresent);
};

export const selectDPCalendarEvents = (
  options = { showOnDemand: true, showBroadcast: true, acctTimezone: '' },
) => (state: RootState): any[] => {
  const eventIds = state.Calendar.allIds;
  return eventIds
    .map((eventId) => {
      const event = state.Calendar.byIds[eventId];
      if (!options.showOnDemand && (
        event.MessageType === VoiceMessageTypeEnum.OnDemand || event.MessageType === VoiceMessageTypeEnum.Both)) {
        return null;
      }
      if (!options.showBroadcast && (
        event.MessageType === VoiceMessageTypeEnum.Broadcast || event.MessageType === VoiceMessageTypeEnum.Both)) {
        return null;
      }
      let bgColor = '';
      let fontColor = '';
      switch (event.RecipientsType) {
        case 'staff':
          bgColor = colors.staff;
          fontColor = colors.broadcast;
          break;
        case 'seniors':
          bgColor = colors.broadcast;
          fontColor = colors.broadcast;
          break;
        case 'family':
          bgColor = colors.family;
          fontColor = colors.broadcast;
          break;
        default:
          fontColor = 'black';
          break;
      }
      const eventType = event.EventType?.toLocaleLowerCase();
      if (event.IsOndemand && event.MessageType !== VoiceMessageTypeEnum.Both) {
        bgColor = '#15B375';
        fontColor = colors.broadcast;
      } else if (event.IsCompliance) {
        bgColor = '#F88225';
        fontColor = colors.broadcast;
      } else if (eventType === 'compliance' || eventType === 'lifedate') {
        bgColor = colors.staff;
        fontColor = colors.automatic;
      }

      const clientDate = moment.utc(event.EventSendDate).tz(options.acctTimezone).toDate();
      const startDate = new Date(clientDate);

      // Check for 11:45 PM - 11:59 PM range.
      if (startDate.getHours() === 23 && startDate.getMinutes() >= 45) {
        startDate.setMinutes(45);
      }

      const endDate = new Date(clientDate).setMinutes(startDate.getMinutes() + 5);
      const label = `${format(new Date(event.EventSendDate), 'h:mm')} - ${
        event.EventName
      }`;

      let html = `<b>Event ID:</b> ${event.EventId}<br />`
                 + `<b>Event Name:</b> ${label}<br />`
                 + `<b>Time:</b> ${moment(event.EventSendDate).format('MM/DD/YYYY hh:mm a')}<br />`
                 + `<b>Message Type:</b> ${event.MessageType}<br />`;

      if (event.IsOndemand && event.ExpirationTime) {
        html += '<b>All Day On Demand:</b> Yes<br />';
      } else if (event.IsOndemand && !event.ExpirationTime) {
        html += '<b>All Day On Demand:</b> No<br />';
      }

      return {
        id: String(event.EventId),
        start: startDate,
        end: new Date(endDate),
        html: label,
        sendMode: event.SendMode,
        messageType: event.MessageType,
        backColor: bgColor,
        fontColor,
        deleteDisabled: true,
        moveDisabled: true,
        resizeDisabled: true,
        bubbleHtml: html,
      };
    })
    .filter(isPresent);
};

export const selectCalendarEvent = (eventId: number, date: string) => (
  state: RootState,
): ICalendarEvent => state.Calendar.byIds[`${eventId}-${date}`];

export const selectEventSummary = (state: RootState): {
  [key: string]: Pick<IEventSummary, 'Channel' | 'Status' | 'MessagesFailed' | 'MessagesSent'>[]
} => state.Calendar.summaryByIds;

export const selectEventReport = (eventId: number, date: string) => (
  state: RootState,
): IEventReport => state.Calendar.reportsByIds[`${eventId}-${date}`];

export const selectCalendarFilter = (rootState: RootState) => rootState.Calendar.calendarFilters;
