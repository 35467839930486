import useAccountLocalTime from 'hooks/useAccountLocalTime';
import moment from 'moment';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createEvent } from 'reducers/Events';
import { getGroupsIndex } from 'reducers/Groups';
import { IEvent, ScheduleMode } from 'types/IEvent';
import { AutomaticEventForm } from './AutomaticEventForm';
import { IAutomaticMessage } from './IAutomaticMessage';

export default function CreateAutomaticEventPage(): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getGroupsIndex());
  }, [dispatch]);
  const initialAutomaticMessage: IAutomaticMessage = {
    ID: 0,
    Name: '',
    Description: '',
    SendTime: useAccountLocalTime().format('HH:mm'),
    GroupId: 0,
    VoiceContent: null,
    EmailContent: null,
    SMSContent: null,
    voiceSelected: false,
    emailSelected: false,
    smsSelected: false,
  }

  const createAutoEvent = (eventData: IEvent): void => {
    let voiceContent = null;
    if (eventData.VoiceContent) {
      const transferToPhoneNumber = eventData.VoiceContent?.TransferToPhoneNumber
        ? eventData.VoiceContent.TransferToPhoneNumber : null;
      voiceContent = { ...eventData.VoiceContent, TransferToPhoneNumber: transferToPhoneNumber }
    }

    // force compliacne event created in schedule mode.
    const submitData = { ...eventData, SendMode: ScheduleMode.Schedule, VoiceContent: voiceContent }
    dispatch(createEvent(submitData, (savedEvent) => {
      const eventSendTime = moment(savedEvent.SendTime, 'h:mm a').format('h:mm a')
      toast.success(`Automatic event successfully scheduled for everyday at ${eventSendTime}`);
      history.push('/events/automatic');
    }));
  }

  return (
    <AutomaticEventForm data={initialAutomaticMessage} saveEvent={createAutoEvent} isNew />
  );
}
