import React, { useEffect, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Spinner } from 'react-bootstrap';
import { useParams, useHistory, Redirect } from 'react-router-dom';

import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { selectTemplateIsLoading } from 'reducers/Templates';
import { useTemplateQuery } from 'components/hooks/useTemplateQuery';
import { RootState } from 'types/rootState';
import { EventTemplateForm } from 'components/EventTemplateForm/EventTemplateForm';
import { ITemplate, ITemplateExtendo } from 'types/IEvent';
import { useSaveTemplate } from 'hooks/useSaveTemplate';
import { useHasFeatures } from 'hooks/useHasFeatures';
import CommunityNameWarningModal from './CommunityNameWarningModal';

export default function TemplatesEditPage(): ReactElement {
  const [templateToSave, setTemplateToSave] = useState<ITemplateExtendo|null>(null);
  const [showCommunityWarningModal, setShowCommunityWarningModal] = useState<boolean>(false);
  const hasWriteMarketingPermission = useHasPermissions('Marketing:Edit');
  const { hasFeature: hasMarketingFeature, isLoading: isFeaturesLoading } = useHasFeatures('marketing-emails');
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const { templateId: urlTemplateId, type: urlTemplateType } = useParams
  <{ templateId: string, type?: 'transactional'|'template' }>();
  const [data, setData] = React.useState<ITemplate|null>(null);

  const isSaving = useSelector(selectTemplateIsLoading);
  const communityName = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.CommunityName);

  const templateId = +urlTemplateId;

  useTemplateQuery(
    templateId,
    urlTemplateType,
    undefined,
    {
      onSuccess: (templateToEdit) => {
        if (!templateToEdit) {
          return;
        }
        setData(templateToEdit);
      },
    },
  );

  const history = useHistory();

  const handleSaveTemplate = useSaveTemplate()

  useEffect(() => {
    if (urlTemplateType && !['template', 'transactional'].includes(urlTemplateType)) {
      history.push('/templates');
    }
  }, [urlTemplateType, history]);

  const getShowCommunityNameWarning = (formData:ITemplateExtendo) => {
    const {
      smsSelected, emailSelected, voiceSelected, submit,
    } = formData;

    if (submit === 'save-as-draft') {
      return false;
    }

    const voiceContentValue = formData.VoiceContent?.Content;
    const smsContentValue = formData.SMSContent?.Content
    const emailContentValue = formData.EmailContent?.Content
    const decodeEmailContentValue = emailContentValue ? atob(emailContentValue) : '';
    if (((voiceSelected && voiceContentValue && voiceContentValue?.indexOf('$$CommunityName$$') !== -1)
      || (smsSelected && smsContentValue && smsContentValue?.indexOf('$$CommunityName$$') !== -1)
      || (emailSelected && decodeEmailContentValue && decodeEmailContentValue?.indexOf('$$CommunityName$$') !== -1))
      && (communityName === null || communityName === '')) {
      return true;
    }
    return false;
  };

  const handleSubmit = (formData: ITemplateExtendo): void => {
    if (getShowCommunityNameWarning(formData)) {
      setTemplateToSave(formData);
      setShowCommunityWarningModal(true);
      return;
    }

    handleSaveTemplate(formData);
  };

  const isDraft = data?.IsDraft ?? true;
  const isMarketing = history.location.pathname.startsWith('/marketing-templates');
  const canWriteMarketing = (hasWriteMarketingPermission || isAdmin || isSuper) && hasMarketingFeature;

  if (isMarketing) {
    if (isFeaturesLoading) {
      return null;
    }
    if (!canWriteMarketing) {
      return <Redirect to="/" />
    }
  }

  const entityName = isMarketing
    ? 'Marketing Email'
    : 'Template';

  const isLoading = !data;

  return (
    <div className="TemplatesEdit">
      <Helmet>
        <title>
          Edit
          {' '}
          {entityName}
        </title>
      </Helmet>
      {isLoading
        ? (
          <div
            className="loading-text d-flex align-items-center justify-content-center"
            role="status"
          >
            <Spinner
              animation="border"
              as="span"
              className="mr-2"
              style={{ width: '1.66em', height: '1.66em' }}
            />
            <span style={{ fontSize: '1.25em' }}>
              Loading...
            </span>
          </div>
        )
        : (
          <>
            <div className="header-bar">
              <h1>
                Edit
                {' '}
                {isDraft
                  ? 'Draft'
                  : entityName}
              </h1>
            </div>
            <EventTemplateForm
              isNew={false}
              data={data}
              onSubmit={handleSubmit}
              onCancel={() => {
                history.push(isMarketing
                  ? '/marketing-templates'
                  : '/templates')
              }}
              allowPreview={false}
              enableGenerateSmartContent
            />
            <CommunityNameWarningModal
              entityName={entityName.toLowerCase()}
              showModal={showCommunityWarningModal}
              onHide={() => {
                setShowCommunityWarningModal(false);
              }}
              onSave={() => {
                setShowCommunityWarningModal(false);
                handleSaveTemplate(templateToSave);
              }}
              disableSavingButton={isSaving}
            />
          </>
        )}
    </div>
  );
}
