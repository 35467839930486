import React, { FC } from 'react';
import {
  Button, Spinner,
} from 'react-bootstrap';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  saveComplete?: boolean
}

export const AddButton: FC<IProps> = ({ saveComplete, disabled, ...props }) => (
  <Button
    disabled={disabled || !saveComplete}
    className="btn btn-secondary"
    {...props}
  >
    {saveComplete && <span>Add This Profile</span>}
    {!saveComplete && (
      <>
        <span className="pr-2">Adding...</span>
        <Spinner as="span" className="" animation="border" size="sm" />
      </>
    )}
  </Button>
)
