import React, {
  FC, useState, useEffect,
} from 'react'

import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import _last from 'lodash/last';
import _times from 'lodash/times';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import { selectDischargedPatientConfig } from 'reducers/DischargedPatients';
import { Input } from 'components/FormControls';

const CallerIdMapping: FC<{ readMode: boolean }> = ({ readMode }) => {
  const {
    control, errors,
  } = useFormContext();
  const config = useSelector(selectDischargedPatientConfig);
  const [rows, setRows] = useState<number[]>([0]);

  useEffect(() => {
    if (!config) {
      return;
    }
    setRows(_times(Object.keys(config.ConfigurationData?.FacilityInfoToCallerIdMapping).length));
  }, [config, readMode]);

  return (
    <div>
      <h6 className="h6">
        Facilities
      </h6>
      {rows.map((key, index) => (
        <Row className="align-items-center" key={key}>
          <Col>
            <Row>
              <Col className="pr-0" xs={4} md={12} xl={4}>
                <Input
                  id={`FacilityInfoToCallerIdMapping.${key}.name`}
                  name={`FacilityInfoToCallerIdMapping.${key}.name`}
                  type="text"
                  label="Facility Name"
                  control={control}
                  rules={{ required: true }}
                  errors={errors.FacilityInfoToCallerIdMapping?.[key]?.name && 'Facility Name is required'}
                  readOnly={readMode}
                />
              </Col>
              <Col className="pr-0" xs={4} md={12} xl={4}>
                <Input
                  id={`FacilityInfoToCallerIdMapping.${key}.CallerId`}
                  name={`FacilityInfoToCallerIdMapping.${key}.CallerId`}
                  type="text"
                  label="Caller ID"
                  control={control}
                  rules={{ required: true }}
                  errors={errors.FacilityInfoToCallerIdMapping?.[key]?.CallerId && 'Caller ID is required'}
                  readOnly={readMode}
                />
              </Col>
              <Col className="pr-0" xs={4} md={12} xl={4}>
                <Input
                  id={`FacilityInfoToCallerIdMapping.${key}.TimeZone`}
                  name={`FacilityInfoToCallerIdMapping.${key}.TimeZone`}
                  type="text"
                  label="Time Zone"
                  control={control}
                  rules={{ required: true }}
                  errors={errors.FacilityInfoToCallerIdMapping?.[key]?.TimeZone && 'Time Zone is required'}
                  readOnly={readMode}
                />
              </Col>
            </Row>
          </Col>
          <Col className="flex-grow-0" style={{ flexBasis: '45.75px', marginTop: '12px' }}>
            <FontAwesomeIcon
              icon="trash-alt"
              style={{
                cursor: (rows.length === 1 || readMode) ? 'default' : 'pointer',
                opacity: (rows.length === 1 || readMode) ? 0.5 : 1,
                fontSize: '18px',
              }}
              color={(rows.length === 1 || readMode) ? 'gray' : '#FF7052'}
              onClick={() => {
                if (rows.length === 1 || readMode) {
                  return;
                }
                setRows([
                  ...rows.slice(0, index),
                  ...rows.slice(index + 1),
                ]);
              }}
            />
          </Col>
        </Row>
      ))}
      <div className="d-flex justify-content-end">
        <FontAwesomeIcon
          icon={faPlusSquare}
          className="mb-3"
          color="#0F4CDB"
          size="2x"
          style={{
            cursor: readMode ? 'default' : 'pointer', fontSize: 24,
          }}
          onClick={() => {
            if (readMode) {
              return;
            }
            setRows([...rows, _last(rows) + 1]);
          }}
        />
      </div>
    </div>
  )
}

export default CallerIdMapping
