import moment from 'moment';

export function format12to24HourTime(timeStr: string | undefined): string {
  if (!timeStr) return '';
  return (new Date(`01/01/0001 ${timeStr}`)).toTimeString().substr(0, 5);
}

export function format24to12HourTime(timeStr: string | undefined): string {
  if (!timeStr) return '';
  const datestring = `1900-01-01T${timeStr}:00+00`
  const m = moment(datestring);
  return m.utc().format('hh:mm A');
}
