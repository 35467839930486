import React, { HTMLAttributes, useEffect } from 'react';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import uuidv4 from 'uuid/v4';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface LabelProps extends HTMLAttributes<HTMLSpanElement> {
  name: string
  showDelete: boolean
  onDelete: () => void
  noTooltip?: boolean
  renderBody?: (ref: React.Ref<HTMLElement|null>) => React.ReactElement
}

const getIsOverflowed = (el: HTMLElement) => el.offsetWidth < el.scrollWidth;

export const Label: React.FC<LabelProps> = ({
  name,
  showDelete,
  onDelete,
  noTooltip = false,
  className,
  renderBody,
  ...props
}) => {
  const labelRef = React.useRef<HTMLElement|null>(null);
  const [isOverflowed, setIsOverflowed] = React.useState<boolean>(false);

  useEffect(() => {
    setIsOverflowed(getIsOverflowed(labelRef.current));
  }, [name]);

  const labelJSX = renderBody
    ? renderBody(labelRef)
    : (
      <span
        className={clsx('entitylabel', isOverflowed && 'overflown', className)}
        {...props}
      >
        <span
          className="entitylabel-text"
          ref={labelRef}
        >
          {name}
        </span>
        {showDelete && (
          <FontAwesomeIcon icon="times" className="entity-delete-icon" size="sm" onClick={onDelete} />
        )}
      </span>
    )

  return (isOverflowed && !noTooltip) ? (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip id={`tooltip-${uuidv4()}`}>
          {name}
        </Tooltip>
      )}
    >
      {labelJSX}
    </OverlayTrigger>
  ) : labelJSX
}
