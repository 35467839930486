import {
  Col, Row,
} from 'react-bootstrap';
import { IProfile } from 'reducers/IProfile';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useCallback, useMemo } from 'react';

interface IProps {
  groupMembers: IProfile[]
  onRemoved: (profile: IProfile) => void
}

const SelectedProfiles = ({
  groupMembers, onRemoved,
}: IProps): ReactElement => {
  useMemo(() => groupMembers?.sort(
    (a, b) => a.LastName.localeCompare(b.LastName)),
  [groupMembers]);

  const getRowColor = (index: number): string => {
    if (index % 2 === 1) {
      return 'oddGroupItem selectedProfileItem';
    }
    return 'evenGroupItem selectedProfileItem';
  }

  const Profiles = useCallback(({ index, style }) => (
    <div style={style} className="no-overflow-x cursor-pointer">
      <Row className={getRowColor(index)} onClick={() => onRemoved(groupMembers[index])}>
        <Col xs={1} className="removeIcon">
          <FontAwesomeIcon
            icon="times"
            size="xs"
          />
        </Col>
        <Col className="align-middle mt-2 ml-1">
          {groupMembers[index]?.FirstName}
          {' '}
          {groupMembers[index]?.LastName}
        </Col>
      </Row>
    </div>
  ), [groupMembers, onRemoved]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '1 1 auto', height: '70vh' }}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={35}
              itemCount={groupMembers?.length}
            >
              {Profiles}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  )
}
export default SelectedProfiles;
