import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { ITemplatesPayload } from 'types/ITemplatesPayload';
import { normalize, schema } from 'normalizr';
import { IFilterableIndexParams } from 'types/ITemplatesIndexParams';
import { ITemplate, ITemplateState, VoiceMessageTypeEnum } from 'types/IEvent';
import {
  IVoiceContent, IEmailContent, ISMSContent,
} from 'types/ILibraryContent';
import log from 'lib/logging';
import { LanguageNames } from 'lib/CommonSelect';
import { serialize } from 'utils/serializeParams';
import { AppThunk } from '../app/appThunk';
import { RootState } from '../types/rootState';
import { hasError } from './Error';

const initialVoiceContentData: IVoiceContent = {
  Content: '',
  TransferToPhoneNumber: '',
  VoiceRecording: null,
  VoiceType: 'TextToSpeech',
  SurveyResponses: [],
};

const initialEmailContentData: IEmailContent = {
  Subject: '',
  Display: '',
  From: 'example@example.com',
  ReplyTo: 'example@example.com',
  Content: 'aW5pdGlhbCBlbWFpbCBjb250ZW50',
  Attachments: [],
};

const initialSmsContentData: ISMSContent = {
  Content: '',
};

const initialTemplate: ITemplate = {
  GroupIds: [],
  ProfileIds: [],
  ID: 0,
  Name: 'reacttemplate',
  Description: 'desc',
  Type: 'Template',
  Location: '',
  EmailContent: initialEmailContentData,
  VoiceContent: initialVoiceContentData,
  SMSContent: initialSmsContentData,
  VoiceMessageType: VoiceMessageTypeEnum.Both,
  Category: 'Activity',
  voiceSelected: false,
  emailSelected: false,
  smsSelected: false,
  CreatedByUserId: 0,
  CreatedDateTimeUTC: null,
  LastModifiedByUserId: 0,
  LastModifiedDateTimeUTC: null,
  LastUsedDateTimeUTC: null,
  SourceLanguage: LanguageNames.English,
  IsDraft: true,
};

const initialSelectedTemplate = {
  GroupIds: [],
  ProfileIds: [],
  ID: 0,
  Name: '',
  Description: '',
  Location: '',
  Type: '',
  VoiceMessageType: VoiceMessageTypeEnum.Broadcast,
  Category: 'Activity',
  voiceSelected: false,
  emailSelected: false,
  smsSelected: false,
  CreatedByUserId: 0,
  CreatedDateTimeUTC: null,
  LastModifiedByUserId: 0,
  LastModifiedDateTimeUTC: null,
  LastUsedDateTimeUTC: null,
  SourceLanguage: null,
  IsDraft: true,
};

const templatesIndexDefaultParams = {
  page: 1,
}

export const INITIAL_STATE: ITemplateState = {
  loading: false,
  templates: [],
  selectedTemplate: initialSelectedTemplate,
  byIds: {},
  pagedIds: {},
  pagination: {
    Page: 1,
    PerPage: 20,
    TotalItems: 0,
    TotalPages: 1,
  },
};

export const selectTemplate = () => (state: RootState): ITemplate[] => state.Templates.templates;
const templateSchema = new schema.Entity('Data', {}, { idAttribute: 'ID' });

const TemplateSlice = createSlice({
  name: 'Templates',
  initialState: INITIAL_STATE,
  reducers: {
    templateRequestStarted(draftReducerState) {
      draftReducerState.loading = true;
    },
    templateRequestFailed(draftReducerState) {
      draftReducerState.loading = false;
    },
    templateRequestSuccess(draftReducerState, action: PayloadAction<ITemplate>) {
      draftReducerState.loading = false;
      draftReducerState.selectedTemplate = action.payload || initialTemplate;
      draftReducerState.byIds[action.payload.ID] = action.payload;
    },
    templateCreatedSuccess(draftReducerState, action: PayloadAction<ITemplate>) {
      draftReducerState.loading = false;
      draftReducerState.selectedTemplate = initialTemplate;
      draftReducerState.byIds[action.payload.ID] = action.payload;
    },
    templateSetByIds(draftReducerState, action: PayloadAction<ITemplate|ITemplate[]>) {
      const templates = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      templates.forEach((item) => {
        draftReducerState.byIds[item.ID] = item;
      })
    },
    templatePayloadSuccess(draftReducerState, action: PayloadAction<ITemplate[]>) {
      draftReducerState.templates = action.payload || [];
      draftReducerState.loading = false;
    },
    templateIndexPayloadSuccess(
      draftReducerState,
      action: PayloadAction<{ data: ITemplatesPayload, params: IFilterableIndexParams }>,
    ) {
      const normalizedPayload = normalize(action.payload.data, {
        Data: [templateSchema],
      });

      const templateIds = normalizedPayload.result.Data;
      const {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      } = action.payload.data.Pagination;

      draftReducerState.byIds = {
        ...draftReducerState.byIds,
        ...normalizedPayload.entities.Data,
      };
      draftReducerState.pagedIds = {
        ...draftReducerState.pagedIds,
        [serialize(action.payload.params, templatesIndexDefaultParams)]: templateIds,
      };

      draftReducerState.pagination = {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      };

      draftReducerState.loading = false;
    },
    templateDeleteSuccess(draftReducerState, action: PayloadAction<{ id: number }>) {
      const templateId = action.payload.id;
      draftReducerState.loading = false;
      delete draftReducerState.byIds[templateId];
    },
    templateBatchDeleteSuccess(draftReducerState, action: PayloadAction<{ ids: string[] }>) {
      action.payload.ids.map((templateId) => delete draftReducerState.byIds[templateId]);
      draftReducerState.loading = false;
    },
  },
});

export const getTemplates = (): AppThunk => async (dispatch, getState, Api) => {
  dispatch(templateRequestStarted());
  try {
    const templateGetResponse = await Api.Templates.templatesGet2();
    dispatch(templatePayloadSuccess(templateGetResponse));
  } catch (err) {
    dispatch(templateRequestFailed());
    dispatch(hasError(err));
  }
};

export const getTemplatesIndex = (
  params: IFilterableIndexParams,
  templateFilterType?: string,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(templateRequestStarted());
  try {
    let type = 'template';
    if (templateFilterType === 'transactional') {
      type = 'transactional';
    }
    const templateIndexResponse = await Api.Templates.TemplatesIndex(params, type);
    dispatch(templateIndexPayloadSuccess({ data: templateIndexResponse, params }));
  } catch (err) {
    dispatch(templateRequestFailed());
    dispatch(hasError(err));
  }
};

export const getSelectedTemplate = (ID: string): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(templateRequestStarted());
  try {
    const templateGetResponse = await Api.Templates.selectedTemplateGet(ID);
    dispatch(templateRequestSuccess(templateGetResponse));
  } catch (err) {
    dispatch(templateRequestFailed());
    dispatch(hasError(err));
  }
};

export const putTemplate = (content: ITemplate, type: number): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(templateRequestStarted());
  try {
    const templatePutResponse = await Api.Templates.templatePut(content, type);
    dispatch(templateRequestSuccess(templatePutResponse));
  } catch (err) {
    dispatch(templateRequestFailed());
    dispatch(hasError(err));
  }
};

export const deleteTemplate = (
  TemplateID: number,
  successCallback = () => { },
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(templateRequestStarted());
  try {
    await Api.Templates.templateDelete(TemplateID);
    log.info('delete template');
    successCallback();
    dispatch(templateDeleteSuccess({ id: TemplateID }));
    log.info('delete dispatched');
  } catch (err) {
    dispatch(templateRequestFailed());
    dispatch(hasError(err));
  }
};
export interface DeleteBatchTemplateError extends AxiosError {
  id: string
}

export const deleteBatchTemplate = (
  ids: string[],
  isMarketing: boolean,
  cb?: (errors?: DeleteBatchTemplateError[]) => void,
): AppThunk => async (dispatch, getState, Api) => {
  const errors = [];
  dispatch(templateRequestStarted());
  try {
    await Api.Templates.batchTemplateDelete(ids, isMarketing);
    dispatch(templateBatchDeleteSuccess({ ids }));
  } catch (err) {
    dispatch(templateRequestFailed());
    errors.push(err);
  }
  if (cb) {
    cb(errors.length ? errors : undefined)
  }
}

export const {
  templateRequestStarted,
  templateRequestFailed,
  templatePayloadSuccess,
  templateRequestSuccess,
  templateCreatedSuccess,
  templateIndexPayloadSuccess,
  templateDeleteSuccess,
  templateSetByIds,
  templateBatchDeleteSuccess,
} = TemplateSlice.actions;

export default TemplateSlice.reducer;

export const saveTemplate = (
  submitData: ITemplate,
  templateType: string,
  successCallback: (template: ITemplate) => void,
  failedCallback?: (errorMessage: string) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(templateRequestStarted());
  try {
    if (submitData.ID) {
      const templateUpdateResponse = await Api.Templates.templatePut(submitData, templateType);
      dispatch(templateRequestSuccess(templateUpdateResponse));
      successCallback(templateUpdateResponse);
    } else {
      const templatePostResponse = await Api.Templates.templateCreate([submitData]);
      dispatch(templateCreatedSuccess(templatePostResponse));
      successCallback(templatePostResponse);
    }
  } catch (err) {
    dispatch(templateRequestFailed());

    if (!failedCallback) {
      return;
    }

    const contents = err.response?.data?.ModelState;

    if (contents) {
      failedCallback(Object.values<string>(contents)
        .reduce((acc, messages) => {
          if (Array.isArray(messages) && messages.length) {
            return `${acc}\r\n• ${messages[0]}`
          }
          return acc;
        },
        `${err.response.data.Message ?? 'Error'}:`,
        ),
      );
      return;
    }

    failedCallback(err.response?.data?.Message ?? err.message ?? 'Error');
  }
};

export const selectTemplateIsLoading = (state: RootState): boolean => state.Templates.loading;

export const selectTemplateById = (
  state: RootState, templateId: number,
): ITemplate => state.Templates.byIds[templateId];

export const selectPaginationData = (
  state: RootState,
): ITemplateState['pagination'] => state.Templates.pagination;

export const selectTemplatesPage = (params: IFilterableIndexParams) => (
  state: RootState,
): ITemplate[] => {
  const hash = serialize(params, templatesIndexDefaultParams);

  const templateIds = state.Templates.pagedIds[hash] || [];
  const templates: ITemplate[] = [];
  templateIds.forEach((id) => {
    const template = state.Templates.byIds[id.toString()];
    if (template) {
      templates.push(template);
    }
  });
  return templates;
};
