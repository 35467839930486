import React, { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet';
import CreateModal from './components/CreateModal';
import SurveyQuestionListTable from './components/Table';

const SurveyQuestionListIndexPage = (): ReactElement => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  return (
    <div className="SurveyQuestionListIndex">
      <Helmet>
        <title>Survey Question List</title>
      </Helmet>
      <div className="header-bar">
        <div className="actions">
          <button
            type="button"
            onClick={() => {
              setShowCreateModal(true);
            }}
            className="btn btn-primary"
          >
            Create New Survey Question List
          </button>
        </div>
      </div>
      <SurveyQuestionListTable />
      <CreateModal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
      />
    </div>
  )
};

export default SurveyQuestionListIndexPage;
