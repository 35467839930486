import React, { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { ReactComponent as Plus } from 'styles/images/plus.svg';
import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { useHasFeatures } from 'hooks/useHasFeatures';
import { Spinner, Tab, Tabs } from 'react-bootstrap';
import useSWR from 'swr';
import { addOneTimeEvent, getGeneratorById } from 'api/BroadcastFormAPI';
import { EnterpriseFormOneTimeEventModelEdit } from 'types/BroadcastForm';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { StringParam, useQueryParam } from 'use-query-params';
import { EbfGeneratorEventsTable, useGeneratorEvents } from './EbfGeneratorEventsTable';
import OneTimeEventModal from './EbfGeneratorEventEditModal';
import { EbfGeneratorTriggeredEventsTable } from './EbfGeneratorTriggeredEventsTable';

const EbfGeneratorEventsPage = ():ReactElement => {
  const [selectedTab, setSelectedTab] = useQueryParam('tab', StringParam);
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const hasAccessPermission = useHasPermissions('Calendar:Read') || isAdmin || isSuper;
  const { hasFeature, isLoading } = useHasFeatures('broadcast-forms');
  const { generatorId: generatorParam } = useParams<{ generatorId: string }>();
  const generatorId = parseInt(generatorParam, 10);
  const [modalShow, setModalShow] = useState(false);
  const history = useHistory();
  const { mutate } = useGeneratorEvents(generatorId);

  const { data: generator, error: fetchError } = useSWR(
    ['EbfGenerator', generatorId],
    async () => {
      const response = await getGeneratorById(generatorId, {
        ignoreGlobalCatch: true,
      });
      return response;
    },
    {
      shouldRetryOnError: false,
    },
  );

  const onSaveNewEvent = async (evtData: EnterpriseFormOneTimeEventModelEdit) => {
    const formattedData: EnterpriseFormOneTimeEventModelEdit = {
      ...evtData,
      SendDate: format(new Date(evtData.SendDate), 'yyyy-MM-dd'),
    };
    try {
      await addOneTimeEvent(generatorId, formattedData);
      toast.success('Event added successfully');
      setModalShow(false)
      mutate();
    } catch (ex) {
      const errMsg = ex?.response?.data.Message || 'Uknown error: Failed to create the broadcast form generator';
      toast.error(errMsg);
    }
  };

  if (!hasAccessPermission) {
    return <Redirect to="/" />
  }
  if (isLoading || !generator) {
    return (
      <Spinner
        animation="border"
        variant="primary"
        role="status"
        className="mx-auto d-block mt-4 flex-shrink-0"
      />
    );
  } if (!hasFeature) {
    return <div>Multi-Account Surveys is not enabled for this account</div>
  }

  if (fetchError) {
    return <div>Error loading generator information</div>;
  }

  return (
    <div className="broadcast-forms Templates">
      <Helmet>
        <title>
          {`Multi-Account Messaging events: ${generator.SurveyName}`}
        </title>
      </Helmet>
      <h3 className="mb-4">
        {`Multi-Account Messaging events: ${generator.SurveyName}`}
      </h3>
      <div className="header-bar broadcast-forms__header-bar">
        <button
          role="link"
          type="button"
          onClick={() => setModalShow(true)}
          className="btn btn-lg btn-warning "
        >
          <Plus
            width="24"
            height="24"
            fill="currentColor"
            style={{ marginRight: '10px' }}
          />
          One Time Event
        </button>
        <button
          role="link"
          type="button"
          onClick={() => history.push(`/internal/ebfgenerators/${generatorId}/automaticEvent`)}
          className="btn btn-lg btn-warning "
        >
          <Plus
            width="24"
            height="24"
            fill="currentColor"
            style={{ marginRight: '10px' }}
          />
          Automatic Events
        </button>
      </div>
      <div className="mb-4" />
      <Tabs
        defaultActiveKey={selectedTab}
        onSelect={(val) => {
          setSelectedTab(val);
        }}
      >
        <Tab eventKey="onetime" title="One-Time Events">
          <EbfGeneratorEventsTable
            generatorId={generatorId}
            showActions
          />
        </Tab>
        <Tab eventKey="automatic" title="Automatic Events">
          <EbfGeneratorTriggeredEventsTable
            generatorId={generatorId}
            showActions
          />
        </Tab>
      </Tabs>
      <OneTimeEventModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSave={onSaveNewEvent}
      />
    </div>
  );
};
export default EbfGeneratorEventsPage;
