import React, { ReactElement, SetStateAction } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CustomizeWarningProps {
  showModal: boolean
  setShowModal: (value: SetStateAction<boolean>) => void
  saveAsDefault(): void
  cancelSave(): void
}

const CustomizeWarningModal = ({
  showModal, setShowModal, saveAsDefault, cancelSave,
}: CustomizeWarningProps): ReactElement | null => (
  <Modal
    show={showModal}
    onHide={() => setShowModal(false)}
    className="DiscardChangeModal"
    backdrop="static"
    keyboard={false}
    centered
  >
    <Modal.Header />
    <Modal.Body>
      <FontAwesomeIcon
        icon="exclamation-circle"
        size="4x"
        color="#FFAE42"
      />

      <div className="message">
        <h4>Are you sure?</h4>
        <p>
          This will change the default column settings for all users. Do you want to proceed?
        </p>
      </div>

      <button
        className="btn btn-block btn-primary"
        onClick={() => { saveAsDefault() }}
        type="button"
      >
        Save as Default
      </button>
      <button
        className="btn btn-block btn-secondary"
        onClick={() => { cancelSave() }}
        type="button"
      >
        Cancel
      </button>
    </Modal.Body>
  </Modal>
);
export default CustomizeWarningModal;
