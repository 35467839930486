import React, { ReactElement, useEffect } from 'react';

import { useForm } from 'react-hook-form'
import { ITemplate } from 'types/IEvent'
import {
  Input, Select, DateInput, TimeInput,
} from '../../../components/FormControls'

interface IProps {
  data?: Partial<ITemplate>
  onSubmit: (data: ITemplate) => void
}

function Form({ data, onSubmit }: IProps): ReactElement {
  const {
    register, control, reset, errors, handleSubmit,
  } = useForm<ITemplate>({ mode: 'onBlur' })
  useEffect(() => { if (data) { reset(data) } }, [reset, data])

  const interceptSubmit = (formData: ITemplate): void => {
    onSubmit({
      ...formData,
    })
  }

  const FrequencyOptions = [
    { label: 'One Time Only', value: 'FirstNameMerge' },
    { label: 'More Than Once', value: 'LastNameMerge' },
  ]

  return (
    <form onSubmit={handleSubmit(interceptSubmit)} className="form ContentForm">
      <fieldset>
        <legend>Details</legend>
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <Input
              id="Name"
              name="Name"
              label="Name"
              type="text"
              control={control}
              errors={errors.Name && 'Name is required'}
              rules={{ required: true }}
            />
          </div>
          <div className="col-xs-12 col-md-3">
            <Input
              id="Description"
              name="Description"
              label="Description"
              type="text"
              control={control}
              rules={{ required: false }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <Select
              id="Frequency"
              name="Frequency"
              label="How Often?"
              options={FrequencyOptions}
              control={control}
              rules={{ required: true }}
            />
          </div>
          <div className="col-xs-12 col-md-3">
            <DateInput
              id="EventDate"
              name="EventDate"
              label="Which Date?"
              control={control}
              rules={{ required: true }}
            />
          </div>
          <div className="col-xs-12 col-md-3">
            <TimeInput
              id="EventTime"
              name="EventTime"
              label="What Time?"
              control={control}
              rules={{ required: true }}
            />
          </div>
        </div>
        <div>
          <input type="hidden" name="Id" ref={register} />
        </div>
      </fieldset>
      <div className="float-left align-bottom">
        <input type="submit" value="Schedule Message (NYI)" />
        <input type="button" value="Review Template (NYI)" />
      </div>
    </form>
  )
}
export default Form
