import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IProfile } from 'reducers/IProfile';
import { RootState } from 'types/rootState';
import { IDischargedPatient } from 'types/IDischargedPatient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import { ISurveyCall } from 'types/ISurveyCall';
import { IUserInfoState } from 'reducers/IUserInfoState';
import moment from 'moment';
import 'moment-timezone';
import ManualPhoneCallIcon from 'components/ManualPhoneCallIcon';
import AutoCallIcon from 'components/AutoCallIcon'

type Props = {
  data: IDischargedPatient
  setSelectedPatient:(patient:IDischargedPatient) => void
};

export const DischargedPatientItem: React.FC<Props> = (
  { data, setSelectedPatient },
) => {
  const selectedDischargedPatient: IDischargedPatient = useSelector(
    (state: RootState) => state.DischargedPatients.selectedDischargedPatient,
  );
  const isSelected = selectedDischargedPatient
   && selectedDischargedPatient.DischargePatientId === data.DischargePatientId;
  const cardBackgroundColor = isSelected ? 'lightgrey' : 'white'
  const patientProfile: IProfile | undefined = useSelector(
    (state: RootState) => state.Profiles.byIds[data.ProfileId],
  );
  const userInfo = useSelector((state: RootState) : IUserInfoState => state.UserInfo);
  const facilityTimeZone = userInfo.userInfo?.FacilityInfoToCallerIdMapping?.[data.Facility]?.TimeZone;
  const zone = facilityTimeZone || userInfo.accountTimezone;

  const zonedLastCallDate = moment(data?.LastCallDate).utc(true).tz(zone);
  const zonedNextAutoCallDate = moment(data?.NextAutoCallDate).utc(true).tz(zone);
  const zonedNextManualCallDate = moment(data?.NextManualCallDate).utc(true).tz(zone);

  const handleCardClick = () => {
    setSelectedPatient(data);
  };

  const patientLastCall = useSelector((
    state: RootState,
  ) : ISurveyCall => state.DischargedPatients.surveyCallsByIds[data.LastCallId]);

  const lastCallOneAttemptCompleted = patientLastCall?.TelephonyStatus === 'Complete'
    && patientLastCall?.TaskStatus === 'Complete'
    && patientLastCall?.TransactionCount === 1

  return (
    <Card
      className="discharged-patient-list-item"
      onClick={handleCardClick}
      style={{ backgroundColor: cardBackgroundColor, cursor: 'pointer' }}
    >
      {!patientProfile && (<Card.Body>LOADING</Card.Body>)}
      {patientProfile && (
        <Card.Body>
          <fieldset>
            <div className="row">
              <div className="col-xs-10 col-md-8">
                {data.ReviewRequired && (
                  <span className="pr-2">
                    <FontAwesomeIcon
                      icon="exclamation-circle"
                      color="#FF0000"
                      title="This patient has a survey call that requires review."
                    />
                  </span>
                )}
                <strong title="Patient Name">
                  {patientProfile?.LastName}
                  {', '}
                  {patientProfile?.FirstName}
                </strong>
              </div>
              <div className="col-xs-10 col-md-4 text-right" title="Discharge Date">
                {data?.DischargeDate}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-10 col-md-6">
                {data?.Facility}
              </div>
              <div className="col-xs-10 col-md-6 text-right" title="Program Status">
                {data?.ProgramStatus}
              </div>
            </div>
            <div className="row">

              <div className="col-xs-8 col-md-6">
                {(data.NextManualCallDate || data.NextAutoCallDate)
              && (
                <div className="pr-2">
                  Next Calls:
                  {' '}
                  <div>
                    <span>
                      {data.NextManualCallDate !== null && (
                        <>
                          {format(zonedNextManualCallDate.toDate(), 'LLL dd')}
                          <ManualPhoneCallIcon />
                        </>
                      )}

                    </span>
                    <span className="pl-2">
                      {data.NextAutoCallDate !== null && (
                        <>
                          {format(zonedNextAutoCallDate.toDate(), 'LLL dd')}
                          <AutoCallIcon />
                        </>
                      )}

                    </span>
                  </div>
                </div>
              )}
              </div>

              {data.LastCallDate !== null && (
                <div className="col-xs-8 col-md-6">
                  <span className="pr-2">
                    Last Call:
                    {' '}
                  </span>
                  <div>
                    {format(zonedLastCallDate.toDate(), 'LLL dd')}
                    {data.LastCallType === 'Auto' && (
                      <>
                        <AutoCallIcon />
                        {!lastCallOneAttemptCompleted && data.LastCallTransactionCount > 0 && (
                          <span className="pl-2" title="# of attempts">
                            {`(${data.LastCallTransactionCount})`}
                          </span>
                        )}
                      </>
                    )}
                    {data.LastCallType === 'Manual' && (
                      <ManualPhoneCallIcon />
                    )}
                  </div>
                </div>
              )}
            </div>
          </fieldset>
        </Card.Body>
      )}
    </Card>
  );
};
