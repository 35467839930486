import React from 'react';

import { ReactComponent as Heart } from 'styles/images/heart.svg';

const REFFERAL_LINK = 'https://goicon.com/customer-referral/';

export const Refferal = () => (
  <>
    <div className="refferal__box">
      <h5 className="refferal__box__heading mb-0">
        Love Icon?
      </h5>
      <h5 className="refferal__box__heading mb-3">
        Refer and Earn!
      </h5>
      <p className="refferal__box__paragraph mb-3">
        Refer a colleague and get a free month of Icon for your organization if they sign up!
      </p>

      <button
        className="btn btn-warning btn-lg square refferal__box__button"
        type="button"
        onClick={() => {
          window.open(REFFERAL_LINK, '_blank');
        }}
      >
        <Heart width="22" height="22" style={{ fill: 'currentColor' }} className="mr-2" />
        Refer Icon
      </button>
    </div>
    <span className="refferal__terms">
      <b>
        <a
          href="https://help.voicefriend.net/article/398-referral-program-terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms
        </a>
      </b>
      {' '}
      apply
    </span>
  </>
)
