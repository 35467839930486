import API from './API';
import { CalendarFilters, ICalendarPayload } from '../types/ICalendarDay';

export const API_PATH = '/api/v2/calendar/CalendarEventsFilter';

export async function eventsGet(
  filters: CalendarFilters,
): Promise<ICalendarPayload> {
  const startDate = filters.startDate.substr(0, 10);
  const endDate = filters.endDate.substr(0, 10);
  const params = {
    startDate,
    endDate,
    GroupIds: filters.groupIds?.length ? filters.groupIds : undefined,
    ProfileIds: filters.profileIds?.length ? filters.profileIds : undefined,
  };

  const response = await API.post<ICalendarPayload>(API_PATH, params);
  return response.data;
}
