import { Card } from 'react-bootstrap';
import React, { useState } from 'react';
import { IEntityLabel } from 'types/IEntityLabel';
import { Controller, useFormContext } from 'react-hook-form';
import { Label } from '../Label';
import { CreateAssociationModal } from './CreateAssociationModal';
import { DeleteAssociationModal } from './DeleteAssociationModal';

interface TemplateLabelDetailProps {
  labels: IEntityLabel[]
  onChange: (associations: IEntityLabel[]) => void
}

export const TemplateLabelDetail: React.FC<TemplateLabelDetailProps> = ({
  labels,
  onChange,
}) => {
  const [showAssociate, setShowAssociate] = React.useState(false);
  const [labelToDelete, setLabelToDelete] = React.useState<null|IEntityLabel>(null);
  const [templateLabels, setTemplateLabels] = useState<IEntityLabel[]>(labels);
  const { control } = useFormContext();
  const handleDelete = (id: string) => {
    const newLabels = templateLabels.filter((l) => l.EntityLabelId !== id);
    setTemplateLabels(newLabels);
    onChange(newLabels);
  };

  const shortenLongName = (longName: string) => {
    if (longName && longName.length > 40) {
      return `${longName.substring(0, 40)}...`;
    }
    return longName;
  }

  return (
    <Controller
      control={control}
      name="Labels"
      defaultValue={[]}
      render={() => (
        <>
          {templateLabels && templateLabels.length > 0 && (
            <>
              <span className="entitylabel-title">Label as:</span>
              <Card className="entitylabels-card">
                <div>
                  {templateLabels?.map((label) => (
                    <Label
                      name={label.Name}
                      key={label.EntityLabelId}
                      showDelete
                      onDelete={() => {
                        setLabelToDelete(label);
                      }}
                    />
                  ))}
                </div>
              </Card>
            </>
          )}
          <button
            type="button"
            className="btn btn-medium btn-primary square btn-addlabel"
            onClick={() => {
              setShowAssociate(true);
            }}
          >
            Add Label
          </button>
          {showAssociate && (
            <CreateAssociationModal
              onSave={(associations) => {
                setShowAssociate(false);
                setTemplateLabels(associations);
                onChange(associations);
              }}
              onCancel={() => {
                setShowAssociate(false);
              }}
              onCreate={() => {
                setShowAssociate(false);
              }}
              templateLabels={templateLabels}
            />
          )}
          {!!labelToDelete && (
            <DeleteAssociationModal
              onHide={() => {
                setLabelToDelete(null);
              }}
              onDelete={() => {
                handleDelete(labelToDelete.EntityLabelId);
                setLabelToDelete(null);
              }}
              label={shortenLongName(labelToDelete.Name)}
            />
          )}
        </>
      )}
    />
  )
}
