import { Switch, Route } from 'react-router-dom';
import React from 'react';

import CalendarIndexPage from '../pages/Calendar/Index';

function CalendarRoutes() {
  return (
    <Switch>
      <Route path="/calendar" component={CalendarIndexPage} />
    </Switch>
  );
}

export default CalendarRoutes;
