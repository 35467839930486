import { ITemplate } from 'types/IEvent';

export const getTemplateClone = (template: ITemplate) => ({
  ...template,
  ID: undefined,
  IsDraft: true,
  Name: `${template.Name.length > 43
    ? `${template.Name.slice(0, 40)}...`
    : template.Name
  } - Copy`,
  GroupIds: template.GroupIds ?? [],
  ProfileIds: template.ProfileIds ?? [],
})
