import React, {
  useState, useMemo, ReactElement, useCallback,
} from 'react';
import { CellProps } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card } from 'react-bootstrap';
import _difference from 'lodash/difference';
import useUpdateEffect from 'react-use/lib/useUpdateEffect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons';

import log from 'lib/logging';
import Table from 'components/Table';
import { DeleteActionModal } from 'components/DeleteActionModal';
import { BulkActions } from 'components/BulkActions';
import {
  getSurveyQuestionListItemIndex,
  selectQuestions,
  deleteBatchSurveyQuestionListItem,
  selectById,
  selectByIdLoading,
  updateBatchSurveyQuestionListItem,
} from 'reducers/SurveyQuestionList';
import {
  ISurveyQuestionListItem, ISurveyQuestionList,
} from 'types/ISurveyQuestionList';
import { getAllLibraryContent, getLibraryIsLoading } from 'reducers/LibraryContent';
import { hasError } from 'reducers/Error';
import { ILibraryContent } from 'types/ILibraryContent';
import { EditItemModal } from './EditItemModal';
import { CreateItemModal } from './CreateItemModal';

const sortByOrdinal = (
  items: ISurveyQuestionListItem[],
): ISurveyQuestionListItem[] => [...items].sort((a, b) => a.Ordinal - b.Ordinal);

function SurveyQuestionListItemsTable(): ReactElement {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [itemToEdit, setItemToEdit] = useState<ISurveyQuestionListItem|null>(null);
  const dispatch = useDispatch();
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [itemToDeleteIds, setItemToDeleteIds] = useState<string[]>([]);

  const { id: listId } = useParams<{ id: string }>();

  const questions: ISurveyQuestionListItem[] = useSelector(selectQuestions);

  const [data, setData] = useState<ISurveyQuestionListItem[]>(sortByOrdinal(questions));

  useUpdateEffect(() => {
    setData(sortByOrdinal(questions));
  }, [questions]);

  const updateData = (newData) => {
    const oldData = [...data];
    const updatedData = [];
    const itemsToUpdate = [];
    const itemsToRollback = [];

    for (let i = 1; i <= newData.length; i++) {
      if (newData[i - 1].Ordinal !== i) {
        const updatedItem = {
          ...newData[i - 1],
          Ordinal: i,
        }
        updatedData.push(updatedItem);
        itemsToUpdate.push(updatedItem);
        itemsToRollback.push(newData[i - 1]);
      } else {
        updatedData.push(newData[i - 1]);
      }
    }

    const rollbackUpdate = () => {
      setData(oldData);
      dispatch(updateBatchSurveyQuestionListItem(+listId, itemsToRollback, (err) => {
        if (err) {
          dispatch(hasError(err));
        }
      }));
    }

    setData(updatedData);

    if (!itemsToUpdate.length) {
      return;
    }

    dispatch(updateBatchSurveyQuestionListItem(+listId, itemsToUpdate, (err) => {
      if (err) {
        dispatch(hasError(err));
        rollbackUpdate();
      }
    }))
  }

  const questionListData: ISurveyQuestionList = useSelector(selectById);
  const questionListDataLoading = useSelector(selectByIdLoading);

  const libraryData: ILibraryContent[] = useSelector(getAllLibraryContent);
  const libraryDataLoading: boolean = useSelector(getLibraryIsLoading);

  const isLoading = libraryDataLoading || questionListDataLoading;

  const callback = useCallback(({ cell: { value } }) => (
    <>
      <FontAwesomeIcon
        icon={faArrowsAlt}
        className="Table__draggable-handle"
        style={{ marginRight: '0.5rem', cursor: 'move' }}
      />
      {value}
    </>
  ), []);

  const columns = useMemo(() => [
    {
      Header: 'Order',
      accessor: 'Ordinal',
      Cell: callback,
    },
    {
      Header: 'Voice Content',
      accessor: 'VoiceContent',
      Cell: (props: CellProps<ISurveyQuestionListItem>): string => {
        const {
          row: { original },
        } = props;
        if (original.VoiceContent) {
          return original.VoiceContent;
        }

        if (isLoading) {
          return 'Loading...';
        }

        if (!questionListData) {
          return '';
        }

        const found = libraryData.find((item) => +item.ID === original.ContentId);

        if (!found) {
          log.error('SurveyQuestionListItem not found in library');
          return ''
        }

        return found.VoiceContent?.Content ?? '';
      },
    },
    {
      Header: 'SMS Content',
      accessor: 'SMSContent',
    },
  ], [isLoading, libraryData, questionListData, callback]);

  const handleDelete = () => {
    const updateSelectedRowIds = (undeletedItems = []) => {
      const deletedItems = _difference(itemToDeleteIds, undeletedItems);
      setSelectedRowIds(_difference(selectedRowIds, deletedItems));
    }

    dispatch(deleteBatchSurveyQuestionListItem(+listId, itemToDeleteIds, (errors) => {
      if (errors) {
        errors.forEach((err) => {
          toast.error(err.message);
        })
        updateSelectedRowIds(errors.map((err) => err.id).filter(Boolean));
      } else {
        toast.success(`Survey Question List Question${itemToDeleteIds.length > 1 ? 's' : ''} successfully deleted`);
        updateSelectedRowIds();
      }
      dispatch(getSurveyQuestionListItemIndex(+listId));
      setItemToDeleteIds([]);
    }));
  }

  const rowActionItems = (id: string, item): ReactElement => (
    <>
      <a
        href={`/surveyQuestionLists/${listId}`}
        onClick={(e) => {
          e.preventDefault();
          setItemToEdit(item);
        }}
      >
        Edit
      </a>
      {' '}
      <a
        href={`/surveyQuestionLists/${listId}`}
        onClick={(e) => {
          e.preventDefault();
          setItemToDeleteIds([id]);
        }}
      >
        Delete
      </a>
    </>
  );

  const renderBulkActions = () => {
    if (selectedRowIds.length === 0) {
      return null;
    }

    return (
      <div
        className="bulk-actions ml-3"
        style={{ height: 40 }}
      >
        <BulkActions items={[
          {
            label: 'Delete',
            handler: () => {
              setItemToDeleteIds([...selectedRowIds]);
            },
          }]}
        />
      </div>
    )
  }

  return (
    <>
      <Card className="mb-4">
        <Card.Header as="h4">
          Questions
        </Card.Header>
        <Card.Body className="d-block">

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary right"
              style={{ marginBottom: '10px' }}
              onClick={() => {
                setShowCreateModal(true);
              }}
            >
              Add
            </button>
            {renderBulkActions()}
          </div>
          <Table
            columns={columns}
            data={data}
            rowIDAccessor="SurveyQuestionContentId"
            rowActionItems={rowActionItems}
            selectedRowIds={selectedRowIds}
            onRowClick={(id, item) => {
              setItemToEdit(item)
            }}
            onRowSelect={(selectedIds) => {
              setSelectedRowIds(selectedIds);
            }}
            draggableRows
            updateData={updateData}
          />
        </Card.Body>
      </Card>
      <DeleteActionModal
        isOpen={itemToDeleteIds.length !== 0}
        title="Are you sure?"
        onCancel={() => setItemToDeleteIds([])}
        onSuccess={handleDelete}
      />
      <EditItemModal
        show={!!itemToEdit}
        onHide={() => {
          setItemToEdit(null)
        }}
        item={itemToEdit}
      />
      <CreateItemModal
        show={showCreateModal}
        onHide={() => {
          setShowCreateModal(false);
        }}
      />
    </>
  );
}

export default SurveyQuestionListItemsTable;
