/* eslint-disable */
import React, { useEffect, useState, ReactElement, FormEvent, useCallback, ReactNode, useRef } from 'react';

import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Alert, Col, Row } from 'react-bootstrap';
import queryString from 'query-string';
import log from 'lib/logging';
import { useCookies } from 'react-cookie/es6';
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';
import { useQueryParam, StringParam } from 'use-query-params';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import Checkbox from 'components/Inputs/Checkbox';
import { ReactComponent as Logo } from 'styles/images/goicon-logo.svg';
import { Refferal } from 'components/Refferal';
import { ReactComponent as LoginIllustration } from 'styles/images/login_illustration.svg';
import { authenticate, forgotPassword, getLoggedInStatus, setCurrentNavItem } from '../reducers/UserInfo';
import Input from '../components/Inputs/Input';
import PasswordInput from 'components/Inputs/PasswordInput';
import { RootState } from '../types/rootState';

interface IForgotPasswordProps {
  message: string;
  isError: boolean;
}
const REMEMBER_USERNAME_COOKIE = 'RememberMe'; // consist with the legacy ui.

function LoginBox(): ReactElement {
  const dispatch = useDispatch();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState<IForgotPasswordProps>(null);
  const [rememberMe, setRememberMe] = useState<boolean>(true);
  const passwordInputRef = useRef<HTMLInputElement>();
  const usernameInputRef = useRef<HTMLInputElement>();
  const loggedInStatus = useSelector(getLoggedInStatus);
  const [reason, setReason] = useQueryParam('reason', StringParam);

  useEffect(() => {
    // load remember me setting from cookie at page load.
    const rememberMeCookie = Cookies.get(REMEMBER_USERNAME_COOKIE);
    if (rememberMeCookie) {
      if (rememberMeCookie.includes('user=')) {
        const decodedUsername = window.atob(rememberMeCookie.substring(5));
        setUsername(decodedUsername);
        Cookies.remove(rememberMeCookie);
      } else {
        setUsername(rememberMeCookie);
      }
      if (passwordInputRef.current) {
        passwordInputRef.current.focus();
      }
      return;
    }
    if (usernameInputRef.current) {
      usernameInputRef.current.focus();
    }
  }, [dispatch]);

  const { error, loading, loggedIn, changePassword, requireMfa, startPath, token } = useSelector(
    (state: RootState) => state.UserInfo,
  );
  const [, , removeCookie] = useCookies();
  const onSubmit = (event: FormEvent): void => {
    event.preventDefault();
    setReason('');
    if (rememberMe) {
      Cookies.set(REMEMBER_USERNAME_COOKIE, username, { expires: 365 });
    } else {
      Cookies.remove(REMEMBER_USERNAME_COOKIE);
    }
    removeCookie('OldLogon');
    dispatch(setCurrentNavItem(null));
    dispatch(authenticate(username, password));
  };

  const renderForgotPasswordTip = (): ReactElement => {
    if (!error.failure && forgotPasswordMessage) {
      if (forgotPasswordMessage.isError) {
        return <span className="errors">{forgotPasswordMessage.message}</span>;
      }
      return (
        <Alert variant="primary" className="w-100 mb-0">
          {forgotPasswordMessage.message}
        </Alert>
      );
    }
    return null;
  };

  const renderError = (): ReactElement => {
    if (error.failure) {
      return <span className="errors">{error.message}</span>;
    }
    return null;
  };

  const renderReason = useCallback((): ReactNode => {
    if (reason) {
      const message =
        reason === 'timeout' ? 'Your session has expired. Please log in again.' : 'You have been logged out';
      return <span className="errors">{message}</span>;
    }
    return null;
  }, [reason]);

  if (loggedIn && !(changePassword || requireMfa) && loggedInStatus) {
    const params = queryString.parse(document.location.search);
    const redirect = decodeURIComponent(`${params.redirectTo || startPath}`);
    return <Redirect to={redirect} push />;
  }
  if (loggedIn && requireMfa) {
    const url = '/mfa';
    return <Redirect to={url} />;
  }
  if (loggedIn && changePassword) {
    const url = `/changePassword?source=requireChangePassword&user=${username}`;
    return <Redirect to={url} />;
  }

  return (
    <div className="LoginPage__form-box">
      <Logo className="LoginPage__form-box__logo" />
      <div className="form-group LoginPage__form-box__message-container">
        {renderReason()}
        {renderError()}
        {renderForgotPasswordTip()}
      </div>

      <form onSubmit={onSubmit}>
        <label htmlFor="username" className="LoginPage__form-box__label">
          Username
        </label>
        <Input
          ref={usernameInputRef}
          type="text"
          name="username"
          id="username"
          value={username}
          autoComplete="username"
          onChange={(event) => setUsername(event.target.value)}
          style={{ marginBottom: '32px' }}
        />
        <label htmlFor="password" className="LoginPage__form-box__label">
          Password
        </label>
        <PasswordInput
          ref={passwordInputRef}
          id="password"
          name="password"
          value={password}
          autoComplete="current-password"
          onChange={(event) => setPassword(event.target.value)}
          className="mb-2"
        />

        <a
          href="/login"
          className="LoginPage__form-box__link"
          onClick={async (e) => {
            e.preventDefault();
            if (loading) return;
            if (!username) {
              setForgotPasswordMessage({
                message: 'Please fill in username',
                isError: true,
              });
            }
            if (username) {
              segmentAnalyticsTrack(trackActions.forgotPassword());
              await dispatch(forgotPassword(username));
              setForgotPasswordMessage({
                message: 'An email has been sent with instructions on how to reset your password',
                isError: false,
              });
            }
          }}
        >
          Forgot your password?
        </a>
        <Checkbox
          name="chkSaveUserName"
          label="Save User Name"
          defaultChecked
          onChange={(e) => {
            setRememberMe(e.target.checked);
          }}
          className="LoginPage__form-box__checkbox"
        />

        <input
          type="submit"
          value="Login"
          className="btn btn-lg btn-primary square w-100"
          disabled={loading}
          style={{ marginTop: '32px' }}
        />
      </form>
      <div className="appStoreBadges">
        <a href="https://play.google.com/store/apps/details?id=net.voicefriend.app&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            className="androidBadge"
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>

        <a href="https://apps.apple.com/us/app/voicefriend/id1563270162?itsct=apps_box_badge&amp;itscg=30200">
          <img
            className="appleBadge"
            alt="Download on the App Store"
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1623801600&h=0be826ae6347e24d2c33dfcef7429046"
          />
        </a>
      </div>
    </div>
  );
}

function LoginPage(): ReactElement {
  return (
    <div className="LoginPage">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Row className="h-100">
        <Col xs={12} xl={9} className="LoginPage__main">
          <div className="d-none d-lg-block LoginPage__illustration-container">
            <LoginIllustration className="LoginPage__illustration" />
          </div>

          <LoginBox />

          <SupportBox />
        </Col>
        <Col xl={3} className="d-none d-xl-flex LoginPage__side">
          <Refferal />
        </Col>
      </Row>
    </div>
  );
}

const SupportBox = () => (
  <div className="LoginPage__support-box">
    <span className="LoginPage__support-box__text">Need help?</span>
    <a href="tel:18889966993" title="Call Icon Support" className="LoginPage__support-box__link">
      888-996-6993
    </a>
    {' | '}
    <a href="mailto:support@goicon.com" title="Email Icon Support" className="LoginPage__support-box__link">
      support@goicon.com
    </a>
    {' | '}
    <a
      target="_blank"
      href="https://goicon.com/privacy-policy/"
      className="LoginPage__support-box__link"
    >
      Privacy Policy
    </a>
  </div>
);

interface IProps {
  title: string;
  children: React.ReactNode;
}

export function NoNavFrame({ title = 'Login', children }: IProps): ReactElement {
  return (
    <div className="Login">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="Login-form">
        <div className="form-container">
          <Logo className="logo" />
          {children}
        </div>
        <div className="copyright label">
          &copy;&nbsp;
          {new Date().getFullYear()} Icon. All rights reserved.
        </div>
      </div>
      <div className="Login-splash d-none d-lg-flex">
        <div className="Login-splash__img" />
      </div>
    </div>
  );
}

export default LoginPage;
