import { Input } from 'components/FormControls';
import { NoNavFrame } from 'pages/Login';
import React, { ReactElement, useEffect, useState } from 'react';

import {
  Alert,
  Form,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import SubmitButton from 'components/FormControls/SubmitButton';
import { logUserWithResetKey, validateUserPassword } from '../../reducers/UserInfo';
import { RootState } from '../../types/rootState';

interface IChangePassword {
  password: string
  confirmPassword: string
}

export default function ChangePassword():ReactElement {
  const params = queryString.parse(document.location.search);
  return (
    <NoNavFrame title="Change Your Password.">
      <ChangePasswordComponent
        source={params?.source}
        user={params?.user}
      />
    </NoNavFrame>
  );
}

interface IChangePasswordModeProps {
  source: string | string[]
  user: string | string[]
}

function ChangePasswordComponent({ source, user }: IChangePasswordModeProps): ReactElement {
  const changePasswordContent: {[key: string]: string } = {
    requireChangePassword: 'You must change your password to continue',
    forgotPassword: 'Please input your new password',
    userChangePassword: 'Please input your new password',
  }

  const dispatch = useDispatch();
  const {
    handleSubmit, errors, control, watch,
  } = useForm<IChangePassword>();
  const history = useHistory();
  const [key] = useQueryParam<string>('key');
  const [isResetKeyMissing, setIsResetKeyMissing] = useState<boolean>(false);
  const [, , removeCookie] = useCookies(['VFHideNav']);
  useEffect(() => {
    const login = async () => {
      if (key) {
        dispatch(logUserWithResetKey(key));
      } else {
        setIsResetKeyMissing(true);
      }
    }
    login();
  }, [dispatch, key]);

  const {
    startPath, loggedIn, loading,
    requireMfa,
  } = useSelector((state: RootState) => state.UserInfo);
  const password = watch('password');
  const changePasswordErrors = useSelector((state: RootState) => state.UserInfo.passwordError);
  const resetKeyErrors = useSelector((state: RootState) => state.UserInfo.resetKeyError);
  const onSubmit = async (values) => {
    removeCookie('VFHideNav');
    dispatch(validateUserPassword(values.password, () => {
      history.push(startPath);
    }));
  };

  const renderTitle = () => (
    <h4>
      {source === undefined
        ? 'Please input your new password'
        : changePasswordContent[source?.toString()]}
    </h4>
  )

  if (loggedIn && requireMfa) {
    const url = '/mfa';
    return (
      <Redirect to={url} />
    );
  }
  if (!loggedIn) {
    return (
      <div>
        {resetKeyErrors.failure && (
          <Alert variant="danger">
            {resetKeyErrors.message}
          </Alert>
        )}
      </div>
    )
  }

  if (isResetKeyMissing && source == 'forgotPassword') {
    return (
      <div>
        <Alert variant="danger">
          The reset key is missing.
        </Alert>
      </div>
    )
  }

  return (
    <>
      {renderTitle()}
      {changePasswordErrors.failure && (
        <Alert variant="danger">
          {changePasswordErrors.message}
        </Alert>
      )}
      {!resetKeyErrors.failure
      && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Input
              id="password"
              name="password"
              className={errors.password ? 'errorBorder' : ''}
              type="password"
              label="Password"
              control={control}
              rules={{
                required: 'Password must be supplied',
              }}
            />
            <span className="text-danger">
              {errors.password && errors.password.message}
            </span>
          </Form.Group>
          <Form.Group>
            <Input
              id="confirmPassword"
              name="confirmPassword"
              className={errors.confirmPassword ? 'errorBorder' : ''}
              label="Confirm Password"
              type="password"
              control={control}
              rules={{
                required: 'Confirm Password must be filled out',
                validate: (value) => password === value || 'Passwords must match',
              }}
            />
            <span className="text-danger">
              {errors.confirmPassword && errors.confirmPassword.message}
              &nbsp;
            </span>
          </Form.Group>
          <SubmitButton
            label="Submit"
            savingLabel="Saving..."
            className="btn btn-primary"
            saveComplete={!loading}
          />
        </Form>
      )}
    </>
  )
}
