import { useSelector } from 'react-redux';
import { selectUserRoles } from 'reducers/UserInfo';

export const hasRole = (userRoles, role) => {
  if (!userRoles) {
    return false;
  }
  return userRoles.includes(role);
}

type UseHasRoles = ((role: string) => boolean) & ((roles: string[]) => boolean[]);

export const useHasRoles: UseHasRoles = (roles) => {
  const userRoles = useSelector(selectUserRoles);

  if (Array.isArray(roles)) {
    return roles.map((role) => hasRole(userRoles, role));
  }

  return hasRole(userRoles, roles);
}
