import React, {
  useState, useEffect, useCallback, FC, ReactElement,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _difference from 'lodash/difference';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';

import { getGroupsIndex } from 'reducers/Groups';
import {
  getTemplatesIndex,
  selectTemplatesPage,
  selectPaginationData,
  deleteBatchTemplate,
  selectTemplateIsLoading,
} from 'reducers/Templates';
import { hasError } from 'reducers/Error';
import { ITemplatesIndexParams } from 'types/ITemplatesIndexParams';
import IndexFilter, {
  ISearchQuery,
} from 'components/Inputs/IndexFilter_Id_Name';
import { DeleteActionModal } from 'components/DeleteActionModal';
import { ITemplate } from 'types/IEvent';
import { BulkActions } from 'components/BulkActions';
import Pagination from 'components/Pagination';
import Table from 'components/Table';
import { LabelsCell } from 'components/LabelsCell';
import { ViewLabels } from 'components/ViewLabels';

type Props = {
  showActions?: boolean
  selectButton?: string
  templateFilterType?: 'transactional'
};

/**
 * @deprecated use components/Templates/TemplateTable
 */
export const AccushieldTemplateTable: FC<Props> = ({
  showActions,
  selectButton,
  templateFilterType,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const columns = [
    {
      Header: 'Name', accessor: 'Name', sortable: true, showToolTip: true,
    },
    { Header: 'Labels', accessor: 'Labels', Cell: LabelsCell },
    {
      Header: 'Description', accessor: 'Description', sortable: true, showToolTip: true,
    },
  ].filter(Boolean);

  const [currentPage, setCurrentPage] = useQueryParam('page', NumberParam);
  const [searchQuery, setSearchQuery] = useQueryParam(
    'searchQuery',
    StringParam,
  );
  const [sortField, setSortField] = useQueryParam('sortField', StringParam);
  const [sortDirection, setSortDirection] = useQueryParam(
    'sortDirection',
    StringParam,
  );

  const [itemToDeleteIds, setItemToDeleteIds] = useState<string[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const templatesLoading = useSelector(selectTemplateIsLoading);

  const resetFilters = (): void => {
    setSearchQuery('', 'pushIn');
    setCurrentPage(1, 'pushIn');
    setSelectedRowIds([]);
  };

  const getSearchQuery = useCallback((): ISearchQuery => {
    const query = searchQuery?.split(',');
    if (!query) return {};
    return { searchField: query[0], searchValue: query[1] };
  }, [searchQuery]);

  const getTemplateIndexParams = useCallback(() => {
    const params: ITemplatesIndexParams = {
      page: currentPage,
      perpage: 10,
      skipTemplateAttachments: true,
    };

    const query = getSearchQuery();
    if (query.searchField && query.searchValue) {
      params.searchField = query.searchField;
      params.searchValue = query.searchValue;
    }

    if (sortField && sortDirection) {
      params.sortField = sortField;
      params.sortDirection = sortDirection;
    }

    return params;
  }, [currentPage, getSearchQuery, sortField, sortDirection]);

  const dispatchTemplatesIndex = useCallback((): void => {
    dispatch(getTemplatesIndex(getTemplateIndexParams(), templateFilterType));
  }, [templateFilterType, dispatch, getTemplateIndexParams]);

  useEffect(() => {
    dispatchTemplatesIndex();
  }, [dispatchTemplatesIndex]);

  React.useEffect(() => {
    dispatch(getGroupsIndex());
  }, [dispatch]);

  const templates: ITemplate[] = useSelector(
    selectTemplatesPage(getTemplateIndexParams()),
  );
  const paginationData = useSelector(selectPaginationData);

  const onSearch = (newSearchQuery: ISearchQuery): void => {
    if (newSearchQuery.searchField || newSearchQuery.searchValue) {
      setSearchQuery(
        `${newSearchQuery.searchField || ''},${newSearchQuery?.searchValue || ''
        }`,
      );
    } else {
      setSearchQuery('');
    }
    setCurrentPage(1, 'pushIn');
    setSelectedRowIds([]);
  };

  const handleDelete = () => {
    const updateSelectedRowIds = (undeletedItems = []) => {
      const deletedItems = _difference(itemToDeleteIds, undeletedItems);
      setSelectedRowIds(_difference(selectedRowIds, deletedItems));
    }

    dispatch(deleteBatchTemplate(itemToDeleteIds, false, (errors) => {
      if (errors) {
        errors.forEach((err) => {
          dispatch(hasError(err));
        })
        updateSelectedRowIds(errors.map((err) => err.id).filter(Boolean));
      } else {
        toast.success(`Template${itemToDeleteIds.length > 1 ? 's' : ''} successfully deleted`);
        updateSelectedRowIds();
      }
      dispatchTemplatesIndex();
      setItemToDeleteIds([]);
    }));
  }

  const renderBulkActions = () => {
    if (selectedRowIds.length === 0) {
      return null;
    }

    return (
      <BulkActions
        className="ml-3"
        items={[
          {
            label: 'Delete',
            handler: () => {
              setItemToDeleteIds([...selectedRowIds]);
            },
            className: 'delete-action',
          }]}
      />
    )
  }

  const rowBulkActionItems = (entityId: string, entity: ITemplate): ReactElement => {
    const actionItems = [];

    actionItems.push({
      label: 'Edit',
      handler: () => {
        history.push(`/templates/${entityId}/edit/${templateFilterType}`);
      },
    });

    actionItems.push({
      label: 'Clone',
      handler: () => {
        history.push({
          pathname: '/templates/new',
          state: { sourceTemplateId: entity.ID, sourceTemplateType: 'transactional' },
        });
      },
    });

    actionItems.push({
      label: 'Delete',
      handler: () => {
        setItemToDeleteIds([entityId]);
      },
      className: 'delete-action',
    });

    return (
      <BulkActions
        className="ml-3"
        items={actionItems}
      />
    );
  };

  const renderDeleteActionModalChildren = () => {
    if (!itemToDeleteIds.length || itemToDeleteIds.length > 1) {
      return null;
    }

    const templateToDeleteId = +itemToDeleteIds[0];
    const templateToDelete = templates.find((item) => item.ID === templateToDeleteId);

    return (
      <p>
        The template &quot;
        {templateToDelete?.Name ?? ''}
        &quot; will be deleted.
      </p>
    )
  }

  return (
    <div>
      <DeleteActionModal
        isOpen={itemToDeleteIds.length !== 0}
        title="Are you sure?"
        onCancel={() => setItemToDeleteIds([])}
        onSuccess={handleDelete}
        isDeleting={templatesLoading}
      >
        {renderDeleteActionModalChildren()}
      </DeleteActionModal>

      <div>
        <IndexFilter
          searchQuery={getSearchQuery()}
          onSearch={onSearch}
          onResetFilters={resetFilters}
          title="Template"
          totalItems={paginationData.TotalItems}
          itemsLoading={templatesLoading}
          defaultField="name"
          options={[{ label: 'Label', value: 'label' }]}
        />
        <div className="d-flex justify-content-end ml-auto mb-2">
          <ViewLabels />
          {renderBulkActions()}
        </div>
      </div>
      <br />
      <Table
        className="Template-Table"
        columns={columns}
        data={templates}
        selectButton={selectButton}
        rowIDAccessor="ID"
        selectedRowIds={selectedRowIds}
        onRowSelect={(selectedIds) => {
          setSelectedRowIds(selectedIds);
        }}
        isLoading={templatesLoading}
        rowActionItems={showActions ? rowBulkActionItems : undefined}
        onColumnSort={(fieldName, direction) => {
          if (fieldName.length > 0) {
            setSortField(fieldName);
            setSortDirection(direction);
          } else {
            setSortField('');
            setSortDirection('');
          }
        }}
        defaultSortedColumn={sortField}
        defaultSortedColumnDirection={sortDirection}
      />

      <Pagination
        currentPage={currentPage || 1}
        totalPages={paginationData.TotalPages}
        onPageChange={(page: number) => setCurrentPage(page, 'pushIn')}
        pageDelta={5}
      />
    </div>
  );
};
