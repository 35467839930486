import React from 'react'

// styles
import { Col, Row } from 'react-bootstrap';
// import DoughnutChart from '../charts/doughnut-chart/DoughnutChart';
import { useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import { IUserInfoState } from 'reducers/IUserInfoState';
import { ReportDisplay } from './ReportDisplay';
import { useGetReportData, WrappedDateRangePicker } from './WrappedDateRangePicker';

function MiddleDashboard() {
  const userInfo = useSelector((state: RootState) : IUserInfoState => state.UserInfo);
  const acctTimezone = userInfo.accountTimezone;
  const [dateRange, setDateRange, reportData] = useGetReportData(acctTimezone);
  return (
    <>
      <Row><Col><h1>Dashboard</h1></Col></Row>
      <Row>
        <Col className="d-flex justify-content-end align-items-center">
          <WrappedDateRangePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
            acctTimezone={acctTimezone}
          />
        </Col>
      </Row>
      <Row className="middleDashboardContainer pt-5">
        <ReportDisplay reportData={reportData} />
      </Row>
    </>
  )
}

export default MiddleDashboard
