import React,
{
  ReactElement, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { RootState } from 'types/rootState';
import { useHistory } from 'react-router-dom';
import { exchangeToken, handleDestroyToken } from 'reducers/UserInfo';
import { updateLogoutUrl } from 'reducers/SamlData';
import { NoNavFrame } from './Login';

function CookieExchange(): ReactElement {
  const {
    loggedIn: successfullyLoggedIn,
    logoutUrl,
    message,
  } = queryString.parse(document.location.search, { parseBooleans: true });
  const userInfo = useSelector((state: RootState) => state.UserInfo);
  const { startPath } = userInfo;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    handleDestroyToken();
    const redirectPostAccChange = (): void => {
      history.push(startPath);
    };
    if (successfullyLoggedIn as boolean) {
      dispatch(updateLogoutUrl(logoutUrl as string));
      dispatch(exchangeToken(redirectPostAccChange));
    }
  }, [dispatch, history, logoutUrl, startPath, successfullyLoggedIn]);

  return (
    <>
      {successfullyLoggedIn && (
        <NoNavFrame title="Loading">
          <div className="header-bar">
            <p>Please wait while we log you in.</p>
          </div>
        </NoNavFrame>
      )}

      {!successfullyLoggedIn && (
        <NoNavFrame title="Error">
          <div className="header-bar">
            <p>There was an error with logging in.</p>
            <p>{message}</p>
          </div>
        </NoNavFrame>
      )}
    </>
  )
}

export default CookieExchange;
