import * as UserInfoAPI from 'api/UserInfoAPI';
import { useSelector } from 'react-redux';
import { getUserState, hasFeature } from 'reducers/UserInfo';
import useSWR from 'swr/immutable';

export function useHasFeatures(featureSlug: string): {
  hasFeature: boolean
  isLoading: boolean
};
export function useHasFeatures(featureSlugs: string[]): {
  hasFeature: boolean[]
  isLoading: boolean
};
export function useHasFeatures(featureSlugs) {
  const userState = useSelector(getUserState);
  const accountId = userState.userInfo?.AccountDetail?.AccountID ?? '';

  const { data: enabledFeatures, error } = useSWR(
    ['enabled-features', accountId],
    UserInfoAPI.getEnabledFeatures,
  );

  // default to loading state if no account is selected
  if (!accountId) {
    if (Array.isArray(featureSlugs)) {
      return {
        hasFeature: featureSlugs.map((featureSlug) => false),
        isLoading: true,
      };
    }
    return { hasFeature: false, isLoading: true };
  }

  // both data and error as undefined indicates initial loading state.
  const isInitialLoading = (enabledFeatures === undefined && error === undefined);
  const hasFeatureSetting: any = hasFeature(enabledFeatures, featureSlugs);
  if (Array.isArray(featureSlugs)) {
    return {
      hasFeature: featureSlugs.map((featureSlug) => hasFeature(enabledFeatures, featureSlug)),
      isLoading: isInitialLoading,
    };
  }
  return { hasFeature: hasFeatureSetting, isLoading: isInitialLoading };
}
