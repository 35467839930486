import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import _union from 'lodash/union';

import { IGroupsPayload } from 'types/IGroupsPayload';
import { IGroupMembers } from 'types/IGroupMembers';
import { getProfilesByCpids } from 'reducers/Profiles';
import { toast } from 'react-toastify';
import { ICaremergeCategory, IGroup } from '../types/IGroup';
import { RootState } from '../types/rootState';
import { AppThunk } from '../app/appThunk';
import { IGroupsState, DEFAULT_GROUPS } from './IGroupsState';
import { IProfilesIndexParams } from '../types/IProfilesIndexParams';
import { IProfilesPayload } from '../types/IProfilesPayload';
import { IProfile } from './IProfile';
import { hasError } from './Error';
import { selectIsSyncedCustomer } from './UserInfo';

export const INITIAL_STATE: IGroupsState = {
  byIds: {},
  allIds: [],
  membersByIds: {},
  loading: false,
  selectedGroupId: null,
  searchProfiles: [],
  pagination: {
    PerPage: 20,
    TotalItems: 0,
    TotalPages: 1,
  },
  profileIdsToAdd: [],
  filterGroupMembers: [],
  categories: [],
  categoryById: {},
};

const groupSchema = new schema.Entity('Groups', {}, { idAttribute: 'Id' });
const groupCategorySchema = new schema.Entity('CategoryName', {}, { idAttribute: 'CaremergeCategoryId' });
const GroupsSlice = createSlice({
  name: 'Groups',
  initialState: INITIAL_STATE,
  reducers: {
    groupsRequestStarted(draftReducerState) {
      draftReducerState.loading = true;
    },
    groupsPayloadSuccess(draftReducerState, action: PayloadAction<IGroup[]>) {
      const normalizedPayload = normalize(action.payload, [groupSchema]);
      draftReducerState.byIds = {
        ...draftReducerState.byIds,
        ...normalizedPayload.entities.Groups,
      };
      draftReducerState.allIds = _union(draftReducerState.allIds, normalizedPayload.result);
      draftReducerState.loading = false;
    },
    groupsRequestFailed(draftReducerState) {
      draftReducerState.loading = false;
    },
    groupsRequestComplete(draftReducerState) {
      draftReducerState.loading = false;
    },
    groupsSpecificRequest(draftReducerState, id: PayloadAction<number>) {
      draftReducerState.selectedGroupId = id.payload;
    },
    groupsSetSelectedOptional(draftReducerState, group: PayloadAction<number | undefined>) {
      if (group.payload !== undefined && group.payload in draftReducerState.byIds) {
        draftReducerState.selectedGroupId = group.payload;
      } else {
        draftReducerState.selectedGroupId = null;
      }
    },
    groupsSetSelectedId(draftReducerState, group: PayloadAction<number | null>) {
      draftReducerState.selectedGroupId = group.payload;
    },
    groupsSetSelectedProperty(
      draftReducerState,
      property: PayloadAction<{ key: string, value: any }>,
    ) {
      if (draftReducerState.selectedGroupId != null) {
        const { selectedGroupId } = draftReducerState;
        const { key } = property.payload;
        const theValue = property.payload.value;
        draftReducerState.byIds[selectedGroupId][key] = theValue;
      }
    },
    groupsSetMembers(
      draftReducerState,
      payload: PayloadAction<{ id: number, members: IGroupMembers }>,
    ) {
      const draftMembersByIds = draftReducerState.membersByIds;
      draftMembersByIds[payload.payload.id] = {
        ...payload.payload.members,
      }
    },

    groupsSetFilterMembers(
      draftReducerState,
      payload: PayloadAction<{ id: number, members: IGroupMembers }>,
    ) {
      // TODO this is just the top level group members
      draftReducerState.filterGroupMembers = payload.payload.members.CustomerProfileIds;
    },

    groupsRemoveGroup(draftReducerState, payload: PayloadAction<number>) {
      const id = payload.payload;
      if (draftReducerState.selectedGroupId === id) {
        draftReducerState.selectedGroupId = null;
      }
      if (id in draftReducerState.byIds) {
        delete draftReducerState.byIds[id];
      }
      draftReducerState.allIds = draftReducerState.allIds.filter((theirId) => theirId !== id);
    },
    groupsSetSearchProfiles(draftReducerState, action: PayloadAction<IProfilesPayload>) {
      draftReducerState.searchProfiles = action.payload.Profiles;
      const { PerPage, TotalItems, TotalPages } = action.payload.Pagination;

      draftReducerState.pagination = {
        PerPage,
        TotalItems,
        TotalPages,
      };
      draftReducerState.loading = false;
    },

    allGroupsPayloadSuccess(draftReducerState, action: PayloadAction<IGroupsPayload>) {
      const normalizedPayload = normalize(action.payload, [groupSchema]);
      const {
        PerPage,
        TotalItems,
        TotalPages,
      } = action.payload.Pagination;
      draftReducerState.byIds = {
        ...draftReducerState.byIds,
        ...normalizedPayload.entities.Groups,
      };
      draftReducerState.pagination = {
        PerPage,
        TotalItems,
        TotalPages,
      };
      draftReducerState.allIds = _union(draftReducerState.allIds, normalizedPayload.result);
      draftReducerState.loading = false;
    },
    groupCategoriesSuccess(draftReducerState, action: PayloadAction<ICaremergeCategory[]>) {
      let unCategorized: ICaremergeCategory = {
        CaremergeCategoryId: 0, CategoryName: 'Uncategorized', GroupIds: [],
      }
      const unCategorizedGroupIds = [];
      draftReducerState.allIds.forEach((id, index) => {
        const group = draftReducerState.byIds[id];
        if (!action.payload.find((c) => c.GroupIds.indexOf(id) !== -1) && group?.GroupType < 2) {
          unCategorizedGroupIds.push(id);
        }
      });
      if (action.payload.findIndex((c) => c.CaremergeCategoryId === null || c.CaremergeCategoryId === 0) === -1) {
        unCategorizedGroupIds.forEach((id) => { unCategorized.GroupIds.push(id) });
        action.payload.push(unCategorized);
      } else {
        unCategorized = action.payload.find((c) => c.CaremergeCategoryId === null || c.CaremergeCategoryId === 0);
        unCategorizedGroupIds.forEach((id) => { unCategorized.GroupIds.push(id) });
      }
      draftReducerState.categories = action.payload;
      const normalizedPayload = normalize(action.payload, [groupCategorySchema]);
      draftReducerState.categoryById = {
        ...draftReducerState.categoryById,
        ...normalizedPayload.entities.CategoryName,
      };
    },
  },
});

export const {
  groupsRequestStarted,
  groupsPayloadSuccess,
  groupsRequestFailed,
  groupsRequestComplete,
  groupsSetMembers,
  groupsSetFilterMembers,
  groupsRemoveGroup,
  groupsSetSelectedId,
  groupsSetSearchProfiles,
  allGroupsPayloadSuccess,
  groupCategoriesSuccess,
} = GroupsSlice.actions;

export default GroupsSlice.reducer;

// Thunks

export const getGroupsIndex = (id?: number): AppThunk => async (dispatch, getState, Api) => {
  dispatch(groupsRequestStarted());
  try {
    const groupsIndexResponse = await Api.Groups.groupsIndex(id);
    // no need get categories if account do not synced
    const state = getState();
    const isCaremergeSyncedCustomer = selectIsSyncedCustomer(state);

    if (isCaremergeSyncedCustomer) {
      const groupCategories = await Api.Groups.getGroupCategories();
      dispatch(groupCategoriesSuccess(groupCategories));
    }
    dispatch(groupsPayloadSuccess(groupsIndexResponse));
  } catch (err) {
    dispatch(groupsRequestFailed());
    dispatch(hasError(err.toString()));
  }
};

export const getManyGroupsMembers = (
  groupIds: number[],
): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(groupsRequestStarted());
  try {
    const groupMembers = await Promise.all(
      groupIds.map((id) => Api.Groups.getGroupMembers(id).then((members) => ({ id, members }))),
    );
    groupMembers.forEach((groupMemberProps) => dispatch(groupsSetMembers(groupMemberProps)));
    const profileIds = groupMembers.reduce((acc, groupMemberProps) => (
      [...acc, ...groupMemberProps.members.CustomerProfileIds]
    ), []);
    if (profileIds.length) {
      dispatch(getProfilesByCpids(profileIds))
    }
  } catch (err) {
    dispatch(hasError(err));
  }
  dispatch(groupsRequestComplete());
};

export const groupsDeleteMemberStart = (
  groupId: number,
  memberId: string,
  onDeleteComplete?: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(groupsRequestStarted());
  try {
    await Api.Groups.removeGroupMember(groupId, memberId);
    onDeleteComplete();
    dispatch(groupsRequestComplete());
  } catch (err) {
    dispatch(groupsRequestFailed());
    dispatch(hasError(err));
  }
};

export const addDeleteGroupsFromMember = (
  memberId: string,
  groupsToRemove: number[],
  groupsToAdd: number[],
  successCallback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(groupsRequestStarted());
  try {
    let updateHadFailure = false;

    groupsToRemove.forEach(async (groupId) => {
      try {
        await Api.Groups.removeGroupMember(groupId, memberId);
      } catch (err) {
        updateHadFailure = true;
        toast.error(err.response.data.Message);
      }
    });

    groupsToAdd.forEach(async (groupId) => {
      const groupMembers: IGroupMembers = { CustomerProfileIds: [memberId], GroupIds: [] };
      try {
        await Api.Groups.addGroupMember(groupId, groupMembers);
      } catch (err) {
        updateHadFailure = true;
        toast.error(err.response.data.Message);
      }
    });

    if (updateHadFailure) {
      dispatch(groupsRequestFailed());
    } else {
      dispatch(groupsRequestComplete());
      successCallback();
    }
  } catch (err) {
    dispatch(groupsRequestFailed());
    dispatch(hasError(err));
  }
};

export const groupDeleteStart = (
  groupId: number,
  successCallback: () => void,
): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(groupsRequestStarted());
  try {
    await Api.Groups.deleteGroup(groupId);
    dispatch(groupsRemoveGroup(groupId));
    dispatch(groupsRequestComplete());
    successCallback();
  } catch (err) {
    dispatch(groupsRequestFailed());
    dispatch(hasError(err));
  }
};

export const saveGroupWithMembers = (
  group: Partial<IGroup>,
  groupMembers: IGroupMembers,
  newCaremergeCategoryId: number|null|undefined,
  successCallback: () => void,
): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(groupsRequestStarted());
  try {
    await Promise.all([
      Api.Groups.saveGroup(group),
      Api.Groups.setGroupMembers(group.Id, groupMembers),
      newCaremergeCategoryId !== undefined
          && Api.Groups.assignCaremergeCategoryToGroup(newCaremergeCategoryId, group.Id),
    ]);
    dispatch(getGroupsIndex());
    await dispatch(getGroupsMembers(group.Id));
    dispatch(groupsRequestComplete());
    successCallback();
  } catch (err) {
    dispatch(groupsRequestFailed());
    dispatch(hasError(err));
  }
}

export const saveNewGroup = (group: IGroup, successCallback: ()=>void): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(groupsRequestStarted());
  try {
    if (group != null) {
      const newGroup = await Api.Groups.saveGroup(group);
      await dispatch(groupsSetSelectedId(newGroup.Id));
      dispatch(getGroupsIndex());
      successCallback();
    }
    dispatch(groupsRequestComplete());
  } catch (err) {
    dispatch(groupsRequestFailed());
    dispatch(hasError(err));
  }
}

export const saveNewSubGroup = (parentGroup: IGroup, subGroup: IGroup): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(groupsRequestStarted());
  try {
    if (parentGroup != null && subGroup != null) {
      const newGroup = await Api.Groups.saveGroup(subGroup);
      const parentGroupMembers = await Api.Groups.getGroupMembers(parentGroup.Id);
      parentGroupMembers.GroupIds = parentGroupMembers.GroupIds.concat(newGroup.Id);
      await Api.Groups.setGroupMembers(parentGroup.Id, parentGroupMembers);
      await dispatch(getGroupsIndex());
      await dispatch(getGroupsMembers(parentGroup.Id));
      await dispatch(groupsSetSelectedId(newGroup.Id));
    }
    dispatch(groupsRequestComplete());
  } catch (err) {
    dispatch(groupsRequestFailed());
    dispatch(hasError(err));
  }
}

export const persistGroup = (group: IGroup): AppThunk => async (
  dispatch, getState, Api,
): Promise<IGroup> => {
  dispatch(groupsRequestStarted());
  try {
    if (group !== null) {
      const selectedGroup = await Api.Groups.saveGroup(group);
      await dispatch(getGroupsIndex());
      dispatch(groupsSetSelectedId(selectedGroup.Id));
      await dispatch(getGroupsMembers(selectedGroup.Id));
      return selectedGroup;
    }
    dispatch(groupsRequestComplete());
  } catch (err) {
    dispatch(groupsRequestFailed());
    dispatch(hasError(err));
  }
  return group;
};

export const groupsAddMemberToGroup = (
  groupId: number,
  groupMembers: IGroupMembers,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(groupsRequestStarted());
  try {
    if (groupId !== null && groupId !== 0) {
      await Api.Groups.addGroupMember(groupId, groupMembers);
      await dispatch(getGroupsMembers(groupId));
    }
    dispatch(groupsRequestComplete());
  } catch (err) {
    dispatch(groupsRequestFailed());
    dispatch(hasError(err));
  }
};

export const groupsSearchNonMemberProfiles = (
  params: IProfilesIndexParams,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(groupsRequestStarted());
  try {
    const profilesIndexResponse = await Api.Profiles.profilesIndex(params);
    dispatch(groupsSetSearchProfiles(profilesIndexResponse));
  } catch (err) {
    dispatch(groupsRequestFailed());
    dispatch(hasError(err));
  }
};

export const getGroupsMembers = (groupId: number): AppThunk => async (
  dispatch, getState, Api,
): Promise<IGroupMembers> => {
  dispatch(groupsRequestStarted());
  try {
    const results = await Api.Groups.getGroupMembers(groupId);
    dispatch(groupsSetMembers({ id: groupId, members: results }));
    dispatch(groupsRequestComplete());
    return results;
  } catch (err) {
    dispatch(groupsRequestFailed());
    dispatch(hasError(err));
    return null;
  }
};

export const getFilterGroupsMembers = (groupId: number): AppThunk => async (
  dispatch, getState, Api,
): Promise<void> => {
  dispatch(groupsRequestStarted());
  if (groupId === 0) {
    groupsSetFilterMembers({ id: 0, members: { CustomerProfileIds: [], GroupIds: [] } })
  } else {
    const results = await Api.Groups.getGroupMembers(groupId);
    dispatch(groupsSetFilterMembers({ id: groupId, members: results }));
  }
  dispatch(groupsRequestComplete());
};

// Selectors

export const selectLoading = (state: RootState): boolean => state.Groups.loading;

const selectAllGroupsIds = (state: RootState) => state.Groups.allIds;
export const selectAllGroupsById = (state: RootState):{[key:number]:IGroup} => state.Groups.byIds;
const selectAllGroupObjects = createSelector(
  selectAllGroupsIds,
  selectAllGroupsById,
  (allIds, byIds) => allIds.map((id) => byIds[id]),
);

export const selectGroups = (shouldGetAll: boolean, searchValue?: string, filterGroupType?: number[]) => (
  state: RootState,
): IGroup[] => {
  let groups = selectAllGroupObjects(state);
  if (shouldGetAll) {
    groups = groups.filter((group) => !DEFAULT_GROUPS.includes(group.Name));
  }
  if (searchValue) {
    const query = searchValue.toLowerCase();
    groups = groups.filter((group) => group.Name.toLowerCase().includes(query));
  }
  if (filterGroupType) { groups = groups.filter((group) => filterGroupType.indexOf(group.GroupType) > -1); }

  return groups;
};

export const selectGroupsDelegate = (
  shouldGetAll: boolean,
  filterCallback: (groupName: string, query: string) => boolean,
  searchValue?: string,
  filterGroupType?: number[],
) => (
  state: RootState,
): IGroup[] => {
  let groups = selectAllGroupObjects(state);
  if (shouldGetAll) {
    groups = groups.filter((group) => !DEFAULT_GROUPS.includes(group.Name));
  }
  if (searchValue) {
    const query = searchValue.toLowerCase();
    groups = groups.filter((group) => filterCallback(group.Name, query));
  }
  if (filterGroupType) { groups = groups.filter((group) => filterGroupType.indexOf(group.GroupType) > -1); }

  return groups;
};

export const selectNonDefaultGroupsById = (ids: number[]) => (
  state: RootState,
): IGroup[] => {
  const customGroups = selectGroups(false)(state);

  return customGroups.filter((group) => ids.includes(group.Id));
};

export const getGroupByIds = (ids: number[]) => (
  state: RootState,
): IGroup[] => ids.map((id) => state.Groups.byIds[id]).filter(Boolean);

export const getGroupProfiles = (ids: number[]) => (state: RootState):IProfile[] => {
  const groupsById = state.Groups.byIds;
  const profileById = state.Profiles.byIds;
  const profileIds = ids.map((id) => groupsById[id]?.CustomerProfileIds).flat();
  return [...new Set(profileIds)]
    .map((profileId) => profileById[profileId])
    .filter(Boolean);
};

export const getGroupProfileCountByIds = (groupIds: number[]) => (state: RootState): number => {
  const groupsById = state.Groups.byIds;
  if (groupIds) {
    const profileCount = groupIds.map((gid) => groupsById[gid]?.ProfileCount)
    return profileCount.reduce((total, count) => total + count, 0)
  }
  return 0;
};
