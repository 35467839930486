import * as React from 'react';
import { Modal } from 'react-bootstrap';

export interface ConfirmationOptions {
  catchOnCancel?: boolean
  variant: 'danger' | 'info'
  title: string
  description: string
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean
  onSubmit: () => void
  onClose: () => void
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  catchOnCancel,
}) => (
  <Modal
    show={open}
    className="DeleteActionButtonModal"
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header />
    <Modal.Body>
      <div>
        <h4 style={{ textAlign: 'left' }}>{title}</h4>
        <span>
          {description}
        </span>
      </div>
    </Modal.Body>

    <Modal.Footer>
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={onClose}
      >
        Cancel
      </button>
      <button
        type="button"
        className="ml-2 btn btn-danger"
        onClick={onSubmit}
      >
        Abandon Changes
      </button>
    </Modal.Footer>
  </Modal>

);
