import React from 'react';
import { Modal } from 'react-bootstrap';
import { useQueryParam } from 'use-query-params';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from 'components/Inputs/Checkbox';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';

interface UserTypeCheckedState {
  senior: boolean
  staff: boolean
  family: boolean
}

const getChecked = (queryParamVal: string):UserTypeCheckedState => {
  if (!queryParamVal) {
    return {
      senior: true,
      staff: true,
      family: true,
    }
  }

  const draft = {
    senior: false,
    staff: false,
    family: false,
  }
  const splitted = queryParamVal.split(',').filter(Boolean);

  for (const item of ['senior', 'staff', 'family']) {
    if (splitted.includes(item)) {
      draft[item] = true;
    }
  }

  return draft;
}

export const UserTypeFilter = () => {
  const [userTypeParam = '', setUserTypeParam] = useQueryParam<string, string>('userType');
  const [showModal, setShowModal] = React.useState(false);
  const [checked, setChecked] = React.useState(getChecked(userTypeParam));

  useUpdateEffect(() => {
    if (showModal) {
      setChecked(getChecked(userTypeParam));
    }
  }, [showModal])

  const onSubmit = () => {
    const newParamVal = Object.entries(checked)
      .filter(([key, val]) => !!val)
      .map(([key, val]) => key)
      .join(',');

    setUserTypeParam(newParamVal);
    setShowModal(false);
  }
  const translateUserType = useUserTypeTranslation();

  return (
    <>
      <button
        type="button"
        id="profile-filter"
        className="filter-button ml-2"
        onClick={() => { setShowModal(true) }}
      >
        <span className="Calendar__filter-icon">
          <FontAwesomeIcon icon="filter" />
        </span>
      </button>

      {showModal && (
        <Modal
          show
          centered
          onHide={() => {
            setShowModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title as="p">
              User types:
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Checkbox
                name="senior"
                label={translateUserType('member')}
                checked={checked.senior}
                disabled={!checked.family && !checked.staff}
                onChange={(e) => {
                  setChecked({
                    ...checked,
                    [e.target.name]: e.target.checked,
                  })
                }}
                className="mb-1 mt-2"
              />
              <Checkbox
                name="staff"
                label={translateUserType('staff')}
                checked={checked.staff}
                disabled={!checked.family && !checked.senior}
                onChange={(e) => {
                  setChecked({
                    ...checked,
                    [e.target.name]: e.target.checked,
                  })
                }}
                className="mb-1"
              />
              <Checkbox
                name="family"
                label={translateUserType('family')}
                checked={checked.family}
                disabled={!checked.senior && !checked.staff}
                onChange={(e) => {
                  setChecked({
                    ...checked,
                    [e.target.name]: e.target.checked,
                  })
                }}
                className="mb-3"
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="ml-2 btn btn-primary w-100"
              onClick={onSubmit}
            >
              Apply filter
            </button>
          </Modal.Footer>
        </Modal>
      )}

    </>
  )
}
