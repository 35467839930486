import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SurveyQuestionListIndexPage from 'pages/SurveyQuestionList/Index';
import SurveyQuestionListViewPage from 'pages/SurveyQuestionList/View';

function SurveyQuestionListRoutes() {
  return (
    <Switch>
      <Route path="/surveyQuestionLists/:id" component={SurveyQuestionListViewPage} />
      <Route path="/surveyQuestionLists" component={SurveyQuestionListIndexPage} />
    </Switch>
  );
}

export default SurveyQuestionListRoutes;
