import React, {
  useState, ReactElement, useMemo,
} from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Col, Form } from 'react-bootstrap';

import _sum from 'lodash/sum';

import { Dropzone, IRejection } from 'components/Dropzone';
import { Input } from 'components/FormControls';
import { IEmailContent, ILibraryFile } from 'types/ILibraryContent';
import { getFileBlobFromB64Data } from 'utils/file';
import { getBase64 } from 'utils/stringUtils';
import { MarketingEmailEditor } from 'components/MarketingEmail/MarketingEmailEditor';
import { selectIsOotbAccount } from 'reducers/UserInfo';
import { useSelector } from 'react-redux';
import { EmailEditor } from './EmailEditor';
import defaultJson from '../MarketingEmail/MarketingEmailEditorDefaultJson.json';

const initialEmailContentData = {
  Subject: '',
  Display: '',
  From: '',
  ReplyTo: '',
  Content: '',
  Attachments: [],
  UnlayerEmailContentJson: JSON.stringify(defaultJson),
};

type EmailContentProps = {
  emailContent?: IEmailContent
  isMarketing?: boolean
  requireValidation?: boolean
  isUrlLinkSurvey?: boolean
}

const maxUploadFilesSize = 19.5 * 1024 * 1024;
const fileNameRegex = /^[a-zA-Z0-9._%+-= ]+$/;

export const EmailContent = ({
  isMarketing, requireValidation, isUrlLinkSurvey,
}: EmailContentProps):ReactElement => {
  const {
    setValue, control, errors, watch,
  } = useFormContext();
  const data = watch('EmailContent', initialEmailContentData);

  const files = React.useMemo(
    () => data?.Attachments ?? [],
    [data],
  );
  const [rejectFiles, setRejectFiles] = useState<IRejection[]>([]);
  const isOotbAccount = useSelector(selectIsOotbAccount);

  const fileList = useMemo(() => {
    if (files?.length > 0) {
      const fileObjects = files.map((myfile) => {
        const fileblob = getFileBlobFromB64Data(myfile?.Data);
        return Object.assign(fileblob, { name: myfile.FileName, lastModified: Date.now() });
      });
      return fileObjects;
    }
    return [];
  }, [files]);

  const totalFilesSize = useMemo(() => {
    if (fileList?.length > 0) {
      const fileSizes = fileList.map((myfile) => myfile.size);
      return _sum(fileSizes);
    }
    return 0;
  }, [fileList]);

  return (
    <>
      <Form.Row>
        <Form.Group as={Col}>
          <Input
            id="EmailContent.Display"
            name="EmailContent.Display"
            label="Sender's Display Name"
            type="text"
            control={control}
            errors={errors?.EmailContent?.Display && "Sender's Display Name is required"}
            rules={{ required: (!isOotbAccount || requireValidation) }}
            maxLength={255}
            className="emailInput"
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Input
            id="EmailContent.From"
            name="EmailContent.From"
            label="Sender's Email Address"
            maxLength={64}
            type="email"
            control={control}
            errors={errors?.EmailContent?.From?.message}
            rules={(!isOotbAccount || requireValidation)
              ? {
                required: "Sender's Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                  message: "Sender's Email is invalid.",
                },
              }
              : {}}
            className="emailInput"
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Input
            id="EmailContent.ReplyTo"
            name="EmailContent.ReplyTo"
            label="Reply-To Email Address"
            type="email"
            control={control}
            errors={errors?.EmailContent?.ReplyTo?.message}
            maxLength={64}
            rules={(!isOotbAccount || requireValidation)
              ? {
                required: 'Reply-To Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                  message: 'Reply-To Email is invalid.',
                },
              }
              : {}}
            className="emailInput"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Input
            highlights={['<Replace Text Here>']}
            id="EmailContent.Subject"
            name="EmailContent.Subject"
            label="Subject"
            type="text"
            control={control}
            maxLength={128}
            errors={errors?.EmailContent?.Subject && 'Subject is required'}
            rules={{
              validate: (val: string) => !!val?.trim(),
            }}
            className="emailInput"
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          {
            isUrlLinkSurvey
              ? <p className="text-danger"> The $$SURVEY_URL$$ field must be present in the message content below </p>
              : null
          }
          <Controller
            control={control}
            name="EmailContent.Content"
            defaultValue=""
            rules={{
              required: 'Content is required',
              validate: (val: any) => {
                if (val === null || val === undefined || val.trim() === '') {
                  return 'Content Required';
                }
                if (isUrlLinkSurvey) {
                  const decodeEmailContentValue = val ? atob(val) : '';
                  if (decodeEmailContentValue && decodeEmailContentValue?.indexOf('$$SURVEY_URL$$') === -1) {
                    return 'Email content require merge field: $$SURVEY_URL$$';
                  }
                }
                return true;
              },
            }}
            render={({ ref, onChange, ...renderProps }) => (isMarketing
              ? (
                <MarketingEmailEditor
                  {...renderProps}
                  onChange={(html, json) => {
                    onChange(html);
                    setValue('EmailContent.UnlayerEmailContentJson', json);
                  }}
                  label="Body"
                  initialValue={data?.UnlayerEmailContentJson}
                  errors={errors?.EmailContent?.Content?.message}
                />
              ) : (
                <EmailEditor
                  {...renderProps}
                  onChange={onChange}
                  mergeFields={isUrlLinkSurvey ? 'survey' : 'default'}
                  highlights={['<Replace Text Here>']}
                  errors={errors?.EmailContent?.Content?.message}
                />
              ))}
          />

          {isMarketing && (
            <Input
              id="UnlayerEmailContentJson"
              control={control}
              type="hidden"
              name="EmailContent.UnlayerEmailContentJson"
            />
          )}

          <div className="text-center mt-5">
            <Controller
              name="EmailContent.Attachments"
              render={({ onChange: onChangeAttachments }) => (
                <Dropzone
                  onFileRemove={(file) => {
                    onChangeAttachments(files.filter((x) => x.FileName !== file.name));
                    setRejectFiles([]);
                  }}
                  files={fileList}
                  rejectFiles={rejectFiles}
                  dragAndDropText="Drag and drop files here to upload attachments"
                  validator={(file) => {
                    if (file.size < 1) {
                      const f = {
                        code: file.name,
                        message: 'Please ensure the file is not empty and reupload.',
                      };
                      setRejectFiles([f]);
                      return f;
                    }
                    if (file.name.length > 250) {
                      const f = {
                        code: file.name,
                        message: 'Please ensure file name is 250 characters or less.',
                      };
                      setRejectFiles([f]);
                      return f;
                    }
                    if (!fileNameRegex.test(file.name)) {
                      const f = {
                        code: file.name,
                        message: 'Please ensure file name only include simple characters.',
                      };
                      setRejectFiles([f]);
                      return f;
                    }
                    if (totalFilesSize + file.size > maxUploadFilesSize) {
                      const f = {
                        code: file.name,
                        message: 'The email attachments total size can not be greater than 20MB.',
                      };
                      setRejectFiles([f]);
                      return f;
                    }
                    setRejectFiles([]);
                    return null;
                  }}
                  onDropAccepted={async (acceptedFiles) => {
                    const mappedNewFilesPromises = [];
                    const filteredFiles = acceptedFiles.filter(
                      (acceptedFile) => !files.some((file) => file.FileName === acceptedFile.name),
                    );
                    for (const acceptedFile of filteredFiles) {
                      mappedNewFilesPromises.push(
                        new Promise<ILibraryFile>((resolve, reject) => {
                          getBase64(acceptedFile)
                            .then((fileData) => resolve({
                              FileName: acceptedFile.name,
                              DocumentId: 0,
                              Data: fileData,
                              DownloadUrl: '',
                            }))
                            .catch((e) => reject(e));
                        }),
                      );
                    }
                    const mappedNewFiles = await Promise.all(
                      mappedNewFilesPromises,
                    );

                    const newFilesArray = [...files, ...mappedNewFiles];
                    onChangeAttachments(newFilesArray);
                  }}
                />
              )}
            />
          </div>
        </Form.Group>
      </Form.Row>
    </>
  );
};
