import React, { Dispatch, SetStateAction, ReactElement } from 'react';

import NavItem, { INavItem } from './NavItem';

interface IProps {
  navItems: INavItem[]
  openMenuHandler: Dispatch<SetStateAction<boolean>>
}

function VerticalNav({ navItems, openMenuHandler }: IProps): ReactElement {
  return (
    <nav className="VerticalNav">
      <ul>
        {navItems.map((navItem) => (
          <NavItem key={navItem.label} item={navItem} openMenuHandler={openMenuHandler} />
        ))}
      </ul>
    </nav>
  );
}

export default VerticalNav;
