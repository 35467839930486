import { clamp } from 'lodash';
import { IUserInfoState } from 'reducers/IUserInfoState'

export const isTokenExpired = (jwt: IUserInfoState['jwt']) => Date.now() / 1000 > jwt.exp;

export const isTokenHalfwayExpired = (jwt: IUserInfoState['jwt']) => {
  const halfwayTime = 0.5 * (jwt.nbf + jwt.exp);
  return Date.now() / 1000 > halfwayTime;
}

export const tokenMilliRemaining = (jwt: IUserInfoState['jwt']) => {
  const now = Date.now();
  const tokenExpiryTime = jwt.exp * 1000;
  return tokenExpiryTime - now;
}

export const tokenIdleTimeout = (jwt: IUserInfoState['jwt']) => {
  const fifteenMinutes = 1000 * 60 * 15;
  const timeUntilExpiry = tokenMilliRemaining(jwt);
  // make sure idle timeout always leaves enough time for the countdown to complete
  // before the token has expired.
  return clamp(timeUntilExpiry - 90 * 1000, 0, fifteenMinutes);
}
