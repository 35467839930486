import React, {
  useState, useEffect, FC, useMemo,
} from 'react';
import { Col, Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getGroupsIndex, selectGroups } from 'reducers/Groups';
import { SearchInput } from 'components/Inputs/SearchInput';
import Select, { IOptions } from 'components/Inputs/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserType } from 'reducers/IProfile';
import { useUserTypeTranslation } from '../hooks/useUserTypeTranslation';

type Props = {
  onSubmit: (query: string, group: string, userType: UserType | '') => void
  showGroupFilter?: boolean
  showUserTypeFilter?: boolean
  userTypeEnabledOptions?: UserType[]
  searchVal: string|undefined
  userTypeVal?: UserType|''
  groupVal?: string
};

export const ProfileSearchFilter: FC<Props> = ({
  onSubmit,
  showUserTypeFilter = false,
  showGroupFilter = true,
  userTypeEnabledOptions,
  searchVal,
  userTypeVal = '',
  groupVal = '',
}) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState(searchVal);
  const [filterGroup, setFilterGroup] = useState(groupVal);
  const [userTypeFilter, setUserTypeFilter] = useState<UserType | ''>(userTypeVal);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const groups = useSelector(selectGroups(false));

  const translateUserType = useUserTypeTranslation();
  const groupOptions = groups.map((group) => ({
    value: group.Name,
    label: translateUserType(group.Name),
  }));

  const defaultUserTypeOptions = useMemo<IOptions[]>(() => {
    const defaultOptions: IOptions[] = [];
    Object.values(UserType).map((userType) => defaultOptions.push(
      { label: translateUserType(userType), value: userType }));
    return defaultOptions;
  }, [translateUserType]);

  const userTypeOptions = useMemo<IOptions[]>(() => {
    if (userTypeEnabledOptions) {
      return userTypeEnabledOptions.map((userType): IOptions => (
        { label: translateUserType(userType), value: userType }
      ));
    }
    return defaultUserTypeOptions;
  }, [defaultUserTypeOptions, userTypeEnabledOptions, translateUserType]);

  useEffect(() => {
    dispatch(getGroupsIndex());
  }, [dispatch]);

  React.useEffect(() => {
    if (isProfileModalOpen) {
      setSearchQuery(searchVal);
      setFilterGroup(groupVal);
      setUserTypeFilter(userTypeVal);
    }
  }, [isProfileModalOpen, searchVal, userTypeVal, groupVal]);

  return (
    <>
      <span>
        {(!!searchVal || !!groupVal || !!userTypeVal) && (
          <button
            type="button"
            className="filter-button ml-2"
            title="Remove filters?"
            onClick={() => {
              setSearchQuery(undefined);
              setFilterGroup(undefined);
              setUserTypeFilter('');
              onSubmit(undefined, undefined, '');
            }}
          >
            <span className="">
              <FontAwesomeIcon icon="times" />

            </span>
          </button>
        )}
        <button
          type="button"
          id="profile-filter"
          className="filter-button  ml-2"
          onClick={() => { setProfileModalOpen(true) }}
        >
          <span className="Calendar__filter-icon">
            <FontAwesomeIcon icon="filter" />
          </span>
        </button>
      </span>
      <Modal
        show={isProfileModalOpen}
        onHide={() => {
          setProfileModalOpen(false);
        }}
        dialogClassName="modal-20w"
        centered
      >
        {showGroupFilter && (
          <div className="form-row">
            <Col>
              <Select
                id="filterGroup"
                name="filterGroup"
                className="filter-group"
                label="Group"
                value={filterGroup}
                onChange={(evt) => {
                  setFilterGroup(evt);
                }}
                options={[{ value: '', label: 'All groups' }, ...groupOptions]}
              />
            </Col>
          </div>
        )}

        {showUserTypeFilter && (
          <div className="form-row">
            <Col>
              <Select
                id="filterUserType"
                name="filterUserType"
                label="User Type"
                value={userTypeFilter}
                onChange={(val) => {
                  setUserTypeFilter(val);
                }}
                placeholder="All Types"
                options={userTypeOptions}
              />
            </Col>
          </div>
        )}

        <div className="form-row">
          <Col>
            <SearchInput
              label="Search by full name"
              placeholder="I am looking for..."
              name="searchValue"
              value={searchQuery}
              onChange={(evt) => {
                setSearchQuery(evt.target.value);
              }}
              onKeyDown={(evt) => {
                if (evt.key !== 'Enter') {
                  return;
                }
                evt.preventDefault();
                setProfileModalOpen(false);
                onSubmit(searchQuery, filterGroup, userTypeFilter);
              }}
              className="w-100"
            />
          </Col>
        </div>
        <Button
          type="submit"
          onClick={() => {
            setProfileModalOpen(false);
            onSubmit(searchQuery, filterGroup, userTypeFilter);
          }}
        >
          Apply filter
        </Button>
      </Modal>
    </>
  );
};
