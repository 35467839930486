import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import _remove from 'lodash/remove';
import _union from 'lodash/union';
import _chunk from 'lodash/chunk';
import _flatten from 'lodash/flatten';
import _times from 'lodash/times';
import log from 'lib/logging';
import { serialize } from 'utils/serializeParams';
import { mutate } from 'swr';
import { DEFAULT_GROUPS } from './IGroupsState';
import { RootState } from '../types/rootState';
import { IProfilesPayload } from '../types/IProfilesPayload';
import { IProfilesIndexParams } from '../types/IProfilesIndexParams';
import { IProfilesState } from './IProfilesState';
import { AppThunk } from '../app/appThunk';
import { IProfile } from './IProfile';
import { hasError } from './Error';
import { updateProfileColumnsSetting } from './UserInfo';

export const INITIAL_STATE: IProfilesState = {
  byIds: {},
  selectedProfiles: [],
  allIds: [],
  pagedIds: {},
  pagination: {
    Page: 1,
    PerPage: 20,
    TotalItems: 0,
    TotalPages: 1,
  },
  loading: false,
  exportProfiles: [],
  exporting: false,
  addingFamily: {
    customerProfileID: '',
    families: [],
  },
  profilesFamlies: [],
  customFields: {},
};

export const PROFILE_TYPES = [
  { label: 'Senior', value: 'Member' },
  { label: 'Staff', value: 'Staff' },
  { label: 'Family', value: 'Family' },
];
export const PROFILE_FAMILY_TYPES = [
  { label: 'Staff', value: 'Staff' },
  { label: 'Family', value: 'Family' },
];
export const SYNCED_PROFILE_FAMILY_TYPES = [
  { label: 'Family', value: 'Family' },
];
const profileSchema = new schema.Entity(
  'Profiles',
  {},
  { idAttribute: 'CustomerProfileID' },
);

const profilesIndexDefaultParams = {
  page: 1,
}

const ProfilesSlice = createSlice({
  name: 'Profiles',
  initialState: INITIAL_STATE,
  reducers: {
    addProfile(draftReducerState, action) {
      draftReducerState.byIds[action.payload.Id] = action.payload;
      draftReducerState.allIds.push(action.payload.Id);
    },
    profilesRequestStarted(draftReducerState) {
      draftReducerState.loading = true;
    },
    profilesRequestFailed(draftReducerState) {
      draftReducerState.loading = false;
    },
    profilesRequestSuccess(draftReducerState) {
      draftReducerState.loading = false;
    },
    profilesByIdsPayloadSuccess(draftReducerState, action: PayloadAction<IProfile[]>) {
      draftReducerState.selectedProfiles = action.payload;
      action.payload.forEach((profile) => {
        draftReducerState.byIds[profile.CustomerProfileID] = profile;
      });
      draftReducerState.loading = false;
    },
    profilesIndexSuccess(
      draftReducerState, action: PayloadAction<{ data: IProfilesPayload, params: IProfilesIndexParams }>,
    ) {
      // log.info('action payload', action);
      const normalizedPayload = normalize(action.payload.data, {
        Profiles: [profileSchema],
      });
      // log.info('normalizedPayload result:', normalizedPayload);
      const profileIds = normalizedPayload.result.Profiles;
      // log.info('profileid', profileIds);
      const {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      } = action.payload.data.Pagination;

      draftReducerState.byIds = {
        ...draftReducerState.byIds,
        ...normalizedPayload.entities.Profiles,
      };
      draftReducerState.pagedIds = {
        ...draftReducerState.pagedIds,
        [serialize(action.payload.params, profilesIndexDefaultParams)]: profileIds,
      };
      draftReducerState.pagination = {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      };
      draftReducerState.allIds = _union(draftReducerState.allIds, profileIds);
      draftReducerState.loading = false;
    },
    profilesPayloadSuccess(draftReducerState, action: PayloadAction<IProfile|IProfile[]>) {
      const profiles = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      profiles.forEach((profile) => {
        draftReducerState.byIds[profile.CustomerProfileID] = profile;
      });

      draftReducerState.allIds = _union(
        draftReducerState.allIds,
        profiles.map((p) => p.CustomerProfileID),
      );
      draftReducerState.loading = false;
    },

    profileCustomFieldsSuccess(
      draftReducerState,
      action: PayloadAction<{
        CustomerProfileID: string
        customFields: { [key: string]: string }
      }>,
    ) {
      const prof = draftReducerState.byIds[action.payload.CustomerProfileID];
      draftReducerState.byIds[action.payload.CustomerProfileID] = {
        ...prof,
        CustomFields: action.payload.customFields,
      }

      draftReducerState.loading = false;
    },

    profileGroupsSuccess(
      draftReducerState,
      action: PayloadAction<{
        CustomerProfileID: string
        groupIDs: number[]
      }>,
    ) {
      draftReducerState.byIds[action.payload.CustomerProfileID].Groups = action.payload.groupIDs;
      draftReducerState.loading = false;
    },

    profileRelationshipsSuccess(
      draftReducerState,
      action: PayloadAction<{
        CustomerProfileID: string
        relatedIds: Array<string>
      }>,
    ) {
      if (draftReducerState.byIds[action.payload.CustomerProfileID].RelatedProfiles) {
        const mergedRelationships = draftReducerState.byIds[action.payload.CustomerProfileID]
          .RelatedProfiles.concat(action.payload.relatedIds)
        draftReducerState.byIds[action.payload.CustomerProfileID]
          .RelatedProfiles = mergedRelationships;
      } else {
        draftReducerState.byIds[action.payload.CustomerProfileID]
          .RelatedProfiles = action.payload.relatedIds
      }
    },
    profileRelationshipsDeletedSuccess(
      draftReducerState,
      action: PayloadAction<{
        CustomerProfileID: string
        relatedIds: Array<string>
      }>,
    ) {
      draftReducerState.byIds[action.payload.CustomerProfileID]
        .RelatedProfiles = action.payload.relatedIds;
    },
    profilesDeleteSuccess(draftReducerState, action: PayloadAction<string>) {
      const CustomerProfileID = action.payload;

      delete draftReducerState.byIds[CustomerProfileID];
      draftReducerState.allIds = _remove(
        draftReducerState.allIds,
        (id: string) => id === CustomerProfileID,
      );
    },
    profilesExportStarted(draftReducerState) {
      draftReducerState.exporting = true;
    },
    profilesExportFailed(draftReducerState) {
      draftReducerState.exporting = false;
    },
    profilesExportSuccess(draftReducerState, action: PayloadAction<IProfilesPayload>) {
      const normalizedPayload = normalize(action.payload, {
        Profiles: [profileSchema],
      });
      const profiles: IProfile[] = [];
      const profileIds = normalizedPayload.result.Profiles;
      profileIds.forEach((id) => {
        const profileObj = normalizedPayload.entities.Profiles[id];
        if (profileObj.UserType.toLowerCase() === 'member') {
          profileObj.UserType = 'Senior';
        }
        if (profileObj) {
          const customFields = profileObj.CustomFields;
          profileObj.CustomFields = undefined;
          if (customFields) {
            // convert JSON custom fields object into properties.
            for (const key of Object.keys(customFields)) {
              profileObj[key] = customFields[key];
            }
          }
          // remove original custom fields
          delete profileObj.CustomFields;
        }
        profiles.push(profileObj);
      });
      draftReducerState.exportProfiles = profiles;
      draftReducerState.exporting = false;
    },
    profilesAddFamilyStarted(draftReducerState, customerProfileID) {
      draftReducerState.addingFamily = { customerProfileID: customerProfileID.payload, families: [] };
    },
    profilesAddFamilyBack(draftReducerState, familyCustomerProfile) {
      if (draftReducerState.addingFamily.customerProfileID) {
        draftReducerState.addingFamily.families.push(familyCustomerProfile.payload);
      }
    },
    profilesAddFamilySuccess(draftReducerState) {
      draftReducerState.addingFamily = { customerProfileID: '', families: [] };
    },
    profilesFamliesByIdsPayloadSuccess(draftReducerState, action: PayloadAction<IProfile[]>) {
      draftReducerState.addingFamily.families = action.payload;
      draftReducerState.loading = false;
    },
    cleanProfilesAddFamily(draftReducerState, customerProfileID) {
      draftReducerState.addingFamily = { customerProfileID: customerProfileID.payload, families: [] };
    },
    removeProfilesAddFamily(draftReducerState, customerProfileID) {
      draftReducerState.addingFamily.families = _remove(
        draftReducerState.addingFamily.families,
        (f: IProfile) => f.CustomerProfileID === customerProfileID.payload,
      );
    },
  },
});

export const {
  addProfile,
  profilesRequestStarted,
  profilesRequestFailed,
  profilesRequestSuccess,
  profilesIndexSuccess,
  profilesPayloadSuccess,
  profileCustomFieldsSuccess,
  profileGroupsSuccess,
  profilesDeleteSuccess,
  profilesByIdsPayloadSuccess,
  profileRelationshipsSuccess,
  profileRelationshipsDeletedSuccess,
  profilesExportSuccess,
  profilesExportStarted,
  profilesExportFailed,
  profilesAddFamilyStarted,
  profilesAddFamilyBack,
  profilesAddFamilySuccess,
  profilesFamliesByIdsPayloadSuccess,
  removeProfilesAddFamily,
  cleanProfilesAddFamily,

} = ProfilesSlice.actions;

export default ProfilesSlice.reducer;

// Thunks

export const getProfilesIndex = (
  params: IProfilesIndexParams,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(profilesRequestStarted());
  try {
    const profilesIndexResponse = await Api.Profiles.profilesIndex(params);
    dispatch(profilesIndexSuccess({ data: profilesIndexResponse, params }));
  } catch (err) {
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
  }
};

export const getAllProfiles = (): AppThunk => async (dispatch, getState, Api) => {
  dispatch(profilesRequestStarted());
  try {
    const params = { perpage: 1000 };
    const indexResponse = await Api.Profiles.profilesIndex(params);
    let allProfiles = [...indexResponse.Profiles];

    if (indexResponse.Pagination.TotalPages > 1) {
      const restIndexResponses = await Promise.all(
        _times(indexResponse.Pagination.TotalPages - 1)
          .map((idx) => Api.Profiles.profilesIndex({ ...params, page: idx + 2 })),
      );

      allProfiles = [
        ...allProfiles,
        ...restIndexResponses
          .map((res) => res.Profiles)
          .flat(),
      ]
    }
    dispatch(profilesPayloadSuccess(allProfiles))
  } catch (err) {
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
  }
};

export const getProfilesByCpids = (cpids: string[]): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(profilesRequestStarted());
  try {
    const profilesByIdsResponses = await Promise.all(
      _chunk<string>(cpids, 1000)
        .map((profileIdsChunk) => Api.Profiles.profilesGetByIds(profileIdsChunk)),
    );
    const profilesByIds = _flatten(profilesByIdsResponses).filter(Boolean);
    dispatch(profilesByIdsPayloadSuccess(profilesByIds));
  } catch (err) {
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
  }
};

export const getProfile = (CustomerProfileID: string): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(profilesRequestStarted());
  try {
    const profilesGetResponse = await Api.Profiles.profilesGet(CustomerProfileID);
    dispatch(profilesPayloadSuccess(profilesGetResponse));

    dispatch(getProfileCustomFields(CustomerProfileID));
    dispatch(getProfileFamiliesByCpids(profilesGetResponse.RelatedProfiles))
  } catch (err) {
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
  }
};

export const getProfileCustomFields = (CustomerProfileID: string): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(profilesRequestStarted());
  try {
    const profilesGetResponse = await Api.Profiles.profileCustomFieldsGet(CustomerProfileID);
    dispatch(
      profileCustomFieldsSuccess({
        CustomerProfileID,
        customFields: profilesGetResponse,
      }),
    );
  } catch (err) {
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
  }
};

export const setProfileCustomFields = (
  CustomerProfileID: string, customFields: { [key: string]: string },
): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(profilesRequestStarted());
  try {
    await Api.Profiles.profileCustomFieldsSet(CustomerProfileID, customFields);
    dispatch(profilesRequestSuccess());
  } catch (err) {
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
  }
};

export const createProfile = (
  profileData: Partial<IProfile>,
  successCallback: (CustomerProfileID: string) => void,
  failedCallback?: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(profilesRequestStarted());
  try {
    const { CustomerProfileID } = await Api.Profiles.profilesCreate(profileData);
    const createdProfile = await Api.Profiles.profilesGet(CustomerProfileID);
    // refetch distribution list
    await mutate(
      (key) => typeof key === 'string' && key.startsWith('/api/v2/Profiles?'),
      undefined,
      {
        revalidate: true,
        populateCache: true,
      },
    );
    dispatch(profilesAddFamilyBack(createdProfile));
    dispatch(profilesPayloadSuccess(createdProfile));
    successCallback(createdProfile.CustomerProfileID);
  } catch (err) {
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
    if (failedCallback) failedCallback();
  }
};

export const updateProfile = (
  profileData: Partial<IProfile> & { CustomerProfileID: string },
  successCallback: () => void,
  failedCallback?: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(profilesRequestStarted());
  try {
    const currentProfile = getState().Profiles.byIds[
      profileData.CustomerProfileID
    ];
    const updatedProfile = { ...currentProfile, ...profileData };
    await Api.Profiles.profilesUpdate(updatedProfile);
    await mutate(
      (key) => typeof key === 'string' && key.startsWith('/api/v2/Profiles?'),
      undefined,
      {
        revalidate: true,
        populateCache: true,
      },
    );
    dispatch(profilesPayloadSuccess(updatedProfile));
    successCallback();
  } catch (err) {
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
    if (failedCallback) { failedCallback(); }
  }
};

export const deleteProfile = (
  CustomerProfileID: string,
  successCallback: () => void,
  failureCallBack: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(profilesRequestStarted());
  try {
    await Api.Profiles.profilesDelete(CustomerProfileID);
    await mutate(
      (key) => typeof key === 'string' && key.startsWith('/api/v2/Profiles?'),
      (existingData) => {
        if (!existingData) {
          return existingData;
        }
        return {
          ...existingData,
          Profiles: existingData.Profiles.filter((item) => item.CustomerProfileID !== CustomerProfileID),
        };
      },
      {
        revalidate: false,
        populateCache: true,
      },
    );
    successCallback();
    dispatch(profilesDeleteSuccess(CustomerProfileID));
  } catch (err) {
    log.info(err);
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
    failureCallBack();
  }
};

export const addProfileGroups = (
  CustomerProfileID: string,
  groupIDs: Array<number>,
  successCallback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(profilesRequestStarted());
  try {
    await Api.Profiles.profileAddGroups(CustomerProfileID, groupIDs);
    const Groups = getState().Profiles.byIds[CustomerProfileID]?.Groups || [];
    dispatch(
      profileGroupsSuccess({
        CustomerProfileID,
        groupIDs: [...Groups, ...groupIDs],
      }),
    );
    successCallback();
  } catch (err) {
    log.info(err);
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
  }
};

export const setProfileGroups = (
  CustomerProfileID: string,
  groupIDs: number[],
  successCallback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  const groups = getState().Groups.allIds.map(
    (groupId) => getState().Groups.byIds[groupId],
  );
  const defaultGroupIds = groups
    .filter((group) => DEFAULT_GROUPS.includes(group.Name))
    .map((group) => group.Id);
  const filteredGroupIDs = groupIDs.filter((id) => !defaultGroupIds.includes(id));

  dispatch(profilesRequestStarted());
  try {
    await Api.Profiles.profileSetGroups(CustomerProfileID, filteredGroupIDs);
    dispatch(profileGroupsSuccess({ CustomerProfileID, groupIDs: filteredGroupIDs }));
    successCallback();
  } catch (err) {
    log.info(err);
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
  }
};

export const putProfileRelationships = (
  CustomerProfileID: string,
  relatedIds: Array<string>,
  isDelete?: boolean,
): AppThunk => async (dispatch, getState, Api) => {
  try {
    await Api.Profiles.profilePutRelationships(CustomerProfileID, relatedIds);
    if (isDelete) {
      dispatch(profileRelationshipsDeletedSuccess({ CustomerProfileID, relatedIds }));
    } else {
      dispatch(profileRelationshipsSuccess({ CustomerProfileID, relatedIds }));
    }
  } catch (err) {
    log.info(err);
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
  }
};

export const postProfileRelationships = (
  CustomerProfileID: string,
  relatedIds: Array<string>,
): AppThunk => async (dispatch, getState, Api) => {
  try {
    await Api.Profiles.profilePostRelationships(CustomerProfileID, relatedIds);
    dispatch(profileRelationshipsSuccess({ CustomerProfileID, relatedIds }));
  } catch (err) {
    log.info(err);
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
  }
};

// Selectors

export const selectLoading = (state: RootState): boolean => state.Profiles.loading;

export const selectPaginationData = (
  state: RootState,
): IProfilesState['pagination'] => state.Profiles.pagination;

export const selectProfilesPage = (params: IProfilesIndexParams) => (
  state: RootState,
): IProfile[] => {
  const hash = serialize(params, profilesIndexDefaultParams);

  const profileIds = state.Profiles.pagedIds[hash] || [];
  const profiles: IProfile[] = [];

  profileIds.forEach((id) => {
    const profile = state.Profiles.byIds[id];
    if (profile) {
      profiles.push(profile);
    }
  });

  return profiles;
};

export const getProfilesByIds = (ids: string[]) => (
  state: RootState,
): IProfile[] => ids.map((id) => state.Profiles.byIds[id]).filter((profile) => profile);

export const exportLoading = (state: RootState): boolean => state.Profiles.exporting;
export const getExportProfiles = (
  params: IProfilesIndexParams,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(profilesExportStarted());
  try {
    const params2 = { ...params, page: 1, perpage: 9999 }
    const profilesResponse = await Api.Profiles.profilesIndex(params2);
    dispatch(profilesExportSuccess(profilesResponse));
  } catch (err) {
    dispatch(profilesExportFailed());
  }
};
export const getProfileFamiliesByCpids = (cpids: string[]): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(profilesRequestStarted());
  try {
    const profilesByIdsResponse = await Api.Profiles.profilesGetByIds(cpids);
    dispatch(profilesFamliesByIdsPayloadSuccess(profilesByIdsResponse));
  } catch (err) {
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
  }
};
export const saveProfileLayoutAsDefault = (
  accountId: number,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(profilesRequestStarted());
  try {
    const existingDataJson = localStorage.getItem(`acc-${accountId}`);
    await Api.UserInfo.saveAccountSetting('defaultprofiletablelayout', existingDataJson);
    // save the current setting into userInfo.ProfileColumnsSetting
    dispatch(updateProfileColumnsSetting(existingDataJson));
    dispatch(profilesRequestSuccess());
  } catch (err) {
    dispatch(profilesRequestFailed());
    dispatch(hasError(err));
  }
};
