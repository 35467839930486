import React from 'react';
import { Form } from 'react-bootstrap';
import clsx from 'clsx';

import { ContentEditable, ContentEditableTextareaProps } from './ContentEditable';

interface TextareaSharedProps {
  label?: string
  errors?: string
  icon?: React.FC<React.SVGAttributes<SVGElement>>
}

type TextareaContentEditableProps = ContentEditableTextareaProps & TextareaSharedProps;

type TextareaTextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & TextareaSharedProps & {
  value?: string|null
  onChange?: (...event: any[]) => void
}

export type ITextareaProps = TextareaTextareaProps & TextareaContentEditableProps;

const Textarea = React.forwardRef<HTMLTextAreaElement|HTMLDivElement, ITextareaProps>(
  (
    {
      id,
      name,
      label,
      value = '',
      required,
      errors,
      className = '',
      rows = 3,
      highlights,
      style = {},
      ...props
    },
    ref,
  ) => {
    const textareaProps = {
      id,
      name,
      value,
      required,
      rows,
      className: clsx('form-control', errors && 'invalid'),
      ...props,
    }

    return (
      <div
        className={clsx('Textarea', 'form-group', className)}
        style={style}
      >
        {label && (
          <label htmlFor={id}>
            {label}
          </label>
        )}
        <div style={{ position: 'relative' }}>
          {required ? <span className="required-asterisk">*</span> : null}
          {highlights ? (
            <ContentEditable
              highlights={highlights}
              {...textareaProps}
              ref={ref as React.Ref<HTMLDivElement>}
            />
          ) : (
            <Form.Text
              as="textarea"
              {...textareaProps}
              ref={ref as React.Ref<HTMLTextAreaElement>}
            />
          )}
        </div>
        {errors ? <span className="errors">{errors}</span> : ''}
      </div>
    );
  },
);

export default Textarea;
