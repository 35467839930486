import React from 'react';
import {
  Modal, Row, Col, Form, Button,
} from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import useMountedState from 'react-use/lib/useMountedState';

import * as SmartContentAPI from 'api/SmartContentAPI';
import { Input } from 'components/FormControls';
import { string2bin } from 'utils/stringUtils';
import { hasError } from 'reducers/Error';

const formatToHTML = (str: string) => str
  .split(/\r\n\r\n|\n\n/)
  .map((frag) => `<p>${frag}</p>`)
  .join('')
  .replace(/\r\n|\n/g, '<br>')

interface GenerateContentModalProps {
  channel: 'voice'|'sms'|'email'
  onHide: () => void
}

export const GenerateContentModal: React.FC<GenerateContentModalProps> = ({ channel, onHide }) => {
  const [isFirstStep, setIsFirstStep] = React.useState(true);

  const { watch } = useFormContext();

  const name = watch('Name', '');
  const description = watch('Description', '');

  return (
    <Modal
      show
      onHide={onHide}
      centered
      size="lg"
      className="generate-content-modal"
    >
      <Modal.Header
        closeButton
      >
        <Modal.Title>
          Generate Smart Content
        </Modal.Title>
      </Modal.Header>
      {isFirstStep ? (
        <FirstStep
          name={name}
          description={description}
          setIsFirstStep={setIsFirstStep}
          onHide={onHide}
          channel={channel}
        />
      ) : (
        <SecondStep
          name={name}
          description={description}
          setIsFirstStep={setIsFirstStep}
          onHide={onHide}
          channel={channel}
        />
      )}
    </Modal>
  )
}

interface StepProps {
  name: string
  description: string
  setIsFirstStep: (isFirstStep: boolean) => void
  onHide: () => void
  channel: 'voice'|'sms'|'email'
}

const FirstStep: React.FC<StepProps> = ({
  name, description, setIsFirstStep, onHide, channel,
}) => {
  const {
    errors, control, watch, setValue, getValues,
  } = useFormContext();

  let fieldToWatch: string;

  switch (channel) {
    case 'sms': fieldToWatch = 'SMSContent.Content'; break
    case 'voice': fieldToWatch = 'VoiceContent.Content'; break;
    default: fieldToWatch = 'EmailContent.Content';
  }

  const hasContent = !!watch(fieldToWatch);

  const onGenerate = () => {
    setValue('Name', getValues('generateName'));
    setValue('Description', getValues('generateDescription'));
    setIsFirstStep(false);
  }

  return (
    <>
      <Modal.Body>
        <Row>
          <Col xs="12">
            <Form.Group>
              <Input
                highlights={['<Replace Text Here>']}
                defaultValue={name}
                name="generateName"
                control={control}
                maxLength={50}
                rules={{
                  required: 'The name is required',
                }}
                type="Text"
                id="generate-template-name"
                label="Name"
                required
                errors={errors.generateName?.message}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Form.Group>
              <Input
                highlights={['<Replace Text Here>']}
                type="text"
                id="generate-description"
                defaultValue={description}
                control={control}
                name="generateDescription"
                label="Description"
                errors={errors.generateDescription?.message}
                maxLength={600}
              />
            </Form.Group>
          </Col>
        </Row>
        {hasContent && (
          <p
            className="generate-content-modal__warning mb-2"
          >
            <FontAwesomeIcon
              icon="exclamation-triangle"
              color="var(--orange)"
              className="mr-1"
            />
            This will replace your existing
            {' '}
            {channel}
            {' '}
            content.
          </p>
        )}

        <p className="generate-content-modal__warning">
          <FontAwesomeIcon
            icon="exclamation-circle"
            color="var(--orange)"
            className="mr-1"
          />
          Content generated by a 3rd party. Do not include PHI in name or description.
          Icon isn’t responsible and has limited ability to control the content generated.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          onClick={onHide}
          className="mr-auto"
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          onClick={onGenerate}
          disabled={!!errors.generateName || !!errors.generateDescription}
        >
          Generate
        </Button>
      </Modal.Footer>
    </>
  )
}

interface Decoded {
  StreamId:string
  ErrorMessage:null|string
  IsFinished: boolean
  Delta: string
}

const SecondStep: React.FC<StepProps> = ({
  name, description, setIsFirstStep, onHide, channel,
}) => {
  const [selectedValue, setSelectedValue] = React.useState<number>(0);
  const {
    setValue,
  } = useFormContext();
  const dispatch = useDispatch();
  const [subjects, setSubjects] = React.useState(['']);
  const [contents, setContents] = React.useState(['']);
  const [isGenerating, setIsGenerating] = React.useState(false);
  const stopRef = React.useRef<boolean>(false);
  const isMounted = useMountedState();

  React.useEffect(() => {
    const getSmartContent = async (
      part?: 'content'|'subject',
    ) => {
      if (channel === 'email' && !part) {
        throw new Error('please provide part argument');
      }

      // to prevent memory leak
      if (!isMounted()) {
        return;
      }
      setIsGenerating(true);

      try {
        const stream = await SmartContentAPI.getSmartContent(
          channel === 'email'
            ? `${channel}${part}` as 'emailcontent'|'emailsubject'
            : channel,
          { name, description },
        );
        const reader = stream.getReader();

        const pump = ({ done, value }: { value?: Uint8Array, done: boolean}) => {
          if (stopRef.current) {
            return reader.cancel();
          }
          if (done) {
            return undefined;
          }

          const decoded = new TextDecoder().decode(value);

          const decodedItems = decoded.split('\n').filter(Boolean);

          for (const decodedItem of decodedItems) {
            const decodedObj: Decoded = JSON.parse(decodedItem);

            if (decodedObj.ErrorMessage) {
              throw new Error(decodedObj.ErrorMessage);
            }

            if (decodedObj.Delta === null) {
              return undefined;
            }

            // to prevent memory leak
            if (!isMounted()) {
              return reader.cancel();
            }

            if (part === 'subject') {
              setSubjects((oldSubjects) => {
                const lastSubject = oldSubjects[oldSubjects.length - 1];
                return [...oldSubjects.slice(0, -1), `${lastSubject}${decodedObj.Delta}`];
              });
            } else {
              setContents((oldContents) => {
                const lastContent = oldContents[oldContents.length - 1];
                return [...oldContents.slice(0, -1), `${lastContent}${decodedObj.Delta}`];
              });
            }
          }

          return reader.read().then(pump);
        }

        await reader.read().then(pump);

        if (channel === 'email' && part === 'subject') {
          await getSmartContent('content');
        }
      } catch (err) {
        dispatch(hasError(err));
      }

      // to prevent memory leak
      if (!isMounted()) {
        return;
      }
      // console.log('Token count>', tokenCount);
      setIsGenerating(false);
    }

    getSmartContent(channel === 'email' ? 'subject' : undefined);
  }, [channel, description, name, contents.length, dispatch, isMounted]);

  const onUse = () => {
    const content = contents[selectedValue - 1];
    switch (channel) {
      case 'sms': {
        setValue('SMSContent.Content', content, {
          shouldValidate: true,
        });
        break;
      }
      case 'voice': {
        setValue('VoiceContent.Content', content, {
          shouldValidate: true,
        });
        break;
      }
      default: {
        const subject = subjects[selectedValue - 1];
        setValue('EmailContent.Subject', subject, {
          shouldValidate: true,
        });
        setValue('EmailContent.Content', string2bin(formatToHTML(content)), {
          shouldValidate: true,
        });
        break;
      }
    }

    onHide();
  }

  let buttonJSX;

  if (isGenerating) {
    const generated = channel === 'email'
      ? subjects[subjects.length - 1]
      : contents[contents.length - 1];

    buttonJSX = generated === ''
      ? null
      : (
        <Button
          type="button"
          variant="outline-primary"
          className="mb-3 mx-auto d-block"
          onClick={() => {
            stopRef.current = true;
          }}
        >
          Stop Generating
        </Button>
      );
  } else if (contents.length < 3) {
    buttonJSX = (
      <Button
        type="button"
        variant="outline-primary"
        className="mb-3 mx-auto d-block"
        onClick={() => {
          setSelectedValue(0);
          stopRef.current = false;
          if (channel === 'email') {
            setSubjects([...subjects, '']);
          }
          setContents([...contents, '']);
        }}
      >
        Generate Another
      </Button>
    );
  } else {
    buttonJSX = null;
  }

  return (
    <>
      <Modal.Body>
        <ul className="generate-content-modal__list">
          {contents?.map((content, index) => {
            const isActive = index + 1 === selectedValue;
            const isLast = index === contents.length - 1;

            const isGenerated = isLast && isGenerating;

            if (channel === 'email') {
              const subject = subjects[index];

              const isGeneratingSubject = isGenerated && content === '';
              const isGeneratingContent = isGenerated && content !== '';

              return (
                <li
                  key={content}
                  className="generate-content-modal__list__email-item"
                  onClick={() => {
                    setSelectedValue(index + 1);
                  }}
                >
                  <div
                    className={clsx(
                      'generate-content-modal__list__item mb-2',
                      isActive && 'active',
                      isGeneratingSubject && 'generating',
                    )}
                  >
                    {subject}
                  </div>
                  <div
                    className={clsx(
                      'generate-content-modal__list__item',
                      isActive && 'active',
                      isGeneratingContent && 'generating',
                    )}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </li>
              );
            }
            return (
              <li
                key={content}
                className={clsx(
                  'generate-content-modal__list__item',
                  isActive && 'active',
                  isGenerated && 'generating',
                )}
                onClick={() => {
                  setSelectedValue(index + 1);
                }}
              >
                {content}
              </li>
            )
          })}
        </ul>
        {buttonJSX}

      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          onClick={onHide}
          className="mr-auto"
        >
          Cancel
        </Button>

        <Button
          variant="link"
          onClick={() => {
            setIsFirstStep(true);
          }}
          className="mr-2"
        >
          Back
        </Button>
        <Button
          variant="primary"
          onClick={onUse}
          disabled={!selectedValue || isGenerating}
        >
          Use
        </Button>
      </Modal.Footer>
    </>
  )
}
