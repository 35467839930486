import React, { ReactElement } from 'react';

import { Helmet } from 'react-helmet';

function Error403Page(): ReactElement {
  return (
    <>
      <Helmet>
        <title>Forbidden</title>
      </Helmet>

      <div className="header-bar">
        <h1>Forbidden (403)</h1>
      </div>
      <p>
        You do not have permission to view or update the resource you were accessing.
      </p>
      <p>
        Please contact support at 888-996-6993 or support@goicon.com
      </p>
    </>
  );
}

export default Error403Page;
