import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { IGroup } from 'types/IGroup';
import { RootState } from 'types/rootState';
import GroupEdit from './GroupEdit';
import GroupView from './GroupView';

interface IProps {
  groups: IGroup[]
  editMode: boolean
  setEditMode: (boolean) => void
}
const GroupMembers = ({ groups, editMode = false, setEditMode }: IProps): ReactElement => {
  const selectedGroupId = useSelector((state: RootState) => state.Groups.selectedGroupId);
  return (
    <>
      {editMode
        && (
          <GroupEdit
            key={selectedGroupId}
            groups={groups}
            setShowEditMembers={setEditMode}
          />
        )}
      {!editMode
        && (
          <GroupView
            setEditMode={setEditMode}
          />
        )}
    </>
  )
}
export default GroupMembers;
