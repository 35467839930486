import React, { useEffect, ReactElement } from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import { fetchUserDetail } from 'reducers/UserDetail';
import { ILibraryContent, LibraryContentType } from 'types/ILibraryContent';
import {
  Card, Dropdown, DropdownButton,
} from 'react-bootstrap';
import { useLibraries } from 'components/hooks/useLibraryContent';
import { TemplateOrEvent } from 'types/IEvent';
import { EmailContent } from './EmailContent';
import { GenerateContentButton } from './GenerateContentButton';

type props = {
  isNew: boolean
  removeButton: ReactElement
  data?: TemplateOrEvent
  setShowGenerateModal?: (show: 'email') => void
  requireValidation?: boolean
  isUrlLinkSurvey?: boolean
}

export const TemplateContentEmail = ({
  isNew, removeButton, data, setShowGenerateModal, requireValidation, isUrlLinkSurvey,
}:props):ReactElement => {
  const dispatch = useDispatch();
  const { setValue, watch } = useFormContext();
  const type = LibraryContentType.EMAIL;
  const selectedLanguage = watch('SourceLanguage');
  const libraries = useLibraries({ type }).filter(
    (lib) => lib.SourceLanguage === selectedLanguage);

  const onSelectLibrary = (library: ILibraryContent) => {
    setValue('EmailContent', library.EmailContent);
    setValue('EmailContent.Attachments', library.EmailContent.Attachments)
  };

  const emailContentTypeOptions = libraries.map((l) => ({
    id: l.ID,
    label: l.Name,
    value: l.ID,
  }));
  useEffect(() => {
    if (isNew) {
      dispatch(fetchUserDetail())
    }
  }, [dispatch, isNew]);

  const defaultData = useSelector((state: RootState) => state.UserDetail)?.data;
  const smartContentEnabled = !!setShowGenerateModal;

  useEffect(() => {
    if (isNew && defaultData && !data?.EmailContent) {
      const { ReplyToEmailAddress, SenderEmailAddress, SenderDisplayName } = defaultData;
      setValue('EmailContent.Display', SenderDisplayName);
      setValue('EmailContent.ReplyTo', ReplyToEmailAddress);
      setValue('EmailContent.From', SenderEmailAddress);
    }
  }, [defaultData, isNew, setValue, data]);

  const generateLibraryItems = (options: any) => {
    if (options && options.length > 0) {
      return (
        <>
          {options.map(({ id, label }) => (
            <Dropdown.Item key={id} eventKey={id}>
              {label}
            </Dropdown.Item>
          ))}
        </>
      )
    }
    return (
      <Dropdown.Item>No Content</Dropdown.Item>
    )
  }

  return (
    <Card className="mt-4">
      <Card.Header as="h4">
        Email Content
        {removeButton}
        <div className="float-right d-flex">
          {smartContentEnabled && (
            <GenerateContentButton
              setShowGenerateModal={setShowGenerateModal}
              channel="email"
            />
          )}
          <DropdownButton
            id="select-library-content"
            title="Select Content from Library"
            variant="secondary"
            size="sm"
            onSelect={(e) => {
              if (libraries && libraries.length > 0) {
                onSelectLibrary(libraries.find((lib) => lib.ID.toString() === e));
              }
            }}
          >
            {generateLibraryItems(emailContentTypeOptions)}
          </DropdownButton>
        </div>

      </Card.Header>
      <Card.Body>
        <EmailContent
          emailContent={data?.EmailContent}
          requireValidation={requireValidation}
          isUrlLinkSurvey={isUrlLinkSurvey}
        />
      </Card.Body>
    </Card>
  );
};
