import { useSelector } from 'react-redux';
import { selectUserPermissions } from 'reducers/UserInfo';

export const hasPermission = (userPermissions, permission) => {
  if (!userPermissions) {
    return false;
  }
  const [name, mode] = permission.split(':');
  return !!userPermissions[name]?.[mode];
}

type UseHasPermissions = ((permission: string) => boolean) & ((permissions: string[]) => boolean[]);

export const useHasPermissions: UseHasPermissions = (permissions:any):any => {
  const userPermissions = useSelector(selectUserPermissions);

  if (Array.isArray(permissions)) {
    return permissions.map((permission) => hasPermission(userPermissions, permission));
  }

  return hasPermission(userPermissions, permissions)
}
