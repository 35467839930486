import React, {
  useState, useEffect, useCallback, useRef, ReactElement, useMemo,
} from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import _difference from 'lodash/difference';
import _intersection from 'lodash/intersection';
import _isEqual from 'lodash/isEqual';
import { RootState } from 'types/rootState';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'react-bootstrap';
import { CellProps } from 'react-table';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import _capitalize from 'lodash/capitalize';

import ProfilePreferences from 'components/ProfilePreferences';
import { IAccountIntegration } from 'reducers/IUserInfoState';
import {
  accountProfileColumnOrder, accountProfileDisplayedColumns, getAccountIntegrations, selectUserInfo,
} from 'reducers/UserInfo';
import { IGroup } from 'types/IGroup';
import Storage from 'lib/Storage';
import {
  getProfilesIndex,
  selectPaginationData,
  selectProfilesPage,
  getExportProfiles,
  exportLoading,
  selectLoading,
  saveProfileLayoutAsDefault,
} from 'reducers/Profiles';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import { formatPhoneNumberCell } from 'lib/formatters/phoneNumber';
import { ProfileTypeCell } from 'components/ProfileTypeCell';
import { useIsSyncedCustomer } from 'hooks/useIsSyncedCustomer';
import { IProfilesIndexParams } from 'types/IProfilesIndexParams';
import { IProfile, IProfileExport } from 'reducers/IProfile';
import { getGroupsIndex, selectGroups } from 'reducers/Groups';
import { BulkActions } from 'components/BulkActions';
import { ReactComponent as Plus } from 'styles/images/plus.svg';
import { ReactComponent as Filter } from 'styles/images/filter.svg';
import PageSizePicker from 'components/PageSizePicker';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';
import { CustomizeModal } from './components/CustomizeModal';
import IndexFilter, { ISearchQuery } from './components/IndexFilter';
import DeleteActionModal from './components/DeleteActionModal';
import CustomizeWarningModal from './components/CustomizeWarningModal';

const DEFAULT_PROFILE_DISPLAYED_FIELDS = [
  'FirstName',
  'LastName',
  'UserType',
  'RoomNumber',
  'LandLine',
  'MobilePhone',
  'Email',
  'Preferences',
];

const formatIsInactiveCell = (props: CellProps<Record<string, unknown>>): string => {
  const { cell: { value } } = props
  if (value) return 'Yes';
  return 'No';
}

const formatCustomFieldLabel = (input: string): string => {
  if (!input) {
    return '';
  }
  return input.split('#')[0].trim(); // Split at "#" and take the first part, then trim any extra spaces
}

function ProfilesIndexPage(): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [displayedFields, setDisplayedFields] = useState<string[]|undefined>();
  const [displayedFieldsPreview, setDisplayedFieldsPreview] = useState<string[]|undefined>();
  const [columnOrder, setColumnOrder] = useState<string[]|undefined>();
  const [columnOrderPreview, setColumnOrderPreview] = useState<string[]|undefined>();
  const [itemToDeleteIds, setItemToDeleteIds] = useState<string[]>([]);
  const [pageSize = 10, setPageSize] = useQueryParam('perpage', NumberParam);
  const [currentPage, setCurrentPage] = useQueryParam('page', NumberParam);

  const [filterType, setFilterType] = useQueryParam('filterType', StringParam);
  const [filterGroup, setFilterGroup] = useQueryParam(
    'filterGroup',
    StringParam,
  );
  const [searchQuery, setSearchQuery] = useQueryParam(
    'searchQuery',
    StringParam,
  );
  const [sortField, setSortField] = useQueryParam(
    'sortField',
    StringParam,
  );
  const [sortDirection, setSortDirection] = useQueryParam(
    'sortDirection',
    StringParam,
  );
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [showCustomizeWarningModal, setShowCustomizeWarningModal] = useState(false);
  const resetFilters = (): void => {
    setFilterType('', 'pushIn');
    setFilterGroup('', 'replaceIn');
    setSearchQuery('', 'replaceIn');
    setCurrentPage(1, 'replaceIn');
  };

  const getSearchQuery = useCallback((): ISearchQuery => {
    const query = searchQuery?.split(',');
    if (!query) return {};
    return { searchField: query[0], searchValue: query[1], customFieldName: query[2] };
  }, [searchQuery]);

  const getProfilesIndexParams = useCallback(() => {
    const params: IProfilesIndexParams = {
      page: currentPage,
      perpage: pageSize,
    };

    params.filterType = filterType;
    params.filterGroup = filterGroup;

    const query = getSearchQuery();
    if (query.searchField && query.searchValue) {
      params.searchField = query.searchField;
      params.searchValue = query.searchValue;
      params.customFieldName = query.customFieldName;
    }

    if (sortField && sortDirection) {
      params.sortField = sortField;
      params.sortDirection = sortDirection;
    }

    return params;
  }, [
    currentPage,
    filterType,
    filterGroup,
    pageSize,
    getSearchQuery,
    sortField,
    sortDirection,
  ]);

  const dispatchProfilesIndex = useCallback((): void => {
    dispatch(getProfilesIndex(getProfilesIndexParams()));
  }, [dispatch, getProfilesIndexParams]);

  useEffect(() => {
    dispatchProfilesIndex();
  }, [dispatchProfilesIndex]);
  useEffect(() => {
    dispatch(getGroupsIndex());
  }, [dispatch]);

  const isProfilesLoading = useSelector(selectLoading);

  const profiles: IProfile[] = useSelector(
    selectProfilesPage(getProfilesIndexParams()), shallowEqual,
  );

  const groups: IGroup[] = useSelector(
    selectGroups(true),
  );
  const paginationData = useSelector(selectPaginationData);
  const accountIntegrations: IAccountIntegration[] = useSelector(
    getAccountIntegrations,
  );
  const exportProfiles: IProfile[] = useSelector<RootState, IProfile[]>(
    (state: RootState) => state.Profiles.exportProfiles,
  );
  const userInfo = useSelector(selectUserInfo);
  const acctProfileColumnOrder = useSelector(accountProfileColumnOrder);
  const acctProfileDisplayedColumns = useSelector(accountProfileDisplayedColumns);
  const accountId = useMemo(() => userInfo?.AccountDetail?.AccountID ?? null, [userInfo]);

  const profileExportLoading: boolean = useSelector(exportLoading);
  const csvLink = useRef<CSVLink>();
  const downloadHandler = async () => {
    if (profileExportLoading) return;
    const params: IProfilesIndexParams = {
      page: 1,
      perpage: 999,
    };

    if (filterType || filterGroup) {
      params.filterType = filterType;
      params.filterGroup = filterGroup;
    }

    const query = getSearchQuery();
    if (query.searchField && query.searchValue) {
      params.searchField = query.searchField;
      params.searchValue = query.searchValue;
      params.customFieldName = query.customFieldName;
    }

    if (sortField && sortDirection) {
      params.sortField = sortField;
      params.sortDirection = sortDirection;
    }
    await dispatch(getExportProfiles(params));
    csvLink.current.link.click();
  }

  const callback = useCallback((props: CellProps<IProfile>): ReactElement => {
    const {
      row: { original },
    } = props;

    return (
      <ProfilePreferences
        profile={original}
        showOptedOutPreferences
        iconWidth="1.5em"
      />
    );
  }, []);

  const columns = useMemo(() => {
    const res = [
      { Header: 'Profile ID', accessor: 'CustomerProfileID' },
      { Header: 'First Name', accessor: 'FirstName', sortable: true },
      {
        Header: 'Last Name', accessor: 'LastName', sortable: true,
      },
      {
        Header: 'Type',
        accessor: 'UserType',
        Cell: ProfileTypeCell,
        sortable: true,
      },
      { Header: 'Room #', accessor: 'RoomNumber', sortable: true },
      {
        Header: 'Landline Number',
        accessor: 'LandLine',
        Cell: formatPhoneNumberCell,
      },
      {
        Header: 'Mobile Number',
        accessor: 'MobilePhone',
        Cell: formatPhoneNumberCell,
      },
      { Header: 'Email Address', accessor: 'Email' },
      {
        Header: 'Preferences',
        accessor: 'Preferences',
        Cell: callback,
      },
      { Header: 'Address', accessor: 'Address' },
      { Header: 'City', accessor: 'City' },
      { Header: 'State', accessor: 'State' },
      { Header: 'Zip Code', accessor: 'ZipCode' },
      { Header: 'On Demand', accessor: 'OnDemand' },
      { Header: 'Is Inactive', accessor: 'IsInactive', Cell: formatIsInactiveCell },
      { Header: 'Inactive Start Date', accessor: 'InactiveStartDate' },
      { Header: 'Inactive End Date', accessor: 'InactiveEndDate' },
      { Header: 'Language', accessor: 'Language' },
      { Header: 'Job Classification', accessor: 'JobClassification' },
      { Header: 'Role', accessor: 'Role' },
      { Header: 'Block Begin Time', accessor: 'BlockBeginTime' },
      { Header: 'Block End Time', accessor: 'BlockEndTime' },
    ];
    if (!userInfo) {
      return res;
    }
    if (userInfo.CustomFields?.length) {
      res.push(...userInfo.CustomFields.map(({ FieldName }) => (
        { Header: formatCustomFieldLabel(FieldName), accessor: `CustomFields.${FieldName}` }
      )));
    }
    if (userInfo.ComplianceDates) {
      Object.entries(userInfo.ComplianceDates).forEach(([key, val]) => {
        if (val !== null) {
          res.push({ Header: `${val}`, accessor: key });
        }
      })
    }
    if (userInfo.LifeDates) {
      Object.entries(userInfo.LifeDates).forEach(([key, val]) => {
        if (val !== null) {
          res.push({ Header: `${val}`, accessor: key });
        }
      })
    }
    return res;
  }, [userInfo, callback]);

  const profileDisplayableColumns = useMemo<string[] | null>(() => {
    if (!userInfo) {
      return null;
    }
    return columns.map((item) => item.accessor);
  }, [userInfo, columns]);

  useEffect(() => {
    if (accountId === null) {
      return;
    }
    let profileDisplayedFields = Storage.getItemByAccountId('profileDisplayedColumns', accountId);
    if (!profileDisplayedFields) {
      if (acctProfileDisplayedColumns) {
        profileDisplayedFields = acctProfileDisplayedColumns;
      } else {
        profileDisplayedFields = DEFAULT_PROFILE_DISPLAYED_FIELDS;
      }
      Storage.setItemByAccountId('profileDisplayedColumns', profileDisplayedFields, accountId);
    }
    setDisplayedFields(profileDisplayedFields);
    setDisplayedFieldsPreview(profileDisplayedFields);
  }, [accountId, acctProfileDisplayedColumns]);

  useEffect(() => {
    if (!profileDisplayableColumns || accountId === null) {
      return;
    }
    let profileColumnOrder = Storage.getItemByAccountId('profileColumnOrder', accountId);
    if (profileColumnOrder) {
      if (!_isEqual([...profileColumnOrder].sort(), [...profileDisplayableColumns].sort())) {
        profileColumnOrder = [
          ..._intersection(profileDisplayableColumns, profileColumnOrder),
          ..._difference(profileDisplayableColumns, profileColumnOrder),
        ];
        Storage.setItemByAccountId('profileColumnOrder', profileColumnOrder, accountId);
      }
    } else {
      if (acctProfileColumnOrder) {
        profileColumnOrder = acctProfileColumnOrder;
      } else {
        profileColumnOrder = profileDisplayableColumns;
      }
      Storage.setItemByAccountId('profileColumnOrder', profileColumnOrder, accountId);
    }
    setColumnOrder(profileColumnOrder);
    setColumnOrderPreview(profileColumnOrder);
  }, [profileDisplayableColumns, setColumnOrder, accountId, acctProfileColumnOrder]);

  const rowActionItems = (entityId: string): ReactElement => (
    <>
      <Link to={`/profiles/${entityId}/edit`}>Edit</Link>
      &nbsp;
      <a
        href="/profiles"
        onClick={(e) => {
          e.preventDefault();
          setItemToDeleteIds([entityId]);
        }}
      >
        Delete
      </a>
    </>
  );

  const onTypeChange = (type: string): void => {
    setFilterType(type, 'pushIn');
    setCurrentPage(1, 'replaceIn');
  };

  const onGroupChange = (group: string): void => {
    setFilterGroup(group, 'pushIn');
    setCurrentPage(1, 'replaceIn');
  };

  const onSearch = (searchingQuery: ISearchQuery): void => {
    if (searchingQuery.searchField || searchingQuery.searchValue) {
      setSearchQuery(
        // eslint-disable-next-line max-len
        `${searchingQuery.searchField || ''},${searchingQuery?.searchValue || ''},${searchingQuery?.customFieldName || ''}`,
        'pushIn',
      );
    } else {
      setSearchQuery('');
    }
    setCurrentPage(1, 'replaceIn');
  };

  const renderBulkActions = () => {
    if (selectedRowIds.length === 0) {
      return null;
    }

    return (
      <BulkActions
        className="ml-3"
        items={[
          {
            label: 'Delete',
            handler: () => {
              setItemToDeleteIds([...selectedRowIds]);
            },
          }]}
      />
    )
  }

  const RemapGroupIds = (): IProfileExport[] => {
    const groupDictionary = groups.reduce((d, g) => ({ ...d, [g.Id]: g.Name }), {});
    const result = exportProfiles.map((p) => {
      const { Groups, ...profileExport } = { ...p, GroupNames: [] };
      profileExport.GroupNames = p.Groups?.map((pg) => groupDictionary[pg]).filter((x) => x !== undefined);
      profileExport.UserType = translateUserType(profileExport.UserType.toLowerCase());
      return profileExport;
    });
    return result;
  }
  const translateUserType = useUserTypeTranslation();
  const isSyncedCustomer = useIsSyncedCustomer();

  return (
    <div className="ProfilesIndex">
      <Helmet>
        <title>Profiles</title>
      </Helmet>
      <div className="header-bar">
        <div className="actions w-100">
          <div className="d-flex justify-content-between align-items-center">
            <button
              type="button"
              role="link"
              className="btn btn-lg btn-warning"
              onClick={() => {
                history.push(isSyncedCustomer ? '/profiles/add' : '/profiles/new');
              }}
            >
              <Plus
                width="24"
                height="24"
                fill="currentColor"
                style={{ marginRight: '10px' }}
              />
              Add New Profile
            </button>
            <span className="account-integration">
              {accountIntegrations
                && accountIntegrations.map((ai, i) => (
                  <Alert
                    key={ai.UserType}
                    variant="info"
                  >
                    {_capitalize(translateUserType(ai.UserType))}
                    &nbsp;records are managed by
                    {' '}
                    {ai.IntegrationPartner}
                  </Alert>
                ))}
              &nbsp;
            </span>
            <button
              type="button"
              className="btn btn-lg btn-secondary"
              onClick={async () => { await downloadHandler(); }}
            >
              <FontAwesomeIcon icon="file-export" />
              {' '}
              {profileExportLoading ? 'Exporting...' : 'Export Data'}
            </button>
            <CSVLink
              data={RemapGroupIds()}
              ref={csvLink}
              filename="profiles.csv"
              className="hidden"
              target="_blank"
              asyncOnClick
            />
          </div>
        </div>
      </div>
      <div>
        <IndexFilter
          filterType={filterType || ''}
          filterGroup={filterGroup || ''}
          searchQuery={getSearchQuery()}
          onTypeChange={onTypeChange}
          onGroupChange={onGroupChange}
          onSearch={onSearch}
          onResetFilters={resetFilters}
          totalItems={paginationData.TotalItems}
        >
          <div className="d-flex align-items-end ml-auto mb-2">
            <Filter
              className="ml-3 mb-1"
              width="32"
              height="32"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowModal(true);
              }}
            />
            {renderBulkActions()}
          </div>
        </IndexFilter>
      </div>

      <div className="position-relative">
        <Table
          columns={columns}
          data={profiles}
          rowIDAccessor="CustomerProfileID"
          rowActionItems={rowActionItems}
          selectedRowIds={selectedRowIds}
          onRowClick={(id: string) => {
            history.push(`/profiles/${id}/edit`);
          }}
          isLoading={isProfilesLoading}
          onRowSelect={(selectedIds) => {
            setSelectedRowIds(selectedIds);
          }}
          onColumnSort={(fieldName, direction) => {
            if (fieldName.length > 0) {
              setSortField(fieldName);
              setSortDirection(direction);
            } else {
              setSortField('');
              setSortDirection('');
            }
          }}
          defaultSortedColumn={sortField}
          defaultSortedColumnDirection={sortDirection}
          displayedColumns={displayedFields}
          columnOrder={columnOrder}
          className="Profiles-Table"
        />

        <div className="paging">
          <PageSizePicker
            initialPageSize={pageSize}
            onSetPageSize={(size) => {
              setCurrentPage(1, 'replaceIn');
              setPageSize(size);
              setSelectedRowIds([]);
            }}
          />

          <Pagination
            currentPage={currentPage ?? 1}
            totalPages={paginationData.TotalPages}
            onPageChange={(page: number) => {
              setCurrentPage(page, 'pushIn');
              setSelectedRowIds([]);
            }}
            pageDelta={5}
          />
        </div>
      </div>

      <CustomizeModal
        show={showModal && accountId !== null}
        onHide={() => {
          setShowModal(false);
        }}
        setDisplayedColumns={(updatedDisplayedColumns) => {
          setDisplayedFieldsPreview(updatedDisplayedColumns);
        }}
        setColumnOrder={(updatedColumnOrder) => {
          setColumnOrderPreview(updatedColumnOrder);
        }}
        displayedColumns={displayedFieldsPreview}
        columnOrder={columnOrderPreview}
        columns={columns}
        onReset={() => {
          if (acctProfileDisplayedColumns) {
            setDisplayedFieldsPreview(acctProfileDisplayedColumns);
          } else {
            setDisplayedFieldsPreview(DEFAULT_PROFILE_DISPLAYED_FIELDS);
          }
          if (acctProfileColumnOrder) {
            setColumnOrderPreview(acctProfileColumnOrder);
          } else {
            setColumnOrderPreview(profileDisplayableColumns);
          }
        }}
        saveAsDefault={() => {
          setShowModal(false);
          setShowCustomizeWarningModal(true);
        }}
      />

      <DeleteActionModal
        show={!!itemToDeleteIds.length}
        hideModal={() => {
          setItemToDeleteIds([]);
        }}
        profileIds={itemToDeleteIds}
        onSuccess={() => {
          setSelectedRowIds([]);
          dispatchProfilesIndex();
        }}
      />
      <CustomizeWarningModal
        showModal={showCustomizeWarningModal}
        setShowModal={setShowCustomizeWarningModal}
        saveAsDefault={() => {
          setDisplayedFields(displayedFieldsPreview);
          Storage.setItemByAccountId('profileDisplayedColumns', displayedFieldsPreview, accountId);
          setColumnOrder(columnOrderPreview);
          Storage.setItemByAccountId('profileColumnOrder', columnOrderPreview, accountId);
          dispatch(saveProfileLayoutAsDefault(accountId));
          toast.success('Profile layout successfully saved.');
          setShowCustomizeWarningModal(false);
        }}
        cancelSave={() => {
          setDisplayedFieldsPreview(displayedFields);
          setColumnOrderPreview(columnOrder);
          setShowCustomizeWarningModal(false);
        }}
      />
    </div>
  );
}

export default ProfilesIndexPage;
