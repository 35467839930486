import React, { FC } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  onSuccess: () => void
  onCancel: () => void
  isOpen?: boolean
  title: string
  buttonName?: string
  isDeleting?: boolean
  deleteButtonClassName?: string
  customIcon?: JSX.Element
};

export const DeleteActionModal: FC<Props> = ({
  onSuccess,
  onCancel,
  isOpen,
  title,
  buttonName,
  children,
  isDeleting = false,
  deleteButtonClassName = 'btn-danger',
  customIcon,
}) => (
  <Modal
    show={isOpen}
    onHide={onCancel}
    className="DeleteActionButtonModal"
    backdrop="static"
    centered
  >
    <Modal.Header />
    <Modal.Body>
      {customIcon || (
        <FontAwesomeIcon icon="trash-alt" size="4x" color="#FF7052" />)}

      <div className="message">
        <h4>{title}</h4>
        {' '}
        {children}
      </div>

      <button
        type="button"
        id="modalDelete"
        className={`btn btn-block ${deleteButtonClassName}`}
        onClick={onSuccess}
        disabled={isDeleting}
      >
        <span>
          {' '}
          {buttonName || 'Delete'}
          {isDeleting && <Spinner as="span" className="ml-2" animation="border" size="sm" />}
        </span>
      </button>
      <button
        type="button"
        className="btn btn-block btn-secondary"
        onClick={onCancel}
      >
        Cancel
      </button>
    </Modal.Body>
  </Modal>
);
