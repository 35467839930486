import React, { ReactElement } from 'react';

import { Controller } from 'react-hook-form';

import SelectInput, { ISelectProps } from '../Inputs/Select'
import { IFormControlProps } from './IFormControlProps';

interface IProps extends Omit<IFormControlProps, 'defaultValue'>, ISelectProps {}

function Select({
  name, defaultValue, control, rules, ...props
}: IProps): ReactElement {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={rules}
      render={(renderProps) => (
        <SelectInput {...renderProps} {...props} />
      )}
    />
  );
}

export default Select;
