import React, { FC } from 'react'

// styles
import { Col, Row } from 'react-bootstrap';

interface IProps {
  chart?: React.ReactNode
  body?: React.ReactNode
  title: string
}

const CustomCard: FC<IProps> = ({ chart, title, body } : IProps) => (
  <div className="customCardMiddle">
    <Row>
      <Col><h3>{title}</h3></Col>
      {/* <Col xs={11} className="typography3 pl-2">Increase in today sales</Col> */}
    </Row>
    <Row>
      <Col>
        <div className="chart">
          <Col>
            {chart}
          </Col>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <Row>
          <Col className="dasbhoard-widget-body">
            {body}
          </Col>
        </Row>
      </Col>
    </Row>

  </div>
)

export default CustomCard
