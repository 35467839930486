import React, { useReducer, FC } from 'react';

import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

type props = {
  label: string
  onAccept: () => void
  warningMessage: string
  className?: string
};

const OverlayCancelButton: FC<props> = ({
  label, warningMessage, className, onAccept,
}) => {
  const [showPopover, togglePopover] = useReducer((state) => !state, false);
  const btnClassName = `btn mr-2 ${className}`;
  const popoverContent = (
    <Popover id={label} onClick={(event) => event.stopPropagation()} style={{ width: 300, maxWidth: 300 }}>
      <Popover.Content>
        {warningMessage}
        <div className="d-flex justify-content-between pt-2">
          <Button
            variant="outline-danger"
            className="btn-cancel ml-2"
            onClick={() => {
              togglePopover();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            className="ml-2 btn-abandon"
            onClick={() => {
              togglePopover();
              onAccept();
            }}
          >
            Abandon&nbsp;changes
          </Button>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="left"
      show={showPopover}
      overlay={popoverContent}
    >
      <button
        type="button"
        className={btnClassName}
        onClick={() => {
          togglePopover();
        }}
      >
        {label}
      </button>
    </OverlayTrigger>
  );
};

export default OverlayCancelButton;
