export enum SearchField {
  LASTNAME = 'LastName',
  FIRSTNAME = 'FirstName',
  FULLNAME = 'FullName',
  LANDLINE = 'LandLine',
  MOBILE = 'Mobile',
  ROOM = 'Room',
  PROFILEID = 'ProfileID',
}

export interface IProfilesIndexParams {
  filterType?: string
  filterGroup?: string
  searchField?: string // Should use the search field enum.
  customFieldName?: string
  searchValue?: string
  sortField?: string
  sortDirection?: string
  page?: number
  perpage?: number
  afterLoad?: () => void
}
