import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import log from 'lib/logging';
import { logout, refreshToken } from 'reducers/UserInfo';
import { getStoreRef } from 'app/utils';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';

interface IApiClient extends AxiosInstance {
  setHeader(headerKey: string, headerValue: string): void
  unsetHeader(headerKey: string): void
  setAuthHeader(token: string): void
  unsetAuthHeader(): void
}

export interface AxiosRequestOptions extends AxiosRequestConfig {
  ignoreGlobalCatch?: boolean
}

function ApiClient() {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || document.location.origin,
  });

  instance.interceptors.response.use(
    (response) => {
      if ('x-extend-auth' in response.headers) {
        log.info('Writing extended auth token');
        const newToken = response.headers['x-extend-auth'];
        getStoreRef().dispatch(refreshToken(newToken) as any);
      }
      return response
    },
    (error) => {
      if (error.config?.ignoreGlobalCatch) {
        throw error;
      }
      if (!error.response) {
        throw error;
      }
      segmentAnalyticsTrack(trackActions.apiErrors({
        url: error.response.config.url,
        params: error.response.config.params,
        message: error.response.data.Message,
      }));
      if (
        ['/login', '/mfa', '/linkvoicefriend', '/iconredirect']
          .every((path) => !document.location.pathname.toLowerCase().startsWith(path))
        && error.response.status === 401
      ) {
        const { pathname, search, hash } = document.location;
        const redirectTo = `${pathname}${search}${hash}`;
        getStoreRef().dispatch(logout({ redirectTo }));
      }

      if (error.response.status > 499 && error.response.status < 600) {
        // all responses in the 500 namespace
        if (document.location.pathname !== '/500') {
          document.location.href = '/500';
        }
      }

      if (error.response.status === 403) {
        if (document.location.pathname !== '/403') {
          document.location.href = '/403';
        }
      }

      throw error;
    },
  );

  const apiClient = Object.assign(instance, {
    setHeader(headerKey: string, headerValue: string): void {
      this.defaults.headers.common = {
        ...this.defaults.headers.common,
        [headerKey]: headerValue,
      };
    },
    unsetHeader(headerKey: string): void {
      const commonHeaders = { ...this.defaults.headers.common };
      delete commonHeaders[headerKey];

      this.defaults.headers.common = commonHeaders;
    },
    setAuthHeader(token: string): void {
      this.setHeader('Authorization', `Bearer ${token}`);
    },
    unsetAuthHeader(): void {
      this.unsetHeader('Authorization');
    },
  });

  return apiClient;
}

export default ApiClient() as IApiClient;
