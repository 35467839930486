import React, {
  useState, useEffect,
} from 'react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import 'styles/components/Inputs/RawDateInput.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseISO } from 'date-fns';

export interface IProps {
  name: string
  label: string
  errors?: string
  value?: string
  className?: string
  required?: boolean
  onChange?: (date: string) => void
  id?:string
  useSingleMonth?: boolean
  maxDate?: Date
  startDate?: string
  endDate?: string
  selectsStart?: boolean
  selectsEnd?: boolean
  minDate?: Date
  disabled?: boolean
}

const parseUnknownDateString = (value: string) => {
  const yearMonthDay = value ? parseISO(value) : null;
  const validYearMonthDay = yearMonthDay && !isNaN(yearMonthDay.getTime()) ? yearMonthDay : null;
  const monthDayYear = value ? parse(value, 'MM/dd/yyyy', new Date()) : null;
  const validMonthDayYear = monthDayYear && !isNaN(monthDayYear.getTime()) ? monthDayYear : null;
  return validYearMonthDay ?? validMonthDayYear;
}

const DateInput = ({
  name, label, errors, value, className, onChange, required, useSingleMonth, id, startDate, endDate, disabled, ...props
}:IProps): React.ReactElement => {
  const elementId = id || name;
  const selectedDate = parseUnknownDateString(value);
  const start = parseUnknownDateString(startDate);
  const end = parseUnknownDateString(endDate);
  const [textValue, setTextValue] = useState(selectedDate ? format(selectedDate, 'MM/dd/yyyy') : '');

  useEffect(() => {
    setTextValue(selectedDate ? format(selectedDate, 'MM/dd/yyyy') : value);
  }, [selectedDate, value]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = event.target.value;
    if (dateValue === '__/__/____') {
      onChange(null);
    } else {
      onChange(dateValue);
    }
  };

  return (
    <div className={className}>
      <div
        className="Input form-group floating-label active"
      >
        {required ? <span className="required-asterisk">*</span> : null}
        <label htmlFor={id}>
          {label}
          {required ? <span> (Required)</span> : ''}
        </label>

        <div className="position-relative">
          <InputMask
            className="form-control"
            value={textValue ?? ''}
            mask="99/99/9999"
            alwaysShowMask
            onChange={changeHandler}
            disabled={disabled}
          />
          <DatePicker
            id={elementId}
            wrapperClassName="dateInputCalendar"
            customInput={<FontAwesomeIcon className="dateInputCalendarButton" icon="calendar-day" />}
            selected={selectedDate}
            onChange={(date: Date) => {
              const formattedDate = date ? format(date, 'MM/dd/yyyy') : null;
              onChange(formattedDate);
            }}
            isClearable={false}
            monthsShown={useSingleMonth ? 1 : 2}
            startDate={start}
            endDate={end}
            disabled={disabled}
            {...props}
          />
        </div>

        {errors ? <span className="errors">{errors}</span> : ''}
      </div>
    </div>
  );
}

export default DateInput;
