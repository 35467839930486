import React from 'react';
import { useFormContext } from 'react-hook-form';
import useInterval from 'react-use/lib/useInterval';
import { toast } from 'react-toastify';
import useMountedState from 'react-use/lib/useMountedState';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import useSWRMutation from 'swr/mutation';
import { useSWRConfig } from 'swr';

import * as TemplatesApi from 'api/TemplatesAPI';
import { selectTemplateIsLoading } from 'reducers/Templates';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import log from 'lib/logging';
import { TemplateTransformer } from 'transformers/Template';
import { ITemplateExtendo, ITemplate } from 'types/IEvent';
import useAccountLocalTime from 'hooks/useAccountLocalTime';
import { AutosaveDraftContext } from 'contexts/AutosaveDraftContext';

interface AutosaveDraftProviderProps {
  data: ITemplateExtendo
  enabled: boolean
}

export const AutosaveDraftProvider: React.FC<AutosaveDraftProviderProps> = ({
  enabled,
  data,
  children,
}) => {
  const [showNotification, setShowNotification] = React.useState(false);
  const [lastSubmitData, setLastSubmitData] = React.useState(null);
  const { mutate } = useSWRConfig();
  const {
    watch, setValue, getValues,
  } = useFormContext();
  const isMounted = useMountedState();
  const hasName = !!watch('Name');

  const isSaving = useSelector(selectTemplateIsLoading);

  const handleAutosave = async () => {
    const formData = {
      ...data,
      ...getValues(),
      submit: 'save-as-draft',
    }

    const submitData = TemplateTransformer.toAPI(formData);

    if (lastSubmitData && _isEqual(_omit(lastSubmitData, 'ID'), _omit(submitData, 'ID'))) {
      // if nothing changed from last autosave
      return;
    }

    segmentAnalyticsTrack(formData.ID
      ? trackActions.updateTemplate(formData)
      : trackActions.createTemplate(formData),
    );

    let receivedTemplate: ITemplate;
    if (submitData.ID) {
      receivedTemplate = await TemplatesApi.templatePut(submitData, 'template');
    } else {
      receivedTemplate = await TemplatesApi.templateCreate([submitData]);

      // to prevent memory leak
      if (isMounted()) {
        setValue('ID', receivedTemplate.ID);
      }
    }

    await mutate(
      ['template', receivedTemplate.ID],
      receivedTemplate,
      {
        revalidate: false,
        populateCache: true,
      },
    );

    // to prevent memory leak
    if (!isMounted()) {
      return;
    }
    setShowNotification(true);
    setLastSubmitData(submitData);
  }

  const draftTemplatesQueryKey = ['templates', true];

  const { isMutating: isAutosaving, trigger: autosave } = useSWRMutation(
    draftTemplatesQueryKey,
    handleAutosave,
    {
      onError: (err) => {
        const contents = err.response?.data?.ModelState;

        if (contents) {
          toast.error(Object.values(contents)
            .reduce((acc, messages) => {
              if (Array.isArray(messages) && messages.length) {
                return `${acc}\r\n• ${messages[0]}`
              }
              return acc;
            },
            'Autosave error:',
            ),
          { className: 'list-toast' },
          );
          return;
        }

        let message = err.response?.data?.Message;

        if (!message) {
          log.error('Autosave error:', err);
          message = err.message ?? 'Autosave Error';
        }

        toast.error(message);
      },
    },
  );

  useInterval(
    autosave,
    enabled && hasName && !isSaving
      ? 120000
      : null,
  );

  useInterval(
    () => setShowNotification(false),
    showNotification
      ? 5000
      : null,
  );

  const time = useAccountLocalTime().format('h:mm A');

  const ctxValue = React.useMemo(() => ({
    enabled,
    isAutosaving,
  }), [isAutosaving, enabled]);

  return (
    <AutosaveDraftContext.Provider value={ctxValue}>
      {children}
      {showNotification
        ? (
          <div className="autosaving-draft">
            <FontAwesomeIcon icon="save" className="mr-2" />
            <div>
              <span>Autosaved draft</span>
              <span>{time}</span>
            </div>
          </div>
        )
        : null}
    </AutosaveDraftContext.Provider>
  )
}
