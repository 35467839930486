import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { IOptions } from 'components/Inputs/Select';
import { ILanguage } from 'reducers/IUserInfoState';
import { RootState } from 'types/rootState';
import { Input, Select } from 'components/FormControls';
import { IPatientFormData } from 'types/IPatientFormData';

interface IProps {
  data?: IPatientFormData
  formPrefix?: string
}

export const useLanguages = ():IOptions[] => {
  const languageList: ILanguage[] = useSelector(
    (state: RootState) => state.UserInfo.userInfo?.Languages,
  );
  const [LANGUAGES, setLANGUAGES] = useState<IOptions[]>([]);

  useEffect(() => {
    const arrObj = []
    languageList?.forEach((val) => {
      arrObj.push({
        label: val.DisplayName,
        value: val.Value,
      })
    });
    setLANGUAGES(arrObj);
  }, [languageList]);
  return LANGUAGES;
}

function DischargedPatientContactForm(
  { data, formPrefix }: IProps,
): React.ReactElement {
  const {
    errors, control,
  } = useFormContext<IPatientFormData>();
  const phoneRegEx = /^\(?[2-9][0-9]{2}\)?\s?[2-9][0-9]{2}-?[0-9]{4}$/;
  const prefix = formPrefix || 'contactProfile';
  const LANGUAGES = useLanguages();

  const validateContactNumber = (val: any): boolean => {
    if (val !== '' && val !== '(___) ___-____') {
      return phoneRegEx.test(val);
    }
    return true;
  };

  return (
    <fieldset>
      <legend>Contact Information</legend>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <Input
            id={`${prefix}.FirstName`}
            name={`${prefix}.FirstName`}
            label="First Name"
            type="text"
            control={control}
            errors={errors?.contactProfile?.FirstName && 'First Name is required'}
            rules={{ required: true }}
          />
        </div>
        <div className="col-xs-12 col-md-6">
          <Input
            id={`${prefix}.LastName`}
            name={`${prefix}.LastName`}
            label="Last Name"
            type="text"
            control={control}
            errors={errors?.contactProfile?.LastName && 'Last Name is required'}
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <Input
            id={`${prefix}.LandLine`}
            name={`${prefix}.LandLine`}
            label="Land Line"
            type="text"
            control={control}
            mask="(999) 999-9999"
            errors={errors?.contactProfile?.LandLine && 'Valid Land Line or Mobile Phone required'}
            rules={{
              required: false,
              validate: (val: any) => validateContactNumber(val),
            }}
          />
        </div>
        <div className="col-xs-12 col-md-6">
          <Input
            id={`${prefix}.MobilePhone`}
            name={`${prefix}.MobilePhone`}
            label="Mobile Phone"
            type="text"
            control={control}
            mask="(999) 999-9999"
            errors={errors?.contactProfile?.MobilePhone && 'Valid Mobile or Land Line Phone required'}
            rules={{
              required: false,
              validate: (val: any) => validateContactNumber(val),
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <Select
            id={`${prefix}.Language`}
            name={`${prefix}.Language`}
            label="Language"
            placeholder="Select language"
            defaultValue="english"
            control={control}
            options={LANGUAGES}
            errors={errors?.contactProfile?.Language && 'Language is required'}
            rules={{ required: true }}
          />
        </div>
      </div>
      <Input
        id={`${prefix}.CustomerProfileID`}
        name={`${prefix}.CustomerProfileID`}
        label=""
        type="hidden"
        control={control}
      />
      <Input
        id={`${prefix}.UserType`}
        name={`${prefix}.UserType`}
        label=""
        type="hidden"
        control={control}
      />
    </fieldset>
  );
}

export default DischargedPatientContactForm;
