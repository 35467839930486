import React, {
  ReactElement, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns/esm';

import { ProfileTypeCell } from 'components/ProfileTypeCell';
import {
  getEventDetails, selectCalendarEvent, selectEventReport, downloadEventReportFile,
} from 'reducers/Calendar';
import Table from 'components/Table';

const summaryColumns = [
  { Header: 'Channel', accessor: 'channel' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Delivered', accessor: 'messagesSent' },
  { Header: 'Failed', accessor: 'messagesFailed' },
  { Header: 'Duplicates', accessor: 'messagesDuplicated' },
];

const voiceColumns = [
  { Header: 'Status', accessor: 'status' },
  { Header: 'First Name', accessor: 'firstName' },
  { Header: 'Last Name', accessor: 'lastName' },
  { Header: 'Type', accessor: 'type', Cell: ProfileTypeCell },
  { Header: 'Phone Number', accessor: 'phoneNumber' },
  { Header: 'Room', accessor: 'room' },
  { Header: 'Duration Seconds', accessor: 'duration' },
  { Header: 'From Number', accessor: 'fromNumber' },
  { Header: 'Live/Voicemail', accessor: 'deliveryMethod' },
  { Header: 'Keypress', accessor: 'keypress' },
  { Header: 'Error', accessor: 'error' },
];

const emailColumns = [
  { Header: 'Status', accessor: 'status' },
  { Header: 'First Name', accessor: 'firstName' },
  { Header: 'Last Name', accessor: 'lastName' },
  { Header: 'Type', accessor: 'type', Cell: ProfileTypeCell },
  { Header: 'To Email', accessor: 'toEmail' },
  { Header: 'Room', accessor: 'room' },
  { Header: 'Opened', accessor: 'opened' },
  { Header: 'Error', accessor: 'error' },
];

const smsColumns = [
  { Header: 'Status', accessor: 'status' },
  { Header: 'First Name', accessor: 'firstName' },
  { Header: 'Last Name', accessor: 'lastName' },
  { Header: 'Type', accessor: 'type', Cell: ProfileTypeCell },
  { Header: 'Phone Number', accessor: 'phoneNumber' },
  { Header: 'Room', accessor: 'room' },
  { Header: 'FromNumber', accessor: 'fromNumber' },
  { Header: 'Note', accessor: 'note' },
];

export const EventResults = ():ReactElement => {
  const { id, date } = useParams<{ id: string, date: string }>();
  const dispatch = useDispatch();
  const eventData = useSelector(selectCalendarEvent(Number(id), date));
  const eventReport = useSelector(selectEventReport(Number(id), date));

  useEffect(() => {
    dispatch(getEventDetails(Number(id), date));
  }, [id, date, dispatch]);

  if (!eventData) {
    return <div />;
  }

  return (
    <div>
      <h3>{eventData?.EventName}</h3>
      <p>{eventData?.EventDescription}</p>
      <p>{`${format(parseISO(eventData?.EventSendDate), 'M/dd/yyyy')} ${eventData?.EventSendTime}`}</p>

      <Button
        disabled={!(eventReport?.summary?.length > 0)}
        className="mb-2"
        onClick={() => {
          dispatch(downloadEventReportFile(id, date, eventData?.EventName));
        }}
      >
        Export Data
      </Button>

      <Table
        columns={summaryColumns}
        data={eventReport?.summary || []}
        enableCheck={false}
      />

      {!eventData.IsMarketing && (
        <>
          <h4 className="pt-4 pb-4" style={{ marginTop: 10 }}>Voice Transaction Report</h4>
          <Table
            columns={voiceColumns}
            data={eventReport?.voice || []}
            enableCheck={false}
            className="TransactionReportTable"
          />
        </>
      )}

      <h4 className="pt-4 pb-4" style={{ marginTop: 10 }}>Email Transaction Report</h4>
      <Table
        columns={emailColumns}
        data={eventReport?.emails || []}
        enableCheck={false}
        className="TransactionReportTable"
      />

      {!eventData.IsMarketing && (
        <>
          <h4 className="pt-4 pb-4" style={{ marginTop: 10 }}>SMS Transaction Report</h4>
          <Table
            columns={smsColumns}
            data={eventReport?.sms || []}
            enableCheck={false}
            className="TransactionReportTable"
          />
        </>
      )}

    </div>
  );
};
