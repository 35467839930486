import React, { ReactElement, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory } from 'react-router-dom';
import { ReactComponent as Plus } from 'styles/images/plus.svg';
import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { useHasFeatures } from 'hooks/useHasFeatures';
import { Spinner } from 'react-bootstrap';
import { debugGenerateTodaysEvents, getEnterpriseSubaccounts } from 'api/BroadcastFormAPI';
import useSWR from 'swr';
import { toast } from 'react-toastify';
import { EbfGeneratorTable } from './EbfGeneratorTable';
import { useEbfAccountEventsConfig } from './EbfGeneratorTriggeredEventsTable';

const useEnterpriseSubaccounts = () => useSWR(
  ['enterpriseSubaccounts'],
  async () => {
    const response = await getEnterpriseSubaccounts({
      ignoreGlobalCatch: true,
    });
    return response;
  },
  {
    shouldRetryOnError: false,
  },
);

const MultiAccountSurveyConfigPage = ():ReactElement => {
  const history = useHistory();
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const hasAccessPermission = useHasPermissions('Calendar:Read') || isAdmin || isSuper;
  const { hasFeature, isLoading } = useHasFeatures('broadcast-forms');
  const { data: enterpriseSubaccounts, error, isLoading: subaccountsLoading } = useEnterpriseSubaccounts();
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const { data: eventsConfig, isLoading: eventsConfigLoading } = useEbfAccountEventsConfig();
  const hasWarnings = (eventsConfig?.WarningMessages?.length ?? 0) > 0;
  const warningMessages = useMemo(() => eventsConfig?.WarningMessages?.join(', '), [eventsConfig?.WarningMessages]);

  if (!hasAccessPermission) {
    return <Redirect to="/" />
  }

  if (error) {
    return (
      <div>
        {error?.response?.data?.Message || 'An error occurred'}
      </div>
    )
  }

  if (isLoading || subaccountsLoading || eventsConfigLoading) {
    return (
      <Spinner
        animation="border"
        variant="primary"
        role="status"
        className="mx-auto d-block mt-4 flex-shrink-0"
      />
    );
  }

  if (!hasFeature) {
    return <div>Multi-Account Surveys is not enabled for this account</div>
  }

  if (enterpriseSubaccounts.length === 0) {
    return <div>This account has not been set up for Multi-account surveys</div>
  }

  return (
    <div className="broadcast-forms Templates">
      <Helmet>
        <title>Feedback360: Multi-Account Surveys</title>
      </Helmet>
      <div className="header-bar broadcast-forms__header-bar">
        <button
          role="link"
          type="button"
          onClick={() => history.push('/internal/ebfgenerators/new')}
          className="btn btn-lg btn-warning "
        >
          <Plus
            width="24"
            height="24"
            fill="currentColor"
            style={{ marginRight: '10px' }}
          />
          Add New Generator
        </button>

        <button
          role="link"
          type="button"
          disabled={isGenerating}
          onClick={async () => {
            setIsGenerating(true);
            try {
              await debugGenerateTodaysEvents();
              toast.success('Finished Generating surveys for today');
            } catch (ex) {
              toast.error(`Generation failed with ex: ${ex}`);
            } finally {
              setIsGenerating(false);
            }
          }}
          className="btn btn-lg btn-warning "
        >
          {isGenerating ? 'Generating...' : 'Debug generate events'}
        </button>
        <div className="broadcast-forms__header-description">
          Multi-account survey generators can be used to setup automation for sending Feedback360 surveys to multiple accounts. A survey and event to send the survey link will be created in each subaccount.
        </div>
      </div>
      <p>{`Subaccounts: ${enterpriseSubaccounts.join(', ')}`}</p>
      {hasWarnings ? <p className="mt-4 text-danger">{warningMessages}</p> : null}
      <div className="mb-4" />
      <EbfGeneratorTable
        showActions
        filterTitle="Generator"
      />
    </div>
  );
};
export default MultiAccountSurveyConfigPage;
