import React from 'react';
import { ISurveyQuestion } from 'types/ISurveyQuestion';
import { SurveyQuestionForm } from './SurveyQuestionForm';

type Props = {
  surveyCallId: number
  isComplete: boolean
  telephonyStatus: string
  data: ISurveyQuestion[]
};

export const SurveyQuestions: React.FC<Props> = ({
  data, surveyCallId, isComplete, telephonyStatus,
}) => {
  const questions = data.slice().sort((a, b) => (a.Ordinal - b.Ordinal));
  return (
    <div>
      {
        questions?.length > 0 && (
          questions.map((content, index) => (
            <div className="surveyQuestions" key={content.ContentId.toString()}>
              <SurveyQuestionForm
                key={content.ContentId.toString()}
                data={content}
                surveyCallId={surveyCallId}
                isComplete={isComplete}
                telephonyStatus={telephonyStatus}
                index={index}
              />
            </div>
          ))
        )
      }
    </div>
  )
}
