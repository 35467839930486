import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { EventTemplateMode, IEventExtendo } from 'types/IEvent';
import { RootState } from 'types/rootState';
import { EventTemplateForm } from 'components/EventTemplateForm/EventTemplateForm';
import { useTemplateQuery } from 'components/hooks/useTemplateQuery';
import { TemplateTransformer } from 'transformers/Template';
import { useSaveEvent } from 'hooks/useSaveEvent';

/**
 * @desc usage: when scheduling a template
 */
export default function CreateEventPage() {
  const history = useHistory();
  const { state: { sourceTemplateId, sourceTemplateType } = {} } = useLocation<{
    sourceTemplateId?: number
    sourceTemplateType?: 'transactional'|'template'
  }>();
  const accountTz = useSelector((state: RootState) => state.UserInfo.accountTimezone);

  useTemplateQuery(
    sourceTemplateId,
    sourceTemplateType,
    undefined,
    {
      onSuccess: (sourceTemplate) => {
        if (!sourceTemplate) {
          return;
        }
        setData(TemplateTransformer.toEvent(sourceTemplate, accountTz));
      },
    },
  );

  const [data, setData] = React.useState<IEventExtendo|null>(null);

  React.useEffect(() => {
    if (!sourceTemplateId) {
      history.push('/calendar');
    }
  }, [sourceTemplateId, history]);

  const handleSaveEvent = useSaveEvent({
    redirectOnSuccess: '/calendar',
  });

  const isLoading = !data;

  return (
    <div>
      {isLoading ? (
        <div
          className="loading-text d-flex align-items-center justify-content-center"
          role="status"
        >
          <Spinner
            animation="border"
            as="span"
            className="mr-2"
            style={{ width: '1.66em', height: '1.66em' }}
          />
          <span style={{ fontSize: '1.25em' }}>
            Loading...
          </span>
        </div>
      ) : (
        <EventTemplateForm
          data={data}
          setData={setData}
          onSubmit={handleSaveEvent}
          onCancel={() => {
            history.push('/calendar')
          }}
          eventTemplateMode={EventTemplateMode.Event}
          isNew={false}
          enableGenerateSmartContent
        />
      )}
    </div>
  );
}
