import React, { ReactElement } from 'react';

import { Controller } from 'react-hook-form';

import ControlledCheckbox, { ICheckboxProps } from '../Inputs/Checkbox';
import { IFormControlProps } from './IFormControlProps';

interface IProps extends IFormControlProps, Omit<ICheckboxProps, 'defaultValue' | 'ref' | 'id' > {}

function Checkbox({
  id,
  name,
  control,
  rules,
  defaultValue,
  ...props
}: IProps): ReactElement {
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
      render={({ value, onChange, ...renderProps }) => (
        <ControlledCheckbox
          checked={value}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          {...renderProps}
          {...props}
        />
      )}
    />
  );
}

export default Checkbox;
