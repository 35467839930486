import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { RRule } from 'rrule';
import { useHistory } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import {
  createEvent,
} from 'reducers/Events';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import { EventTransformer } from 'transformers/Event';
import { IEventExtendo } from 'types/IEvent';

interface UseSaveEventProps {
  redirectOnSuccess?: false|string
}

export const useSaveEvent = ({ redirectOnSuccess = false }: UseSaveEventProps = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { mutate } = useSWRConfig();

  return (formData: IEventExtendo) => {
    const submitData = EventTransformer.toAPI(formData);

    segmentAnalyticsTrack(trackActions.createEvent(submitData));

    const onSuccess = async (receivedEvent) => {
      let msg = 'Event successfully scheduled for';
      if (receivedEvent.SendMode.toLowerCase() === 'once') {
        const m = moment(`${formData.StartDate} ${receivedEvent.SendTime}`, 'MM-DD-yyyy HH:mm');
        msg += ` one time on ${m.calendar()}`;
      } else if (receivedEvent.SendMode.toLowerCase() === 'schedule') {
        const eventRrule = RRule.fromString(receivedEvent.RRule);
        msg += ` ${eventRrule.toText()}`;
      }

      toast.success(msg);
      if (redirectOnSuccess) {
        history.push(redirectOnSuccess);
      }

      await mutate(
        ['event', receivedEvent.ID],
        receivedEvent,
        {
          revalidate: false,
          populateCache: true,
        },
      );
    }

    dispatch(createEvent(
      submitData,
      onSuccess,
    ));
  }
}
