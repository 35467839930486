import { createContext } from 'react';

export interface IAutosaveDraftContext {
  isAutosaving: boolean
  enabled: boolean
}

export const defaultAutosaveDraftContextValue: IAutosaveDraftContext = {
  isAutosaving: false,
  enabled: false,
}

export const AutosaveDraftContext = createContext<IAutosaveDraftContext>(defaultAutosaveDraftContextValue);
