import moment from 'moment';
import { useSelector } from 'react-redux';
import { IUserInfoState } from 'reducers/IUserInfoState';
import { RootState } from 'types/rootState';

function useAccountLocalTime(): moment.Moment {
  const userInfo = useSelector((state: RootState) : IUserInfoState => state.UserInfo);
  return moment().tz(userInfo.accountTimezone);
}
export default useAccountLocalTime;
