import React from 'react';
import { Card, Col, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import { bin2string } from 'utils/stringUtils';
import { AudioPlayer } from 'components/AudioPlayer';
import { useTemplateQuery } from './hooks/useTemplateQuery';

interface Props {
  templateId: number
  accountId: number
  isBlueprint: boolean
}

export const TemplateSummary: React.FC<Props> = ({
  templateId,
  accountId,
  isBlueprint,
}) => {
  const { data: template } = useTemplateQuery(
    templateId,
    'template',
    isBlueprint
      ? 0
      : accountId,
  );

  return (
    <Col className="template-summary">
      {!template ? (
        <div
          className="loading-text d-flex align-items-center justify-content-center"
          role="status"
        >
          <Spinner
            animation="border"
            as="span"
            className="mr-2"
            style={{ width: '1.66em', height: '1.66em' }}
          />
          <span style={{ fontSize: '1.25em' }}>
            Loading...
          </span>
        </div>
      ) : (
        <>
          <h3 className="mb-4 template-name mt-3">{template.Name}</h3>
          <label className="template-location">
            Location
          </label>
          <p>{template.Location || '-'}</p>

          <label className="template-description">Description</label>
          <p>{template.Description || '-'}</p>

          {template.VoiceContent && (
            <Card
              id="voice-summary"
            >
              <Card.Header as="h6">
                <FontAwesomeIcon className="mr-1" icon="phone" />
                {' '}
                Voice Message
              </Card.Header>
              <Card.Body>
                {template.VoiceContent?.Content && (
                  <pre
                    className="pt-3"
                  >
                    {template.VoiceContent.Content}
                  </pre>
                )}
                {template.VoiceContent?.VoiceRecording && (
                  <AudioPlayer
                    src={`data:audio/wav;base64,${template.VoiceContent.VoiceRecording.Data}`}
                  />
                )}
              </Card.Body>
            </Card>
          )}
          {template.EmailContent && (
            <Card className={template.VoiceContent ? 'mt-4' : ''}>
              <Card.Header as="h6">
                <FontAwesomeIcon className="mr-1" icon="envelope-square" />
                {' '}
                Email Message
              </Card.Header>
              <Card.Body>
                <p className="m-0">
                  <strong>Subject:</strong>
                  {' '}
                  {template.EmailContent.Subject}
                </p>
                <p className="m-0">
                  <strong>From:</strong>
                  {' '}
                  {template.EmailContent.From}
                  {' '}
                  { template.EmailContent.Display ? `(${template.EmailContent.Display})` : ''}
                </p>
                <p className="m-0">
                  <strong>Reply To:</strong>
                  {' '}
                  {template.EmailContent.ReplyTo}
                </p>
                <p
                  className={clsx('pt-3', !template.IsMarketing && 'fr-view')}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: template.EmailContent.Content
                      ? bin2string(template.EmailContent.Content)
                      : '',
                  }}
                />
                {template.EmailContent.Attachments && (
                  <>
                    <p className="m-0">
                      <strong>Attachments:</strong>
                    </p>
                    {template.EmailContent.Attachments.map(({ FileName, DocumentId }) => (
                      <p key={DocumentId}>{FileName}</p>
                    ))}
                  </>
                )}
              </Card.Body>
            </Card>
          )}
          {template.SMSContent && (
            <Card
              className={
                template.VoiceContent || template.EmailContent ? 'mt-4' : ''
              }
            >
              <Card.Header as="h6">
                <FontAwesomeIcon className="mr-1" icon="comment" />
                {' '}
                Text Message
              </Card.Header>
              <Card.Body>
                <pre>
                  {template.SMSContent.Content}
                </pre>
              </Card.Body>
            </Card>
          )}
        </>
      )}
    </Col>
  )
}
