import React, { ReactElement, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import log from 'lib/logging';
import { ITemplate } from 'types/IEvent';
import { getSelectedTemplate } from '../../reducers/Templates'
import Form from './components/EditCalendarEventForm'
import { RootState } from '../../types/rootState';

interface IProps {
  match: { params: { id: string } }
}

function CalendarEventDetailsPage({ match }: IProps): ReactElement {
  const dispatch = useDispatch()
  const templateId = match.params.id

  useEffect(() => {
    dispatch(getSelectedTemplate(templateId))
  }, [dispatch, templateId])

  const onSubmit = (data: ITemplate): void => {
    log.info(data)
  }

  const template: ITemplate | undefined = useSelector((state: RootState) => state.Templates.selectedTemplate)

  return (
    <div>
      <div className="ContentShow">
        <Helmet>
          <title>Edit Event Details</title>
        </Helmet>
        <div className="container">
          <div className="header-bar">
            <h1>Edit Event Details</h1>
            <div className="actions">
              <strong>Template Name:</strong>
              {' '}
              {template?.Name}
            </div>
          </div>
          <Form data={template} onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  )
}

export default CalendarEventDetailsPage
