import { Input } from 'components/FormControls';
import React, { ReactElement, useState, useEffect } from 'react';

import {
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import API from 'api/API';
import { useHistory } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { RootState } from '../../types/rootState';

interface IMfa {
  code1: string
  code2: string
}

export default function CreateMfa():ReactElement {
  return (
    <MfaComponent />
  );
}

function MfaComponent(): ReactElement {
  const {
    handleSubmit, errors, control,
  } = useForm<IMfa>();
  const history = useHistory();

  const { startPath, email, loading: userInfoLoading } = useSelector((state: RootState) => state.UserInfo);

  const [key, setKey] = useState();
  const [errorState, setErrorState] = useState<string>(null);
  const [loadingState, setLoadingState] = useState<string>();

  useEffect(() => {
    const getSecret = async () => {
      try {
        setLoadingState('loading');
        const results = await API.get('/api/v2/user/mfa');
        setKey(results.data);
        setLoadingState('done');
      } catch (e) {
        setLoadingState('hasMfa');
      }
    };
    getSecret();
  }, []);

  const onSubmit = async (values) => {
    try {
      await API.post('/api/v2/user/mfa/create', { key, code1: values.code1, code2: values.code2 });
      history.push(startPath);
    } catch (e) {
      setErrorState('Please enter the correct pin')
    }
  };

  const renderTitle = () => {
    if (errorState) {
      return (
        <h4
          className="danger"
        >
          {errorState}
        </h4>
      );
    }
    return (
      <h4>Enter two secret codes</h4>
    )
  }

  if (loadingState === 'loading' || userInfoLoading) {
    return null;
  }

  if (loadingState === 'hasMfa') {
    return (<>This user has set MFA up already</>)
  }

  return (
    <Container>
      <Row>
        <Col className="pb-2 d-flex justify-content-center">
          <h5>
            Scan this QR code with your authenticator application
          </h5>
        </Col>
      </Row>
      <Row>
        <Col className="pb-2 d-flex justify-content-center" />
      </Row>
      <Row>
        <Col className="pb-2 d-flex justify-content-center">
          <QRCode value={`otpauth://totp/Voicefriend (${email})?secret=${key}&amp;issuer=VoiceFriend`} />

        </Col>
      </Row>

      <Row className="d-flex justify-content-center">
        <Col className="pb-2 d-flex justify-content-center">
          {renderTitle()}
        </Col>
      </Row>
      <Row>
        <Col className="pb-2 d-flex justify-content-center">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Input
                id="code1"
                name="code1"
                type="text"
                label="Code 1"
                control={control}
                rules={{ required: 'Required' }}
                errors={errors?.code1?.message}
                autoComplete="off"
              />
              <Input
                id="code2"
                name="code2"
                type="text"
                label="Code 2"
                control={control}
                rules={{ required: 'Required' }}
                errors={errors?.code2?.message}
                autoComplete="off"
              />
            </Form.Group>
            <button className="btn btn-primary" type="submit">Verify Codes</button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
