import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactTags from 'react-tag-autocomplete';
import { toast } from 'react-toastify';
import { getTagsForPatient, deleteTag, createTag } from 'api/DischargedPatientsAPI';
import useTags from 'hooks/useTags';
import { IPatientFormData } from 'types/IPatientFormData';

interface IProps {
  data?: IPatientFormData
}

export function TagInterface({ data }: IProps): React.ReactElement {
  const [tags, setTags] = useState<string[]>([]);

  const availableTags = useTags();

  const dischargedPatientId = data.dischargedPatient.DischargePatientId;

  useEffect(() => {
    (async () => {
      setTags((await getTagsForPatient(dischargedPatientId)).data);
    })();
  }, [dischargedPatientId]);

  const onAdd = useCallback(async (tag: { name: string }) => {
    try {
      await createTag(dischargedPatientId, tag.name);
      setTags(
        [].concat(tags, tag.name),
      );
      availableTags.refreshTags();
    } catch (e) {
      toast.error(e.response.statusText);
    }
  }, [availableTags, dischargedPatientId, tags]);

  const [modalValue, setShowModal] = useState<number | undefined>(undefined);
  const showModal = modalValue !== undefined;
  const handleModalClose = () => {
    setShowModal(undefined);
  };

  const handleModalDelete = async () => {
    try {
      if (showModal) {
        const tag = tags[modalValue];
        await deleteTag(dischargedPatientId, tag);
        const tagsInternal = tags.splice(0);
        tagsInternal.splice(modalValue, 1);
        setTags(tagsInternal);
        setShowModal(undefined);
      }
    } catch (e) {
      toast.error(e.response.statusText);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
      >
        <Modal.Header>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This will remove the tag named
          {' '}
          {tags[modalValue]}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleModalDelete}>
            Remove
            {' '}
            {tags[modalValue]}
            ?
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="form-group">
        <label htmlFor="react-tags">
          Tags
        </label>
        <ReactTags
          id="react-tags"
          minQueryLength={0}
          allowBackspace={false}
          className="form-input"
          maxSuggestionsLength={20}
          tags={tags.map((x) => ({ name: x }))}
          suggestions={availableTags.tags.filter((tag) => !tags.includes(tag)).map((x) => ({ name: x }))}
          onDelete={(i) => {
            setShowModal(i);
          }}
          onAddition={(tag) => {
            onAdd(tag);
          }}
        />
      </div>
    </>
  );
}
