import React, { FC, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, Button } from 'react-bootstrap';

import log from 'lib/logging';
import {
  selectById,
  selectFarewell,
  selectByIdLoading,
  selectItemIndexLoading,
} from 'reducers/SurveyQuestionList';
import { getAllLibraryContent, getLibraryIsLoading } from 'reducers/LibraryContent';
import { ILibraryContent } from 'types/ILibraryContent';
import { EditItemModal } from './EditItemModal';

export const FarewellCard: FC = () => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const questionListData = useSelector(selectById);
  const questionListDataLoading = useSelector(selectByIdLoading);

  const libraryData: ILibraryContent[] = useSelector(getAllLibraryContent);
  const libraryDataLoading: boolean = useSelector(getLibraryIsLoading);

  const questionListItemsLoading = useSelector(selectItemIndexLoading);

  const isLoading = libraryDataLoading || questionListDataLoading;

  const farewell = useSelector(selectFarewell);
  const [voiceContent, smsContent] = useMemo(() => {
    if (questionListItemsLoading) {
      return ['Loading...', 'Loading...'];
    }

    if (!farewell) {
      return ['', ''];
    }

    const sms = farewell.SMSContent ?? '';

    if (farewell.VoiceContent) {
      return [farewell.VoiceContent, sms];
    }

    if (isLoading) {
      return ['Loading...', sms];
    }

    if (!questionListData) {
      return ['', sms];
    }

    const found = libraryData.find((item) => +item.ID === farewell.ContentId);

    if (!found) {
      log.error('SurveyQuestionListItem not found in library');
      return ['', sms];
    }

    return [found.VoiceContent?.Content ?? '', sms];
  }, [farewell, isLoading, libraryData, questionListData, questionListItemsLoading]);
  return (
    <>
      <Card>
        <Card.Header as="h4">
          Farewell
        </Card.Header>
        <Card.Body className="d-flex justify-content-between">
          <div>
            <h6 className="h6 mb-0">Voice Content</h6>
            <p className="mb-2">{voiceContent || '\u00A0'}</p>
            <h6 className="h6 mb-0">SMS Content</h6>
            <p className="mb-2">{smsContent || '\u00A0'}</p>
          </div>
          <Button
            type="button"
            variant="link"
            className="p-0"
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            Edit
          </Button>
        </Card.Body>
      </Card>
      <EditItemModal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(null)
        }}
        item={farewell}
      />
    </>
  )
}
