import { IOAuthResult } from 'reducers/IUserInfoState';
import { ICaremergeConfiguration } from 'types/ICaremergeConfig';
import API from './API';

export const API_PATH = '/api/v2/integration';
export const API_CAREMERGE_USER_TOKEN = '/api/v2/caremerge/user/token';

export const CAREMERGE_REDIRECT_URL_QA = 'https://api.goicon.dev/vf/login';
export const CAREMERGE_REDIRECT_URL_PROD = 'https://api.goicon.com/vf/login';

export async function associateCaremergeUser(
  caremergeJwt: string,
  username: string,
  password: string,
): Promise<void> {
  await API.post<void>(`${API_PATH}/associate/user`, {
    CaremergeJwt: caremergeJwt,
    Email: username,
    Password: password,
  });
}

export async function getCaremergeVFJwt(): Promise<string> {
  const response = await API.get<string>(API_CAREMERGE_USER_TOKEN);
  return response.data;
}

export async function getCaremergeConfig(): Promise<ICaremergeConfiguration> {
  const response = await API.get<ICaremergeConfiguration>('/api/v2/caremerge/config');
  return response.data;
}

export async function exchangeCaremergeToken(
  caremergeJwt: string,
): Promise<IOAuthResult> {
  const response = await API.post<IOAuthResult>('api/auth/caremerge/exchange', {
    caremergeJwt,
  });
  if (response.status !== 200) {
    throw response;
  }
  return response.data;
}
