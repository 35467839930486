import React from 'react';
import clsx from 'clsx';

import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { useHasFeatures } from 'hooks/useHasFeatures';
import { useCurrentAccount } from './hooks/useCurrentAccount';

export const UnreadMessageCount: React.FC<{ className?: string }> = ({ className, ...props }) => {
  const canReadInbox = useHasPermissions('Inbox:Read');
  const { hasFeature: hasSmsInboxFeature } = useHasFeatures('sms-inbox');
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const currentAccount = useCurrentAccount();

  const canViewInbox = (canReadInbox || isAdmin || isSuper) && hasSmsInboxFeature;

  if (!canViewInbox || !currentAccount?.UnreadConversationCount) {
    return null;
  }

  return (
    <div className={clsx('inboxUnreadMessageCount', className)} {...props}>
      {currentAccount.UnreadConversationCount > 99
        ? '99+'
        : currentAccount.UnreadConversationCount}
    </div>
  )
}
