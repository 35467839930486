import React, { ReactElement } from 'react';
import useSWR from 'swr';
import { Spinner } from 'react-bootstrap';
import * as PbiApi from 'api/PowerBiApi';
import PbiReportEmbed from 'components/PowerBi/PbiReportEmbed';
import { useParams } from 'react-router-dom';

const fetchReportData = async (formId: string) => {
  if (!formId) return null;
  const response = await PbiApi.getBroadcastFormReport(formId, {
    ignoreGlobalCatch: true,
  });
  return response;
};

const BroadcastFormReportPage = (): ReactElement => {
  const { formId } = useParams<{ formId?: string }>();

  const { data: reportEmbedInfo, error, isLoading } = useSWR(
    ['broadcastFormEmbeddedReport', formId],
    () => fetchReportData(formId),
    {
      shouldRetryOnError: false,
    },
  );

  if (!formId || isLoading) {
    return (
      <div className="loading-text d-flex align-items-center">
        <Spinner animation="border" variant="primary" role="status" className="mr-3" />
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="loading-text d-flex align-items-center">
        {error?.response?.data?.Message || 'An error has occurred'}
      </div>
    )
  }

  if (!reportEmbedInfo) {
    return <h2>Unable to get report data.</h2>;
  }

  const { EmbedToken, EmbedUrl } = reportEmbedInfo;

  return (
    <div>
      <PbiReportEmbed EmbedToken={EmbedToken} EmbedUrl={EmbedUrl} />
    </div>
  );
};

export default BroadcastFormReportPage;
