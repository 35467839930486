export function atou(b64: string): string {
  return decodeURIComponent(escape(atob(b64)));
}
export function utoa(data: string): string {
  return btoa(unescape(encodeURIComponent(data)));
}
export async function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result?.toString().replace(/^data:(.*,)?/, '');
      if (encoded && encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
}

export function bin2string(array: string): string {
  let result = '';
  const decoded = atou(array);
  for (let i = 0; i < decoded.length; ++i) {
    result += decoded.charAt(i);
  }
  return result;
}

export function string2bin(myString: string): string {
  let result = '';
  const encoded = utoa(myString);
  for (let i = 0; i < encoded.length; ++i) {
    result += encoded.charAt(i);
  }
  return result;
}
