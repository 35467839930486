import React, {
  ReactElement, useCallback, useState, useMemo, useEffect,
} from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns/esm';
import { useHistory } from 'react-router-dom';

import { EventTemplateForm as EventStepper } from 'components/EventTemplateForm/EventTemplateForm';
import { EventSchedulerForm as EventScheduler } from 'components/EventScheduler';
import { INITIAL_STATE, createEvent } from 'reducers/Events';
import { getUserState, selectInputLanguageDefaultValue } from 'reducers/UserInfo';
import {
  IEvent, EventType, ScheduleMode, EventTemplateMode,
} from 'types/IEvent';

export default function SurveyCreatePage(): ReactElement {
  const [showStepper, setShowStepper] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserState);
  const defaultInputLanguage = useSelector(selectInputLanguageDefaultValue);

  const initialData: IEvent = useMemo(() => ({
    ...INITIAL_STATE.selectedEvent,
    ID: undefined,
    EventType: EventType.SURVEY,
    StartDate: format(new Date(), 'yyyy-MM-dd'),
    SendTime: moment().tz(userInfo.accountTimezone).add(5, 'minutes').format('HH:mm'),
    SendMode: ScheduleMode.Once,
    RRule: '',
    ExpirationTime: null,
    SourceLanguage: defaultInputLanguage,
  }), [userInfo.accountTimezone]);

  const [data, setData] = useState(initialData);

  useEffect(() => {
    setData((oldData) => ({
      ...oldData,
      SendTime: moment().tz(userInfo.accountTimezone).add(5, 'minutes').format('HH:mm'),
    }));
  }, [userInfo.accountTimezone]);

  const saveData = useCallback((newData: Partial<IEvent>) => {
    setData((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }, [setData]);

  const handleSubmitStepper = useCallback((formData) => {
    const stepperData: Partial<IEvent> = {
      ...formData,
    };

    if (formData.VoiceContent) {
      const VoiceContent = {
        ...formData.VoiceContent,
        SurveyResponses: formData.VoiceContent?.SurveyResponses?.map((item, index) => ({
          Response: item.Response,
          Keypress: index === 9 ? 0 : index + 1,
        })).filter((item) => item.Response)
           ?? [],
      }
      Object.assign(stepperData, { VoiceContent });
    }

    setShowStepper(false);
    saveData(stepperData);
  }, [saveData]);

  const handleSubmitScheduler = useCallback((formData) => {
    const surveyEvent: IEvent = {
      ...data,
      ...formData,
    }

    dispatch(createEvent(surveyEvent, () => {
      toast.success('Survey successfully created');
      history.push('/events/survey');
    }));
  }, [data, dispatch, history]);

  return (
    <div className="TemplatesEdit">
      <Helmet>
        <title>Create Survey</title>
      </Helmet>
      <div className="header-bar">
        <h1>Create Survey</h1>
      </div>
      {showStepper ? (
        <EventStepper
          isNew
          data={data}
          eventTemplateMode={EventTemplateMode.Event}
          allowPreview={false}
          onSubmit={handleSubmitStepper}
          onCancel={() => history.push('/events/survey')}
        />
      ) : (
        <EventScheduler
          data={data}
          onSubmit={handleSubmitScheduler}
        />
      )}
    </div>
  );
}
