import React, { ReactElement, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

interface IProps {
  initialPageSize: number
  onSetPageSize: (size: number) => void
}

const supportedPageSize = [10, 20, 30, 50, 100];

function PageSizePicker({
  initialPageSize,
  onSetPageSize,
}: IProps): ReactElement {
  const [pageSize, setPageSize] = useState<number>(initialPageSize);

  return (
    <div className="page-size-picker">
      <span className="page-size-picker__text">Page Size</span>
      <Dropdown>
        <Dropdown.Menu popperConfig={
          {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -2],
                },
              }],
          }
        }
        >
          {supportedPageSize.map(((size) => (
            <Dropdown.Item
              key={size.toString()}
              onClick={() => {
                onSetPageSize(size)
                setPageSize(size)
              }}
            >
              {size}
            </Dropdown.Item>
          )))}
        </Dropdown.Menu>
        <Dropdown.Toggle id="pagesizeToggle">
          {pageSize}
        </Dropdown.Toggle>
      </Dropdown>
    </div>
  )
}
export default PageSizePicker;
