import React, { ReactElement } from 'react';

import { Controller } from 'react-hook-form';

import ControlledDateInput, { DateInputProps } from '../Inputs/DateInput'
import { IFormControlProps } from './IFormControlProps';

interface IProps extends IFormControlProps, Omit<DateInputProps, 'defaultValue' | 'id' | 'name' > {}

function DateInput({
  name, defaultValue = '', control, rules, ...props
}: IProps): ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={(renderProps) => (
        <ControlledDateInput {...renderProps} {...props} />
      )}
    />
  );
}

export default DateInput;
