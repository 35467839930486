const STORAGE_KEY = 'vf-nav';

class PersistNavItem {
  static setNavItem(item: string): void {
    localStorage.setItem(STORAGE_KEY, item);
  }

  static getNavItem(): string {
    const item = localStorage.getItem(STORAGE_KEY) || '';
    return item;
  }

  static destroyNavItem(): void {
    localStorage.removeItem(STORAGE_KEY);
  }
}

export default PersistNavItem;
