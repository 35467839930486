import React, {
  useCallback, ComponentProps, FC, MutableRefObject,
} from 'react';
import insertTextAtCursor from 'insert-text-at-cursor';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import SelectableContext from 'react-bootstrap/SelectableContext';

const mergeFields = {
  voice: [
    {
      name: 'First Name',
      tag: '$$FirstName$$',
    },
    {
      name: 'Last Name',
      tag: '$$LastName$$',
    },
    {
      name: 'Phone number',
      tag: '$$PhoneNumber$$',
    },
    {
      name: 'Email Address',
      tag: '$$EmailAddress$$',
    },
    {
      name: 'Mobile',
      tag: '$$Mobile$$',
    },
    {
      name: 'Location',
      tag: '$$Location$$',
    },
    {
      name: 'Date',
      tag: '$$Date$$',
    },
    {
      name: 'Community Name',
      tag: '$$CommunityName$$',
    },
  ],
  sms: [
    {
      name: 'First Name',
      tag: '$$FirstName$$',
    },
    {
      name: 'Last Name',
      tag: '$$LastName$$',
    },
    {
      name: 'Phone number',
      tag: '$$PhoneNumber$$',
    },
    {
      name: 'Email Address',
      tag: '$$EmailAddress$$',
    },
    {
      name: 'Mobile',
      tag: '$$Mobile$$',
    },
    {
      name: 'Location',
      tag: '$$Location$$',
    },
    {
      name: 'Date',
      tag: '$$Date$$',
    },
    {
      name: 'Community Name',
      tag: '$$CommunityName$$',
    },
  ],
  urlsurevysms: [
    {
      name: 'First Name',
      tag: '$$FirstName$$',
    },
    {
      name: 'Last Name',
      tag: '$$LastName$$',
    },
    {
      name: 'Phone number',
      tag: '$$PhoneNumber$$',
    },
    {
      name: 'Email Address',
      tag: '$$EmailAddress$$',
    },
    {
      name: 'Mobile',
      tag: '$$Mobile$$',
    },
    {
      name: 'Location',
      tag: '$$Location$$',
    },
    {
      name: 'Date',
      tag: '$$Date$$',
    },
    {
      name: 'Community Name',
      tag: '$$CommunityName$$',
    },
    {
      name: 'SURVEY URL',
      tag: '$$SURVEY_URL$$',
    },
  ],
};

type Props = ComponentProps<'textarea'> & {
  type: keyof typeof mergeFields
  dropdownId: string
  textbox: MutableRefObject<HTMLTextAreaElement|HTMLDivElement>
};

const MergeFields: FC<Props> = ({
  type,
  dropdownId,
  value,
  textbox,
  ...props
}) => {
  const callback = useCallback((event) => insertTextAtCursor(textbox.current, event), [textbox]);

  return (
    <SelectableContext.Provider
      value={callback}
    >
      <DropdownButton id={dropdownId} title="Personalization" variant="secondary" size="sm" className="right mb-2">
        {mergeFields[type].map(({ name, tag }) => (
          <Dropdown.Item key={tag} eventKey={tag}>
            {name}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </SelectableContext.Provider>
  )
}

export default MergeFields;
