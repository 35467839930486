import React from 'react';
import { Spinner } from 'react-bootstrap';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { useLocation, Link } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { CellProps } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { selectAccountTz } from 'reducers/UserInfo';
import PageSizePicker from 'components/PageSizePicker';
import Pagination from 'components/Pagination';
import Table from 'components/Table';
import { IBroadcastHistory } from 'types/IBroadcastHistory';
import { useBroadcastHistoryQuery } from './hooks/useBroadcastHistoryQuery';
import { ExportPdfModal } from './ExportPdfModal';

const formatDateSentCell = (acctTz: string) => (props: CellProps<Record<string, unknown>>) => {
  const { cell: { value } } = props;

  const localTime = moment
    .utc(value, 'YYYY-MM-DDTHH:mm:ss.SSS')
    .tz(acctTz);

  return localTime.format('MMM D, YYYY h:mm a');
}

export const BroadcastHistoryTable = () => {
  const { data, isLoading } = useBroadcastHistoryQuery();
  const [selectedRowIds, setSelectedRowIds] = React.useState<string[]>([]);
  const [showExportPdfModal, setShowExportPdfModal] = React.useState<boolean>(false);
  const [pageParam = 1, setPageParam] = useQueryParam('page', NumberParam);
  const [perPageParam = 10, setPerPageParam] = useQueryParam('perpage', NumberParam);
  const [sortFieldParam, setSortFieldParam] = useQueryParam('sortField', StringParam);
  const [sortDirectionParam, setSortDirectionParam] = useQueryParam('sortDirection', StringParam);
  const location = useLocation();
  const accountTz = useSelector(selectAccountTz);

  useUpdateEffect(() => {
    setSelectedRowIds([]);
  }, [location.search])

  const columns = [
    { Header: 'Name', accessor: 'Name' },
    { Header: 'Event ID', accessor: 'EventId' },
    { Header: 'Instance ID', accessor: 'EventInstanceId' },
    { Header: 'Recipients', accessor: 'Recipients' },
    {
      Header: 'Date Sent', accessor: 'SendDateTimeUtc', Cell: formatDateSentCell(accountTz), sortable: true,
    },
  ]

  const items = data?.Data ?? [];

  const rowActionItems = (entityId: string, entity: IBroadcastHistory) => {
    const localTime = moment
      .utc(entity.SendDateTimeUtc, 'YYYY-MM-DDTHH:mm:ss.SSS')
      .tz(accountTz);

    return (
      <Link to={`/events/${entity.EventId}/content/${localTime.format('YYYY-MM-DD')}`}>
        View summary
      </Link>
    )
  }

  const eventsToExport = React.useMemo(() => {
    if (!selectedRowIds.length) {
      return null;
    }

    return selectedRowIds.reduce((acc, instanceId) => {
      const found = items.find((item) => item.EventInstanceId === +instanceId);

      if (!found) {
        return acc;
      }

      const foundLocalDate = moment
        .utc(found.SendDateTimeUtc, 'YYYY-MM-DDTHH:mm:ss.SSS')
        .tz(accountTz);

      return [
        ...acc, {
          ...found,
          ocurrenceDate: foundLocalDate.format('YYYY-MM-DD'),
        },
      ];
    }, [])
  }, [selectedRowIds, items, accountTz]);

  return (
    <>
      <div className="d-flex justify-content-end mt-2">
        <div className="itemCount">
          {isLoading
            ? (
              <div className="loading-text d-flex align-items-center">
                <Spinner
                  animation="border"
                  variant="primary"
                  role="status"
                  className="mr-3"
                />
                Loading...
              </div>
            ) : (
              <>
                {data?.Pagination?.TotalItems ?? 0}
                {' '}
                Item(s) In Total
              </>
            )}
        </div>
        {!!selectedRowIds.length && (
          <button
            type="button"
            className="broadcast-history__download-button btn btn-secondary btn-sm"
            onClick={() => {
              setShowExportPdfModal(true);
            }}
          >
            <FontAwesomeIcon icon="file-export" className="mr-2" color="var(--blue)" />
            Export as PDF
          </button>
        )}
      </div>
      <Table
        columns={columns}
        data={items}
        rowActionItems={rowActionItems}
        rowIDAccessor="EventInstanceId"
        selectedRowIds={selectedRowIds}
        onRowSelect={(selectedIds) => {
          setSelectedRowIds(selectedIds);
        }}
        isLoading={isLoading}
        onColumnSort={(fieldName, direction) => {
          if (fieldName.length > 0) {
            setSortFieldParam(fieldName);
            setSortDirectionParam(direction);
          } else {
            setSortFieldParam('');
            setSortDirectionParam('');
          }
        }}
        sortedColumn={sortFieldParam}
        sortedColumnDirection={sortDirectionParam}
        className="Template-Table"
      />

      <div className="paging">
        <PageSizePicker
          initialPageSize={perPageParam}
          onSetPageSize={(size) => {
            setPageParam(1, 'replaceIn');
            setPerPageParam(size);
          }}
        />

        <Pagination
          currentPage={pageParam}
          totalPages={data?.Pagination?.TotalPages ?? 1}
          onPageChange={(newPage: number) => setPageParam(newPage, 'pushIn')}
          pageDelta={5}
        />
      </div>
      <ExportPdfModal
        show={showExportPdfModal}
        onHide={() => {
          setShowExportPdfModal(false);
        }}
        events={eventsToExport}
      />
    </>

  )
}
