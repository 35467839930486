import React, { ChangeEvent, forwardRef, Ref } from 'react';

import Input from './Input';

interface IProps {
  name: string
  label: string
  errors?: string
  value?: string
  className?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const TimeInput = forwardRef(
  ({
    name, label, errors, value, className, onChange,
  }: IProps, ref: Ref<HTMLInputElement>) => {
    const fallbackMask = (): string | undefined => {
      if (window.Modernizr.inputtypes.time) return undefined;
      return '99:99';
    };
    const fallbackLabel = fallbackMask() ? `${label} (24 hour)` : label;

    return (
      <Input
        className={className}
        name={name}
        label={fallbackLabel}
        type="time"
        value={value}
        errors={errors}
        mask={fallbackMask()}
        onChange={onChange}
        ref={ref}
      />
    );
  },
);

export default TimeInput;
