import { updateQueryStringParameter } from 'lib/formatters/url';
import React, { FC } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getLoggedInStatus } from '../reducers/UserInfo';

interface IProps {
  tabName: string
  url: string
  tabKey: string
  scrolling?: boolean
  visible: boolean
}

export const IFrameTabs: FC<{ params: IProps[] }> = ({ params }) => {
  const loggedInStatus = useSelector(getLoggedInStatus)
  if (!loggedInStatus) {
    return <Redirect to="/login" />;
  }
  const defaultTabKey = params?.filter((param) => param.visible)[0]?.tabKey;
  const renderTab = (param: IProps) => {
    if (param.visible) {
      return (
        <Tab eventKey={param.tabKey} key={param.tabKey} title={param.tabName} style={{ height: '768px' }}>
          <iframe
            scrolling={param.scrolling ? 'yes' : 'no'}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="legacy app"
            src={updateQueryStringParameter(param.url, 'IFrame', 'true')}
          />
        </Tab>
      )
    }
    return null
  }

  return (
    <Tabs defaultActiveKey={params ? defaultTabKey : ''} id="iFrameTabs" className="mb-3">
      {params && (
        params.map((param) => (
          renderTab(param)),
        )
      )}
    </Tabs>
  );
};
