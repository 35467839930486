import React, { FC, useState, useEffect } from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import {
  getDischargedPatientConfig, selectDischargedPatientConfigLoading, getAllTags,
} from 'reducers/DischargedPatients';
import { getSurveyQuestionListAll } from 'reducers/SurveyQuestionList';
import { InfoForm, StatesForm, ScheduleForm } from './components/forms';

const DischargedPatient: FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [readMode, setReadMode] = useState(true);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectDischargedPatientConfigLoading);

  useEffect(() => {
    dispatch(getDischargedPatientConfig());
    dispatch(getAllTags());
    dispatch(getSurveyQuestionListAll());
  }, [dispatch]);

  const onCancel = () => {
    setReadMode(true);
  }

  return isLoading ? (
    <div className="d-flex justify-content-center align-center w-100 h-100 ">
      <Spinner animation="border" role="status" variant="primary">
        <span className="sr-only d-flex justify-content-center align-center ">
          Loading...
        </span>
      </Spinner>
    </div>
  ) : (
    <>
      <div className="d-flex justify-content-between">
        <h1 className="h1 mb-4">
          Discharged Patient Configuration
        </h1>
        {readMode && (
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              setReadMode(false);
            }}
          >
            Edit
          </button>
        )}
      </div>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => {
          setTabIndex(index);
        }}
      >
        <TabList>
          <Tab>Info</Tab>
          <Tab>States</Tab>
          <Tab key={0}>
            Schedule
          </Tab>
        </TabList>
        <TabPanel>
          <InfoForm readMode={readMode} onCancel={onCancel} />
        </TabPanel>
        <TabPanel>
          <StatesForm readMode={readMode} onCancel={onCancel} />
        </TabPanel>
        <TabPanel>
          <ScheduleForm readMode={readMode} onCancel={onCancel} />
        </TabPanel>
      </Tabs>
    </>

  );
};

export default DischargedPatient;
