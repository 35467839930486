import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { toast } from 'react-toastify';

import { ITemplateExtendo, ITemplate } from 'types/IEvent';
import { saveTemplate } from 'reducers/Templates';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import { TemplateTransformer } from 'transformers/Template';

/**
 * @desc handles creating and updating template from event template form
 */
export const useSaveTemplate = () => {
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();
  const history = useHistory();
  const { type: urlType } = useParams<{ type?: string }>();
  const isMarketing = history.location.pathname.startsWith('/marketing-templates');

  const templateType = urlType || 'template';

  return async (formData: ITemplateExtendo) => {
    segmentAnalyticsTrack(formData.ID
      ? trackActions.updateTemplate(formData)
      : trackActions.createTemplate(formData),
    );
    const submitData = TemplateTransformer.toAPI(formData);

    const onSuccess = async (receivedTemplate: ITemplate) => {
      await mutate(
        ['template', receivedTemplate.ID],
        receivedTemplate,
        {
          revalidate: false,
          populateCache: true,
        },
      );

      if (formData.submit === 'schedule') {
        history.push(
          '/events/create',
          {
            sourceTemplateId: receivedTemplate.ID,
            sourceTemplateType: receivedTemplate.Type,
          },
        );
        return;
      }

      let toastMessage;
      let redirectUrl;

      if (urlType === 'transactional') {
        toastMessage = 'Transactional successfully updated';
        redirectUrl = '/events/automatic';
      } else if (formData.submit === 'save-as-draft') {
        toastMessage = 'Draft successfully saved';
        redirectUrl = `${isMarketing
          ? '/marketing-templates'
          : '/templates'}?isDraft=1`;
      } else if (isMarketing) {
        toastMessage = 'Marketing Email successfully updated';
        redirectUrl = '/marketing-templates';
      } else {
        toastMessage = 'Template successfully updated';
        redirectUrl = '/templates';
      }

      toast.success(toastMessage);
      history.push(redirectUrl);
    }

    const onError = (errorMessage) => {
      toast.error(
        errorMessage,
        errorMessage.includes('•')
          ? { className: 'list-toast' }
          : undefined,
      );
    }

    dispatch(
      saveTemplate(
        submitData,
        templateType,
        onSuccess,
        onError,
      ));
  }
}
