import React, {
  ButtonHTMLAttributes, FC, useState, useMemo, useRef,
} from 'react';
import Overlay, { Placement } from 'react-bootstrap/Overlay'
import Spinner from 'react-bootstrap/Spinner';
import clsx from 'clsx';
import useLifecycles from 'react-use/lib/useLifecycles';
import useDebounce from 'react-use/lib/useDebounce';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import usePromise from 'react-use/lib/usePromise';
import _omit from 'lodash/omit';

import Input from 'components/Inputs/Input';
import { ReactComponent as EmojiSvg } from 'styles/images/emoji-pick.svg';
import { ReactComponent as SearchSvg } from 'styles/images/loupe.svg';

interface EmojiPopupProps {
  onPick: (emoji: string) => void
  placement?: Placement
  show: boolean
  onHide: () => void
  target: HTMLButtonElement|null
}

const EmojiPopup: FC<EmojiPopupProps> = ({
  show, onPick, placement, onHide, target,
}) => {
  const [emojiList, setEmojiList] = useState<any[]|null>(null);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const inputRef = useRef<HTMLInputElement|null>(null);

  const mounted = usePromise();

  const [, cancel] = useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    200,
    [search],
  );

  useLifecycles(cancel, cancel);

  useUpdateEffect(() => {
    if (!show) {
      return;
    }
    inputRef.current?.focus({ preventScroll: true });
    setSearch('');
    setDebouncedSearch('');
    if (!emojiList) {
      const importEmojiList = async () => {
        const emojiBase = await mounted(import('emojibase-data/en/compact.json'));
        setEmojiList(emojiBase.default
          .reduce((acc, item) => {
            if (typeof item.group === 'undefined') {
              return acc;
            }

            return [
              ...acc,
              {
                ...item,
                tags: [
                  ...item.tags,
                  ...item.label.split(/\W+/).filter(Boolean),
                ],
              },
            ]
          }, []));
      }

      importEmojiList();
    }
  }, [show]);

  const filteredEmojis = useMemo(() => {
    if (!debouncedSearch || !emojiList) {
      return emojiList;
    }

    const parsedSearch = debouncedSearch.trim();
    const re = new RegExp(`^${parsedSearch}`, 'i');

    return emojiList.filter((item) => item.tags.some((tag: string) => re.test(tag)))
  }, [debouncedSearch, emojiList])

  return (
    <Overlay
      popperConfig={
        {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 2],
              },
            }],
        }
      }
      show={show}
      placement={placement}
      onHide={onHide}
      rootClose
      target={target}
    >
      {(props) => (
        <div
          className="emoji-picker__popup"
          {..._omit(props, ['placement', 'arrowProps', 'show', 'popper'])}
        >
          <Input
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className="emoji-picker__popup__input"
            icon={SearchSvg}
            placeholder="Search..."
            ref={inputRef}
          />
          <div className="emoji-picker__popup__items">
            {
              filteredEmojis?.map((emoji) => (
                <button
                  type="button"
                  key={emoji.hexcode}
                  onClick={() => {
                    onPick(emoji.unicode);
                    onHide();
                  }}
                  className="emoji-picker__popup__item"
                >
                  {emoji.unicode}
                </button>
              )) ?? (
                <Spinner
                  animation="border"
                  variant="primary"
                  role="status"
                  size="sm"
                  className="emoji-picker__popup__loading"
                />
              )
            }
          </div>

        </div>
      )}
    </Overlay>
  )
}

type EmojiButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

interface EmojiPickerProps extends EmojiButtonProps, Pick<EmojiPopupProps, 'onPick'|'placement'> {

}

export const EmojiPicker: FC<EmojiPickerProps> = ({
  placement = 'top-end', onPick, className, ...props
}) => {
  const [show, setShow] = useState(false);
  const target = useRef<HTMLButtonElement|null>(null);

  return (
    <>
      <button
        type="button"
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        onClick={() => setShow(!show)}
        className={clsx('emoji-picker__button', className)}
        ref={target}
        {...props}
      >
        <EmojiSvg className={show ? 'active' : ''} />
      </button>

      <EmojiPopup
        show={show}
        target={target.current}
        placement={placement}
        onHide={() => {
          setShow(false);
        }}
        onPick={onPick}
      />
    </>
  )
}
