import React, {
  useReducer, FC, ReactElement, useState,
  useEffect,
} from 'react';
import {
  Form, Row, Col, OverlayTrigger, Popover, Button,
} from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { EventTemplateMode, TemplateOrEvent } from 'types/IEvent';
import { TemplateContentVoice } from './EventTemplateVoice';
import { TemplateContentEmail } from './EventTemplateEmail';
import { TemplateContentSms } from './EventTemplateSms';
import { GenerateContentModal } from './GenerateContentModal';
import { LanguageSelect } from './LanguageSelect';

type Props = {
  isNew?: boolean
  removeVoice?: () => void
  removeSms?: () => void
  removeEmail?: () => void
  eventTemplateMode?: EventTemplateMode
  isSurvey?: boolean
  isUrlLinkSurvey?: boolean
  data?: TemplateOrEvent
  enableGenerateSmartContent?: boolean
};

type ChannelSelectedCheckboxProps = {
  onToggleCheck: () => void
  channelName: string
  checked: boolean
  checkName: string
};

const URL_LINK_SURVEY_CONTENT = 'Hi $$FirstName$$, please take our survey: $$SURVEY_URL$$';

export const ChannelSelectedCheckbox: FC<ChannelSelectedCheckboxProps> = ({
  checked, channelName, onToggleCheck, checkName,
}) => {
  const { register, getValues, trigger } = useFormContext();

  const validateChannelSelected = () => {
    if (getValues('voiceSelected') || getValues('smsSelected') || getValues('emailSelected')) {
      return true;
    }
    return false
  }

  return (
    <Form.Check
      ref={register({
        required: false,
        validate: () => validateChannelSelected(),
      })}
      className="d-none"
      name={checkName}
      type="checkbox"
      label={channelName}
      inline
      defaultChecked={checked}
      onChange={() => {
        trigger(['voiceSelected', 'smsSelected', 'emailSelected'])
        onToggleCheck();
      }}
    />
  );
};

type AddChannelButtonProps = Pick<ChannelSelectedCheckboxProps, 'checkName'|'channelName'>

const AddChannelButton: FC<AddChannelButtonProps> = ({ children, channelName, checkName }) => {
  const { setValue } = useFormContext();

  return (
    <button
      type="button"
      id={`add-content-${channelName}`}
      className="btn btn-link"
      onClick={() => { setValue(checkName, true) }}
    >
      {children}
    </button>
  )
}

type TCheckboxToggleButton = {
  label: string | ReactElement
  checkName: string
  removeChannelContent?: () => void
  id: string
};

const CheckboxToggleButton: FC<TCheckboxToggleButton> = ({
  label, checkName, removeChannelContent, id,
}) => {
  const { setValue, watch, trigger } = useFormContext();
  const checked = watch(checkName);

  const [showPopover, togglePopover] = useReducer((state) => !state, false);
  const popoverContent = (
    <Popover id={id} onClick={(event) => event.stopPropagation()}>
      <Popover.Content>
        All changes on this section will be lost.
        <div className="d-flex justify-content-between pt-2">
          <Button
            variant="outline-danger"
            onClick={() => {
              togglePopover()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              removeChannelContent();
              togglePopover();
              setValue(checkName, !checked);
              trigger(['voiceSelected', 'smsSelected', 'emailSelected'])
            }}
          >
            Remove
          </Button>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      show={showPopover}
      overlay={popoverContent}
    >
      <button
        type="button"
        className="btn btn-link"
        onClick={() => {
          if (checked) {
            togglePopover();
          }
        }}
      >
        {label}
      </button>
    </OverlayTrigger>
  );
}

export const CommunicationPreferences: FC<Props> = ({
  isNew, removeVoice, removeSms, removeEmail, eventTemplateMode, isSurvey, isUrlLinkSurvey, data, enableGenerateSmartContent,
}) => {
  const [showGenerateModal, setShowGenerateModal] = useState<'voice'|'sms'|'email'|false>(false);
  const { watch, setValue } = useFormContext();
  const isVoiceSelected = Boolean(watch('voiceSelected'));
  const isSmsSelected = Boolean(watch('smsSelected'));
  const isEmailSelected = Boolean(watch('emailSelected'));

  useEffect(() => {
    if (isSmsSelected && isUrlLinkSurvey && !data.SMSContent?.Content) {
      setValue('SMSContent.Content', URL_LINK_SURVEY_CONTENT);
    }
    if (isEmailSelected && isUrlLinkSurvey && !data.EmailContent?.Content) {
      setValue('EmailContent.Content', btoa(URL_LINK_SURVEY_CONTENT));
    }
  }, [isSmsSelected, isEmailSelected, isUrlLinkSurvey]);

  return (
    <>
      <ChannelSelectedCheckbox
        channelName="Voice"
        checkName="voiceSelected"
        checked={isVoiceSelected}
        onToggleCheck={() => setValue('voiceSelected', !isVoiceSelected, { shouldDirty: true })}
      />
      <ChannelSelectedCheckbox
        channelName="SMS"
        checkName="smsSelected"
        checked={isSmsSelected}
        onToggleCheck={() => setValue('smsSelected', !isSmsSelected, { shouldDirty: true })}
      />
      <ChannelSelectedCheckbox
        channelName="Email"
        checkName="emailSelected"
        checked={isEmailSelected}
        onToggleCheck={() => setValue('emailSelected', !isEmailSelected, { shouldDirty: true })}
      />
      {eventTemplateMode !== EventTemplateMode.Instance && (
        <Row>
          <Col className="commPrefCol">
            {!isUrlLinkSurvey && !isVoiceSelected && (
              <AddChannelButton
                channelName="Voice"
                checkName="voiceSelected"
              >
                Add Voice content
              </AddChannelButton>
            )}

            {!isSurvey && (
              <>
                {!isSmsSelected && (
                  <AddChannelButton
                    channelName="SMS"
                    checkName="smsSelected"
                  >
                    Add Text Message content
                  </AddChannelButton>
                )}

                {!isEmailSelected && (
                  <AddChannelButton
                    channelName="Email"
                    checkName="emailSelected"
                  >
                    Add Email content
                  </AddChannelButton>
                )}

              </>
            )}

            <LanguageSelect />
          </Col>
        </Row>
      )}

      {isVoiceSelected && (
        <TemplateContentVoice
          setShowGenerateModal={enableGenerateSmartContent ? setShowGenerateModal : undefined}
          removeButton={eventTemplateMode === EventTemplateMode.Instance ? null : (
            <CheckboxToggleButton
              label={(
                <>
                  <FontAwesomeIcon icon="minus" />
                  {' '}
                  Remove
                </>
              )}
              checkName="voiceSelected"
              removeChannelContent={removeVoice}
              id="TemplateContentVoiceToggleButton"
            />
          )}
          isEvent={eventTemplateMode !== EventTemplateMode.Template}
          isSurvey={isSurvey}
          data={data}
        />
      )}
      {isSmsSelected && (
        <TemplateContentSms
          setShowGenerateModal={enableGenerateSmartContent ? setShowGenerateModal : undefined}
          removeButton={eventTemplateMode === EventTemplateMode.Instance ? null : (
            <CheckboxToggleButton
              label={(
                <>
                  <FontAwesomeIcon icon="minus" />
                  {' '}
                  Remove
                </>
              )}
              checkName="smsSelected"
              removeChannelContent={removeSms}
              id="TemplateContentSmsToggleButton"
            />
          )}
          data={data}
          isUrlLinkSurvey={isUrlLinkSurvey}
        />
      )}
      {isEmailSelected && (
        <TemplateContentEmail
          setShowGenerateModal={enableGenerateSmartContent ? setShowGenerateModal : undefined}
          removeButton={eventTemplateMode === EventTemplateMode.Instance ? null : (
            <CheckboxToggleButton
              label={(
                <>
                  <FontAwesomeIcon icon="minus" />
                  {' '}
                  Remove
                </>
              )}
              checkName="emailSelected"
              removeChannelContent={removeEmail}
              id="TemplateContentEmailToggleButton"
            />
          )}
          isNew={isNew}
          data={data}
          requireValidation={eventTemplateMode !== EventTemplateMode.Template}
          isUrlLinkSurvey={isUrlLinkSurvey}
        />
      )}
      {showGenerateModal && (
        <GenerateContentModal
          channel={showGenerateModal}
          onHide={() => {
            setShowGenerateModal(false);
          }}
        />
      )}

    </>
  );
};
