import { Switch, Route } from 'react-router-dom';
import React from 'react';
import ProfilesIndexPage from '../pages/Profiles/Index';
import ProfilesNewPage from '../pages/Profiles/New';
import ProfilesEditPage from '../pages/Profiles/Edit';
import ProfilesAddPage from '../pages/Profiles/Add';

function ProfilesRoutes() {
  return (
    <Switch>
      <Route path="/profiles/add" component={ProfilesAddPage} />
      <Route path="/profiles/new" component={ProfilesNewPage} />
      <Route path="/profiles/:id/edit" component={ProfilesEditPage} />
      <Route path="/profiles" component={ProfilesIndexPage} />
    </Switch>
  );
}

export default ProfilesRoutes;
