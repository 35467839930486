import { useRef } from 'react';
import { useSelector } from 'react-redux';

/**
 * Maintains the same object reference when selector equality has not changed.
 * This is useful for when the returned value is then used within a dependency array. By returning
 * the same reference when the value equality has not changed, dependent hooks can avoid being unnecessarily
 * re-run.
 *
 * Note: this functionality doesn't come for free. The previous state must be maintained in memory and
 * an extra equality check must be performed. So this should really only be used when the returned selector
 * value actually has expensive dependencies.
 * @param selector The selector function
 * @param equalityFn The function that will be used to determine equality
 * @returns The selected state
 */
export const useRefSelector = <RootState, T>(
  selector: (state: RootState) => T,
  equalityFn: (left: T, right: T) => boolean,
): T => {
  const previousState = useRef<T>(null);
  const newState = useSelector(selector, equalityFn);
  if (equalityFn(previousState.current, newState)) {
    return previousState.current;
  }
  previousState.current = newState;
  return newState;
};
