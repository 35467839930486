import React, { MouseEvent } from 'react';
import {
  Button, Spinner,
} from 'react-bootstrap';

export interface IOptions {
  label: string
  value: string
  disabled?: boolean
}

interface IProps {
  label: string
  key?:string
  variant?:string
  savingLabel: string
  className?: string
  style?:React.CSSProperties
  errors?: string
  id?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  saveComplete?: boolean
  disabled?: boolean
  type?: string
}

const SubmitButton = React.forwardRef(({
  label, key, variant, savingLabel, saveComplete, onClick, className, style, errors, id, disabled, type,
}: IProps, ref: React.Ref<HTMLButtonElement>) => (
  <Button
    ref={ref}
    key={key}
    id={id ?? ''}
    variant={variant}
    disabled={disabled ?? !saveComplete}
    onClick={onClick}
    className={className || 'right'}
    style={style}
    type={type || 'submit'}
  >
    {saveComplete && <span className="">{label}</span>}
    {!saveComplete && (
      <>
        <span className="pr-2">{savingLabel}</span>
        <Spinner
          as="span"
          animation="border"
          size="sm"
        />
      </>
    )}
  </Button>
));

export default SubmitButton;
