import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import { useHistory, useParams } from 'react-router-dom';
import {
  createEvent,
  getSelectedEvent,
  stopEvent,
  validateEventContent,
} from 'reducers/Events';
import { getGroupsIndex } from 'reducers/Groups';
import { IEvent } from 'types/IEvent';
import format from 'date-fns/format';
import { toast } from 'react-toastify';
import { EventTransformer } from 'transformers/Event';
import { AutomaticEventForm } from './AutomaticEventForm';
import { IAutomaticMessage } from './IAutomaticMessage';

export default function EditAutomaticEventPage(): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const eventLoading = useSelector((state: RootState) => state.Events.loading);
  const selectedEvent = useSelector((state: RootState) => state.Events.selectedEvent);

  useEffect(() => {
    dispatch(getGroupsIndex());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getSelectedEvent(Number(id)));
    }
  }, [dispatch, id]);

  const selectedAutomaticMessage: IAutomaticMessage = {
    ID: selectedEvent?.ID,
    Name: selectedEvent?.Name,
    Description: selectedEvent?.Description,
    SendTime: selectedEvent?.SendTime,
    GroupId: selectedEvent?.GroupIds[0],
    VoiceContent: selectedEvent?.VoiceContent,
    EmailContent: selectedEvent?.EmailContent,
    SMSContent: selectedEvent?.SMSContent,
    voiceSelected: selectedEvent?.VoiceContent !== null,
    emailSelected: selectedEvent?.EmailContent !== null,
    smsSelected: selectedEvent?.SMSContent !== null,
    SourceLanguage: selectedEvent?.SourceLanguage,
  }

  const updateAutoEvent = (eventData: IEvent): void => {
    dispatch(validateEventContent([EventTransformer.toAPI(eventData)], 'compliance', () => {
      dispatch(
        stopEvent(selectedEvent, format(new Date(), 'yyyy-MM-dd')),
      );
      dispatch(createEvent({ ...eventData, ...{ ID: 0 } }, (savedEvent) => {
        toast.success('Event successfully updated');
        history.push('/events/automatic');
      }));
    }, (err) => { }));
  }

  return (
    <>
      <h1>Edit Automatic Event</h1>
      {(!eventLoading && selectedEvent
       && <AutomaticEventForm data={selectedAutomaticMessage} saveEvent={updateAutoEvent} isNew={false} />
      )}
    </>
  );
}
