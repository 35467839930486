import React, { Dispatch, SetStateAction, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  sidebarOpen: boolean
  openMenuHandler: Dispatch<SetStateAction<boolean>>
}

function MobileNav({ sidebarOpen, openMenuHandler }: IProps): ReactElement {
  return (
    <div className="MobileNav">
      <ul className="MobileNav--List">
        <li className="MobileNav--ListItem">
          <Link to="/">
            <FontAwesomeIcon icon="calendar-day" />
            <span>Home</span>
          </Link>
        </li>
        <li className="MobileNav--ListItem">
          <Link to="/profiles">
            <FontAwesomeIcon icon="users" />
            <span>Profiles</span>
          </Link>
        </li>
        <li className="MobileNav--ListItem">
          <a href="/templates">
            <FontAwesomeIcon icon="comment-alt" />
            <span>Templates</span>
          </a>
        </li>
        <li className="MobileNav--ListItem" onClick={() => openMenuHandler(!sidebarOpen)}>
          <FontAwesomeIcon icon="bars" />
          <span>Menu</span>
        </li>
      </ul>
    </div>
  );
}

export default MobileNav;
