import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IProfile } from 'reducers/IProfile';

const ProfilePreferences = ({ profile, showOptedOutPreferences = false, iconWidth }:
{profile: IProfile, showOptedOutPreferences?: boolean, iconWidth: string}):ReactElement => (
  <>
    {(showOptedOutPreferences || profile.LandLineOptIn) && (
      <span className="fa-stack fa-1x" style={{ width: iconWidth }}>
        <FontAwesomeIcon
          className="fas fa-stack-1x"
          icon="phone"
          title="Landline Voice - Opted In"
          size="xs"
        />
        {(!profile.LandLineOptIn && showOptedOutPreferences)
          && (
            <FontAwesomeIcon
              color="red"
              className="fas fa-stack-1x"
              icon="slash"
              title="Landline Voice - Opted Out"
            />
          )}
      </span>
    )}

    {(profile.MobilePhoneOptIn || showOptedOutPreferences) && (
      <span className="fa-stack fa-1x" style={{ width: iconWidth }}>
        <FontAwesomeIcon
          className="fas fa-stack-1x"
          icon="mobile"
          title="Mobile Voice - Opted In"
          size="xs"
        />
        {(!profile.MobilePhoneOptIn && showOptedOutPreferences)
          && (
            <FontAwesomeIcon
              color="red"
              className="fas fa-stack-1x"
              icon="slash"
              title="Mobile Voice - Opted Out"
            />
          )}
      </span>
    )}

    {(profile.EmailOptIn || showOptedOutPreferences) && (
      <span className="fa-stack" style={{ width: iconWidth }}>
        <FontAwesomeIcon
          className="fas fa-stack-1x"
          icon="at"
          title="Email - Opted In"
          size="xs"
        />
        {(!profile.EmailOptIn && showOptedOutPreferences)
          && (
            <FontAwesomeIcon
              color="red"
              className="fas fa-stack-1x"
              icon="slash"
              title="Email - Opted Out"
            />
          )}
      </span>
    )}

    {(profile.SMSOptIn || showOptedOutPreferences) && (
      <span className="fa-stack" style={{ width: iconWidth }}>
        <FontAwesomeIcon
          className="fas fa-stack-1x"
          icon="comment"
          title="Text Messaging - Opted In"
          size="xs"
        />
        {(!profile.SMSOptIn && showOptedOutPreferences)
           && (
             <FontAwesomeIcon
               color="red"
               className="fas fa-stack-1x"
               icon="slash"
               title="SMS - Opted Out"
             />
           )}
      </span>
    )}
  </>
)

export default ProfilePreferences;
