import React, { useEffect, ReactElement } from 'react';

import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom';
import { ILibraryContent, LoadingState } from 'types/ILibraryContent';
import { toast } from 'react-toastify';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import { getSelectedLibraryContent, putLibraryContent, validateLibraryContent } from '../../reducers/LibraryContent'
import EditLibraryContentForm from './components/EditLibraryContentForm'
import { RootState } from '../../types/rootState';
import { AppThunk } from '../../app/appThunk';

interface IProps {
  match: { params: { id: string } }
}

function LibraryContentEditPage({ match }: IProps): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const libraryContentId = match.params.id;

  useEffect(() => {
    dispatch(getSelectedLibraryContent(libraryContentId));
  }, [dispatch, libraryContentId])

  const onSubmit = (data: ILibraryContent): void => {
    dispatch(validateLibraryContent(data, () => {
      segmentAnalyticsTrack(trackActions.updateLibrary(data));
      const secretThunk = (): AppThunk => async () => {
        await dispatch(putLibraryContent(data, () => {
          toast.success('Content successfully updated');
          history.push('/LibraryContent');
        }));
      }
      dispatch(secretThunk())
    }, (err) => { }));
  };

  const content: ILibraryContent | undefined = useSelector(
    (state: RootState) => state.LibraryContent.selectedContent,
  )

  const loadingState: any = useSelector(
    (state: RootState) => state.LibraryContent.loadingState,
  )

  return (
    <div>
      <div className="ContentShow">
        <Helmet>
          <title>Edit Content</title>
        </Helmet>
        <div className="container">
          <div className="header-bar">
            <h1>Edit Content</h1>
            <div className="actions">
              <strong>Content Name:</strong>
              {' '}
              {content?.Name}
            </div>
          </div>
          {loadingState === LoadingState.LOADED && (
            <EditLibraryContentForm data={content} onSubmit={onSubmit} />
          )}
        </div>
      </div>
    </div>
  )
}

export default LibraryContentEditPage
