import React from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BarChart from '../charts/bar-chart/BarChart';
import CustomCard from './custom-card';
import { DataToCompare } from './WrappedDateRangePicker';

interface IProps{
  dischargedPatientsByStatus: DataToCompare
}

export const StatusSummary = ({ dischargedPatientsByStatus }:IProps):React.ReactElement => {
  if (!dischargedPatientsByStatus?.current) { return null; }
  const getArrowIcon = (entry, previousValue) => {
    if (previousValue && previousValue?.[1] !== null && previousValue?.[1] !== entry[1]) {
      return (entry[1] > previousValue?.[1]
        ? <FontAwesomeIcon style={{ color: 'green' }} icon="arrow-up" />
        : <FontAwesomeIcon style={{ color: 'red' }} icon="arrow-down" />
      );
    }

    return <FontAwesomeIcon icon="minus" />;
  };

  const labels = Object.keys(dischargedPatientsByStatus?.current?.Results?.[0]);

  return (dischargedPatientsByStatus?.current || null) && (
    <Col>
      <CustomCard
        title="Status Summary"
        body={(
          <table className="summary">
            <thead>
              <tr>
                <td>Status</td>
                <td>Current</td>
                <td>Previous</td>
              </tr>
            </thead>
            <tbody>
              {labels?.map((entry) => {
                const currentValue = Object.entries(
                  dischargedPatientsByStatus?.current?.Results[0],
                )?.find((x) => x[0] === entry);
                const previousValue = Object.entries(
                  dischargedPatientsByStatus?.previous?.Results[0],
                )?.find((x) => x[0] === entry);
                return (
                  <tr key={entry}>
                    <td>{entry === 'EnteredProgram' ? 'Entered Program' : entry}</td>
                    <td>{currentValue?.[1] ?? 0}</td>

                    <td>
                      {previousValue?.[1] ?? 0}
                    </td>
                    <td>
                      {getArrowIcon(currentValue, previousValue)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        chart={(
          <BarChart
            labels={labels.map((x) => (x === 'EnteredProgram' ? 'Entered Program' : x))}
            data={[
              {
                color: '#00b7d9',
                chartLabel: 'Previous',
                data: Object.entries(dischargedPatientsByStatus?.previous?.Results[0])?.map(
                  (entry) => ({ label: entry[0], value: Number(entry[1]) }),
                ) || [],
              },
              {
                color: '#0F4CDB',
                chartLabel: 'Current',
                data: Object.entries(dischargedPatientsByStatus?.current?.Results[0])?.map(
                  (entry) => ({ label: entry[0], value: Number(entry[1]) }),
                ),
              },
            ]}
          />
        )}
      />
    </Col>
  );
};
