import React, {
  ReactElement, useCallback, useRef, useState, useEffect, useMemo,
} from 'react';
import { Helmet } from 'react-helmet';
import API from 'api/API';
import { useForm } from 'react-hook-form';
import {
  Button, Spinner,
} from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'components/Table';
import { DateInput } from 'components/FormControls';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import { Column } from 'react-table';

function AccushieldReportPage(): ReactElement {
  const { control, handleSubmit } = useForm();
  const [reportResults, setReportResults] = useState<Record<string, any>>();
  const [reportIsRunning, setReportIsRunning] = useState(false);
  const csvLink = useRef<CSVLink>();
  const [reportExportLoading, setReportExportLoading] = useState(false);
  const userInfo = useSelector((state: RootState) => state.UserInfo);
  const acctTimezone = userInfo.accountTimezone;
  const { startOfMonth, endOfMonth } = useMemo(() => {
    const acctNow = moment().tz(acctTimezone);
    return {
      startOfMonth: acctNow.startOf('month').format('YYYY-MM-DD'),
      endOfMonth: acctNow.endOf('month').format('YYYY-MM-DD'),
    };
  }, [acctTimezone])

  const columns: Array<Column> = [{
    Header: 'First Name',
    accessor: 'FirstName',
  },
  {
    Header: 'Last Name',
    accessor: 'LastName',
  },
  {
    Header: 'Date',
    accessor: 'SendDateTime',
    Cell: useCallback(
      ({ cell }) => <>{moment.utc(cell.value).tz(acctTimezone).format('M/D/YYYY h:mm A')}</>,
      [acctTimezone],
    ),
  },
  {
    Header: 'Voice Landline',
    accessor: 'LandLineVoice',
  },
  {
    Header: 'Voice Mobile',
    accessor: 'MobileVoice',
  },
  {
    Header: 'SMS',
    accessor: 'SMS',
  },
  {
    Header: 'Email',
    accessor: 'Email',
  },
  ]

  const downloadHandler = async () => {
    if (reportExportLoading) return;
    setReportExportLoading(true);
    csvLink.current.link.click();
    setReportExportLoading(false);
  }
  const onSubmit = useCallback(async (data) => {
    setReportIsRunning(true);
    const start = `${data.startDate} 00:00:00`;
    const end = `${data.endDate} 23:59:59`;
    const results:Record<string, any> = await API.post('api/v2/report', {
      ReportName: 'TransactionReceipt',
      Parameters: {
        // convert the local time to UTC, api will search data via UTC time
        '@start': moment.tz(start, acctTimezone).utc().format('YYYY-MM-DD HH:mm:ss'),
        '@end': moment.tz(end, acctTimezone).utc().format('YYYY-MM-DD HH:mm:ss'),
      },
      ContextType: 'Account',
    });
    setReportResults(results.data);
    setReportIsRunning(false);
  }, [acctTimezone]);

  useEffect(() => {
    // console.log('useEffect');
    onSubmit({ startDate: startOfMonth, endDate: endOfMonth });
  }, [endOfMonth, startOfMonth, onSubmit]);
  return (
    <>
      <Helmet>
        <title>Accushield Visitors Report</title>
      </Helmet>
      <div className="container reports">
        <h3>Accushield Visitor Messaging Report</h3>
        <p>Residents who have received notification about Accushield Visitor Check-Ins</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-row mb-3">
            <div className="col-3">
              <DateInput
                id="startDate"
                format="yyyy-MM-DD"
                label="Start"
                control={control}
                name="startDate"
                defaultValue={startOfMonth}
              />
            </div>
            <div className="col-3">
              <DateInput
                id="endDate"
                label="End"
                format="yyyy-MM-DD"
                control={control}
                name="endDate"
                defaultValue={endOfMonth}
              />
            </div>
            <div className="p-2 align-self-end">
              <Button
                style={{ width: '160px', display: 'flex', alignItems: 'center' }}
                type="submit"
                disabled={reportIsRunning}
              >
                <span className="pr-1">
                  Run Report
                </span>
                {reportIsRunning && <Spinner className="pl-2" as="span" animation="border" />}
              </Button>

            </div>
            <div className="p-2 ">
              {!reportIsRunning && reportResults && (
                <>
                  <button type="button" className="btn btn-link" onClick={async () => { await downloadHandler(); }}>
                    <FontAwesomeIcon icon="file-export" />
                    {' '}
                    { reportExportLoading
                      ? ('Exporting...' && <Spinner className="pl-2" as="span" animation="border" />)
                      : 'Export Data' }
                  </button>
                  <CSVLink
                    data={reportResults?.Results || []}
                    ref={csvLink}
                    filename="report.csv"
                    className="hidden"
                    target="_blank"
                    asyncOnClick
                  />
                </>
              ) }
            </div>

          </div>
        </form>
      </div>
      {!reportIsRunning
      && reportResults
      && (
        <Table
          columns={(reportResults.Results.length > 0
            ? columns
            : []) as any}
          data={reportResults.Results || []}
          enableCheck={false}
        />
      )}
    </>
  )
}

export default AccushieldReportPage;
