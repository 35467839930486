import React from 'react';
import clsx from 'clsx';

export interface ISwitch {
  id?: string
  name?: string
  checked: boolean
  onChange(...args:any): void
  labels?: [string, string]
  disabled?: boolean
  className?: string
  color?: string
}

export const Switch: React.FC<ISwitch> = ({
  id, name, color = '#0F4CDB', checked, onChange, labels, disabled, className,
}) => (
  <div className={clsx('switch', disabled && 'switch--disabled', className)}>
    <input
      type="checkbox"
      name={name}
      className="switch__checkbox"
      id={id}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <label className="switch__label" htmlFor={id}>
      <span
        className="switch__label--inner"
        data-yes={labels?.[0]}
        data-no={labels?.[1]}
        style={{ backgroundColor: color }}
      />
      <span
        className="switch__circle"
      />
      <div className="switch__bg-shadow" />
    </label>
  </div>
);
