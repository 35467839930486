import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { EventResults } from 'pages/CalendarEvent/Results';
import { EventContent } from 'pages/CalendarEvent/Content';
import CreateEventPage from 'pages/Events/Create';
import EditEventPage from 'pages/Events/Edit';
import EditOccurrence from 'pages/Events/EditOccurrence';
import EventsIndexPage from 'pages/Events/Index';
import AutomaticMessagingIndexPage from 'pages/Events/AutomaticMessaging/IndexPage';
import CreateAutomaticEventPage from 'pages/Events/AutomaticMessaging/Create';
import EditAutomaticEventPage from 'pages/Events/AutomaticMessaging/Edit';
import EventSurveyIndex from 'pages/Events/Survey/Index';
import CreateSurvey from 'pages/Events/Survey/New';
import EditSurvey from 'pages/Events/Survey/Edit';

function CalendarEventRoutes() {
  return (
    <Switch>
      <Route path="/events/create" component={CreateEventPage} />
      <Route path="/events/automatic/new" component={CreateAutomaticEventPage} />
      <Route path="/events/automatic/:id/edit" component={EditAutomaticEventPage} />
      <Route path="/events/:id/edit" component={EditEventPage} />
      <Route path="/events/:id/occurrence/:date" component={EditOccurrence} />
      <Route
        path="/events/:id/results/:date"
        component={EventResults}
      />
      <Route path="/events/:id/content/:date" component={EventContent} />
      <Route path="/events/:id/content" component={EventContent} />
      <Route path="/events/automatic" component={AutomaticMessagingIndexPage} />
      <Route path="/events/survey/new" component={CreateSurvey} />
      <Route path="/events/survey/:id/edit" component={EditSurvey} />
      <Route path="/events/survey" component={EventSurveyIndex} />
      <Route path="/events" component={EventsIndexPage} />
    </Switch>
  );
}

export default CalendarEventRoutes;
