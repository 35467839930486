import React from 'react';
import useSWR, { SWRConfiguration, Fetcher } from 'swr';
import {
  useQueryParam, NumberParam, StringParam, BooleanParam,
} from 'use-query-params';

import { ITemplatesPayload } from 'types/ITemplatesPayload';
import { ITemplatesIndexParams } from 'types/ITemplatesIndexParams';
import * as TemplatesApi from 'api/TemplatesAPI';
import {
  ISearchQuery,
} from 'components/Inputs/IndexFilter_Id_Name';
import { useLocation } from 'react-router-dom';

const perpage = 10;
const skipTemplateAttachments = true;

export const useTemplatesQuery = (
  type: 'template'|'transactional',
  options: SWRConfiguration<ITemplatesPayload, any, Fetcher<ITemplatesPayload>> | undefined = {},
) => {
  const { pathname } = useLocation();
  const [pageParam = 1] = useQueryParam('page', NumberParam);
  const [searchQueryParam] = useQueryParam('searchQuery', StringParam);
  const [sortFieldParam] = useQueryParam('sortField', StringParam);
  const [sortDirectionParam] = useQueryParam('sortDirection', StringParam);
  const [isDraftParam = false] = useQueryParam('isDraft', BooleanParam);

  const searchQuery = React.useMemo((): ISearchQuery => {
    if (!searchQueryParam) {
      return {};
    }
    const [searchField, searchValue] = searchQueryParam.split(',');
    return { searchField, searchValue }
  }, [searchQueryParam]);

  const isMarketing = pathname.startsWith('/marketing-templates');
  const trimmedSearchValue = searchQuery.searchValue?.trim();

  return useSWR<ITemplatesPayload>([
    'templates',
    isDraftParam,
    pageParam,
    perpage,
    (searchQuery.searchField && trimmedSearchValue) ? searchQuery.searchField : undefined,
    (searchQuery.searchField && trimmedSearchValue) ? trimmedSearchValue : undefined,
    (sortFieldParam && sortDirectionParam) ? sortFieldParam : undefined,
    (sortFieldParam && sortDirectionParam) ? sortDirectionParam : undefined,
    skipTemplateAttachments,
    isMarketing,
  ], ([
    ,
    isDraft,
    page,
    _perpage,
    searchField,
    searchValue,
    sortField,
    sortDirection,
    _skipTemplateAttachments,
    _isMarketing,
  ]) => {
    const params: ITemplatesIndexParams = {
      page,
      perpage: _perpage,
      searchField,
      searchValue,
      sortField,
      sortDirection,
      isDraft,
      skipTemplateAttachments: _skipTemplateAttachments,
      isMarketing: _isMarketing,
    };

    return TemplatesApi.TemplatesIndex(params, type)
  }, options)
}
