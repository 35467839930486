import React, { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import { Row } from 'react-bootstrap';

interface BarChartData{
  chartLabel: string
  color:string
  data:{
    value:number
  }[]
}

interface IProps{
  data: BarChartData[]
  labels?: string[]
  stacked?: boolean
}

const BarChart: FC<IProps> = ({ data, labels, stacked }) => {
  const datasets = data.map((dataset) => ({
    label: dataset.chartLabel,
    data: dataset.data.map((item) => item.value),
    fill: false,
    fontColor: '#FFFFFF',
    backgroundColor: dataset.color,
  }));
  const internalData = {
    labels,
    datasets,
  };

  return (
    <Row className="chart-bar">
      <Bar
        data={internalData}
        options={({
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            labels: {
              color: '#FFFFFF',
              fontSize: 18,
            },
          },
          datalabels: {
            color: '#FFFFFF',
          },
          plugins: {
            legend: {
              position: 'top',
              label: {
                color: '#FFFFFF',
              },
            },
            title: {
              display: false,
              text: 'Chart.js Line Chart',
            },
          },
          scales: {
            yAxes: [{
              gridLines: {
                color: 'white', // give the needful color
              },
              display: true,
              stacked: true,
              ticks: {
                fontColor: '#FFFFFF',
                fontSize: 18,
                stepSize: 1,
                beginAtZero: true,
                textStrokeColor: '#FFFFFF',
              },
            }],
            xAxes: [{
              ticks: {
                fontColor: '#FFFFFF',
                fontSize: 14,
                stepSize: 1,
                beginAtZero: true,
              },
              stacked: !!stacked,
              gridLines: {
                color: 'white',
                zeroLineColor: 'white',
              },
            }],
          },
        }) as any}
      />
    </Row>
  )
}

export default BarChart
