import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { ReactComponent as WarningSvg } from 'styles/images/warning.svg';

interface DeleteAssociationModalProps {
  onHide: () => void
  onDelete: () => void
  label: string
}

export const DeleteAssociationModal: React.FC<DeleteAssociationModalProps> = ({ onHide, onDelete, label }) => (
  <Modal
    show
    onHide={onHide}
    className="delete-labels-modal"
  >
    <Modal.Header
      closeButton
    >
      <WarningSvg />
      <Modal.Title>
        Remove Label
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p className="message">
        Are you sure you want to remove the label &quot;
        {label}
        &quot; from labels list?
      </p>
      <button
        type="button"
        className="btn btn-primary square w-100"
        onClick={onDelete}
      >
        Remove
      </button>
      <button
        type="button"
        className="btn btn-link w-100 mt-3"
        onClick={onHide}
      >
        Cancel
      </button>
    </Modal.Body>
  </Modal>
)
