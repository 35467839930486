import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import API from 'api/API';
import { Link } from 'react-router-dom';
import { Col, Row, Container } from 'react-bootstrap';

interface DateRange{
  start: string
  end: string
}

export interface IReport {
  ReportName: string
  Parameters: Record<string, any>
  Results: Record<string, any>[]
}

// const getReport = async (reportName, dateRange, accTimezone) => {
//   const results = API.post<IReport>('api/v2/report', {
//     ReportName: reportName,
//     Parameters: {
//       '@startdateutc': dateRange.start,
//       '@enddateutc': dateRange.end,
//       '@timezone': accTimezone,
//     },
//     ContextType: 'Account',
//   });

//   return { reportName, data: (await results).data };
// }

const getReportAndHistorical = async (reportName, dateRange, previousDateRange, accTimezone) => {
  const results = API.post<IReport>('api/v2/report', {
    ReportName: reportName,
    Parameters: {
      '@startdateutc': dateRange.start,
      '@enddateutc': dateRange.end,
      '@timezone': accTimezone,
    },
    ContextType: 'Account',
  });

  const results2 = API.post<IReport>('api/v2/report', {
    ReportName: reportName,
    Parameters: {
      '@startdateutc': previousDateRange.start,
      '@enddateutc': previousDateRange.end,
      '@timezone': accTimezone,
    },
    ContextType: 'Account',
  });

  return { reportName, current: (await results).data, previous: (await results2).data };
}

const getDateRangeInTimezone = (dateRange, accountTimezone) => {
  const startMoment = moment.tz(dateRange.start, accountTimezone);
  const endMoment = moment.tz(dateRange.end, accountTimezone);
  return {
    start: moment.tz(startMoment, accountTimezone).startOf('day').utc()
      .format('yyyy-MM-DD HH:mm:ss'),
    end: moment.tz(endMoment, accountTimezone).endOf('day').utc()
      .format('yyyy-MM-DD HH:mm:ss'),
  }
}

export interface DataToCompare{
  current:IReport
  previous:IReport
}

export interface DashboardData{
  DischargedPatientsByStatus:DataToCompare
  DischargedPatientsTagSummary:DataToCompare
  SurveyCallSummaryTimeSeries:IReport
}

export const useGetReportData = (acctTimezone:string):[DateRange, (DashboardData)=>any, Record<string, any>] => {
  const defaultStartMoment = moment.tz(acctTimezone);
  const [dateRange, setDateRange] = useState<{start:string, end: string}>(
    {
      start: moment(defaultStartMoment).add(-30, 'd').format('yyyy-MM-DD HH:mm'),
      end: defaultStartMoment.format('yyyy-MM-DD HH:mm'),
    },
  );

  const [reportData, setReportData] = useState<DashboardData>();
  useEffect(() => {
    (async () => {
      if (dateRange) {
        const results = getReportAndHistorical(
          'DischargedPatientsByStatus',
          getDateRangeInTimezone(dateRange, acctTimezone),
          getPreviousDateRange(dateRange, acctTimezone),
          acctTimezone,
        );

        const results2 = getReportAndHistorical(
          'DischargedPatientsTagSummary',
          getDateRangeInTimezone(dateRange, acctTimezone),
          getPreviousDateRange(dateRange, acctTimezone),
          acctTimezone,
        );
        Promise.all([results,
          results2,
        ]).then(
          (promiseResponses) => {
            const reportDataTransform:DashboardData = {
              DischargedPatientsByStatus: null,
              DischargedPatientsTagSummary: null,
              SurveyCallSummaryTimeSeries: null,
            };
            promiseResponses.forEach((x) => {
              reportDataTransform[x.reportName] = x
            });
            setReportData(reportDataTransform)
          },
        );
      }
    })()
  }, [acctTimezone, dateRange]);

  return [dateRange, setDateRange, reportData]
}

export const getPreviousDateRange = (dateRange:DateRange, accountTimezone:string):DateRange => {
  const startMoment = moment.tz(dateRange.start, accountTimezone);
  const endMoment = moment.tz(dateRange.end, accountTimezone);

  const previousDateRange = {
    start: moment.tz(startMoment, accountTimezone).subtract(
      moment.duration(endMoment.diff(startMoment)),
    ).startOf('day').utc()
      .format('yyyy-MM-DD HH:mm:ss'),
    end: moment.tz(dateRange.start, accountTimezone).add(-1, 'd').endOf('day').utc()
      .format('yyyy-MM-DD HH:mm:ss'),
  }

  return previousDateRange;
}
interface IProps{
  dateRange: DateRange
  setDateRange: (DateRange)=>any
  acctTimezone: string
}
export const WrappedDateRangePicker = ({ dateRange, setDateRange, acctTimezone }:IProps):React.ReactElement => {
  const previousDateRange = getPreviousDateRange(dateRange, acctTimezone);
  const [myStartDate, setMyStartDate] = useState(moment(dateRange.start));
  const [myEndDate, setMyEndDate] = useState(moment(dateRange.end));

  const [focusedInputState, setFocusedInput] = useState<FocusedInputShape>(null);
  return (
    <Container className="" style={{ width: '60%', marginRight: 0 }}>
      <Row>
        <Col className="d-flex justify-content-end align-items-center">
          <Link to="/dischargedpatients">Activities &amp; Status</Link>
        </Col>
        <div className="d-flex justify-content-end align-items-center">
          <DateRangePicker
            minDate={moment().subtract(100, 'years')}
            startDate={myStartDate} // momentPropTypes.momentObj or null,
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
            endDate={myEndDate} // momentPropTypes.momentObj or null,
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
            anchorDirection="left"
            onDatesChange={({ startDate, endDate }) => {
              setMyStartDate(startDate);
              setMyEndDate(endDate);
              if (startDate && endDate) {
                setDateRange({
                  start: startDate.startOf('day').format('yyyy-MM-DD HH:mm'),
                  end: endDate.endOf('day').format('yyyy-MM-DD HH:mm'),
                });
              }
            }}
            focusedInput={focusedInputState} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            hideKeyboardShortcutsPanel
            minimumNights={0}
            enableOutsideDays
            keepOpenOnDateSelect
            isOutsideRange={() => false}
            // isRTL
          />
        </div>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end align-items-end">
          Compared To:
          {' '}
          {moment(previousDateRange.start).format('yyyy-MM-DD')}
          {' - '}
          {moment(previousDateRange.end).format('yyyy-MM-DD')}
        </Col>
      </Row>
    </Container>
  );
};
