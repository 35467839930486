import {
  Controller, Control, DeepMap, FieldError,
} from 'react-hook-form';

import React, { ReactElement } from 'react';
import Input from '../Inputs/Input';
import RawDateInput from './RawDateInput';

interface IFieldMap {
  [key: string]: string | undefined
}

interface IProps {
  fieldMap?: IFieldMap
  type: string
  control: Control
  fieldClassName?: string
  hide?: boolean
  errors?: DeepMap<any, FieldError>
}

function FieldMap({
  control, fieldMap, type, fieldClassName, hide, errors,
}: IProps): ReactElement | null {
  if (!fieldMap || hide) return null;

  const inputs = Object.keys(fieldMap).flatMap((key) => {
    const label = fieldMap[key];

    if (label && label.length > 0) {
      return [{
        name: key,
        label: fieldMap[key],
      }];
    }

    return [];
  });

  return (
    <>
      {inputs.map((input) => (
        type === 'date'
          ? (
            <RawDateInput
              id={input.name}
              key={input.name}
              name={input.name}
              label={input.label ?? ''}
              className={fieldClassName}
              control={control}
              errors={errors && errors[input.name]?.message}
            />
          )
          : (
            <Controller
              type="text"
              as={Input}
              key={input.name}
              name={input.name}
              className={fieldClassName}
              label={input.label ?? ''}
              control={control}
              errors={errors && errors[input.name]?.message}
            />
          )
      ))}
    </>
  );
}

export default FieldMap;
