import { DateTime } from 'luxon';
import {
  IVoiceContent, IEmailContent, ISMSContent, LoadingState, ILibraryContent,
} from './ILibraryContent';
import { IndexPayload } from './request';
import { IEntityLabel } from './IEntityLabel';

export enum ScheduleMode {
  Once = 'Once',
  Schedule = 'Schedule',
}

export interface ITemplate extends IEventTemplateBase {
  IsDraft: boolean
  Type: string
  Labels?: IEntityLabel[]
  RecipientsById?: string[]
  CreatedByUserId: number
  CreatedDateTimeUTC: DateTime
  LastModifiedByUserId?: number
  LastModifiedDateTimeUTC?: DateTime
  LastUsedDateTimeUTC?: DateTime
  SourceLanguage: string
}
export enum VoiceMessageTypeEnum {
  Both = 'Both',
  OnDemand = 'OnDemand',
  Broadcast = 'Broadcast',
  None = 'None'
}

export enum EventTemplateMode {
  Template = 'Template',
  Event = 'Event',
  Instance = 'Instance',
}

export type TemplateAndEvent = ITemplateExtendo & IEventExtendo;
export type TemplateOrEvent = ITemplateExtendo | IEventExtendo;

export interface ITemplateExtendo extends ITemplate {
  [k: string]: any
}

export interface IEventExtendo extends IEvent {
  [k: string]: any
}
export interface IEventTemplateBase {
  ID: number
  Name: string
  Description: string
  Location: string
  GroupIds: number[]
  ProfileIds: string[]
  VoiceContent?: IVoiceContent
  EmailContent?: IEmailContent
  SMSContent?: ISMSContent
  VoiceMessageType: VoiceMessageTypeEnum
  voiceSelected?: boolean
  emailSelected?: boolean
  smsSelected?: boolean
  Category: string
  SourceLanguage: string
  IsMarketing?: boolean
}

export enum EventType {
  SURVEY = 'SimpleSurvey',
  ANNOUNCEMENT = 'Announcements',
  TRANSACTION = 'Transaction',
  AUTOMATIC = 'Automatic',
  URLLINKSURVEY = 'UrlLinkSurvey'
}

export interface IEvent extends IEventTemplateBase {
  SendMode: ScheduleMode
  RRule: string
  StartDate: string
  SendTime: string
  SelectedGroups?: number[]
  SelectedProfiles?: string[]
  TemplateID: number
  ExpirationTime: string
  Status: string
  StopDateLocal: string
  EventType: string | EventType
  IsEmergency: boolean
  IsSendNow: boolean
  IsBroadcastForm: boolean
  FormType?: string
  BroadcastFormId?: number
  BroadcastFormTypeQuestions?: string[]
  DaysToRespond?: string
}

export interface IEventSummary {
  Channel: string
  Status: string
  MessagesFailed: number
  MessagesSent: number
  Duplicate: number
}

export interface IEventEmail {
  ProfileId: string
  EventId: number
  Status: string
  FirstName: string
  LastName: string
  UserType: string
  ToEmail: string
  Opened: string
  Error: string
  Room: string
}

export interface IEventSms {
  ProfileId: string
  EventId: number
  Status: string
  FirstName: string
  LastName: string
  UserType: string
  PhoneNumber: number
  FromNumber: number
  Error: string
  Room: string
}

export interface IEventVoice {
  ProfileId: string
  EventId: number
  Status: string
  FirstName: string
  LastName: string
  UserType: string
  PhoneNumber: number
  DurationSeconds: number
  FromNumber: number
  DeliveryMethod: string
  Keypress: number | null
  Error: string
  Room: string
}

export type IEventsPayload = IndexPayload<IEvent>;

export interface ITemplateState {
  // This really should not be on the store in this form, as it encourages data duplication.
  templates: ITemplate[]
  // This really should not be on the store.
  selectedTemplate: ITemplate
  // This really should not be on the store.
  pagination: {
    Page: number
    PerPage: number
    TotalItems: number
    TotalPages: number
  }
  loading: boolean
  byIds: { [key: string]: ITemplate }
  pagedIds: { [key: string]: number[] }
}
export interface ILibraryContentState {
  loadingState: LoadingState
  contents: Record<string, ILibraryContent[]>
  selectedContent: ILibraryContent
  pagination: {
    Page: number
    PerPage: number
    TotalItems: number
    TotalPages: number
  }
  allContents: ILibraryContent[]
  validating: boolean
}
