import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import _join from 'lodash/join';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import {
  createDischargedPatientAndProfiles,
  getDischargedPatientConfig,
  selectDischargedPatientConfig,
} from 'reducers/DischargedPatients';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDischargedPatient } from 'types/IDischargedPatient';
import { ISurveyCall } from 'types/ISurveyCall';
import { Prompt } from 'react-router-dom';
import { IPatientFormData } from 'types/IPatientFormData';
import {
  Accordion, Modal, Row, Col,
} from 'react-bootstrap';
import { buildDefaultSurveyCall } from 'lib/dischargedPatient/utils';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import { IUserInfoState } from 'reducers/IUserInfoState';
import { RootState } from 'types/rootState';
import DischargedPatientForm from './DischargedPatientForm';
import DischargedPatientContactForm from './DischargedPatientContactForm';
import DischargedPatientView from './DischargedPatientView';
import { PatientDetailsSurveyCalls } from './PatientDetailsSurveyCalls';
import { SurveyCallForm } from './SurveyCallForm';
import { NotesComponent } from './DischargedPatientNotes';
import './PatientDetailsForm.scss'

interface IProps {
  data?: IPatientFormData
  selectedCallId: number
  showPatientInfoForm: boolean
  setShowPatientInfoForm: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedPatient: (patient: IDischargedPatient) => void
  setSelectedCall: (patient: IDischargedPatient, surveyCall: ISurveyCall) => void
  showBack: boolean
  hasNoteChanged: boolean
  setHasNoteChanged?: React.Dispatch<React.SetStateAction<boolean>>
  startExpanded: boolean
}

function PatientDetailsForm({
  data,
  selectedCallId,
  showPatientInfoForm,
  setShowPatientInfoForm,
  setSelectedPatient,
  setSelectedCall,
  showBack,
  hasNoteChanged,
  setHasNoteChanged,
  startExpanded,
}: IProps): React.ReactElement {
  const dispatch = useDispatch();
  const [isAddingNewQuestion, setIsAddingNewQuestion] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>(data.dischargedPatient.Notes || '');
  const [initNotes] = useState<string>(data.dischargedPatient.Notes || '');
  const methods = useForm<IPatientFormData>({ mode: 'onBlur' });
  const userInfo = useSelector((state: RootState) : IUserInfoState => state.UserInfo);

  const placeHolder = '(___) ___-____';

  useEffect(() => {
    dispatch(getDischargedPatientConfig());
  }, [dispatch]);

  const config = useSelector(selectDischargedPatientConfig);
  const defaultSurveyCall = useMemo(() => {
    const surveyCallSettings = buildDefaultSurveyCall(data?.dischargedPatient, userInfo.accountTimezone);
    if (config?.ConfigurationData?.MaxCallAttemptsDefault && config?.ConfigurationData?.MaxCallAttemptsDefault != 0) {
      surveyCallSettings.MaxCallAttempts = config?.ConfigurationData?.MaxCallAttemptsDefault;
    }
    return surveyCallSettings;
  }, [config]);

  const {
    reset, errors, handleSubmit, watch, formState, getValues,
  } = methods;
  useEffect(() => { if (data) { reset(data); } }, [reset, data]);

  useEffect(() => {
    if (notes !== initNotes) {
      setHasNoteChanged(true);
    } else {
      setHasNoteChanged(false);
    }
  }, [notes, initNotes, setHasNoteChanged]);

  useEffect(() => {
    const errFields = [];
    for (const k in errors) {
      if (k) {
        errFields.push(k.replace(/([A-Z])/g, ' $1').trim());
      }
    }
    if (errFields.length) {
      toast.error(`Please check ${_join(errFields, ', ')} before saving the profile.`);
    }
  }, [errors]);
  const [showPhoneWarningModal, setShowPhoneWarningModal] = useState<boolean>(false);
  const shouldShowPhoneWarning = (formData: IPatientFormData): boolean => {
    if (
      (!formData?.contactProfile?.MobilePhone
        || formData?.contactProfile?.MobilePhone === ''
        || formData?.contactProfile?.MobilePhone === placeHolder)
      && (!formData?.contactProfile?.LandLine
        || formData?.contactProfile?.LandLine === ''
        || formData?.contactProfile?.LandLine === placeHolder)
    ) {
      return true;
    }
    return false;
  }
  const saveDischargedPatientsAndProfiles = (formData: IPatientFormData): void => {
    const contactProfile = {
      ...formData.contactProfile,
      LandLine: placeHolder === formData.contactProfile.LandLine ? '' : formData.contactProfile.LandLine,
      MobilePhone: placeHolder === formData.contactProfile.MobilePhone ? '' : formData.contactProfile.MobilePhone,
    }
    const updatedPatientProfile = {
      ...formData.patientProfile,
      Language: contactProfile.Language,
    }
    const patientData = {
      ...formData,
      contactProfile,
      patientProfile: updatedPatientProfile,
    };
    if (!patientData?.dischargedPatient?.DischargePatientId
      || patientData?.dischargedPatient?.DischargePatientId === 0) {
      // todo: let's remove this success callback
      segmentAnalyticsTrack(trackActions.createDischargedPatient());
      dispatch(createDischargedPatientAndProfiles((patientData), (Id) => {
        setShowPatientInfoForm(false);
        setSelectedPatient(patientData.dischargedPatient);
      }));
    } else {
      // we don't use this anymore because editing is in place on 3 forms...
      // dispatch(updateDischargedPatient(patientData));
    }
    setShowPatientInfoForm(false);
  }
  const onSubmitClick = (formData: IPatientFormData): void => {
    if (shouldShowPhoneWarning(formData)) {
      setShowPhoneWarningModal(true);
    } else {
      saveDischargedPatientsAndProfiles(formData);
    }
  };

  const submitData = (): void => {
    const formData: IPatientFormData = getValues();
    saveDischargedPatientsAndProfiles(formData);
  }

  const patientStatus = watch('dischargedPatient.ProgramStatus');
  const showAddCall = data?.dischargedPatient?.DischargePatientId > 0
    && patientStatus === 'In Program'
    && !showPatientInfoForm;

  const messageObj = {
    title: 'Discharge Patient Changes',
    messageText: 'You have unsaved changes, are you sure you want to leave?',
  };
  const notesMessageObj = {
    title: 'Discharge Patient Notes Changes',
    messageText: 'You have unsaved changes, are you sure you want to leave?',
  };
  return (
    <div>
      <Prompt
        when={!formState.isSubmitting && !formState.isSubmitted && formState.isDirty}
        message={JSON.stringify(messageObj)}
      />
      <Prompt
        when={hasNoteChanged}
        message={JSON.stringify(notesMessageObj)}
      />
      {showPatientInfoForm && (
        <Row>
          <h1 style={{ marginTop: '0.5rem', marginLeft: '1rem' }}>
            {data.dischargedPatient.DischargePatientId !== 0
              ? 'Edit Patient Survey Info'
              : 'Create New Patient'}
          </h1>
        </Row>
      )}
      {(!showPatientInfoForm && data.dischargedPatient.DischargePatientId !== 0) && (
        <>
          <Row>
            <Col>
              {showBack && (<a className="m-1" href="/dischargedpatients">Back to Follow Up Survey Search</a>)}
            </Col>
            <Col />
          </Row>
          <Row>
            <DischargedPatientView
              data={data}
              setSelectedPatient={setSelectedPatient}
              startExpanded={startExpanded}
            />
          </Row>

        </>
      )}
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmitClick)}
          className="form ProfilesForm"
        >
          {showPatientInfoForm && (
            <>
              <div className="row">
                <div className="col-xs-12 col-md-12">
                  <button
                    type="button"
                    className="btn btn-link right"
                    onClick={(e) => {
                      setShowPatientInfoForm(false);
                      if (data.dischargedPatient.DischargePatientId === 0) {
                        setSelectedPatient(null);
                      } else {
                        setSelectedPatient(data.dischargedPatient);
                      }
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary right"
                  >
                    Save
                  </button>
                </div>
              </div>
              <DischargedPatientForm
                data={data}
              />
              <DischargedPatientContactForm
                data={data}
              />
            </>
          )}
        </form>
      </FormProvider>
      {data.dischargedPatient.DischargePatientId !== 0 && !showPatientInfoForm && (
        <PatientDetailsSurveyCalls
          key={data.dischargedPatient.DischargePatientId}
          selectedCallId={selectedCallId}
          dischargedPatient={data?.dischargedPatient}
          setSelectedCall={setSelectedCall}
        />
      )}
      {isAddingNewQuestion ? (
        <Accordion
          activeKey={defaultSurveyCall.SurveyCallId.toString()}
        >
          <SurveyCallForm
            key={defaultSurveyCall.SurveyCallId.toString()}
            data={defaultSurveyCall}
            setActiveKey={() => {
              setSelectedCall(data.dischargedPatient, defaultSurveyCall);
            }}
            clearActiveKey={() => {
              setSelectedCall(data.dischargedPatient, null);
            }}
            setIsAddingNewQuestion={setIsAddingNewQuestion}
          />
        </Accordion>
      ) : showAddCall && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            setIsAddingNewQuestion(true);
            setSelectedCall(data.dischargedPatient, defaultSurveyCall);
          }}
        >
          Add New Survey Event
        </button>
      )}

      <Modal
        show={showPhoneWarningModal}
        onHide={() => setShowPhoneWarningModal(false)}
        className="DeleteActionButtonModal"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header />
        <Modal.Body>
          <FontAwesomeIcon
            icon="trash-alt"
            size="4x"
            color="#FF7052"
          />
          <div className="message">
            <h4>Are you sure?</h4>
            <p>
              Are you sure you want to save this without phone numbers?
            </p>
          </div>
          <button
            type="button"
            className="btn btn-block btn-danger"
            onClick={() => {
              setShowPhoneWarningModal(false);
              submitData();
            }}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-block btn-secondary"
            onClick={() => setShowPhoneWarningModal(false)}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
      {(!showPatientInfoForm && data.dischargedPatient.DischargePatientId !== 0) && (
        <NotesComponent
          patientId={data.dischargedPatient.DischargePatientId}
          notes={notes}
          setNotes={setNotes}
          setHasNoteChanged={setHasNoteChanged}
        />
      )}
    </div>
  );
}

export default PatientDetailsForm;
