import * as CalendarAPI from './CalendarAPI';
import * as DischargedPatientsAPI from './DischargedPatientsAPI';
import * as EventsAPI from './EventsAPI';
import * as GroupsAPI from './GroupsAPI';
import * as LibraryContentAPI from './LibraryContentAPI';
import * as ProfilesAPI from './ProfilesAPI';
import * as RecordingStudioAPI from './RecordingStudioAPI';
import * as SurveyCallsAPI from './SurveyCallsAPI';
import * as SurveyQuestionsAPI from './SurveyQuestionAPI';
import * as TemplatesAPI from './TemplatesAPI';
import * as UserInfoAPI from './UserInfoAPI';
import * as WellnessAPI from './WellnessAPI';
import * as SurveyQuestionListAPI from './SurveyQuestionListAPI';
import * as CaremergeIntegrationAPI from './CaremergeIntegrationAPI';
import * as InboxAPI from './InboxAPI';
import * as SmartContentApi from './SmartContentAPI';

const moduleExports = {
  Calendar: CalendarAPI,
  DischargedPatients: DischargedPatientsAPI,
  Events: EventsAPI,
  Groups: GroupsAPI,
  LibraryContent: LibraryContentAPI,
  Profiles: ProfilesAPI,
  RecordingStudio: RecordingStudioAPI,
  SurveyCalls: SurveyCallsAPI,
  SurveyQuestions: SurveyQuestionsAPI,
  Templates: TemplatesAPI,
  UserInfo: UserInfoAPI,
  Wellness: WellnessAPI,
  SurveyQuestionList: SurveyQuestionListAPI,
  CaremergeIntegration: CaremergeIntegrationAPI,
  Inbox: InboxAPI,
  SmartContent: SmartContentApi,
}
export default moduleExports;
