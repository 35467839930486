import React,
{
  ReactElement, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { RootState } from 'types/rootState';
import { useParams } from 'react-router-dom';
import { changeCurrentAccount, getCurrentAccount } from 'reducers/UserInfo';
import { Spinner } from 'react-bootstrap';

function RerouteAccount(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const requestedAccId = parseInt(id, 10);
  const params = queryString.parse(document.location.search);
  const dispatch = useDispatch();
  const { startPath } = useSelector((state: RootState) => state.UserInfo);
  const currentAccount = useSelector(getCurrentAccount);

  useEffect(() => {
    const redirectPostAccChange = (): void => {
      if (params?.route) {
        window.location.assign(`/${params.route || startPath}`);
      }
    };
    if (currentAccount) {
      if (currentAccount?.Id === requestedAccId) {
        window.location.assign(`/${params.route || startPath}`);
      } else {
        dispatch(changeCurrentAccount(requestedAccId, redirectPostAccChange));
      }
    }
  }, [currentAccount, dispatch, params.route, requestedAccId, startPath]);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <h1 className="d-flex align-items-center">
        <Spinner as="span" className="" animation="border" />
        Please Wait . . .
      </h1>
    </div>
  )
}

export default RerouteAccount;
