import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParam, NumberParam } from 'use-query-params';
import { ISurveyCall } from 'types/ISurveyCall';
import { Accordion } from 'react-bootstrap';
import { IDischargedPatient } from 'types/IDischargedPatient';
import {
  getSurveyCallsByDischargedPatientId, selectDischargedPatientSurveyCallsPage,
} from 'reducers/DischargedPatients';
import { RootState } from 'types/rootState';
import { SurveyCallForm } from './SurveyCallForm';

type Props = {
  dischargedPatient: IDischargedPatient
  selectedCallId: number
  setSelectedCall:(patient:IDischargedPatient, surveyCall:ISurveyCall) => void
};

export const PatientDetailsSurveyCalls: React.FC<Props> = (
  {
    dischargedPatient,
    selectedCallId,
    setSelectedCall,
  },
) => {
  const dispatch = useDispatch();

  const dischargedPatientId = dischargedPatient?.DischargePatientId;

  const [currentPage] = useQueryParam('page', NumberParam);
  const surveyCalls: ISurveyCall[] = useSelector(
    selectDischargedPatientSurveyCallsPage(currentPage || 1),
  ).sort((a, b) => ((a.SendDateTimeUtc > b.SendDateTimeUtc) ? 1 : -1))

  const loadingIndicators = useSelector(
    (state:RootState) => state.DischargedPatients.loadingIndicators,
  );

  const getCallsLoading = 'getCalls' in loadingIndicators && loadingIndicators.getCalls;
  useEffect(() => {
    if (dischargedPatient) {
      dispatch(getSurveyCallsByDischargedPatientId(dischargedPatientId));
    }
  }, [dischargedPatient,
    dischargedPatientId,
    dispatch,
  ]);

  return (
    <>
      {getCallsLoading && (<div>Loading</div>)}
      <div>
        <div>
          {surveyCalls?.filter((x) => x.DischargePatientId === dischargedPatientId).length > 0 && (
            <Accordion
              activeKey={selectedCallId?.toString()}
            >
              {surveyCalls.map((call) => (
                <SurveyCallForm
                  key={call.SurveyCallId}
                  data={call}
                  setActiveKey={() => {
                    setSelectedCall(dischargedPatient, call);
                  }}
                  clearActiveKey={() => {
                    setSelectedCall(dischargedPatient, call);
                  }}
                />
              ))}
            </Accordion>
          )}
        </div>
      </div>
    </>
  );
};
