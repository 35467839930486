import React from 'react';
import { Switch, Route } from 'react-router-dom'

import GroupMainPage from '../pages/Groups/GroupMainPage';

function GroupsRoute(): JSX.Element {
  return (
    <Switch>
      <Route path="/groups" component={GroupMainPage} />
    </Switch>
  )
}

export default GroupsRoute
