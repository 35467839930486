import React, {
  useRef, useMemo, useCallback, useLayoutEffect, forwardRef, useImperativeHandle,
} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import _groupBy from 'lodash/groupBy';
import clsx from 'clsx';
import useSWRInfinite from 'swr/infinite';
import usePrevious from 'react-use/lib/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useLatest from 'react-use/lib/useLatest';

import { ReactComponent as PasswordVisible } from 'styles/images/password-visible.svg';
import { ReactComponent as PasswordInvisible } from 'styles/images/password-invisible.svg';
import { ReactComponent as BroadcastSvg } from 'styles/images/broadcast.svg';
import { getUserState } from 'reducers/UserInfo';
import * as InboxApi from 'api/InboxAPI';
import {
  IConversation, MessageType, ConversationMessage, ConversationMessagesPayload, Status,
} from 'types/IConversation';
import { hasError } from 'reducers/Error';
import { MessagesRef, ConversationsRef } from './types';

const updateMessageInCache = (updatedMessage: ConversationMessage, currentMessages: ConversationMessagesPayload[]) => {
  if (!currentMessages) {
    return currentMessages;
  }

  let messageIndexInPage = -1;
  const messagesPageIndex = currentMessages.findIndex((messagesPage) => {
    const messageIdx = messagesPage.Messages.findIndex(
      (messageItem) => messageItem.MessageId === updatedMessage.MessageId,
    );

    if (messageIdx === -1) {
      return false;
    }

    messageIndexInPage = messageIdx;
    return true;
  });

  if (messageIndexInPage === -1 || messagesPageIndex === -1) {
    return currentMessages;
  }

  const messagesPage = currentMessages[messagesPageIndex];

  const updatedMessagesPage = {
    ...messagesPage,
    Messages: [
      ...messagesPage.Messages.slice(0, messageIndexInPage),
      updatedMessage,
      ...messagesPage.Messages.slice(messageIndexInPage + 1),
    ],
  }

  return [
    ...currentMessages.slice(0, messagesPageIndex),
    updatedMessagesPage,
    ...currentMessages.slice(messagesPageIndex + 1),
  ];
}

interface MessagesProps {
  selectedConversation: IConversation|null
  conversationsRef: React.RefObject<ConversationsRef>
}

export const Messages = forwardRef<MessagesRef, MessagesProps>(({
  selectedConversation, conversationsRef,
}, ref) => {
  const messagesRef = useRef<null|HTMLDivElement>(null);
  const userInfoState = useSelector(getUserState);
  const dispatch = useDispatch();

  const getKey = (pageIndex, previousPageData) => {
    const page = pageIndex + 1;
    const perpage = 20;

    if (previousPageData && (
      previousPageData.Pagination.TotalPages === 0
        || previousPageData.Pagination.TotalPages === previousPageData.Pagination.Page
    )) {
      return null;
    }

    return [
      'inboxSmsMessages',
      selectedConversation.ConversationId,
      page,
      perpage,
    ];
  }

  const fetcher = async ([
    ,
    conversationId,
    page,
    perpage,
  ]) => InboxApi.getInboxConversationMessages(conversationId, { page, perpage })

  const {
    data, size, setSize, mutate,
  } = useSWRInfinite<ConversationMessagesPayload>(
    getKey,
    fetcher,
    {
      refreshInterval: 60000, // 10000 prev
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      persistSize: false,
      revalidateAll: true,
    },
  );

  useImperativeHandle(ref, () => ({ mutate }), [mutate]);

  const latestData = useLatest(data);
  const isLoadingMore = (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isLoading = !data || isLoadingMore;
  const hasMore = useMemo(() => {
    if (!data?.length) {
      return false;
    }

    const lastPage = data[data.length - 1];
    return lastPage.Pagination.TotalPages !== 0 && lastPage.Pagination.TotalPages !== lastPage.Pagination.Page
  }, [data])

  const fetchedMessages: ConversationMessage[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.reduce((acc, page) => [
      ...[...page.Messages].reverse(),
      ...acc,
    ], [])
  }, [data]);

  const messagesByDay: [string, ConversationMessage[]][] = useMemo(() => {
    if (!fetchedMessages) {
      return [];
    }

    const grouppedMessages = _groupBy(fetchedMessages, (item) => (
      moment.utc(item.MessageDateUTC, 'YYYY-MM-DDTHH:mm:ss.SSS')
        .tz(userInfoState.accountTimezone)
        .format('YYYYMMDD')
    ));

    return Object.keys(grouppedMessages)
      .sort((a, b) => a.localeCompare(b))
      .map((datekey) => {
        const d = moment.tz(datekey, 'YYYYMMDD', userInfoState.accountTimezone);
        const browserDate = d.toDate().toLocaleDateString();
        return ([
          d.calendar(null, {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: 'dddd',
            sameElse: () => `[${browserDate}]`,
          }), grouppedMessages[datekey]])
      });
  }, [fetchedMessages, userInfoState.accountTimezone])

  const selectedConversationId = selectedConversation?.ConversationId;
  const previousSelectedConversationId = usePrevious(selectedConversation?.ConversationId);
  const previousData = usePrevious(data);

  const didScrollDown = useRef<boolean>(false);

  useLayoutEffect(() => {
    // when selected conversation changes and data already fetched
    if (previousSelectedConversationId !== selectedConversationId) {
      didScrollDown.current = false;

      if (data?.length) {
        messagesRef.current?.scrollTo(0, messagesRef.current.scrollHeight);
        return;
      }
    }

    // after fetching first page, just once
    if (!previousData?.length && data?.length === 1 && !didScrollDown.current) {
      messagesRef.current?.scrollTo(0, messagesRef.current.scrollHeight);
      didScrollDown.current = true;
      return;
    }

    // after we send a message
    if (data?.[0]?.Messages?.[0]?.MessageId.startsWith('optimistic-')) {
      messagesRef.current?.scrollTo(0, messagesRef.current.scrollHeight);
    }
  }, [data, previousData, previousSelectedConversationId, selectedConversationId]);

  const observer = useRef<IntersectionObserver>();
  const firstMessageRef = useCallback((node) => {
    if (isLoading) {
      return;
    }
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setSize((oldSize) => oldSize + 1)
      }
    })
    if (node) {
      observer.current.observe(node);
    }
  }, [isLoading, hasMore, setSize]);

  const isMutatingRef = React.useRef<Record<string, boolean>>({});

  const onHide = async (message: ConversationMessage, isHidden: boolean) => {
    try {
      const optimisticMessage = {
        ...message,
        IsHidden: isHidden,
      }

      isMutatingRef.current[message.MessageId] = true;
      await mutate(() => InboxApi.hideMessage(
        selectedConversation.ConversationId,
        message.MessageId,
        isHidden,
      ) as any,
      {
        revalidate: false,
        populateCache: (updatedMessage: ConversationMessage) => (
          updateMessageInCache(updatedMessage, latestData.current)
        ),
        optimisticData: () => updateMessageInCache(optimisticMessage, latestData.current),
      });
      isMutatingRef.current[message.MessageId] = false;

      if (selectedConversation.LastRecievedMessage?.MessageId === message.MessageId) {
        const optimisticConv: IConversation = {
          ...selectedConversation,
          LastRecievedMessage: optimisticMessage,
        }

        await conversationsRef.current.mutate((currentConv) => {
          if (!currentConv) {
            return currentConv;
          }

          const conversationPage = currentConv
            .find((page) => page.SmsConversations
              .some((conv) => conv.ConversationId === selectedConversation.ConversationId),
            );

          if (!conversationPage) {
            return currentConv;
          }

          const conversationIndex = conversationPage.SmsConversations
            .findIndex((conv) => conv.ConversationId === selectedConversation.ConversationId);

          if (conversationIndex === -1) {
            return currentConv;
          }

          const optimisticSmsConversations = [
            ...conversationPage.SmsConversations.slice(0, conversationIndex),
            optimisticConv,
            ...conversationPage.SmsConversations.slice(conversationIndex + 1),
          ];

          const newConversationPage = {
            ...conversationPage,
            SmsConversations: optimisticSmsConversations,
          }

          return [
            ...currentConv.slice(0, currentConv.indexOf(conversationPage)),
            newConversationPage,
            ...currentConv.slice(currentConv.indexOf(conversationPage) + 1),
          ]
        },
        false);
      }
    } catch (err) {
      dispatch(hasError(err));
    }
  }

  return (
    <div
      ref={messagesRef}
      className="sms-inbox__chat-view__chat__messages"
    >
      {isLoading && (
        <Spinner
          animation="border"
          variant="primary"
          role="status"
          className="mx-auto d-block mt-4 flex-shrink-0"
        />
      )}
      {messagesByDay.map(([date, datemessages], index) => (
        <React.Fragment key={date}>
          <div
            ref={index === 0 ? firstMessageRef : undefined}
            className="sms-inbox__chat-view__chat__messages__date"
          >
            {date}
          </div>
          {datemessages.map((message) => {
            const isBroadcast = message.MessageType === MessageType.Broadcast;
            const isDirect = message.MessageType === MessageType.Direct;
            const isReply = message.MessageType === MessageType.Reply;
            const isHidden = message.IsHidden;

            let replacedContent = '';
            if (isHidden) {
              replacedContent = 'Message Hidden';
            } else {
              replacedContent = message.MessageContent;
            }

            const browserTime = moment.utc(message.MessageDateUTC, 'YYYY-MM-DDTHH:mm:ss.SSS')
              .tz(userInfoState.accountTimezone)
              .toDate()
              .toLocaleTimeString(undefined, { timeStyle: 'short' });

            return (
              <div
                className={clsx(
                  'sms-inbox__chat-view__chat__messages__message',
                  isBroadcast && 'message--broadcast',
                  isDirect && 'message--direct',
                  isHidden && 'message--hidden',
                  isReply && 'message--reply',
                )}
                key={message.MessageId}
                title={(isReply && isHidden)
                  ? 'Message Hidden'
                  : ''}
              >
                {isBroadcast && (
                  <OverlayTrigger
                    placement="top"
                    overlay={(
                      <Tooltip id={`tooltip-broadcast-info-${message.MessageId}`}>
                        THIS IS A BROADCAST MESSAGE
                      </Tooltip>
                    )}
                  >
                    <BroadcastSvg
                      className="sms-inbox__chat-view__chat__messages__message__broadcast-icon"
                    />
                  </OverlayTrigger>
                )}
                {replacedContent}
                <span className="sms-inbox__chat-view__chat__messages__message__time">
                  <span>
                    {browserTime}
                  </span>
                </span>
                {isReply && (
                  isHidden ? (
                    <PasswordInvisible
                      className="sms-inbox__chat-view__chat__messages__message__hide-icon"
                      onClick={(e) => {
                        if (!isMutatingRef.current[message.MessageId]) {
                          onHide(message, !isHidden)
                        }
                      }}
                      onMouseOver={(e) => {
                        // eslint-disable-next-line no-param-reassign
                        e.target.closest('div').title = '';
                      }}
                      onMouseLeave={(e) => {
                        // eslint-disable-next-line no-param-reassign
                        e.target.closest('div').title = replacedContent;
                      }}
                    />
                  )
                    : (
                      <PasswordVisible
                        className="sms-inbox__chat-view__chat__messages__message__hide-icon"
                        onClick={() => {
                          if (!isMutatingRef.current[message.MessageId]) {
                            onHide(message, !isHidden);
                          }
                        }}
                      />
                    )
                )}
                {message.Status === Status.Error && ((isReply && !isHidden) || isDirect) && (
                  <div
                    className="sms-inbox__chat-view__chat__messages__message__error-actions"
                  >
                    <OverlayTrigger
                      placement="left"
                      overlay={(
                        <Tooltip id={`tooltip-${message.MessageId}`}>
                          {message.ErrorMessage || 'Something went wrong.'}
                        </Tooltip>
                      )}
                    >
                      <FontAwesomeIcon
                        icon="exclamation-circle"
                        color="#FF0000"
                      />
                    </OverlayTrigger>

                  </div>
                )}
              </div>
            )
          })}
        </React.Fragment>
      ))}
    </div>
  )
});
