import React from 'react';
import { useQueryParam } from 'use-query-params';
import clsx from 'clsx';

import { ReactComponent as SortDesc } from 'styles/images/sortable-desc.svg';
import { ReactComponent as SortAsc } from 'styles/images/sortable-asc.svg';

export const OrderByButton = () => {
  const [searchParam = '', setSearchParam] = useQueryParam<string, string>('orderby');

  const isDesc = searchParam === '-new';
  const isAsc = !isDesc;

  return (
    <button
      className={clsx('sms-inbox__conversation-view__orderby-button', isDesc && 'active')}
      type="button"
      title="Coversations are sorted by the date of the last recieved REPLY"
      onClick={() => {
        if (isDesc) {
          setSearchParam('+new');
        } else {
          setSearchParam('-new');
        }
      }}
    >
      <div className="sms-inbox__conversation-view__orderby-button__sorts">
        <SortDesc className={clsx('sms-inbox__conversation-view__orderby-button__sort', isDesc && 'active')} />
        <SortAsc className={clsx('sms-inbox__conversation-view__orderby-button__sort', isAsc && 'active')} />
      </div>
      Sort by Date
    </button>
  )
}
