import { IEntityLabel } from 'types/IEntityLabel';
import API from './API'

export const API_PATH = '/api/v2/entitylabel'

export async function entityLabelsGet(filter: string): Promise<IEntityLabel[]> {
  const url = filter ? `${API_PATH}?filterLabel=${filter}` : API_PATH;

  const response = await API.get<IEntityLabel[]>(url);
  return response.data;
}

export async function entityLabelCreate(labels: Partial<IEntityLabel>[]): Promise<IEntityLabel[]> {
  const response = await API.post<IEntityLabel[]>(API_PATH, labels);
  return response.data;
}

export async function entityLabelPut(label: IEntityLabel): Promise<IEntityLabel> {
  const response = await API.put<IEntityLabel>(`${API_PATH}/${label.EntityLabelId}`, label);
  return response.data;
}

export async function entityLabelDelete(labelId: string): Promise<void> {
  await API.delete(`${API_PATH}/${labelId}`);
}
