import React, { FC } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';

export type BulkActionsButtonProps = React.ButtonHTMLAttributes<HTMLElement>

export interface IBulkActionsProps extends BulkActionsButtonProps {
  items: { className?: string, label: string, handler: (...args: any[]) => void }[]
}

export const BulkActionsButton = React.forwardRef<HTMLButtonElement, BulkActionsButtonProps>(
  ({
    children, onClick, className, ...props
  }, ref) => (
    <button
      type="button"
      className={clsx(
        'btn btn-secondary square bulk-actions-button', className.replace(/(^| )dropdown-toggle( |$)/, ''),
      )}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  ),
);

export const BulkActions: FC<IBulkActionsProps> = ({
  id = 'bulk-actions-button', className, items, ...props
}) => (
  <Dropdown>
    <Dropdown.Toggle
      id={id}
      className={className}
      as={BulkActionsButton}
      {...props}
    >
      <FontAwesomeIcon icon="ellipsis-h" size="sm" color="#0F4CDB" />
    </Dropdown.Toggle>
    <Dropdown.Menu popperConfig={
      {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          }],
        strategy: 'fixed',
      }
    }
    >
      {items.map(((item) => (
        <Dropdown.Item key={item.label} onClick={item.handler} className={item.className}>
          {item.label}
        </Dropdown.Item>
      )))}
    </Dropdown.Menu>
  </Dropdown>
)
