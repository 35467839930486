import React, {
  FC, useState, useCallback, useMemo, useRef,
} from 'react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import useSWRInfinite from 'swr/infinite';
import {
  Row, Col, ListGroup, Card,
} from 'react-bootstrap';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import * as TemplatesApi from 'api/TemplatesAPI';
import { ITemplate } from 'types/IEvent';
import { IAccountTemplatesPayload } from 'types/ITemplatesPayload';
import { TemplateSearchFilter } from 'components/TemplateSearchFilter';

interface TemplatePickerProps {
  setSelectedTemplate: (template: ITemplate) => void
  selectedTemplate: ITemplate
  selectedAccountId: number
  isBlueprint: boolean
}

export const TemplatePicker: FC<TemplatePickerProps> = ({
  setSelectedTemplate,
  selectedTemplate,
  selectedAccountId,
  isBlueprint,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchField, setSearchField] = useState<string>('');
  const { pathname } = useLocation();
  const isMarketing = pathname.startsWith('/marketing-templates');

  const getKey = (pageIndex, previousPageData) => {
    const page = pageIndex + 1;
    const perpage = 50;
    const skipTemplateAttachments = true;

    if (previousPageData && (
      previousPageData.Pagination.TotalPages === 0
        || previousPageData.Pagination.TotalPages === previousPageData.Pagination.Page
    )) {
      return null;
    }

    const draftKey = isBlueprint
      ? ['blueprintTemplates']
      : ['accountTemplates', selectedAccountId]

    return [
      ...draftKey,
      searchValue && searchField,
      searchValue,
      page,
      perpage,
      skipTemplateAttachments,
      isMarketing,
    ];
  }

  const fetcher = async ([
    ,
    ...args
  ]) => {
    if (isBlueprint) {
      const [_searchField, _searchValue, page, perpage, skipTemplateAttachments, _isMarketing] = args;
      return TemplatesApi.getBlueprintTemplates({
        searchField: _searchField,
        searchValue: _searchValue,
        page,
        perpage,
        skipTemplateAttachments,
        isMarketing: _isMarketing,
      });
    }

    const [accountId, _searchField, _searchValue, page, perpage, skipTemplateAttachments, _isMarketing] = args;
    return TemplatesApi.getAccountTemplates(accountId, {
      searchField: _searchField,
      searchValue: _searchValue,
      page,
      perpage,
      skipTemplateAttachments,
      isMarketing: _isMarketing,
    })
  };

  const {
    data, size, setSize,
  } = useSWRInfinite<IAccountTemplatesPayload>(
    getKey,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateFirstPage: true,
      persistSize: false,
    },
  );

  const isLoadingMore = (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isLoading = !data || isLoadingMore;
  const hasMore = useMemo(() => {
    if (!data?.length) {
      return false;
    }

    const lastPage = data[data.length - 1];
    return lastPage.Pagination.TotalPages !== 0 && lastPage.Pagination.TotalPages !== lastPage.Pagination.Page
  }, [data])

  const observer = useRef<IntersectionObserver>();
  const lastTemplateRef = useCallback((node) => {
    if (isLoading) {
      return;
    }
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setSize((oldSize) => oldSize + 1)
      }
    })
    if (node) {
      observer.current.observe(node);
    }
  }, [isLoading, hasMore, setSize]);

  const fetchedTemplates: ITemplate[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.reduce((acc, page) => [...acc, ...page.Data], [])
  }, [data]);

  const getRowColor = (index: number): string => {
    if (index % 2 === 1) {
      return 'oddGroupItem';
    }
    return 'evenGroupItem';
  }

  const Templates = useCallback(({
    index, style,
  }) => {
    if (isLoading && index === fetchedTemplates.length - 1 + 1) {
      return (
        <div
          className="loading-text d-flex text-center justify-content-center"
          key="loading"
          role="status"
          style={style}
        >
          Loading...
        </div>
      )
    }

    const template = fetchedTemplates[index];

    const rowProps = {
      key: template.ID,
      style,
      className: 'no-overflow-x cursor-pointer',
      ref: index === fetchedTemplates.length - 1 ? lastTemplateRef : undefined,
    }

    return (
      <div {...rowProps}>
        <div
          role="row"
          tabIndex={0}
          onClick={() => setSelectedTemplate(template)}
          className={clsx(
            'align-middle ellipsis pl-2',
            getRowColor(index),
            selectedTemplate?.ID === template.ID && 'selected',
          )}
          style={{ overflowX: 'hidden' }}
        >
          <span title={template.Name}>
            {template.Name}
          </span>
        </div>
      </div>
    )
  }, [fetchedTemplates, isLoading, lastTemplateRef, setSelectedTemplate, selectedTemplate]);

  return (
    <Card>
      <Card.Body style={{ paddingRight: 40 }}>

        <ListGroup style={{ lineHeight: '0.7rem' }}>
          <Row>
            <Col>
              <table className="table table-striped" style={{ marginBottom: '0rem' }}>
                <thead>
                  <tr>
                    <th className="pt-0">
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        paddingLeft: '0.25rem',
                      }}
                      >
                        <span>
                          Templates
                        </span>
                        <TemplateSearchFilter
                          searchVal={searchValue}
                          onSubmit={(_searchValue, _searchField) => {
                            setSearchValue(_searchValue);
                            setSearchField(_searchField);
                            setSelectedTemplate(null);
                          }}
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            </Col>
          </Row>

          <div>
            <div className="d-flex">
              <div style={{ flex: '1 1 auto', height: '600px', lineHeight: '2.2rem' }}>
                <AutoSizer>
                  {({ height, width }) => (
                    <FixedSizeList
                      height={height}
                      width={width}
                      itemSize={35}
                      itemCount={fetchedTemplates.length + (isLoading ? 1 : 0)}
                      rowWidth={width}
                    >
                      {Templates}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </div>
            </div>
          </div>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};
