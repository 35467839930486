import React from 'react';
import {
  Row, Col, Modal, Alert, Form, Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { selectEventsLoading } from 'reducers/Events';
import { ReactComponent as SendArrowSvg } from 'styles/images/send-arrow.svg';
import { RootState } from 'types/rootState';
import { ControlledTimePicker } from 'components/FormControls/TimePicker';
import { DateInput } from 'components/FormControls';

interface ScheduleModalProps {
  onHide: () => void
  onSubmit: () => void
  show: boolean
  interactionEnabled: boolean
}

export const ScheduleModal: React.FC<ScheduleModalProps> = ({
  onHide, onSubmit, show, interactionEnabled,
}) => {
  const userInfo = useSelector((state: RootState) => state.UserInfo);
  const isSaving = useSelector(selectEventsLoading);
  const acctDateTimeNow = moment().tz(userInfo.accountTimezone);

  const { control, getValues, errors } = useFormContext();

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      centered
      className="BroadcastScheduleModal"
    >
      <Modal.Header
        closeButton
      />
      <Modal.Body>
        {(errors?.SendTime || errors?.StartDate) && (
          <Alert variant="danger" style={{ marginBottom: 12 }}>
            <FontAwesomeIcon icon="info-circle" className="mr-2" />
            Please schedule event for a time in the future
          </Alert>
        )}
        <Row>
          <Col>
            <DateInput
              label="Date"
              id="BroadcastStartDate"
              control={control}
              defaultValue={
                acctDateTimeNow.format('MM-DD-yyyy')
              }
              format="MM-DD-yyyy"
              name="StartDate"
              rules={{
                required: 'The start date is required.',
                validate: (val: string) => moment(val, 'MM-DD-yyyy')
                        >= moment(acctDateTimeNow.format('MM-DD-yyyy'), 'MM-DD-yyyy'),
              }}
            />
          </Col>
          <Col>
            <Form.Group>
              <label>Time</label>
              <ControlledTimePicker
                name="SendTime"
                defaultValue={
                  acctDateTimeNow.add(5, 'minutes').format('HH:mm')
                }
                validate={(val: any) => {
                  if (moment(getValues('StartDate'), 'MM-DD-yyyy') > acctDateTimeNow) {
                    return true;
                  }
                  return val > moment().tz(userInfo.accountTimezone).format('HH:mm');
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <button
          type="button"
          className="btn btn-lg btn-primary mt-2 w-100"
          id="schedule-and-send-submit-btn"
          onClick={onSubmit}
          disabled={!interactionEnabled || isSaving}
        >
          Schedule & Send
          {isSaving
            ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="ml-2"
              />
            )
            : <SendArrowSvg />}
        </button>
      </Modal.Body>
    </Modal>
  )
}
