/* eslint-disable no-param-reassign */
import React, {
  useState, useEffect, useRef, ReactElement,
} from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import MergeFields from 'components/MergeFields';
import { Dropzone, IRejection } from 'components/Dropzone';
import {
  Button,
  Row,
  Col,
  Modal,
  Form,
} from 'react-bootstrap';
import _times from 'lodash/times';

import {
  getRecordingSessionPin,
  checkRecordingSession,
  getRecordingContentByPin,
} from 'api/RecordingStudioAPI';
import useInterval from 'components/hooks/UseInterval';
import { Input } from 'components/FormControls';
import { useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import log from 'lib/logging';
import {
  ILibraryContent, IVoiceContent, LibraryContentType, VoiceContentType,
} from 'types/ILibraryContent';
import { AudioPlayer } from 'components/AudioPlayer';
import { toast } from 'react-toastify';
import { Tooltip } from '@material-ui/core';
import { format } from 'date-fns';
import { isIE } from 'react-device-detect';
import Textarea from 'components/Inputs/Textarea';
import { selectCustomFieldNames } from 'reducers/UserInfo';
import { getFileBlobFromB64Data, getB64DataFromFile } from 'utils/file';
import { VoiceContentResponses } from './VoiceContentResponses';
import { ContentLength } from './ContentLength';

const createFile = (b64data: string, fileName: string) => Object.assign(
  getFileBlobFromB64Data(b64data), { name: fileName, lastModified: Date.now() } as File,
)

VoiceContent.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  showPhoneSurvey: undefined,
  showNewContentButtons: undefined,
  libraryContent: undefined,
}

export function VoiceContent({
  showNewContentButtons = false,
  showKeypad = false,
  libraryContent,
}: {
  showNewContentButtons?: boolean
  showKeypad?: boolean
  libraryContent?: IVoiceContent
}): ReactElement {
  const {
    control, watch, setValue, getValues, register, errors,
  } = useFormContext();

  const data = watch('VoiceContent');

  const recordingStudioNumber: string = useSelector(
    (state: RootState) => state.UserInfo.userInfo?.RecordingStudioNumber,
  );
  const voiceType = data?.VoiceType;
  const isTextToSpeech = voiceType === VoiceContentType.TTS;
  const isRecording = voiceType === VoiceContentType.RECORDING;
  const showVoiceOptions = !(voiceType);

  const phoneRegEx = /^\(?[2-9][0-9]{2}\)?\s?[2-9][0-9]{2}-?[0-9]{4}$/;
  const placeHolder = '(___) ___-____';

  const [pin, setPin] = useState('');
  const [recordingSession, setRecordingSession] = useState(null);
  const initialAudioFile = React.useMemo(() => (
    data?.VoiceRecording?.Data
      ? createFile(
        data?.VoiceRecording?.Data,
        data?.VoiceRecording?.FileName ?? '',
      )
      : null
  ), []);
  const [audioFile, setAudioFile] = useState<File>(initialAudioFile);
  const [showRecordingModal, setShowRecordingModal] = useState<boolean>(false);
  const [audioData, setAudioData] = useState<string>(data?.VoiceRecording?.Data ?? '');
  const [rejectFiles, setRejectFiles] = useState<IRejection[]>([]);
  const defaultInputLanguage = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.InputLanguageDefaultValue);
  const customFieldNames = useSelector(selectCustomFieldNames);
  const textboxRef = useRef<HTMLDivElement|null>(null);
  const type = LibraryContentType.VOICE
  const newTTS = {
    ID: 'newTTS',
    Name: '+ Add New Text-To-Speech',
    Description: '',
    Type: type, // TODO: should use the enum LibraryContentType.
    VoiceContent: {
      Content: '',
      VoiceRecording: null,
      VoiceType: 'TextToSpeech', // TODO: should use the enum VoiceContentType.
      TransferToPhoneNumber: '',
      SurveyResponses: showKeypad ? [..._times(9, (n) => n + 1), 0].map((n) => ({
        Keypress: n,
        Response: '',
      })) : [],
    },
    SMSContent: null,
    EmailContent: null,
    SourceLanguage: defaultInputLanguage,
  };
  const newRecording = {
    ID: 'newRecording',
    Name: '+ Add New Recording',
    Description: '',
    Type: type, // TODO: should use the enum LibraryContentType.
    VoiceContent: {
      Content: '',
      VoiceRecording: {
        Data: null,
        DocumentId: 0,
        DownloadUrl: null,
        FileName: null,
      },
      VoiceType: 'Recording', // TODO: should use the enum VoiceContentType.
      TransferToPhoneNumber: '',
      SurveyResponses: showKeypad ? [..._times(9, (n) => n + 1), 0].map((n) => ({
        Keypress: n,
        Response: '',
      })) : [],
    },
    SMSContent: null,
    EmailContent: null,
    SourceLanguage: defaultInputLanguage,
  };

  const onChooseType = (library: ILibraryContent) => {
    setValue('VoiceContent.VoiceType', library.VoiceContent.VoiceType);
    setValue('VoiceContent', library.VoiceContent);
  };

  const requestRecording = (): void => {
    const pinPromise = getRecordingSessionPin();
    pinPromise.then((result) => {
      log.info('Pin:', result);
      setPin(result);
      // set this to null to let the delay = 500, the interval do not start if delay is null
      setRecordingSession(null);
      setShowRecordingModal(true);
    });
  };

  let delay = null;
  if (pin && !recordingSession) {
    delay = 5000;
  }

  const checkRecordingStatus = async (): Promise<void> => {
    log.info('Checking...');
    const sessionPromise = checkRecordingSession(pin);
    sessionPromise.then(async (result) => {
      //
      if (result !== null && result.RecordingUrl !== null) {
        log.info('Complete', result);
        setRecordingSession(result);
        const messageData = await getRecordingContentByPin(pin);
        setShowRecordingModal(false);
        const recordFileName = `Recorded-Message-${format(new Date(), 'yyy-MM-dd')}.wav`;
        const newFile = createFile(messageData, recordFileName);
        setAudioFile(newFile);
        // const newRecordingFile = await getBase64(newFile);
        setAudioData(messageData);
        setValue(
          'VoiceContent.VoiceRecording.DownloadUrl',
          '',
          { shouldDirty: true },
        );
        setValue(
          'VoiceContent.VoiceRecording.Data',
          messageData,
          { shouldDirty: true },
        );
        setValue(
          'VoiceContent.VoiceRecording.DocumentId',
          '0',
          { shouldDirty: true },
        );
        setValue(
          'VoiceContent.VoiceRecording.FileName',
          recordFileName,
          { shouldDirty: true },
        );
      }
    });
  };

  useInterval(() => checkRecordingStatus(), delay);

  const clearRecording = (): void => {
    setPin(null);
    setRecordingSession(null);
    setAudioData('');
    setAudioFile(null);
  };

  const audioUrl = React.useMemo(() => {
    if (!audioFile) {
      return '';
    }

    return URL.createObjectURL(audioFile);
  }, [audioFile]);

  React.useEffect(() => () => {
    if (audioUrl) {
      URL.revokeObjectURL(audioUrl);
    }
  }, [audioUrl]);

  const generateDownloadLink = () => {
    const fileName = getValues('VoiceContent.VoiceRecording.FileName');

    return (
      <a
        onClick={(event) => { event.stopPropagation(); }}
        download={fileName}
        href={audioUrl}
        className="my-3 d-block"
      >
        Click here to download recording
      </a>
    )
  }

  useEffect(() => {
    if (libraryContent) {
      setAudioData(libraryContent?.VoiceRecording?.Data);
      setAudioFile(libraryContent?.VoiceRecording?.Data
        ? createFile(
          libraryContent?.VoiceRecording?.Data,
          libraryContent?.VoiceRecording?.FileName ?? '',
        )
        : null,
      );
    }
  }, [libraryContent]);

  const hasAudioData = !!data?.VoiceRecording?.Data;

  return (
    <>
      <Row>
        {showVoiceOptions && (
          <>
            <Col md="auto">
              <Button
                id="new-text-to-speech"
                variant="secondary"
                size="sm"
                onClick={() => onChooseType(newTTS)}
              >
                Create New Text to Speech
              </Button>
            </Col>
            <Col md="auto">
              <Button variant="secondary" size="sm" onClick={() => onChooseType(newRecording)}>
                Create New Recording
              </Button>
            </Col>
          </>
        )}
      </Row>
      <input type="hidden" name="VoiceContent.VoiceType" ref={register({ required: true })} />
      <input type="hidden" name="VoiceContent.ID" ref={register} />
      {isRecording && (
        <>
          <input
            type="hidden"
            name="VoiceContent.VoiceRecording.DownloadUrl"
            ref={register}
          />
          <input
            type="hidden"
            name="VoiceContent.VoiceRecording.Data"
            ref={register({ required: true })}
          />
          <input
            type="hidden"
            name="VoiceContent.VoiceRecording.DocumentId"
            ref={register}
          />
          <input
            type="hidden"
            name="VoiceContent.VoiceRecording.FileName"
            ref={register}
          />
        </>
      )}
      {(!showVoiceOptions && isTextToSpeech) && (
        <Controller
          errors={errors?.VoiceContent?.Content && 'Content Required'}
          rules={{
            required: 'Content Required',
            validate: (val: any) => {
              if (val === null || val === undefined || val.trim() === '') {
                return 'Content Required';
              }
              const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
              if (regex.test(val)) {
                return 'Content can not include emojis';
              }

              const mergeFields = ['FirstName', 'LastName', 'PhoneNumber', 'EmailAddress', 'Mobile', 'Location', 'Date', 'CommunityName'];
              let tempContent = val;
              mergeFields.map((f) => {
                tempContent = tempContent.replaceAll(`$$${f}$$`, '');
              });
              customFieldNames.map((f) => {
                tempContent = tempContent.replaceAll(`$$${f}$$`, '');
              });
              if (tempContent.trim() === '') {
                return 'The Voice Content cannot contain merge fields only'
              }

              return true;
            },
          }}
          control={control}
          name="VoiceContent.Content"
          render={({ ref, ...props }) => (
            <>
              <Row>
                <Col>
                  <MergeFields
                    dropdownId="sms-content-mergefield"
                    type="voice"
                    textbox={textboxRef}
                    {...props}
                  />
                  <ContentLength name="VoiceContent.Content" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Textarea
                    {...props}
                    highlights={['<Replace Text Here>']}
                    ref={(node: HTMLDivElement) => {
                      textboxRef.current = node;
                      ref.current = node;
                    }}
                    id="voice-content"
                    maxLength={600}
                    rows={5}
                    errors={errors?.VoiceContent?.Content?.message}
                  />
                </Col>
              </Row>
            </>
          )}
        />
      )}
      {(!showVoiceOptions && isRecording) && (
        <>
          {hasAudioData && (
            <>
              <br />
              {!isIE
                && (
                  <>
                    <AudioPlayer
                      autoPlay={false}
                      autoPlayAfterSrcChange={false}
                      src={`data:audio/wav;base64,${audioData}`}
                    />
                    {generateDownloadLink()}
                  </>
                )}
              { isIE && (
                generateDownloadLink()
              ) }
            </>
          )}
          <Dropzone
            onFileRemove={() => {
              setAudioFile(null);
              setAudioData('');
              setValue('VoiceContent.VoiceRecording.Data', '');
              setValue('VoiceContent.VoiceRecording.DocumentId', '0');
              setValue('VoiceContent.VoiceRecording.DownloadUrl', '');
              setValue('VoiceContent.VoiceRecording.FileName', '');
            }}
            rejectFiles={rejectFiles}
            validator={(file) => {
              if (file.size < 1) {
                const f = {
                  code: file.name,
                  message: 'Please ensure the file is not empty and reupload.',
                };
                rejectFiles.push(f);
                setRejectFiles(rejectFiles);
                return f;
              }
              return null;
            }}
            dragAndDropText="Drag and drop a wav file here to upload a recording"
            multiple={false}
            files={audioFile ? [audioFile] : []}
            accept="audio/wav"
            onDropRejected={(e) => {
              toast.error(e[0].errors[0].message);
            }}
            onDropAccepted={async (files) => {
              clearRecording();
              const droppedFile = files[0];
              const b64Data = await getB64DataFromFile(droppedFile)
              setAudioData(b64Data);
              setAudioFile(droppedFile);
              setValue(
                'VoiceContent.VoiceRecording.DownloadUrl',
                '',
                { shouldDirty: true },
              );
              setValue(
                'VoiceContent.VoiceRecording.Data',
                b64Data,
                { shouldDirty: true },
              );
              setValue(
                'VoiceContent.VoiceRecording.DocumentId',
                '0',
                { shouldDirty: true },
              );
              setValue(
                'VoiceContent.VoiceRecording.FileName',
                droppedFile.name,
                { shouldDirty: true },
              );
            }}
          >
            <>
              <Col md="auto">
                <Button>Select Files</Button>
              </Col>
              <Button
                className="ml-1 mr-3"
                onClick={(e) => {
                  requestRecording();
                  e.stopPropagation();
                }}
              >
                Record Message
              </Button>

            </>
          </Dropzone>

          <Modal
            show={showRecordingModal}
            onHide={() => setShowRecordingModal(false)}
            className="RecordingStudioModal"
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Recording Studio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="RecordingModalHeader">
                Please call the following number and enter the pin when prompted:
              </div>
              <div className="RecordingModalBody">
                {`Phone Number: ${recordingStudioNumber}`}
              </div>
              <div className="RecordingModalBody">{`Pin: ${pin}`}</div>
            </Modal.Body>
          </Modal>
        </>
      )}
      {!showVoiceOptions && (
        <>
          <br />
          <Row>
            <Col md={4}>
              <Tooltip
                id="transferTooltip"
                arrow
                title="The recipient of the call will be sent to this phone number when pressing 1 on the keypad."
              >
                <Form.Group>
                  <Input
                    id="VoiceContent.TransferToPhoneNumber"
                    name="VoiceContent.TransferToPhoneNumber"
                    label="Transfer to number"
                    type="text"
                    control={control}
                    mask="(999) 999-9999"
                    errors={errors?.VoiceContent?.TransferToPhoneNumber && 'Invalid Phone Number'}
                    rules={{
                      required: false,
                      validate: (val: any) => {
                        if (val === null || val === undefined || val === '' || val === placeHolder) {
                          return true;
                        }
                        return phoneRegEx.test(val);
                      },
                    }}
                  />
                </Form.Group>
              </Tooltip>
            </Col>
          </Row>
          {showKeypad && (
            <VoiceContentResponses />
          )}
        </>
      )}
    </>
  );
}
