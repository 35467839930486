import React,
{
  ReactElement, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { refreshLegacyToken, refreshToken } from 'reducers/UserInfo';
import { useQueryParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from 'components/LoadingOverlay';

function IconRedirect(): ReactElement {
  const [jwt] = useQueryParam<string>('jwt');
  const [redirectTo] = useQueryParam<string>('redirectTo');
  const [token] = useQueryParam<string>('refresh_token');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!jwt) {
      history.push(`/login?redirectTo=${redirectTo ?? '/'}`);
      return;
    }
    dispatch(refreshLegacyToken(token));
    dispatch(refreshToken(jwt));
    history.push(redirectTo ?? '/');
  }, [dispatch]);

  return (
    <LoadingOverlay isVisible loadingText="Redirecting to Icon..." />
  )
}

export default IconRedirect;
