import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Input, Select, DateInput, Textarea,
} from 'components/FormControls';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { ISurveyCall } from 'types/ISurveyCall';
import {
  Accordion, Button, Card, Col, Modal,
  Row,
} from 'react-bootstrap';
import { TimePicker } from 'components/TimePicker';
import ToggleHeader from 'components/ToggleHeader';
import { useDispatch, useSelector } from 'react-redux';
import { AddSurveyCall, deleteSurveyCall } from 'reducers/SurveyCalls';
import {
  getDischargedPatientById, getSurveyCallsByDischargedPatientId,
  updateSpecificPatientInByIdsDictionary, updateSurveyCall,
} from 'reducers/DischargedPatients';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IOptions } from 'components/Inputs/Select';
import { IUserInfoState } from 'reducers/IUserInfoState';
import { RootState } from 'types/rootState';
import { IDischargedPatient } from 'types/IDischargedPatient';
import moment from 'moment';
import 'moment-timezone';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import SubmitButton from 'components/FormControls/SubmitButton'
import ManualPhoneCallIcon from 'components/ManualPhoneCallIcon';
import AutoCallIcon from 'components/AutoCallIcon'
import TextMessageIcon from 'components/TextMessageIcon';
import { SurveyQuestions } from './SurveyQuestions';

type Props = {
  data: ISurveyCall
  setActiveKey: ()=>void
  clearActiveKey: ()=>void
  setIsAddingNewQuestion?: React.Dispatch<React.SetStateAction<boolean>>
};

export const SurveyCallForm: React.FC<Props> = (
  {
    data, setActiveKey, clearActiveKey, setIsAddingNewQuestion = () => {},
  },
) => {
  const methods = useForm<ISurveyCall>({ mode: 'onBlur' });
  const {
    control, reset, handleSubmit, watch, getValues, errors, register,
  } = methods;
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) : IUserInfoState => state.UserInfo);
  const selectedPatient = useSelector((
    state: RootState,
  ) : IDischargedPatient => state.DischargedPatients.selectedDischargedPatient);

  const deleteCall = useCallback(() => {
    dispatch(showLoading());
    dispatch(deleteSurveyCall(data.SurveyCallId, () => {
      dispatch(getSurveyCallsByDischargedPatientId(data.DischargePatientId))
      dispatch(getDischargedPatientById(data.DischargePatientId, (result) => {
        dispatch(hideLoading());
        dispatch(updateSpecificPatientInByIdsDictionary(result));
      }));
    }));
  }, [data.DischargePatientId, data.SurveyCallId, dispatch]);

  useEffect(() => { if (data) { reset(data); } }, [reset, data]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [saveComplete, setSaveComplete] = useState<boolean>(true);
  const { SendDateTimeUtc } = data;
  const callTypeWatch = watch('CallType');
  const facilityTimeZone = userInfo
    .userInfo.FacilityInfoToCallerIdMapping?.[selectedPatient.Facility]?.TimeZone;
  const zone = facilityTimeZone || userInfo.accountTimezone;
  const zonedDate = moment(SendDateTimeUtc).utc(true).tz(zone);
  const utcNow = moment(new Date()).utc();
  const zonedNow = utcNow.tz(zone);
  const disabledCallType = true;
  const callTypeOptions: IOptions[] = [
    {
      label: 'Auto',
      value: 'Auto',
    },
    {
      label: 'Manual',
      value: 'Manual',
    },
  ];
  const statusOptions: IOptions[] = [
    {
      label: 'Incomplete',
      value: 'Incomplete',
    },
    {
      label: 'Complete',
      value: 'Complete',
    },
  ];
  const callAttemptsOptions: IOptions[] = [
    {
      label: '1',
      value: '1',
    },
    {
      label: '2',
      value: '2',
    },
    {
      label: '3',
      value: '3',
    },
  ];
  const defaultSurveyQuestions = useSelector((state:RootState) => state.UserInfo.userInfo.DefaultSurveyQuestionListIds);
  const onSubmit = (surveyCallData: ISurveyCall): void => {
    const dateTimeLocal = moment.tz(`${surveyCallData.SendDate}T${surveyCallData.SendTime}`, zone);
    let dateTimeUtc = dateTimeLocal.utc();
    if (!dateTimeUtc.isValid()) {
      dateTimeUtc = moment.utc(SendDateTimeUtc);
    }
    const updateData = { ...surveyCallData, SendDateTimeUtc: dateTimeUtc.toISOString() };
    if (surveyCallData.TaskStatus === 'Incomplete' && surveyCallData.TelephonyStatus === 'Complete') {
      updateData.TaskStatus = 'Complete';
    }
    dispatch(showLoading());
    setSaveComplete(false);
    if (surveyCallData.SurveyCallId === -1) {
      if (!surveyCallData.SurveyQuestionListId) {
        updateData.SurveyQuestionListId = defaultSurveyQuestions[surveyCallData.CallType];
      }
      segmentAnalyticsTrack(trackActions.addDischargedPatientCall());
      dispatch(AddSurveyCall(updateData, () => {
        setIsAddingNewQuestion(false);
        dispatch(getSurveyCallsByDischargedPatientId(data.DischargePatientId));
        dispatch(getDischargedPatientById(data.DischargePatientId, (result) => {
          dispatch(updateSpecificPatientInByIdsDictionary(result));
          dispatch(hideLoading());
          setSaveComplete(true);
          clearActiveKey();
        }));
      }));
    } else {
      segmentAnalyticsTrack(trackActions.editDischargedPatientCall());
      dispatch(updateSurveyCall(updateData, () => {
        dispatch(getSurveyCallsByDischargedPatientId(data.DischargePatientId));
        dispatch(getDischargedPatientById(data.DischargePatientId, (result) => {
          dispatch(updateSpecificPatientInByIdsDictionary(result));
          dispatch(hideLoading());
          setSaveComplete(true);
          clearActiveKey();
        }));
      }, (surveyCall) => {
        setSaveComplete(true);

        const enriched = {
          ...surveyCall,
          SendDate: moment.utc(surveyCall.SendDateTimeUtc).tz(zone).format('YYYY-MM-DD'),
          SendTime: moment.utc(surveyCall.SendDateTimeUtc).tz(zone).format('hh:MM a'),
        };

        const conflictedKeys = Object.keys(surveyCallData)
          .filter((item) => ([
            'SendDate',
            'SendTime',
            'CallType',
            'TaskStatus',
            'Comments',
          ].includes(item) && surveyCallData[item] !== enriched[item] ? item : null));
        const stringData = conflictedKeys.reduce(
          (aggregator, currentValue) => `${aggregator}\n${currentValue}: ${enriched[currentValue]}`,
          '',
        );
        conflictedKeys.length && toast.error(
          <>
            <>
              Another team member has updated this survey call.
              <CopyToClipboard
                text={stringData}
              >
                <Button style={{ fontSize: '.75rem' }}>Click to copy your work to the clipboard</Button>
              </CopyToClipboard>
              Please refresh the page to get the most up to date information and then try again
            </>
            {surveyCallData.SurveyCallContent.map((item) => (
              <div key={item.ContentId}>
                <strong>
                  {data.SurveyCallContent.find(
                    (content) => Number(content.ContentId) === Number(item.ContentId),
                  ).MessageContent}
                  :
                </strong>
                {' '}
                {item.SurveyResponse}
              </div>
            ))}
          </>,
        );
      }));
    }
  }
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form ProfilesForm"
      >
        <FormProvider {...methods}>
          <input type="hidden" name="Version" ref={register} />
          <Card>
            <ToggleHeader
              eventKey={data.SurveyCallId.toString()}
              setActiveKey={setActiveKey}
            >
              <div className="row">
                <div className="col-xs-12 col-md d-flex align-items-center text-nowrap">
                  <span className="pr-2">
                    {zonedDate.format('dddd, MMMM DD')}
                  </span>
                  <span className="pr-2">
                    {callTypeWatch === 'Auto' && selectedPatient.CommunicationType?.toLowerCase() === 'voice' && (
                      <>
                        <span className="fa-stack">
                          <AutoCallIcon />
                          {data.TelephonyStatus === 'Cancelled' && (
                            <FontAwesomeIcon
                              icon="slash"
                              title="Cancelled"
                              className="fa-stack-1x"
                              color="red"
                            />
                          )}
                        </span>
                        {data.TransferTransactions
                        && data.TransferTransactions.length
                        && (
                          <>
                            <FontAwesomeIcon
                              icon="exchange-alt"
                              title="Transfer Requested"
                            />
                            <FontAwesomeIcon
                              icon="phone-alt"
                              title="Automated"
                            />
                          </>
                        )}
                      </>
                    )}
                    {callTypeWatch === 'Auto' && selectedPatient.CommunicationType?.toLowerCase() === 'sms' && (
                      <>
                        <span className="fa-stack">
                          <TextMessageIcon />
                          {data.TelephonyStatus === 'Cancelled' && (
                            <FontAwesomeIcon
                              icon="slash"
                              title="Cancelled"
                              className="fa-stack-1x"
                              color="red"
                            />
                          )}
                        </span>
                        {data.TransferTransactions
                        && data.TransferTransactions.length
                        && (
                          <>
                            <FontAwesomeIcon
                              icon="exchange-alt"
                              title="Transfer Requested"
                            />
                            <FontAwesomeIcon
                              icon="phone-alt"
                              title="Automated"
                            />
                          </>
                        )}
                      </>
                    )}
                    {callTypeWatch === 'Manual' && (
                      <span className="fa-stack">
                        <ManualPhoneCallIcon />
                        {data.TelephonyStatus === 'Cancelled' && (
                          <FontAwesomeIcon
                            icon="slash"
                            title="Cancelled"
                            className="fa-stack-1x"
                            color="red"
                          />
                        )}
                      </span>
                    )}
                  </span>
                  {(data.CallType === 'Auto'
                  && data.TransactionCount > 0)
                  && (
                    <span className="pr-2">
                      (
                      {data.TransactionCount}
                      {' '}
                      Call Attempt
                      {data.TransactionCount !== 1 && (
                        <>
                          s
                        </>
                      )}
                      )
                    </span>
                  )}
                  <span className="pr-2 text-truncate">
                    {data.Comments}
                  </span>
                </div>
                <div className="right">
                  {(data.TelephonyStatus === 'Complete' && data.TaskStatus === 'Complete') && (
                    <FontAwesomeIcon
                      icon="check-circle"
                      color="#00FF00"
                      title="This survey call is complete."
                    />
                  )}
                  {(data.TelephonyStatus === 'Complete' && data.TaskStatus === 'Incomplete') && (
                    <div className="right">
                      <FontAwesomeIcon
                        icon="exclamation-circle"
                        color="#FF0000"
                        title="This survey call needs to be reviewed."
                      />
                    </div>
                  )}
                </div>
              </div>
            </ToggleHeader>
            <Accordion.Collapse eventKey={data.SurveyCallId?.toString()}>
              <Card.Body
                style={{ paddingTop: '0.5rem' }}
              >
                <fieldset>
                  {data.TaskStatus === 'Complete' && (
                    <div className="row">
                      <strong className="pr-2">
                        Comments:
                      </strong>
                      {' '}
                      {data?.Comments}
                    </div>
                  )}
                  {(data.TaskStatus === 'Incomplete' && data.TelephonyStatus === 'Incomplete') && (
                    <>
                      <div className="row">
                        <div className="col-xs-12 col-md-12 d-flex align-items-center">
                          <strong className="pr-2">Send date/time: </strong>
                          <DateInput
                            style={{ paddingTop: '1rem' }}
                            control={control}
                            className="event-template-preview"
                            name="SendDate"
                            defaultValue={zonedDate.format('YYYY-MM-DD')}
                            numberOfMonths={1}
                            format="YYYY-MM-DD"
                            id={`SendDate${data.SurveyCallId}`}
                            rules={{
                              required: true,
                              validate: (val: string) => {
                                if (callTypeWatch === 'Auto') {
                                  return moment(val, 'YYYY-MM-DD') >= moment(zonedNow.format('YYYY-MM-DD'));
                                }
                                return true;
                              },
                            }}
                          />
                          {' '}
                          <Col
                            className="d-flex col-md-7"
                          >
                            <Controller
                              control={control}
                              name="SendTime"
                              defaultValue={zonedDate.format('HH:mm')}
                              render={({ onChange, value }) => (
                                <TimePicker
                                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                  className="event-template-preview"
                                  popupClassName="event-template-preview"
                                  allowEmpty={false}
                                  value={value ? moment(value, 'HH:mm') : moment(zonedNow)}
                                  showSecond={false}
                                  onChange={(time) => {
                                    onChange(time.format('HH:mm'));
                                  }}
                                  use12Hours
                                />
                              )}
                              rules={{
                                required: true,
                                validate: (val: any) => {
                                  if (callTypeWatch === 'Auto') {
                                    if (getValues('SendDate') > zonedNow.format('YYYY-MM-DD')) {
                                      return true;
                                    }
                                    return val > zonedNow.format('HH:mm');
                                  }
                                  return true;
                                },
                              }}
                            />
                          </Col>

                        </div>
                      </div>
                      {errors?.SendDate && (
                        <span className="errors d-block text-right">Send date must be in the future</span>
                      )}
                      {errors?.SendTime && (
                        <span className="errors d-block text-right">Send time must be in the future</span>
                      )}
                    </>
                  )}
                  <SurveyQuestions
                    data={data?.SurveyCallContent}
                    surveyCallId={data?.SurveyCallId}
                    isComplete={data.TaskStatus === 'Complete'}
                    telephonyStatus={data.TelephonyStatus}
                  />

                  {(data.TaskStatus === 'Incomplete' && data.TelephonyStatus === 'Incomplete') && (
                    <Row className="flex-nowrap">
                      <Col xs={12} md={6}>
                        <Select
                          id="CallType"
                          name="CallType"
                          label="Call Type"
                          control={control}
                          options={callTypeOptions}
                          disabled={data.SurveyCallId !== -1 && disabledCallType}
                        />
                      </Col>
                      {data.SurveyCallId !== -1 && (
                        <Col xs={12} md={6}>
                          <Select
                            id="TaskStatus"
                            name="TaskStatus"
                            label="Call Status"
                            control={control}
                            options={statusOptions}
                            disabled={callTypeWatch === 'Auto'}
                          />
                        </Col>
                      )}
                    </Row>
                  )}
                  {data.TaskStatus === 'Incomplete' && (
                    <Row className="flex-nowrap">
                      <Col xs={12} md={6}>
                        <Select
                          id="MaxCallAttempts"
                          name="MaxCallAttempts"
                          label="Number of Call Attempts"
                          control={control}
                          options={callAttemptsOptions}
                        />
                      </Col>
                    </Row>
                  )}
                  {data.TaskStatus === 'Incomplete' && (
                    <div className="row">
                      <div className="col-xs-12 col-md-12">
                        <Textarea
                          id="Comments"
                          control={control}
                          name="Comments"
                          label="Comments"
                          rules={{ required: false }}
                          rows={2}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-xs-12 col-md-12">
                      {(data.TaskStatus === 'Incomplete' && data.TelephonyStatus === 'Complete') && (
                        <SubmitButton
                          label="Review"
                          savingLabel="Sending..."
                          className="btn btn-primary right"
                          saveComplete={saveComplete}
                        />
                      )}
                      {(data.TaskStatus === 'Incomplete' && data.TelephonyStatus === 'Incomplete') && (
                        <>
                          <SubmitButton
                            label="Save"
                            savingLabel="Saving..."
                            className="btn btn-primary right"
                            saveComplete={saveComplete}
                          />
                          {data.SurveyCallId < 0 && (
                            <button
                              type="button"
                              onClick={() => {
                                setIsAddingNewQuestion(false);
                              }}
                              className="btn btn-primary right"
                            >
                              Cancel
                            </button>
                          )}

                          {data.SurveyCallId >= 0 && (
                            <button
                              type="button"
                              onClick={() => {
                                setShowDeleteModal(true);
                              }}
                              className="btn btn-primary left"
                            >
                              <FontAwesomeIcon
                                icon="trash-alt"
                                title="Delete survey call"
                              />
                            </button>
                          )}

                        </>
                      )}
                    </div>
                  </div>
                </fieldset>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Input
            id="SurveyCallId"
            name="SurveyCallId"
            label=""
            type="hidden"
            control={control}
          />
          <Input
            id="DischargePatientId"
            name="DischargePatientId"
            label=""
            type="hidden"
            control={control}
          />
          <Input
            id="WellnessCheckId"
            name="WellnessCheckId"
            label=""
            type="hidden"
            control={control}
          />
          <Input
            id="SurveyQuestionListId"
            name="SurveyQuestionListId"
            label=""
            type="hidden"
            control={control}
          />
          <Input
            id="TelephonyStatus"
            name="TelephonyStatus"
            label=""
            type="hidden"
            control={control}
          />
          <Input
            id="RedialStrategy"
            name="RedialStrategy"
            label=""
            type="hidden"
            control={control}
          />
          <Input
            id="TaskStatus"
            name="TaskStatus"
            label=""
            type="hidden"
            control={control}
          />
          {(data.TelephonyStatus === 'Complete' && data.TaskStatus === 'Incomplete') && (
            <Input
              id="CallType"
              name="CallType"
              label=""
              type="hidden"
              control={control}
            />
          )}
        </FormProvider>
      </form>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        className="DeleteActionButtonModal"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header />
        <Modal.Body>
          <FontAwesomeIcon
            icon="trash-alt"
            size="4x"
            color="#FF7052"
          />
          <div className="message">
            <h4>Are you sure?</h4>
            <p>
              This action will delete this call.
            </p>
          </div>
          <button
            type="button"
            className="btn btn-block btn-danger"
            onClick={() => deleteCall()}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-block btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};
