export enum LibraryContentType {
  VOICE = 'Voice',
  SMS = 'Sms',
  EMAIL = 'Email',
}

export interface ISurveyQuestion {
  Keypress: number
  Response: string
}

export interface ILibraryContent {
  [key: string]: any
  ID: string
  Name: string
  Description: string
  Type: string // TODO: should use the enum LibraryContentType.
  VoiceContent: IVoiceContent
  SMSContent: ISMSContent
  EmailContent: IEmailContent
  SourceLanguage: string
}

export interface ILibraryContentPayload {
  Data: ILibraryContent[]
  Pagination: {
    PerPage: number
    Page: number
    TotalItems: number
    TotalPages: number
  }
}

export enum VoiceContentType {
  TTS = 'TextToSpeech',
  RECORDING = 'Recording',
}

export interface IVoiceContent {
  [key: string]: any
  Content: string
  VoiceRecording: ILibraryFile
  VoiceType: string // TODO: should use the enum VoiceContentType.
  TransferToPhoneNumber: string
  SurveyResponses?: ISurveyQuestion[]

}
export interface ILibraryFile {
  [key: string]: any
  FileName: string
  Data?: string
  DownloadUrl?: string
  DocumentId: number
}
export interface ISMSContent {
  [key: string]: any
  Content: string
}
export interface IEmailContent {
  [key: string]: any
  Subject: string
  Display: string
  From: string
  ReplyTo: string
  Content: string
  Attachments?: ILibraryFile[]
  UnlayerEmailContentJson?: string
}

export enum LoadingState {
  NOT_LOADED = 'NOT_LOADED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}
