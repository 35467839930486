import React, {
  forwardRef, useState, useRef, useMemo,
} from 'react';
import clsx from 'clsx';
import { DayPickerRangeController, DayPickerRangeControllerShape, FocusedInputShape } from 'react-dates';
import moment from 'moment';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';

import { ReactComponent as Calendar } from 'styles/images/calendar.svg';
import { ReactComponent as Arrow } from 'styles/images/arrow.svg';
import Input, { InputProps } from './Input';

export interface DateRangeInputProps extends
  Omit<InputProps, 'type' | 'onChange' | 'onBlur'>,
  Partial<Omit<DayPickerRangeControllerShape, 'onBlur' | 'disabled'>>
{
  onChange?: (val: string) => void
  onBlur?: () => void
  format?: string
  delimiter?: string
  navigationUnit?: 'day' | 'month' | 'week'
  onPrev?: (dateStr: string) => void
  onNext?: (dateStr: string) => void
  accountTimezone?: string
}

export const DateRangeInput = forwardRef<HTMLInputElement, DateRangeInputProps>(
  ({
    className, onChange = () => {}, value, name, onFocus, onBlur, placeholder = '00/00/0000 - 00/00/0000',
    numberOfMonths = 2, initialVisibleMonth = null, hideKeyboardShortcutsPanel = true, format = 'MM/DD/YYYY',
    delimiter = ' - ', navigationUnit = 'day', isOutsideRange, onPrev, onNext,
    accountTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone, ...props
  }, forwardedRef) => {
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape|null>(null);
    const createdRef = useRef<HTMLInputElement>();
    const ref = forwardedRef ?? createdRef;

    const [startDate, endDate] = useMemo(() => (value
      ? value.split(' - ').map((dateStr) => {
        if (!dateStr) {
          return null;
        }

        const res = moment(dateStr, format, true);

        return res.isValid() ? res : null;
      })
      : [null, null]),
    [value, format]);

    const [prevData, nextData] = useMemo(() => {
      if (!startDate || !endDate) {
        return [null, null];
      }

      const diff = endDate.diff(startDate, navigationUnit) + 1;
      const prevStartDate = startDate.clone().subtract(diff, navigationUnit);
      const prevEndDate = endDate.clone().subtract(diff, navigationUnit);
      const nextStartDate = startDate.clone().add(diff, navigationUnit);
      const nextEndDate = endDate.clone().add(diff, navigationUnit);

      if (!isOutsideRange) {
        return [{
          startDate: prevStartDate,
          endDate: prevEndDate,
        }, {
          startDate: nextStartDate,
          endDate: nextEndDate,
        },
        ];
      }

      return [
        (isOutsideRange(prevStartDate) || isOutsideRange(prevEndDate)) ? null : {
          startDate: prevStartDate,
          endDate: prevEndDate,
        },
        (isOutsideRange(nextStartDate) || isOutsideRange(nextEndDate)) ? null : {
          startDate: nextStartDate,
          endDate: nextEndDate,
        },
      ]
    }, [endDate, startDate, isOutsideRange, navigationUnit]);
    const onDatesChange = (
      { startDate: _startDate, endDate: _endDate } : {startDate: moment.Moment | null, endDate: moment.Moment | null },
    ): void => {
      const dateStr = [
        _startDate ? _startDate.format(format) : '',
        _endDate ? _endDate.format(format) : '',
      ].join(delimiter);
      onChange(dateStr);
    }

    const dayPickerProps = {
      numberOfMonths,
      initialVisibleMonth,
      hideKeyboardShortcutsPanel,
      isOutsideRange,
      ..._pick(props, [
        'renderMonthText',
        'renderMonthElement',
        'renderWeekHeaderElement',
        'enableOutsideDays',
        'withPortal',
        'daySize',
        'noBorder',
        'verticalBorderSpacing',
        'horizontalMonthPadding',
        'dayPickerNavigationInlineStyles',
        'navPosition',
        'navPrev',
        'navNext',
        'renderNavPrevButton',
        'renderNavNextButton',
        'noNavButtons',
        'noNavNextButton',
        'noNavPrevButton',
        'onOutsideClick',
        'renderCalendarDay',
        'renderDayContents',
        'renderCalendarInfo',
        'renderKeyboardShortcutsButton',
        'renderKeyboardShortcutsPanel',
        'calendarInfoPosition',
        'firstDayOfWeek',
        'verticalHeight',
        'transitionDuration',
        'isFocused',
        'showKeyboardShortcuts',
        'onTab',
        'onShiftTab',
        'monthFormat',
        'weekDayFormat',
        'phrases',
        'dayAriaLabelFormat',
        'isRTL',
        'startDateOffset',
        'endDateOffset',
        'minDate',
        'maxDate',
        'onClose',
        'keepOpenOnDateSelect',
        'minimumNights',
        'isDayBlocked',
        'isDayHighlighted',
        'getMinNightsForHoverDate',
        'daysViolatingMinNightsCanBeClicked',
        'orientation',
        'onPrevMonthClick',
        'onNextMonthClick',
      ]),
    }

    const inputProps = _omit(props, Object.keys(dayPickerProps));

    const InputIcon: React.FC<{ className: string }> = React.memo(({ className: _className }) => (
      <div className="Date-Range-Input__icons-container">
        <Calendar
          className={clsx(_className, 'Date-Range-Input__calendar')}
          onClick={() => {
            if (typeof ref === 'object') {
              ref.current?.focus();
            }
          }}
        />
        <Arrow
          className={clsx(_className, 'Date-Range-Input__arrow-left', !prevData && 'disabled')}
          onClick={() => {
            const dateStr = [
              prevData.startDate.format(format),
              prevData.endDate.format(format),
            ].join(delimiter)
            onChange(dateStr);
            if (onPrev) {
              onPrev(dateStr);
            }
          }}
        />
        <Arrow
          className={clsx(_className, 'Date-Range-Input__arrow-right', !nextData && 'disabled')}
          onClick={() => {
            const dateStr = [
              nextData.startDate.format(format),
              nextData.endDate.format(format),
            ].join(delimiter);
            onChange(dateStr);
            if (onNext) {
              onNext(dateStr);
            }
          }}
        />
      </div>
    ));

    return (
      <div className="position-relative">
        <Input
          type="text"
          name={name}
          className={clsx('Date-Range-Input', className)}
          icon={InputIcon}
          placeholder={placeholder}
          onFocus={(e) => {
            setFocusedInput('startDate');
            if (onFocus) {
              onFocus(e);
            }
          }}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          ref={ref}
          {...inputProps}
        />

        {focusedInput && (
        // eslint-disable-next-line
        // @ts-ignore
          <DayPickerRangeController
            onDatesChange={onDatesChange}
            startDate={startDate}
            endDate={endDate}
            focusedInput={focusedInput}
            onFocusChange={(_focusedInput) => {
              setFocusedInput(_focusedInput);
              if (!focusedInput) {
                if (onBlur) {
                  onBlur();
                }
              }
            }}
            onOutsideClick={(e) => {
              if (typeof ref === 'object' && ref.current.parentNode.contains(e.target as Node)) {
                return;
              }
              setFocusedInput(null);
              if (onBlur) {
                onBlur();
              }
            }}
            {...dayPickerProps}
          />
        )}
      </div>
    )
  },
);

export default DateRangeInput;
