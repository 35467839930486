import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { IErrorState } from 'reducers/IErrorState';
import useErrors from 'components/hooks/useErrors';
import { IDischargedPatient } from 'types/IDischargedPatient';
import { IPatientFormData } from 'types/IPatientFormData';
import { ISurveyCall } from 'types/ISurveyCall';
import { RootState } from '../../types/rootState';
import { IProfile } from '../../reducers/IProfile';
import PatientDetailsForm from './PatientDetailsForm';

interface IProps {
  selectedCallId: number
  selectedDischargedPatient: IDischargedPatient
  showPatientInfoForm: boolean
  setShowPatientInfoForm: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedPatient:(patient:IDischargedPatient) => void
  setSelectedCall:(patient:IDischargedPatient, surveyCall:ISurveyCall) => void
  showBack:boolean
  hasNoteChanged?: boolean
  setHasNoteChanged?: React.Dispatch<React.SetStateAction<boolean>>
  startExpanded?:boolean
}

export const PatientDetailsPage: React.FC<IProps> = (
  {
    selectedDischargedPatient,
    selectedCallId,
    showPatientInfoForm,
    setShowPatientInfoForm,
    setSelectedPatient,
    setSelectedCall,
    showBack,
    hasNoteChanged,
    setHasNoteChanged,
    startExpanded,
  }: IProps,
) => {
  const patientProfile: IProfile | undefined = useSelector(
    (state: RootState) => state.Profiles.byIds[selectedDischargedPatient?.ProfileId],
  );
  const contactProfile: IProfile | undefined = useSelector(
    (state: RootState) => state.Profiles.byIds[selectedDischargedPatient?.ContactProfileId],
  );
  const formData: IPatientFormData = {
    patientProfile: patientProfile || {
      Id: 0,
      FirstName: '',
      LastName: '',
      CustomerProfileID: '',
      UserType: 'seniors',
    },
    contactProfile: contactProfile || {
      Id: 0,
      FirstName: '',
      LastName: '',
      CustomerProfileID: '',
      UserType: 'family',
    },
    dischargedPatient: selectedDischargedPatient,
  };
  const profileErrors: IErrorState = useSelector((state: RootState) => state.Error);
  useErrors(profileErrors);
  return (
    <div>
      <Helmet>
        <title>Follow-Up Survey Details</title>
      </Helmet>
      <div className="">
        <PatientDetailsForm
          key={formData.dischargedPatient.DischargePatientId}
          data={formData}
          selectedCallId={selectedCallId}
          showPatientInfoForm={showPatientInfoForm}
          setShowPatientInfoForm={setShowPatientInfoForm}
          setSelectedPatient={setSelectedPatient}
          setSelectedCall={setSelectedCall}
          showBack={showBack}
          hasNoteChanged={hasNoteChanged}
          setHasNoteChanged={setHasNoteChanged}
          startExpanded={startExpanded === undefined ? true : startExpanded}
        />
      </div>
    </div>
  )
}
export default PatientDetailsPage;
