// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min';

import FroalaEditor from 'froala-editor';
import log from 'lib/logging';

export { default as FroalaEditorComponent } from 'react-froala-wysiwyg';

FroalaEditor.RegisterCommand('merge_fields', {
  title: 'Personalization',
  type: 'dropdown',
  icon: 'Personalization',
  focus: true,
  undo: true,
  refreshAfterCallback: true,
  options: {
    $$FirstName$$: 'FirstName',
    $$LastName$$: 'LastName',
    $$PhoneNumber$$: 'PhoneNumber',
    $$EmailAddress$$: 'EmailAddress',
    $$Mobile$$: 'Mobile',
    $$Location$$: 'Location',
    $$Date$$: 'Date',
    $$CommunityName$$: 'CommunityName',
  },
  callback(cmd, val) {
    this.html.insert(val);
  },
  // Callback on refresh.
  refresh($btn) {
    log.info('do refresh');
  },
  // Callback on dropdown show.
  refreshOnShow($btn, $dropdown) {
    log.info('do refresh when show');
  },
});

FroalaEditor.RegisterCommand('merge_fields_survey', {
  title: 'Personalization',
  type: 'dropdown',
  icon: 'Personalization',
  focus: true,
  undo: true,
  refreshAfterCallback: true,
  options: {
    $$FirstName$$: 'FirstName',
    $$LastName$$: 'LastName',
    $$PhoneNumber$$: 'PhoneNumber',
    $$EmailAddress$$: 'EmailAddress',
    $$Mobile$$: 'Mobile',
    $$Location$$: 'Location',
    $$Date$$: 'Date',
    $$CommunityName$$: 'CommunityName',
    $$SURVEY_URL$$: 'SURVEY URL',
  },
  callback(cmd, val) {
    this.html.insert(val);
  },
  // Callback on refresh.
  refresh($btn) {
    log.info('do refresh');
  },
  // Callback on dropdown show.
  refreshOnShow($btn, $dropdown) {
    log.info('do refresh when show');
  },
});
