import { PaginatedPayload } from './request';

interface ConversationProfile {
  FirstName: string
  LastName: string
  CustomerProfileID: string
  ProfileID: number
  SmsEnabled: boolean
}

export enum MessageType {
  Reply = 'Reply',
  Direct = 'Direct',
  Broadcast = 'Broadcast'
}

export enum Status {
  Sent = 'Sent',
  Error = 'Failed',
  Sending = 'New'
}

export interface ConversationMessage {
  MessageId: string
  MessageDateUTC: string // YYYY-MM-DDTHH:mm:ss.SSS
  MessageContent:string
  MessageType: MessageType
  Status: Status
  ErrorMessage: string|null
  IsHidden: boolean
}

export interface IConversation {
  ConversationId: string
  AccountId: number
  AccountNumber: string
  ProfileNumber: string
  LastRecievedMessage: ConversationMessage
  Profiles: ConversationProfile[]
  IsRead: boolean
}

export interface ConversationMessagesPayload extends PaginatedPayload {
  Messages: ConversationMessage[]
}

export interface ConversationsPayload extends PaginatedPayload {
  SmsConversations: IConversation[]
}

export interface SendSmsBody {
  SentFrom: string
  RecipientNumber: string
  Content: string
}
