import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateDischargedPatient } from 'reducers/DischargedPatients';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'react-bootstrap';
import { FroalaEditorComponent } from 'components/FroalaEditor';
import { RootState } from 'types/rootState';

function strip(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

interface IProps{
  patientId: number
  notes: string
  setNotes: (x:any)=>any
  setHasNoteChanged: (x:any)=>any
}

export function NotesComponent({
  patientId,
  notes,
  setNotes,
  setHasNoteChanged,
}:IProps):React.ReactElement {
  const dispatch = useDispatch();
  const loadingIndicators = useSelector(
    (state: RootState) => state.DischargedPatients.loadingIndicators,
  );
  const isDeleting = !!loadingIndicators.delete;

  const onNotesClick = () => {
    const myData = {
      DischargePatientId: patientId,
      Notes: notes,
    };
    dispatch(updateDischargedPatient(myData, (dcp) => {
      if (notes !== dcp.Notes) {
        setNotes(dcp.Notes);
        toast.error(
          <>
            Another team member has updated this patient&apos;s notes.
            <CopyToClipboard text={strip(notes)}>
              <Button style={{ fontSize: '.75rem' }}>Click to copy your work to the clipboard</Button>
            </CopyToClipboard>
            Paste your notes into the notes field to continue.
          </>,
        );
      }
    }));
    setHasNoteChanged(false);
  };

  return (
    <div>
      <div className="pt-4">
        <FroalaEditorComponent
          config={{
            // env variables in react app MUST be
            // prefixed with 'REACT_APP_', eg: REACT_APP_SOME_VARIABLES
            key: process.env.REACT_APP_FROALA_EDITOR_LICENSE,
            height: 250,
            attribution: false,
            fontFamily: {
              "'Arial'": 'Arial',
              "'Comic Sans Ms'": 'Comic Sans Ms',
              "'Courier New'": 'Courier New',
              "'Georgia'": 'Georgia',
              "'Lucida Sans Unicode'": 'Lucida Sans Unicode',
              "'Tahoma'": 'Tahoma',
              "'Times New Roman'": 'Times New Roman',
              "'Trebuchet Ms'": 'Trebuchet Ms',
              "'Verdana'": 'Verdana',
            },
            fileUpload: false,
            quickInsertEnabled: false,
            // Set the image upload parameter.
            imageUploadParam: 'upload',
            toolbarSticky: false,
            imagePaste: false,
            pastePlain: true,
            // Set the image upload URL.
            imageUploadURL: '/UserControls/ckConnector/vfuploader.aspx',
            // Additional upload params.
            imageUploadParams: {},
            // Set request type.
            imageUploadMethod: 'POST',
            // Set max image size to 5MB.
            imageMaxSize: 5 * 1024 * 1024,
            imageManagerPreloader: '/images/loader.gif',
            // Set page size.
            imageManagerPageSize: 20,
            // Set a scroll offset (value in pixels).
            imageManagerScrollOffset: 10,
            // Set the load images request URL.
            imageManagerLoadURL: '/UserControls/ckConnector/vfconnector.aspx',
            // Set the load images request type.
            imageManagerLoadMethod: 'GET',
            // Additional load params.
            imageManagerLoadParams: {},
            // Set the delete image request URL.
            imageManagerDeleteURL: 'http://example.com/delete_image',
            // Set the delete image request type.
            imageManagerDeleteMethod: 'DELETE',
            // Additional delete params.
            imageManagerDeleteParams: {
              param: 'value',
            },
            saveInterval: 0,
            // Allow to upload PNG and JPG.
            imageAllowedTypes: ['jpeg', 'jpg', 'png'],
            width: '100%',
            toolbarButtons: {
              moreText: {
                buttons: ['bold',
                  'underline',
                  'italic',
                  'strikeThrough',
                  'subscript',
                  'superscript',
                  'fontFamily',
                  'fontSize'],
                buttonsVisible: 0,
              },
              moreParagraph: {
                buttons: ['formatOL',
                  'formatUL',
                  'outdent',
                  'indent',
                  'alignLeft',
                  'alignCenter',
                  'alignRight',
                  'alignJustify',
                  'insertImage',
                  'insertTable'],
                buttonsVisible: 0,
              },
            },
          }}
          model={notes}
          onModelChange={setNotes}
        />
      </div>
      <button className="btn btn-primary" type="button" onClick={onNotesClick} disabled={isDeleting}>
        Save Notes

      </button>
    </div>
  );
}
