import API from './API'
import { IRecordingSession } from '../reducers/IRecordingSession';

export const API_PATH = '/api/v2/recordingstudio'

export async function getRecordingSessionPin(): Promise<string> {
  const response = await API.post<IRecordingSession>(`${API_PATH}/session`);
  const pin = response.data.Pin;
  return pin;
}
export async function checkRecordingSession(pin: string): Promise<IRecordingSession> {
  const sessionResponse = await API.get<IRecordingSession>(`${API_PATH}/session/${pin}`);
  return sessionResponse.data;
}

export async function getRecordingContentByPin(pin: string): Promise<string> {
  const contentResponse = await API.get<string>(`${API_PATH}/session/${pin}/content`);
  return contentResponse.data;
}
