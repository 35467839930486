import { Switch, Route } from 'react-router-dom';
import React from 'react';
import Dashboard from 'pages/dashboard';

function DashboardRoutes() {
  return (
    <Switch>
      <Route path="/dischargedpatients-dashboard" component={Dashboard} />
    </Switch>
  );
}

export default DashboardRoutes;
