import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Input } from 'components/FormControls';
import { selectDefaultEmailFields } from 'reducers/UserDetail';

export const EmailFields = () => {
  const {
    setValue, control, errors, watch,
  } = useFormContext();
  const defaultEmailFields = useSelector(selectDefaultEmailFields);

  const emailSelected = watch('Channels')?.includes('email');

  React.useEffect(() => {
    if (emailSelected && defaultEmailFields) {
      const { ReplyToEmailAddress, SenderEmailAddress, SenderDisplayName } = defaultEmailFields;
      setValue('DisplayName', SenderDisplayName, { shouldDirty: false });
      setValue('ReplyTo', ReplyToEmailAddress, { shouldDirty: false });
      setValue('From', SenderEmailAddress, { shouldDirty: false });
    }
  }, [emailSelected, defaultEmailFields, setValue]);

  if (!emailSelected) {
    return null;
  }

  return (
    <>
      <Input
        id="EmailContentDisplay"
        name="DisplayName"
        label="Sender's Display Name"
        type="text"
        control={control}
        errors={errors?.DisplayName?.message}
        rules={{ required: "Sender's Display Name is required" }}
        maxLength={255}
        className="emailInput"
        required
      />

      <Input
        id="EmailContentFrom"
        name="From"
        label="Sender's Email Address"
        maxLength={64}
        type="email"
        control={control}
        errors={errors?.From?.message}
        rules={{
          required: "Sender's Email is required",
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
            message: "Sender's Email is invalid.",
          },
        }}
        required
        className="emailInput"
      />

      <Input
        id="EmailContent.ReplyTo"
        name="ReplyTo"
        label="Reply-To Email Address"
        type="email"
        control={control}
        errors={errors?.ReplyTo?.message}
        maxLength={64}
        rules={{
          required: 'Reply-To Email is required',
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
            message: 'Reply-To Email is invalid.',
          },
        }}
        required
        className="emailInput"
      />
    </>
  )
}
