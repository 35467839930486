import Cookies from 'js-cookie';
// eslint-disable-next-line import/no-cycle

const AUTH_COOKIE_NAME = 'vf-jwt';
const REFRESH_TOKEN_COOKIE_NAME = 'vf-refresh-token';

class PersistAuth {
  static storeToken(token: string): void {
    Cookies.set(AUTH_COOKIE_NAME, token);
  }

  static storeRefreshToken(refreshtoken: string): void {
    Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refreshtoken);
  }

  static getToken(): string {
    const token = Cookies.get(AUTH_COOKIE_NAME) || '';

    return token;
  }

  static getRefreshToken(): string {
    const token = Cookies.get(REFRESH_TOKEN_COOKIE_NAME) || '';
    return token;
  }

  static destroyToken(): void {
    Cookies.remove(AUTH_COOKIE_NAME);
  }

  static destroyRefreshToken(): void {
    Cookies.remove(REFRESH_TOKEN_COOKIE_NAME);
  }
}

export default PersistAuth;
