import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface PromptModalProps {
  onCancel: () => void
  onAbandonChanges: () => void
  show: boolean
}

export const PromptModal: React.FC<PromptModalProps> = ({ show, onAbandonChanges, onCancel }) => (
  <Modal
    show={show}
    onHide={onCancel}
  >
    <Modal.Header className="mb-3">
      <Modal.Title>Broadcast Changes</Modal.Title>
    </Modal.Header>
    <Modal.Body className="mb-3">
      You have unsaved changes, are you sure you want to leave?
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="outline-danger"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        variant="danger"
        className="ml-2"
        onClick={onAbandonChanges}
      >
        Abandon&nbsp;changes
      </Button>
    </Modal.Footer>
  </Modal>
);
