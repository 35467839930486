import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDischargedPatientsPage } from 'reducers/DischargedPatients';
import { RootState } from 'types/rootState';
import { NumberParam, useQueryParam } from 'use-query-params';
import { IDischargedPatient } from 'types/IDischargedPatient';
import { sortDischargedPatients } from 'utils/sortUtils';
import { DischargedPatientItem } from './DischargedPatientItem';
import { getProfilesByCpids } from '../../reducers/Profiles';

type Props = {
  setSelectedPatient:(patient:IDischargedPatient) => void
  sortField: string
  sortDirection: string
};

export const DischargedPatientsList: React.FC<Props> = (
  { setSelectedPatient, sortField, sortDirection },
) => {
  const dispatch = useDispatch();
  const [currentPage] = useQueryParam('page', NumberParam);

  const dischargedPatientsDictionary = useSelector(
    selectDischargedPatientsPage(currentPage || 1),
  );

  const dischargedPatients = useMemo(() => Object.values(dischargedPatientsDictionary),
    [dischargedPatientsDictionary])

  const allprofilesbyids = useSelector((state: RootState) => state.Profiles.byIds)
  const sortedDischargedPatients = sortDischargedPatients(
    dischargedPatients, allprofilesbyids, sortDirection, sortField,
  );

  useEffect(() => {
    if (dischargedPatients) {
      const profileIds = dischargedPatients.reduce((returnArray,
        dischargedPatient) => [...returnArray,
        dischargedPatient.ContactProfileId,
        dischargedPatient.ProfileId],
      []);
      if (profileIds?.length > 0) {
        dispatch(getProfilesByCpids(profileIds));
      }
    }
  }, [dischargedPatients, dispatch]);

  const loading = useSelector((state: RootState) => state.DischargedPatients.loading)

  return (
    <>
      {loading && (
        <div style={{ height: '2rem' }} className="pb-1">
          <span className="loading-text">Loading...</span>
        </div>
      )}

      <div style={{ height: 605, overflow: 'auto', paddingBottom: 0 }}>
        {sortedDischargedPatients?.length > 0 && (
          sortedDischargedPatients
            .filter(({ wasInSearchResults }) => wasInSearchResults !== false)
            .map((patient) => (
              <DischargedPatientItem
                key={patient.DischargePatientId}
                data={patient}
                setSelectedPatient={setSelectedPatient}
              />
            ))
        )}
        {sortedDischargedPatients?.length === 0 && !loading && (
          <span style={{ paddingLeft: '1.0rem' }}>No Patient Records Found.</span>
        )}
      </div>
    </>
  )
}
