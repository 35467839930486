import { Input } from 'components/FormControls';
import { NoNavFrame } from 'pages/Login';
import React, { ReactElement, useState } from 'react';

import {
  Form,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import API from 'api/API';
import { Redirect } from 'react-router-dom';
import { authenticateUserSuccess, handleSaveToken } from 'reducers/UserInfo';
import { RootState } from '../../types/rootState';

interface IMfa {
  pin: string
}

export default function Mfa():ReactElement {
  return (
    <NoNavFrame title="Enter Mfa Secret Code.">
      <MfaComponent />
    </NoNavFrame>
  );
}

function MfaComponent(): ReactElement {
  const {
    handleSubmit, errors, control,
  } = useForm<IMfa>();
  const dispatch = useDispatch();
  const [errorState, setErrorState] = useState<string>(null);
  const {
    startPath, changePassword, loggedIn, uniqueName, requireMfa,
  } = useSelector((state: RootState) => state.UserInfo);
  const onSubmit = async (values) => {
    try {
      const response = await API.post('/api/v2/user/mfa', { pin: values.pin });
      handleSaveToken(response.data.Token);
      dispatch(authenticateUserSuccess(response.data));
    } catch (e) {
      setErrorState('Please enter the correct pin')
    }
  };

  const renderTitle = () => {
    if (errorState) {
      return (
        <h4
          className="danger"
        >
          {errorState}
        </h4>
      );
    }
    return (
      <h4>Enter your secret code</h4>
    )
  }

  if (loggedIn && changePassword && !requireMfa) {
    const url = `/changePassword?source=requireChangePassword&user=${uniqueName}`;
    return (
      <Redirect to={url} />
    );
  }
  if (loggedIn && !changePassword && !requireMfa) {
    return (<Redirect to={startPath || '/calendar'} />);
  }

  return (
    <>
      {renderTitle()}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Input
            id="pin"
            name="pin"
            type="text"
            label="Pin"
            control={control}
            rules={{ required: 'Password must be supplied' }}
            autoComplete="off"
          />
          <span>
            {errors.pin && errors.pin.message}
              &nbsp;
          </span>
        </Form.Group>
        <button className="btn btn-primary" type="submit">Submit</button>
      </Form>
    </>
  )
}
