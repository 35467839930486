import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getLibraryContent,
  getAllLibraryContent,
} from 'reducers/LibraryContent';
import { ILibraryContent, LibraryContentType } from 'types/ILibraryContent';

type LibraryFilters = {
  type: LibraryContentType
};

export function useLibraries(filters?: LibraryFilters):ILibraryContent[] {
  const dispatch = useDispatch();
  let libraries = useSelector(getAllLibraryContent);
  if (filters?.type) {
    libraries = libraries.filter((library) => library.Type === filters.type);
  }

  useEffect(() => {
    dispatch(getLibraryContent());
  }, [dispatch]);

  return libraries;
}
