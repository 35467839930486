import React, { FC, ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { ILibraryContent, LibraryContentType } from 'types/ILibraryContent';
import {
  Card, Dropdown, DropdownButton,
} from 'react-bootstrap';
import { useLibraries } from 'components/hooks/useLibraryContent';
import { TemplateOrEvent } from 'types/IEvent';
import { SmsContent } from './SmsContent';
import { GenerateContentButton } from './GenerateContentButton';

type props = {
  removeButton: ReactElement
  data?: TemplateOrEvent
  setShowGenerateModal?: (show: 'sms') => void
  isUrlLinkSurvey?: boolean
}

export const TemplateContentSms: FC<props> = ({
  removeButton, data, setShowGenerateModal, isUrlLinkSurvey,
}: props) => {
  const { setValue, watch } = useFormContext();
  const smartContentEnabled = !!setShowGenerateModal;
  const type = LibraryContentType.SMS;
  const selectedLanguage = watch('SourceLanguage');
  const libraries = useLibraries({ type }).filter(
    (lib) => lib.SourceLanguage === selectedLanguage);

  const onSelectLibrary = (library: ILibraryContent) => {
    setTimeout(() => {
      setValue('SMSContent', library.SMSContent);
    }, 1000);
  };
  const smsContentTypeOptions = libraries.map((l) => ({
    id: l.ID,
    label: l.Name,
    value: l.ID,
  }));
  const generateLibraryItems = (options: any) => {
    if (options && options.length > 0) {
      return (
        <>
          {options.map(({ id, label }) => (
            <Dropdown.Item key={id} eventKey={id}>
              {label}
            </Dropdown.Item>
          ))}
        </>
      )
    }
    return (
      <Dropdown.Item>No Content</Dropdown.Item>
    );
  }
  return (
    <Card className="mt-4">
      <Card.Header as="h4">
        Text Message Content
        {removeButton}
        <div className="float-right d-flex">
          {smartContentEnabled && (
            <GenerateContentButton
              setShowGenerateModal={setShowGenerateModal}
              channel="sms"
            />
          )}
          <DropdownButton
            id="select-library-content"
            title="Select Content from Library"
            variant="secondary"
            size="sm"
            onSelect={(e) => {
              if (libraries && libraries.length > 0) {
                onSelectLibrary(libraries.find((lib) => lib.ID.toString() === e));
              }
            }}
          >
            {generateLibraryItems(smsContentTypeOptions)}
          </DropdownButton>
        </div>
      </Card.Header>
      <Card.Body>
        <SmsContent smsContent={data?.SMSContent} isUrlLinkSurvey={isUrlLinkSurvey} />
      </Card.Body>
    </Card>
  );
};
