import useSWR, { SWRConfiguration, Fetcher } from 'swr';

import { ITemplate } from 'types/IEvent';
import * as TemplatesApi from 'api/TemplatesAPI';

export const useTemplateQuery = (
  templateId: number|undefined,
  type: 'template'|'transactional' = 'template',
  accountId: number = undefined,
  {
    enabled = !!templateId,
    dedupingInterval = 0,
    ...options
  }: SWRConfiguration<ITemplate, any, Fetcher<ITemplate>> & { enabled?: boolean } = {},
) => useSWR<ITemplate>(
  enabled
    ? ['template', templateId]
    : null,
  () => {
    if (accountId === undefined) {
      return TemplatesApi.templateGet(templateId, type);
    }
    return TemplatesApi.getAccountTemplateById(templateId, accountId);
  },
  {
    dedupingInterval,
    ...options,
  },
)
