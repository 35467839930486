import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { AppThunk } from 'app/appThunk';
import { IFilterableIndexParams } from 'types/ITemplatesIndexParams';
import { normalize, schema } from 'normalizr';
import { IndexPayload } from 'types/request';
import {
  ISurveyQuestionList, ISurveyQuestionListItem, SurveyQuestionListItemContentTypeEnum,
} from 'types/ISurveyQuestionList';
import { RootState } from 'types/rootState';
import { ISurveyQuestionListState } from './ISurveyQuestionListState';
import { hasError } from './Error';

export const INITIAL_STATE: ISurveyQuestionListState = {
  indexLoading: false,
  createLoading: false,
  updateLoading: false,
  byIdLoading: false,
  byId: null,
  byIds: {},
  pagedIds: {},
  all: [],
  allLoading: false,
  pagination: {
    Page: 1,
    PerPage: 20,
    TotalItems: 0,
    TotalPages: 1,
  },
  items: {
    indexLoading: false,
    createLoading: false,
    updateLoading: false,
    byIdLoading: false,
    byId: null,
    byIds: [],
  },
}

const surveyQuestionListSchema = new schema.Entity('SurveyQuestionLists', {}, { idAttribute: 'SurveyQuestionListId' });

const SurveyQuestionList = createSlice({
  name: 'SurveyQuestionList',
  initialState: INITIAL_STATE,
  reducers: {
    surveyQuestionListIndexStarted(draftReducerState) {
      draftReducerState.indexLoading = true;
    },
    surveyQuestionListIndexFailed(draftReducerState) {
      draftReducerState.indexLoading = false;
    },
    surveyQuestionListByIdSuccess(
      draftReducerState,
      action: PayloadAction<ISurveyQuestionList>,
    ) {
      draftReducerState.byId = action.payload;
    },
    surveyQuestionListIndexSuccess(
      draftReducerState,
      action: PayloadAction<IndexPayload<ISurveyQuestionList>>,
    ) {
      const normalizedPayload = normalize(action.payload, {
        SurveyQuestionLists: [surveyQuestionListSchema],
      });
      const templateIds = normalizedPayload.result.SurveyQuestionLists;
      const {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      } = action.payload.Pagination;

      draftReducerState.byIds = {
        ...draftReducerState.byIds,
        ...normalizedPayload.entities.SurveyQuestionLists,
      };

      draftReducerState.pagedIds = {
        ...draftReducerState.pagedIds,
        [Page]: templateIds,
      };

      draftReducerState.pagination = {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      };

      draftReducerState.indexLoading = false;
    },
    surveyQuestionListCreateStarted(draftReducerState) {
      draftReducerState.createLoading = true;
    },
    surveyQuestionListCreateEnded(draftReducerState) {
      draftReducerState.createLoading = false;
    },
    surveyQuestionListUpdateStarted(draftReducerState) {
      draftReducerState.updateLoading = true;
    },
    surveyQuestionListUpdateEnded(draftReducerState) {
      draftReducerState.updateLoading = false;
    },
    surveyQuestionListByIdStarted(draftReducerState) {
      draftReducerState.byId = null;
      draftReducerState.byIdLoading = true;
    },
    surveyQuestionListByIdEnded(draftReducerState) {
      draftReducerState.byIdLoading = false;
    },
    // items
    surveyQuestionListItemIndexStarted(draftReducerState) {
      draftReducerState.items.indexLoading = true;
    },
    surveyQuestionListItemIndexFailed(draftReducerState) {
      draftReducerState.items.indexLoading = false;
    },
    surveyQuestionListItemByIdSuccess(
      draftReducerState,
      action: PayloadAction<ISurveyQuestionListItem>,
    ) {
      draftReducerState.items.byId = action.payload;
    },
    surveyQuestionListItemIndexSuccess(
      draftReducerState,
      action: PayloadAction<IndexPayload<ISurveyQuestionListItem>>,
    ) {
      draftReducerState.items.byIds = [...action.payload.Data];
      draftReducerState.items.indexLoading = false;
    },
    surveyQuestionListItemCreateStarted(draftReducerState) {
      draftReducerState.items.createLoading = true;
    },
    surveyQuestionListItemCreateEnded(draftReducerState) {
      draftReducerState.items.createLoading = false;
    },
    surveyQuestionListItemUpdateStarted(draftReducerState) {
      draftReducerState.items.updateLoading = true;
    },
    surveyQuestionListItemUpdateEnded(draftReducerState) {
      draftReducerState.items.updateLoading = false;
    },
    surveyQuestionListItemByIdStarted(draftReducerState) {
      draftReducerState.items.byId = null;
      draftReducerState.items.byIdLoading = true;
    },
    surveyQuestionListItemByIdEnded(draftReducerState) {
      draftReducerState.items.byIdLoading = false;
    },
    surveyQuestionListAllSuccess(draftReducerState, action) {
      draftReducerState.all = action.payload?.SurveyQuestionLists ?? [];
    },
    surveyQuestionListAllStarted(draftReducerState) {
      draftReducerState.allLoading = true;
    },
    surveyQuestionListAllEnded(draftReducerState) {
      draftReducerState.allLoading = false;
    },
  },
});

export const {
  surveyQuestionListIndexStarted,
  surveyQuestionListIndexFailed,
  surveyQuestionListIndexSuccess,
  surveyQuestionListCreateEnded,
  surveyQuestionListCreateStarted,
  surveyQuestionListUpdateEnded,
  surveyQuestionListUpdateStarted,
  surveyQuestionListByIdSuccess,
  surveyQuestionListByIdStarted,
  surveyQuestionListByIdEnded,
  surveyQuestionListAllSuccess,
  surveyQuestionListAllStarted,
  surveyQuestionListAllEnded,
  // items
  surveyQuestionListItemIndexStarted,
  surveyQuestionListItemIndexFailed,
  surveyQuestionListItemIndexSuccess,
  surveyQuestionListItemCreateEnded,
  surveyQuestionListItemCreateStarted,
  surveyQuestionListItemUpdateEnded,
  surveyQuestionListItemUpdateStarted,
  surveyQuestionListItemByIdSuccess,
  surveyQuestionListItemByIdStarted,
  surveyQuestionListItemByIdEnded,
} = SurveyQuestionList.actions;

export default SurveyQuestionList.reducer;

/* Actions */

export const getSurveyQuestionListIndex = (
  params: IFilterableIndexParams,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyQuestionListIndexStarted());
  try {
    const indexResponse = await Api.SurveyQuestionList.getSurveyQuestionsListIndex(params);
    dispatch(surveyQuestionListIndexSuccess(indexResponse));
  } catch (err) {
    dispatch(surveyQuestionListIndexFailed());
    dispatch(hasError(err));
  }
};

export const getSurveyQuestionListAll = (
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyQuestionListAllStarted());
  try {
    const response = await Api.SurveyQuestionList.getSurveyQuestionsListIndex({ perpage: 999 });
    dispatch(surveyQuestionListAllSuccess(response));
  } catch (err) {
    dispatch(hasError(err));
  }
  dispatch(surveyQuestionListAllEnded());
};

export const getSurveyQuestionList = (
  id: number,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyQuestionListByIdStarted());
  try {
    const payload = await Api.SurveyQuestionList.getSurveyQuestionList(id);
    dispatch(surveyQuestionListByIdSuccess(payload));
  } catch (err) {
    dispatch(hasError(err));
  }
  dispatch(surveyQuestionListByIdEnded());
};

export const createSurveyQuestionList = (
  data: ISurveyQuestionList, cb?: (payload: ISurveyQuestionList) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyQuestionListCreateStarted());
  try {
    const payload = await Api.SurveyQuestionList.createSurveyQuestionList(data);

    if (cb) {
      cb(payload);
    }
  } catch (err) {
    dispatch(hasError(err));
  }
  dispatch(surveyQuestionListCreateEnded());
};

export const updateSurveyQuestionList = (
  data: ISurveyQuestionList, cb?: (payload: ISurveyQuestionList) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyQuestionListUpdateStarted());
  try {
    const payload = await Api.SurveyQuestionList.updateSurveyQuestionList(data);

    if (cb) {
      cb(payload);
    }
  } catch (err) {
    dispatch(hasError(err));
  }
  dispatch(surveyQuestionListUpdateEnded());
};

interface DeleteBatchSurveyQuestionListError extends Error {
  id: string
}

export const deleteBatchSurveyQuestionList = (
  ids: string[],
  cb: (errors?: DeleteBatchSurveyQuestionListError[]) => void,
): AppThunk => async (dispatch, getState, Api) => {
  const errors = [];

  await Promise.all(ids.map((id) => (async () => {
    try {
      await Api.SurveyQuestionList.deleteSurveyQuestionList(id);
    } catch (err) {
      err.id = id;
      errors.push(err);
    }
  })()));

  if (cb) {
    cb(errors.length ? errors : undefined)
  }
}

// items

export const getSurveyQuestionListItemIndex = (
  listId: number,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyQuestionListItemIndexStarted());
  try {
    const indexResponse = await Api.SurveyQuestionList.getSurveyQuestionsListItemIndex(listId);
    dispatch(surveyQuestionListItemIndexSuccess(indexResponse));
  } catch (err) {
    dispatch(surveyQuestionListItemIndexFailed());
    dispatch(hasError(err));
  }
};

export const getSurveyQuestionListItem = (
  listId: number,
  id: number,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyQuestionListItemByIdStarted());
  try {
    const payload = await Api.SurveyQuestionList.getSurveyQuestionListItem(listId, id);
    dispatch(surveyQuestionListItemByIdSuccess(payload));
  } catch (err) {
    dispatch(hasError(err));
  }
  dispatch(surveyQuestionListItemByIdEnded());
};

export const createSurveyQuestionListItem = (
  listId: number,
  data: ISurveyQuestionListItem[],
  cb?: (payload: ISurveyQuestionListItem) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyQuestionListItemCreateStarted());
  try {
    const payload = await Api.SurveyQuestionList.createSurveyQuestionListItem(listId, data);
    if (cb) {
      cb(payload);
    }
  } catch (err) {
    dispatch(hasError(err));
  }
  dispatch(surveyQuestionListItemCreateEnded());
};

export const updateSurveyQuestionListItem = (
  listId: number,
  data: ISurveyQuestionListItem, cb?: (payload: ISurveyQuestionListItem) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyQuestionListItemUpdateStarted());
  try {
    const payload = await Api.SurveyQuestionList.updateSurveyQuestionListItem(listId, [data]);
    if (cb) {
      cb(payload);
    }
  } catch (err) {
    dispatch(hasError(err));
  }
  dispatch(surveyQuestionListItemUpdateEnded());
};

export const updateBatchSurveyQuestionListItem = (
  listId: number,
  data: ISurveyQuestionListItem[],
  cb?: (error?: AxiosError) => void,
): AppThunk => async (dispatch, getState, Api) => {
  try {
    await Api.SurveyQuestionList.updateSurveyQuestionListItem(listId, data);
    if (cb) {
      cb();
    }
  } catch (err) {
    if (cb) {
      cb(err);
    }
  }
}

interface DeleteBatchSurveyQuestionListItemError extends Error {
  id: string
}

export const deleteBatchSurveyQuestionListItem = (
  listId: number,
  ids: string[],
  cb?: (errors?: DeleteBatchSurveyQuestionListItemError[]) => void,
): AppThunk => async (dispatch, getState, Api) => {
  const errors = [];

  await Promise.all(ids.map((id) => (async () => {
    try {
      await Api.SurveyQuestionList.deleteSurveyQuestionListItem(listId, id);
    } catch (err) {
      err.id = id;
      errors.push(err);
    }
  })()));

  if (cb) {
    cb(errors.length ? errors : undefined)
  }
}

/* Selectors */

export const selectIndexLoading = (state: RootState): boolean => state.SurveyQuestionList.indexLoading;
export const selectCreateLoading = (state: RootState): boolean => state.SurveyQuestionList.createLoading;
export const selectUpdateLoading = (state: RootState): boolean => state.SurveyQuestionList.updateLoading;
export const selectAllLoading = (state: RootState): boolean => state.SurveyQuestionList.allLoading;
export const selectAll = (state: RootState): ISurveyQuestionList[] => state.SurveyQuestionList.all;

export const selectById = (state: RootState): ISurveyQuestionList|null => state.SurveyQuestionList.byId;

export const selectByIdLoading = (state: RootState): boolean => state.SurveyQuestionList.byIdLoading;

export const selectPaginationData = (
  state: RootState,
): ISurveyQuestionListState['pagination'] => state.SurveyQuestionList.pagination;

export const selectPage = (pageNumber: number) => (
  state: RootState,
): ISurveyQuestionList[] => {
  const ids = state.SurveyQuestionList.pagedIds[pageNumber] || [];
  const items: ISurveyQuestionList[] = [];

  ids.forEach((id) => {
    const item = state.SurveyQuestionList.byIds[id];
    if (item) {
      items.push(item);
    }
  });

  return items;
};

// items

export const selectItemIndexLoading = (state: RootState): boolean => state.SurveyQuestionList.items.indexLoading;
export const selectItemCreateLoading = (state: RootState): boolean => state.SurveyQuestionList.items.createLoading;
export const selectItemUpdateLoading = (state: RootState): boolean => state.SurveyQuestionList.items.updateLoading;

export const selectItemById = (state: RootState): ISurveyQuestionListItem|null => (
  state.SurveyQuestionList.items.byId
);

export const selectItemByIdLoading = (state: RootState): boolean => state.SurveyQuestionList.items.byIdLoading;

export const selectItemPage = (
  state: RootState,
): ISurveyQuestionListItem[] => state.SurveyQuestionList.items.byIds

type QuestionType = ISurveyQuestionListItem & { ContentType: SurveyQuestionListItemContentTypeEnum.Question };
type FarewellType = ISurveyQuestionListItem & { ContentType: SurveyQuestionListItemContentTypeEnum.Farewell };
type GreetingType = ISurveyQuestionListItem & { ContentType: SurveyQuestionListItemContentTypeEnum.Greeting };

export const selectQuestions = (
  state: RootState,
): QuestionType[] => (
  state.SurveyQuestionList.items.byIds
    .filter((item) => item.ContentType === SurveyQuestionListItemContentTypeEnum.Question) as QuestionType[]
)

export const selectFarewell = (
  state: RootState,
): FarewellType|null => (
  state.SurveyQuestionList.items.byIds
    .find((item) => item.ContentType === SurveyQuestionListItemContentTypeEnum.Farewell) as FarewellType
    ?? null
)

export const selectGreeting = (
  state: RootState,
): GreetingType|null => (
  state.SurveyQuestionList.items.byIds
    .find((item) => item.ContentType === SurveyQuestionListItemContentTypeEnum.Greeting) as GreetingType
    ?? null
)
