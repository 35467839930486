import React, {
  useState, useEffect, useCallback, useMemo, ReactElement,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import _difference from 'lodash/difference';

import Table from 'components/Table';
import { BulkActions } from 'components/BulkActions';
import Pagination from 'components/Pagination';
import { DeleteActionModal } from 'components/DeleteActionModal';
import {
  getSurveyQuestionListIndex,
  selectPaginationData,
  selectPage,
  deleteBatchSurveyQuestionList,
} from 'reducers/SurveyQuestionList';
import { IProfilesIndexParams } from 'types/IProfilesIndexParams';
import { ISurveyQuestionList } from 'types/ISurveyQuestionList';

import IndexFilter, { ISearchQuery } from './IndexFilter';

function SurveyQuestionListTable(): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useQueryParam('page', NumberParam);
  const [filterType, setFilterType] = useQueryParam('filterType', StringParam);
  const [searchQuery, setSearchQuery] = useQueryParam(
    'searchQuery',
    StringParam,
  );
  const [sortField, setSortField] = useQueryParam(
    'sortField',
    StringParam,
  );
  const [sortDirection, setSortDirection] = useQueryParam(
    'sortDirection',
    StringParam,
  );
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [itemToDeleteIds, setItemToDeleteIds] = useState<string[]>([]);

  const resetFilters = (): void => {
    setFilterType('', 'pushIn');
    setSearchQuery('', 'pushIn');
    setCurrentPage(1, 'pushIn');
  };

  const searchQueryObject = useMemo((): ISearchQuery => {
    const query = searchQuery?.split(',');
    if (!query) return {};
    return { searchField: query[0], searchValue: query[1] };
  }, [searchQuery]);

  const dispatchSurveyQuestionListIndex = useCallback((): void => {
    const params: IProfilesIndexParams = {
      page: currentPage,
      perpage: 10,
    };

    params.filterType = filterType;

    const query = searchQueryObject;
    if (query.searchField && query.searchValue) {
      params.searchField = query.searchField;
      params.searchValue = query.searchValue;
    }

    if (sortField && sortDirection) {
      params.sortField = sortField;
      params.sortDirection = sortDirection;
    }

    dispatch(getSurveyQuestionListIndex(params));
  }, [
    dispatch,
    currentPage,
    filterType,
    sortField,
    sortDirection,
    searchQueryObject,
  ]);

  useEffect(() => {
    dispatchSurveyQuestionListIndex();
  }, [dispatchSurveyQuestionListIndex]);

  const data: ISurveyQuestionList[] = useSelector(
    selectPage(currentPage || 1),
  );
  const paginationData = useSelector(selectPaginationData);
  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'SurveyQuestionListId',
      sortable: true,
    },
    {
      Header: 'Name',
      accessor: 'Name',
      sortable: true,
    },
  ], []);

  const handleDelete = () => {
    const updateSelectedRowIds = (undeletedItems = []) => {
      const deletedItems = _difference(itemToDeleteIds, undeletedItems);
      setSelectedRowIds(_difference(selectedRowIds, deletedItems));
    }

    dispatch(deleteBatchSurveyQuestionList(itemToDeleteIds, (errors) => {
      if (errors) {
        errors.forEach((err) => {
          toast.error(err.message);
        })
        updateSelectedRowIds(errors.map((err) => err.id).filter(Boolean));
      } else {
        toast.success(`Survey Question List${itemToDeleteIds.length > 1 ? 's' : ''} successfully deleted`);
        updateSelectedRowIds();
      }
      dispatchSurveyQuestionListIndex();
      setItemToDeleteIds([]);
    }));
  }

  const rowActionItems = (id: string): ReactElement => (
    <>
      <Link to={`/surveyQuestionLists/${id}`}>
        View
      </Link>
      {' '}
      <a
        href="/surveyQuestionLists"
        onClick={(e) => {
          e.preventDefault();
          setItemToDeleteIds([id]);
        }}
      >
        Delete
      </a>
    </>
  );

  const onSearch = (searchingQuery: ISearchQuery): void => {
    if (searchingQuery.searchField || searchingQuery.searchValue) {
      setSearchQuery(
        `${searchingQuery.searchField || ''},${searchingQuery?.searchValue || ''}`,
      );
    } else {
      setSearchQuery('');
    }
    setCurrentPage(1, 'pushIn');
  };

  const renderBulkActions = () => {
    if (selectedRowIds.length === 0) {
      return null;
    }

    return (
      <BulkActions
        className="ml-3"
        items={[
          {
            label: 'Delete',
            handler: () => {
              setItemToDeleteIds([...selectedRowIds]);
            },
          }]}
      />
    )
  }

  const renderIndexFilter = (): ReactElement | null => (
    <div className="d-flex align-items-end">
      <IndexFilter
        searchQuery={searchQueryObject}
        onSearch={onSearch}
        onResetFilters={resetFilters}
        totalItems={paginationData.TotalItems}
      />
      <span className="flex-grow-1" />
      {renderBulkActions()}
    </div>
  );

  return (
    <>
      {renderIndexFilter()}
      <Table
        columns={columns}
        data={data}
        rowIDAccessor="SurveyQuestionListId"
        rowActionItems={rowActionItems}
        selectedRowIds={selectedRowIds}
        onRowClick={(id: string) => {
          history.push(`/surveyQuestionLists/${id}`);
        }}
        onRowSelect={(selectedIds) => {
          setSelectedRowIds(selectedIds);
        }}
        onColumnSort={(fieldName, direction) => {
          if (fieldName.length > 0) {
            setSortField(fieldName);
            setSortDirection(direction);
          } else {
            setSortField('');
            setSortDirection('');
          }
        }}
      />

      <Pagination
        currentPage={currentPage || 1}
        totalPages={paginationData.TotalPages}
        onPageChange={(page: number) => {
          setCurrentPage(page, 'pushIn');
        }}
        pageDelta={5}
      />
      <DeleteActionModal
        isOpen={itemToDeleteIds.length !== 0}
        title="Are you sure?"
        onCancel={() => setItemToDeleteIds([])}
        onSuccess={handleDelete}
      />
    </>
  );
}

export default SurveyQuestionListTable;
