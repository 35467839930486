import {
  ITemplateExtendo, ITemplate, IEvent, EventType, ScheduleMode,
} from 'types/IEvent';
import moment from 'moment';

export class TemplateTransformer {
  static toAPI(formValues: ITemplateExtendo): ITemplate {
    const submitData = { ...formValues };

    if (!submitData.voiceSelected) {
      submitData.VoiceContent = null
    }

    if (!submitData.emailSelected) {
      submitData.EmailContent = null
    }
    if (!submitData.smsSelected) {
      submitData.SMSContent = null
    }

    if (submitData.VoiceContent != null) {
      const placeHolder = '(___) ___-____';
      if (
        submitData.VoiceContent.TransferToPhoneNumber === ''
        || submitData.VoiceContent.TransferToPhoneNumber === placeHolder
      ) {
        submitData.VoiceContent = {
          ...submitData.VoiceContent,
          TransferToPhoneNumber: null,
        }
      }

      if (submitData.VoiceContent.SurveyResponses) {
        submitData.VoiceContent = {
          ...submitData.VoiceContent,
          SurveyResponses: submitData.VoiceContent.SurveyResponses.filter((item) => item.Response !== undefined),
        }
      }

      if (!submitData.VoiceContent.VoiceRecording?.Data) {
        submitData.VoiceContent.VoiceRecording = null;
      }
    }

    if (submitData?.submit !== 'save-as-draft' && submitData.IsDraft) {
      submitData.IsDraft = false;
    }

    return submitData;
  }

  static toEvent(template: ITemplate, tz: string, override?: Partial<IEvent>): IEvent {
    return {
      ...template,
      ID: undefined,
      EventType: EventType.ANNOUNCEMENT,
      TemplateID: template?.ID ?? null,
      StartDate: moment().format('MM-DD-yyyy'),
      SendTime: moment().tz(tz).add(5, 'minutes').format('HH:mm'),
      SendMode: ScheduleMode.Once,
      RRule: '',
      ExpirationTime: null,
      Status: '',
      StopDateLocal: null,
      IsEmergency: false,
      IsSendNow: false,
      IsBroadcastForm: false,
      ...override,
    }
  }
}
