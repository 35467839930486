import { useSelector } from 'react-redux';

import { isTokenExpired } from 'lib/helpers/ExpiredToken';
import { RootState } from 'types/rootState';

export const useIsLoginValid = () => {
  const { loggedIn, jwt } = useSelector((state: RootState) => state.UserInfo);
  const isLoginValid = loggedIn && !isTokenExpired(jwt);

  return isLoginValid;
}
