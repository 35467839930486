import React, {
  ReactElement, useCallback, useRef, useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';

import { AccushieldTemplateTable } from 'pages/Templates/AccushieldTemplateTable';
import { ReactComponent as Plus } from 'styles/images/plus.svg';
import { IEvent } from 'types/IEvent';
import { EventsTable } from '../EventsTable';
import DeleteActionButton from './DeleteActionButton';

const isPastEvent = (entity: IEvent): boolean => entity?.Status === 'Stopped'
const AutomaticMessagingIndexPage = ():ReactElement => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState<string>('AutomaticMessaging');
  const dispatchEventsIndexRef = useRef<() => void|null>(null);
  const component = useCallback((id, entity) => (
    <>
      <Link to={`/events/automatic/${id}/edit`}>Edit</Link>
      {!isPastEvent(entity) && (
        <>
          {' '}
          <DeleteActionButton
            automaticId={id}
            onSuccess={() => {
              dispatchEventsIndexRef.current?.();
            }}
          />
        </>
      )}
    </>
  ), []);

  return (
    <Tabs
      defaultActiveKey="AutomaticMessaging"
      id="Tabs"
      className="mb-3"
      onSelect={(val) => {
        history.push('/events/automatic')
        setSelectedTab(val)
      }}
    >
      <Tab eventKey="AutomaticMessaging" key="AutomaticMessaging" title="Automatic Messaging">
        {selectedTab === 'AutomaticMessaging' && (
          <div>
            <Helmet>
              <title>
                Automatic Messaging
              </title>
            </Helmet>
            <div className="header-bar mb-4">
              <div className="actions">
                <button
                  role="link"
                  type="button"
                  onClick={() => history.push('/events/automatic/new')}
                  className="btn btn-lg btn-warning"
                >
                  <Plus
                    width="24"
                    height="24"
                    fill="currentColor"
                    style={{ marginRight: '10px' }}
                  />
                  Add New Automatic Event
                </button>
              </div>
            </div>
            <EventsTable
              showActions
              rowActionItem={component}
              filterTitle="Event"
              eventType="compliancelifedate"
              onRowClick={(id) => history.push(`/events/automatic/${id}/edit`)}
              ref={dispatchEventsIndexRef}
            />
          </div>
        )}
      </Tab>
      <Tab eventKey="TransactionalTemplates" key="TransactionalTemplates" title="Accushield Templates">
        {selectedTab === 'TransactionalTemplates' && (
          <div>
            <Helmet>
              <title>
                Accushield Templates
              </title>
            </Helmet>
            <h1>Accushield</h1>
            <div className="header-bar" />
            <AccushieldTemplateTable
              showActions
              templateFilterType="transactional"
            />
          </div>
        )}
      </Tab>
    </Tabs>
  );
};
export default AutomaticMessagingIndexPage;
