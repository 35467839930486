import React, {
  useEffect, useState, ReactElement, useMemo,
} from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectGroups } from 'reducers/Groups';
import ControlledSelect, { IOptions } from 'components/Inputs/Select';
import { SearchInput, Select } from 'components/FormControls';
import { selectLoading } from 'reducers/Profiles';
import { RootState } from 'types/rootState';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';

export interface ISearchQuery {
  searchField?: string
  searchValue?: string
  customFieldName?: string
}

interface IProps {
  filterType: string
  filterGroup: string
  searchQuery: ISearchQuery
  onTypeChange: (type: string) => void
  onGroupChange: (group: string) => void
  onSearch: (searchQuery: ISearchQuery) => void
  onResetFilters: () => void
  totalItems: number
  children: React.ReactNode
}

function IndexFilter({
  filterType, filterGroup, searchQuery, onTypeChange,
  onGroupChange, onSearch, onResetFilters, totalItems, children,
}: IProps): ReactElement {
  const {
    control,
    setValue,
    reset,
    handleSubmit,
  } = useForm<ISearchQuery>({ defaultValues: { ...searchQuery } });
  useEffect(() => {
    reset(searchQuery);
    setTempQuery(searchQuery);
  }, [reset, searchQuery]);

  const [open, setOpen] = useState<boolean>(false);
  const [tempQuery, setTempQuery] = useState<ISearchQuery>(searchQuery);
  const profilesLoading: boolean = useSelector(selectLoading);

  const accountCustomFields = useSelector((state: RootState) => state.UserInfo.userInfo?.CustomFields);

  const groups = useSelector(selectGroups(true));

  const onSearchSubmit = (data: ISearchQuery): void => {
    segmentAnalyticsTrack(trackActions.profileSearch());
    data.searchValue ? onSearch(data) : onSearch({});
  };

  const resetFilters = (): void => {
    setValue('searchValue', '');
    onResetFilters();
    setOpen(false);
  };

  const handleTempQueryChange = (key: keyof ISearchQuery, val: string) => {
    setValue(key, val);

    const newTempQuery = {
      ...tempQuery,
      [key]: val,
    };

    if (key === 'searchField') {
      if (val === 'CustomField') {
        newTempQuery.customFieldName = accountCustomFields[0]?.FieldName;
      } else {
        newTempQuery.customFieldName = '';
      }
    }

    setTempQuery(newTempQuery);
  };

  const renderCustomFields = () => {
    if (tempQuery.searchField !== 'CustomField') {
      return null;
    }
    return (
      <Select
        id="customFieldName"
        label="Field Name"
        name="customFieldName"
        className="mb-2 mr-2"
        style={{ width: '160px' }}
        control={control}
        defaultValue={accountCustomFields?.[0]?.FieldName ?? ''}
        onChange={(v) => {
          handleTempQueryChange('customFieldName', v)
        }}
        options={accountCustomFields?.map(
          (acctField) => ({ value: acctField.FieldName, label: acctField.FieldName }),
        ) ?? []}
      />
    );
  }

  const profileTypes = useSelector(
    (state: RootState) => state.UserInfo.userInfo?.AccountDetail.UserTypeMappings);

  const userTypeOptions: IOptions[] = useMemo(() => {
    const options: IOptions[] = [];
    if (profileTypes) {
      Object.entries(profileTypes).forEach(([key, val]) => {
        options.push({ label: val, value: key });
      })
    }
    return options;
  }, [profileTypes])

  return (
    <div className="index-filter">
      <div className="index-filter__mobile-toggle" role="presentation" onClick={() => setOpen(!open)}>
        Filtering
        <FontAwesomeIcon icon={open ? 'caret-down' : 'caret-right'} />
      </div>
      <form id="form" onSubmit={handleSubmit(onSearchSubmit)}>
        <div className={clsx('index-filter__main', open && 'open')}>
          <ControlledSelect
            id="filterType"
            name="filterType"
            style={{ width: '160px' }}
            className="mb-2 mr-2"
            label="Type"
            value={filterType}
            onChange={onTypeChange}
            options={
              [{ value: '', label: 'All' }].concat(userTypeOptions)
            }
          />
          <ControlledSelect
            id="filterGroup"
            name="filterGroup"
            style={{ width: '160px' }}
            className="mb-2 mr-2"
            label="Group"
            value={filterGroup}
            onChange={onGroupChange}
            options={[
              { value: '', label: 'All groups' },
              ...groups.map((group): IOptions => ({ value: group.Name, label: group.Name })),
            ]}
          />
          <Select
            id="searchField"
            label="Search By"
            name="searchField"
            className="mb-2 mr-2"
            style={{ width: '160px' }}
            control={control}
            defaultValue="LastName"
            onChange={(v) => {
              handleTempQueryChange('searchField', v)
            }}
            options={[
              { label: 'Last Name', value: 'LastName' },
              { label: 'First Name', value: 'FirstName' },
              { label: 'Full Name', value: 'FullName' },
              { label: 'Email Address', value: 'EmailAddress' },
              { label: 'Landline Phone', value: 'LandLine' },
              { label: 'Mobile Phone', value: 'Mobile' },
              { label: 'Room Number', value: 'Room' },
              { label: 'Custom Field', value: 'CustomField' },
            ]}
          />
          {renderCustomFields()}
          <div className="d-flex align-items-end mb-2" style={{ maxWidth: '100%' }}>
            <SearchInput
              id="searchValue"
              label="Search Profiles"
              placeholder="I am looking for..."
              name="searchValue"
              className="mb-0"
              onChange={(e) => {
                handleTempQueryChange('searchValue', e.target.value)
              }}
              control={control}
            />

            <button
              type="submit"
              className="btn btn-md btn-primary square ml-3"
            >
              Search
            </button>
            <button
              type="button"
              className="btn btn-md btn-link ml-3 px-0"
              onClick={resetFilters}
            >
              Cancel
            </button>
          </div>

        </div>
        <div className="flex-grow-1 d-none d-md-block" />
        {profilesLoading && (
          <div className="index-filter__item-count mb-2">
            <div className="itemCount loading-text">
              Loading...
            </div>
          </div>
        )}
        {!profilesLoading && (
          <div className="index-filter__item-count mb-2">
            <div className="itemCount">
              {`${totalItems} Profile(s) In Total`}
            </div>
          </div>
        )}
        {children}
      </form>
    </div>
  );
}

export default IndexFilter;
