import React, { useCallback, ReactElement } from 'react';

import { Helmet } from 'react-helmet';
import { postLibraryContent } from 'reducers/LibraryContent';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { ILibraryContent } from 'types/ILibraryContent';
import { toast } from 'react-toastify';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import { RootState } from 'types/rootState';
import Form from './components/EditLibraryContentForm';

interface IProps {
  match: { params: { voiceType: string } }
}

function LibraryContentNewVoicePage({ match }: IProps): ReactElement {
  const { voiceType } = match.params;
  const dispatch = useDispatch();
  const history = useHistory();

  const defaultInputLanguage = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.InputLanguageDefaultValue);

  const onSubmit = useCallback(
    async (data: ILibraryContent) => {
      segmentAnalyticsTrack(trackActions.createLibrary(data));
      await dispatch(postLibraryContent(data,
        () => {
          toast.success('Content successfully created');
          history.push('/librarycontent');
        },
      ));
    },
    [dispatch, history],
  );
  const newVoiceContent: ILibraryContent = {
    ID: '0',
    Name: '',
    Description: '',
    Type: 'Voice',
    VoiceContent: {
      Content: '',
      TransferToPhoneNumber: '',
      VoiceType: voiceType,
      SurveyResponses: [],
      VoiceRecording: {
        Data: '',
        DocumentId: 0,
        DownloadUrl: '',
        FileName: 'No file selected',
      },
    },
    SMSContent: null,
    EmailContent: null,
    SourceLanguage: defaultInputLanguage,
  };
  const displayName = voiceType === 'TextToSpeech' ? 'Text-To-Speech' : voiceType;
  return (
    <Container>
      <Helmet>
        <title>Library Content</title>
      </Helmet>
      <div className="header-bar">
        <h1>
          New
          {' '}
          {displayName}
          {' '}
          Content
        </h1>
      </div>
      <Form data={newVoiceContent} onSubmit={onSubmit} />
    </Container>
  );
}
export default LibraryContentNewVoicePage;
