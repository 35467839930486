import React, { useEffect, useState, ReactElement } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { addDeleteGroupsFromMember, getGroupsIndex } from 'reducers/Groups';
import { toast } from 'react-toastify';
import { IErrorState } from 'reducers/IErrorState';
import useErrors from 'components/hooks/useErrors';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import Form, { IFormData } from './components/Form';
import {
  updateProfile, getProfile, setProfileCustomFields,
} from '../../reducers/Profiles';
import { RootState } from '../../types/rootState';
import { IProfile } from '../../reducers/IProfile';
import PreferencesWarningModal from './components/PreferencesWarningModal';

interface IProps {
  match: { params: { id: string } }
}

function ProfilesEditPage({ match }: IProps): ReactElement {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState<boolean>(false);
  const CustomerProfileID = match.params.id;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [profData, setProfData] = useState<IFormData>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const profile: IProfile | undefined = useSelector(
    (state: RootState) => state.Profiles.byIds[CustomerProfileID],
  );
  useEffect(() => {
    (async function init() {
      dispatch(getProfile(CustomerProfileID));
      dispatch(getGroupsIndex())
    }());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, CustomerProfileID]);

  const profileErrors: IErrorState = useSelector((state: RootState) => state.Error);

  useErrors(profileErrors);

  const isGroupEdited = (oldGroupIds: number[], newGroupIds: number[]) => {
    // length not equal means groups definitely changed.
    if (oldGroupIds.length !== newGroupIds.length) return true;

    // new group collection doesn't contain any old group id, means changed.
    for (const id of oldGroupIds) {
      if (newGroupIds.indexOf(id) === -1) return true;
    }
    return false;
  };

  const onSubmit = (data: IFormData): void => {
    if (!data.EmailOptIn && !data.OnDemandOptIn && !data.LandLineOptIn
      && !data.SMSOptIn && !data.MobilePhoneOptIn) {
      setProfData(data)
      setShowModal(true)
    } else {
      saveProfile(data);
    }
  };

  const saveProfile = (data: IFormData): void => {
    setSaving(true);
    const placeHolder = '(___) ___-____';
    const blockTimePlaceHolder = '__:__'
    const profileData = {
      ...data,
      LandLine: placeHolder === data.LandLine ? '' : data.LandLine,
      MobilePhone: placeHolder === data.MobilePhone ? '' : data.MobilePhone,
      OnDemand: placeHolder === data.OnDemand ? '' : data.OnDemand,
      ZipCode: data.ZipCode,
      BlockBeginTime: blockTimePlaceHolder === data.BlockBeginTime ? '' : data.BlockBeginTime,
      BlockEndTime: blockTimePlaceHolder === data.BlockEndTime ? '' : data.BlockEndTime,
    };
    const oldGroups = profile.Groups
    const newGroups = profileData.Groups
    segmentAnalyticsTrack(trackActions.editProfile());
    dispatch(updateProfile({ ...profileData, CustomerProfileID }, () => {
      if (oldGroups.length > 0 && isGroupEdited(oldGroups, newGroups)) {
        const removedGroups = oldGroups.filter((x) => !newGroups.includes(x));
        const addedGroups = newGroups.filter((x) => !oldGroups.includes(x));
        dispatch(addDeleteGroupsFromMember(CustomerProfileID, removedGroups, addedGroups, () => {}));
      }
      if (data.CustomFields && Object.keys(data.CustomFields).length > 0) {
        dispatch(setProfileCustomFields(CustomerProfileID, data.CustomFields));
      }
      setSuccess(true);
      setSaving(false);
      toast.success('Profile successfully updated');
    }, () => {
      setShowModal(false)
      setSaving(false);
    }));
  }

  if (success) {
    return (
      <Redirect to="/profiles" />
    );
  }

  return (
    <>
      <Helmet>
        <title>Edit Profile</title>
      </Helmet>

      <div className="container profiles">
        <Form data={profile} onSubmit={onSubmit} saving={saving} />
        <PreferencesWarningModal
          showModal={showModal}
          setShowModal={setShowModal}
          saveProfile={saveProfile}
          disableSavingButton={saving}
          setDisableSavingButton={setSaving}
          profData={profData}
        />
      </div>
    </>
  );
}

export default ProfilesEditPage;
