import React, { useState, ReactElement } from 'react';

import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { deleteLibraryContent } from '../../../reducers/LibraryContent';

interface IProps {
  libraryContentIds: string[]
  label?: string
  className?: string
  onSuccess?: () => void
}

function DeleteActionButton({
  libraryContentIds, label, className, onSuccess,
}: IProps): ReactElement {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [interactionDisabled, setInteractionDisabled] = useState<boolean>(false);
  const itemLabel = (libraryContentIds.length > 1) ? 'Contents' : 'Content';

  const deleteProfiles = (): void => {
    let deletedProfiles = 0;
    setInteractionDisabled(true);

    libraryContentIds.forEach((id) => {
      dispatch(deleteLibraryContent(id, () => {
        deletedProfiles += 1;

        if (deletedProfiles === libraryContentIds.length) {
          setInteractionDisabled(false);
          toast.success('Content successfully deleted');
          if (onSuccess) {
            onSuccess();
          }
        }
      }, () => { setInteractionDisabled(false); }));
    });
  };

  return (
    <>
      <a
        href="/librarycontent"
        className={className}
        onClick={(e) => {
          e.preventDefault();
          setShowModal(true)
        }}
      >
        {label || 'Delete'}
      </a>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="DeleteActionButtonModal"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton={!interactionDisabled} />
        <Modal.Body>
          <FontAwesomeIcon
            icon="trash-alt"
            size="4x"
            color="#FF7052"
          />

          <div className="message">
            <h4>Are you sure?</h4>
            <p>
              This action will delete
              {' '}
              {libraryContentIds.length}
              {' '}
              {itemLabel}
              .
            </p>
          </div>

          <button
            type="button"
            className="btn btn-block btn-danger"
            disabled={interactionDisabled}
            onClick={() => {
              setShowModal(false);
              deleteProfiles();
            }}
          >
            Delete
            {' '}
            {itemLabel}
          </button>
          <button
            type="button"
            className="btn btn-block btn-secondary"
            disabled={interactionDisabled}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteActionButton;
