import React, {
  useState, useEffect, Dispatch, ReactElement, SetStateAction,
} from 'react';
import {
  Accordion, Card, Row, Col, ListGroup, Button,
} from 'react-bootstrap';
import ToggleHeader from 'components/ToggleHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IGroup } from 'types/IGroup';
import { useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import { useIsSyncedCustomer } from 'hooks/useIsSyncedCustomer';
import AddSubGroupActionButton from './AddSubGroupActionButton';
import DeleteGroupActionButton from './DeleteGroupActionButton';

interface IProps {
  group: IGroup
  activeKey: string
  setActiveKey: Dispatch<SetStateAction<string>>
  rowColor?: string
  setEditMode: (boolean) => void
  onGroupSelect: (groupId: string) => void
}

function GroupItem({
  group, activeKey, setActiveKey, rowColor, setEditMode, onGroupSelect,
}: IProps): ReactElement {
  const selectedGroupId = useSelector((state: RootState) => state.Groups.selectedGroupId);
  const groupsLookup = useSelector((state: RootState) => state.Groups.byIds);
  const subGroupIds = useSelector(
    (state: RootState) => state.Groups.membersByIds[group.Id]?.GroupIds,
  );

  const [subGroups, setSubGroups] = useState<IGroup[]>([]);
  const [showAddSubGroupModal, setShowAddSubGroupModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const selectedGroup = groupsLookup[selectedGroupId];
  const profilesLoading = useSelector((state: RootState) => state.Profiles.loading)
  const groupClassName = (): string => {
    if (activeKey === group.Id.toString() || selectedGroupId === group.Id) {
      return 'activeGroupToggle groupListGroupItem';
    }
    return `${rowColor} groupListGroupItem`;
  }
  const subGroupClassName = (subGroup: IGroup): string => {
    if (selectedGroupId?.toString() === subGroup?.Id?.toString()) {
      return 'activeSubGroupToggle subGroupListGroupItem';
    }
    return 'subGroupListGroupItem';
  }
  useEffect(() => {
    const initialSubGroups = subGroupIds?.map((subGroupId) => {
      const subGroup: IGroup = groupsLookup[subGroupId];
      if (subGroup) {
        return subGroup;
      }
      return null;
    });
    setSubGroups(initialSubGroups);
  }, [groupsLookup, subGroupIds])
  const isSyncedCustomer = useIsSyncedCustomer();

  return (
    <Card className="groupCard">
      <div className="groupWrapper">
        <ToggleHeader
          eventKey={group.Id.toString()}
          setActiveKey={setActiveKey}
          className={groupClassName()}
        >
          <Row
            onClick={() => {
              onGroupSelect(group.Id.toString());
            }}
            className="consist-height"
          >
            <Col xs={1}>
              {group.SubGroupProfileCount > 0 && activeKey !== group.Id.toString()
              && (
                <FontAwesomeIcon icon="caret-right" />
              )}
              {(group.SubGroupProfileCount > 0 && activeKey === group.Id.toString())
              && (
                <FontAwesomeIcon icon="caret-down" />
              )}
            </Col>
            <Col xs={6} className="ellipsis">
              <span title={group.Name}>
                {group.Name}
              </span>
            </Col>
            <Col xs={4} className="list-group-item-nowrap">
              {(group.IsReadonly || group.GroupType === 1) && (
                <div>
                  (Readonly)
                </div>
              )}
              {(group.Id?.toString() === activeKey || group.Id === selectedGroupId)
              && !profilesLoading && !profilesLoading
              && !group.IsReadonly && group.GroupType === 0
              && (
                <div>
                  <Button
                    style={{ padding: '0rem' }}
                    type="button"
                    variant="link"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete
                  </Button>
                  {' '}
                  |&nbsp;
                  <Button
                    style={{ padding: '0rem' }}
                    disabled={group.IsReadonly}
                    title={group.IsReadonly ? 'this group is readonly' : ''}
                    type="button"
                    variant="link"
                    onClick={() => {
                      if (group.IsReadonly) return;
                      setEditMode(true);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </ToggleHeader>
        <Accordion.Collapse eventKey={group.Id.toString()}>
          <ListGroup>
            {!isSyncedCustomer && group.GroupType === 0 && (
              <ListGroup.Item style={{ cursor: 'pointer' }} className="subGroupListGroupItem">
                <Row onClick={() => setShowAddSubGroupModal(true)} className="consist-height">
                  <Col xs={2} />
                  <Col>
                    <FontAwesomeIcon icon="plus" size="sm" className="groupItemIcon" />
                    {' '}
                    <span>
                      Add New Sub-Group
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
            {subGroups?.map((sg) => {
              if (sg != null) {
                return (
                  <ListGroup.Item
                    key={sg.Id}
                    style={{ cursor: 'pointer' }}
                    className={subGroupClassName(sg)}
                    onClick={() => {
                      onGroupSelect(sg.Id.toString());
                    }}
                  >
                    <Row className="consist-height">
                      <Col xs={2} />
                      <Col xs={5} className="ellipsis">
                        <span title={sg.Name}>{sg.Name}</span>
                      </Col>
                      <Col xs={5}>
                        {sg.Id === selectedGroupId
                        && (
                          <div>
                            <Button
                              style={{ padding: '0rem' }}
                              type="button"
                              variant="link"
                              onClick={() => setShowDeleteModal(true)}
                            >
                              Delete
                            </Button>

                            {' '}
                            |&nbsp;
                            <Button
                              style={{ padding: '0rem' }}
                              type="button"
                              variant="link"
                              onClick={() => {
                                setEditMode(true);
                              }}
                            >
                              Edit
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )
              }
              return (null)
            })}
          </ListGroup>
        </Accordion.Collapse>
      </div>

      {!isSyncedCustomer && (
        <AddSubGroupActionButton
          parentGroup={group}
          showAddSubGroupModal={showAddSubGroupModal}
          setShowAddSubGroupModal={setShowAddSubGroupModal}
        />
      )}

      <DeleteGroupActionButton
        group={selectedGroup}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </Card>
  )
}

export default GroupItem;
