import * as React from 'react';
import { Modal, Spinner, ProgressBar } from 'react-bootstrap';
import useLatest from 'react-use/lib/useLatest';
import { toast } from 'react-toastify';
import { useSWRConfig } from 'swr';
import moment from 'moment';
import _chunk from 'lodash/chunk';
import _flatten from 'lodash/flatten';
import _uniq from 'lodash/uniq';

import * as GroupsAPI from 'api/GroupsAPI';
import * as ProfilesAPI from 'api/ProfilesAPI';
import * as EventsApi from 'api/EventsAPI';
import log from 'lib/logging';
import Checkbox from 'components/Inputs/Checkbox';
import { IEventSummary, IEvent } from 'types/IEvent';
import { IGroup } from 'types/IGroup';

interface EventIdAndOcurrence {
  EventId: number
  ocurrenceDate: string
}

interface PrintPdfModalProps {
  show: boolean
  onHide: () => void
  events: EventIdAndOcurrence | EventIdAndOcurrence[] | null
}

export const ExportPdfModal: React.FC<PrintPdfModalProps> = ({
  show,
  onHide,
  events: calendarEvents,
}) => (
  <Modal
    show={show}
    onHide={onHide}
    className="export-pdf-modal"
  >
    <Modal.Header
      closeButton
      style={{ marginBottom: 12 }}
    >
      <Modal.Title>
        Export as PDF
      </Modal.Title>
    </Modal.Header>
    <ModalContent
      show={show}
      onHide={onHide}
      events={calendarEvents}
    />
  </Modal>
);

const ModalContent: React.FC<PrintPdfModalProps> = ({
  show,
  onHide,
  events: calendarEvents,
}) => {
  const [includeContent, setIncludeContent] = React.useState(true);
  const [includeReport, setIncludeReport] = React.useState(true);
  const [isExporting, setIsExporting] = React.useState(false);
  const [totalProgress, setTotalProgress] = React.useState(0);
  const [currentProgress, setCurrentProgress] = React.useState(0);

  const { mutate } = useSWRConfig();

  const latestShow = useLatest(show);
  const generateEventsPdf = React.useRef(null);

  React.useEffect(() => {
    (async () => {
      const { generateEventsPDF } = await import('utils/generateEventPDF');
      if (latestShow.current) {
        generateEventsPdf.current = generateEventsPDF;
      }
    })();
  }, [latestShow]);

  const handleExport = async () => {
    const cachePromises = [];

    const calendarEventsArr = Array.isArray(calendarEvents)
      ? calendarEvents
      : [calendarEvents];

    setIsExporting(true);
    if (calendarEventsArr.length > 2) {
      setTotalProgress(calendarEventsArr.length * 2 + 2);
    }

    const getEventDetails = async (calendarEvent: EventIdAndOcurrence) => {
      if (!latestShow.current) {
        return undefined;
      }

      const eventOccurence = await EventsApi.getEventInstanceByIdAndDate(
        calendarEvent.EventId, calendarEvent.ocurrenceDate,
      );

      if (!latestShow.current) {
        return undefined;
      }

      if (!eventOccurence) {
        const event = await EventsApi.getEventById(calendarEvent.EventId);

        if (!latestShow.current) {
          return undefined;
        }

        setCurrentProgress((currProgress) => currProgress + 1);
        cachePromises.push(mutate(['event', calendarEvent.EventId], event, false));

        return event;
      }

      setCurrentProgress((currProgress) => currProgress + 1);
      cachePromises.push(
        mutate(['eventOccurrence', calendarEvent.EventId, calendarEvent.ocurrenceDate], eventOccurence, false),
      );

      return eventOccurence;
    }

    const getGroups = async () => {
      if (!latestShow.current) {
        return undefined;
      }

      const groups = await GroupsAPI.groupsIndex();

      if (!latestShow.current) {
        return undefined;
      }

      setCurrentProgress((currProgress) => currProgress + 1);
      cachePromises.push(
        mutate('groups', groups, false),
      );

      return groups;
    }

    const getEventReport = async (calendarEvent: EventIdAndOcurrence) => {
      const m = moment(calendarEvent.ocurrenceDate, 'YYYY-MM-DD');
      const year = m.year();
      const month = m.month() + 1;
      const day = m.date();

      if (!latestShow.current) {
        return undefined;
      }

      const eventReport = await EventsApi.getEventSummary(calendarEvent.EventId, year, month, day);

      if (!latestShow.current) {
        return undefined;
      }

      setCurrentProgress((currProgress) => currProgress + 1);
      cachePromises.push(
        mutate(['eventSummary', calendarEvent.EventId, calendarEvent.ocurrenceDate], eventReport, false),
      );

      return eventReport;
    }

    try {
      const requestPromises: [Promise<IGroup[]>, Promise<IEvent[]>, Promise<IEventSummary[][]>?] = [
        getGroups(),
        Promise.all(calendarEventsArr.map(getEventDetails)),
      ];

      if (includeReport) {
        requestPromises.push(
          Promise.all(calendarEventsArr.map(getEventReport)),
        );
      }

      const [
        groups,
        eventDetailsArr,
        eventSummaryArr,
      ] = await Promise.all(requestPromises as [Promise<IGroup[]>, Promise<IEvent[]>, Promise<IEventSummary[][]>]);

      if (!latestShow.current) {
        return;
      }

      const profileIds = eventDetailsArr.reduce((acc, eventDetails) => [
        ...acc,
        ...eventDetails.ProfileIds,
      ], []);

      const profilesByIdsResponses = await Promise.all(
        _chunk<string>(_uniq(profileIds), 1000)
          .map(ProfilesAPI.profilesGetByIds),
      );

      if (!latestShow.current) {
        return;
      }

      setCurrentProgress((currProgress) => currProgress + 1);
      const profiles = _flatten(profilesByIdsResponses).filter(Boolean);

      const pdf = await generateEventsPdf.current(
        eventDetailsArr.map((eventDetails, index) => {
          const calendarEvent = calendarEventsArr[index];
          return ({
            ...eventDetails,
            StartDate: calendarEvent.ocurrenceDate,
          })
        }),
        groups,
        profiles,
        eventSummaryArr,
        includeContent,
        includeReport,
      );

      const pdfName = calendarEventsArr.length > 1
        ? 'selection.pdf'
        : `${eventDetailsArr[0].Name}-${
          moment(calendarEventsArr[0].ocurrenceDate, 'yyyy-MM-DD').format('MM-DD-yyyy')}.pdf`;

      if (!latestShow.current) {
        return;
      }

      pdf.download(pdfName);
    } catch (err) {
      toast.error('Failed to download. Try again later.');
      log.error('Failed to download:', err);
    }

    if (latestShow.current) {
      setIsExporting(false);
      onHide();
    }

    await Promise.all(cachePromises);
  }

  const renderProgressBar = () => {
    const percentage = Math.round(currentProgress * 100 / totalProgress);

    return (
      <ProgressBar
        now={percentage}
        label={`${percentage}%`}
      />
    )
  }

  return (
    <>
      <Modal.Body>
        {(isExporting && !!totalProgress)
          ? renderProgressBar()
          : (
            <>
              <Checkbox
                name=""
                id="include-content-check"
                className="mb-2"
                label="Include Message Content"
                checked={includeContent}
                onChange={(e) => setIncludeContent(e.target.checked)}
              />
              <Checkbox
                name=""
                id="include-report-check"
                label="Include Report"
                checked={includeReport}
                onChange={(e) => setIncludeReport(e.target.checked)}
              />
            </>
          )}
      </Modal.Body>

      <Modal.Footer className="pb-0" style={{ marginTop: 20 }}>
        <button
          type="button"
          className="btn btn-dark btn-cancel square mb-0"
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary square d-flex align-items-center justify-content-center mb-0"
          onClick={handleExport}
          disabled={isExporting || !show}
        >
          Export as PDF
          {isExporting && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              className="ml-2"
            />
          )}

        </button>
      </Modal.Footer>
    </>
  )
};
