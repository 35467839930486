import { IFilterableIndexParams } from 'types/ITemplatesIndexParams';
import { ISurveyQuestionList, ISurveyQuestionListItem } from 'types/ISurveyQuestionList';
import { IndexPayload, Pagination } from 'types/request';
import API from './API';

export const API_PATH = '/api/v2/SurveyQuestionList';
export async function getSurveyQuestionsListIndex({
  page,
  perpage,
  filterType = '',
  searchField = '',
  searchValue = '',
  sortField = '',
  sortDirection = '',
}: IFilterableIndexParams): Promise<IndexPayload<ISurveyQuestionList>> {
  const params = {
    filterType: (filterType.length > 0 ? filterType : undefined),
    searchField: (searchField.length > 0 ? searchField : undefined),
    searchValue: (searchValue.length > 0 ? searchValue : undefined),
    sortField: (sortField.length > 0 ? sortField : undefined),
    sortDirection: (sortDirection.length > 0 ? sortDirection : undefined),
    page: (page || undefined),
    perpage: (perpage || undefined),
  };

  const response = await API.get<IndexPayload<ISurveyQuestionList>>(API_PATH, { params });
  return response.data;
}

// Post
export async function createSurveyQuestionList(
  surveyQuestionList: ISurveyQuestionList,
): Promise<ISurveyQuestionList> {
  const response = await API.post<ISurveyQuestionList>(API_PATH, surveyQuestionList);
  return response.data;
}

// Get
export async function getSurveyQuestionList(id: number): Promise<ISurveyQuestionList> {
  const response = await API.get<ISurveyQuestionList>(`${API_PATH}/${id}`);
  return response.data;
}

// Put
export async function updateSurveyQuestionList(
  surveyQuestionList: ISurveyQuestionList,
): Promise<ISurveyQuestionList> {
  const response = await API.put<ISurveyQuestionList>(
    `${API_PATH}/${surveyQuestionList.SurveyQuestionListId}`, surveyQuestionList,
  );
  return response.data;
}

// Delete
export async function deleteSurveyQuestionList(id: number): Promise<void> {
  await API.delete<void>(`${API_PATH}/${id}`);
}

// Questions
export async function getSurveyQuestionsListItemIndex(listId: number)
  : Promise<IndexPayload<ISurveyQuestionListItem>> {
  const params = {
    page: 1,
    perpage: 10,
  };
  const response = await API.get<{
    SurveyQuestionContents: ISurveyQuestionListItem[]
    Pagination: Pagination
  }>(`${API_PATH}/${listId}/questions`, { params });
  return {
    Data: response.data.SurveyQuestionContents,
    Pagination: response.data.Pagination,
  }
}

// Post
export async function createSurveyQuestionListItem(
  listId: number,
  surveyQuestionListItems: ISurveyQuestionListItem[],
): Promise<ISurveyQuestionListItem[]> {
  const response = await API.post<ISurveyQuestionListItem[]>(`${API_PATH}/${listId}/questions`,
    surveyQuestionListItems);
  return response.data;
}

// Get
export async function getSurveyQuestionListItem(listId: number, id: number): Promise<ISurveyQuestionListItem> {
  const response = await API.get<ISurveyQuestionListItem>(`${API_PATH}/${listId}/questions/${id}`);
  return response.data;
}

// Put
export async function updateSurveyQuestionListItem(
  listId: number,
  surveyQuestionListItems: ISurveyQuestionListItem[],
): Promise<ISurveyQuestionListItem[]> {
  const response = await API.put<ISurveyQuestionListItem[]>(
    `${API_PATH}/${listId}/questions`,
    surveyQuestionListItems,
  );
  return response.data;
}

// Delete
export async function deleteSurveyQuestionListItem(listId: number, id: number): Promise<void> {
  await API.delete<void>(`${API_PATH}/${listId}/questions/${id}`);
}
