import React from 'react';
import { CellProps } from 'react-table';

import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';

export function ProfileTypeCell(props: CellProps<Record<string, unknown>>) {
  const { cell: { value } } = props;
  const translateUserType = useUserTypeTranslation();

  if (!value) {
    return null;
  }

  return <>{translateUserType(value)}</>
}
