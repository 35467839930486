import { format, parseISO } from 'date-fns';
import { IFilterableIndexParams } from 'types/ITemplatesIndexParams';
import { IBroadcastHistory, IBroadcastHistoryIndexParams } from 'types/IBroadcastHistory';
import { IndexPayload } from 'types/request';
import API from './API';
import {
  IEvent,
  IEventSummary,
  IEventEmail,
  IEventVoice,
  IEventSms,
  IEventsPayload,
  EventType,
} from '../types/IEvent';

export const API_PATH = '/api/v2/event';
export const EVENT_DETAILS_PATH = '/api/v2/reports/eventdetails';

export async function EventsIndex({
  searchField = '',
  searchValue = '',
  sortField = '',
  sortDirection = '',
  page,
  perpage,
  type = '',
  statusType = '',
}: IFilterableIndexParams): Promise<IEventsPayload> {
  const params = {
    filterType: searchField.length > 0 ? searchField : undefined,
    filterValue: searchValue.trim().length > 0 ? searchValue : undefined,
    sortField: sortField.length > 0 ? sortField : undefined,
    sortDirection: sortDirection.length > 0 ? sortDirection : undefined,
    page: page || undefined,
    perpage: perpage || undefined,
    statusType: statusType.length > 0 ? statusType : undefined,
  };
  const apiPath = type ? `${API_PATH}/type/${type}` : API_PATH
  const response = await API.get<IEventsPayload>(apiPath, { params });

  return response.data;
}

export async function eventsGet(): Promise<IEvent[]> {
  const response = await API.get<IEvent[]>(`${API_PATH}`);
  return response.data;
}

export async function getEventById(ID: number): Promise<IEvent> {
  const response = await API.get<IEvent>(`${API_PATH}/${ID}`);
  return response.data;
}

export async function getEventInstanceByIdAndDate(
  ID: number,
  date: string,
): Promise<IEvent> {
  const response = await API.get<IEvent>(`${API_PATH}/${ID}/instance/${date}`);
  return response.data;
}

export async function eventCreate(events: IEvent[]): Promise<IEvent[]> {
  const placeHolder = '(___) ___-____';
  const newEvents = events.map((x) => {
    const contentToTransform = { ...x };
    if (x.VoiceContent?.TransferToPhoneNumber === ''
       || x.VoiceContent?.TransferToPhoneNumber === placeHolder) {
      contentToTransform.VoiceContent.TransferToPhoneNumber = null;
    }
    return contentToTransform;
  })
  const eventType = newEvents[0].EventType
  const apiPath = (
    eventType === 'compliance' || eventType === 'lifedate' || eventType === EventType.SURVEY || eventType === EventType.URLLINKSURVEY
  ) ? `${API_PATH}/type/${eventType}` : API_PATH
  const response = await API.post<IEvent[]>(apiPath, newEvents);
  return response.data;
}

export async function eventPut(content: IEvent): Promise<IEvent> {
  const response = await API.put<IEvent>(`${API_PATH}/${content.ID}`, content);
  return response.data;
}

export async function eventInstancePut(
  content: IEvent,
  date: string,
): Promise<void> {
  const response = await API.put<void>(
    `${API_PATH}/${content.ID}/instance/${date}`,
    content,
  );
  return response.data;
}

export async function getEventReportFileBytesByIdAndDate(
  eventId: string,
  eventSendDate: string,
): Promise<any> {
  const eventDateYear = format(parseISO(eventSendDate), 'yyyy');
  const eventDateMonth = format(parseISO(eventSendDate), 'MM');
  const eventDateDay = format(parseISO(eventSendDate), 'dd');
  const response = await API.get<any>(
    `/api/v2/reports/eventsummary/export/${eventId}/${eventDateYear}/${eventDateMonth}/${eventDateDay}`,
  );
  return response.data;
}

export async function getEventSummary(
  eventId: number,
  sendDateYear: string|number,
  sendDateMonth: string|number,
  sendDateDay: string|number,
): Promise<IEventSummary[]> {
  const response = await API.get<IEventSummary[]>(
    `${EVENT_DETAILS_PATH}/${eventId}/${sendDateYear}/${sendDateMonth}/${sendDateDay}/summary`,
  );
  return response.data;
}

export async function getEventEmails(
  eventId: number,
  sendDateYear: string,
  sendDateMonth: string,
  sendDateDay: string,
): Promise<IEventEmail[]> {
  const response = await API.get<IEventEmail[]>(
    `${EVENT_DETAILS_PATH}/${eventId}/${sendDateYear}/${sendDateMonth}/${sendDateDay}/email`,
  );
  return response.data;
}

export async function getEventVoice(
  eventId: number,
  sendDateYear: string,
  sendDateMonth: string,
  sendDateDay: string,
): Promise<IEventVoice[]> {
  const response = await API.get<IEventVoice[]>(
    `${EVENT_DETAILS_PATH}/${eventId}/${sendDateYear}/${sendDateMonth}/${sendDateDay}/voice`,
  );
  return response.data;
}

export async function getEventSms(
  eventId: number,
  sendDateYear: string,
  sendDateMonth: string,
  sendDateDay: string,
): Promise<IEventSms[]> {
  const response = await API.get<IEventSms[]>(
    `${EVENT_DETAILS_PATH}/${eventId}/${sendDateYear}/${sendDateMonth}/${sendDateDay}/sms`,
  );
  return response.data;
}

export async function deleteEvent(eventId: number): Promise<void> {
  await API.delete<void>(`${API_PATH}/${eventId}`);
}

export async function deleteSingleEvent(
  eventId: number,
  sendDate: string,
): Promise<void> {
  await API.delete<void>(`${API_PATH}/${eventId}/instance/${sendDate}`);
}

export async function eventSeriesStop(eventId: number, stopDate: Date): Promise<void> {
  const formattedStopDate = format(stopDate, "yyyy'-'MM'-'dd'T'HH':'mm':'ss")
  await API.put<void>(`${API_PATH}/${eventId}/stopseries`, { StopDate: formattedStopDate });
}

export async function eventStop(eventId: number, dateString: string): Promise<void> {
  await API.put<void>(`${API_PATH}/${eventId}/stopdate`, { StopDate: dateString });
}

export async function eventExpirationTime(eventId: number, expirationTime: string, date: string): Promise<void> {
  await API.put<void>(`${API_PATH}/${eventId}/expirationtime`, { ExpirationTime: expirationTime });
}

export async function getEventInstanceSummaryByIdAndDate(
  ID: number,
  date: string,
): Promise<IEventSummary[]> {
  const response = await API.get<IEventSummary[]>(
    `${API_PATH}/${ID}/summary/${date}`,
  );
  return response.data;
}

export async function validateEventSourceLanguage(events: Event[]): Promise<string> {
  const response = await API.post<string>(`${API_PATH}/validate`, events);
  return response.data;
}

export async function validateEventContent(events: Event[], type: string): Promise<string> {
  const response = await API.post<string>(`${API_PATH}/validate/${type}`, events);
  return response.data;
}

export const getBroadcastHistoryIndex = async ({
  startDate,
  endDate,
  includeAutomaticEvents,
  sortField,
  sortDirection,
  page,
  perpage,
}: IBroadcastHistoryIndexParams) => {
  const params = {
    endDate,
    startDate,
    includeAutomaticEvents,
    sortField,
    sortDirection,
    page: page || undefined,
    perpage: perpage || undefined,
  };

  const response = await API.get<IndexPayload<IBroadcastHistory>>(`${API_PATH}/instance/history`, { params });

  return response.data;
}
