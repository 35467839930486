import React from 'react';
import { useQueryParam } from 'use-query-params';
import clsx from 'clsx';

export const ReadStatusFilter = () => {
  const [readStatusParam = 'all', setReadStatusParam] = useQueryParam<string, string>('readStatus');

  return (
    <div className="button-group sms-inbox__conversation-view__read-filter">
      <button
        type="button"
        className={clsx('btn btn-sm', readStatusParam === 'all' && 'active')}
        onClick={() => {
          setReadStatusParam('all');
        }}
      >
        All
      </button>
      <button
        type="button"
        className={clsx('btn btn-sm', readStatusParam === 'unread' && 'active')}
        onClick={() => {
          setReadStatusParam('unread');
        }}
      >
        Unread
      </button>
      <button
        type="button"
        className={clsx('btn btn-sm', readStatusParam === 'read' && 'active')}
        onClick={() => {
          setReadStatusParam('read');
        }}
      >
        Read
      </button>
    </div>
  )
}
