import { Switch, Route } from 'react-router-dom';
import React from 'react';
import TemplatesEditPage from '../pages/Templates/Edit';

function TransactionalTemplateRoutes() {
  return (
    <Switch>
      <Route path="/transactionaltemplates/:templateId/:type" component={TemplatesEditPage} />
    </Switch>
  );
}

export default TransactionalTemplateRoutes;
