import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import { useHasRoles } from 'hooks/useHasRoles';
import { useHasFeatures } from 'hooks/useHasFeatures';
import { useHasPermissions } from 'hooks/useHasPermissions';

interface TemplateSendValidationModalProps {
  templateId: number
  onHide: () => void
  errors: Error[]
}

export const TemplateSendValidationModal: React.FC<TemplateSendValidationModalProps> = ({
  templateId,
  onHide,
  errors,
}) => {
  const history = useHistory();
  const isMarketing = history.location.pathname.startsWith('/marketing-templates');
  const hasMarketingWritePermission = useHasPermissions('Marketing:Edit');
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const { hasFeature: hasMarketingFeature } = useHasFeatures('marketing-emails');
  const canWriteMarketing = (hasMarketingWritePermission || isAdmin || isSuper) && hasMarketingFeature;

  return (
    <Modal
      show
      onHide={onHide}
      className="DiscardChangeModal"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header />
      <Modal.Body>
        <FontAwesomeIcon
          icon="exclamation-circle"
          size="4x"
          color="#FFAE42"
        />
        <div className="message">
          <h4>Unable to send or schedule Message</h4>
          <p>
            This
            {' '}
            {isMarketing ? 'marketing email' : 'template'}
            {' '}
            must be edited before using to send or schedule a message
          </p>
          <ul>
            {errors.map((error) => <li key={error.message}>{error.message}</li>)}
          </ul>
        </div>
        {(!isMarketing || canWriteMarketing) && (
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => {
              history.push(isMarketing
                ? `/marketing-templates/${templateId}/edit`
                : `/templates/${templateId}/edit/template`,
              );
            }}
          >
            Edit
          </button>
        )}
        <button
          type="button"
          className="btn btn-block btn-secondary"
          onClick={onHide}
        >
          Cancel
        </button>
      </Modal.Body>
    </Modal>
  )
};
