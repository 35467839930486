import {
  Col, Row,
} from 'react-bootstrap';
import { IProfile } from 'reducers/IProfile';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import React, { ReactElement, useCallback } from 'react';

interface IProps {
  groupMembers: IProfile[]
  profiles: IProfile[]
  membersIdSet: Set<string>
  onAvailableProfileChecked: (profile: IProfile) => void
}

const AvailableProfiles = ({
  groupMembers, profiles, membersIdSet, onAvailableProfileChecked,
}: IProps): ReactElement => {
  const getRowColor = (index: number): string => {
    if (index % 2 === 1) {
      return 'oddGroupItem';
    }
    return 'evenGroupItem';
  }

  const Profiles = useCallback(({ index, style }) => (
    <div style={style} className="no-overflow-x cursor-pointer">
      <Row className={getRowColor(index)} onClick={() => onAvailableProfileChecked(profiles[index])}>
        <Col xs={1}>
          <input
            type="checkbox"
            checked={membersIdSet.has(profiles[index]?.CustomerProfileID)}
            className="align-middle mt-3 ml-3"
            readOnly
          />
        </Col>
        <Col className="align-middle mt-2 ml-1">
          {profiles[index]?.FirstName}
          {' '}
          {profiles[index]?.LastName}
        </Col>
      </Row>
    </div>
  ), [profiles, membersIdSet, onAvailableProfileChecked]);

  return (
  // did a trick for making the auto sizer worked, it needs this flex and height style
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '1 1 auto', height: '70vh' }}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={35}
              itemCount={profiles.length}
            >
              {Profiles}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  )
}
export default AvailableProfiles;
