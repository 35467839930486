import React, {
  useEffect, useState, useMemo, Dispatch, ReactElement, SetStateAction, ChangeEvent,
} from 'react';
import useSWR from 'swr/immutable';
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
} from 'react-bootstrap';
import { Input, Select as SelectControl } from 'components/FormControls';
import { useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { IGroup } from 'types/IGroup';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'types/rootState';
import { IProfile } from 'reducers/IProfile';
import { getGroupsMembers, saveGroupWithMembers, getFilterGroupsMembers } from 'reducers/Groups';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { SearchInput } from 'components/Inputs/SearchInput';
import { getAllProfiles } from 'reducers/Profiles';
import OverlayCancelButton from 'components/OverlayCancelButton';
import { useLodashDebounce } from 'hooks/useDebounce';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import { useRefSelector } from 'hooks/useRefSelector';
import * as GroupsAPI from 'api/GroupsAPI';
import { useIsSyncedCustomer } from 'hooks/useIsSyncedCustomer';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';
import AvailableProfiles from './AvailableProfiles';
import SelectedProfiles from './SelectedProfiles';

interface IProps {
  groups: IGroup[]
  setShowEditMembers: Dispatch<SetStateAction<boolean>>
}
interface SelectItems {
  id: number
  label: string
  value: number
}
const GroupEdit = ({
  groups,
  setShowEditMembers,
}: IProps): ReactElement => {
  const {
    control, reset, errors, handleSubmit, formState,
  } = useForm<IGroup>({
    mode: 'onBlur',
  });
  const dispatch = useDispatch();
  const selectedGroupId = useSelector(
    (state: RootState) => state.Groups.selectedGroupId,
  );

  const currentMembers = useSelector((state: RootState) => {
    const ids = state.Groups.membersByIds[selectedGroupId]?.CustomerProfileIds;
    return ids?.map((i) => state.Profiles.byIds[i])
  });

  const [groupMembers, setGroupMembers] = useState<IProfile[]>(currentMembers);
  const membersIdSet = new Set(groupMembers?.map((x) => x?.CustomerProfileID));

  const equalityCheck = (left: any[], right: any[]):
  boolean => left?.length === right?.length && JSON.stringify(left) === JSON.stringify(right)

  const allProfiles = useRefSelector(
    (state: RootState) => Object.keys(state.Profiles.byIds).map((k) => state.Profiles.byIds[k]),
    equalityCheck,
  );

  useMemo(() => allProfiles?.sort(
    (a, b) => a.LastName.localeCompare(b.LastName)),
  [allProfiles]);

  const [availableSearchValue, setAvailableSearchValue] = useState('');
  const [normalizedSearchValue, setNormalizedSearchValue] = useState('');

  // const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [filteredGroup, setFilteredGroup] = useState(0);

  const [groupFilterValue, setGroupFilterValue] = useState<SelectItems>({
    id: 0,
    label: 'Search Profiles by Group',
    value: 0,
  });
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [hasFilter, setHasFilter] = useState(false);

  const filterGroupMembers = useSelector((state: RootState) => state.Groups.filterGroupMembers)

  const filterPredicate = (members, searchValue) => (p: IProfile) => (members.length === 0
    || members.some((y: string) => y === p.CustomerProfileID))
    && (
      searchValue === ''
      || p.FirstName.toLowerCase().includes(searchValue)
      || p.LastName.toLowerCase().includes(searchValue)
    );

  // TODO I don't think these checkAll variables are needed anymore
  // const [, setShowCheckAll] = useState<boolean>(false);
  const [checkAllProfiles, setCheckAllProfiles] = useState<boolean>(false);

  const profilesLoading = useSelector(
    (state: RootState) => state.Profiles.loading,
  );
  const groupsLookup = useSelector((state: RootState) => state.Groups.byIds);

  const subGroupIds = useSelector(
    (state: RootState) => state.Groups.membersByIds[selectedGroupId]?.GroupIds,
  );
  const selectedGroup = groupsLookup[selectedGroupId];
  const translateUserType = useUserTypeTranslation();
  const groupOptions = groups.map((g) => ({
    id: g.Id,
    label: translateUserType(g.Name),
    value: g.Id,
  }));

  useEffect(() => {
    if (selectedGroup) {
      reset(selectedGroup);
    }
  }, [selectedGroup, reset]);

  // useEffect(() => {
  //   const f = allProfiles.filter(filterPredicate(filterGroupMembers, normalizedSearchValue));
  //   setFilteredProfiles(f);
  // }, [allProfiles, filterGroupMembers, normalizedSearchValue]);
  const filteredProfiles = useMemo(() => {
    if (!filterGroupMembers) return allProfiles;
    if (filteredGroup === 0 && availableSearchValue === '') return allProfiles;
    return allProfiles.filter(filterPredicate(filterGroupMembers, normalizedSearchValue));
  }, [allProfiles, filterGroupMembers, normalizedSearchValue, filteredGroup, availableSearchValue]);
  useEffect(() => {
    dispatch(getFilterGroupsMembers(filteredGroup));
  }, [dispatch, filteredGroup]);

  const isSyncedCustomer = useIsSyncedCustomer();

  const interceptSubmit = useLodashDebounce(({ CaremergeCategoryId, ...groupFormData }: IGroup): void => {
    const memberData = {
      CustomerProfileIds: groupMembers?.map((p) => p.CustomerProfileID),
      GroupIds: subGroupIds,
    };
    segmentAnalyticsTrack(trackActions.editGroup());

    let newCaremergeCategoryId;
    if (isSyncedCustomer && CaremergeCategoryId !== undefined) {
      // did change
      if (+CaremergeCategoryId !== selectedGroup.CaremergeCategoryId) {
        newCaremergeCategoryId = +CaremergeCategoryId || null;
      }
    }

    dispatch(
      saveGroupWithMembers(
        groupFormData,
        memberData,
        newCaremergeCategoryId,
        () => {
          toast.success('Group successfully updated');
        },
      ),
    );
    setShowEditMembers(false);
  }, 600);

  const handleAddMember = (profile: IProfile): void => {
    setIsGroupMembersChanged(true);
    setGroupMembers(groupMembers ? groupMembers.concat(profile) : [profile]);
  };
  const handleRemoveMember = (profile: IProfile): void => {
    setIsGroupMembersChanged(true);
    setGroupMembers(
      groupMembers.filter(
        (x) => x.CustomerProfileID !== profile.CustomerProfileID,
      ),
    );
  };
  const onAvailableProfileChecked = (
    profile: IProfile,
  ): void => {
    if (groupMembers?.some((x) => x.CustomerProfileID === profile.CustomerProfileID)) {
      setCheckAllProfiles(false);
      handleRemoveMember(profile);
    } else {
      handleAddMember(profile);
    }
  };

  const onCheckAll = (checked: boolean): void => {
    setCheckAllProfiles(checked);
    if (checked) {
      const profilesToAdd = [];
      filteredProfiles.forEach((p) => {
        if (groupMembers) {
          const index = groupMembers.indexOf(p)
          if (index === -1) { profilesToAdd.push(p); }
        } else {
          profilesToAdd.push(p);
        }
      });
      setIsGroupMembersChanged(true);

      if (groupMembers) {
        setGroupMembers(groupMembers.concat(profilesToAdd));
      } else {
        setGroupMembers(profilesToAdd);
      }
    } else {
      // remove all filteredProfiles from the groupMembers list
      const profileSet = new Set(filteredProfiles.map((p) => p.CustomerProfileID));
      setIsGroupMembersChanged(true);
      setGroupMembers(groupMembers.filter((m) => !profileSet.has(m.CustomerProfileID)));
    }
  }

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    // setCheckAllProfiles(false);
    setAvailableSearchValue(e.target.value);
  }

  const onProfileGroupFiltering = () => {
    setHasFilter(true);
    setFilteredGroup(groupFilterValue.value);
    setNormalizedSearchValue(availableSearchValue.toLowerCase());
  }

  const resetFilter = () => {
    setHasFilter(false);
    // review this setFilteredGroup(6160)
    setFilteredGroup(groups.find((group) => group.Name === 'Master').Id);
    setGroupMembers([])
    setGroupFilterValue({ id: 0, label: 'Search Profiles by Group', value: 0 });
    setNormalizedSearchValue('');
    setAvailableSearchValue('');
    setIsFilterModalOpen(false);
    dispatch(getAllProfiles());
  }

  const renderResetFilterButton = () => (
    <Button
      type="button"
      variant="secondary"
      className="mt-4 mr-4"
      size="sm"
      onClick={() => {
        // setIsFilterModalOpen(false)
        resetFilter()
      }}
    >
      Clear Filters
    </Button>
  )

  const currentMembersLength = currentMembers?.length ?? 0;

  useEffect(() => {
    if (!(currentMembersLength > 0)
      && selectedGroupId != null
      && selectedGroupId !== -1
      && selectedGroupId !== 0
    ) {
      dispatch(getGroupsMembers(selectedGroupId));
    }
  }, [currentMembersLength, dispatch, selectedGroupId]);
  const [isGroupMembersChanged, setIsGroupMembersChanged] = useState(false);

  const { data: caremergeCategories } = useSWR(
    isSyncedCustomer ? 'allCaremergeCategories' : null,
    GroupsAPI.getAllCaremergeCategories,
  );

  const caremergeCategoryOptions = useMemo(() => {
    if (!caremergeCategories) {
      return [];
    }

    return caremergeCategories.map(({ CategoryName, CaremergeCategoryId }) => ({
      label: CategoryName,
      value: `${CaremergeCategoryId}`,
    }));
  }, [caremergeCategories]);

  const messageObj = {
    title: 'Group Changes',
    messageText: 'You have unsaved changes, are you sure you want to leave?',
  };

  return (
    <>
      <Prompt
        when={Object.keys(formState.dirtyFields).length > 0 || isGroupMembersChanged}
        message={JSON.stringify(messageObj)}
      />
      {selectedGroupId != null && selectedGroupId?.toString() !== '0' && (
        <form
          onSubmit={handleSubmit(interceptSubmit)}
          className="form ContentForm"
        >
          <Container>
            <Row>
              <Col xs={6}>
                {isSyncedCustomer && (
                  <SelectControl
                    id="category-select"
                    className="mb-2"
                    name="CaremergeCategoryId"
                    label="Category"
                    placeholder="Uncategorized"
                    control={control}
                    options={caremergeCategoryOptions}
                  />
                )}

                <Input
                  id="Name"
                  name="Name"
                  label="Name"
                  type="text"
                  control={control}
                  errors={errors.Name && errors.Name.message}
                  rules={{
                    required: 'Name is required',
                    validate: (val: any) => val.length <= 70 || 'Group name must not exceed 70 characters',
                  }}
                />
              </Col>
              <Col className="text-right">
                <OverlayCancelButton
                  label="Cancel"
                  className="mr-2 btn btn-secondary btn-medium"
                  onAccept={() => {
                    resetFilter();
                    setShowEditMembers(false);
                  }}
                  warningMessage="Are you sure you want to leave this page? Your changes will be lost."
                />
                <Button type="submit" variant="primary" className="btn-medium">
                  Save
                </Button>
              </Col>
            </Row>
            <Row style={{ height: '75vh' }}>
              <Col xs={6}>
                <Row>
                  <Col xs={3} />
                </Row>

                <Row>
                  <Col>
                    <table
                      className="table table-striped"
                      style={{ marginBottom: '0rem' }}
                    >
                      <thead>
                        <tr>
                          <th style={{ paddingLeft: '1.3rem' }}>
                            <input
                              type="checkbox"
                              checked={checkAllProfiles}
                              onChange={(e) => {
                                onCheckAll(e.target.checked);
                              }}
                            />
                          </th>
                          <th>
                            <b>Available Profiles</b>
                          </th>
                          <th style={{ padding: '0 0 5px 0' }}>
                            <button
                              type="button"
                              className="filter-button ml-2"
                              onClick={() => setIsFilterModalOpen(true)}
                            >
                              <span className="Calendar__filter-icon">
                                <FontAwesomeIcon icon="filter" />
                                {hasFilter && <FontAwesomeIcon icon="plus" />}
                              </span>
                            </button>
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <Modal
                      show={isFilterModalOpen}
                      onHide={() => setIsFilterModalOpen(false)}
                      dialogClassName="modal-20w"
                      centered
                    >
                      <Modal.Header closeButton />
                      <Modal.Body>
                        <div className="justify-content-center">
                          <Row>
                            <Select
                              value={groupFilterValue}
                              className="form-control groupFilter"
                              placeholder="Search Profiles by Group"
                              options={groupOptions}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              isClearable
                              onChange={(value: {
                                id: number
                                label: string
                                value: number
                              }) => {
                                // setCheckAllProfiles(false);
                                setGroupFilterValue(
                                  value == null ? { id: 0, label: 'Search Profiles by Group', value: 0 } : value,
                                );
                              }}
                            />
                          </Row>
                          <Row>
                            <SearchInput
                              className="groupFilter"
                              label="Search Profiles by name"
                              placeholder="I am looking for..."
                              name="searchValue"
                              // onKeyDown={(evt) => evt.key === 'Enter' && evt.preventDefault()}
                              onChange={onSearchChange}
                              aria-label="Search"
                              value={availableSearchValue || ''}
                            />
                          </Row>
                          <Row className="modal-buttons">
                            {(availableSearchValue !== '' || groupFilterValue.value !== 0) && renderResetFilterButton()}
                            <Button
                              type="button"
                              className="btn btn-primary mt-4"
                              size="sm"
                              disabled={availableSearchValue === '' && groupFilterValue.value === 0}
                              onClick={() => {
                                onProfileGroupFiltering();
                                setIsFilterModalOpen(false);
                              }}
                            >
                              Apply Filter
                            </Button>
                          </Row>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AvailableProfiles
                      groupMembers={groupMembers}
                      profiles={filteredProfiles}
                      membersIdSet={membersIdSet}
                      onAvailableProfileChecked={onAvailableProfileChecked}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <Row>
                  <Col xs={3} />
                </Row>
                <Row>
                  <Col>
                    <table
                      className="table table-striped"
                      style={{ marginBottom: '0rem' }}
                    >
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'center' }}>
                            <b>Selected Profiles</b>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!profilesLoading && (
                      <SelectedProfiles
                        groupMembers={groupMembers}
                        onRemoved={handleRemoveMember}
                      />
                    )}
                  </Col>
                </Row>
                <Row />
              </Col>
            </Row>
          </Container>
          <Input id="Id" name="Id" type="hidden" control={control} />
          <Input id="Description" name="Description" type="hidden" control={control} />
        </form>
      )}
    </>
  );
};

export default GroupEdit;
