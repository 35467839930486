import React, { useState, ReactElement } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IErrorState } from 'reducers/IErrorState';
import { RootState } from 'types/rootState';
import useErrors from 'components/hooks/useErrors';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import {
  createProfile, addProfileGroups, profilesAddFamilySuccess, postProfileRelationships, setProfileCustomFields,
} from '../../reducers/Profiles';
import Form, { IFormData } from './components/Form';
import PreferencesWarningModal from './components/PreferencesWarningModal';

function ProfilesNewPage(): ReactElement {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [profData, setProfData] = useState<IFormData>(null);
  const profileErrors: IErrorState = useSelector((state: RootState) => state.Error);
  const addingFamily = useSelector((state: RootState) => state.Profiles.addingFamily);
  const [familyRedirectUrl, setFamilyRedirectUrl] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);

  useErrors(profileErrors);

  const onSubmit = (data: IFormData): void => {
    if (!data.EmailOptIn && !data.OnDemandOptIn && !data.LandLineOptIn
      && !data.SMSOptIn && !data.MobilePhoneOptIn) {
      setProfData(data)
      setShowModal(true)
    } else {
      saveProfile(data);
    }
  };
  const saveProfile = (data: IFormData): void => {
    setSaving(true);
    const placeHolder = '(___) ___-____';
    const profileData = {
      ...data,
      LandLine: placeHolder === data.LandLine ? '' : data.LandLine,
      MobilePhone: placeHolder === data.MobilePhone ? '' : data.MobilePhone,
      OnDemand: placeHolder === data.OnDemand ? '' : data.OnDemand,
      ZipCode: data.ZipCode,
    };
    segmentAnalyticsTrack(trackActions.editProfile());
    dispatch(createProfile(profileData, (CustomerProfileID) => {
      if (data.Groups.length > 0) {
        dispatch(addProfileGroups(CustomerProfileID, data.Groups, () => {
        }));
      }

      if (addingFamily.customerProfileID !== '') {
        setFamilyRedirectUrl(`/profiles/${addingFamily?.customerProfileID}/edit`)
        dispatch(postProfileRelationships(addingFamily.customerProfileID, [CustomerProfileID]))
        dispatch(profilesAddFamilySuccess());
      }

      if (data.CustomFields && Object.keys(data.CustomFields).length > 0) {
        dispatch(setProfileCustomFields(CustomerProfileID, data.CustomFields));
      }

      setSuccess(true);
      setSaving(false);
      toast.success('Profile successfully created');
    }, () => {
      setShowModal(false)
      setSaving(false);
    }));
  }
  if (success) {
    if (familyRedirectUrl) {
      return (
        <Redirect to={familyRedirectUrl} />
      );
    }
    return <Redirect to="/profiles" />;
  }

  return (
    <>
      <Helmet>
        <title>New Profile</title>
      </Helmet>

      <div className="container profiles">
        <Form onSubmit={onSubmit} saving={saving} />
        <PreferencesWarningModal
          showModal={showModal}
          setShowModal={setShowModal}
          saveProfile={saveProfile}
          disableSavingButton={saving}
          setDisableSavingButton={setSaving}
          profData={profData}
        />
      </div>
    </>
  );
}

export default ProfilesNewPage;
