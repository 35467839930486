import React, {
  useEffect, useState, FC, ReactElement,
} from 'react';
import {
  Col, Row, Container, Form, Card, DropdownButton, Dropdown,
} from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import Select from 'react-select';
import _first from 'lodash/first';
import API from 'api/API';
import { useDispatch } from 'react-redux';
import { Input } from 'components/FormControls';
import { TemplateOrEvent, VoiceMessageTypeEnum } from 'types/IEvent';
import { useLibraries } from 'components/hooks/useLibraryContent';
import { ILibraryContent, LibraryContentType } from 'types/ILibraryContent';
import { VoiceContent } from './VoiceContent';
import { GenerateContentButton } from './GenerateContentButton';

function getCategories(setCategories) {
  // TODO move to an action creator and reducer
  // and then use a selector to get the categories
  return async () => {
    const categories = await API.get('/api/v2/categories');
    setCategories(categories.data);
  };
}
type props = {
  removeButton: ReactElement
  isEvent?: boolean
  isSurvey?: boolean
  data?: TemplateOrEvent
  setShowGenerateModal?: (show: 'voice') => void
}
export const TemplateContentVoice: FC<props> = ({
  removeButton, isEvent, isSurvey, data, setShowGenerateModal,
}) => {
  const [selectedLibraryContent, setSelectedLibraryContent] = React.useState(null);
  const {
    control, setValue, getValues, errors, register, watch, trigger,
  } = useFormContext();

  const [categories, setCategories] = useState<string[]>([]);
  const categoryWatch = watch('Category');
  const [categorySelected, setCategorySelected] = useState<string>(categoryWatch || '');
  const smartContentEnabled = !!setShowGenerateModal;

  const messageType = watch('VoiceMessageType');
  const broadcastSelected = messageType === VoiceMessageTypeEnum.Broadcast
  || messageType === VoiceMessageTypeEnum.Both;

  const onDemandSelected = messageType === VoiceMessageTypeEnum.OnDemand
  || messageType === VoiceMessageTypeEnum.Both;

  const setBroadcastSelected = (val) => {
    let t: VoiceMessageTypeEnum;
    if (val && onDemandSelected) {
      t = VoiceMessageTypeEnum.Both;
    } else if (val && !onDemandSelected) {
      t = VoiceMessageTypeEnum.Broadcast;
    } else if (!val && onDemandSelected) {
      t = VoiceMessageTypeEnum.OnDemand;
    } else {
      // error none is not valid.
      t = VoiceMessageTypeEnum.None;
    }
    setValue('VoiceMessageType', t);
    if (t === VoiceMessageTypeEnum.Both || t === VoiceMessageTypeEnum.OnDemand) {
      const category = getValues('Category');
      setCategorySelected(category || _first(categories))
    } else {
      setCategorySelected('');
    }
  };

  const setOnDemandSelected = (val) => {
    let t: VoiceMessageTypeEnum;
    if (val && broadcastSelected) {
      t = VoiceMessageTypeEnum.Both;
    } else if (val && !broadcastSelected) {
      t = VoiceMessageTypeEnum.OnDemand;
    } else if (!val && broadcastSelected) {
      t = VoiceMessageTypeEnum.Broadcast;
    } else {
      // error none is not valid.
      t = VoiceMessageTypeEnum.None;
    }
    setValue('VoiceMessageType', t);
    if (t === VoiceMessageTypeEnum.Both || t === VoiceMessageTypeEnum.OnDemand) {
      const category = getValues('Category');
      setCategorySelected(category || _first(categories))
    } else {
      setCategorySelected('');
    }
  };

  const [showVoiceOptions, setShowVoiceOptions] = useState<boolean>(true);
  const selectedLanguage = watch('SourceLanguage');
  const type = LibraryContentType.VOICE
  const libraries = useLibraries({ type }).filter(
    (lib) => lib.SourceLanguage === selectedLanguage);

  const voiceContentTypeOptions = libraries.map((l) => ({
    id: l.ID,
    label: l.Name,
    value: l.ID,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories(setCategories));
  }, [dispatch]);

  useEffect(() => {
    trigger(['VoiceContent.OnDemandMessage', 'VoiceContent.BroadcastMessage']);
    setValue('Category', (categorySelected));
    if (broadcastSelected && onDemandSelected) {
      setValue('VoiceMessageType', VoiceMessageTypeEnum.Both);
    } else if (broadcastSelected) {
      setValue('VoiceMessageType', VoiceMessageTypeEnum.Broadcast)
    } else if (onDemandSelected) {
      setValue('VoiceMessageType', VoiceMessageTypeEnum.OnDemand)
    } else {
      setValue('VoiceMessageType', VoiceMessageTypeEnum.Broadcast)
    }
  }, [broadcastSelected,
    categorySelected,
    onDemandSelected,
    setValue,
    trigger]);

  const handleSelectCategory = (event): void => {
    setValue('Category', event?.value);
  }
  const onSelectLibrary = (library: ILibraryContent) => {
    setShowVoiceOptions(false);
    setValue('VoiceContent.VoiceType', library.VoiceContent.VoiceType);
    setTimeout(() => {
      // should be fill the voice content delay.
      setValue('VoiceContent', library.VoiceContent);
    }, 100);
    setSelectedLibraryContent({ ...library.VoiceContent });
  };
  const generateLibraryItems = (options: any) => {
    if (options && options.length > 0) {
      return (
        <>
          {options.map(({ id, label }) => (
            <Dropdown.Item key={id} eventKey={id}>
              {label}
            </Dropdown.Item>
          ))}
        </>
      )
    }
    return (
      <Dropdown.Item>No Content</Dropdown.Item>
    );
  }

  return (
    <Card>
      <Card.Header as="h4">
        <span>Voice Content</span>
        {' '}
        {removeButton}
        <div className="float-right d-flex">
          {smartContentEnabled && (
            <GenerateContentButton
              setShowGenerateModal={setShowGenerateModal}
              channel="voice"
            />
          )}
          <DropdownButton
            className="ml-1"
            id="select-library-content"
            title="Select Content from Library"
            variant="secondary"
            size="sm"
            onSelect={(e) => {
              if (libraries && libraries.length > 0) {
                onSelectLibrary(libraries.find((lib) => lib.ID.toString() === e));
              }
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {generateLibraryItems(voiceContentTypeOptions)}
          </DropdownButton>
        </div>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <div>
            <input
              type="hidden"
              name="VoiceMessageType"
              ref={register({ required: true })}
            />
            <Input id="Category" control={control} type="hidden" name="Category" />
            <Row style={{ height: '40px' }}>
              <Col md="auto">
                <Form.Check
                  type="checkbox"
                  id="Broadcast-Switch"
                  label="Outbound"
                  name="VoiceContent.BroadcastMessage"
                  ref={
                    register({
                      validate: () => broadcastSelected || onDemandSelected,
                    })
                  }
                  checked={broadcastSelected}
                  onClick={() => {
                    setBroadcastSelected(!broadcastSelected)
                  }}
                />
              </Col>
              <Col md="auto">
                <Form.Check
                  type="checkbox"
                  id="On-Demand-Switch"
                  label="On-Demand"
                  name="VoiceContent.OnDemandMessage"
                  ref={
                    register({
                      validate: () => broadcastSelected || onDemandSelected,
                    })
                  }
                  checked={onDemandSelected}
                  // IE 11 do not fire the onchange event, so i change it to onclick
                  onClick={() => {
                    setOnDemandSelected(!onDemandSelected)
                  }}
                />
              </Col>
              {onDemandSelected && (
                <>
                  <Col md="auto">
                    <h6>
                      Select a message category:
                      {' '}
                    </h6>
                  </Col>
                  <Col>
                    <Select
                      name="CategorySelect"
                      options={categories?.map((c) => (
                        {
                          value: c,
                          label: c,
                        }
                      ))}
                      control={control}
                      defaultValue={
                        {
                          label: (categorySelected || _first(categories)),
                          value: (categorySelected || _first(categories)),
                        }
                      }
                      onChange={handleSelectCategory}
                    />
                  </Col>
                </>
              )}
            </Row>
            {(errors?.VoiceContent?.OnDemandMessage || errors?.VoiceContent?.BroadcastMessage) && (
              <span className="errors">Message type Required</span>
            )}
          </div>
          <br />
          <VoiceContent
            showNewContentButtons={showVoiceOptions}
            showKeypad={isSurvey}
            libraryContent={selectedLibraryContent}
          />
        </Container>
      </Card.Body>
    </Card>
  );
};
