import React from 'react';
import { ViewLabelsModal } from './ViewLabelsModal';

export const ViewLabels = () => {
  const [showView, setShowView] = React.useState(false);

  return (
    <>
      <button
        type="button"
        className="btn btn-warning mt-auto mb-2 btn-md"
        style={{ minWidth: 150 }}
        onClick={() => {
          setShowView(true);
        }}
      >
        View Labels
      </button>
      {showView && (
        <ViewLabelsModal
          onHide={() => {
            setShowView(false);
          }}
          onCreate={() => {
            setShowView(false);
          }}
        />
      )}
    </>
  )
}
