import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import Textarea, { ITextareaProps } from 'components/Inputs/Textarea';
import { IFormControlProps } from './IFormControlProps';

export interface ITextareaControllerProps extends
  IFormControlProps, Omit<ITextareaProps, 'name' | 'defaultValue' | 'id'> {}

const TextareaController: FC<ITextareaControllerProps> = ({
  name, defaultValue = '', control, rules, ...props
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    rules={rules}
    render={(renderProps) => (
      <Textarea {...renderProps} {...props} />
    )}
  />
)

export default TextareaController;
