import API from 'api/API';
import { IWellnessContent } from '../types/IWellnessContent';

export const API_PATH = '/api/Wellness';

export async function wellnessContentGet(id: string): Promise<IWellnessContent[]> {
  const response = await API.get<IWellnessContent[]>(`${API_PATH}/${id}/content`);
  return response.data;
}

export async function wellnessCheckGet(): Promise<IWellnessContent[]> {
  const response = await API.get<IWellnessContent[]>(`${API_PATH}`);
  return response.data;
}
