import React,
{
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchCaremergeVFJwt, fetchUserInfo, linkVoicefriend, setCurrentNavItem,
} from 'reducers/UserInfo';
import { useQueryParam } from 'use-query-params';
import { useForm } from 'react-hook-form';
import { RootState } from 'types/rootState';
import { Alert, Form } from 'react-bootstrap';
import { Input } from 'components/FormControls';
import { IUserInfoState } from 'reducers/IUserInfoState';
import SubmitButton from 'components/FormControls/SubmitButton';
import { Helmet } from 'react-helmet';
import {
  CAREMERGE_REDIRECT_URL_QA,
  CAREMERGE_REDIRECT_URL_PROD,
  getCaremergeConfig,
} from 'api/CaremergeIntegrationAPI';
import useSWR from 'swr';
import { ICaremergeConfiguration } from 'types/ICaremergeConfig';
import logo from '../styles/images/caremerge-icon-logo.png';

const renderCheckingAuthentication = () => (
  <h4>Searching for linked user account... please wait</h4>
);

function LinkCaremerge(): ReactElement {
  const {
    handleSubmit, errors, control, getValues,
  } = useForm<ILinkCaremergeProp>();
  const [page] = useQueryParam<string>('page');
  const history = useHistory();
  const [checkingIfAlreadyLinked, setCheckingIfAlreadyLinked] = useState<boolean>(true);
  const userInfo = useSelector((state: RootState) : IUserInfoState => state.UserInfo);
  const currentAccountId = userInfo?.userInfo?.AccountDetail?.AccountID;
  const dispatch = useDispatch();
  const {
    loggedIn, loading, error,
  } = useSelector((state: RootState) => state.UserInfo);

  const { data: caremergeConfig, isLoading: isLoadingCaremergeConfig } = useSWR<ICaremergeConfiguration>(
    currentAccountId ? ['caremergeConfig', currentAccountId] : null,
    getCaremergeConfig,
  );

  const renderError = (): ReactElement => {
    if (error.failure) {
      return <Alert variant="danger">{error.message}</Alert>;
    }
    return null;
  };

  const redirectToCaremerge = useCallback((token: string) => {
    // if token is empty it means this user has not been linked yet
    if (token) {
      if (process.env.REACT_APP_ENVIRONMENT === 'qa') {
        window.location.replace(`${CAREMERGE_REDIRECT_URL_QA}?vfJwToken=${token}&page=${page}`);
      } else {
        window.location.replace(`${CAREMERGE_REDIRECT_URL_PROD}?vfJwToken=${token}&page=${page}`);
      }
    } else {
      setCheckingIfAlreadyLinked(false);
    }
  }, [page]);

  useEffect(() => {
    if (!loggedIn) {
      history.push(`/login?redirectTo=/linkCaremerge?page=${page}`);
    }
    dispatch(fetchUserInfo());
    dispatch(fetchCaremergeVFJwt(
      redirectToCaremerge,
      () => {
        setCheckingIfAlreadyLinked(false);
      }));
  }, [dispatch, history, loggedIn, page, redirectToCaremerge]);

  const renderTitle = () => (
    <>
      <h4>
        We need to confirm your identity
      </h4>
      <div>
        In order for us to grant access your account,
        we need to validate your identity.
        Please enter your current Caremerge credentials.
      </div>
    </>
  )

  const onSubmit = (): void => {
    dispatch(setCurrentNavItem(null));
    const password = getValues('password');
    const email = getValues('emailAddress');
    dispatch(linkVoicefriend(null, email, password, () => {
      dispatch(fetchCaremergeVFJwt(
        redirectToCaremerge,
        () => { }));
    }, () => { }));
  };

  const linkCaremergeAccount = () => (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Input
          id="emailAddress"
          name="emailAddress"
          readOnly
          defaultValue={userInfo.email}
          className={errors.emailAddress ? 'errorBorder' : ''}
          type="email"
          label="Email"
          control={control}
          rules={{
            required: 'Email must be supplied',
          }}
        />
        <span className="text-danger">
          {errors.emailAddress && errors.emailAddress.message}
        </span>
      </Form.Group>
      <Form.Group>
        <Input
          id="password"
          name="password"
          autoComplete="new-password"
          className={errors.password ? 'errorBorder' : ''}
          type="password"
          label="Caremerge Password"
          control={control}
          rules={{
            required: 'Password must be supplied',
          }}
        />
        <span className="text-danger">
          {errors.password && errors.password.message}
        </span>
      </Form.Group>
      <SubmitButton
        label="Submit"
        savingLabel="Saving..."
        className="btn btn-primary"
        saveComplete={!loading}
      />
    </Form>
  )

  if (isLoadingCaremergeConfig || checkingIfAlreadyLinked) {
    return (
      <NoNavFrameLink title="Link Voicefriend">
        {renderCheckingAuthentication()}
      </NoNavFrameLink>
    )
  }

  if (caremergeConfig == null) {
    return (
      <NoNavFrameLink title="Link Voicefriend">
        <h4>This account has no associated caremerge account</h4>
      </NoNavFrameLink>
    )
  }

  if (caremergeConfig.IconUsersOnly) {
    return (
      <NoNavFrameLink title="Link Voicefriend">
        <h4>This account is not configured to support user linking</h4>
      </NoNavFrameLink>
    )
  }

  return (
    <NoNavFrameLink title="Link Voicefriend">
      <>
        {renderTitle()}
        {renderError()}
        {linkCaremergeAccount()}
      </>
    </NoNavFrameLink>
  )
}
interface ILinkCaremergeProp {
  emailAddress: string
  password: string
}

interface IProps {
  title: string
  children: React.ReactNode
}
export function NoNavFrameLink({ title = 'Link Caremerge', children }: IProps): ReactElement {
  return (
    <div className="linkVoicefriend">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="linkVoicefriend-form">
        <div className="form-container">
          <img className="logo" alt="VoiceFriend logo" src={logo} />
          {children}
        </div>
        <div className="copyright label">
          &copy;
          {new Date().getFullYear()}
          {' '}
          VoiceFriend LLC. All rights reserved.
        </div>
      </div>
      <div className="link-caremerge-splash d-none d-lg-flex">
        <div className="link-caremerge-splash__img" />
      </div>
    </div>
  );
}

export default LinkCaremerge;
