import React from 'react'
import MiddleDashboard from './components/middle-dashboard'

function Dashboard() {
  return (
    <div className="dashboard">
      <MiddleDashboard />
    </div>
  )
}

export default Dashboard
