import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSWRConfig } from 'swr';

import { updateEventInstance } from 'reducers/Events';
import { EventTemplateMode, IEventExtendo } from 'types/IEvent';
import { EventTemplateForm } from 'components/EventTemplateForm/EventTemplateForm';
import { useEventOccurrenceQuery } from 'components/hooks/useEventOccurrenceQuery';
import { useEventQuery } from 'components/hooks/useEventQuery';

export default function EditEventOccurencePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { mutate } = useSWRConfig();
  const { id: eventId, date: occurrenceDate } = useParams<{
    id: string
    date: string
  }>();

  const [data, setData] = React.useState<IEventExtendo|null>(null);

  const {
    data: eventOccurrence, isValidating: isEventOccurrenceFetching,
  } = useEventOccurrenceQuery(+eventId, occurrenceDate, {
    onSuccess: (eventToEdit) => {
      if (!eventToEdit) {
        return;
      }
      setData({
        ...eventToEdit,
        StartDate: moment(occurrenceDate, 'yyyy-MM-DD').format('MM-DD-yyyy'),
      });
    },
  });
  useEventQuery(+eventId, {
    enabled: !eventOccurrence && !isEventOccurrenceFetching,
    onSuccess: (eventToEdit) => {
      if (!eventToEdit) {
        return;
      }
      setData({
        ...eventToEdit,
        StartDate: moment(occurrenceDate, 'yyyy-MM-DD').format('MM-DD-yyyy'),
      });
    },
  });

  React.useEffect(() => {
    if (!eventId) {
      history.push('/calendar');
    }
  }, [eventId, history]);

  const handleEditEventOccurence = (formData: IEventExtendo) => {
    const onSuccess = async () => {
      history.push('/calendar');
      await mutate(
        ['eventOccurrence', data.ID, occurrenceDate],
        undefined,
        {
          revalidate: false,
          populateCache: true,
        },
      );
    }

    dispatch(updateEventInstance(formData, occurrenceDate, onSuccess));
  };

  const isLoading = !data;

  return (
    <div>
      {isLoading ? (
        <div
          className="loading-text d-flex align-items-center justify-content-center"
          role="status"
        >
          <Spinner
            animation="border"
            as="span"
            className="mr-2"
            style={{ width: '1.66em', height: '1.66em' }}
          />
          <span style={{ fontSize: '1.25em' }}>
            Loading...
          </span>
        </div>
      ) : (
        <EventTemplateForm
          data={data}
          setData={setData}
          onSubmit={handleEditEventOccurence}
          onCancel={() => {
            history.push('/calendar')
          }}
          eventTemplateMode={EventTemplateMode.Instance}
          occurrenceDate={occurrenceDate}
          enableGenerateSmartContent
        />
      )}
    </div>
  );
}
