/* eslint-disable react/no-unused-prop-types */
import React, {
  useEffect,
  useState,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as LabelsSvg } from 'styles/images/labels.svg';
import { ReactComponent as WarningSvg } from 'styles/images/warning.svg';
import useSWR from 'swr'
import { Label } from 'components/Label';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Input from 'components/Inputs/Input';
import * as EntityLabelApi from 'api/EntityLabelAPI';
import { IEntityLabel } from 'types/IEntityLabel';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { useDebounce } from 'react-use';

interface ViewLabelsModalProps {
  onHide: () => void
  onCreate: () => void
}

export const ViewLabelsModal: React.FC<ViewLabelsModalProps> = ({ onHide, onCreate }) => {
  const [searchVal, setSearchVal] = React.useState('');
  const [labelToDelete, setLabelToDelete] = React.useState<null|IEntityLabel>(null);
  const [labelToEdit, setLabelToEdit] = React.useState<null|IEntityLabel>(null);
  const [debouncedSearchVal, setDebouncedSearchVal] = React.useState('');

  useDebounce(
    () => {
      setDebouncedSearchVal(searchVal);
    },
    250,
    [searchVal],
  );

  const swrKey = ['entityLabels', debouncedSearchVal];

  const { data: entityLabels, mutate, isValidating } = useSWR(
    swrKey,
    () => EntityLabelApi.entityLabelsGet(encodeURIComponent(searchVal.trim())),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const handleDelete = async () => {
    try {
      setMutationInProgress(true);
      await EntityLabelApi.entityLabelDelete(labelToDelete.EntityLabelId);
      mutate();
      setLabelToDelete(null);
      setShowDelete(false);
    } catch (e) {
      toast.error('An unknown error has occurred, please try again later.');
    } finally {
      setMutationInProgress(false);
    }
  }

  const handleEdit = async () => {
    try {
      setMutationInProgress(true);
      await EntityLabelApi.entityLabelPut({ ...labelToEdit, Name: createValue });
      mutate();
      setLabelToEdit(null);
      setShowEdit(false);
    } catch (e) {
      toast.error(e.response.data.Message);
    } finally {
      setMutationInProgress(false);
    }
  }

  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [mutationInProgress, setMutationInProgress] = useState(false);

  const [createValue, setCreateValue] = React.useState('');
  const handleCreate = async () => {
    try {
      setMutationInProgress(true);
      await EntityLabelApi.entityLabelCreate([{ Name: createValue.trim() }]);
      setShowCreate(false);
      mutate();
    } catch (e) {
      toast.error(e.response.data.Message);
    } finally {
      setMutationInProgress(false);
    }
  }

  const disabledSaveButton = () => {
    if (showCreate) {
      return !createValue.trim() || mutationInProgress
    }
    if (showEdit && labelToEdit) {
      return !createValue.trim() || createValue.trim() === labelToEdit?.Name || mutationInProgress
    }
    return false;
  }

  const shortenLongName = (longName: string) => {
    if (longName && longName.length > 40) {
      return `${longName.substring(0, 40)}...`;
    }
    return longName;
  }

  useEffect(() => {
    setCreateValue('');
  }, [showCreate]);

  return (
    <Modal
      show
      onHide={onHide}
      className={showDelete ? 'delete-labels-modal' : 'view-labels-modal'}
    >
      <Modal.Header>
        {!showDelete && (
          <>
            <LabelsSvg />
            <Modal.Title>
              Labels
            </Modal.Title>
            {isValidating && (
              <Spinner
                animation="border"
                variant="primary"
                role="status"
                className="d-block ml-3"
              />
            )}
          </>
        )}
        {showDelete && (
          <>
            <WarningSvg />
            <Modal.Title>
              Delete Label
            </Modal.Title>
          </>
        )}
      </Modal.Header>
      <Modal.Body>
        {!showCreate && !showEdit && !showDelete && (
          <div style={{ overflowY: 'auto', overflowX: 'hidden', height: 400 }}>
            <Input
              type="text"
              placeholder="Search label..."
              // className="mb-0"
              value={searchVal}
              onChange={(e) => {
                setSearchVal(e.target.value);
              }}
            />
            <div>
              {entityLabels && entityLabels.length > 0 && (
                entityLabels.map((label) => (
                  <Row key={label.EntityLabelId}>
                    <Col className="d-flex align-items-center label-container" style={{ width: 300 }}>
                      <Label
                        name={label.Name}
                        showDelete={false}
                        onDelete={() => {}}
                        renderBody={(ref) => (
                          <span
                            className="label-text mt-1"
                            ref={ref}
                          >
                            {label.Name}
                          </span>
                        )}
                      />
                    </Col>
                    <Col className="d-flex align-items-center" style={{ padding: 0, maxWidth: 40 }}>
                      <button
                        type="button"
                        className="btn btn-cancel label-edit"
                        onClick={() => {
                          setShowEdit(true);
                          setLabelToEdit(label)
                          setCreateValue(label.Name);
                        }}
                      >
                        Edit
                      </button>
                    </Col>
                    <Col className="d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-cancel label-delete"
                        onClick={() => {
                          setShowDelete(true);
                          setLabelToDelete(label)
                        }}
                      >
                        Delete
                      </button>
                    </Col>
                  </Row>
                ))
              )}
              {(!entityLabels || entityLabels.length === 0) && (
                <Row>
                  <Col className="col-md-9 d-flex align-items-center label-container">
                    <span className="label-unmatch mt-1">No Matching Labels</span>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        )}

        {(showCreate || showEdit) && (
          <Input
            type="text"
            placeholder="Enter label name"
            label="Please enter a new label name:"
            className="mb-0"
            value={createValue}
            onChange={(e) => {
              setCreateValue(e.target.value);
            }}
          />
        )}

        {showDelete && (
          <div>
            <p className="message">
              Are you sure you want to delete &quot;
              {shortenLongName(labelToDelete?.Name)}
              &quot;
            </p>
            <button
              type="button"
              className="btn btn-primary square w-100"
              disabled={mutationInProgress}
              onClick={handleDelete}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-link w-100 mt-3"
              onClick={() => { setShowDelete(false); }}
            >
              Cancel
            </button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!showCreate && !showEdit && !showDelete && (
          <div>
            <button
              type="button"
              className="btn btn-dark square"
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary square ml-2"
              onClick={() => { setShowCreate(true) }}
            >
              Add New Label
            </button>
          </div>
        )}

        {(showCreate || showEdit) && (
          <div>
            <button
              type="button"
              className="btn btn-dark square"
              onClick={() => {
                if (showCreate) {
                  setShowCreate(false)
                } else {
                  setShowEdit(false)
                }
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary square ml-2"
              onClick={() => {
                if (showCreate) {
                  handleCreate()
                } else {
                  handleEdit();
                }
              }}
              disabled={disabledSaveButton()}
            >
              { showCreate ? 'Create' : 'Save' }
            </button>
          </div>
        )}

      </Modal.Footer>
    </Modal>
  )
}
