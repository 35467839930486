import React, {
  useEffect, useCallback, ReactElement,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Dropdown, DropdownButton,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { CellProps } from 'react-table';
import {
  selectLibraryContent,
  getLibraryIsLoading,
  getLibraryContentIndex,
  selectPaginationData,
} from 'reducers/LibraryContent';
import Table from 'components/Table';
import { ILibraryIndexParams } from 'types/ILibraryIndexParams';
import Pagination from 'components/Pagination';
import { ILibraryContent } from 'types/ILibraryContent';
import { ReactComponent as Plus } from 'styles/images/plus.svg';
import IndexFilter, { ISearchQuery } from './components/IndexFilter';
import DeleteActionButton from './components/DeleteActionButton';

const contentOptions = [
  {
    value: '/librarycontent/newvoice/TextToSpeech',
    label: 'New Text-To-Speech Voice',
    icon: 'phone',
  },
  {
    value: '/librarycontent/newvoice/Recording',
    label: 'New Voice Recording',
    icon: 'phone',
  },
  {
    value: '/librarycontent/newsms',
    label: 'New Text Message',
    icon: 'comment',
  },
  {
    value: '/librarycontent/newemail',
    label: 'New Email',
    icon: 'envelope-square',
  },
];

const columns = [
  { Header: 'ID', accessor: 'ID', sortable: true },
  { Header: 'Name', accessor: 'Name', sortable: true },
  {
    Header: 'Type',
    accessor: 'Type',
    sortable: true,
    Cell: (props: CellProps<ILibraryContent>): string => {
      const {
        row: { original },
      } = props;
      if (original.Type.toLocaleLowerCase() === 'sms') return 'Text Message';
      return original.Type !== 'Voice' ? original.Type : original.VoiceContent.VoiceType;
    },
  },
];

function LibraryContentIndexPage(): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useQueryParam('page', NumberParam);
  const [filterType, setFilterType] = useQueryParam('filterType', StringParam);
  const [, setFilterGroup] = useQueryParam(
    'filterGroup',
    StringParam,
  );

  const [sortField, setSortField] = useQueryParam('sortField', StringParam);
  const [sortDirection, setSortDirection] = useQueryParam(
    'sortDirection',
    StringParam,
  );

  const [searchQuery, setSearchQuery] = useQueryParam(
    'searchQuery',
    StringParam,
  );

  const isLoading = useSelector(getLibraryIsLoading);

  const resetFilters = (): void => {
    setFilterType('', 'pushIn');
    setFilterGroup('', 'pushIn');
    setSearchQuery('', 'pushIn');
    setCurrentPage(1, 'pushIn');
  };

  const paginationData = useSelector(selectPaginationData);

  const getSearchQuery = useCallback((): ISearchQuery => {
    const query = searchQuery?.split(',');
    if (!query) return {};
    return { searchField: query[0], searchValue: query[1] };
  }, [searchQuery]);

  const onTypeChange = (type: string): void => {
    setFilterType(type, 'pushIn');
    setCurrentPage(1, 'pushIn');
  };
  const onSearch = (query: ISearchQuery): void => {
    if (query.searchField || query.searchValue) {
      setSearchQuery(
        `${query.searchField || ''},${query?.searchValue || ''}`,
      );
    } else {
      setSearchQuery('');
    }
    setCurrentPage(1, 'pushIn');
  };

  const getLibraryIndexParams = useCallback(() => {
    const params: ILibraryIndexParams = {
      page: currentPage,
      perpage: 10,
    };
    const query = getSearchQuery();
    if (query.searchField && query.searchValue) {
      params.searchField = query.searchField;
      params.searchValue = query.searchValue;
    }

    if (sortField && sortDirection) {
      params.sortField = sortField;
      params.sortDirection = sortDirection;
    }

    return params;
  }, [getSearchQuery, sortField, sortDirection, currentPage])

  const dispatchLibraryIndex = useCallback((): void => {
    dispatch(getLibraryContentIndex(getLibraryIndexParams()));
  }, [dispatch, getLibraryIndexParams]);

  useEffect(() => {
    dispatchLibraryIndex();
  }, [dispatchLibraryIndex]);

  const libraryContent: ILibraryContent[] = useSelector(selectLibraryContent(getLibraryIndexParams()));
  const rowActionItems = (entityId: string): ReactElement => (
    <>
      <Link to={`/librarycontent/${entityId}/edit`}>Edit</Link>
      {' '}
      <DeleteActionButton
        libraryContentIds={[entityId]}
        onSuccess={dispatchLibraryIndex}
      />
    </>
  );

  const renderIndexFilter = (): ReactElement | null => (
    <IndexFilter
      filterType={filterType || ''}
      searchQuery={getSearchQuery()}
      onTypeChange={onTypeChange}
      onSearch={onSearch}
      onResetFilters={resetFilters}
      totalItems={paginationData.TotalItems}
      itemsLoading={isLoading}
    />
  );

  const createNewcontent = (value: string): void => {
    history.push(value);
  };

  return (
    <div className="LibraryContentIndex">
      <Helmet>
        <title>Library Content</title>
      </Helmet>
      <div className="pb-4">
        <DropdownButton
          id="ddbNewContent"
          size="lg"
          title={(
            <>
              <Plus
                width="24"
                height="24"
                fill="currentColor"
                style={{ marginRight: '10px' }}
              />
              Add New Content
            </>
          )}
          variant="warning"
          onSelect={(e) => {
            createNewcontent(e);
          }}
        >
          {contentOptions.map(({ value, label, icon }) => (
            <Dropdown.Item key={value} eventKey={value}>
              <FontAwesomeIcon icon={icon as IconName} />
              &nbsp;&nbsp;
              {label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
      {renderIndexFilter()}
      <div>
        <Table
          columns={columns}
          data={libraryContent}
          rowIDAccessor="ID"
          rowActionItems={rowActionItems}
          enableCheck={false}
          onRowClick={(id: string) => {
            history.push(`/librarycontent/${id}/edit`);
          }}
          isLoading={isLoading}
          onColumnSort={(fieldName, direction) => {
            if (fieldName.length > 0) {
              setSortField(fieldName);
              setSortDirection(direction);
            } else {
              setSortField('');
              setSortDirection('');
            }
          }}
          defaultSortedColumn={sortField}
          defaultSortedColumnDirection={sortDirection}
        />
        <Pagination
          currentPage={currentPage || 1}
          totalPages={paginationData.TotalPages}
          onPageChange={(page: number) => setCurrentPage(page, 'pushIn')}
          pageDelta={5}
        />
      </div>
    </div>
  );
}
export default LibraryContentIndexPage;
