/* eslint-disable jsx-a11y/label-has-associated-control */

import React, {
  useEffect, useState, ReactElement,
} from 'react';

import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Select, SearchInput } from 'components/FormControls';

export interface ISearchQuery {
  searchField?: string
  searchValue?: string
}

interface IProps {
  filterType: string
  searchQuery: ISearchQuery
  onTypeChange: (type: string) => void
  onSearch: (searchQuery: ISearchQuery) => void
  onResetFilters: () => void
  totalItems: number
  itemsLoading?: boolean
  children?: React.ReactNode
}

function IndexFilter({
  filterType,
  searchQuery,
  onTypeChange,
  onSearch,
  onResetFilters,
  totalItems,
  itemsLoading,
  children,
}: IProps): ReactElement {
  const {
    control, setValue, reset, handleSubmit,
  } = useForm<ISearchQuery>({
    defaultValues: { ...searchQuery },
  });
  useEffect(() => {
    reset(searchQuery);
  }, [reset, searchQuery]);

  const [open, setOpen] = useState<boolean>(false);
  const [tempQuery, setTempQuery] = useState<ISearchQuery>(searchQuery);

  const onSearchSubmit = (data: ISearchQuery): void => {
    data.searchValue ? onSearch(data) : onSearch({});
  };

  const resetFilters = (): void => {
    setValue('searchValue', '');
    setValue('searchField', 'Name');
    onResetFilters();
    setOpen(false);
  };

  const [inputType, setInputType] = useState('text');

  useEffect(() => {
    if (tempQuery.searchField && tempQuery.searchField === 'id') {
      setInputType('number');
      setValue('searchValue', '');
      return;
    }
    setInputType('text');
  }, [tempQuery.searchField, setValue]);

  const handleTempQueryChange = (key: keyof ISearchQuery, val: string) => {
    setValue(key, val);

    setTempQuery({
      ...tempQuery,
      [key]: val,
    });
  };

  return (
    <div
      className="index-filter"
    >
      <div className="index-filter__mobile-toggle" role="presentation" onClick={() => setOpen(!open)}>
        Filtering
        <FontAwesomeIcon icon={open ? 'caret-down' : 'caret-right'} />
      </div>
      <form id="form" onSubmit={handleSubmit(onSearchSubmit)}>
        <div
          className={clsx('index-filter__main', open && 'open')}
        >
          <Select
            id="searchField"
            label="Search By"
            name="searchField"
            className="mb-2 mr-2"
            style={{ width: '240px' }}
            control={control}
            defaultValue="Name"
            onChange={(v) => {
              handleTempQueryChange('searchField', v)
            }}
            options={[
              { label: 'Stored Message Name', value: 'Name' },
            ]}
          />

          <div className="d-flex align-items-end mb-2" style={{ maxWidth: '100%' }}>
            <SearchInput
              id="searchValue"
              label="Search Stored Message"
              placeholder="I am looking for..."
              name="searchValue"
              type={inputType}
              className="mb-0"
              onChange={(e) => {
                handleTempQueryChange('searchValue', e.target.value)
              }}
              control={control}
            />
            <button
              type="submit"
              className="btn btn-md btn-primary square ml-3"
            >
              Search
            </button>
            <button
              type="button"
              className="btn btn-md btn-link ml-3 px-0"
              onClick={resetFilters}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="flex-grow-1 d-none d-md-block" />
        {itemsLoading && (
          <div className="index-filter__item-count mb-2">
            <div className="itemCount loading-text">
              Loading...
            </div>
          </div>
        )}
        {!itemsLoading && (
          <div className="index-filter__item-count mb-2">
            <div className="itemCount">
              {`${totalItems} Content(s) In Total`}
            </div>
          </div>
        )}
        {children}
      </form>
    </div>
  );
}

export default IndexFilter;
