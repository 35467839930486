import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { loadingBarMiddleware } from 'react-redux-loading-bar'
import { Store } from 'redux';

import Api from 'api';
import rootReducer from './rootReducer';

export const createStore = (): Store => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [
      ...getDefaultMiddleware({
        thunk: {
          extraArgument: Api,
        },
      }),
      loadingBarMiddleware({
        promiseTypeSuffixes: ['Started', 'Success', 'Failed'],
      }),
    ],
  });

  if (process.env.NODE_ENV === 'development' && (module as any).hot) {
    (module as any).hot.accept('./rootReducer', () => {
      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      const newRootReducer = require('./rootReducer').default;
      store.replaceReducer(newRootReducer);
    });
  }

  window.__STORE__ = store;

  return store;
}
