import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _union from 'lodash/union';
import { normalize, schema } from 'normalizr';
import { IWellnessContent } from 'types/IWellnessContent';
import { IWellnessContentPayload } from 'types/IWellnessContentPayload';
import { RootState } from 'types/rootState';
import { AppThunk } from '../app/appThunk';
import { hasError } from './Error';
import { IWellnessState } from './IWellnessState';

export const INITIAL_STATE: IWellnessState = {
  loading: false,
  wellnessChecks: [],
  wellnessContent: [],
  wellnessContentByIds: {},
  selectedWellnessContent: null,
  selectedWellnessContents: [],
  pagedIds: {},
  allIds: [],
  pagination: {
    Page: 1,
    PerPage: 20,
    TotalItems: 0,
    TotalPages: 1,
  },

};

const wellnessContentSchema = new schema.Entity('Data', {}, { idAttribute: 'Id' });

const WellnessSlice = createSlice({
  name: 'Wellness',
  initialState: INITIAL_STATE,
  reducers: {
    wellnessRequestStarted(draftReducerState) {
      draftReducerState.loading = true;
    },
    wellnessRequestFailed(draftReducerState) {
      draftReducerState.loading = false;
    },
    wellnessContentRequestSuccess(draftReducerState, action: PayloadAction<IWellnessContent>) {
      draftReducerState.loading = false;
      draftReducerState.wellnessContentByIds[action.payload.ID] = action.payload;
    },
    wellnessContentPayloadSuccess(
      draftReducerState, action: PayloadAction<IWellnessContent[]>,
    ) {
      draftReducerState.wellnessContent = action.payload;
      draftReducerState.loading = false;
    },
    wellnessContentGetPayloadSuccess(
      draftReducerState, action: PayloadAction<IWellnessContent[]>,
    ) {
      draftReducerState.selectedWellnessContents = action.payload || undefined;
      draftReducerState.loading = false;
    },
    wellnessContentDeleteSuccess(draftReducerState, action: PayloadAction<{ id: number }>) {
      const wellnessContentId = action.payload.id;
      draftReducerState.loading = false;
      delete draftReducerState.wellnessContentByIds[wellnessContentId];
    },
    wellnessContentsPayloadSuccess(
      draftReducerState, action: PayloadAction<IWellnessContentPayload>,
    ) {
      const normalizedPayload = normalize(action.payload, {
        Data: [wellnessContentSchema],
      });
      const contentIds = normalizedPayload.result.Data;
      draftReducerState.wellnessContentByIds = {
        ...draftReducerState.wellnessContentByIds,
        ...normalizedPayload.entities.Data,
      };
      const {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      } = action.payload.Pagination;
      draftReducerState.pagedIds = {
        ...draftReducerState.pagedIds,
        [Page]: contentIds,
      };

      draftReducerState.pagination = {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      };
      draftReducerState.allIds = _union(draftReducerState.allIds, normalizedPayload.result);
      draftReducerState.loading = false;
    },
  },
});

export const getWellnessContent = (
  wellnessCheckId: string,
): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(wellnessRequestStarted());
  try {
    const wellnessGetResponse = await Api.Wellness.wellnessContentGet(wellnessCheckId);
    dispatch(wellnessContentPayloadSuccess(wellnessGetResponse));
  } catch (err) {
    dispatch(wellnessRequestFailed());
    dispatch(hasError(err));
  }
};

export const {
  wellnessRequestStarted,
  wellnessRequestFailed,
  wellnessContentPayloadSuccess,
  wellnessContentGetPayloadSuccess,
  wellnessContentsPayloadSuccess,
  wellnessContentRequestSuccess,
  wellnessContentDeleteSuccess,
} = WellnessSlice.actions;

export default WellnessSlice.reducer;

export const wellnessIsLoading = (
  state: RootState,
): boolean => state.Wellness.loading;

export const selectWellnessContentById = (
  state: RootState, wellnessContentId: number,
): IWellnessContent => state.Wellness.wellnessContent.filter((x) => x.Id === wellnessContentId)[0];

export const selectPaginationData = (
  state: RootState,
): IWellnessState['pagination'] => state.Wellness.pagination;

export const selectWellnessContentPage = (pageNumber: number) => (
  state: RootState,
): IWellnessContent[] => {
  const wellnessIds: number[] = state.Wellness.pagedIds[pageNumber] || [];
  const wellness: IWellnessContent[] = [];
  wellnessIds.forEach((id) => {
    const wellnessContent = state.Wellness.wellnessContentByIds[id.toString()];
    if (wellnessContent) {
      wellness.push(wellnessContent);
    }
  });
  return wellness;
};
