import API from 'api/API';
import { ISurveyQuestionsPayload } from 'types/ISurveyQuestionsPayload';
import { IFilterableIndexParams } from 'types/ITemplatesIndexParams';
import { ISurveyQuestion } from '../types/ISurveyQuestion';

export const API_PATH = '/api/v2/SurveyCalls';
// const questions = [
//   'Have you fallen or been re-admitted to a facility?',
//   'Have you had visits to the ER or Emergency Care',
//   'Are any of your symptoms worsening?',
//   'Have you missed any appointments?',
//   'Are you following your medications?',
// ];

export async function SurveyQuestionsIndex({
  searchField = '',
  searchValue = '',
  sortField = '',
  sortDirection = '',
  page,
  perpage,
}: IFilterableIndexParams): Promise<ISurveyQuestionsPayload> {
  const params = {
    filterType: (searchField.length > 0 ? searchField : undefined),
    filterValue: (searchValue.length > 0 ? searchValue : undefined),
    sortField: (sortField.length > 0 ? sortField : undefined),
    sortDirection: (sortDirection.length > 0 ? sortDirection : undefined),
    page: (page || undefined),
    perpage: (perpage || undefined),
  };
  const response = await API.get<ISurveyQuestionsPayload>(API_PATH, { params });
  return response.data;
}

// Survey Questions
// Get
export async function surveyQuestionsGet(): Promise<ISurveyQuestion[]> {
  const response = await API.get<ISurveyQuestion[]>(`${API_PATH}`);
  return { ...response.data };
}
export async function getSurveyQuestions(): Promise<ISurveyQuestion[]> {
  const response = await API.get<ISurveyQuestion[]>(`${API_PATH}`);
  return { ...response.data };
}
export async function surveyQuestionGet(id: number): Promise<ISurveyQuestion> {
  const response = await API.get<ISurveyQuestion>(`${API_PATH}/${id}`);
  return { ...response.data };
}
export async function getSurveyQuestionContent(id: number): Promise<string> {
  const response = await API.get<string>(`${API_PATH}/${id}/content`);
  return response.data;
}

// Post
export async function surveyQuestionCreate(
  surveyQuestion: ISurveyQuestion,
): Promise<ISurveyQuestion> {
  const response = await API.post<ISurveyQuestion>(API_PATH, surveyQuestion);
  return response.data;
}

// Put
export async function updateSurveyQuestion(
  surveyQuestion: ISurveyQuestion,
): Promise<ISurveyQuestion> {
  const response = await API.put<ISurveyQuestion>(
    `${API_PATH}/${surveyQuestion.SurveyCallId}/Response/${surveyQuestion.ContentId}`, surveyQuestion,
  );
  return response.data;
}

// Delete
export async function deleteSurveyQuestion(id: number): Promise<void> {
  await API.delete<void>(`${API_PATH}/${id}`);
}
