import React, { FC, useState, useMemo } from 'react';
import {
  Modal, Row, Col, Form,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

import {
  ISurveyQuestionListItem, SurveyQuestionListItemContentTypeEnum,
} from 'types/ISurveyQuestionList';
import {
  updateSurveyQuestionListItem, selectItemUpdateLoading, getSurveyQuestionListItemIndex,
} from 'reducers/SurveyQuestionList';
import { Select, Textarea } from 'components/FormControls';
import { getAllLibraryContent } from 'reducers/LibraryContent';
import { ILibraryContent, LibraryContentType } from 'types/ILibraryContent';
import SubmitButton from 'components/FormControls/SubmitButton';
import { SurveyContentResponseTypeEnum } from 'types/IDischargedPatient';
import { IOptions } from 'components/Inputs/Select';

export interface IEditItemModalProps {
  onHide(): void
  show: boolean
  item: ISurveyQuestionListItem
}

type IFormData = Omit<ISurveyQuestionListItem, 'ID' | 'Ordinal'>

export const EditItemModal: FC<IEditItemModalProps> = ({ show, onHide, item: itemToEdit }) => {
  const [showTextarea, setShowTextarea] = useState<boolean>(!itemToEdit?.ContentId);
  const dispatch = useDispatch();
  const { id: listId } = useParams<{ id: string }>();

  const editLoading = useSelector(selectItemUpdateLoading);

  const libraryData: ILibraryContent[] = useSelector(getAllLibraryContent);

  const surveyResponseTypeOptions: IOptions[] = [
    { label: 'Binary', value: SurveyContentResponseTypeEnum.Binary },
    { label: 'Rating', value: SurveyContentResponseTypeEnum.Rating },
  ]

  const handleEdit = (formData) => {
    const data = {
      ...itemToEdit,
      ...formData,
      ContentId: formData.ContentId ? +formData.ContentId : null,
      VoiceContent: formData.VoiceContent ?? null,
      SMSContent: formData.SMSContent ?? null,
    }

    dispatch(updateSurveyQuestionListItem(+listId, data, () => {
      dispatch(getSurveyQuestionListItemIndex(+listId));
      toast.success('Question successfully updated');
      onHide();
    }));
  }

  const {
    control, errors, reset, handleSubmit,
  } = useForm<IFormData>({
    mode: 'onBlur',
    defaultValues: {
      VoiceContent: itemToEdit?.VoiceContent,
      SMSContent: itemToEdit?.SMSContent,
      ContentId: itemToEdit?.ContentId,
      ContentType: itemToEdit?.ContentType,
      ResponseType: itemToEdit?.ResponseType,
      SurveyContentResponsePattern: itemToEdit?.SurveyContentResponsePattern,
    },
  });

  useUpdateEffect(() => {
    if (itemToEdit) {
      setShowTextarea(!itemToEdit.ContentId);
      reset({
        VoiceContent: itemToEdit?.VoiceContent,
        SMSContent: itemToEdit?.SMSContent,
        ContentId: itemToEdit?.ContentId,
        ContentType: itemToEdit?.ContentType,
        ResponseType: itemToEdit?.ResponseType,
        SurveyContentResponsePattern: itemToEdit?.SurveyContentResponsePattern,
      })
    }
  }, [itemToEdit]);

  const libraryContentOptions = useMemo(() => {
    if (!libraryData.length) {
      return [];
    }

    return libraryData
      .filter((item) => item.Type === LibraryContentType.VOICE)
      .map((item) => {
        const content = item.VoiceContent?.Content ?? '';

        return {
          label: `${item.Name} - ${content}`,
          value: item.ID,
        }
      });
  }, [libraryData]);
  return (
    <Modal
      show={show}
      keyboard={false}
      onHide={() => { }}
    >
      <Modal.Header>
        <Modal.Title className="w-100">
          Edit
          {' '}
          {itemToEdit?.ContentType ?? 'Question'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <hr />
        <Form onSubmit={handleSubmit(handleEdit)}>
          {itemToEdit?.ContentType === SurveyQuestionListItemContentTypeEnum.Question && (
            <>
              <Row>
                <Col>
                  <label className="mb-0">
                    Survey Response Type
                  </label>
                  <Select
                    id="ResponseType"
                    name="ResponseType"
                    control={control}
                    options={surveyResponseTypeOptions}
                    className="mt-1"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="mb-0">
                    Response Pattern
                  </label>
                  <Textarea
                    control={control}
                    id="SurveyContentResponsePattern"
                    name="SurveyContentResponsePattern"
                    rows={3}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col>
              <label className="mb-0">
                SMS Content
              </label>
              <Textarea
                control={control}
                id="SMSContent"
                name="SMSContent"
                defaultValue=""
                rules={{ required: 'SMS Content is required' }}
                errors={errors.SMSContent?.message}
                placeholder="Type SMS content"
                rows={3}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <label className="mb-0">
                Content
              </label>
              <div>
                <button
                  type="button"
                  className={clsx('tab-control', showTextarea && 'active')}
                  onClick={() => {
                    setShowTextarea(true);
                  }}
                >
                  Custom
                </button>
                &nbsp;
                {' | '}
                &nbsp;
                <button
                  type="button"
                  className={clsx('tab-control', !showTextarea && 'active')}
                  onClick={() => {
                    setShowTextarea(false)
                  }}
                >
                  Library Content
                </button>
              </div>
              {showTextarea ? (
                <Textarea
                  id="VoiceContent"
                  control={control}
                  name="VoiceContent"
                  defaultValue=""
                  rules={{ required: 'Voice Content is required' }}
                  errors={errors.VoiceContent?.message}
                  placeholder="Type Voice content"
                  rows={3}
                />
              ) : (
                <Select
                  id="ContentId"
                  name="ContentId"
                  placeholder="Select library content"
                  control={control}
                  options={libraryContentOptions}
                  errors={errors.ContentId?.message}
                  rules={{ required: 'Voice Content is required' }}
                  className="mt-1"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <SubmitButton
                label="Save"
                savingLabel="Saving..."
                className="btn btn-primary right"
                saveComplete={!editLoading}
              />
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={onHide}
              >
                Cancel
              </button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
