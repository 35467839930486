import React, {
  ReactElement, MouseEvent as ReactMouseEvent, useCallback,
} from 'react';

import { Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayButton from './OverlayButton';

const TwoButtonPopover = ({
  onPopoverComplete,
  title,
  content,
  onClick,
  id,
}: {
  content: string | ReactElement
  onPopoverComplete: () => void
  title: string
  onClick?: (e:ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void
  id: string
}): ReactElement => {
  const handleCompleteClick = useCallback((): void => {
    onPopoverComplete();
  }, [onPopoverComplete]);
  const overlayBody = useCallback((open, close) => (
    <>
      <Popover.Title as="h3">
        {title}
      </Popover.Title>
      <Popover.Content>
        <div className="text-center mb-1">
          {content}
        </div>
        <div className="d-inline-block w-100">
          <input
            type="button"
            className="float-left btn btn-secondary"
            onClick={(e) => {
              onClick?.(e);
              close();
            }}
            value="Cancel"
          />
          <input
            type="button"
            className="float-right btn btn-outline-danger"
            onClick={(e) => {
              onClick?.(e);
              handleCompleteClick();
              close();
            }}
            value="Delete"
          />
        </div>
      </Popover.Content>
    </>
  ), [content, handleCompleteClick, onClick, title])
  const interactionElement = useCallback((open) => (
    <button
      id={`${id}`}
      className="float-right btn btn-outline-danger"
      type="button"
      onClick={(e) => {
        open(e.currentTarget);
        onClick?.(e);
      }}
    >
      <FontAwesomeIcon icon="trash-alt" />
    </button>
  ), [id, onClick]);
  return (
    <OverlayButton
      id={id}
      createOverlayBody={overlayBody}
      createInteractionElement={interactionElement}
    />
  );
}

export default TwoButtonPopover;
