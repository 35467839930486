import React from 'react';
import { useHistory } from 'react-router-dom';

export const ClearFilters = () => {
  const history = useHistory();

  return (
    <>
      <span className="filter-result">Conversations are filtered.</span>
      {' '}
      &nbsp;
      <a
        className="ml-1"
        href="/smsinbox"
        onClick={(e) => {
          e.preventDefault();
          history.replace('/smsinbox');
        }}
      >
        Remove Filters
      </a>
    </>
  )
}
