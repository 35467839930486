import React from 'react';
import { useQueryParam, BooleanParam } from 'use-query-params';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { selectAccountTz } from 'reducers/UserInfo';
import DateRangeInput from 'components/Inputs/DateRangeInput';
import Checkbox from 'components/Inputs/Checkbox';

const getValidStartDateStr = (startDateParam) => {
  if (!startDateParam) {
    return '';
  }

  const dStart = moment(startDateParam, 'MM/DD/YYYY', true);

  if (
    !dStart.isValid()
    || dStart.isBefore(moment().subtract(10, 'years'))
    || moment().isBefore(dStart)
  ) {
    return '';
  }

  return dStart.format('MM/DD/YYYY');
}

const getValidEndDateStr = (startDateParam: string, endDateParam: string) => {
  if (!endDateParam) {
    return '';
  }

  const dStart = moment(startDateParam, 'MM/DD/YYYY', true);
  const dEnd = moment(endDateParam, 'MM/DD/YYYY', true);

  if (!dEnd.isValid()
    || dEnd.isBefore(moment().subtract(10, 'years'))
    || moment().isBefore(dEnd)
    || (dStart.isValid() && dEnd.isBefore(dStart))
  ) {
    return '';
  }

  return dEnd.format('MM/DD/YYYY');
}

const getDefaultRangeStr = (startDateParam: string, endDateParam: string) => {
  const validStartDateStr = getValidStartDateStr(startDateParam);
  const validEndDateStr = getValidEndDateStr(startDateParam, endDateParam);

  if (!validStartDateStr && !validEndDateStr) {
    return '';
  }

  return `${validStartDateStr} - ${validEndDateStr}`;
}

export const BroadcastHistoryFilters = () => {
  const accountTz = useSelector(selectAccountTz);
  const [startDateParam = '', setStartDateParam] = useQueryParam<string, string>('startDate');
  const [endDateParam = '', setEndDateParam] = useQueryParam<string, string>('endDate');
  const [
    includeAutomaticParam = true, setIncludeAutomaticParam,
  ] = useQueryParam<boolean, boolean>('includeAutomaticEvents', BooleanParam);

  const [rangeStr, setRangeStr] = React.useState(getDefaultRangeStr(startDateParam, endDateParam));

  const hasFilters = startDateParam || endDateParam || !includeAutomaticParam;

  return (
    <div className="broadcast-history-filters">
      <div className="d-flex align-items-end">
        <DateRangeInput
          autoComplete="off"
          value={rangeStr}
          onChange={(dateStr) => {
            setRangeStr(dateStr);

            const [newStartDate, newEndDate] = dateStr.split(' - ');
            const validStartDateStr = getValidStartDateStr(newStartDate);
            const validEndDateStr = getValidEndDateStr(newStartDate, newEndDate);

            if (startDateParam !== validStartDateStr) {
              setStartDateParam(validStartDateStr);
            }

            if (endDateParam !== validEndDateStr) {
              setEndDateParam(validEndDateStr);
            }
          }}
          isOutsideRange={(day) => day.isAfter(moment())}
          label="Date Range"
          format="MM/DD/YYYY"
          mask="99/99/9999 - 99/99/9999"
          navigationUnit="day"
          id="RangeFilter"
          name="calendarRange"
          className="mb-0"
          accountTimezone={accountTz}
        />

        <Checkbox
          name="include-automatic-events"
          id="include-automatic-events-check"
          className="ml-4"
          style={{ marginBottom: 10 }}
          label="Include Automatic Events"
          checked={includeAutomaticParam}
          onChange={(e) => setIncludeAutomaticParam(e.target.checked)}
        />
      </div>

      {hasFilters && (
        <div className="d-flex" style={{ marginTop: 12 }}>
          <span>Broadcasts are filtered.</span>
          {' '}
         &nbsp;
          <a
            className="ml-1"
            href="/broadcast-history"
            onClick={(e) => {
              e.preventDefault();
              setRangeStr('');
              setStartDateParam('');
              setEndDateParam('');
              setIncludeAutomaticParam(undefined);
            }}
          >
            Remove Filters
          </a>
        </div>
      )}
    </div>
  )
}
