import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { selectCommunityName } from 'reducers/UserInfo';

export const CommunityNameWarningValidator = () => {
  const {
    watch,
  } = useFormContext();

  const communityName = useSelector(selectCommunityName);
  const content = watch('Content');

  const shouldShowCommunityNameWarning = (communityName === null || communityName === '')
  && content?.includes('$$CommunityName$$');

  if (shouldShowCommunityNameWarning) {
    return (
      <Alert variant="warning" className="mb-3">
        <FontAwesomeIcon icon="info-circle" className="mr-2" />
        This broadcast is using the CommunityName merge field. This value needs to be set in Account Settings.
      </Alert>
    )
  }

  return null;
}
