import React, { useState, ReactElement, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { ReactComponent as Plus } from 'styles/images/plus.svg';
import { DeleteActionModal } from 'components/DeleteActionModal';
import { deleteEvent } from 'reducers/Events';
import { EventType } from 'types/IEvent';
import { EventsTable } from '../EventsTable';

const SurveyEventsIndexPage = ():ReactElement => {
  const history = useHistory();
  const [eventToDeleteID, setEventToDeleteID] = useState<number|undefined>();
  const dispatch = useDispatch();
  const refetchRef = useRef<() => void>();

  const rowActions = (id: number) => (
    <>
      <Link
        to={`/events/survey/${id}/edit`}
      >
        Edit
      </Link>
      {' '}
      <a
        href="/events/survey"
        onClick={(e) => {
          e.preventDefault();
          setEventToDeleteID(id);
        }}
      >
        Delete
      </a>
    </>
  )

  return (
    <div>
      <Helmet>
        <title>Survey Events</title>
      </Helmet>
      <div className="header-bar">
        <div className="actions">
          <button
            role="link"
            type="button"
            onClick={() => history.push('/events/survey/new')}
            className="btn btn-lg btn-warning "
          >
            <Plus
              width="24"
              height="24"
              fill="currentColor"
              style={{ marginRight: '10px' }}
            />
            Add New Survey
          </button>
        </div>
      </div>
      <EventsTable
        ref={refetchRef}
        showActions
        eventType={EventType.SURVEY}
        rowActionItem={rowActions}
        filterTitle="Event"
        onRowClick={(id) => history.push(`/events/survey/${id}/edit`)}
      />
      <DeleteActionModal
        isOpen={eventToDeleteID !== undefined}
        title="Are you sure?"
        onCancel={() => setEventToDeleteID(undefined)}
        onSuccess={() => {
          dispatch(deleteEvent(eventToDeleteID,
            () => {
              refetchRef.current();
              toast.success('Template successfully deleted');
            },
            (err) => {
              toast.error(err?.data?.Message);
            }));
          setEventToDeleteID(undefined);
        }}
      />
    </div>
  );
};
export default SurveyEventsIndexPage;
