import React from 'react';
import { Redirect } from 'react-router-dom';

import { BroadcastHistoryFilters } from 'components/BroadcastHistoryFilters';
import { BroadcastHistoryTable } from 'components/BroadcastHistoryTable';
import { useHasRoles } from 'hooks/useHasRoles';
import { useHasFeatures } from 'hooks/useHasFeatures';
import { useHasPermissions } from 'hooks/useHasPermissions';

const BroadcastHistoryPage = () => {
  const canReadCalendar = useHasPermissions('Calendar:Read');
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const { hasFeature, isLoading } = useHasFeatures('search-event-history');

  if (isLoading) {
    return null;
  }

  const canViewPage = (canReadCalendar || isAdmin || isSuper) && hasFeature;

  if (!canViewPage) {
    return <Redirect to="/" />
  }

  return (
    <div className="broadcast-history">
      <h1 className="mb-4">Broadcast History</h1>
      <p>This history of sent messages includes all templates and broadcasts. Make a selection to download. </p>

      <BroadcastHistoryFilters />
      <BroadcastHistoryTable />
    </div>
  )
}

export default BroadcastHistoryPage;
