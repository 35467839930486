import { ITemplatesPayload, IAccountTemplatesPayload } from 'types/ITemplatesPayload';
import { ITemplatesIndexParams } from 'types/ITemplatesIndexParams';
import { LanguageNames } from 'lib/CommonSelect';
import { ITemplate, VoiceMessageTypeEnum } from 'types/IEvent';
import API from './API'

export const API_PATH = '/api/v2/template'

export async function TemplatesIndex({
  searchField = '',
  searchValue = '',
  sortField = '',
  sortDirection = '',
  page,
  perpage,
  isDraft,
  isMarketing,
  skipTemplateAttachments,
}: ITemplatesIndexParams, type: string): Promise<ITemplatesPayload> {
  const params = {
    filterType: (searchField.length > 0 ? searchField : undefined),
    filterValue: (searchValue.trim().length > 0 ? searchValue : undefined),
    sortField: (sortField.length > 0 ? sortField : undefined),
    sortDirection: (sortDirection.length > 0 ? sortDirection : undefined),
    page: (page || undefined),
    perpage: (perpage || undefined),
    isDraft: isDraft || undefined,
    isMarketing: isMarketing || undefined,
    skipTemplateAttachments: skipTemplateAttachments || undefined,
  };
  const response = await API.get<ITemplatesPayload>(`${API_PATH}/${type}`, { params });

  return response.data;
}

export async function getAccountTemplates(
  accountId: number,
  {
    searchField = '',
    searchValue = '',
    page,
    perpage,
    skipTemplateAttachments,
    isMarketing,
  }: ITemplatesIndexParams): Promise<IAccountTemplatesPayload> {
  const params = {
    filterType: (searchField.length > 0 ? searchField : undefined),
    filterValue: (searchValue.length > 0 ? searchValue : undefined),
    page: (page || undefined),
    perpage: (perpage || undefined),
    skipTemplateAttachments: skipTemplateAttachments || undefined,
    isMarketing: isMarketing || undefined,
  };
  const response = await API.get<IAccountTemplatesPayload>(`/api/v2/account/${accountId}/templates`, { params });

  return response.data;
}

export async function getBlueprintTemplates(
  {
    searchField = '',
    searchValue = '',
    page,
    perpage,
    skipTemplateAttachments,
    isMarketing,
  }: ITemplatesIndexParams): Promise<IAccountTemplatesPayload> {
  const params = {
    filterType: (searchField.length > 0 ? searchField : undefined),
    filterValue: (searchValue.length > 0 ? searchValue : undefined),
    page: (page || undefined),
    perpage: (perpage || undefined),
    skipTemplateAttachments: skipTemplateAttachments || undefined,
    isMarketing: isMarketing || undefined,
  };
  const response = await API.get<IAccountTemplatesPayload>('/api/v2/account/blueprints/templates', { params });

  return response.data;
}

export async function templatesGet(): Promise<ITemplate[]> {
  const data = []
  for (let i = 0; i < 20; i++) {
    data.push({
      ID: i.toString(),
      Name: `Template ${i}`,
      Description: `This is a test template. ${i}`,
      GroupIds: [],
      ProfileIds: [],
    });
  }
  return data
}
export async function selectedTemplateGet(ID: string): Promise<ITemplate> {
  // const response = await API.get<ITemplate>(`${API_PATH}/${ID}`)
  // return response.data
  const data = {
    GroupIds: [],
    ProfileIds: [],
    ID: 0,
    Location: '',
    Name: `Template#${ID}`,
    Description: `'This is a test template #${ID}`,
    Type: 'Template',
    VoiceMessageType: VoiceMessageTypeEnum.Both,
    Category: 'Activity',
    voiceSelected: true,
    emailSelected: true,
    smsSelected: true,
    CreatedByUserId: 0,
    CreatedDateTimeUTC: null,
    LastModifiedByUserId: 0,
    LastModifiedDateTimeUTC: null,
    LastUsedDateTimeUTC: null,
    SourceLanguage: LanguageNames.English,
    IsDraft: true,
  }
  return data
}
export async function templatePut(content: ITemplate, type: string): Promise<ITemplate> {
  const response = await API.put<ITemplate>(`${API_PATH}/${content.ID}/${type}`, content)
  return response.data
}

export async function templatesGet2(): Promise<ITemplate[]> {
  const response = await API.get<{ Data: ITemplate[] }>(API_PATH);
  return response.data.Data;
}

export async function templateGet(TemplateID: number, type: string): Promise<ITemplate> {
  const response = await API.get<ITemplate>(`${API_PATH}/${TemplateID}`, {
    params: {
      type,
    },
  });
  return response.data
}

export async function getAccountTemplateById(templateID: number, accountId?: number): Promise<ITemplate> {
  const response = await API.get<ITemplate>(`/api/v2/account/template/${templateID}`, {
    params: {
      accountId: accountId || undefined,
    },
  });
  return response.data
}

export async function templateCreate(payload: ITemplate[]): Promise<ITemplate> {
  const response = await API.post<ITemplate[]>(API_PATH, payload);
  return response.data.find((t) => t);
}

export async function templateDelete(TemplateID: number): Promise<void> {
  await API.delete(`${API_PATH}/${TemplateID}`);
}
export async function batchTemplateDelete(TemplateIDs: Array<number>, isMarketing: boolean): Promise<void> {
  if (isMarketing) {
    await API.post(`${API_PATH}/marketing/batchdelete`, TemplateIDs);
  } else {
    await API.post(`${API_PATH}/batchdelete`, TemplateIDs);
  }
}
