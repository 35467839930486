import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import RRuleGenerator from 'react-rrule-generator';
import RRule from 'rrule';

export const CustomRRuleGenerator: FC<{
  control: Control
  disabled?: boolean
}> = ({ control, disabled }) => (
  <div className="rrule">
    <Controller
      control={control}
      defaultValue="FREQ=DAILY"
      name="RRule"
      className="abc"
      render={({ value, onChange }) => (
        <RRuleGenerator
          value={value}
          onChange={onChange}
          config={{
            repeat: ['Yearly', 'Monthly', 'Weekly', 'Daily'],
          }}
          disabled={disabled}
        />
      )}
      rules={{
        required: true,
        validate: (val: any) => {
          const eventRrule = RRule.fromString(val);
          if (eventRrule.origOptions.freq === 2 && !eventRrule.origOptions.byweekday) {
            return 'Please select at least one day when repeating weekly'
          }
          if (eventRrule.origOptions.count < 1) {
            return 'Must have at least one occurrence'
          }
          return true;
        },
      }}
    />
  </div>
);
