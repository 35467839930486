import React, { ReactElement } from 'react';

import { Controller } from 'react-hook-form';

import ControlledDateRangeInput, { DateRangeInputProps } from '../Inputs/DateRangeInput'
import { IFormControlProps } from './IFormControlProps';

interface IProps extends IFormControlProps, Omit<DateRangeInputProps, 'defaultValue' | 'id' | 'name' > {}

function DateRangeInput({
  name, defaultValue = '', control, rules, ...props
}: IProps): ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={(renderProps) => (
        <ControlledDateRangeInput {...renderProps} {...props} />
      )}
    />
  );
}

export default DateRangeInput;
