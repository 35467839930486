export default class Storage {
  static setItemByAccountId<T = any>(key: string, data: T, accountId: number): void {
    const existingDataJson = localStorage.getItem(`acc-${accountId}`) ?? '{}';
    localStorage.setItem(`acc-${accountId}`, JSON.stringify({
      ...JSON.parse(existingDataJson),
      [key]: data,
    }));
  }

  static getItemByAccountId<T = any>(key: string, accountId: number): T|null {
    const existingDataJson = localStorage.getItem(`acc-${accountId}`);
    if (!existingDataJson) {
      return null;
    }
    const existingData = JSON.parse(existingDataJson);
    return existingData[key] ?? null;
  }
}
