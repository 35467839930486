import React, { useState, useRef } from 'react';

import { ConversationView, ChatView } from 'components/Inbox';
import { IConversation } from 'types/IConversation';
import { MessagesRef, ConversationsRef } from 'components/Inbox/types';
import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { Redirect } from 'react-router-dom';
import { useHasFeatures } from 'hooks/useHasFeatures';
import { Spinner } from 'react-bootstrap';

const SmsInboxPage = () => {
  const [selectedConversation, setSelectedConversation] = useState<IConversation|null>(null);
  const messagesRef = useRef<MessagesRef|null>(null);
  const conversationsRef = useRef<ConversationsRef|null>(null);
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const hasAccessPermission = useHasPermissions('Inbox:Read') || isAdmin || isSuper;
  const { hasFeature, isLoading } = useHasFeatures('sms-inbox');
  if (!hasAccessPermission) {
    return <Redirect to="/" />
  }
  if (isLoading) {
    return (
      <Spinner
        animation="border"
        variant="primary"
        role="status"
        className="mx-auto d-block mt-4 flex-shrink-0"
      />
    );
  } if (!hasFeature) {
    return <div> SMS Inbox is not enabled for this account</div>
  }
  return (
    <div className="sms-inbox">
      <ConversationView
        selectedConversation={selectedConversation}
        setSelectedConversation={setSelectedConversation}
        messagesRef={messagesRef}
        conversationsRef={conversationsRef}
      />
      <div className="sms-inbox__separator" />
      <ChatView
        selectedConversation={selectedConversation}
        messagesRef={messagesRef}
        conversationsRef={conversationsRef}
      />
    </div>
  )
}

export default SmsInboxPage;
