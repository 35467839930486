import React, { useEffect, useState, ReactElement } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { Button, Form, Col } from 'react-bootstrap';
import { Input } from 'components/FormControls';
import { EmailContent } from 'components/EventTemplateForm/EmailContent';
import { VoiceContent } from 'components/EventTemplateForm/VoiceContent';
import { SmsContent } from 'components/EventTemplateForm/SmsContent';
import { Prompt, Link } from 'react-router-dom';
import { ILibraryContent } from 'types/ILibraryContent';
import { LanguageNames, LANGUAGES } from 'lib/CommonSelect';
import { useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import Select from 'react-select';
import { getLibraryIsLoading } from 'reducers/LibraryContent';

interface IProps {
  data?: ILibraryContent
  onSubmit: (data: ILibraryContent) => void
}

function EditLibraryContentForm({
  data,
  onSubmit,
}: IProps): ReactElement {
  const methods = useForm<ILibraryContent>({
    mode: 'onBlur',
    defaultValues: {
      ID: data.ID,
      Name: data.Name,
      Description: data.Description,
      Type: data.Type,
      VoiceContent: data.VoiceContent,
      SMSContent: data.SMSContent,
      EmailContent: data.EmailContent,
      SourceLanguage: data.SourceLanguage,
    },
  });
  const {
    register,
    control,
    reset,
    errors,
    handleSubmit,
    formState,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);
  const interceptSubmit = (formData: ILibraryContent): void => {
    onSubmit(formData);
  };

  const isContentLoading = useSelector(getLibraryIsLoading);
  const watchType = data?.Type;
  const isVoice = watchType?.toLowerCase() === 'voice';
  const isEmail = watchType?.toLowerCase() === 'email';
  const isSms = watchType?.toLowerCase() === 'sms';

  const [isBlocking, setIsBlocking] = useState(false);
  const messageObj = {
    title: 'Library Content Changes',
    messageText: 'You have unsaved changes, are you sure you want to leave?',
  };
  const selectedLanguage = watch('SourceLanguage');
  const selectedLanguageObj = LANGUAGES.find((l) => l.value === selectedLanguage);
  const allowInputLanguage = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.AllowInputLanguageSelection);
  useEffect(() => {
    if (Object.keys(formState.dirtyFields).length > 0 && !isContentLoading) {
      setIsBlocking(true);
    } else {
      setIsBlocking(false);
    }
  }, [formState, isContentLoading]);

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Prompt
        when={isBlocking}
        message={JSON.stringify(messageObj)}
      />
      <FormProvider {...methods}>
        <Form
          onSubmit={handleSubmit(interceptSubmit)}
          className="form ContentForm"
        >
          <Form.Row>
            <Form.Group as={Col}>
              <Input
                id="Name"
                name="Name"
                label="Name"
                type="text"
                control={control}
                errors={errors.Name && 'Name is required and its max length is 50 characters'}
                rules={{ required: true, maxLength: 50 }}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Input
                id="Description"
                name="Description"
                label="Description"
                type="text"
                control={control}
                errors={errors.Description && 'Max length of description is 450 characters'}
                rules={{ required: false, maxLength: 450 }}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <div className="languageSelection">
              <input
                type="hidden"
                name="SourceLanguage"
                ref={register({ required: true })}
              />
              {(selectedLanguage !== LanguageNames.English || allowInputLanguage) && (
                <>
                  <Form.Group as={Col} className="inputLanguageLabel">
                    <label
                      htmlFor="select-language"
                    >
                      Input Language:
                    </label>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Select
                      id="select-language"
                      name="select-language"
                      isDisabled={!allowInputLanguage}
                      label="Language"
                      placeholder="Select language"
                      defaultValue={{ label: selectedLanguageObj?.label, value: selectedLanguageObj?.value }}
                      options={LANGUAGES}
                      control={control}
                      onChange={(e) => setValue('SourceLanguage', e.value, { shouldDirty: true })}
                      rules={{ required: true }}
                    />
                  </Form.Group>
                </>
              )}
            </div>
          </Form.Row>
          <input ref={register} type="hidden" name="Type" />
          <Form.Row>
            <Form.Group as={Col}>
              {isVoice && data?.VoiceContent && <VoiceContent />}
              {isEmail && data?.EmailContent && <EmailContent requireValidation />}
              {isSms && data?.SMSContent && <SmsContent />}
            </Form.Group>
          </Form.Row>
          <div>
            <input type="hidden" name="ID" ref={register} />
            <input type="hidden" name="Type" ref={register} />
          </div>
          <Form.Row>
            <Form.Group as={Col}>
              <div>
                <Button className="d-block float-right ml-2" type="submit" disabled={isContentLoading}>
                  {isContentLoading ? 'Saving' : 'Save'}
                </Button>
                <Link
                  className="d-block btn btn-secondary float-right"
                  to={isContentLoading ? '#' : '/librarycontent'}
                >
                  Cancel
                </Link>
              </div>
            </Form.Group>
          </Form.Row>
        </Form>
      </FormProvider>
    </>
  );
}
export default EditLibraryContentForm;
