import { ThrottleSettings } from 'lodash';
import throttle from 'lodash.throttle';
import { useMemo, DependencyList } from 'react';

export const useThrottle = <T extends (...args: any) => any>(
  callback: T,
  dependencies: DependencyList,
  wait?: number,
  options?: ThrottleSettings,
// eslint-disable-next-line react-hooks/exhaustive-deps
) => useMemo(() => throttle(callback, wait, options), dependencies);
