import React, {
  Dispatch, ReactElement, SetStateAction, useEffect, useMemo,
} from 'react';

import {
  Container, Row, Col, ListGroup,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'types/rootState';
import { getGroupsMembers } from 'reducers/Groups';
import { useRefSelector } from 'hooks/useRefSelector';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';

interface IProps {
  setEditMode: Dispatch<SetStateAction<boolean>>
}

const GroupView = ({ setEditMode }: IProps): ReactElement => {
  const dispatch = useDispatch();

  const groupsLookup = useSelector((state: RootState) => state.Groups.byIds);
  const selectedGroupId = useSelector((state: RootState) => state.Groups.selectedGroupId);
  useEffect(() => {
    if (selectedGroupId != null && selectedGroupId !== -1 && selectedGroupId !== 0) {
      dispatch(getGroupsMembers(selectedGroupId));
    }
  }, [dispatch, selectedGroupId]);
  const groupMemberIds = useSelector(
    (state: RootState) => Array.from(
      new Set(state.Groups.membersByIds[selectedGroupId]?.CustomerProfileIds),
    ),
  );
  const selectedGroup = groupsLookup[selectedGroupId];
  const equalityCheck = (left: any[], right: any[]):
  boolean => left?.length === right?.length && JSON.stringify(left) === JSON.stringify(right)
  const members = useRefSelector(
    (state: RootState) => groupMemberIds?.map((i) => state.Profiles.byIds[i]),
    equalityCheck,
  );

  const translateUserType = useUserTypeTranslation();
  useMemo(() => members?.sort(
    (a, b) => a.LastName.localeCompare(b.LastName)),
  [members]);

  const profilesLoading = useSelector((state: RootState) => state.Profiles.loading);
  const groupsLoading = useSelector((state: RootState) => state.Groups.loading);
  const loading = profilesLoading || groupsLoading;
  return (
    selectedGroupId != null && selectedGroupId?.toString() !== '0'
                && (
                  <form className="form ContentForm">
                    <Container className="groupView">
                      <Row>
                        <Col xs={6} className="text-center">
                          <h6>{translateUserType(selectedGroup?.Name)}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <Row />
                          <Container
                            className="listGroupMainMedium"
                          >
                            <div className="text-center">
                              {loading && <span className="loading-text">Loading . . .</span>}

                              {!loading && (members === null || members.length === 0) && (
                                <span>There are no members for this group.</span>
                              )}
                            </div>

                            {members?.map((member) => {
                              if (member != null) {
                                return (
                                  <ListGroup.Item key={member.CustomerProfileID} className="groupListGroupItem">
                                    <Row>
                                      <Col>
                                        {member.FirstName}
                                        {' '}
                                        {member.LastName}
                                      </Col>
                                    </Row>
                                  </ListGroup.Item>
                                )
                              }
                              return null
                            })}
                          </Container>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                )
  )
}

export default GroupView;
