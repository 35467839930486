import urljoin from 'url-join';
import { IGroupsIndexParams } from 'types/IGroupsIndexParams';
import { IGroupsPayload } from 'types/IGroupsPayload';
import { IGroupMembers } from 'types/IGroupMembers';
import API from './API';
import { ICaremergeCategory, IGroup } from '../types/IGroup';

export const API_PATH_V1 = '/api/v1/groups'
export const API_PATH = '/api/v2/groups';
export const API_MEMBERS_PATH = '/members';
export const CAREMERGE_CATEGORIES_API_PATH = 'api/v2/caremerge/categories';

export async function groupsIndex(id?: number): Promise<IGroup[]> {
  let path = API_PATH;
  if (id) {
    path = urljoin(path, id.toString());
  }
  const response = await API.get<IGroup[]>(path);
  return response.data;
}

export async function groupsIndexWithParams({
  page,
  perpage,
}: IGroupsIndexParams): Promise<IGroupsPayload> {
  const params = {
    page: (page || undefined),
    perpage: (perpage || 10),
  };
  const response = await API.get<IGroupsPayload>(API_PATH, { params });
  return response.data;
}

export async function saveGroup(group: IGroup): Promise<IGroup> {
  if (group.Id > 0) {
    return (await API.put<IGroup>(urljoin(API_PATH, group.Id.toString()), group)).data;
  }
  return (await API.post<IGroup>(API_PATH, group)).data;
}

export async function getGroupMembers(groupId: number): Promise<IGroupMembers> {
  const response = await API.get<IGroupMembers>(urljoin(API_PATH, groupId.toString(), API_MEMBERS_PATH));
  return response.data
}

export async function getGroup(groupId: number): Promise<string[]> {
  const response = await API.get<string[]>(urljoin(API_PATH, groupId.toString()));
  return response.data
}

export async function addGroupMember(groupId: number, members: IGroupMembers): Promise<void> {
  await API.post<string[]>(urljoin(API_PATH, groupId.toString(), API_MEMBERS_PATH), members);
}

export async function setGroupMembers(groupId: number, members: IGroupMembers): Promise<void> {
  await API.put<string[]>(urljoin(API_PATH, groupId.toString(), API_MEMBERS_PATH), members);
}

export async function removeGroupMember(groupId: number, member: string): Promise<void> {
  await API.delete<string[]>(urljoin(API_PATH, groupId.toString(), API_MEMBERS_PATH, member));
}

export async function deleteGroup(groupId: number): Promise<void> {
  await API.delete<string[]>(urljoin(API_PATH, groupId.toString()));
}
export async function getGroupCategories(): Promise<ICaremergeCategory[]> {
  const response = await API.get<ICaremergeCategory[]>(`${CAREMERGE_CATEGORIES_API_PATH}?includeGroupIds=true`);
  return response.data;
}

export const getAllCaremergeCategories = async (): Promise<ICaremergeCategory[]> => {
  const response = await API.get<ICaremergeCategory[]>(CAREMERGE_CATEGORIES_API_PATH);
  return response.data;
}

export const assignCaremergeCategoryToGroup = async (newCaremergeCategoryId: number|null, groupId: number) => {
  await API.post<void>('/api/v2/group/category', {
    GroupId: groupId,
    CaremergeCategoryId: newCaremergeCategoryId,
  });
}
