import { ILibraryContent, ILibraryContentPayload } from 'types/ILibraryContent';
import API from './API'
import { ILibraryIndexParams } from '../types/ILibraryIndexParams';

export const API_PATH = '/api/v2/content'

export async function libraryContentGet({
  searchField = '',
  searchValue = '',
  sortField = '',
  sortDirection = '',
  page,
  perpage,
}: ILibraryIndexParams): Promise<ILibraryContentPayload> {
  const params = {
    searchField: (searchField.length > 0 ? searchField : undefined),
    searchValue: (searchValue.trim().length > 0 ? searchValue : undefined),
    sortField: (sortField.length > 0 ? sortField : undefined),
    sortDirection: (sortDirection.length > 0 ? sortDirection : undefined),
    page: (page || undefined),
    perpage: (perpage || undefined),
  };
  const response = await API.get<ILibraryContentPayload>(`${API_PATH}`, { params });
  return response.data;
}
export async function selectedLibraryContentGet(ID: string): Promise<ILibraryContent> {
  const response = await API.get<ILibraryContent>(`${API_PATH}/${ID}`)
  return response.data
}
export async function libraryContentPut(content: ILibraryContent): Promise<ILibraryContent> {
  const placeHolder = '(___) ___-____';
  const content2 = content;
  if (content.VoiceContent?.TransferToPhoneNumber === ''
   || content.VoiceContent?.TransferToPhoneNumber === placeHolder) {
    content2.VoiceContent.TransferToPhoneNumber = null;
  }
  const response = await API.put<ILibraryContent>(`${API_PATH}/${content.ID}`, content2)
  return response.data
}
export async function libraryContentPost(content: ILibraryContent): Promise<ILibraryContent> {
  const placeHolder = '(___) ___-____';
  const content2 = content;
  if (content.VoiceContent?.TransferToPhoneNumber === ''
  || content.VoiceContent?.TransferToPhoneNumber === placeHolder) {
    content2.VoiceContent.TransferToPhoneNumber = null;
  }
  const response = await API.post<ILibraryContent>(`${API_PATH}`, [content2])
  return response.data
}
export async function libraryContentDelete(ID: string): Promise<boolean> {
  await API.delete(`${API_PATH}/${ID}`);
  return true;
}

export async function validateLibraryContent(content: ILibraryContent): Promise<string> {
  const response = await API.post<string>(`${API_PATH}/validate`, content);
  return response.data;
}
