import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'app/appThunk';
import log from 'lib/logging';
import { RootState } from 'types/rootState';
import { hasError } from './Error';

const INITIAL_STATE:Record<string, any> = { data: null };

const UserDetailsSlice = createSlice({
  name: 'UserDetail',
  initialState: INITIAL_STATE,
  reducers: {
    getUserDetailSuccess(state, action:PayloadAction<Record<string, any>>) {
      // eslint-disable-next-line no-param-reassign
      state.data = action.payload;
    },
  },
});

export default UserDetailsSlice.reducer;

export const fetchUserDetail = (): AppThunk => async (dispatch, getState, Api) => {
  try {
    const userInfoResponse = await Api.UserInfo.getCurrentUserInfo();
    dispatch(getUserDetailSuccess(userInfoResponse));
  } catch (err) {
    log.error(err);
    dispatch(hasError(err));
  }
};

export const {
  getUserDetailSuccess,
} = UserDetailsSlice.actions;

export const selectDefaultEmailFields = (state: RootState) => state.UserDetail.data;
