import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import { mutate } from 'swr';
import { IDischargedPatient, IDischargedPatientConfigGet, IDischargedPatientConfig } from 'types/IDischargedPatient';
import { IPatientFormData } from 'types/IPatientFormData';
import { IDischargedPatientsPayload } from 'types/IDischargedPatientsPayload';
import { ISurveyCall } from 'types/ISurveyCall';
import { IFilterableIndexParams } from 'types/ITemplatesIndexParams';
import { RootState } from 'types/rootState';
import uuid from 'uuid';
import _mergeWith from 'lodash/mergeWith';
import { AppThunk } from '../app/appThunk';
import { hasError } from './Error';
import { IDischargedPatientsState } from './IDischargedPatientsState';
import { IProfile } from './IProfile';

export const INITIAL_STATE: IDischargedPatientsState = {
  loading: false,
  dischargedPatients: [],
  selectedDischargedPatient: undefined,
  surveyCalls: [],
  selectedSurveyCalls: [],
  byIds: {},
  surveyCallsByIds: {},
  pagedIds: {},
  pagedSurveyCallIds: {},
  currentPagePatients: [],
  pagination: {
    Page: 1,
    PerPage: 20,
    TotalItems: 0,
    TotalPages: 1,
  },
  loadingIndicators: {},
  tags: [],
  timeStamp: 0,
  config: null,
  configLoading: false,
};

const dischargedPatientSchema = new schema.Entity('DischargedPatients', {}, { idAttribute: 'DischargePatientId' });

const DischargedPatientSlice = createSlice({
  name: 'DischargedPatients',
  initialState: INITIAL_STATE,
  reducers: {
    updateSpecificPatientInByIdsDictionary(draftReducerState, { payload }: PayloadAction<IDischargedPatient>) {
      if (payload.DischargePatientId.toString() in draftReducerState.byIds) {
        draftReducerState.byIds[payload.DischargePatientId.toString()] = _mergeWith(
          {},
          draftReducerState.byIds[payload.DischargePatientId.toString()],
          payload,
          (a, b) => (b === null ? a : undefined),
        );
      }
    },
    dischargedPatientStartLoadingIndicator(draftReducerState, payload:PayloadAction<string>) {
      draftReducerState.loadingIndicators[payload.payload] = true;
    },

    dischargedPatientStopLoadingIndicator(draftReducerState, payload:PayloadAction<string>) {
      draftReducerState.loadingIndicators[payload.payload] = false;
    },
    setCurrentPageDischargedPatients(
      draftReducerState, action: PayloadAction<IDischargedPatient[]>,
    ) {
      draftReducerState.currentPagePatients = action.payload;
    },
    dischargedPatientRequestStarted(draftReducerState) {
      draftReducerState.loading = true;
    },
    dischargedPatientRequestFailed(draftReducerState) {
      draftReducerState.loading = false;
    },
    dischargedPatientRequestSuccess(draftReducerState, action: PayloadAction<IDischargedPatient>) {
      draftReducerState.loading = false;
      draftReducerState.byIds[action.payload.DischargePatientId] = action.payload;
    },
    dischargedPatientCreatedSuccess(draftReducerState, action: PayloadAction<IDischargedPatient>) {
      draftReducerState.selectedDischargedPatient = action.payload;
      draftReducerState.byIds[action.payload.DischargePatientId] = action.payload;
    },
    dischargedPatientPayloadSuccess(
      draftReducerState, action: PayloadAction<IDischargedPatient[]>,
    ) {
      draftReducerState.dischargedPatients = action.payload || [];
      draftReducerState.loading = false;
    },
    dischargedPatientSurveyCallsPayloadSuccess(
      draftReducerState, action: PayloadAction<ISurveyCall[]>,
    ) {
      draftReducerState.surveyCalls = action.payload || [];
      draftReducerState.loading = false;
    },
    dischargedPatientGetPayloadSuccess(
      draftReducerState, action: PayloadAction<IDischargedPatient>,
    ) {
      draftReducerState.selectedDischargedPatient = action.payload || undefined;
      draftReducerState.dischargedPatients = [
        ...draftReducerState.dischargedPatients,
        action.payload,
      ];
      draftReducerState.byIds[action.payload.DischargePatientId] = action.payload;
      draftReducerState.loading = false;
    },
    dischargedPatientSetSelected(
      draftReducerState, action: PayloadAction<IDischargedPatient>,
    ) {
      draftReducerState.selectedDischargedPatient = action.payload || undefined;
    },
    dischargedPatientSetTags(
      draftReducerState, action: PayloadAction<string[]>,
    ) {
      draftReducerState.tags = action.payload || [];
    },
    dischargedPatientIndexPayloadSuccess(
      draftReducerState,
      action: PayloadAction<IDischargedPatientsPayload>,
    ) {
      const normalizedPayload = normalize(action.payload, {
        DischargedPatients: [dischargedPatientSchema],
      });
      const dischargedPatientIds = normalizedPayload.result.DischargedPatients;
      const {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      } = action.payload.Pagination;

      draftReducerState.byIds = {
        ...normalizedPayload.entities.DischargedPatients,
      };

      const currentPatient = action.payload.SelectedPatient;
      if (currentPatient?.DischargePatientId) {
        if (!draftReducerState.byIds[currentPatient?.DischargePatientId]) {
          draftReducerState.byIds[currentPatient.DischargePatientId] = {
            ...currentPatient,
            wasInSearchResults: false,
          };
        } else {
          draftReducerState.byIds[currentPatient?.DischargePatientId].wasInSearchResults = true;
        }
      }

      draftReducerState.pagedIds = {
        [Page]: dischargedPatientIds,
      };

      draftReducerState.pagination = {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      };

      draftReducerState.loading = false;
    },

    dischargedPatientSurveyCallIndexPayloadSuccess(
      draftReducerState,
      action: PayloadAction<ISurveyCall[]>,
    ) {
      const normalizedPayload = {};
      for (const i of action.payload) {
        normalizedPayload[i.SurveyCallId] = i;
      }

      draftReducerState.surveyCallsByIds = {
        ...normalizedPayload,
      };
    },

    dischargedPatientSurveyCallCreatedSuccess(draftReducerState, action: PayloadAction<ISurveyCall>) {
      const dict = {};
      dict[action.payload.SurveyCallId] = action.payload
      draftReducerState.surveyCallsByIds = { ...draftReducerState.surveyCallsByIds, ...dict }
    },
    dischargedPatientDeleteSuccess(draftReducerState, action: PayloadAction<{ id: number }>) {
      const dischargedPatientId = action.payload.id;
      draftReducerState.selectedDischargedPatient = null;
      draftReducerState.loading = false;
      delete draftReducerState.byIds[dischargedPatientId];
    },

    setLastPatientEventTimeStamp(draftReducerState) {
      draftReducerState.timeStamp = new Date().getTime();
    },
    setDischargedPatientConfig(draftReducerState, action) {
      draftReducerState.config = action.payload;
    },
    setDischargedPatientConfigLoadingStarted(draftReducerState) {
      draftReducerState.configLoading = true;
    },
    setDischargedPatientConfigLoadingEnded(draftReducerState) {
      draftReducerState.configLoading = false;
    },
  },
});

export const getDischargedPatientConfig = ():AppThunk => async (dispatch, getState, Api) => {
  dispatch(setDischargedPatientConfigLoadingStarted());
  try {
    const config = await Api.DischargedPatients.dischargedPatientConfigGet();
    dispatch(setDischargedPatientConfig(config));
  } catch (err) {
    dispatch(hasError(err));
  }
  dispatch(setDischargedPatientConfigLoadingEnded());
}

export const updateDischargedPatientConfig = (data: IDischargedPatientConfig, cb: () => void):AppThunk => (
  async (dispatch, getState, Api) => {
    dispatch(setDischargedPatientConfigLoadingStarted());
    try {
      await Api.DischargedPatients.dischargedPatientConfigUpdate(data);
      if (cb) {
        cb();
      }
    } catch (err) {
      dispatch(hasError(err));
    }
    dispatch(setDischargedPatientConfigLoadingEnded());
  });

export const getAllTags = ():AppThunk => async (dispatch, getState, Api) => {
  dispatch(dischargedPatientStartLoadingIndicator('tags'))
  const tags = await Api.DischargedPatients.getTags();
  dispatch(dischargedPatientSetTags(tags.data));
  dispatch(dischargedPatientStopLoadingIndicator('tags'))
}

export const getDischargedPatientsIndex = (
  params: IFilterableIndexParams,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(dischargedPatientRequestStarted());
  try {
    const dischargedPatientIndexResponse = await Api.DischargedPatients.DischargedPatientsIndex(params);
    const selectedPatient = getState().DischargedPatients.selectedDischargedPatient;
    dispatch(dischargedPatientIndexPayloadSuccess({
      ...dischargedPatientIndexResponse,
      SelectedPatient: selectedPatient,
    }));
  } catch (err) {
    dispatch(dischargedPatientRequestFailed());
    dispatch(hasError(err));
  }
};

export const getDischargedPatientById = (
  dischargedPatientId: number,
  onSuccess?: (patient: IDischargedPatient) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(dischargedPatientRequestStarted());
  try {
    const dischargedPatientGetResponse = await Api.DischargedPatients.dischargedPatientGet(
      dischargedPatientId,
    );
    dispatch(dischargedPatientGetPayloadSuccess(dischargedPatientGetResponse));
    onSuccess?.(dischargedPatientGetResponse);
  } catch (err) {
    dispatch(dischargedPatientRequestFailed());
    dispatch(hasError(err));
  }
};

export const setCurrentPagePatients = (pageNumber: number):AppThunk => (dispatch, getState) => {
  const dischargedPatientIds: number[] = getState().DischargedPatients.pagedIds[pageNumber] || [];
  const dischargedPatients: IDischargedPatient[] = [];
  dischargedPatientIds.forEach((id) => {
    const dischargedPatient = getState().DischargedPatients.byIds[id.toString()];
    if (dischargedPatient) {
      dischargedPatients.push(dischargedPatient);
    }
  });
  dispatch(setCurrentPageDischargedPatients(dischargedPatients));
};

export const getSurveyCallsByDischargedPatientId = (
  dischargedPatientId: number,
): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(dischargedPatientStartLoadingIndicator('getCalls'));
  try {
    const dischargedPatientSurveyCallsGetResponse = await Api.DischargedPatients.dischargedPatientSurveyCallsGet(
      dischargedPatientId,
    );

    dispatch(
      dischargedPatientSurveyCallIndexPayloadSuccess(dischargedPatientSurveyCallsGetResponse),
    );
    dispatch(dischargedPatientStopLoadingIndicator('getCalls'));
  } catch (err) {
    dispatch(dischargedPatientStopLoadingIndicator('getCalls'));
    dispatch(hasError(err));
  }
};

export const getDischargedPatients = (): AppThunk => async (dispatch, getState, Api) => {
  dispatch(dischargedPatientRequestStarted());
  try {
    const dischargedPatientGetResponse = await Api.DischargedPatients.dischargedPatientsGet();
    dispatch(dischargedPatientPayloadSuccess(dischargedPatientGetResponse));
  } catch (err) {
    dispatch(dischargedPatientRequestFailed());
    dispatch(hasError(err));
  }
};

export const createDischargedPatient = (
  dischargedPatientData: IDischargedPatient,
  successCallback: (dischargedPatientId: number) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(dischargedPatientRequestStarted());
  try {
    const newDischargedPatient = await Api.DischargedPatients.dischargedPatientCreate(dischargedPatientData);
    dispatch(dischargedPatientCreatedSuccess(newDischargedPatient[0]));
    const dischargedPatientGetResponse = await Api.DischargedPatients.dischargedPatientGet(
      newDischargedPatient[0].DischargePatientId,
    );
    dispatch(dischargedPatientGetPayloadSuccess(dischargedPatientGetResponse));
    successCallback(newDischargedPatient[0].DischargePatientId);
  } catch (err) {
    dispatch(dischargedPatientRequestFailed());
    dispatch(hasError(err));
  }
};

export const createDischargedPatientAndProfiles = (
  formData: IPatientFormData,
  successCallback: (dischargedPatientId: number) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(dischargedPatientRequestStarted());
  try {
    const dischargedPatientData = formData.dischargedPatient;
    dischargedPatientData.ProgramStatus = 'In Program';
    const { contactProfile } = formData
    let refetchDistributionList = false;
    if (contactProfile.CustomerProfileID == null || contactProfile.CustomerProfileID === '') {
      if (contactProfile.MobilePhone) {
        contactProfile.MobilePhoneOptIn = true;
      }
      if (contactProfile.LandLine) {
        contactProfile.LandLineOptIn = true;
      }
      contactProfile.CustomerProfileID = uuid.v4();
      const result = await Api.Profiles.profilesCreate(contactProfile);
      refetchDistributionList = true;
      dischargedPatientData.ContactProfileId = result.CustomerProfileID;
    }
    const { patientProfile } = formData
    if (patientProfile.CustomerProfileID == null || patientProfile.CustomerProfileID === '') {
      if (patientProfile.MobilePhone) {
        patientProfile.MobilePhoneOptIn = true;
      }
      if (patientProfile.LandLine) {
        patientProfile.LandLineOptIn = true;
      }
      patientProfile.CustomerProfileID = uuid.v4();
      const result = await Api.Profiles.profilesCreate(patientProfile);
      refetchDistributionList = true;
      dischargedPatientData.ProfileId = result.CustomerProfileID;
    }
    if (refetchDistributionList) {
      await mutate(
        (key) => typeof key === 'string' && key.startsWith('/api/v2/Profiles?'),
        undefined,
        {
          revalidate: true,
          populateCache: true,
        },
      );
    }
    // We're assuming that the profiles exist and don't need to be update if the profileid is present.
    const newDischargedPatient = await Api.DischargedPatients.dischargedPatientCreate(dischargedPatientData);
    dispatch(dischargedPatientCreatedSuccess(newDischargedPatient[0]));
    const dischargedPatientGetResponse = await Api.DischargedPatients.dischargedPatientGet(
      newDischargedPatient[0].DischargePatientId,
    );
    dispatch(dischargedPatientGetPayloadSuccess(dischargedPatientGetResponse));
    successCallback(newDischargedPatient[0].DischargePatientId);
  } catch (err) {
    dispatch(dischargedPatientRequestFailed());
    dispatch(hasError(err));
  }
};

export const updateDischargedPatient = (
  dischargedPatientData: Partial<IDischargedPatient>,
  conflictCallback:(x:IDischargedPatient)=>void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(dischargedPatientStartLoadingIndicator('delete'));
  try {
    const currentDischargedPatient = getState().DischargedPatients.byIds[
      dischargedPatientData.DischargePatientId
    ];
    const updatedDischargedPatient = { ...currentDischargedPatient, ...dischargedPatientData };
    const updatedVersion = await Api.DischargedPatients.dischargedPatientUpdate(updatedDischargedPatient);
    dispatch(dischargedPatientCreatedSuccess({ ...updatedDischargedPatient, ...updatedVersion }));
    dispatch(dischargedPatientStopLoadingIndicator('delete'));
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        // refresh "current state"
        dispatch(dischargedPatientCreatedSuccess(err.response.data));
        conflictCallback(err.response.data);
      }
    }
    dispatch(dischargedPatientStopLoadingIndicator('delete'));
    dispatch(hasError(err));
  }
};

export const updateDischargedPatientAndProfiles = (
  dischargedPatientFormData: IPatientFormData,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(dischargedPatientStartLoadingIndicator('delete'));
  try {
    const dischargedPatientData = dischargedPatientFormData.dischargedPatient;
    const currentDischargedPatient = getState().DischargedPatients.byIds[dischargedPatientData.DischargePatientId];
    const updatedDischargedPatient = { ...currentDischargedPatient, ...dischargedPatientData };

    const patient = dischargedPatientFormData.patientProfile as IProfile;
    let patientProfile = await Api.Profiles.profilesGet(patient.CustomerProfileID);
    patientProfile = { ...patientProfile, ...patient }
    await Api.Profiles.profilesUpdate(patientProfile);
    const contact = dischargedPatientFormData.contactProfile as IProfile;
    let contactProfile = await Api.Profiles.profilesGet(contact.CustomerProfileID);
    contactProfile = { ...contactProfile, ...contact }
    await Api.Profiles.profilesUpdate(contactProfile);
    await mutate(
      (key) => typeof key === 'string' && key.startsWith('/api/v2/Profiles?'),
      undefined,
      {
        revalidate: true,
        populateCache: true,
      },
    );
    await Api.DischargedPatients.dischargedPatientUpdate(updatedDischargedPatient);
    dispatch(dischargedPatientCreatedSuccess(updatedDischargedPatient));
    dispatch(dischargedPatientStopLoadingIndicator('delete'));
  } catch (err) {
    dispatch(dischargedPatientStopLoadingIndicator('delete'));
    dispatch(hasError(err));
  }
};

export const updateSurveyCall = (
  surveyCallData: ISurveyCall,
  successCallback: () => void,
  conflictCallback: (conflict:ISurveyCall) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(dischargedPatientStartLoadingIndicator('updateSurveyCall'));
  try {
    const surveyCallUpdated = await Api.SurveyCalls.surveyCallUpdate(surveyCallData);
    dispatch(dischargedPatientSurveyCallCreatedSuccess(surveyCallUpdated));
    successCallback();
  } catch (err) {
    if (err.response) {
      if (err.response.status === 409) {
        // refreshes version field with current version
        dispatch(dischargedPatientSurveyCallCreatedSuccess(err.response.data));
        conflictCallback(err.response.data);
      }
    }
    dispatch(dischargedPatientStopLoadingIndicator('updateSurveyCall'));
    dispatch(hasError(err));
  }
};

export const updateDischargedPatientStatus = (
  dischargedPatientId: number,
  status: string,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(dischargedPatientStartLoadingIndicator('delete'));
  try {
    const currentDischargedPatient = getState().DischargedPatients.byIds[
      dischargedPatientId
    ];
    const updatedDischargedPatient = { ...currentDischargedPatient, ...currentDischargedPatient };
    updatedDischargedPatient.ProgramStatus = status;
    const updatedPatient = await Api.DischargedPatients.dischargedPatientUpdate(updatedDischargedPatient);
    dispatch(dischargedPatientCreatedSuccess(updatedPatient));
    dispatch(dischargedPatientStopLoadingIndicator('delete'));
  } catch (err) {
    dispatch(dischargedPatientStopLoadingIndicator('delete'));
    dispatch(hasError(err));
  }
};

export const deleteDischargedPatient = (
  dischargedPatientId: number,
  successCallback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(dischargedPatientStartLoadingIndicator('delete'));
  try {
    await Api.DischargedPatients.dischargedPatientDelete(dischargedPatientId);
    dispatch(dischargedPatientDeleteSuccess({ id: dischargedPatientId }));
    dispatch(dischargedPatientStopLoadingIndicator('delete'));
    successCallback();
  } catch (err) {
    dispatch(dischargedPatientStopLoadingIndicator('delete'));
    dispatch(hasError(err));
  }
}

export const patientDataUpdated = (): AppThunk => async (dispatch) => {
  dispatch(setLastPatientEventTimeStamp())
}

export const {
  dischargedPatientStartLoadingIndicator,
  dischargedPatientStopLoadingIndicator,
  dischargedPatientRequestStarted,
  dischargedPatientRequestFailed,
  dischargedPatientPayloadSuccess,
  dischargedPatientGetPayloadSuccess,
  dischargedPatientRequestSuccess,
  dischargedPatientCreatedSuccess,
  dischargedPatientIndexPayloadSuccess,
  dischargedPatientDeleteSuccess,
  dischargedPatientSurveyCallsPayloadSuccess,
  dischargedPatientSurveyCallIndexPayloadSuccess,
  setCurrentPageDischargedPatients,
  dischargedPatientSetSelected,
  updateSpecificPatientInByIdsDictionary,
  dischargedPatientSetTags,
  setLastPatientEventTimeStamp,
  dischargedPatientSurveyCallCreatedSuccess,
  setDischargedPatientConfig,
  setDischargedPatientConfigLoadingEnded,
  setDischargedPatientConfigLoadingStarted,
} = DischargedPatientSlice.actions;

export default DischargedPatientSlice.reducer;

export const dischargedPatientsIsLoading = (
  state: RootState,
): boolean => state.DischargedPatients.loading;

export const selectDischargedPatientById = (
  state: RootState, dischargedPatientId: number,
): IDischargedPatient => state.DischargedPatients.byIds[dischargedPatientId];

export const selectPaginationData = (
  state: RootState,
): IDischargedPatientsState['pagination'] => state.DischargedPatients.pagination;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectDischargedPatientsPage = (pageNumber: number) => (
  state: RootState,
) => state.DischargedPatients.byIds;

export const selectDischargedPatientSurveyCallsPage = (pageNumber: number) => (
  state: RootState,
): ISurveyCall[] => Object.values(state.DischargedPatients.surveyCallsByIds);
export const getFirstSortedDischargedPatient = () => (
  state: RootState,
):IDischargedPatient => {
  const patients = Object.values(state.DischargedPatients.byIds);
  const allProfiles = state.Profiles.byIds;
  const sorted = patients.sort(
    (a, b) => allProfiles[a.ProfileId]?.LastName.localeCompare(allProfiles[b.ProfileId]?.LastName),
  ).filter(({ wasInSearchResults }) => wasInSearchResults !== false);

  return sorted[0];
}

export const selectDischargedPatientConfig = (
  state: RootState,
): IDischargedPatientConfigGet|null => state.DischargedPatients.config;

export const selectDischargedPatientConfigLoading = (
  state: RootState,
): boolean => state.DischargedPatients.configLoading;

export const selectDischargedPatientTags = (
  state: RootState,
): string[] => state.DischargedPatients.tags;
