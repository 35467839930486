import React, { ReactElement, SetStateAction } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFormData } from './Form';

interface IProps {
  showModal: boolean
  setShowModal: (value: SetStateAction<boolean>) => void
  saveProfile: (data: IFormData) => void
  disableSavingButton: boolean
  setDisableSavingButton: (value: SetStateAction<boolean>) => void
  profData: IFormData
}

const PreferencesWarningModal = ({
  showModal, setShowModal, saveProfile, disableSavingButton, setDisableSavingButton, profData,
}: IProps): ReactElement | null => (
  <Modal
    show={showModal}
    onHide={() => setShowModal(false)}
    className="DiscardChangeModal"
    backdrop="static"
    keyboard={false}
    centered
  >
    <Modal.Header />
    <Modal.Body>
      <FontAwesomeIcon
        icon="exclamation-circle"
        size="4x"
        color="#FFAE42"
      />

      <div className="message">
        <h4>Are you sure?</h4>
        <p>
          No communication preferences were selected.
          This profile will not receive messages until one is selected.
          Do you want to continue saving?
        </p>
      </div>

      <button
        className="btn btn-block btn-primary"
        disabled={disableSavingButton}
        onClick={() => {
          saveProfile(profData);
        }}
        type="button"
      >
        Save
      </button>
      <button
        className="btn btn-block btn-secondary"
        onClick={() => setShowModal(false)}
        type="button"
      >
        Cancel
      </button>
    </Modal.Body>
  </Modal>
)

export default PreferencesWarningModal;
