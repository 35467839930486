import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';

const DotsButton = React.forwardRef<HTMLButtonElement, React.HTMLAttributes<HTMLButtonElement>>(
  ({
    children, onClick, className, ...props
  }, ref) => (
    <button
      type="button"
      className="sms-inbox__conversation-view__conversations__conversation__dots-btn"
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  ),
);

export const Dots = ({ items, ...props }) => (
  <div className="sms-inbox__conversation-view__conversations__conversation__dots">
    <Dropdown alignRight>
      <Dropdown.Toggle
        as={DotsButton}
        {...props}
      >
        <FontAwesomeIcon
          icon="ellipsis-v"
          color="#6C757D"
          size="lg"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu
        popperConfig={
          {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 5],
                },
              }],
          }
        }
      >
        {items.map(((item) => (
          <Dropdown.Item
            key={item.label}
            onClick={(e) => {
              e.stopPropagation();
              item.handler();
            }}
          >
            {item.label}
          </Dropdown.Item>
        )))}
      </Dropdown.Menu>
    </Dropdown>
  </div>
)
