import React from 'react';
import clsx from 'clsx';

import { useSmsConversationsQuery } from 'components/hooks/useSmsConversationsQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const RefreshButton = () => {
  const { mutate } = useSmsConversationsQuery();
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  const onRefresh = async () => {
    setIsRefreshing(true);
    await mutate();
    setIsRefreshing(false);
  }

  return (
    <button
      type="button"
      className={clsx('sms-inbox__conversation-view__refresh-button', isRefreshing && 'refreshing')}
      onClick={onRefresh}
      disabled={isRefreshing}
    >
      <FontAwesomeIcon icon="redo" />
    </button>
  )
}
