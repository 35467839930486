import React, { FC } from 'react';
import { Col } from 'react-bootstrap';
import { TagSummary } from './TagSummary';
import { StatusSummary } from './StatusSummary';
// import { SurveyCallTimeSeriesSummary } from './SurveyCallTimeSeriesSummary';

export const ReportDisplay: FC<Record<string, any>> = ({ reportData }) => (
  <>
    <Col xl={6} md={6} lg={6}>
      <TagSummary dischargedPatientsTagSummary={reportData?.DischargedPatientsTagSummary} />
    </Col>
    <Col xl={6} md={6} lg={6}>
      <StatusSummary dischargedPatientsByStatus={reportData?.DischargedPatientsByStatus} />
    </Col>
    {/* <Col>
      <SurveyCallTimeSeriesSummary dischargedPatientsTimeSeriesSummary={reportData?.SurveyCallSummaryTimeSeries} />
    </Col> */}
  </>
);
