import React, {
  useEffect, useState, ReactElement, ChangeEvent,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import {
  getGroupsIndex,
  selectGroups,
  selectNonDefaultGroupsById,
} from 'reducers/Groups';
import { RootState } from 'types/rootState';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';

interface IProps {
  groups?: number[]
  onAssociateGroup: (groupIds: number[]) => void
  caremergeUserId: number | 0
  userType: string
}

function GroupManager({
  groups = [],
  onAssociateGroup,
  caremergeUserId,
  userType,
}: IProps): ReactElement {
  const [showModal, setShowModal] = useState<boolean>(false);
  const allGroups = useSelector(selectGroups(true, '', [0, 1]));
  const associatedGroups = useSelector(selectNonDefaultGroupsById(groups));
  const allCategories = useSelector((state: RootState) => state.Groups.categories)

  const dispatch = useDispatch();

  const translateUserType = useUserTypeTranslation();
  const formatUserType = (ut: string) => translateUserType(ut);

  useEffect(() => {
    if (allGroups.length === 0) {
      dispatch(getGroupsIndex());
    }
  }, [allGroups.length, dispatch]);

  const onGroupChange = (
    groupId: number,
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    const ids = [...groups];

    if (event.currentTarget.checked) {
      ids[ids.length] = groupId;
      onAssociateGroup(ids);
    } else {
      const idIndex = ids.indexOf(groupId);
      if (idIndex !== -1) ids.splice(idIndex, 1);
      onAssociateGroup(ids);
    }
  };

  const getCategoryNameByGroupId = (
    groupId: number,
  ): string => {
    const category = allCategories.find((c) => c.GroupIds.indexOf(groupId) > -1);
    return category ? `(${category.CategoryName}) ` : '';
  }

  return (
    <>
      <fieldset className="GroupManager">
        <legend>
          Groups&nbsp;|&nbsp;
          <a
            href={window.location.pathname}
            onClick={(e) => {
              e.preventDefault();
              setShowModal(true);
            }}
          >
            Manage Groups
          </a>
        </legend>

        <div className="groups">
          {associatedGroups.length === 0
            ? 'This profile is not assigned to any groups.'
            : null}
          {associatedGroups.map((group) => (
            <span key={group.Id}>{formatUserType(group.Name)}</span>
          ))}
        </div>
      </fieldset>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        className="GroupManagerModal"
        size="lg"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body className="modal-logo">
          <h4>Associated Groups</h4>
          <div className="groups">
            {allGroups.filter((x) => !x.IsReadonly).map((group) => (
              <div key={group.Id} className="group">
                <label htmlFor={`${group.Id}-check`}>
                  <input
                    type="checkbox"
                    checked={groups.includes(group.Id)}
                    onChange={(e) => onGroupChange(group.Id, e)}
                    id={`${group.Id}-check`}
                  />
                  {getCategoryNameByGroupId(group.Id)}
                  {group.Name}
                </label>
              </div>
            ))}
            {allGroups.filter((x) => !x.IsReadonly).length === 0 && (
              <div>
                <p className="mt-3 mb-4 text-center">
                  There are currently no groups.
                  <br />
                  To create one click Manage Groups below.
                </p>
                <a
                  href="/groups"
                  className="btn btn-secondary btn-block"
                >
                  Manage Groups
                </a>
              </div>
            )}
          </div>
          <button type="button" className="btn btn-primary btn-block" onClick={() => setShowModal(false)}>
            Done
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GroupManager;
