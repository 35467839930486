import React, { useState } from 'react';
import { useQueryParam } from 'use-query-params';
import useLifecycles from 'react-use/lib/useLifecycles';
import useDebounce from 'react-use/lib/useDebounce';

import Input from 'components/Inputs/Input';
import { ReactComponent as SearchSvg } from 'styles/images/loupe.svg';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

export const Searchbox = () => {
  const [searchParam = '', setSearchParam] = useQueryParam<string, string>('search');
  const [search, setSearch] = useState<string>(searchParam);

  const [, cancel] = useDebounce(
    () => {
      setSearchParam(search);
    },
    200,
    [search],
  );

  useLifecycles(cancel, cancel);

  useUpdateEffect(() => {
    if (searchParam !== search) {
      setSearch(searchParam);
    }
  }, [searchParam]);

  return (
    <Input
      type="text"
      className="sms-inbox__conversation-view__searchbox"
      value={search}
      onChange={(e) => {
        setSearch(e.target.value);
      }}
      icon={SearchSvg}
      placeholder="Search name or number"
    />
  )
}
