import React, { useState, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { deleteProfile } from 'reducers/Profiles';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';

interface IProps {
  show: boolean
  profileIds: string[]
  onSuccess?: () => void
  hideModal: () => void
}

DeleteActionModal.defaultProps = {
  onSuccess: undefined,
}

function DeleteActionModal({
  profileIds, onSuccess, hideModal, show,
}: IProps): ReactElement {
  const dispatch = useDispatch();
  const [interactionDisabled, setInteractionDisabled] = useState<boolean>(false);
  const itemLabel = (profileIds.length > 1) ? 'Profiles' : 'Profile';

  const deleteProfiles = (): void => {
    let deletedProfiles = 0;
    setInteractionDisabled(true);

    profileIds.forEach((id) => {
      dispatch(deleteProfile(id, () => {
        deletedProfiles += 1;
        if (deletedProfiles === profileIds.length) {
          toast.success('Profile successfully deleted');
          segmentAnalyticsTrack(trackActions.deleteProfile());
          setInteractionDisabled(false);
          hideModal();
          if (onSuccess) {
            onSuccess();
          }
        }
      }, () => {
        setInteractionDisabled(false);
        hideModal();
      }));
    });
  };

  return (
    <Modal
      show={show}
      onHide={hideModal}
      className="DeleteActionButtonModal"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton={!interactionDisabled} />
      <Modal.Body>
        <FontAwesomeIcon
          icon="trash-alt"
          size="4x"
          color="#FF7052"
        />

        <div className="message">
          <h4>Are you sure?</h4>
          <p>
            This action will delete
            {' '}
            {profileIds.length}
            {' '}
            {itemLabel}
            .
          </p>
        </div>

        <button
          type="button"
          className="btn btn-block btn-danger"
          disabled={interactionDisabled}
          onClick={deleteProfiles}
        >
          Delete
          {' '}
          {itemLabel}
        </button>
        <button
          type="button"
          className="btn btn-block btn-secondary"
          disabled={interactionDisabled}
          onClick={hideModal}
        >
          Cancel
        </button>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteActionModal;
