import { IGroupMembers } from 'types/IGroupMembers';
import { ICaremergeCategory, IGroup } from '../types/IGroup';
import { IProfile } from './IProfile';

export interface IGroupsState {
  byIds: {
    [key: number]: IGroup
  }
  membersByIds: {
    [key: number]: IGroupMembers
  }
  allIds: Array<number>
  loading: boolean
  selectedGroupId: number | null
  searchProfiles: IProfile[]
  pagination: {
    PerPage: number
    TotalItems: number
    TotalPages: number
  }
  profileIdsToAdd: string[]
  filterGroupMembers: string[]
  categories: ICaremergeCategory[]
  categoryById: {
    [key: number] : ICaremergeCategory
  }
}

export const DEFAULT_GROUPS = ['Master', 'Seniors', 'Staff', 'Family'];
