import React from 'react';
import uuidv4 from 'uuid/v4';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { formatPhoneNumberString } from 'lib/formatters/phoneNumber';
import { ReactComponent as Logo } from 'styles/images/goicon-logo-sm.svg';
import {
  IConversation, Status, MessageType,
} from 'types/IConversation';
import * as InboxApi from 'api/InboxAPI';
import { hasError } from 'reducers/Error';
import { Textbox } from './Textbox';
import { Messages } from './Messages';
import { MessagesRef, ConversationsRef } from './types';

interface ChatViewProps {
  selectedConversation: IConversation|null
  messagesRef: React.RefObject<MessagesRef>
  conversationsRef: React.RefObject<ConversationsRef>
}

export const ChatView: React.FC<ChatViewProps> = ({
  selectedConversation, messagesRef, conversationsRef,
}) => {
  const dispatch = useDispatch();
  const recipient = selectedConversation && (
    selectedConversation.Profiles.map(({ FirstName, LastName }) => (
      `${FirstName} ${LastName}`
    )).join(', ') || 'Unknown'
  );

  const onSend = async (val) => {
    // revalidate messages, optimistic message + optimistic conv

    const optimisticMessage = {
      MessageType: MessageType.Direct,
      MessageDateUTC: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      MessageContent: val,
      MessageId: `optimistic-${uuidv4()}`,
      ErrorMessage: null,
      Status: Status.Sending,
      IsHidden: false,
    }

    try {
      await messagesRef.current.mutate(
        () => InboxApi.sendSms({
          SentFrom: selectedConversation.AccountNumber,
          RecipientNumber: selectedConversation.ProfileNumber,
          Content: val,
        }) as any,
        {
          revalidate: true,
          populateCache: false,
          optimisticData: (currentMessages) => {
            if (!currentMessages) {
              return currentMessages;
            }

            const currentMessagesFirstPage = currentMessages[0];

            const newCurrentMessagesFirstPage = {
              ...currentMessagesFirstPage,
              Messages: [
                optimisticMessage,
                ...currentMessagesFirstPage.Messages,
              ],
            }

            return [
              newCurrentMessagesFirstPage,
              ...currentMessages.slice(1),
            ]
          },
        },
      );
    } catch (err) {
      dispatch(hasError(err));
    }
  }

  return (
    <div
      className="sms-inbox__chat-view"
    >
      {selectedConversation
        ? (
          <div className="sms-inbox__chat-view__chat">
            <header
              title={recipient}
              className="sms-inbox__chat-view__chat__header"
            >
              <span className="recipient">
                {recipient}
              </span>
              <span className="phone-number">
                {formatPhoneNumberString(selectedConversation.ProfileNumber)}
              </span>
            </header>

            <Messages
              ref={messagesRef}
              selectedConversation={selectedConversation}
              conversationsRef={conversationsRef}
            />

            <Textbox
              selectedConversation={selectedConversation}
              onSend={onSend}
            />
          </div>
        )
        : (
          <div className="sms-inbox__chat-view__placeholder">
            <Logo className="sms-inbox__chat-view__placeholder__logo" />
            <p className="sms-inbox__chat-view__placeholder__text">
              Please select an item on the left.
            </p>
          </div>
        )}
    </div>
  )
}
