import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IEvent,
  IEventsPayload,
  TemplateOrEvent,
  ScheduleMode,
  VoiceMessageTypeEnum,
  EventType,
} from 'types/IEvent';
import { LanguageNames } from 'lib/CommonSelect';
import { IFilterableIndexParams } from 'types/ITemplatesIndexParams';
import { normalize, schema } from 'normalizr';
import { serialize } from 'utils/serializeParams';
import { AppThunk } from '../app/appThunk';
import { RootState } from '../types/rootState';
import { IEventsState } from './IEventsState';
import { hasError } from './Error';

const eventsIndexDefaultParams = {
  page: 1,
}

const initialSelectedEvent = {
  ID: 0,
  Name: '',
  Description: '',
  SendMode: ScheduleMode.Once,
  RRule: '',
  StartDate: '',
  Location: '',
  SendTime: '',
  EmailContent: null,
  SMSContent: null,
  VoiceContent: null,
  GroupIds: [],
  ProfileIds: [],
  VoiceMessageType: VoiceMessageTypeEnum.Both,
  TemplateID: 0,
  Category: 'Activity',
  voiceSelected: false,
  emailSelected: false,
  smsSelected: false,
  ExpirationTime: null,
  EventType: EventType.ANNOUNCEMENT,
  Status: '',
  StopDateLocal: null,
  SourceLanguage: LanguageNames.English,
  IsEmergency: false,
  IsSendNow: false,
  IsBroadcastForm: false,
}

export const INITIAL_STATE: IEventsState = {
  loading: false,
  validating: false,
  events: [],
  selectedEvent: initialSelectedEvent,
  byIds: {},
  pagedIds: {},
  pagination: {
    Page: 1,
    PerPage: 20,
    TotalItems: 0,
    TotalPages: 1,
  },
};

export const selectEvents = () => (state: RootState): IEvent[] => state.Events.events;

export const selectEventsLoading = (state: RootState): boolean => state.Events.loading;

export const selectEventsValidating = (state: RootState): boolean => state.Events.validating;

const eventSchema = new schema.Entity('Data', {}, { idAttribute: 'ID' });

const EventSlice = createSlice({
  name: 'Events',
  initialState: INITIAL_STATE,
  reducers: {
    validateLanguageRequestStarted(draftReducerState) {
      draftReducerState.validating = true;
    },
    validateLanguageRequestFinished(draftReducerState) {
      draftReducerState.validating = false;
    },
    validateEventContentStarted(draftReducerState) {
      draftReducerState.validating = true;
    },
    validateEventContentFinished(draftReducerState) {
      draftReducerState.validating = false;
    },
    eventRequestStarted(draftReducerState) {
      draftReducerState.loading = true;
    },
    eventRequestFailed(draftReducerState) {
      draftReducerState.loading = false;
    },
    eventRequestSuccess(draftReducerState, action: PayloadAction<IEvent>) {
      draftReducerState.loading = false;
      draftReducerState.selectedEvent = action.payload || undefined;
    },
    eventRequestFinished(draftReducerState) {
      draftReducerState.loading = false;
    },
    eventPayloadSuccess(draftReducerState, action: PayloadAction<IEvent[]>) {
      draftReducerState.events = action.payload || new Array<IEvent>();
      draftReducerState.loading = false;
    },
    selectedEventPayloadSuccess(
      draftReducerState,
      action: PayloadAction<IEvent>,
    ) {
      draftReducerState.selectedEvent = action.payload || undefined;
      if (
        !draftReducerState.events.find(
          (event) => event.ID === action.payload.ID,
        )
      ) {
        draftReducerState.events.push(action.payload);
      }
      draftReducerState.loading = false;
    },
    eventIndexPayloadSuccess(
      draftReducerState,
      action: PayloadAction<{ data: IEventsPayload, params: IFilterableIndexParams }>,
    ) {
      const normalizedPayload = normalize(action.payload.data, {
        Data: [eventSchema],
      });

      const eventIds = normalizedPayload.result.Data;
      const {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      } = action.payload.data.Pagination;

      draftReducerState.byIds = {
        ...draftReducerState.byIds,
        ...normalizedPayload.entities.Data,
      };
      draftReducerState.pagedIds = {
        ...draftReducerState.pagedIds,
        [serialize(action.payload.params, eventsIndexDefaultParams)]: eventIds,
      };

      draftReducerState.pagination = {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      };

      draftReducerState.loading = false;
    },
    getEventInstanceRequestStart(draftReducerState, action: PayloadAction<IEvent>) {
      draftReducerState.loading = false;
      draftReducerState.selectedEvent = undefined;
    },
  },
});

export const getEvents = (): AppThunk => async (dispatch, getState, Api) => {
  dispatch(eventRequestStarted());
  try {
    const eventGetResponse = await Api.Events.eventsGet();
    dispatch(eventPayloadSuccess(eventGetResponse));
  } catch (err) {
    dispatch(eventRequestFailed());
    dispatch(hasError(err));
  }
};

export const getSelectedEvent = (ID: number): AppThunk => async (dispatch, getState, Api) => {
  dispatch(eventRequestStarted());
  try {
    const selectedEventResponse = await Api.Events.getEventById(ID);
    dispatch(selectedEventPayloadSuccess(selectedEventResponse));
  } catch (err) {
    dispatch(eventRequestFailed());
    dispatch(hasError(err));
  }
};

export const getSelectedEventInstance = (
  ID: number,
  date: string,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(getEventInstanceRequestStart());
  try {
    const selectedEventInstanceResponse = await Api.Events.getEventInstanceByIdAndDate(
      ID,
      date,
    );
    if (selectedEventInstanceResponse) {
      dispatch(selectedEventPayloadSuccess(selectedEventInstanceResponse));
    } else {
      const selectedEventResponse = await Api.Events.getEventById(ID);
      dispatch(selectedEventPayloadSuccess(selectedEventResponse));
    }
  } catch (err) {
    dispatch(eventRequestFailed());
    dispatch(hasError(err));
  }
};

export const updateEventInstance = (
  data: IEvent,
  date: string,
  onSuccess?: () => void,
): AppThunk => async (
  dispatch, getState, Api,
) => {
  try {
    await Api.Events.eventInstancePut(data, date);
    onSuccess?.();
  } catch (err) {
    dispatch(hasError(err));
  }
};

export const createEvent = (content: IEvent, afterCreate?: (event: IEvent) => void):
AppThunk => async (dispatch, getState, Api) => {
  dispatch(eventRequestStarted());
  try {
    const [createdEvent] = await Api.Events.eventCreate([content]);
    dispatch(eventRequestSuccess(createdEvent));
    afterCreate?.(createdEvent);
  } catch (err) {
    dispatch(eventRequestFailed());
    dispatch(hasError(err));
  }
};

export const stopEvent = (content: IEvent, dateStr: string): AppThunk => async (
  dispatch, getState, Api,
) => {
  await Api.Events.eventStop(content.ID, dateStr);
};

export const deleteEvent = (id: number, cb?: () => void, errorCallback?: (any) => void): AppThunk => async (
  dispatch, getState, Api,
) => {
  try {
    await Api.Events.deleteEvent(id)
    if (cb) {
      cb();
    }
  } catch (err) {
    if (errorCallback) {
      errorCallback(err?.response);
    }
  }
};

export const {
  validateLanguageRequestStarted,
  validateLanguageRequestFinished,
  validateEventContentStarted,
  validateEventContentFinished,
  eventRequestStarted,
  eventRequestFailed,
  eventPayloadSuccess,
  selectedEventPayloadSuccess,
  eventRequestSuccess,
  eventIndexPayloadSuccess,
  getEventInstanceRequestStart,
  eventRequestFinished,
} = EventSlice.actions;

export default EventSlice.reducer;

// Selectors

export const getEventById = (
  eventId: number,
) => (
  store: RootState,
): IEvent => store.Events.events.find(
  (event) => event.ID === eventId,
);

export const getEventsIndex = (
  params: IFilterableIndexParams,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(eventRequestStarted());
  try {
    const templateIndexResponse = await Api.Events.EventsIndex(params);
    dispatch(eventIndexPayloadSuccess({ data: templateIndexResponse, params }));
    dispatch(eventRequestSuccess());
  } catch (err) {
    dispatch(eventRequestFailed());
    dispatch(hasError(err));
  }
};

export const validateEventSourceLanguage = (
  data: TemplateOrEvent[],
  successCallback?: () => void,
  failedCallback?: (err: Error) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(validateLanguageRequestStarted());
  try {
    await Api.Events.validateEventSourceLanguage(data);
    successCallback?.();
  } catch (err) {
    dispatch(hasError(err));
    failedCallback?.(err);
  }
  dispatch(validateLanguageRequestFinished());
};

export const validateEventContent = (
  data: TemplateOrEvent[],
  type: string,
  successCallback?: () => void,
  failedCallback?: (err: Error) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(validateEventContentStarted());

  try {
    await Api.Events.validateEventContent(data, type);
    successCallback?.();
  } catch (err) {
    dispatch(hasError(err));
    failedCallback?.(err);
  }
  dispatch(validateEventContentFinished());
};

export const selectPaginationData = (
  state: RootState,
): IEventsState['pagination'] => state.Events.pagination;

export const selectEventsPage = (params: IFilterableIndexParams) => (
  state: RootState,
): IEvent[] => {
  const hash = serialize(params, eventsIndexDefaultParams);

  const eventIds = state.Events.pagedIds[hash] || [];
  const events: IEvent[] = [];
  eventIds.forEach((id) => {
    const event = state.Events.byIds[id.toString()];
    if (event) {
      events.push(event);
    }
  });
  return events;
};

export const selectSelectedEvent = (
  state: RootState,
): IEventsState['selectedEvent'] => state.Events.selectedEvent;

export const selectIsValidating = (
  state: RootState,
): boolean => state.Events.validating;
