import React, {
  useMemo, useCallback, useRef, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import clsx from 'clsx';
import { mutate } from 'swr';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _omit from 'lodash/omit';

import { hasError } from 'reducers/Error';
import { formatPhoneNumberString } from 'lib/formatters/phoneNumber';
import * as InboxApi from 'api/InboxAPI';
import { IConversation } from 'types/IConversation';
import { getUserState } from 'reducers/UserInfo';
import { useSmsConversationsQuery } from 'components/hooks/useSmsConversationsQuery';
import { ConversationsRef, MessagesRef } from './types';
import { Dots } from './Dots';

interface ConversationsProps {
  selectedConversation: IConversation|null
  setSelectedConversation: (newConversation: IConversation) => void
  messagesRef: React.RefObject<MessagesRef>
  hasFilters: boolean
}

export const Conversations = forwardRef<ConversationsRef, ConversationsProps>(({
  selectedConversation,
  setSelectedConversation,
  messagesRef,
  hasFilters,
}, ref) => {
  const dispatch = useDispatch();
  const userInfoState = useSelector(getUserState);

  const {
    data, mutate: boundMutate, isLoading, setSize,
  } = useSmsConversationsQuery({
    refreshInterval: hasFilters ? 0 : 60000, // 10000 prev
  });

  useImperativeHandle(ref, () => ({ mutate: boundMutate }), [boundMutate]);

  const hasMore = useMemo(() => {
    if (!data?.length) {
      return false;
    }

    const lastPage = data[data.length - 1];
    return lastPage.Pagination.TotalPages !== 0 && lastPage.Pagination.TotalPages !== lastPage.Pagination.Page
  }, [data])

  const fetchedConversations: IConversation[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.reduce((acc, page) => [...acc, ...page.SmsConversations], [])
  }, [data]);

  useEffect(() => {
    if (!selectedConversation) {
      return;
    }

    const newerConversation = fetchedConversations.find(
      (item) => item.ConversationId === selectedConversation.ConversationId,
    );

    if (!newerConversation) {
      return;
    }

    if (JSON.stringify(newerConversation) === JSON.stringify(selectedConversation)) {
      return;
    }

    setSelectedConversation(newerConversation);

    if (JSON.stringify(_omit(newerConversation.LastRecievedMessage, 'IsHidden'))
    !== JSON.stringify(_omit(selectedConversation.LastRecievedMessage, 'IsHidden'))) {
      // revalidate messages
      messagesRef.current?.mutate();
    }
  }, [
    selectedConversation, fetchedConversations, setSelectedConversation, messagesRef,
  ])

  const observer = useRef<IntersectionObserver>();
  const lastConversationRef = useCallback((node) => {
    if (isLoading) {
      return;
    }
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setSize((oldSize) => oldSize + 1)
      }
    })
    if (node) {
      observer.current.observe(node);
    }
  }, [isLoading, hasMore, setSize]);

  const markAsRead = async (conversation: IConversation, read = true) => {
    const optimisticConv = {
      ...conversation,
      IsRead: read,
    }

    // workaround for optimistic message (swr < 2, doesn't support optimistic updates in infinite queries)
    await boundMutate(
      (currentConv) => {
        if (!currentConv) {
          return currentConv;
        }

        const conversationPage = currentConv
          .find((page) => page.SmsConversations
            .some((conv) => conv.ConversationId === conversation.ConversationId),
          );

        if (!conversationPage) {
          return currentConv;
        }

        const conversationIndex = conversationPage.SmsConversations
          .findIndex((conv) => conv.ConversationId === conversation.ConversationId);

        if (conversationIndex === -1) {
          return currentConv;
        }

        const newSmsConversations = [
          ...conversationPage.SmsConversations.slice(0, conversationIndex),
          optimisticConv,
          ...conversationPage.SmsConversations.slice(conversationIndex + 1),
        ];

        const newConversationPage = {
          ...conversationPage,
          SmsConversations: newSmsConversations,
        }

        return [
          ...currentConv.slice(0, currentConv.indexOf(conversationPage)),
          newConversationPage,
          ...currentConv.slice(currentConv.indexOf(conversationPage) + 1),
        ]
      },
      false,
    );

    try {
      await InboxApi.markAsRead(conversation.ConversationId, read);
    } catch (err) {
      dispatch(hasError(err));
    }

    await mutate('inboxUnreadMessageCount');
  }

  return (
    <div className="sms-inbox__conversation-view__conversations">
      {fetchedConversations.map((conversation, index) => {
        const recipient = conversation.Profiles.map(({ FirstName, LastName }) => (
          `${FirstName} ${LastName}`
        )).join(', ') || formatPhoneNumberString(conversation.ProfileNumber);

        const noProfiles = !conversation.Profiles.length;
        const multipleProfiles = conversation.Profiles.length > 1;

        let warning: string|null = null;
        if (noProfiles) {
          warning = 'No profiles within this account are associated to this number. A profile with this number may have been deleted, or their number has changed.';
        } else if (multipleProfiles) {
          warning = `Multiple profiles in this account are associated to the number ${formatPhoneNumberString(conversation.ProfileNumber)}`
        }

        const d = moment.utc(conversation.LastRecievedMessage.MessageDateUTC, 'YYYY-MM-DDTHH:mm:ss.SSS')
          .tz(userInfoState.accountTimezone);
        const browserTime = d.toDate().toLocaleTimeString(undefined, { timeStyle: 'short' });
        const browserDate = d.toDate().toLocaleDateString();

        const formattedDate = d.calendar(null, {
          sameDay: `[${browserTime}, Today]`,
          lastDay: `[${browserTime}, Yesterday]`,
          lastWeek: `[${browserTime}], dddd`,
          sameElse: () => `[${browserTime}, ${browserDate}]`,
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
        });

        const isPreviewHidden = conversation.LastRecievedMessage.IsHidden;
        // shows <image> when reply only contains image
        let replacedContent = '';
        if (isPreviewHidden) {
          replacedContent = 'Message Hidden';
        } else {
          replacedContent = conversation.LastRecievedMessage.MessageContent;
        }

        return (
        /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
          <div
            key={conversation.ConversationId}
            ref={index === fetchedConversations.length - 1 ? lastConversationRef : undefined}
            className={clsx(
              'sms-inbox__conversation-view__conversations__conversation',
              selectedConversation?.ConversationId === conversation.ConversationId && 'selected',
              conversation.IsRead && 'read',
            )}
            onClick={() => {
              setSelectedConversation(conversation);
              markAsRead(conversation);
            }}
          >
            <h6
              className="sms-inbox__conversation-view__conversations__conversation__title"
            >
              {warning && (
                <OverlayTrigger
                  placement="right"
                  overlay={(
                    <Tooltip id={`tooltip-${conversation.ConversationId}`}>
                      {warning}
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon
                    icon="exclamation-triangle"
                    color="var(--orange)"
                  />

                </OverlayTrigger>
              )}
              <span title={recipient}>
                {recipient}
              </span>
            </h6>

            <p className="sms-inbox__conversation-view__conversations__conversation__datetime">
              {formattedDate}
            </p>

            <p
              className={clsx(
                'sms-inbox__conversation-view__conversations__conversation__preview',
                isPreviewHidden && 'preview--hidden',
              )}
              title={replacedContent}
            >
              {replacedContent}
            </p>

            <Dots
              id={conversation.ConversationId}
              items={[{
                label: `Mark as ${conversation.IsRead ? 'unread' : 'read'}`,
                handler: () => {
                  markAsRead(conversation, !conversation.IsRead)
                },
              }]}
            />
          </div>
        )
      })}

      {isLoading && (
        <Spinner
          animation="border"
          variant="primary"
          role="status"
          className="mx-auto d-block mt-3 mb-3"
        />
      )}

    </div>
  )
});
