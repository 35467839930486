import React, { ReactElement, useMemo } from 'react';
import useSWR from 'swr';
import { Spinner } from 'react-bootstrap';
import * as PbiApi from 'api/PowerBiApi';
import PbiReportEmbed from 'components/PowerBi/PbiReportEmbed';
import {
  selectUserInfo,
} from 'reducers/UserInfo';
import { useSelector } from 'react-redux';

const fetchReportData = async (accountId: number) => {
  if (!accountId) return null;
  const response = await PbiApi.getEnterpriseResidentSurveysReport({
    ignoreGlobalCatch: true,
  });
  return response;
};

const EnterpriseResidentSurveyReportPage = (): ReactElement => {
  const userInfo = useSelector(selectUserInfo);
  const accountId = useMemo(() => userInfo?.AccountDetail?.AccountID ?? null, [userInfo]);

  const { data: reportEmbedInfo, error, isLoading } = useSWR(
    ['enterpriseResidentSurveyReport', accountId],
    () => fetchReportData(accountId),
    {
      shouldRetryOnError: false,
      revalidateIfStale: true,
      dedupingInterval: 10 * 60 * 1000, // fresh for 10 minutes
    },
  );

  if (!accountId || isLoading) {
    return (
      <div className="loading-text d-flex align-items-center">
        <Spinner animation="border" variant="primary" role="status" className="mr-3" />
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="loading-text d-flex align-items-center">
        {error?.response?.data?.Message || 'An error has occurred'}
      </div>
    )
  }

  if (!reportEmbedInfo) {
    return <h2>Unable to get report data.</h2>;
  }

  const { EmbedToken, EmbedUrl } = reportEmbedInfo;

  return (
    <div>
      <PbiReportEmbed EmbedToken={EmbedToken} EmbedUrl={EmbedUrl} />
    </div>
  );
};

export default EnterpriseResidentSurveyReportPage;
