import React, { ReactElement, useEffect, useState } from 'react';
import {
  Spinner,
} from 'react-bootstrap';
import useSWRMutation from 'swr/mutation';
import * as UserInfoApi from 'api/UserInfoAPI';
import { useHistory } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { useCookies } from 'react-cookie';
import SubmitButton from 'components/FormControls/SubmitButton';
import JwtDecode from 'jwt-decode';
import useSWRImmutable from 'swr/immutable';

interface IUnsubscribe {
  email: string
  AccountId: number
  AccountName: string
}

interface INoNavFrameProps {
  title: string
  children: React.ReactNode
}

function decodeUnsubscribeJwt(unsubscribeJwt: string): IUnsubscribe {
  try {
    const jwt = JwtDecode<IUnsubscribe>(unsubscribeJwt);
    const {
      email,
      AccountId,
      AccountName,
    } = jwt;
    return {
      email,
      AccountId,
      AccountName,
    };
  } catch (err) {
    return null;
  }
}

function NoNavFrame({ title = 'Unsubscribe', children }: INoNavFrameProps): ReactElement {
  return (
    <div className="Login">
      <div className="Login-form">
        <div className="form-container">
          {children}
        </div>
        <div className="copyright label">
          &copy;&nbsp;
          {new Date().getFullYear()}
          {' '}
          Icon. All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default function Unsubscribe():ReactElement {
  return (
    <NoNavFrame title="Unsubscribe">
      <UnsubscribeComponent />
    </NoNavFrame>
  );
}

function UnsubscribeComponent(): ReactElement {
  const history = useHistory();
  const [unsubscribeEmailJwt] = useQueryParam<string>('unsubscribeEmailJwt');
  const [jwtObject, setJwtObject] = useState<IUnsubscribe>(null);
  const [showUnsubscribeScreen, setshowUnsubscribeScreen] = useState<boolean>(false);
  const { data: hadUnsubscribed, isLoading } = useSWRImmutable(
    jwtObject && ['unsubscribeEmailAddress', unsubscribeEmailJwt],
    () => UserInfoApi.getIsUnsubscribe(unsubscribeEmailJwt),
  );

  const unsubscribeEmailAddress = async (url, { arg }: { arg: string }) => {
    await UserInfoApi.unsubscribeEmailAddress(arg);
  }

  const resubscribeEmailAddress = async (url, { arg }: { arg: string }) => {
    await UserInfoApi.resubscribeEmailAddress(arg);
  }

  const { isMutating, trigger } = useSWRMutation(
    jwtObject && ['unsubscribeEmailAddress', unsubscribeEmailJwt],
    unsubscribeEmailAddress,
    {
      revalidate: false,
      populateCache: false,
      onSuccess: () => {
        setshowUnsubscribeScreen(false);
      },
    });

  const { isMutating: isResubscribeMutating, trigger: resubscribeTrigger } = useSWRMutation(
    jwtObject && ['resubscribeEmailAddress', unsubscribeEmailJwt],
    resubscribeEmailAddress,
    {
      revalidate: false,
      populateCache: false,
      onSuccess: () => {
        setshowUnsubscribeScreen(true);
      },
    });

  useEffect(() => {
    if (!unsubscribeEmailJwt) {
      return;
    }
    const obj = decodeUnsubscribeJwt(unsubscribeEmailJwt);
    if (obj === null) {
      return;
    }
    setJwtObject(obj);
    setshowUnsubscribeScreen(!hadUnsubscribed);
  }, [unsubscribeEmailJwt, history, hadUnsubscribed]);
  const [, , removeCookie] = useCookies(['VFHideNav']);

  const unsubscribe = async () => {
    removeCookie('VFHideNav');
    trigger(unsubscribeEmailJwt);
  };

  const resubscribe = async () => {
    removeCookie('VFHideNav');
    resubscribeTrigger(unsubscribeEmailJwt);
  };

  const unsubscribeMessage = jwtObject?.AccountName
    ? `Are you sure you want to unsubscribe from all marketing emails from "${jwtObject?.AccountName}"?`
    : 'Are you sure you want to unsubscribe?';

  const resubscribeMessage = jwtObject?.AccountName
    ? `You have unsubscribed from all marketing emails from "${jwtObject?.AccountName}"`
    : 'You have unsubscribed from all marketing emails';

  return (
    <>
      {isLoading && (
        <Spinner
          animation="border"
          variant="primary"
          role="status"
          className="d-block ml-3"
        />
      )}
      {jwtObject && !isLoading
    && (
      <div className="unsubscribeEmail">
        {!showUnsubscribeScreen && (
          <>
            <div className="content">
              {resubscribeMessage}
            </div>
            <div className="content">
              {jwtObject?.email}
            </div>
            <SubmitButton
              label="Click here to Resubscribe"
              savingLabel="Resubscribing..."
              className="btn btn-primary resubscribeButton"
              saveComplete={!isResubscribeMutating}
              onClick={resubscribe}
              type="button"
            />
          </>
        )}

        {showUnsubscribeScreen && (
          <>
            <div className="title">
              We&apos;re sorry to see you go.
            </div>
            <div className="content">
              {unsubscribeMessage}
            </div>
            <div className="content">
              {jwtObject?.email}
            </div>

            <SubmitButton
              label="Confirm Unsubscribe"
              savingLabel="Unsubscribing..."
              className="btn btn-primary unsubscribeButton"
              saveComplete={!isMutating}
              onClick={unsubscribe}
              type="button"
            />
            <div className="description">
              You will be unsubscribed from marketing emails, but not internal messages or broadcasts.
            </div>
          </>
        )}
      </div>
    )}
      {!jwtObject && (
        <div className="unsubscribeEmail">
          <div className="title errors">
            The link is invalid.
          </div>
        </div>
      )}
    </>
  )
}
