import React, {
  forwardRef, useState, useRef, useMemo,
} from 'react';
import clsx from 'clsx';
import { DayPickerSingleDateController, DayPickerSingleDateControllerShape } from 'react-dates';
import moment from 'moment';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';

import { ReactComponent as Calendar } from 'styles/images/calendar.svg';
import Input, { InputProps } from './Input';

export interface DateInputProps extends
  Omit<InputProps, 'type' | 'onChange' | 'onBlur'>,
  Partial<Omit<DayPickerSingleDateControllerShape, 'onBlur'>>
{
  onChange?: (val: string) => void
  onBlur?: () => void
  format?: string
}

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  ({
    className, onChange = () => {}, value, name, onFocus, onBlur, placeholder = '00/00/0000',
    initialVisibleMonth = null, hideKeyboardShortcutsPanel = true, format = 'MM/DD/YYYY', ...props
  }, forwardedRef) => {
    const [focused, setFocused] = useState(false);
    const createdRef = useRef<HTMLInputElement>();
    const ref = forwardedRef ?? createdRef;

    const date = useMemo(() => {
      if (!value) {
        return null;
      }
      const res = moment(value, format, true);
      return res.isValid() ? res : null;
    }, [value, format]);

    const onDateChange = (newDate: moment.Moment) => {
      onChange(newDate.format(format))
    }

    const dayPickerProps = {
      hideKeyboardShortcutsPanel,
      initialVisibleMonth,
      ..._pick(props, [
        'renderWeekHeaderElement',
        'enableOutsideDays',
        'numberOfMonths',
        'withPortal',
        'daySize',
        'noBorder',
        'verticalBorderSpacing',
        'horizontalMonthPadding',
        'dayPickerNavigationInlineStyles',
        'navPosition',
        'navPrev',
        'navNext',
        'renderNavPrevButton',
        'renderNavNextButton',
        'noNavButtons',
        'noNavNextButton',
        'noNavPrevButton',
        'onOutsideClick',
        'renderCalendarDay',
        'renderDayContents',
        'renderCalendarInfo',
        'calendarInfoPosition',
        'firstDayOfWeek',
        'verticalHeight',
        'transitionDuration',
        'isFocused',
        'showKeyboardShortcuts',
        'onTab',
        'onShiftTab',
        'monthFormat',
        'weekDayFormat',
        'phrases',
        'dayAriaLabelFormat',
        'isRTL',
        'minDate',
        'maxDate',
        'onClose',
        'keepOpenOnDateSelect',
        'isOutsideRange',
        'isDayBlocked',
        'isDayHighlighted',
        'orientation',
        'onPrevMonthClick',
        'onNextMonthClick',
      ]),
    }

    const inputProps = _omit(props, Object.keys(dayPickerProps));

    const InputIcon = React.memo(({ className: _className }: { className: string}) => (
      <Calendar
        className={_className}
        onClick={() => {
          if (typeof ref === 'object') {
            ref.current?.focus();
          }
        }}
      />
    ));

    return (
      <div className="position-relative">
        <Input
          type="text"
          name={name}
          className={clsx('Date-Input', className)}
          icon={InputIcon}
          placeholder={placeholder}
          onFocus={(e) => {
            setFocused(true);
            if (onFocus) {
              onFocus(e);
            }
          }}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          ref={ref}
          {...inputProps}
        />

        {focused && (
          <DayPickerSingleDateController
            onDateChange={onDateChange}
            focused={focused}
            date={date}
            onFocusChange={({ focused: isFocused }) => {
              if (!isFocused) {
                setFocused(false);
                if (onBlur) {
                  onBlur();
                }
              }
            }}
            onOutsideClick={(e) => {
              if (typeof ref === 'object' && ref.current.parentNode.contains(e.target as Node)) {
                return;
              }
              setFocused(false);
              if (onBlur) {
                onBlur();
              }
            }}
            {...dayPickerProps}
          />
        )}
      </div>
    )
  },
);

export default DateInput;
