import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as LabelsSvg } from 'styles/images/labels.svg';
import useSWR from 'swr/immutable';
import * as EntityLabelAPI from 'api/EntityLabelAPI';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Input from 'components/Inputs/Input';
import { IEntityLabel } from 'types/IEntityLabel';
import { Label } from 'components/Label';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/esm/Spinner';
import { useDebounce } from 'react-use';

interface CreateAssociationModalProps {
  onSave: (associations: IEntityLabel[]) => void
  onCancel: () => void
  onCreate: () => void
  templateLabels: IEntityLabel[]
}

export const CreateAssociationModal: React.FC<CreateAssociationModalProps> = ({
  onSave,
  onCancel,
  onCreate,
  templateLabels,
}) => {
  const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<IEntityLabel[]>([]);

  const [searchVal, setSearchVal] = React.useState('');
  const [debouncedSearchVal, setDebouncedSearchVal] = React.useState('');
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [val, setVal] = React.useState('');
  const [mutationInProgress, setMutationInProgress] = useState(false);

  useDebounce(
    () => {
      setDebouncedSearchVal(searchVal);
    },
    250,
    [searchVal],
  );

  useEffect(() => {
    setVal('');
  }, [showCreate]);

  const swrKey = ['entityLabels', debouncedSearchVal];

  const { data: entityLabels, mutate, isValidating } = useSWR(
    swrKey,
    () => EntityLabelAPI.entityLabelsGet(encodeURIComponent(searchVal.trim())),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const handleCreate = async () => {
    try {
      await EntityLabelAPI.entityLabelCreate([{ Name: val }]);
      setShowCreate(false);
      mutate();
    } catch (e) {
      toast.error(e.response.data.Message);
    } finally {
      setMutationInProgress(false);
    }
  }

  const handleSave = () => {
    if (selectedLabels && selectedLabels.length > 20) {
      toast.error('A maximum of 20 labels can be associated');
      return;
    }
    onSave(selectedLabels);
  }

  useEffect(() => {
    if (templateLabels) {
      setSelectedLabels(templateLabels);
      setSelectedLabelIds(templateLabels.map((l) => l.EntityLabelId));
    } else {
      setSelectedLabels([]);
      setSelectedLabelIds([]);
    }
  }, [templateLabels]);

  const onAvailableLabelChecked = useCallback((label: IEntityLabel): void => {
    if (selectedLabelIds.includes(label.EntityLabelId)) {
      setSelectedLabelIds(selectedLabelIds.filter((id) => id !== label.EntityLabelId));
      setSelectedLabels(selectedLabels.filter(({ EntityLabelId }) => (
        EntityLabelId !== label.EntityLabelId
      )));
    } else {
      setSelectedLabelIds([...selectedLabelIds, label.EntityLabelId]);
      setSelectedLabels([...selectedLabels, label]);
    }
  }, [selectedLabels, selectedLabelIds, setSelectedLabelIds]);

  return (
    <Modal
      show
      onHide={onCancel}
      className="view-labels-modal"
    >
      <Modal.Header
        closeButton
      >
        <LabelsSvg />
        <Modal.Title>
          Labels
        </Modal.Title>
        {isValidating && (
          <Spinner
            animation="border"
            variant="primary"
            role="status"
            className="d-block ml-3"
          />
        )}
      </Modal.Header>
      <Modal.Body>
        {!showCreate && (
          <div style={{ overflowY: 'auto', overflowX: 'hidden', height: 400 }}>
            <Input
              type="text"
              placeholder="Search label..."
              value={searchVal}
              onChange={(e) => {
                setSearchVal(e.target.value);
              }}
            />
            <div>
              {entityLabels && entityLabels.length > 0 && (
                entityLabels?.map((label) => (
                  <Row
                    key={label.EntityLabelId}
                    onClick={
                      () => onAvailableLabelChecked(label)
                    }
                  >
                    <Col xs={1}>
                      <input
                        type="checkbox"
                        className="align-middle ml-3 mt-2"
                        readOnly
                        checked={selectedLabelIds?.includes(label.EntityLabelId)}
                      />
                    </Col>
                    <Col className="d-flex align-items-center label-container" style={{ width: 300 }}>
                      <Label
                        name={label.Name}
                        showDelete={false}
                        onDelete={() => {}}
                        renderBody={(ref) => (
                          <span
                            className="label-text mt-1"
                            ref={ref}
                          >
                            {label.Name}
                          </span>
                        )}
                      />
                    </Col>
                  </Row>
                ))
              )}
              {(!entityLabels || entityLabels.length === 0) && (
                <Row>
                  <Col className="col-md-9 d-flex align-items-center label-container">
                    <span className="label-unmatch mt-1">No Matching Labels</span>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        )}
        {showCreate && (
          <Input
            type="text"
            placeholder="Enter label name"
            label="Please enter a new label name:"
            className="mb-0"
            value={val}
            onChange={(e) => {
              setVal(e.target.value);
            }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {!showCreate && (
          <>
            <button
              type="button"
              className="btn btn-cancel btn-dark square entity-button"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary square entity-button"
              onClick={handleSave}
            >
              Ok
            </button>
            <button
              type="button"
              className="btn btn-primary square entity-button"
              onClick={() => { setShowCreate(true) }}
            >
              Add New Label
            </button>
          </>
        )}
        {showCreate && (
          <div>
            <button
              type="button"
              className="btn btn-dark square"
              onClick={() => { setShowCreate(false) }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary square ml-2"
              onClick={handleCreate}
              disabled={!val.trim() || mutationInProgress}
            >
              Create
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}
