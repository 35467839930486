import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import {
  Input, Select, Textarea, Checkbox, DateInput, DateRangeInput,
} from 'components/FormControls';
import Search from 'components/Inputs/SearchInput';
import { Switch } from 'components/Inputs/Switch';
import Pagination from 'components/Pagination';

const R = ({ children }: any) => <div className="d-flex mb-2 align-items-center">{children}</div>;
const C = ({ children, w }: any) => <div style={{ flexBasis: w }}>{children}</div>
const H = ({ children }: any) => <h3 className="mb-4">{children}</h3>
const S = () => <div className="mt-4" />

const PaginationExample = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  return (
    <Pagination
      currentPage={currentPage}
      totalPages={46}
      onPageChange={setCurrentPage}
      pageDelta={5}
      style={{ marginTop: 0 }}
    />
  )
}

const ButtonGroupExample = () => {
  const [active, setActive] = useState(0);

  return (
    <div className="btn-group">
      <button
        type="button"
        className={clsx('btn btn-primary', active === 0 && 'active')}
        onClick={() => setActive(0)}
      >
        First Button
      </button>
      <button
        type="button"
        className={clsx('btn btn-primary', active === 1 && 'active')}
        onClick={() => setActive(1)}
      >
        Second Button
      </button>
      <button
        type="button"
        className={clsx('btn btn-primary', active === 2 && 'active')}
        onClick={() => setActive(2)}
      >
        Third Button
      </button>
    </div>
  )
}

const FormExample = () => {
  const { control, errors, setError } = useForm({
    mode: 'onChange',
  });
  const [searchInputValue, setSearchInputValue] = useState('');
  const [switchChecked, setSwitchChecked] = useState(true);

  useEffect(() => {
    setError('ih2', { type: 'manual', message: 'This field is invalid' });
    setError('th2', { type: 'manual', message: 'This field is invalid' });
    setError('field2', { type: 'manual', message: 'This field is invalid' });
    setError('select2', { type: 'manual', message: 'This field is invalid' });
    setError('textarea2', { type: 'manual', message: 'This field is invalid' });
    setError('dateinput2', { type: 'manual', message: 'This field is invalid' });
    setError('daterangeinput2', { type: 'manual', message: 'This field is invalid' });
  }, [setError]);

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <S />
      <Row className="mb-2">
        <Col className="d-flex align-items-center" style={{ maxWidth: '300px' }} />
        <Col>
          Default
        </Col>
        <Col>
          Invalid
        </Col>
        <Col>
          Disabled
        </Col>
      </Row>
      <H>Form:</H>
      <Row>
        <Col className="d-flex align-items-center" style={{ maxWidth: '300px' }}>
          Input
        </Col>
        <Col>
          <Input
            id="field1"
            label="Label"
            name="field1"
            control={control}
            type="text"
            placeholder="Placeholder"
            rules={{ required: 'This field is required' }}
            required
            errors={errors.field1?.message}
          />
        </Col>
        <Col>
          <Input
            id="field2"
            label="Label"
            name="field2"
            control={control}
            type="text"
            rules={{ validate: () => 'This field is invalid' }}
            placeholder="Placeholder"
            errors={errors.field2?.message}
          />
        </Col>
        <Col>
          <Input
            id="field3"
            label="Label"
            name="field3"
            control={control}
            type="text"
            disabled
            placeholder="Placeholder"
            errors={errors.field3?.message}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center" style={{ maxWidth: '300px' }}>
          Select
        </Col>
        <Col>
          <Select
            id="select1"
            label="Label"
            name="select1"
            control={control}
            placeholder="Placeholder"
            rules={{ required: 'This field is required' }}
            required
            errors={errors.select1?.message}
            options={[
              { label: 'Item 1', value: '1' },
              { label: 'Item 2', value: '2' },
              { label: 'Item 3', value: '3' },
            ]}
          />
        </Col>
        <Col>
          <Select
            id="select2"
            label="Label"
            name="select2"
            control={control}
            placeholder="Placeholder"
            rules={{ validate: () => 'This field is invalid' }}
            errors={errors.select2?.message}
            options={[
              { label: 'Item 1', value: '1' },
              { label: 'Item 2', value: '2' },
              { label: 'Item 3', value: '3' },
            ]}
          />
        </Col>
        <Col>
          <Select
            id="select3"
            label="Label"
            name="select3"
            control={control}
            placeholder="Placeholder"
            rules={{ required: 'This field is required' }}
            disabled
            errors={errors.select3?.message}
            options={[
              { label: 'Item 1', value: '1' },
              { label: 'Item 2', value: '2' },
              { label: 'Item 3', value: '3' },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center" style={{ maxWidth: '300px' }}>
          Textarea
        </Col>
        <Col>
          <Textarea
            id="textarea1"
            label="Label"
            name="textarea1"
            control={control}
            placeholder="Placeholder"
            rules={{ required: 'This field is required' }}
            required
            errors={errors.textarea1?.message}
          />
        </Col>
        <Col>
          <Textarea
            id="textarea2"
            label="Label"
            name="textarea2"
            control={control}
            placeholder="Placeholder"
            rules={{ validate: () => 'This field is invalid' }}
            required
            errors={errors.textarea2?.message}
          />
        </Col>
        <Col>
          <Textarea
            id="textarea3"
            label="Label"
            name="textarea3"
            control={control}
            placeholder="Placeholder"
            disabled
            errors={errors.textarea3?.message}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center" style={{ maxWidth: '300px' }}>
          Date Input
        </Col>
        <Col>
          <DateInput
            id="dateinput1"
            label="Label"
            name="dateinput1"
            control={control}
            rules={{ required: 'This field is required' }}
            errors={errors.dateinput1?.message}
            required
          />
        </Col>
        <Col>
          <DateInput
            id="dateinput2"
            label="Label"
            name="dateinput2"
            control={control}
            rules={{ validate: () => 'This field is invalid' }}
            errors={errors.dateinput2?.message}
            required
          />
        </Col>
        <Col>
          <DateInput
            id="dateinput3"
            label="Label"
            name="dateinput3"
            control={control}
            errors={errors.dateinput3?.message}
            disabled
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center" style={{ maxWidth: '300px' }}>
          Date Range Input
        </Col>
        <Col>
          <DateRangeInput
            id="daterangeinput1"
            label="Label"
            name="daterangeinput1"
            control={control}
            rules={{ required: 'This field is required' }}
            errors={errors.daterangeinput1?.message}
            required
          />
        </Col>
        <Col>
          <DateRangeInput
            id="daterangeinput2"
            label="Label"
            name="daterangeinput2"
            control={control}
            rules={{ validate: () => 'This field is invalid' }}
            errors={errors.daterangeinput2?.message}
            required
          />
        </Col>
        <Col>
          <DateRangeInput
            id="daterangeinput3"
            label="Label"
            name="daterangeinput3"
            control={control}
            errors={errors.daterangeinput3?.message}
            disabled
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col className="d-flex align-items-center" style={{ maxWidth: '300px' }}>
          Switch
        </Col>
        <Col>
          <Switch
            id="switch1"
            name="switch1"
            checked={switchChecked}
            onChange={(e) => {
              setSwitchChecked(e.target.checked);
            }}
          />
        </Col>
        <Col>
          -
        </Col>
        <Col>
          <Switch
            name="switch2"
            checked
            onChange={() => {}}
            disabled
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col className="d-flex align-items-center" style={{ maxWidth: '300px' }}>
          Checkbox
        </Col>
        <Col>
          <Checkbox
            id="check1"
            name="check1"
            label="Option 1"
            control={control}
            defaultValue
          />
        </Col>
        <Col>
          -
        </Col>
        <Col>
          <Checkbox
            id="check2"
            name="check2"
            label="Option 1"
            control={control}
            defaultValue
            disabled
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center" style={{ maxWidth: '300px' }}>
          Input with highlights
        </Col>
        <Col>
          <Input
            highlights={['<Replace Text Here>']}
            id="ih1"
            label="Label"
            name="ih1"
            control={control}
            type="text"
            placeholder="Placeholder"
            rules={{ required: 'This field is required' }}
            required
            errors={errors.ih1?.message}
          />
        </Col>
        <Col>
          <Input
            highlights={['<Replace Text Here>']}
            id="ih2"
            label="Label"
            name="ih2"
            control={control}
            type="text"
            rules={{ validate: () => 'This field is invalid' }}
            placeholder="Placeholder"
            errors={errors.ih2?.message}
          />
        </Col>
        <Col>
          <Input
            highlights={['<Replace Text Here>']}
            id="ih3"
            label="Label"
            name="ih3"
            control={control}
            type="text"
            disabled
            placeholder="Placeholder"
            errors={errors.ih3?.message}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center" style={{ maxWidth: '300px' }}>
          Textarea with highlights
        </Col>
        <Col>
          <Textarea
            highlights={['<Replace Text Here>']}
            id="th1"
            label="Label"
            name="th1"
            control={control}
            placeholder="Placeholder"
            rules={{ required: 'This field is required' }}
            required
            errors={errors.th1?.message}
          />
        </Col>
        <Col>
          <Textarea
            highlights={['<Replace Text Here>']}
            id="th2"
            label="Label"
            name="th2"
            control={control}
            placeholder="Placeholder"
            rules={{ validate: () => 'This field is invalid' }}
            required
            errors={errors.th2?.message}
          />
        </Col>
        <Col>
          <Textarea
            highlights={['<Replace Text Here>']}
            id="th3"
            label="Label"
            name="th3"
            control={control}
            placeholder="Placeholder"
            disabled
            errors={errors.th3?.message}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center" style={{ maxWidth: '300px' }}>
          Search Input + Primary Button Square + Link Button
        </Col>
        <Col>
          <div className="d-flex align-items-end">
            <Search
              name="searchInput"
              label="Search Template"
              placeholder="Name, tag or editor"
              className="mb-0"
              value={searchInputValue}
              onChange={(e) => {
                setSearchInputValue(e.target.value);
              }}
            />
            <button
              type="button"
              className="btn btn-md btn-primary square ml-3"
            >
              Search
            </button>
            <button
              type="button"
              className="btn btn-md btn-link ml-3 px-0"
            >
              Cancel
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

const DesignSystem = () => (
  <div style={{
    padding: '40px', overflowY: 'auto', height: '100%', background: 'white',
  }}
  >
    <R>
      <C w="300px" />
      <C w="300px">
        Default
      </C>
      <C w="300px">
        Hover
      </C>
      <C w="300px">
        Disabled
      </C>
    </R>

    <H>Buttons:</H>

    <R>
      <C w="300px">
        Primary Button (.btn-primary)
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-primary"
        >
          Click me
        </button>
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-primary"
        >
          Click me
        </button>
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-primary"
          disabled
        >
          Click me
        </button>
      </C>
    </R>

    <R>
      <C w="300px">
        Warning Button (.btn-warning)
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-warning"
        >
          Click me
        </button>
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-warning"
        >
          Click me
        </button>
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-warning"
          disabled
        >
          Click me
        </button>
      </C>
    </R>

    <R>
      <C w="300px">
        Secondary Button (.btn-secondary)
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-secondary"
        >
          Click me
        </button>
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-secondary"
        >
          Click me
        </button>
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-secondary"
          disabled
        >
          Click me
        </button>
      </C>
    </R>

    <R>
      <C w="300px">
        Link Button (.btn-link)
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-link"
        >
          Click me
        </button>
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-link"
        >
          Click me
        </button>
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-link"
          disabled
        >
          Click me
        </button>
      </C>
    </R>
    <R>
      <C w="1000px">
        <i>
          *For making any of up-mentioned buttons square, add .square class to it.
          <br />
          **For square buttons same hover, disabled states apply.
        </i>
      </C>
    </R>

    <S />
    <H>Links:</H>

    <R>
      <C w="300px">
        Link (a)
      </C>
      <C w="300px">
        <a href="#defaultLink" id="defaultLink">Click me</a>
      </C>
      <C w="300px">
        <a href="#hoverLink" id="hoverLink">Click me</a>
      </C>
      <C w="300px">
        -
      </C>
    </R>

    <S />
    <H>Button sizes:</H>

    <R>
      <C w="300px">
        Small (Default) (.btn-sm)
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-primary"
        >
          Click me
        </button>
      </C>
    </R>
    <R>
      <C w="300px">
        Medium (.btn-md)
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-md btn-primary"
        >
          Click me
        </button>
      </C>
    </R>
    <R>
      <C w="300px">
        Large (.btn-lg)
      </C>
      <C w="300px">
        <button
          type="button"
          className="btn btn-lg btn-primary"
        >
          Click me
        </button>
      </C>
    </R>

    <S />
    <H>Button groups:</H>

    <R>
      <C w="300px">
        Button group (.btn-group)
      </C>
      <C w="500px">
        <ButtonGroupExample />
      </C>
    </R>

    <S />
    <H>Pagination:</H>
    <PaginationExample />

    <FormExample />
  </div>

)

export default DesignSystem;
