import React, { useState, FC, ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import _difference from 'lodash/difference';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useQueryParam, NumberParam, StringParam, BooleanParam,
} from 'use-query-params';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { useSWRConfig } from 'swr';

import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { getGroupsIndex } from 'reducers/Groups';
import {
  deleteBatchTemplate, templateSetByIds, selectTemplateIsLoading,
} from 'reducers/Templates';
import { hasError } from 'reducers/Error';
import { DeleteActionModal } from 'components/DeleteActionModal';
import { ITemplate } from 'types/IEvent';
import { BulkActions } from 'components/BulkActions';
import Pagination from 'components/Pagination';
import Table from 'components/Table';
import { LabelsCell } from 'components/LabelsCell';
import { useTemplatesQuery } from 'components/hooks/useTemplatesQuery';
import { TemplateSendNowModal } from 'components/TemplateSendNowModal';
import { TemplateSendValidationModal } from 'components/TemplateSendValidationModal';
import { useHasFeatures } from 'hooks/useHasFeatures';

export const validateRecipientCount = (template: ITemplate) => (
  !!template.GroupIds?.length || !!template.ProfileIds?.length
);

export const validateEmail = (template: ITemplate) => {
  if (template.EmailContent) {
    if (template.EmailContent.From === ''
        || template.EmailContent.Display === ''
        || template.EmailContent.ReplyTo === '') {
      return false;
    }
  }
  return true;
}

type TemplateTableProps = {
  showActions?: boolean
  selectButton?: string
  templateFilterType?: 'template'|'transactional'
};

export const TemplateTable: FC<TemplateTableProps> = ({
  showActions,
  selectButton,
  templateFilterType,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { mutate: globalMutate } = useSWRConfig();
  const isMarketing = location.pathname.startsWith('/marketing-templates');

  const columns = [
    {
      Header: 'Name', accessor: 'Name', sortable: true, showToolTip: true,
    },
    {
      Header: 'Labels', accessor: 'Labels', Cell: LabelsCell,
    },
    {
      Header: 'Description', accessor: 'Description', sortable: true, showToolTip: true,
    },
  ].filter(Boolean);

  const [page = 1, setPage] = useQueryParam('page', NumberParam);
  const [sortField, setSortField] = useQueryParam('sortField', StringParam);
  const [sortDirection, setSortDirection] = useQueryParam('sortDirection', StringParam);
  const [isDraftParam = false] = useQueryParam('isDraft', BooleanParam);

  const [itemToDeleteIds, setItemToDeleteIds] = useState<string[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  useUpdateEffect(() => {
    setSelectedRowIds([]);
  }, [location.search])

  const canCreateCalendarAnnouncement = useHasPermissions('Calendar Announcement:Create');
  const [
    hasReadMarketingPermission, hasWriteMarketingPermission,
  ] = useHasPermissions(['Marketing:Send', 'Marketing:Edit']);
  const { hasFeature: hasMarketingFeature } = useHasFeatures('marketing-emails');
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);

  const canScheduleEvent = canCreateCalendarAnnouncement || isAdmin || isSuper;
  const canReadMarketing = (hasReadMarketingPermission || isAdmin || isSuper) && hasMarketingFeature;
  const canWriteMarketing = (hasWriteMarketingPermission || isAdmin || isSuper) && hasMarketingFeature;

  const [showSendNowModal, setShowSendNowModal] = useState<boolean>(false);
  const [sendValidationErrors, setSendValidationErrors] = React.useState<null|Error[]>(null);
  const [sendNowTemplateId, setSendNowTemplateId] = useState<number|null>(null);
  const [scheduleTemplateId, setScheduleTemplateId] = useState<number|null>(null);

  const isTemplateDeleting = useSelector(selectTemplateIsLoading);

  const { data, isLoading, mutate } = useTemplatesQuery(
    templateFilterType,
    {
      onSuccess: (_data) => {
        dispatch(templateSetByIds(_data.Data))
      },
    });

  const templates = data?.Data ?? [];

  React.useEffect(() => {
    dispatch(getGroupsIndex());
  }, [dispatch]);

  const handleDelete = () => {
    const updateCache = async (deletedItems: number[]) => {
      await Promise.all([
        ...deletedItems.map(
          (deletedTemplateId) => globalMutate(
            ['template', deletedTemplateId],
            undefined,
            { populateCache: true, revalidate: false },
          ),
        ),
        mutate((currentTemplatesPage) => {
          if (!currentTemplatesPage) {
            return currentTemplatesPage;
          }

          const optimisticTemplates = currentTemplatesPage.Data.filter((temp) => !deletedItems.includes(temp.ID));

          if (!optimisticTemplates.length) {
            return undefined;
          }

          return {
            ...currentTemplatesPage,
            Data: optimisticTemplates,
          }
        }, true),
      ])
    }

    const updateSelectedRowIds = (undeletedItems = []) => {
      const deletedItems = _difference(itemToDeleteIds, undeletedItems);
      setSelectedRowIds(_difference(selectedRowIds, deletedItems));
      updateCache(deletedItems);
    }

    setItemToDeleteIds([]);
    dispatch(deleteBatchTemplate(itemToDeleteIds, isMarketing, (errors) => {
      if (errors) {
        errors.forEach((err) => {
          dispatch(hasError(err));
        })
        updateSelectedRowIds(errors.map((err) => err.id).filter(Boolean));
      } else {
        let entityName;
        if (isDraftParam) {
          entityName = 'Draft';
        } else if (isMarketing) {
          entityName = 'Marketing Email';
        } else {
          entityName = 'Template';
        }

        toast.success(`${entityName}${itemToDeleteIds.length > 1 ? 's' : ''} successfully deleted`,
        );
        updateSelectedRowIds();
      }
    }));
  }

  const renderBulkActions = () => {
    if (selectedRowIds.length === 0) {
      return null;
    }

    return (
      <BulkActions
        className="ml-3"
        items={[
          {
            label: 'Delete',
            handler: () => {
              setItemToDeleteIds([...selectedRowIds]);
            },
            className: 'delete-action',
          }]}
      />
    )
  }

  const validateTemplateToSend = (template: ITemplate) => {
    const errors = [];

    if (!validateRecipientCount(template)) {
      errors.push(new Error('Please select at least one profile or group to continue'));
    }
    if (!validateEmail(template)) {
      errors.push(new Error('Email content must have email from address, sender name and reply to address.'));
    }

    if (errors.length) {
      setSendValidationErrors(errors);
    }

    return !errors.length;
  }

  const rowBulkActionItems = (entityId: string, entity: ITemplate): ReactElement => {
    const actionItems = [];
    if (
      (isMarketing ? canReadMarketing : canScheduleEvent)
      && templateFilterType === 'template'
      && !isDraftParam
    ) {
      actionItems.push({
        label: 'Send Now',
        handler: () => {
          setSendNowTemplateId(entity.ID);

          if (!validateTemplateToSend(entity)) {
            return;
          }

          setShowSendNowModal(true);
        },
      })
      actionItems.push({
        label: 'Schedule',
        handler: () => {
          setScheduleTemplateId(entity.ID);

          if (!validateTemplateToSend(entity)) {
            return;
          }

          history.push(
            '/events/create',
            { sourceTemplateId: entity.ID },
          );
        },
      })
    }

    if (!isMarketing || canWriteMarketing) {
      actionItems.push({
        label: 'Edit',
        handler: () => {
          history.push(isMarketing
            ? `/marketing-templates/${entityId}/edit`
            : `/templates/${entityId}/edit/${templateFilterType}`);
        },
      });

      actionItems.push({
        label: 'Clone',
        handler: () => {
          history.push({
            pathname: isMarketing
              ? '/marketing-templates/new'
              : '/templates/new',
            state: { sourceTemplateId: entity.ID, sourceTemplateType: entity.Type },
          });
        },
      });

      actionItems.push({
        label: 'Delete',
        handler: () => {
          setItemToDeleteIds([entityId]);
        },
        className: 'delete-action',
      });
    }

    return (
      <BulkActions
        className="ml-3"
        items={actionItems}
      />
    );
  };

  const renderDeleteActionModalChildren = () => {
    if (!itemToDeleteIds.length || itemToDeleteIds.length > 1) {
      return null;
    }

    const templateToDeleteId = +itemToDeleteIds[0];
    const templateToDelete = templates.find((item) => item.ID === templateToDeleteId);

    return (
      <p>
        The
        {' '}
        {isMarketing ? 'marketing email' : 'template'}
        {' '}
        &quot;
        {templateToDelete?.Name ?? ''}
        &quot; will be deleted.
      </p>
    )
  }

  return (
    <div>
      <DeleteActionModal
        isOpen={itemToDeleteIds.length !== 0}
        title="Are you sure?"
        onCancel={() => setItemToDeleteIds([])}
        onSuccess={handleDelete}
        isDeleting={isTemplateDeleting}
      >
        {renderDeleteActionModalChildren()}
      </DeleteActionModal>

      {sendValidationErrors && (
        <TemplateSendValidationModal
          templateId={scheduleTemplateId || sendNowTemplateId}
          errors={sendValidationErrors}
          onHide={() => {
            setSendValidationErrors(null);
            if (scheduleTemplateId) {
              setScheduleTemplateId(null);
              return;
            }
            if (sendNowTemplateId) {
              setSendNowTemplateId(null);
            }
          }}
        />
      )}

      {showSendNowModal && (
        <TemplateSendNowModal
          templateId={sendNowTemplateId}
          onHide={() => {
            setShowSendNowModal(false);
            setSendNowTemplateId(null);
          }}
        />
      )}

      <div className="bulkActionsRow">
        <div className="itemCount">
          {isLoading
            ? (
              <div className="loading-text d-flex align-items-center">
                <Spinner
                  animation="border"
                  variant="primary"
                  role="status"
                  className="mr-3"
                />
                Loading...
              </div>
            ) : (
              <>
                {data?.Pagination?.TotalItems ?? 0}
                {' '}
                Item(s) In Total
              </>
            )}
        </div>
        {renderBulkActions()}
      </div>
      <Table
        columns={columns}
        data={templates}
        enableCheck={!isMarketing || canWriteMarketing}
        selectButton={selectButton}
        rowIDAccessor="ID"
        selectedRowIds={selectedRowIds}
        onRowSelect={(selectedIds) => {
          setSelectedRowIds(selectedIds);
        }}
        isLoading={isLoading}
        rowActionItems={showActions ? rowBulkActionItems : undefined}
        onColumnSort={(fieldName, direction) => {
          if (fieldName.length > 0) {
            setSortField(fieldName);
            setSortDirection(direction);
          } else {
            setSortField('');
            setSortDirection('');
          }
        }}
        sortedColumn={sortField}
        sortedColumnDirection={sortDirection}
        className="Template-Table"
      />

      <Pagination
        currentPage={page}
        totalPages={data?.Pagination?.TotalPages ?? 1}
        onPageChange={(newPage: number) => setPage(newPage, 'pushIn')}
        pageDelta={5}
      />
    </div>
  );
};
