import useSWR, { SWRConfiguration, Fetcher } from 'swr';
import {
  useQueryParam, NumberParam, StringParam,
  BooleanParam,
} from 'use-query-params';

import { IndexPayload } from 'types/request';
import * as BroadcastFormApi from 'api/BroadcastFormAPI';
import { IEbfGeneratorsPayload } from 'types/BroadcastForm';
import React from 'react';
import { ISearchQuery } from 'components/Inputs/IndexFilter_Id_Name';

export const useEbfGeneratorsQuery = (
  options: SWRConfiguration<
  IndexPayload<IEbfGeneratorsPayload>, any, Fetcher<IndexPayload<IEbfGeneratorsPayload>> | undefined
  > = {},
) => {
  const [pageParam = 1] = useQueryParam('page', NumberParam);
  const [perPageParam = 10] = useQueryParam('perpage', NumberParam);
  const [sortFieldParam] = useQueryParam('sortField', StringParam);
  const [sortDirectionParam] = useQueryParam('sortDirection', StringParam);
  const [searchQueryParam] = useQueryParam('searchQuery', StringParam);
  const [isActiveParam] = useQueryParam('isActive', BooleanParam);

  const searchQuery = React.useMemo((): ISearchQuery => {
    if (!searchQueryParam) {
      return {};
    }
    const [searchField, searchValue] = searchQueryParam.split(',');
    return { searchField, searchValue }
  }, [searchQueryParam]);

  const trimmedSearchValue = searchQuery.searchValue?.trim();

  return useSWR<IndexPayload<IEbfGeneratorsPayload>>([
    'ebf-generators',
    pageParam,
    perPageParam,
    (searchQuery.searchField && trimmedSearchValue) ? searchQuery.searchField : undefined,
    (searchQuery.searchField && trimmedSearchValue) ? trimmedSearchValue : undefined,
    (sortFieldParam && sortDirectionParam) ? sortFieldParam : undefined,
    (sortFieldParam && sortDirectionParam) ? sortDirectionParam : undefined,
    isActiveParam ?? true,
  ], ([
    ,
    page,
    perpage,
    searchField,
    searchValue,
    sortField,
    sortDirection,
    isActive,
  ]) => BroadcastFormApi.getEbfGenerators({
    page,
    perpage,
    searchField,
    searchValue,
    sortField,
    sortDirection,
    isActive,
  }), options)
}
