import React, {
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import Input from 'components/Inputs/Input';
import {
  Col, Container, ListGroup, Row,
} from 'react-bootstrap';
import useSWR from 'swr';
import { selectCurrentAccountId } from 'reducers/UserInfo';
import { useSelector } from 'react-redux';
import * as UserInfoAPI from 'api/UserInfoAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Prompt } from 'react-router-dom';

const domainPattern = /^[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/i;

function ReservedDomainsPage(): ReactElement {
  const accountId = useSelector(selectCurrentAccountId)
  const [domainName, setDomainName] = useState('');
  const [domainList, setDomainList] = useState<string[]>([]);

  const { data: dbReservedDomains, mutate, isLoading } = useSWR(
    ['reserved-domains', accountId],
    () => UserInfoAPI.getSenderReservedDomain(),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const messageObj = {
    title: 'Reserved Domain Changes',
    messageText: 'You have unsaved changes, are you sure you want to leave?',
  };

  const sortedDbDomains = useMemo(() => dbReservedDomains?.sort((a, b) => a.localeCompare(b)), [dbReservedDomains]);
  const sortedNewDomains = useMemo(() => domainList?.sort((a, b) => a.localeCompare(b)), [domainList]);
  // only show the save button when the new domain list is different from the orignial one in database.
  const saveEnabled = useMemo(() => JSON.stringify(sortedDbDomains) !== JSON.stringify(sortedNewDomains), [sortedDbDomains, sortedNewDomains]);
  const [isSaving, setIsSaving] = useState<boolean>();

  useEffect(() => {
    setDomainList(dbReservedDomains);
  }, [dbReservedDomains]);

  const saveDisabled = !saveEnabled || isSaving;

  const addToDomainList = (domain): boolean => {
    if (domain.trim() === '') {
      return false;
    }

    if (domain.length > 253) {
      toast.error('The length of domain name should not be longer than 253 characters.');
      return false;
    }

    if (!domainPattern.test(domain)) {
      toast.error(`'${domain}' is not a valid domain name.`);
      return false;
    }

    // deduplicate case insensetivily
    if (!domainList.some((d) => d.toLowerCase() === domain.toLowerCase())) {
      setDomainList(domainList.concat(domain));
    } else {
      toast.error(`'${domain}' has already been added to the list.`);
    }

    return true;
  };

  const onRemoveDomain = (domain) => {
    if (domainList.includes(domain)) {
      const newDomains = domainList.filter((d) => d !== domain);
      setDomainList(newDomains);
    }
  }

  const onSave = async () => {
    try {
      setIsSaving(true);
      const savedDomains = await UserInfoAPI.setSenderReservedDomain(domainList);
      setDomainList(savedDomains);
      mutate(savedDomains, {
        populateCache: true, // populate cache with saved domains
        revalidate: false, // do not refetch
      });
      toast.success('Reserved domain successfully saved.');
    } catch (error) {
      toast.error(error.response.data.Message);
    } finally {
      setIsSaving(false);
    }
  }

  const renderDomainList = function (): ReactElement {
    if (isLoading) {
      return (
        <ListGroup>
          <ListGroup.Item>
            <label className="domain-name">Loading...</label>
          </ListGroup.Item>
        </ListGroup>
      )
    }

    return (
      <ListGroup>
        {
          domainList && domainList.map((domain) => (
            <ListGroup.Item
              key={domain}
            >
              <button
                className="px-1 remove-recipient-btn account-list"
                type="button"
                onClick={() => {
                  onRemoveDomain(domain);
                }}
              >
                <FontAwesomeIcon
                  icon="times"
                  size="sm"
                />
              </button>
              <label className="domain-name" title={domain}>{domain}</label>
            </ListGroup.Item>
          ),
          )
        }
      </ListGroup>
    )
  }

  const renderSavedPermittedDomains = function (): ReactElement {
    if (!isLoading && (!dbReservedDomains || dbReservedDomains.length === 0)) {
      return (
        <div className="empty-domain">No Reserved Domains have been set.</div>
      )
    }
    return (
      <ListGroup>
        {
          dbReservedDomains && dbReservedDomains.map((domain) => (
            <ListGroup.Item
              key={domain}
            >
              <label className="domain-name" title={domain}>{domain}</label>
            </ListGroup.Item>
          ),
          )
        }
      </ListGroup>
    )
  }

  return (
    <>
      <Prompt
        when={saveEnabled}
        message={JSON.stringify(messageObj)}
      />
      <Helmet>
        <title>DKIM Reserved Domains</title>
      </Helmet>
      <div className="container reports">
        <h3>DKIM Reserved Domains</h3>
        <br />
        <p style={{ color: 'red' }}>
          Add DKIM registered domains here to protect their usage.
          Only accounts that are have been specifically configured will be able to send emails with these domains.
          To configure an account to use a reserved domain, switch to that account and in the Internal -
          Reserved Domains tab add the domain to the list of permitted domains for that account.
        </p>
        <br />
        <form>
          <Container fluid>
            <Row>
              <Col xs={5}>
                <Input
                  className="mb-0"
                  type="text"
                  label="Add sender reserved domain (enter to submit)"
                  placeholder="Email Domain"
                  value={domainName}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter') {
                      evt.preventDefault();
                      if (addToDomainList(domainName)) {
                        setDomainName('');
                      }
                    }
                  }}
                  onChange={(e) => {
                    setDomainName(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
              <Col xs={2}>
                <button
                  type="button"
                  className="btn btn-primary square mb-0 "
                  style={{ marginTop: 25 }}
                  onClick={() => {
                    if (addToDomainList(domainName)) {
                      setDomainName('');
                    }
                  }}
                >
                  Add
                </button>
              </Col>
            </Row>
            <Row>
              <Col xs={7} />
              <Col>
                <label
                  className="saved-caption"
                >
                  Currently saved reserved domains
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
                { renderDomainList() }
              </Col>
              <Col xs={2} />
              <Col>
                { renderSavedPermittedDomains() }
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col xs={5}>
                <button
                  type="button"
                  className="btn btn-primary square mb-0"
                  onClick={onSave}
                  disabled={saveDisabled}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  )
}

export default ReservedDomainsPage;
