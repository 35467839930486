import useSWR, { SWRConfiguration, Fetcher } from 'swr';

import { IEvent } from 'types/IEvent';
import * as EventsApi from 'api/EventsAPI';

export const useEventOccurrenceQuery = (
  eventId: number|undefined,
  occurrenceDate: string|undefined,
  {
    enabled = !!eventId && !!occurrenceDate,
    ...options
  }: SWRConfiguration<IEvent, any, Fetcher<IEvent>> & { enabled?: boolean } = {},
) => useSWR<IEvent>(
  enabled
    ? ['eventOccurrence', eventId, occurrenceDate]
    : null,
  ([, id, date]) => EventsApi.getEventInstanceByIdAndDate(id, date),
  options,
)
