/* eslint-disable no-param-reassign */
import React from 'react';
import escapeHtml from 'escape-html';

import { FroalaEditorComponent } from 'components/FroalaEditor';
import FileDragContext from 'lib/FileDragContext';
import { string2bin, bin2string } from 'utils/stringUtils';
import {
  getCursorPosition,
  setCursorPosition,
  PosStat,
} from 'utils/contentEditable';

const boldOne = (
  boldedSlice: string,
  notBoldedSlice: string,
  escapedTextToBold: string,
) => {
  if (!notBoldedSlice.length) {
    return boldedSlice;
  }

  const indexInSlice = notBoldedSlice.indexOf(escapedTextToBold);

  if (indexInSlice === -1) {
    return `${boldedSlice}${notBoldedSlice}`;
  }

  const piece = notBoldedSlice.slice(0, indexInSlice);
  const startIndexStrong = piece.lastIndexOf('<strong>');

  if (startIndexStrong === -1) {
    return boldOne(
      `${boldedSlice + piece}<strong>${escapedTextToBold}</strong>`,
      notBoldedSlice.slice(indexInSlice + escapedTextToBold.length),
      escapedTextToBold,
    );
  }

  const endIndexStrong = piece.lastIndexOf('</strong>');

  if (endIndexStrong === -1 || startIndexStrong > endIndexStrong) {
    return boldOne(
      boldedSlice + piece + escapedTextToBold,
      notBoldedSlice.slice(indexInSlice + escapedTextToBold.length),
      escapedTextToBold,
    );
  }

  return boldOne(
    `${boldedSlice + piece}<strong>${escapedTextToBold}</strong>`,
    notBoldedSlice.slice(indexInSlice + escapedTextToBold.length),
    escapedTextToBold,
  );
};

const bold = (htmlStr: string, textsToBold: string[]) => {
  if (!htmlStr) {
    return htmlStr;
  }

  const res = textsToBold.reduce((acc, textToBold) => {
    const escapedTextToBold = escapeHtml(textToBold);

    return boldOne('', acc, escapedTextToBold);
  }, htmlStr);

  return res;
};

interface EmailEditorProps {
  errors?: string
  onChange?: (...event: any[]) => void
  value: string | null
  onBlur?: () => void
  highlights?: string[]
  mergeFields?: 'default' | 'survey'
}

export const EmailEditor: React.FC<EmailEditorProps> = ({
  errors,
  onChange,
  value,
  onBlur,
  highlights,
  mergeFields = 'default',
}) => {
  const { isFileDraggedOnPage, setIsFileDraggedOnPage } = React.useContext(FileDragContext);
  const [isFileDraggedOnEditor, setIsFileDraggedOnEditor] = React.useState(false);

  let style = { border: 'transparent dotted' };
  if (isFileDraggedOnPage) {
    style = { border: 'gray dotted' };
  }
  if (isFileDraggedOnEditor) {
    style = { border: 'black dotted' };
  }

  const lastPosRef = React.useRef<PosStat | null>(null);
  const editorRef = React.useRef<FroalaEditorComponent | null>(null);

  React.useLayoutEffect(() => {
    const lastPos = lastPosRef.current;
    if (lastPos !== null) {
      // restore cursor position
      const sel = window.getSelection();
      const editorNode = editorRef.current.getEditor().$el[0];
      sel.removeAllRanges();
      const range = setCursorPosition(editorNode, document.createRange(), {
        pos: lastPos.pos,
        done: false,
      });
      range.collapse(true);
      sel.addRange(range);
      lastPosRef.current = null;
    }
  });

  const htmlValue = bin2string(value || '');
  const model = highlights ? bold(htmlValue, highlights) : htmlValue;

  return (
    <>
      <div style={style}>
        <FroalaEditorComponent
          ref={editorRef}
          config={{
            // env variables in react app MUST be
            // prefixed with 'REACT_APP_', eg: REACT_APP_SOME_VARIABLES
            key: process.env.REACT_APP_FROALA_EDITOR_LICENSE,
            height: 250,
            attribution: false,
            events: {
              initialized() {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const editor: any = this;
                editor.events.on(
                  'dragover',
                  (dropEvent: any) => {
                    setIsFileDraggedOnEditor(true);
                  },
                  true,
                );
                editor.events.on(
                  'dragleave',
                  (dropEvent: any) => {
                    setIsFileDraggedOnEditor(false);
                  },
                  true,
                );
                editor.events.on(
                  'drop',
                  (dropEvent: any) => {
                    setIsFileDraggedOnEditor(false);
                    setIsFileDraggedOnPage(false);
                  },
                  true,
                );
                editor.events.on('blur', () => {
                  onBlur();
                });
              },
            },
            fontFamily: {
              "'Arial'": 'Arial',
              "'Comic Sans Ms'": 'Comic Sans Ms',
              "'Courier New'": 'Courier New',
              "'Georgia'": 'Georgia',
              "'Lucida Sans Unicode'": 'Lucida Sans Unicode',
              "'Tahoma'": 'Tahoma',
              "'Times New Roman'": 'Times New Roman',
              "'Trebuchet Ms'": 'Trebuchet Ms',
              "'Verdana'": 'Verdana',
            },
            fileUpload: false,
            quickInsertEnabled: false,
            // Set the image upload parameter.
            imageUploadParam: 'upload',
            toolbarSticky: false,
            imagePaste: false,
            pastePlain: true,
            // Set the image upload URL.
            imageUploadURL: '/UserControls/ckConnector/vfuploader.aspx',

            // Additional upload params.
            imageUploadParams: {},

            // Set request type.
            imageUploadMethod: 'POST',

            // Set max image size to 5MB.
            imageMaxSize: 5 * 1024 * 1024,
            imageManagerPreloader: '/images/loader.gif',

            // Set page size.
            imageManagerPageSize: 20,
            // Set a scroll offset (value in pixels).
            imageManagerScrollOffset: 10,
            // Set the load images request URL.
            imageManagerLoadURL: '/UserControls/ckConnector/vfconnector.aspx',
            // Set the load images request type.
            imageManagerLoadMethod: 'GET',
            // Additional load params.
            imageManagerLoadParams: {},
            // Set the delete image request URL.
            imageManagerDeleteURL: '/api/v2/template/delete/image',
            // Set the delete image request type.
            imageManagerDeleteMethod: 'DELETE',
            // Additional delete params.
            // imageManagerDeleteParams: { param: 'value' },
            saveInterval: 0,
            // Allow to upload PNG and JPG.
            imageAllowedTypes: ['jpeg', 'jpg', 'png'],
            width: '100%',
            // Hide open in new tab option on link
            linkAlwaysBlank: true,
            toolbarButtons: {
              moreText: {
                buttons: [
                  'bold',
                  'underline',
                  'italic',
                  'textColor',
                  'backgroundColor',
                  'strikeThrough',
                  'fontFamily',
                  'fontSize',
                  'subscript',
                  'superscript',
                ],
                buttonsVisible: 8,
              },
              moreParagraph: {
                buttons: [
                  'formatOL',
                  'formatUL',
                  'outdent',
                  'indent',
                  'insertLink',
                  'alignLeft',
                  'alignCenter',
                  'alignRight',
                  'alignJustify',
                  'insertImage',
                  'insertTable',
                ],
                buttonsVisible: 5,
              },
              moreMisc: {
                buttons: [
                  'emoticons',
                  'specialCharacters',
                  'insertHR',
                  'selectAll',
                  'clearFormatting',
                  'undo',
                  'redo',
                ],
                buttonsVisible: 5,
              },
              orthers: {
                buttons: [
                  'html',
                  mergeFields === 'default'
                    ? 'merge_fields'
                    : 'merge_fields_survey',
                  'plainPaste',
                  'wordPaste',
                ],
              },
            },
          }}
          model={model}
          onModelChange={(changeVal) => {
            // to prevent cursor to jumping to start after html changes
            if (highlights && bold(changeVal, highlights) !== changeVal) {
              // save last position
              const selection = editorRef.current.getEditor().selection.get();
              const node = selection.focusNode;
              const offset = selection.focusOffset;
              const editorNode = editorRef.current.getEditor().$el[0];
              const pos = getCursorPosition(editorNode, node, offset, {
                pos: 0,
                done: false,
              });
              if (offset === 0) {
                pos.pos += 0.5;
              }
              lastPosRef.current = pos;
            }

            onChange(string2bin(changeVal));
          }}
        />
      </div>
      {!!errors && <span className="errors">{errors}</span>}
    </>
  );
};
