import React, { ReactElement } from 'react';

import { Helmet } from 'react-helmet';

function Error404Page(): ReactElement {
  return (
    <>
      <Helmet>
        <title>Not Found</title>
      </Helmet>

      <div className="header-bar">
        <h1>Not found (404)</h1>
      </div>
      <p>
        We couldn&apos;t find the item you were looking for. It may have moved or gotten
        deleted, use the navigation bar to explore and find what you&apos;re looking for.

      </p>
    </>
  );
}

export default Error404Page;
