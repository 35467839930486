import { IGroup } from 'types/IGroup';
import Input from 'components/FormControls/Input';
import {
  Modal, Row, Col,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { saveNewSubGroup } from 'reducers/Groups';
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';

interface IProps {
  parentGroup: IGroup
  showAddSubGroupModal: boolean
  setShowAddSubGroupModal: Dispatch<SetStateAction<boolean>>
}

function AddSubGroupActionButton(
  { parentGroup, showAddSubGroupModal, setShowAddSubGroupModal }: IProps,
): ReactElement {
  const {
    control, errors, handleSubmit,
  } = useForm<IGroup>({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const interceptSubmit = (groupFormData: IGroup): void => {
    segmentAnalyticsTrack(trackActions.createSubGroup());
    dispatch(saveNewSubGroup(parentGroup, groupFormData));
    setShowAddSubGroupModal(false);
  }
  return (
    <Modal
      show={showAddSubGroupModal}
      onHide={() => setShowAddSubGroupModal(false)}
      className="DeleteActionButtonModal"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <h4>Create New Sub-Group</h4>
      </Modal.Header>
      <Modal.Body>
        <hr />
        <form onSubmit={handleSubmit(interceptSubmit)} className="form ContentForm">
          <Row>
            <Col xs={1}>
              <span>Name</span>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Input
                id="Name"
                name="Name"
                type="text"
                control={control}
                errors={errors.Name && errors.Name.message}
                rules={{
                  required: true,
                  validate: (val: any) => val.length <= 70 || 'Group name must not exceed 70 characters',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3} />
            <Col>
              <button
                type="submit"
                className="btn btn-block btn-primary"
              >
                Create
              </button>
              <button
                type="button"
                className="btn btn-block btn-secondary"
                onClick={() => setShowAddSubGroupModal(false)}
              >
                Cancel
              </button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddSubGroupActionButton;
