import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import { ISurveyQuestion } from 'types/ISurveyQuestion';
import { ISurveyQuestionsPayload } from 'types/ISurveyQuestionsPayload';
import { IFilterableIndexParams } from 'types/ITemplatesIndexParams';
import { RootState } from 'types/rootState';
import { AppThunk } from '../app/appThunk';
import { hasError } from './Error';
import { ISurveyQuestionState } from './ISurveyQuestionState';

export const INITIAL_STATE: ISurveyQuestionState = {
  loading: false,
  surveyQuestions: [],
  byIds: {},
  pagedIds: {},
  pagination: {
    Page: 1,
    PerPage: 20,
    TotalItems: 0,
    TotalPages: 1,
  },
};

const surveyQuestionSchema = new schema.Entity('SurveyQuestions', {}, { idAttribute: 'Id' });

const SurveyQuestionSlice = createSlice({
  name: 'SurveyQuestions',
  initialState: INITIAL_STATE,
  reducers: {
    surveyQuestionRequestStarted(draftReducerState) {
      draftReducerState.loading = true;
    },
    surveyQuestionRequestFailed(draftReducerState) {
      draftReducerState.loading = false;
    },
    surveyQuestionRequestSuccess(draftReducerState, action: PayloadAction<ISurveyQuestion>) {
      draftReducerState.loading = false;
      draftReducerState.byIds[action.payload.ID] = action.payload;
    },
    surveyQuestionCreatedSuccess(draftReducerState, action: PayloadAction<ISurveyQuestion>) {
      draftReducerState.loading = false;
      draftReducerState.byIds[action.payload.ID] = action.payload;
    },
    surveyQuestionPayloadSuccess(draftReducerState, action: PayloadAction<ISurveyQuestion[]>) {
      draftReducerState.surveyQuestions = action.payload || [];
      draftReducerState.loading = false;
    },
    surveyQuestionIndexPayloadSuccess(
      draftReducerState,
      action: PayloadAction<ISurveyQuestionsPayload>,
    ) {
      const normalizedPayload = normalize(action.payload, {
        SurveyQuestions: [surveyQuestionSchema],
      });
      const surveyQuestionIds = normalizedPayload.result.SurveyQuestions;
      const {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      } = action.payload.Pagination;

      draftReducerState.byIds = {
        ...draftReducerState.byIds,
        ...normalizedPayload.entities.SurveyQuestions,
      };
      draftReducerState.pagedIds = {
        ...draftReducerState.pagedIds,
        [Page]: surveyQuestionIds,
      };

      draftReducerState.pagination = {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      };

      draftReducerState.loading = false;
    },
    surveyQuestionDeleteSuccess(draftReducerState, action: PayloadAction<{ id: number }>) {
      const surveyQuestionId = action.payload.id;
      draftReducerState.loading = false;
      delete draftReducerState.byIds[surveyQuestionId];
    },
  },
});

export const getSurveyQuestionsIndex = (
  params: IFilterableIndexParams,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyQuestionRequestStarted());
  try {
    const surveyQuestionIndexResponse = await Api.SurveyQuestions.SurveyQuestionsIndex(params);
    dispatch(surveyQuestionIndexPayloadSuccess(surveyQuestionIndexResponse));
  } catch (err) {
    dispatch(surveyQuestionRequestFailed());
    dispatch(hasError(err));
  }
};

export const updateSurveyResponse = (surveyResponseData: ISurveyQuestion): AppThunk => async (
  dispatch, getState, Api,
) => {
  await Api.SurveyQuestions.updateSurveyQuestion(surveyResponseData);
}

export const getSurveyQuestions = (): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyQuestionRequestStarted());
  try {
    const surveyQuestionGetResponse = await Api.SurveyQuestions.surveyQuestionsGet();
    dispatch(surveyQuestionPayloadSuccess(surveyQuestionGetResponse));
  } catch (err) {
    dispatch(surveyQuestionRequestFailed());
    dispatch(hasError(err));
  }
};

export const createSurveyQuestion = (
  surveyQuestionData: ISurveyQuestion,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(surveyQuestionRequestStarted());
  try {
    const { Id } = await Api.SurveyQuestions.surveyQuestionCreate(surveyQuestionData);
    const createdSurveyQuestion = await Api.SurveyQuestions.surveyQuestionGet(Id);
    dispatch(surveyQuestionCreatedSuccess(createdSurveyQuestion));
  } catch (err) {
    dispatch(surveyQuestionRequestFailed());
    dispatch(hasError(err));
  }
};

export const {
  surveyQuestionRequestStarted,
  surveyQuestionRequestFailed,
  surveyQuestionPayloadSuccess,
  surveyQuestionRequestSuccess,
  surveyQuestionCreatedSuccess,
  surveyQuestionIndexPayloadSuccess,
  surveyQuestionDeleteSuccess,
} = SurveyQuestionSlice.actions;

export default SurveyQuestionSlice.reducer;

export const surveyQuestionsIsLoading = (
  state: RootState,
): boolean => state.SurveyQuestions.loading;

export const selectSurveyQuestionById = (
  state: RootState, surveyQuestionId: number,
): ISurveyQuestion => state.SurveyQuestions.byIds[surveyQuestionId];

export const selectPaginationData = (
  state: RootState,
): ISurveyQuestionState['pagination'] => state.SurveyQuestions.pagination;

export const selectSurveyQuestionsPage = (pageNumber: number) => (
  state: RootState,
): ISurveyQuestion[] => {
  const surveyQuestionIds: number[] = state.SurveyQuestions.pagedIds[pageNumber] || [];
  const surveyQuestions: ISurveyQuestion[] = [];
  surveyQuestionIds.forEach((id) => {
    const surveyQuestion = state.SurveyQuestions.byIds[id.toString()];
    if (surveyQuestion) {
      surveyQuestions.push(surveyQuestion);
    }
  });
  return surveyQuestions;
};
