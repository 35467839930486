import { Input } from 'components/FormControls';
import { NoNavFrame } from 'pages/Login';
import React, { ReactElement, useEffect, useState } from 'react';

import {
  Form,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { userLoginActivate, validateActivationKey } from '../../reducers/UserInfo';
import { RootState } from '../../types/rootState';

interface IActivationData {
  newPassword: string
  confirmedPassword: string
}

export default function Activate():ReactElement {
  return (
    <NoNavFrame title="Activate your Icon Account.">
      <ActivateComponent />
    </NoNavFrame>
  );
}

function ActivateComponent(): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    handleSubmit, errors, control, getValues,
  } = useForm<IActivationData>();
  const [key] = useQueryParam<string>('key');
  const [, , removeCookie] = useCookies(['VFHideNav']);
  const [mode, setMode] = useState<string>('NA');
  useEffect(() => {
    const validateKey = async () => {
      if (key) {
        setMode('Validating');
        dispatch(validateActivationKey(key, () => setMode('')));
      }
    }
    validateKey();
  }, [dispatch, key]);

  const validateKeyErrors = useSelector((state: RootState) => state.UserInfo.error);
  const userInfoLoading = useSelector((state: RootState) => state.UserInfo.loading);

  const onSubmit = async (values) => {
    removeCookie('VFHideNav');
    dispatch(userLoginActivate(key, values.newPassword, () => {
      history.push('/calendar');
    }));
  };

  return (
    <>
      <h2>{mode}</h2>
      <h5>
        Please set a new password to activate your Icon user account.
      </h5>
      <span>
        {validateKeyErrors?.message}
      </span>
      {userInfoLoading && (<span>Loading...</span>)}
      {!validateKeyErrors?.failure && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Input
              id="newPassword"
              name="newPassword"
              type="password"
              label="Password"
              control={control}
              rules={{ required: 'Password must be supplied' }}
            />
            <span>
              {errors.newPassword && errors.newPassword.message}
              &nbsp;
            </span>
          </Form.Group>
          <Form.Group>
            <Input
              id="confirmedPassword"
              name="confirmedPassword"
              label="Confirm Password"
              type="password"
              control={control}
              rules={{
                required: 'Confirm Password must be filled out',
                validate: (value) => {
                  const password = getValues('newPassword');

                  return password === value || 'Passwords must match'
                },
              }}
            />
            <span className="danger">
              {errors.confirmedPassword && errors.confirmedPassword.message}
              &nbsp;
            </span>
          </Form.Group>
          <button className="btn btn-primary" type="submit">Submit</button>
        </Form>
      )}
    </>
  )
}
