import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AutoCallIcon(): ReactElement {
  return (
    <span style={{
      backgroundColor: 'rgb(248 130 37 / 19%)',
      padding: '5px 7px 5px 0px',
      marginLeft: '5px',
      borderRadius: '5px',
    }}
    >
      <span
        className="fa-layers fa-fw pl-2 fa-1x"
        title="Auto call"
      >
        <FontAwesomeIcon icon="phone" transform="right-6 down-3" />
        <span
          className="fa-layers-text fa-inverse"
          data-fa-transform="shrink-10 left-4"
          style={{
            fontWeight: 700,
            fontSize: 9,
            color: '#F88225',
          }}
        >
          VF
        </span>
      </span>
    </span>
  );
}

export default AutoCallIcon;
