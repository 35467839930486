import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Row, Col, Modal } from 'react-bootstrap';

import Input from 'components/FormControls/Input';
import {
  updateSurveyQuestionList, getSurveyQuestionList, selectUpdateLoading, selectById, selectByIdLoading,
} from 'reducers/SurveyQuestionList';
import SubmitButton from 'components/FormControls/SubmitButton'
import { ISurveyQuestionList } from 'types/ISurveyQuestionList';

const SurveyQuestionListEdit: FC<{ show: boolean, onHide(): void }> = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const data = useSelector(selectById);
  const dataLoading = useSelector(selectByIdLoading);

  const updateLoading = useSelector(selectUpdateLoading);

  useEffect(() => {
    dispatch(getSurveyQuestionList(+id));
  }, [dispatch, id])

  const handleEdit = (formData) => {
    dispatch(updateSurveyQuestionList({ ...formData, SurveyQuestionListId: data.SurveyQuestionListId }, () => {
      toast.success('Survey Question List successfully updated!');
      history.push('/surveyQuestionLists');
    }));
  }

  const {
    control, errors, handleSubmit, reset,
  } = useForm<Omit<ISurveyQuestionList, 'ID'>>(
    {
      mode: 'onBlur',
      defaultValues: {
        Name: data?.Name ?? '',
      },
    },
  );

  useEffect(() => {
    if (data) {
      reset({
        Name: data.Name,
      });
    }
  }, [data, reset]);

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header>
        <Modal.Title className="w-100">
          Edit Survey Question List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <hr />
        {dataLoading ? 'Loading...' : (
          <form onSubmit={handleSubmit(handleEdit)}>
            <Row>
              <Col>
                <Input
                  id="Name"
                  name="Name"
                  label="Name"
                  type="text"
                  control={control}
                  errors={errors.Name && errors.Name.message}
                  rules={{
                    required: 'Name is required',
                    validate: (val: string) => val.length <= 50 || 'Name must not exceed 50 characters',
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-1">
              <Col className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onHide}
                >
                  Cancel
                </button>
                <SubmitButton
                  label="Save"
                  savingLabel="Saving..."
                  className="btn btn-primary ml-3"
                  saveComplete={!updateLoading}
                />
              </Col>
            </Row>
          </form>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default SurveyQuestionListEdit;
