import React, {
  FC, useState, useCallback, useMemo, useRef,
} from 'react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import useSWRInfinite from 'swr/infinite';
import { Row, Col, ListGroup } from 'react-bootstrap';
import clsx from 'clsx';

import { IProfile, UserType } from 'reducers/IProfile';
import { IProfilesPayload } from 'types/IProfilesPayload';
import * as ProfilesApi from 'api/ProfilesAPI';
import { ProfileSearchFilter } from 'components/ProfileSearchFilter';

interface ProfilePickerProps {
  setSelectedProfile: (profile: IProfile) => void
  selectedProfile: IProfile
  selectedAccountId: number
}

export const ProfilePicker: FC<ProfilePickerProps> = ({
  setSelectedProfile,
  selectedProfile,
  selectedAccountId,
}) => {
  const [searchQuery, setSearchQuery] = useState<undefined | string>(undefined);
  const [userTypeFilter, setUserTypeFilter] = useState<UserType | ''>('');

  const getKey = (pageIndex, previousPageData) => {
    const excludeSharedProfiles = true;
    // searchField: (searchQuery ? SearchField.FULLNAME : undefined),
    const searchValue = searchQuery || undefined;
    const page = pageIndex + 1;
    const perpage = 50;
    const userFilterType = userTypeFilter || undefined;

    if (previousPageData && (
      previousPageData.Pagination.TotalPages === 0
      || previousPageData.Pagination.TotalPages === previousPageData.Pagination.Page
    )) {
      return null;
    }

    return [
      'caremergeTenantAccountProfiles',
      selectedAccountId,
      excludeSharedProfiles,
      userFilterType,
      searchValue,
      page,
      perpage,
    ];
  }

  const fetcher = async ([
    ,
    accountId,
    excludeSharedProfiles,
    userFilterType,
    searchValue,
    page,
    perpage,
  ]) => ProfilesApi.getCaremergeTenantAccountProfiles(
    accountId,
    {
      excludeSharedProfiles,
      userFilterType,
      excludeUserFilterType: UserType.Seniors,
      searchValue,
      page,
      perpage,
    },
  );

  const {
    data, setSize, size,
  } = useSWRInfinite<IProfilesPayload>(
    getKey,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      persistSize: false,
    },
  );

  const isLoadingMore = (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isLoading = !data || isLoadingMore;
  const hasMore = useMemo(() => {
    if (!data?.length) {
      return false;
    }

    const lastPage = data[data.length - 1];
    return lastPage.Pagination.TotalPages !== 0 && lastPage.Pagination.TotalPages !== lastPage.Pagination.Page
  }, [data])

  const observer = useRef<IntersectionObserver>();
  const lastProfileRef = useCallback((node) => {
    if (isLoading) {
      return;
    }
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setSize((oldSize) => oldSize + 1)
      }
    })
    if (node) {
      observer.current.observe(node);
    }
  }, [isLoading, hasMore, setSize]);

  const fetchedProfiles: IProfile[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.reduce((acc, page) => [...acc, ...page.Profiles], [])
  }, [data]);

  const getRowColor = (index: number): string => {
    if (index % 2 === 1) {
      return 'oddGroupItem';
    }
    return 'evenGroupItem';
  }

  const Profiles = useCallback(({
    index, style,
  }) => {
    if (isLoading && index === fetchedProfiles.length - 1 + 1) {
      return (
        <div
          className="loading-text d-flex text-center justify-content-center"
          key="loading"
          style={style}
        >
          Loading...
        </div>
      )
    }

    const profile = fetchedProfiles[index];

    const rowProps = {
      key: profile.CustomerProfileID,
      style,
      className: 'no-overflow-x cursor-pointer',
      ref: index === fetchedProfiles.length - 1 ? lastProfileRef : undefined,
    }

    const name = `${profile.FirstName} ${profile.LastName}`.trim();
    return (
      <div {...rowProps}>
        <div
          role="row"
          tabIndex={0}
          onClick={() => setSelectedProfile(profile)}
          className={clsx(
            'align-middle ellipsis pl-2',
            getRowColor(index),
            selectedProfile?.CustomerProfileID === profile.CustomerProfileID && 'selected',
          )}
          style={{ overflowX: 'hidden' }}
        >
          <span title={name}>
            {name}
          </span>
        </div>
      </div>
    )
  }, [fetchedProfiles, isLoading, lastProfileRef, setSelectedProfile, selectedProfile]);
  const cmUserTypeOptions = [UserType.Family, UserType.Staff]
  return (
    <ListGroup style={{ lineHeight: '0.7rem' }}>
      <Row>
        <Col>
          <table className="table table-striped" style={{ marginBottom: '0rem' }}>
            <thead>
              <tr>
                <th>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    paddingLeft: '0.25rem',
                  }}
                  >
                    <span>
                      Profiles
                    </span>
                    <ProfileSearchFilter
                      searchVal={searchQuery}
                      userTypeVal={userTypeFilter}
                      onSubmit={(_searchQuery, _filterGroup, _userTypeFilter) => {
                        setSearchQuery(_searchQuery);
                        setUserTypeFilter(_userTypeFilter);
                      }}
                      showUserTypeFilter
                      showGroupFilter={false}
                      userTypeEnabledOptions={cmUserTypeOptions}
                    />
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </Col>
      </Row>

      <div>
        <div className="d-flex">
          <div style={{ flex: '1 1 auto', height: '600px', lineHeight: '2.2rem' }}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  height={height}
                  width={width}
                  itemSize={35}
                  itemCount={fetchedProfiles.length + (isLoading ? 1 : 0)}
                  rowWidth={width}
                >
                  {Profiles}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    </ListGroup>
  );
};
