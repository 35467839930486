import React from 'react';
import UnlayerEmailEditor, { EditorRef } from 'react-email-editor';
import { toast } from 'react-toastify';
import { JSONTemplate } from 'state/types/index';

import log from 'lib/logging';
import { string2bin } from 'utils/stringUtils';

interface EmailEditorProps {
  onChange: (newHtml: string, newJson: string) => void
  errors?: string|false
  onBlur?: () => void
  initialValue?: string
  label?: string
}

const shouldComponentUpdate = (prevProps, newProps) => prevProps.errors === newProps.errors

const isEditorEmpty = (json: JSONTemplate) => {
  if (json.body.footers.length || json.body.headers.length) {
    return false;
  }

  if (!json.body.rows?.length) {
    return true;
  }

  return json.body.rows.every((row) => {
    if (!row.columns?.length) {
      return true;
    }

    return row.columns.every((column) => !column.contents?.length)
  });
}

export const MarketingEmailEditor: React.FC<EmailEditorProps> = React.memo(({
  onChange, onBlur, errors, initialValue, label,
}) => {
  const editorRef = React.useRef<EditorRef>(null);
  const focusedRef = React.useRef(false);

  React.useEffect(() => {
    let timeout1;
    let timeout2;
    const blurListener = () => {
      timeout1 = setTimeout(() => {
        if (document.activeElement.tagName === 'IFRAME') {
          focusedRef.current = true;
        }
      });
    }

    window.addEventListener('blur', blurListener);

    const focusListener = () => {
      timeout2 = setTimeout(() => {
        if (focusedRef.current) {
          focusedRef.current = false;

          if (onBlur) {
            onBlur();
          }
        }
      });
    }

    window.addEventListener('focus', focusListener);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      window.removeEventListener('blur', blurListener);
      window.removeEventListener('focus', focusListener);
    }
  }, [onBlur]);

  return (
    <>
      {label && <label>{label}</label>}
      <UnlayerEmailEditor
        ref={editorRef}
        onLoad={() => {
          if (!editorRef.current) {
            return;
          }

          editorRef.current.editor.addEventListener('design:updated', () => {
            editorRef.current.editor.exportHtml((data) => {
              const { html, design: json } = data;

              if (isEditorEmpty(json)) {
                onChange('', '');
              } else {
                onChange(string2bin(html), JSON.stringify(json));
              }
            }, {
              minify: true,
            })
          })

          if (!initialValue) {
            editorRef.current.editor.loadBlank({
              backgroundColor: '#fff',
              // contentWidth: '500px', // or percent "50%"
            });
            return;
          }

          try {
            const json = JSON.parse(initialValue)
            editorRef.current.editor.loadDesign(json);
          } catch (err) {
            const message = 'Error loading marketing email content in the editor';
            toast.error(message)
            log.error(`${message}:`, err);
          }
        }}
        options={{
          projectId: 190583,
          displayMode: 'email',
          mergeTags: {
            first_name: {
              name: 'First Name',
              value: '$$FirstName$$',
              // sample: 'John',
            },
            last_name: {
              name: 'Last Name',
              value: '$$LastName$$',
              // sample: 'Doe',
            },
            phone_number: {
              name: 'Phone number',
              value: '$$PhoneNumber$$',
            },
            email: {
              name: 'Email Address',
              value: '$$EmailAddress$$',
            },
            mobile: {
              name: 'Mobile',
              value: '$$Mobile$$',
            },
            location: {
              name: 'Location',
              value: '$$Location$$',
            },
            date: {
              name: 'Date',
              value: '$$Date$$',
            },
            community_name: {
              name: 'Community Name',
              value: '$$CommunityName$$',
            },
          },
          features: {
            smartMergeTags: false,
          },
          appearance: {
            loader: {
              html: '<div class="spinner-border"></div>',
              css: `
                @keyframes spinner-border {
                    to { transform: rotate(360deg); }
                }
                .spinner-border {
                    color: #DF459C;
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    vertical-align: text-bottom;
                    border: 4px solid currentColor;
                    border-right-color: transparent;
                    border-radius: 50%;
                    animation: spinner-border .75s linear infinite;
                }`,
            },
          } as any,
        }}
        editorId="marketing-email-editor"
        style={{
          height: 700,
        }}
      />
      {!!errors && (
        <span className="errors">{errors}</span>
      )}
    </>
  )
}, shouldComponentUpdate);
