import { Controller } from 'react-hook-form';

import React, { ReactElement } from 'react';
import ControlledTimeInput from '../Inputs/TimeInput'
import { IFormControlProps } from './IFormControlProps';

function TimeInput({
  name, label, control, errors, rules,
}: IFormControlProps): ReactElement {
  return (
    <Controller
      as={ControlledTimeInput}
      name={name}
      label={label}
      control={control}
      errors={errors}
      rules={rules}
    />
  );
}

export default TimeInput;
