export function clearEventListeners(element: HTMLElement):void {
  const clone = element.cloneNode(true) as HTMLElement;
  // eslint-disable-next-line no-param-reassign, no-param-reassign, no-self-assign
  element = clone;
}

export function getPositionFromEvent(event: MouseEvent):{left:number, top:number} {
  let posx = 0;
  let posy = 0;

  if (event.clientX || event.clientX) {
    posx = event.clientX;
    posy = event.clientY;
  }

  return {
    left: posx,
    top: posy,
  };
}
