export enum FullCalendarViewType {
  Time,
  List,
}

export interface IViewMap {
  day: string
  week: string
  month: string
}

export const TIME_VIEW_MAP: IViewMap = {
  day: 'timeGridDay',
  week: 'timeGridWeek',
  month: 'dayGridMonth',
}

export const LIST_VIEW_MAP: IViewMap = {
  day: 'dayGridDay',
  week: 'dayGridWeek',
  month: 'dayGridMonth',
}

export const MOBILE_VIEW_MAP: IViewMap = {
  day: 'listDay',
  week: 'listWeek',
  month: 'listMonth',
}

export enum CalendarFilterKey {
  Emergency = 'Emergency',
  OnDemand = 'OnDemand',
  Automatic = 'Automatic',
  Staff = 'Staff',
  Senior = 'Seniors',
  Family = 'Family',
  NoRecipients = 'NoProfiles',
}

export interface ICalendarView {
  fcViewPeriod: string // day , week, month
  fcViewType: FullCalendarViewType
  filterKeys?: Array<CalendarFilterKey>
}

export const getFullCalendarViewName = (viewState: ICalendarView, isMobile: boolean): string => {
  const viewPeriod = viewState?.fcViewPeriod || 'week';
  const viewType = (viewState?.fcViewType !== undefined) ? viewState.fcViewType : FullCalendarViewType.Time;
  if (isMobile) return MOBILE_VIEW_MAP[viewPeriod];
  return viewType === FullCalendarViewType.List
    ? LIST_VIEW_MAP[viewPeriod]
    : TIME_VIEW_MAP[viewPeriod];
}
