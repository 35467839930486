import React, { ReactElement } from 'react';
import clsx from 'clsx';

interface IViewButtonProps {
  viewName: string
  viewValue: string
  currentView: string
  disableNavigation: boolean | undefined
  onViewClick: () => void
}
export function ViewButton({
  viewName,
  viewValue,
  currentView,
  disableNavigation,
  onViewClick,
}: IViewButtonProps): ReactElement {
  return (
    <button
      type="button"
      className={clsx('btn btn-primary', currentView === viewValue && 'active')}
      disabled={disableNavigation}
      onClick={() => onViewClick()}
    >
      {viewName.charAt(0).toUpperCase() + viewName.slice(1)}
    </button>
  );
}
