import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  Alert, Button, Card, Col, Modal, Row,
} from 'react-bootstrap';
import 'moment-timezone';
import { Controller, useFormContext, UseFormMethods } from 'react-hook-form';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { isIE } from 'react-device-detect';
import clsx from 'clsx';

import { useHasRoles } from 'hooks/useHasRoles';
import { useHasFeatures } from 'hooks/useHasFeatures';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { DateInput, Input } from 'components/FormControls';
import SubmitButton from 'components/FormControls/SubmitButton';
import { getGroupByIds, getGroupsIndex } from 'reducers/Groups';
import { ControlledTimePicker } from 'components/FormControls/TimePicker';
import { getProfilesByCpids, getProfilesByIds } from 'reducers/Profiles';
import { IUserInfoState } from 'reducers/IUserInfoState';
import { AudioPlayer } from 'components/AudioPlayer';
import { RootState } from 'types/rootState';
import {
  ScheduleMode, TemplateOrEvent, VoiceMessageTypeEnum, IEventExtendo,
  EventType,
} from 'types/IEvent';
import { bin2string } from 'utils/stringUtils';
import { selectIsValidating, validateEventSourceLanguage } from 'reducers/Events';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';
import { EventTransformer } from 'transformers/Event';
import { CustomRRuleGenerator } from './CustomRRuleGenerator';

type Props = {
  data: TemplateOrEvent
  onEdit: () => void
  occurrenceDate: string
  onSubmit: ReturnType<UseFormMethods['handleSubmit']>
  isEditEvent?: boolean
};

const emergencyValue = 'Emergency';

const scheduleModeOptions = [
  { label: 'Once', optionValue: ScheduleMode.Once },
  { label: 'More than once', optionValue: ScheduleMode.Schedule },
];

export const EventTemplatePreview = ({
  data,
  onEdit,
  occurrenceDate = '',
  onSubmit,
  isEditEvent,
}: Props): JSX.Element => {
  const {
    control, errors, getValues, watch, setValue,
  } = useFormContext();

  const formValues = {
    ...data,
    ...getValues(),
  };

  const translateUserType = useUserTypeTranslation();
  const formatUserType = (ut: string) => translateUserType(ut);

  const onDemand = (formValues.VoiceMessageType === VoiceMessageTypeEnum.Both
    || formValues.VoiceMessageType === VoiceMessageTypeEnum.OnDemand);
  const showScheduleMode = ScheduleMode.Schedule === watch('SendMode');
  const selectedGroups = useSelector(getGroupByIds(formValues.GroupIds || []));
  const eventGroups = selectedGroups.map((group) => group.Name);
  const eventProfiles = useSelector(
    getProfilesByIds(formValues.ProfileIds || []),
  ).map((profile) => `${profile.FirstName} ${profile.LastName}`);
  const location = useLocation<{ isResend?: boolean }>();

  const groupRecipientCount = selectedGroups.reduce(
    (sum, group) => sum + group.ProfileCount,
    0,
  );

  const userInfo = useSelector((state: RootState): IUserInfoState => state.UserInfo);
  const acctDateTimeNow = moment().tz(userInfo.accountTimezone);
  const saving = useSelector((state: RootState) => state.Events.loading);
  const [isNoRecipients, setisNoRecipients] = useState(false);
  const [isNoEmailFields, setIsNoEmailFields] = useState(false);
  const [showConfirmEmergencyModal, setShowConfirmEmergencyModal] = useState(false);
  const [isEmergency, setIsEmergency] = useState(false);
  const dispatch = useDispatch();
  const hasMarketingWritePermission = useHasPermissions('Marketing:Edit');
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const { hasFeature: hasMarketingFeature } = useHasFeatures('marketing-emails');
  const canWriteMarketing = (hasMarketingWritePermission || isAdmin || isSuper) && hasMarketingFeature;
  const isUrlLinkSurvey: boolean = data.EventType === EventType.URLLINKSURVEY;

  useEffect(() => {
    dispatch(getGroupsIndex());
  }, [dispatch]);

  useEffect(() => {
    if (formValues.ProfileIds?.length) {
      dispatch(getProfilesByCpids(formValues.ProfileIds));
    }
  }, [dispatch, formValues.ProfileIds]);

  useEffect(() => {
    if (formValues.IsEmergency === true) {
      setIsEmergency(true);
    }
  }, [dispatch, formValues.IsEmergency, setIsEmergency]);

  useEffect(() => {
    setValue('IsEmergency', isEmergency);
  }, [setValue, isEmergency]);

  useEffect(() => {
    // need to set the RRule here,
    // need to change the option if move this code to onchange event after switch sendmode
    if (showScheduleMode && !getValues('RRule')) {
      setValue('RRule', 'FREQ=DAILY')
    }
  }, [getValues, setValue, showScheduleMode])

  const generateDownloadLink = () => {
    const downloadUrl = formValues.VoiceContent.VoiceRecording.DownloadUrl;
    if (downloadUrl) {
      return (
        <>
          <a href={downloadUrl}>Click here to download recording</a>
          {' '}
          <br />
        </>
      )
    }
    return (null)
  }

  const getHeaderText = () => {
    if (occurrenceDate) {
      return `Edit ${occurrenceDate}`;
    }

    if (location.state?.isResend) {
      return 'Resend';
    }

    return 'New message';
  }
  const communityName = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.CommunityName);

  const getShowCommunityNameWarning = () => {
    const voiceContentValue = formValues.VoiceContent?.Content;
    const smsContentValue = formValues.SMSContent?.Content
    const emailContentValue = formValues.EmailContent?.Content
    const decodeEmailContentValue = emailContentValue ? atob(emailContentValue) : '';

    if (((voiceContentValue && voiceContentValue?.indexOf('$$CommunityName$$') !== -1)
      || (smsContentValue && smsContentValue?.indexOf('$$CommunityName$$') !== -1)
      || (decodeEmailContentValue && decodeEmailContentValue?.indexOf('$$CommunityName$$') !== -1))
      && (communityName === null || communityName === '')) {
      return true;
    }
    return false;
  };

  const validating = useSelector(selectIsValidating);

  return (
    <div className="event-template-preview">
      <h3 className="pb-2">
        {getHeaderText()}
        &nbsp;-&nbsp;
        {formValues.Name}
      </h3>
      <p className="mb-2">{formValues.Description}</p>
      <p>{formValues.Location}</p>
      {(errors?.SendTime || errors?.StartDate) && (
        <Alert variant="danger">
          Please schedule event for a time in the future
        </Alert>
      )}
      {(errors?.RRule) && (
        <Alert variant="danger">

          {errors?.RRule.message}
        </Alert>
      )}
      {(isNoRecipients) && (
        <Alert variant="danger">
          Please select at least one profile or group to continue
        </Alert>
      )}
      {(isNoEmailFields) && (
        <Alert variant="danger">
          Email content must have email from address, sender name and reply to address.
          Please edit the template and set these fields.
        </Alert>
      )}
      {getShowCommunityNameWarning() && (
        <Alert variant="warning">
          This
          {' '}
          {formValues?.IsMarketing
            ? 'marketing email'
            : 'template'}
          {' '}
          is using the CommunityName merge field. This value needs to be set in Account Settings.
        </Alert>
      )}
      <div>
        <div className="mt-2">
          <Card>
            <Card.Header as="h4">Scheduling</Card.Header>
            <Card.Body>
              <Row id="schedule">
                <Col className="col-md-2">
                  <strong>Send date/time:  </strong>
                </Col>
                <Col
                  className="d-flex col-md-7"
                >
                  <DateInput
                    id="StartDate"
                    control={control}
                    defaultValue={
                      acctDateTimeNow.format('MM-DD-yyyy')
                    }
                    format="MM-DD-yyyy"
                    name="StartDate"
                    rules={{
                      required: 'The start date is required.',
                      validate: (val: string) => moment(val, 'MM-DD-yyyy')
                        >= moment(acctDateTimeNow.format('MM-DD-yyyy'), 'MM-DD-yyyy'),
                    }}
                  />
                  <ControlledTimePicker
                    name="SendTime"
                    defaultValue={
                      acctDateTimeNow.add(5, 'minutes').format('HH:mm')
                    }
                    validate={(val: any) => {
                      if (moment(getValues('StartDate'), 'MM-DD-yyyy') > acctDateTimeNow) {
                        return true;
                      }
                      return val > moment().tz(userInfo.accountTimezone).format('HH:mm');
                    }}
                  />
                </Col>
                <Col className="SchedulingButtons">
                  {(!formValues.IsMarketing || canWriteMarketing) && (
                    <Button
                      variant="secondary"
                      onClick={onEdit}
                    >
                      Edit
                    </Button>
                  )}

                  <SubmitButton
                    id="event-send"
                    label="Schedule"
                    savingLabel="Scheduling..."
                    className="ml-4"
                    saveComplete={!saving && !validating}
                    onClick={(e) => {
                      e.preventDefault();
                      if (formValues.EmailContent) {
                        if (formValues.EmailContent.From === ''
                          || formValues.EmailContent.Display === ''
                          || formValues.EmailContent.ReplyTo === '') {
                          setIsNoEmailFields(true);
                          return;
                        }
                      }
                      if (selectedGroups?.length === 0 && eventProfiles.length === 0) {
                        setisNoRecipients(true);
                        return;
                      }

                      const futureEvent = {
                        ...data as IEventExtendo,
                        ...getValues(),
                      }

                      // this is going to be called when creating/editing
                      // all type of events except for survey and automatic
                      dispatch(
                        validateEventSourceLanguage(
                          [EventTransformer.toAPI(futureEvent)],
                          onSubmit,
                        ),
                      )
                    }}
                  />
                </Col>
              </Row>
              {!occurrenceDate && (
                <>
                  <Row>
                    <Col className="col-md-2">
                      <strong>Scheduling mode: </strong>
                    </Col>
                    <Col>
                      <Controller
                        control={control}
                        defaultValue={formValues.IsEmergency === true ? emergencyValue : formValues.SendMode}
                        name="SendMode"
                        render={({ onChange, value }) => (
                          <RadioGroup row>
                            {scheduleModeOptions.map(({ label, optionValue }) => (
                              <FormControlLabel
                                key={optionValue}
                                value={optionValue}
                                control={<Radio />}
                                label={label}
                                checked={value === optionValue && isEmergency === false}
                                onChange={() => { onChange(optionValue); setIsEmergency(false); }}
                                disabled={isEditEvent}
                              />
                            ))}
                            <FormControlLabel
                              key={emergencyValue}
                              value={emergencyValue}
                              control={<Radio />}
                              label={emergencyValue}
                              checked={isEmergency === true}
                              disabled={isEditEvent}
                              onChange={() => {
                                setShowConfirmEmergencyModal(true);
                              }}
                            />
                          </RadioGroup>
                        )}
                        rules={{ required: true }}
                      />
                      <Input
                        id="IsEmergency"
                        name="IsEmergency"
                        label=""
                        type="hidden"
                        control={control}
                      />
                    </Col>
                  </Row>
                  {showScheduleMode && (
                    <Row>
                      <Col>
                        <div className="mt-2">
                          <CustomRRuleGenerator
                            control={control}
                            disabled={isEditEvent}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  {onDemand && !showScheduleMode && !isUrlLinkSurvey && (
                    <Row className="d-flex align-items-center">
                      <Col className="col-md-2">
                        <strong>All Day On Demand: </strong>
                      </Col>
                      <Col>
                        <Controller
                          control={control}
                          name="ExpirationTime"
                          render={(
                            {
                              onChange, onBlur, value,
                            },
                          ) => (
                            <input
                              type="checkbox"
                              id="ExpirationTime"
                              name="ExpirationTime"
                              onBlur={onBlur}
                              onChange={(e) => onChange(e.target.checked ? '23:59' : null)}
                              checked={value === '23:59'}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
      <Card
        className="mt-4"
        id="event-summary"
      >
        <Card.Header as="h5">Summary</Card.Header>
        <Card.Body>
          <Row className="pt-4">
            <Col>
              <Card>
                <Card.Header as="h6">Message Recipients</Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      {selectedGroups?.length === 0 ? (
                        <h6>No Groups selected</h6>
                      ) : (
                        <>
                          <h6>
                            Groups (
                            {groupRecipientCount}
                            {' '}
                            recipients)
                          </h6>
                          {eventGroups.map((group, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <p className="m-0" key={index}>
                              {formatUserType(group)}
                            </p>
                          ))}
                        </>
                      )}
                    </Col>
                    <Col>
                      {eventProfiles.length === 0 ? (
                        <h6>No Profiles selected</h6>
                      ) : (
                        <>
                          <h6>
                            Profiles (
                            {eventProfiles.length}
                            {' '}
                            recipients)
                          </h6>
                          {eventProfiles.map((profile, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <p className="m-0" key={index}>
                              {profile}
                            </p>
                          ))}
                        </>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              {formValues.VoiceContent && (
                <Card
                  id="voice-summary"
                >
                  <Card.Header as="h6">
                    <FontAwesomeIcon className="mr-1" icon="phone" />
                    {' '}
                    Voice Message
                  </Card.Header>
                  <Card.Body>
                    {formValues.VoiceContent.Content && (
                      <pre
                        className="pt-3 wrapword"
                        style={{
                          fontFamily: 'sans-serif',
                          fontSize: '1rem',
                        }}
                      >
                        {formValues.VoiceContent.Content}
                      </pre>
                    )}
                    {formValues.VoiceContent.VoiceRecording && isIE && (
                      generateDownloadLink()
                    )}
                    {formValues.VoiceContent.VoiceRecording && !isIE && (
                      <AudioPlayer
                        src={`data:audio/wav;base64,${formValues.VoiceContent.VoiceRecording.Data}`}
                      />
                    )}
                  </Card.Body>
                </Card>
              )}
              {formValues.EmailContent && (
                <Card className={formValues.VoiceContent ? 'mt-4' : ''}>
                  <Card.Header as="h6">
                    <FontAwesomeIcon className="mr-1" icon="envelope-square" />
                    {' '}
                    Email Message
                  </Card.Header>
                  <Card.Body>
                    <p className="m-0">
                      <strong>Subject:</strong>
                      {' '}
                      {formValues.EmailContent.Subject}
                    </p>
                    <p className="m-0">
                      <strong>From:</strong>
                      {' '}
                      {formValues.EmailContent.From}
                      {' '}
                      {formValues.EmailContent.Display ?? `(${formValues.EmailContent.Display})`}
                    </p>
                    <p className="m-0">
                      <strong>Reply To:</strong>
                      {' '}
                      {formValues.EmailContent.ReplyTo}
                    </p>
                    <p
                      className={clsx('pt-3', !formValues.IsMarketing && 'fr-view')}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: formValues.EmailContent.Content
                          ? bin2string(formValues.EmailContent.Content)
                          : '',
                      }}
                    />
                    {formValues.EmailContent.Attachments && (
                      <>
                        <p className="m-0">
                          <strong>Attachments:</strong>
                        </p>
                        {formValues.EmailContent.Attachments.map(({ FileName }) => (
                          <p>{FileName}</p>
                        ))}
                      </>
                    )}
                  </Card.Body>
                </Card>
              )}
              {formValues.SMSContent && (
                <Card
                  className={
                    formValues.VoiceContent || formValues.EmailContent ? 'mt-4' : ''
                  }
                >
                  <Card.Header as="h6">
                    <FontAwesomeIcon className="mr-1" icon="comment" />
                    {' '}
                    Text Message
                  </Card.Header>
                  <Card.Body>
                    <pre className="wrapword" style={{ fontFamily: 'sans-serif', fontSize: '1rem' }}>
                      {formValues.SMSContent.Content}
                    </pre>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal
        show={showConfirmEmergencyModal}
        onHide={() => setShowConfirmEmergencyModal(false)}
        className="DeleteActionButtonModal"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header />
        <Modal.Body>
          <FontAwesomeIcon
            icon="exclamation-circle"
            size="4x"
            color="#EA5455"
          />
          <div className="message">
            <h4>Are you sure?</h4>
            <p>
              This message will be sent to recipients using all available methods of contact,
              regardless of their inactivity status, do not call settings and communication preferences.
              This should only be used in the case of an emergency.
            </p>
          </div>
          <button
            type="button"
            className="btn btn-block btn-danger"
            onClick={() => {
              setValue('SendMode', 'Once');
              setValue('IsEmergency', true);
              setIsEmergency(true);
              setShowConfirmEmergencyModal(false);
            }}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-block btn-secondary"
            onClick={() => {
              setValue('IsEmergency', false);
              setIsEmergency(false);
              setShowConfirmEmergencyModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
