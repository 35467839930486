import { combineReducers } from '@reduxjs/toolkit';

import { loadingBarReducer } from 'react-redux-loading-bar';
import Calendar from '../reducers/Calendar'
import Groups from '../reducers/Groups'
import Profiles from '../reducers/Profiles'
import UserInfo from '../reducers/UserInfo'
import LibraryContent from '../reducers/LibraryContent'
import Templates from '../reducers/Templates'
import Events from '../reducers/Events'
import UserDetail from '../reducers/UserDetail'
import Error from '../reducers/Error'
import SurveyCalls from '../reducers/SurveyCalls'
import SurveyQuestions from '../reducers/SurveyQuestions'
import DischargedPatients from '../reducers/DischargedPatients'
import Wellness from '../reducers/Wellness'
import SamlData from '../reducers/SamlData'
import SurveyQuestionList from '../reducers/SurveyQuestionList'

const rootReducer = combineReducers({
  Calendar,
  Groups,
  Profiles,
  UserInfo,
  LibraryContent,
  Templates,
  Events,
  UserDetail,
  Error,
  SurveyCalls,
  SurveyQuestions,
  DischargedPatients,
  loadingBar: loadingBarReducer,
  Wellness,
  SamlData,
  SurveyQuestionList,
})

export default rootReducer;
