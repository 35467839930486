import produce from 'immer';
import moment from 'moment';

import { format12to24HourTime, format24to12HourTime } from '../../lib/formatters/time';
import { IProfile } from '../../reducers/IProfile';

const DEFAULT_API_DATE = '0001-01-01T00:00:00';

function toISODate(dateStr: string | undefined): string {
  if (!dateStr || dateStr === DEFAULT_API_DATE) return '';
  return moment(new Date(dateStr)).format('MM/DD/yyyy');
}

function toISODateTime(dateStr: string | undefined): string {
  if (!dateStr || dateStr === DEFAULT_API_DATE) return '';
  return moment(new Date(dateStr)).format('yyyy-MM-DDT00:00:00');
}

const PROFILES_DATE_FIELDS = [
  'InactiveStartDate',
  'InactiveEndDate',
  'ComplianceDate1',
  'ComplianceDate2',
  'ComplianceDate3',
  'ComplianceDate4',
  'ComplianceDate5',
  'ComplianceDate6',
  'ComplianceDate7',
  'ComplianceDate8',
  'LifeDate1',
  'LifeDate2',
];

const PROFILES_12_HOUR_TIME_FIELDS = [
  'BlockBeginTime',
  'BlockEndTime',
];

class ProfilesTransformer {
  static toState(profile: Partial<IProfile>): Partial<IProfile> {
    return produce<Partial<IProfile>>(profile, (draft) => {
      PROFILES_DATE_FIELDS.forEach((field) => {
        draft[field] = toISODate(profile[field]);
      });

      PROFILES_12_HOUR_TIME_FIELDS.forEach((field) => {
        draft[field] = format12to24HourTime(profile[field]);
      });
    });
  }

  static toAPI(profile: Partial<IProfile>): Partial<IProfile> {
    return produce<Partial<IProfile>>(profile, (draft) => {
      PROFILES_DATE_FIELDS.forEach((field) => {
        draft[field] = toISODateTime(profile[field]);
      });

      PROFILES_12_HOUR_TIME_FIELDS.forEach((field) => {
        const value = profile[field];
        draft[field] = format24to12HourTime(value);
      });
    });
  }
}

export default ProfilesTransformer;
