import React, { ReactElement } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import { logout } from 'reducers/UserInfo';

function UserMenu(): ReactElement {
  const userInfo = useSelector((state: RootState) => state.UserInfo);
  const dispatch = useDispatch();
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  }

  const hideChangePassword = userInfo?.userInfo?.AccountDetail?.IconUsersOnly ?? false;

  return (
    <div className="UserMenu">
      <div className="UserMenu--Modal">
        <div className="user-info">
          <div className="email">{userInfo.nameid}</div>
        </div>
        <ul className="links">
          {!hideChangePassword && (
            <li>
              <a href="/changePassword?source=userChangePassword">Change Password</a>
            </li>
          )}
          <li>
            <a href="/login" onClick={handleLogout}>Logout</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default UserMenu;
