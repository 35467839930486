import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useSWR from 'swr/immutable';
import { useSelector } from 'react-redux';

import log from 'lib/logging';
import * as UserInfoAPI from 'api/UserInfoAPI';
import { getCurrentAccount as selectCurrentAccount } from 'reducers/UserInfo';
import Select from 'components/Inputs/Select';
import * as ProfilesAPI from 'api/ProfilesAPI';
import { IProfile } from 'reducers/IProfile';
import { useIsSyncedCustomer } from 'hooks/useIsSyncedCustomer';

import { AddButton } from './components/AddButton';
import { ProfilePicker } from './components/CMProfilePicker';
import { ProfileInfo } from './components/CMProfileInfo';

const ProfilesAddPage = () => {
  const history = useHistory();
  const [isAdding, setIsAdding] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<IProfile>(null);
  const [selectedAccountId, setSelectedAccountId] = useState<string>('');

  const currentAccount = useSelector(selectCurrentAccount);
  const isSyncedCustomer = useIsSyncedCustomer();

  const { data: caremergeTenantAccounts } = useSWR(
    'caremergeTenantAccounts',
    UserInfoAPI.getCaremergeTenantAccounts,
  );

  const caremergeTenantAccountsOptions = useMemo(() => {
    if (!caremergeTenantAccounts || !currentAccount) {
      return [];
    }

    return caremergeTenantAccounts
      .filter((account) => account.Id !== currentAccount.Id)
      .map((account) => ({
        value: `${account.Id}`,
        label: account.Name,
      }))
  }, [caremergeTenantAccounts, currentAccount]);

  useEffect(() => {
    if (!caremergeTenantAccountsOptions.length) {
      return;
    }

    setSelectedAccountId(caremergeTenantAccountsOptions[0].value);
  }, [caremergeTenantAccountsOptions]);

  if (currentAccount && !isSyncedCustomer) {
    return <Redirect to="/profiles/new" />
  }

  const handleAdd = async () => {
    setIsAdding(true);

    try {
      await ProfilesAPI.copyProfileFromCaremergeTenantAccount(
        selectedProfile.CustomerProfileID,
        +selectedAccountId,
      );

      history.push('/profiles');
      toast.success('Profile successfully added');
    } catch (error) {
      log.error(error);
      toast.error(error.response.data.Message);
    }

    setIsAdding(false);
  }

  return (
    <div className="profiles-add">
      <div className="header-bar">
        <h1>
          &gt; Add Existing Profile from Account
        </h1>
        <div
          className="action-bar"
          style={{ flexShrink: 0 }}
        >
          <div>
            <button
              type="button"
              role="link"
              className="btn btn-link"
              onClick={() => {
                history.push('/profiles')
              }}
            >
              Cancel
            </button>
            <AddButton
              disabled={!selectedAccountId || !selectedProfile}
              saveComplete={!isAdding}
              onClick={handleAdd}
            />
            <Button
              type="button"
              onClick={() => {
                history.push('/profiles/new')
              }}
              variant="primary"
            >
              Create New Profile
            </Button>
          </div>
        </div>
      </div>

      <Row className="mt-3">
        <Col xs={4}>

          <Select
            id="tenantAccount"
            label="From Account"
            placeholder="Select"
            options={caremergeTenantAccountsOptions}
            name="tenantAccount"
            value={selectedAccountId}
            onChange={(newAccountId: string) => {
              setSelectedAccountId(newAccountId);
              setSelectedProfile(null);
            }}
            disabled={!currentAccount || !caremergeTenantAccounts}
          />
        </Col>
      </Row>

      {!!selectedAccountId && (
        <Row className="mt-3">
          <Col xs={4}>
            <ProfilePicker
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
              selectedAccountId={+selectedAccountId}
            />
          </Col>

          {selectedProfile && (
            <Col xs={8}>
              <ProfileInfo selectedProfile={selectedProfile} />
            </Col>
          )}

        </Row>
      )}

    </div>
  )
}

export default ProfilesAddPage;
