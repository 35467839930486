import useSWRInfinite, { SWRInfiniteConfiguration, SWRInfiniteFetcher } from 'swr/infinite';
import { useQueryParam } from 'use-query-params';

import { ConversationsPayload } from 'types/IConversation';
import * as InboxApi from 'api/InboxAPI';

export const useSmsConversationsQuery = (
  options: SWRInfiniteConfiguration<
  ConversationsPayload, any, SWRInfiniteFetcher<ConversationsPayload>
  > | undefined = {},
) => {
  const [orderByParam = ''] = useQueryParam<string, string>('orderby');
  const [searchValueParam = ''] = useQueryParam<string, string>('search');
  const [readStatusParam = 'all'] = useQueryParam<string, string>('readStatus');
  const [userTypeParam = 'senior,staff,family'] = useQueryParam<string, string>('userType');

  const getKey = (pageIndex, previousPageData) => {
    const page = pageIndex + 1;
    const perpage = 20;

    if (previousPageData && (
      previousPageData.Pagination.TotalPages === 0
        || previousPageData.Pagination.TotalPages === previousPageData.Pagination.Page
    )) {
      return null;
    }

    const orderBy = orderByParam === '-new'
      ? 'oldest'
      : 'newest';

    const readStatus = ['all', 'read', 'unread'].includes(readStatusParam)
      ? readStatusParam
      : 'all';

    const splittedUserTypeParam = userTypeParam.split(',').filter(Boolean);
    const senior = splittedUserTypeParam.includes('senior');
    const staff = splittedUserTypeParam.includes('staff');
    const family = splittedUserTypeParam.includes('family');

    return [
      'inboxSmsConversations',
      searchValueParam.replace(/[+()-\s]/g, ''),
      orderBy,
      page,
      perpage,
      readStatus,
      senior,
      staff,
      family,
    ];
  }

  const fetcher = async ([
    ,
    searchValue,
    ordering,
    page,
    perpage,
    readStatus,
    senior,
    staff,
    family,
  ]) => InboxApi.getInboxConversations({
    searchValue,
    ordering,
    page,
    perpage,
    readStatus,
    senior,
    staff,
    family,
  })

  const { data, size, ...query } = useSWRInfinite<ConversationsPayload>(
    getKey,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      revalidateAll: true,
      persistSize: false,
      ...options,
    },
  );

  const isLoadingMore = (size > 0 && !!data && typeof data[size - 1] === 'undefined');
  const isLoading = !data || isLoadingMore;

  return {
    ...query,
    data,
    size,
    isLoading,
    isLoadingMore,
  }
}
