import React, { ReactElement, useCallback } from 'react';

import { Helmet } from 'react-helmet'
import { postLibraryContent } from 'reducers/LibraryContent';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ILibraryContent } from 'types/ILibraryContent';
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';
import { RootState } from 'types/rootState';
import EditLibraryContentForm from './components/EditLibraryContentForm';

function LibraryContentNewSmsPage(): ReactElement {
  const dispatch = useDispatch()
  const history = useHistory();
  const defaultInputLanguage = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.InputLanguageDefaultValue);

  const onSubmit = useCallback(
    async (data: ILibraryContent) => {
      segmentAnalyticsTrack(trackActions.createLibrary(data));
      await dispatch(postLibraryContent(data,
        () => {
          toast.success('Content successfully created');
          history.push('/librarycontent');
        },
      ));
    },
    [dispatch, history],
  );

  const newSmsContent: ILibraryContent = {
    ID: '0',
    Name: '',
    Description: '',
    Type: 'SMS',
    VoiceContent: undefined,
    SMSContent: { Content: '' },
    EmailContent: undefined,
    SourceLanguage: defaultInputLanguage,
  }
  return (
    <Container>
      <Helmet>
        <title>Library Content</title>
      </Helmet>
      <div className="header-bar">
        <h1>New Text Message Content</h1>
      </div>
      <EditLibraryContentForm data={newSmsContent} onSubmit={onSubmit} />
    </Container>
  )
}
export default LibraryContentNewSmsPage
