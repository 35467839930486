/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import log from 'lib/logging';

import { ILibraryContent, ILibraryContentPayload, LoadingState } from 'types/ILibraryContent';
import { ILibraryContentState } from 'types/IEvent';
import { serialize } from 'utils/serializeParams';
import { ILibraryIndexParams } from '../types/ILibraryIndexParams';
import { RootState } from '../types/rootState';
import { AppThunk } from '../app/appThunk';
import { hasError } from './Error';

export const INITIAL_STATE: ILibraryContentState = {
  loadingState: LoadingState.NOT_LOADED,
  contents: {},
  allContents: [],
  selectedContent: {
    ID: '0',
    Name: '',
    Description: '',
    Type: '',
    VoiceContent: {
      Content: '',
      TransferToPhoneNumber: '',
      VoiceType: '',
      SurveyResponses: [],
      VoiceRecording: {
        Data: '',
        DocumentId: 0,
        DownloadUrl: '',
        FileName: 'No file set',
      },
    },
    SMSContent: { Content: '' },
    EmailContent: {
      Subject: '',
      Display: '',
      From: '',
      ReplyTo: '',
      Content: '',
      Attachments: [],
    },
    SourceLanguage: null,
  },
  pagination: {
    Page: 1,
    PerPage: 20,
    TotalItems: 0,
    TotalPages: 1,
  },
  validating: false,
};

const libraryIndexDefaultParams = {
  page: 1,
}

export const selectLibraryContent = (params: ILibraryIndexParams) => (
  state: RootState,
): ILibraryContent[] => {
  const hash = serialize(params, libraryIndexDefaultParams);

  return state.LibraryContent.contents[hash] ?? [];
};

const LibraryContentSlice = createSlice({
  name: 'LibraryContents',
  initialState: INITIAL_STATE,
  reducers: {
    libraryContentRequestStarted(draftReducerState) {
      draftReducerState.loadingState = LoadingState.LOADING;
    },
    libraryContentRequestFailed(draftReducerState) {
      draftReducerState.loadingState = LoadingState.LOADED;
    },
    libraryContentValidateStarted(draftReducerState) {
      draftReducerState.validating = true;
    },
    libraryContentValidateFinished(draftReducerState) {
      draftReducerState.validating = false;
    },
    libraryContentRequestSuccess(
      draftReducerState,
      action: PayloadAction<ILibraryContent>,
    ) {
      draftReducerState.loadingState = LoadingState.LOADED;
      draftReducerState.selectedContent = action.payload || undefined;
    },
    libraryContentPayloadSuccess(
      draftReducerState,
      action: PayloadAction<{ data: ILibraryContentPayload, params: ILibraryIndexParams }>,
    ) {
      draftReducerState.contents = {
        ...draftReducerState.contents,
        [serialize(action.payload.params, libraryIndexDefaultParams)]: action.payload.data.Data ?? [],
      }

      const {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      } = action.payload.data.Pagination;

      draftReducerState.pagination = {
        Page,
        PerPage,
        TotalItems,
        TotalPages,
      };
      draftReducerState.loadingState = LoadingState.LOADED;
    },
    selectedLibraryContentPayloadSuccess(
      draftReducerState,
      action: PayloadAction<ILibraryContent>,
    ) {
      draftReducerState.selectedContent = action.payload || undefined;
      draftReducerState.loadingState = LoadingState.LOADED;
    },
    libraryContentDeleteSuccess(draftReducerState) {
      draftReducerState.loadingState = LoadingState.LOADED;
    },
    libraryAllContentPayloadSuccess(
      draftReducerState,
      action: PayloadAction<ILibraryContentPayload>,
    ) {
      draftReducerState.allContents = action.payload.Data || [];
      draftReducerState.loadingState = LoadingState.LOADED;
    },
  },
});

export const getLibraryContent = (): AppThunk => async (dispatch, getState, Api) => {
  // const { LibraryContent } = getState();
  // if (LibraryContent.loadingState !== LoadingState.NOT_LOADED) {
  //   return;
  // }

  dispatch(libraryContentRequestStarted());
  try {
    const params: ILibraryIndexParams = { perpage: 999 };
    const libraryContentGetResponse = await Api.LibraryContent.libraryContentGet(params);
    dispatch(libraryAllContentPayloadSuccess(libraryContentGetResponse));
  } catch (err) {
    dispatch(libraryContentRequestFailed());
    dispatch(hasError(err));
  }
};
export const getLibraryContentIndex = (
  params: ILibraryIndexParams,
): AppThunk => async (dispatch, getState, Api) => {
  // if (LibraryContent.loadingState !== LoadingState.NOT_LOADED) {
  //   log.info('reduce1.5')
  //   return;
  // }
  dispatch(libraryContentRequestStarted());
  try {
    const libraryContentGetResponse = await Api.LibraryContent.libraryContentGet(params);
    dispatch(libraryContentPayloadSuccess({ data: libraryContentGetResponse, params }));
  } catch (err) {
    dispatch(libraryContentRequestFailed());
    dispatch(hasError(err));
  }
};
export const getSelectedLibraryContent = (ID: string): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(libraryContentRequestStarted());
  try {
    const libraryContentGetResponse = await Api.LibraryContent.selectedLibraryContentGet(ID);
    dispatch(selectedLibraryContentPayloadSuccess(libraryContentGetResponse));
  } catch (err) {
    dispatch(libraryContentRequestFailed());
    dispatch(hasError(err));
  }
};

export const deleteLibraryContent = (
  id: string,
  successCallback: () => void,
  failedCallback?: (err: Error) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(libraryContentRequestStarted());
  try {
    await Api.LibraryContent.libraryContentDelete(id);
    successCallback();
    dispatch(libraryContentDeleteSuccess());
  } catch (err) {
    log.info(err);
    dispatch(libraryContentRequestFailed());
    dispatch(hasError(err));
    failedCallback?.(err);
  }
};

export const putLibraryContent = (
  content: ILibraryContent,
  successCallback: () => void,
): AppThunk => async (
  dispatch, getState, Api,
) => {
  dispatch(libraryContentRequestStarted());
  try {
    const libraryContentPutResponse = await Api.LibraryContent.libraryContentPut(content);
    successCallback();
    dispatch(libraryContentRequestSuccess(libraryContentPutResponse));
  } catch (err) {
    dispatch(libraryContentRequestFailed());
    dispatch(hasError(err));
  }
};

export const postLibraryContent = (
  content: ILibraryContent,
  onSuccess: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(libraryContentRequestStarted());
  try {
    const libraryContentPostResponse = await Api.LibraryContent.libraryContentPost(content);
    dispatch(libraryContentRequestSuccess(libraryContentPostResponse));
    onSuccess();
  } catch (err) {
    dispatch(libraryContentRequestFailed());
    dispatch(hasError(err));
  }
};

export const {
  libraryContentRequestStarted,
  libraryContentRequestFailed,
  libraryContentPayloadSuccess,
  libraryContentValidateStarted,
  libraryContentValidateFinished,
  selectedLibraryContentPayloadSuccess,
  libraryContentRequestSuccess,
  libraryContentDeleteSuccess,
  libraryAllContentPayloadSuccess,
} = LibraryContentSlice.actions;

export default LibraryContentSlice.reducer;

export const getAllLibraryContent = (
  state: RootState,
): ILibraryContent[] => state.LibraryContent.allContents;

export const getLibraryIsLoading = (
  state: RootState,
): boolean => state.LibraryContent.loadingState === LoadingState.LOADING;

export const selectPaginationData = (
  state: RootState,
): ILibraryContentState['pagination'] => state.LibraryContent.pagination;

export const validateLibraryContent = (
  content: ILibraryContent,
  successCallback?: () => void,
  failedCallback?: (err: Error) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(libraryContentValidateStarted());
  try {
    await Api.LibraryContent.validateLibraryContent(content);
    successCallback?.();
  } catch (err) {
    dispatch(hasError(err));
    failedCallback?.(err);
  }
  dispatch(libraryContentValidateFinished());
};
