import moment from 'moment';
import { BroadcastFormValues } from 'types/BroadcastFormValues';

import {
  IEventExtendo, IEvent, EventType, ScheduleMode, VoiceMessageTypeEnum,
} from 'types/IEvent';
import { VoiceContentType } from 'types/ILibraryContent';
import { string2bin } from 'utils/stringUtils';

export class EventTransformer {
  static toAPI(formData: IEventExtendo): IEvent {
    const submitData = {
      ...formData,
      StartDate: moment(formData.StartDate, 'MM-DD-yyyy').format('yyyy-MM-DD'),
    }

    if (formData.VoiceContent) {
      const placeHolder = '(___) ___-____';

      if (
        submitData.VoiceContent.TransferToPhoneNumber === ''
        || submitData.VoiceContent.TransferToPhoneNumber === placeHolder
      ) {
        submitData.VoiceContent = {
          ...submitData.VoiceContent,
          TransferToPhoneNumber: null,
        }
      }

      submitData.VoiceContent = {
        ...submitData.VoiceContent,
        SurveyResponses: formData.VoiceContent.SurveyResponses?.map((item, index) => ({
          Response: item.Response,
          Keypress: index === 9 ? 0 : index + 1,
        })).filter(Boolean) ?? [],
      };
    }

    return submitData;
  }

  static broadcastToAPI(formData: BroadcastFormValues, tz: string): IEvent {
    const startDate = (
      formData.StartDate
        ? moment(formData.StartDate, 'MM-DD-yyyy')
        : moment().tz(tz)
    ).format('yyyy-MM-DD');

    const sendTime = formData.SendTime ?? moment().tz(tz).add(1, 'minutes').format('HH:mm');

    const submitData: Partial<IEvent> = {
      ID: undefined,
      TemplateID: 0,
      EventType: EventType.ANNOUNCEMENT,
      Category: null,
      Name: `New Broadcast - ${moment().tz(tz).format('MM-DD-yyyy')}`,
      GroupIds: formData.GroupIds,
      ProfileIds: [],
      SendMode: ScheduleMode.Once,
      StartDate: startDate,
      SendTime: sendTime,
      IsEmergency: false,
      VoiceMessageType: VoiceMessageTypeEnum.Broadcast,
      IsSendNow: !formData.SendTime && !formData.StartDate,
      // SourceLanguage: defaultInputLanguage,
    }

    if (formData.Channels.includes('sms')) {
      submitData.SMSContent = {
        Content: `${formData.Subject?.trim() ? `${formData.Subject}: ` : ''}${formData.Content}`,
      }
    }

    if (formData.Channels.includes('voice')) {
      submitData.VoiceContent = {
        Content: `${formData.Subject?.trim() ? `${formData.Subject}: ` : ''}${formData.Content}`,
        TransferToPhoneNumber: null,
        // OnDemandMessage: false, - not sure if needed
        // BroadcastMessage: true, /
        VoiceRecording: null,
        VoiceType: VoiceContentType.TTS,
      }
    }

    if (formData.Channels.includes('email')) {
      submitData.EmailContent = {
        Content: string2bin(formData.Content),
        Subject: formData.Subject?.trim() || 'No subject',
        Display: formData.DisplayName,
        From: formData.From,
        ReplyTo: formData.ReplyTo,
      }
    }

    return submitData as IEvent;
  }
}
