import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CreateUrlLinkSurvey from 'pages/BroadcastForm/URLLinkSurvey/New';
import UrlLinkSurveyEventsIndex from 'pages/BroadcastForm/URLLinkSurvey/Index';
import BroadcastFormResults from 'pages/BroadcastForm/URLLinkSurvey/Results';
import BroadcastFormResentPage from 'pages/BroadcastForm/URLLinkSurvey/Resent';
import FillOutSurveyPage from 'pages/BroadcastForm/URLLinkSurvey/FillOutSurvey';
import BroadcastFormReportPage from 'pages/BroadcastForm/BroadcastFormReportPage';

function BroadcastFormsRoutes() {
  return (
    <Switch>
      <Route path="/feedback360/new" component={CreateUrlLinkSurvey} />
      <Route path="/feedback360/:formId/results" component={BroadcastFormResults} />
      <Route path="/feedback360/:formId/pbi/report" component={BroadcastFormReportPage} />
      <Route path="/feedback360/:formId/resent" component={BroadcastFormResentPage} />
      <Route path="/feedback360/:formId/filloutsurvey" component={FillOutSurveyPage} />
      <Route path="/feedback360" component={UrlLinkSurveyEventsIndex} />
    </Switch>
  );
}

export default BroadcastFormsRoutes;
