import React from 'react';
import { Switch, Route } from 'react-router-dom';

import TemplatesCreatePage from 'pages/Templates/New';
import TemplatesIndexPage from 'pages/Templates/Index';
import TemplatesEditPage from 'pages/Templates/Edit';
import TemplatesClonePage from 'pages/Templates/Clone';

function MarketingTemplateRoutes() {
  return (
    <Switch>
      <Route path="/marketing-templates/new" component={TemplatesCreatePage} />
      <Route path="/marketing-templates/clone" component={TemplatesClonePage} />
      <Route path="/marketing-templates/blueprint" component={TemplatesClonePage} />
      <Route path="/marketing-templates/:templateId/edit" component={TemplatesEditPage} />
      <Route path="/marketing-templates" component={TemplatesIndexPage} />
    </Switch>
  );
}

export default MarketingTemplateRoutes;
