import React from 'react';
import { Resizable } from 're-resizable';
import { useQueryParams, StringParam } from 'use-query-params';

import { IConversation } from 'types/IConversation';
import { Searchbox } from './Searchbox';
import { OrderByButton } from './OrderByButton';
import { Conversations } from './Conversations';
import { ReadStatusFilter } from './ReadStatusFilter';
import { UserTypeFilter } from './UserTypeFilter';
import { MessagesRef, ConversationsRef } from './types';
import { ClearFilters } from './ClearFilters';
import { RefreshButton } from './RefreshButton';

interface ConversationViewProps {
  selectedConversation: IConversation|null
  setSelectedConversation: (newConversation: IConversation) => void
  messagesRef: React.RefObject<MessagesRef>
  conversationsRef: React.RefObject<ConversationsRef>
}

export const ConversationView: React.FC<ConversationViewProps> = ({
  selectedConversation,
  setSelectedConversation,
  messagesRef,
  conversationsRef,
}) => {
  const [{
    search: searchParam = '',
    orderby: orderByParam = '',
    readStatus: readStatusParam = 'all',
    userType: userTypeParam = 'senior,staff,family',
  }] = useQueryParams({
    search: StringParam,
    orderby: StringParam,
    readStatus: StringParam,
    userType: StringParam,
  });

  const splittedUserTypeParam = userTypeParam.split(',').filter(Boolean);

  const hasFilters = searchParam !== ''
  || orderByParam === '-new'
  || ['read', 'unread'].includes(readStatusParam)
  || (
    !['family', 'staff', 'senior'].every((item) => splittedUserTypeParam.includes(item))
    && ['family', 'staff', 'senior'].some((item) => splittedUserTypeParam.includes(item))
  );

  return (
    <Resizable
      defaultSize={{
        width: '35%',
        height: 'auto',
      }}
      minWidth={455}
      maxWidth="80%"
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleStyles={{
        right: {
          width: 20,
          right: -15,
        },

      }}
      className="sms-inbox__conversation-view"
    >
      <header className="sms-inbox__conversation-view__header">
        <div>
          <Searchbox />
          <UserTypeFilter />
        </div>
        <div>
          <OrderByButton />
          <ReadStatusFilter />
          {hasFilters && (
            <>
              <span className="flex-grow-1" />
              <RefreshButton />
            </>
          )}
        </div>
        {hasFilters && (
          <div>
            <ClearFilters />
          </div>
        )}

      </header>
      <Conversations
        hasFilters={hasFilters}
        selectedConversation={selectedConversation}
        setSelectedConversation={setSelectedConversation}
        messagesRef={messagesRef}
        ref={conversationsRef}
      />
    </Resizable>

  )
}
