import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CalendarEventIndexPage from 'pages/CalendarEvent/Index';
import CalendarEventDetailsPage from 'pages/CalendarEvent/Details';

function CalendarEventRoutes() {
  return (
    <Switch>
      <Route
        path="/CalendarEvent/:id/edit"
        component={CalendarEventDetailsPage}
      />
      <Route
        path="/CalendarEvent"
        component={CalendarEventIndexPage}
      />
    </Switch>
  );
}

export default CalendarEventRoutes;
