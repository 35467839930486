import API from 'api/API';
import { ISurveyCallsPayload } from 'types/ISurveyCallsPayload';
import { IFilterableIndexParams } from 'types/ITemplatesIndexParams';
import { ISurveyCall } from '../types/ISurveyCall';
import { ISurveyQuestion } from '../types/ISurveyQuestion';

export const API_PATH = '/api/v2/SurveyCalls';

export async function SurveyCallsIndex({
  searchField = '',
  searchValue = '',
  sortField = '',
  sortDirection = '',
  page,
  perpage,
}: IFilterableIndexParams): Promise<ISurveyCallsPayload> {
  const response = await API.get<ISurveyCallsPayload>(`${API_PATH}`);
  return response.data;
}

// Survey Calls
// Get
export async function surveyCallsGet(): Promise<ISurveyCall[]> {
  const response = await API.get<ISurveyCall[]>(`${API_PATH}`);
  return { ...response.data };
}

export async function surveyCallGet(id: number): Promise<ISurveyCall> {
  const response = await API.get<ISurveyCall>(`${API_PATH}/${id}`);
  return { ...response.data };
}

export async function getSurveyQuestionsByCallId(id: number): Promise<ISurveyQuestion[]> {
  const response = await API.get<ISurveyQuestion[]>(`${API_PATH}/${id}`);
  return { ...response.data };
}

// Post
export async function surveyCallCreate(surveyCall: ISurveyCall): Promise<ISurveyCall> {
  const response = await API.post<ISurveyCall>(API_PATH, surveyCall);
  return response.data;
}

// Put
export async function surveyCallUpdate(surveyCall: ISurveyCall): Promise<ISurveyCall> {
  const response = await API.put<ISurveyCall>(`${API_PATH}/${surveyCall.SurveyCallId}`, surveyCall);
  return response.data;
}

// Delete
export async function surveyCallDelete(id: number): Promise<void> {
  await API.delete<void>(`${API_PATH}/${id}`);
}
