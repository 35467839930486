import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { Helmet } from 'react-helmet';
import { SWRConfig } from 'swr';
import { ToastContainer, toast } from 'react-toastify';
import { AxiosError } from 'axios';

import UserConfirmation from 'components/UserConfirmation';
import React from 'react';
import { config } from '@fortawesome/fontawesome-svg-core';
import log from 'lib/logging';
import FontAwesomeLibrary from './lib/FontAwesomeLibrary';
import RootRoutes from './routes/RootRoutes';
import { createStore } from './app/store';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tabs/style/react-tabs.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-time-picker/assets/index.css';

import './styles/index.scss';

config.autoAddCss = false;
FontAwesomeLibrary.init();

ReactDOM.render(
  <Provider store={createStore()}>
    <Helmet titleTemplate="%s | Icon" defaultTitle="Icon">
      <meta charSet="utf8" />
    </Helmet>
    <ToastContainer />
    <SWRConfig value={{
      errorRetryCount: 1,
      onError: (error: Error) => {
        log.error(error);
        const errorMessage = (error as AxiosError).response?.data?.Message ?? error.message;

        // on redirects some requests may be aborted and we don't want to show an error for that
        if (errorMessage !== 'Request aborted') {
          toast.error(errorMessage);
        }
      },
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }}
    >
      <Router
        getUserConfirmation={(message, callback) => UserConfirmation(message, callback)}
      >
        <QueryParamProvider ReactRouterRoute={Route}>
          <RootRoutes />
        </QueryParamProvider>
      </Router>
    </SWRConfig>
  </Provider>,
  document.getElementById('root'),
);
