import React, {
  useEffect, useState, ReactElement,
} from 'react';

import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Select, SearchInput } from 'components/FormControls';
import { selectIndexLoading } from 'reducers/SurveyQuestionList';

export interface ISearchQuery {
  searchField?: string
  searchValue?: string
}

interface IProps {
  searchQuery: ISearchQuery
  onSearch: (searchQuery: ISearchQuery) => void
  onResetFilters: () => void
  totalItems: number
}

function IndexFilter({
  searchQuery,
  onSearch, onResetFilters, totalItems,
}: IProps): ReactElement {
  const {
    control,
    reset,
    handleSubmit,
  } = useForm<ISearchQuery>({ defaultValues: { ...searchQuery } });
  useEffect(() => {
    reset(searchQuery);
  }, [reset, searchQuery]);

  const [open, setOpen] = useState<boolean>(false);

  const isLoading = useSelector(selectIndexLoading);

  const onSearchSubmit = (data: ISearchQuery): void => {
    data.searchValue ? onSearch(data) : onSearch({});
  };

  const resetFilters = (): void => {
    onResetFilters();
    setOpen(false);
  };

  return (
    <div className="index-filter">
      <div className="index-filter__mobile-toggle" role="presentation" onClick={() => setOpen(!open)}>
        Filtering
        <FontAwesomeIcon icon={open ? 'caret-down' : 'caret-right'} />
      </div>
      <form id="form" onSubmit={handleSubmit(onSearchSubmit)}>
        <div className={clsx('index-filter__main', open && 'open')}>
          <Select
            id="searchField"
            label="Search By"
            name="searchField"
            className="mb-2 mr-2"
            style={{ width: '160px' }}
            control={control}
            defaultValue="Name"
            options={[
              { label: 'Name', value: 'Name' },
            ]}
          />
          <div className="d-flex align-items-end mb-2" style={{ maxWidth: '100%' }}>
            <SearchInput
              label="Search Survey Question Lists"
              placeholder="I am looking for..."
              name="searchValue"
              className="mb-0"
              control={control}
            />
            <button
              type="submit"
              className="btn btn-md btn-primary square ml-3"
            >
              Search
            </button>
            <button
              type="button"
              className="btn btn-md btn-link ml-3 px-0"
              onClick={resetFilters}
            >
              Cancel
            </button>
          </div>
          {/* <div className="index-filter__search">
            <div className="form-group prepend">
              <label htmlFor="searchField">Search By</label>
              <select
                name="searchField"
                className="mb-2 mr-2"
                ref={register}
                onChange={handleTempQueryChange('searchField')}
              >
                <option value="Name">Name</option>
              </select>
            </div>
            <SearchInput
              label="Search Survey Question Lists"
              placeholder="I am looking for..."
              name="searchValue"
              className="mb-0"
              control={control}
            />
            <button
              type="submit"
              className="btn btn-md btn-primary square ml-3"
            >
              Search
            </button>
            <button
              type="button"
              className="btn btn-md btn-link ml-3 px-0"
              onClick={resetFilters}
            >
              Cancel
            </button>
          </div> */}
        </div>

        <div className="flex-grow-1 d-none d-md-block" />
        {isLoading && (
          <div className="index-filter__item-count mb-2">
            <div className="itemCount loading-text">
              Loading...
            </div>
          </div>
        )}
        {!isLoading && (
          <div className="index-filter__item-count mb-2">
            <div className="itemCount">
              {`${totalItems} Survey Question List(s) In Total`}
            </div>
          </div>
        )}
        {/* {children} */}
      </form>
    </div>
  );
}

export default IndexFilter;
