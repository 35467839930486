import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import { dischargedPatientSetSelected } from 'reducers/DischargedPatients';
import { IDischargedPatient, CommunicationTypeEnum } from 'types/IDischargedPatient';
import { Col, Row } from 'react-bootstrap';
import { getProfilesByCpids } from 'reducers/Profiles';
import { useHistory } from 'react-router-dom';
import { ISurveyCall } from 'types/ISurveyCall';
import { PatientDetailsPage } from './PatientDetails';
import { getDischargedPatientById } from '../../reducers/DischargedPatients';

interface IProps {
  match: { params: { id: string, callId: string } }
}

const DischargedPatientsView: React.FC<IProps> = ({ match }: IProps) => {
  const dispatch = useDispatch();
  const dischargedPatientIdFromUrl = match.params.id;
  // todo: what if this is bogus?

  const selectedDischargedPatient = useSelector(
    (state: RootState) => state.DischargedPatients.selectedDischargedPatient,
  );

  const patientList = useSelector((state:RootState) => state.DischargedPatients.byIds);
  const listLoading = useSelector((state:RootState) => state.DischargedPatients.loading);
  const selectedPatientId = selectedDischargedPatient?.DischargePatientId.toString();
  const [showPatientInfoForm, setShowPatientInfoForm] = useState<boolean>(false);
  const [hasNoteChanged, setHasNoteChanged] = useState<boolean>();
  const [selectedCallId, setSelectedCallId] = useState<number>(parseInt(match.params.callId, 10));

  useEffect(() => {
    const listNotLoading = !listLoading;
    let patient: IDischargedPatient;
    if (listNotLoading
        && dischargedPatientIdFromUrl
        && dischargedPatientIdFromUrl !== selectedPatientId) {
      if (dischargedPatientIdFromUrl === 'new') {
        patient = {
          DischargePatientId: 0,
          ProfileId: '',
          ContactProfileId: '',
          DischargeDate: format(new Date(), 'yyyy-MM-dd'),
          NextAutoCallDate: format(new Date(), 'yyyy-MM-dd'),
          NextManualCallDate: format(new Date(), 'yyyy-MM-dd'),
          LastCallDate: format(new Date(), 'yyyy-MM-dd'),
          ProgramStatus: 'In Program',
          Facility: '',
          MRN: '',
          DischargeTo: '',
          AttendingPhysician: '',
          AdmittingDiagnosis: '',
          LastResponse: '',
          LastCallResult: '',
          ReviewRequired: false,
          LastCallType: '',
          NextCallType: '',
          LastCallTransactionCount: 0,
          IncompleteCallCount: 0,
          CompleteCallCount: 0,
          TotalCalls: 0,
          LastCallId: 0,
          SurveyCalls: [],
          Notes: '',
          AdditionalPhoneNumber: '',
          Tags: [],
          Version: undefined,
          CommunicationType: CommunicationTypeEnum.Voice,
        };
      } else {
        patient = patientList[dischargedPatientIdFromUrl];
      }
      if (patient) {
        dispatch(dischargedPatientSetSelected(patient));
        dispatch(getProfilesByCpids([patient.ProfileId, patient.ContactProfileId]));
      } else {
        dispatch(getDischargedPatientById(parseInt(dischargedPatientIdFromUrl, 10),
          (myPatient) => {
            dispatch(dischargedPatientSetSelected(myPatient));
            dispatch(getProfilesByCpids([myPatient.ProfileId, myPatient.ContactProfileId]));
          }));
      }
    }
  }, [dischargedPatientIdFromUrl, dispatch, listLoading, patientList, selectedPatientId]);
  const history = useHistory();
  return (
    <div className="discharged-patients">
      <Helmet>
        <title>Survey Calls</title>
      </Helmet>
      <Row>
        <Col>
          {selectedDischargedPatient && (
            <PatientDetailsPage
              showBack
              selectedCallId={selectedCallId}
              selectedDischargedPatient={selectedDischargedPatient}
              showPatientInfoForm={showPatientInfoForm}
              setShowPatientInfoForm={setShowPatientInfoForm}
              setSelectedPatient={(patient) => {
                if (patient) {
                  history.push(`/dischargedpatients/${patient.DischargePatientId}`);
                } else {
                  history.push('/dischargedpatients/');
                }
              }}
              setSelectedCall={(patient:IDischargedPatient,
                surveyCall:ISurveyCall) => {
                if (selectedCallId === surveyCall?.SurveyCallId) {
                  setSelectedCallId(null);
                } else if (surveyCall) {
                  setSelectedCallId(surveyCall.SurveyCallId)
                } else {
                  setSelectedCallId(null);
                }
              }}
              hasNoteChanged={hasNoteChanged}
              setHasNoteChanged={setHasNoteChanged}
            />
          )}
        </Col>
      </Row>
    </div>
  )
};
export default DischargedPatientsView;
