import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserType } from 'reducers/IProfile';
import { RootState } from 'types/rootState';

export const useUserTypeTranslation = () => {
  const userTypeMappings = useSelector(
    (state: RootState) => state.UserInfo.userInfo?.AccountDetail?.UserTypeMappings);

  const mappings = useMemo(() => {
    const map = new Map<string, string>();

    map.set('senior', userTypeMappings?.Member || UserType.Seniors);
    map.set('seniors', userTypeMappings?.Member || UserType.Seniors);
    map.set('member', userTypeMappings?.Member || UserType.Seniors); // need 'member' option item for profile index page
    map.set('staff', userTypeMappings?.Staff || UserType.Staff);
    map.set('family', userTypeMappings?.Family || UserType.Family);

    return map;
  }, [userTypeMappings]);

  return useCallback((value: string) => {
    const lowerValue = value?.toLowerCase();
    if (mappings.has(lowerValue)) {
      return mappings.get(lowerValue);
    }
    return value;
  }, [mappings]);
}
