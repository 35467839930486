import { SWRConfiguration, Fetcher } from 'swr';
import useSWRImmutable from 'swr/immutable';

import * as UserInfoAPI from 'api/UserInfoAPI';
import { IUserAccount } from 'reducers/IUserInfoState';

export const useUserAccounts = (
  {
    enabled = true,
    ...options
  }: SWRConfiguration<IUserAccount[], any, Fetcher<IUserAccount[]>> & { enabled?: boolean } = {},
) => useSWRImmutable<IUserAccount[]>(
  enabled
    ? 'userAccounts'
    : null,
  UserInfoAPI.getUserAccounts,
  options,
)
