import { updateQueryStringParameter } from 'lib/formatters/url';
import moment from 'moment';
import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getLoggedInStatus } from '../reducers/UserInfo';

export const IFrame: FC<{ url: string }> = ({ url }) => {
  const loggedInStatus = useSelector(getLoggedInStatus)
  const key = moment.utc().valueOf().toString();
  if (!loggedInStatus) {
    return <Redirect to="/login" />;
  }
  return (
    <iframe
      style={{ width: '100%', height: '100%', border: 'none' }}
      title="legacy app"
      src={updateQueryStringParameter(url, 'IFrame', 'true')}
      key={key}
    />
  );
};
