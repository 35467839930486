import React, {
  FC, ReactElement, useMemo, useState,
} from 'react';
import { BulkActions } from 'components/BulkActions';
import Table from 'components/Table';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectAccountTz } from 'reducers/UserInfo';
import { CellProps } from 'react-table';
import useSWR from 'swr';
import { deleteOneTimeEventById, getOneTimeEvents, updateOneTimeEvent } from 'api/BroadcastFormAPI';
import { format } from 'date-fns';
import { EnterpriseFormOneTimeEventModelEdit } from 'types/BroadcastForm';
import OneTimeEventModal from './EbfGeneratorEventEditModal';

type EbfGeneratorEventsTableProps = {
  generatorId: number
  showActions?: boolean
  filterTitle?:string
};

const formatDateSentCell = (acctTz: string) => (props: CellProps<Record<string, unknown>>) => {
  const { cell: { value } } = props;
  return format(new Date(value), 'MMM d, yyyy h:mm a');
}

export const useGeneratorEvents = (generatorId: number) => useSWR(
  ['EbfGeneratorEvents', generatorId],
  async () => {
    const response = await getOneTimeEvents(generatorId, {
      ignoreGlobalCatch: true,
    });
    return response;
  },
  {
    shouldRetryOnError: false,
  },
)

export const EbfGeneratorEventsTable: FC<EbfGeneratorEventsTableProps> = ({
  generatorId,
  showActions,
  filterTitle,
}) => {
  const { data, isLoading, mutate } = useGeneratorEvents(generatorId);

  const accountTz = useSelector(selectAccountTz);
  const [editEventId, setEditEventId] = useState<number | null>(null);

  const onUpdateEvent = async (evtData: EnterpriseFormOneTimeEventModelEdit) => {
    // Make sure date is formatted properly
    // Regular expression to match the format 'yyyy-MM-dd'
    const dateString = evtData.SendDate;
    const isFormatted = /^\d{4}-\d{2}-\d{2}$/.test(dateString);
    const formattedDate = isFormatted ? dateString : format(new Date(dateString), 'yyyy-MM-dd');
    const formattedData: EnterpriseFormOneTimeEventModelEdit = {
      ...evtData,
      SendDate: formattedDate,
    };

    try {
      await updateOneTimeEvent(editEventId, formattedData);
      toast.success('Event added successfully');
      setEditEventId(null);
      mutate();
    } catch (ex) {
      const errMsg = ex?.response?.data.Message || 'Uknown error: Failed to update event';
      toast.error(errMsg);
    }
  };

  const handleDelete = async (OneTimeEventId: number) => {
    try {
      await deleteOneTimeEventById(OneTimeEventId);
      toast.success('Event deleted');
      mutate();
    } catch (ex) {
      const errMsg = ex?.response?.data.Message || 'Uknown error: Failed to delete the broadcast form generator';
      toast.error(errMsg);
    }
  };

  const columns = useMemo(() => [
    {
      Header: 'Send Date',
      accessor: 'SendDateTime',
      Cell: formatDateSentCell(accountTz),
      sortable: false,
      showToolTip: true,
    },
    {
      Header: 'Message', accessor: 'Message', sortable: false, showToolTip: true,
    },
    {
      Header: 'Status', accessor: 'SendState', sortable: false, showToolTip: true,
    },
    {
      Header: 'Error Message', accessor: 'ErrorMessage', sortable: false, showToolTip: true,
    },
  ].filter(Boolean), []);

  const items = data ?? [];

  const rowBulkActionItems = (entityId: number): ReactElement => {
    if (items.find((item) => item.OneTimeEventId === entityId)?.SendState !== 'scheduled') {
      return undefined;
    }
    const actionItems = [];
    actionItems.push({
      label: 'Edit',
      handler: () => {
        setEditEventId(entityId);
      },
    });
    actionItems.push({
      label: 'Delete',
      handler: () => handleDelete(entityId),
      className: 'delete-action',
    });
    return (
      <BulkActions
        className="ml-3"
        items={actionItems}
      />
    );
  };

  return (
    <div>
      <Table
        columns={columns}
        enableCheck={false}
        data={items}
        selectButton={undefined}
        rowIDAccessor="OneTimeEventId"
        isLoading={isLoading}
        rowActionItems={showActions ? rowBulkActionItems : undefined}
        className="Template-Table"
      />
      <OneTimeEventModal
        show={editEventId !== null}
        onHide={() => setEditEventId(null)}
        onSave={onUpdateEvent}
        initialData={items.find((item) => item.OneTimeEventId === editEventId)}
      />
    </div>
  );
};
