import React, { ReactElement } from 'react';

import { Helmet } from 'react-helmet';

function Error500Page(): ReactElement {
  return (
    <>
      <Helmet>
        <title>Server Error</title>
      </Helmet>

      <div className="header-bar">
        <h1>Something went wrong (500)</h1>
      </div>
      <p>
        Whoops! It looks like our server has encountered an error.
        Please try again later or contact support if you continue to have issues.

      </p>
    </>
  );
}

export default Error500Page;
