import React, { useEffect, useState, FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt, useParams } from 'react-router-dom';
import _times from 'lodash/times';
import _pick from 'lodash/pick';

import Checkbox from 'components/Inputs/Checkbox';
import {
  EventTemplateMode, TemplateAndEvent, TemplateOrEvent, EventType, ITemplateExtendo, IEventExtendo,
} from 'types/IEvent';
import { Input } from 'components/FormControls';
import { getManyGroupsMembers } from 'reducers/Groups';
import { RootState } from 'types/rootState';
import { AutosaveDraftProvider } from './AutosaveDraftProvider';
import { EventTemplatePreview } from './EventTemplatePreview';
import { EventTemplateStepper } from './EventTemplateStepper';

const getDefaultVoiceContent = (voiceContent, isSurvey) => {
  if (!isSurvey || !voiceContent) {
    return voiceContent;
  }

  const surveyResponses = [..._times(9, (n) => n + 1), 0].map((n) => {
    const found = voiceContent?.SurveyResponses?.find((item) => item.Keypress === n);
    return found ?? {
      Keypress: n,
      Response: '',
    }
  });

  return {
    ...voiceContent,
    SurveyResponses: surveyResponses,
  }
}

type Props = {
  data: TemplateOrEvent
  setData?: (updatedData: IEventExtendo) => void
  eventTemplateMode?: EventTemplateMode
  onSubmit: (data: TemplateOrEvent) => void
  onCancel: () => void
  isNew?: boolean
  occurrenceDate?: string
  allowPreview?: boolean
  initialShowPreview?: boolean
  enableGenerateSmartContent?: boolean
};

export const EventTemplateForm: FC<Props> = ({
  data,
  setData,
  eventTemplateMode = EventTemplateMode.Template,
  onSubmit,
  onCancel,
  isNew = false,
  allowPreview = true,
  initialShowPreview = allowPreview,
  occurrenceDate = '',
  enableGenerateSmartContent = false,
}) => {
  const isEvent: boolean = eventTemplateMode !== EventTemplateMode.Template;
  const isTemplate = eventTemplateMode === EventTemplateMode.Template;
  const isSurvey: boolean = isEvent && data.EventType === EventType.SURVEY;
  const isUrlLinkSurvey: boolean = isEvent && data.EventType === EventType.URLLINKSURVEY;
  const defaultInputLanguage = useSelector(
    (state: RootState) => state.UserInfo?.userInfo?.AccountDetail?.InputLanguageDefaultValue);
  const methods = useForm<TemplateAndEvent>({
    mode: 'onBlur',
    defaultValues: {
      ID: data.ID,
      RRule: data.RRule,
      EventType: data.EventType,
      RecipientsById: data.RecipientsById,
      SelectedGroups: data.SelectedGroups,
      SelectedProfiles: data.SelectedProfiles,
      VoiceMessageType: data.VoiceMessageType,
      Category: data.Category,
      Type: data.Type,
      TemplateID: data.TemplateID,
      StartDate: data.StartDate,
      SendTime: data.SendTime,
      SendMode: data.SendMode,
      ExpirationTime: data.ExpirationTime,
      Name: data.Name,
      Description: data.Description,
      Location: data.Location,
      GroupIds: data.GroupIds,
      ProfileIds: data.ProfileIds,
      VoiceContent: getDefaultVoiceContent(data.VoiceContent, isSurvey),
      SMSContent: data.SMSContent,
      EmailContent: data.EmailContent,
      voiceSelected: data.VoiceContent != null,
      smsSelected: data.SMSContent != null,
      emailSelected: data.IsMarketing || data.EmailContent != null,
      SourceLanguage: data.SourceLanguage ?? defaultInputLanguage,
      IsEmergency: data.IsEmergency ?? false,
      IsMarketing: data.IsMarketing ?? false,
      IsBroadcastForm: isUrlLinkSurvey,
      FormType: data.FormType,
    },
  });

  const { type: urlType } = useParams<{ type?:string }>();
  const templateType = urlType || 'template'
  const {
    control,
    handleSubmit,
    formState,
    getValues,
    setError,
    clearErrors,
    reset,
    register,
  } = methods;

  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.GroupIds?.length) {
      return;
    }
    dispatch(getManyGroupsMembers(data.GroupIds));
  }, [dispatch, data.GroupIds]);

  const [showPreview, setShowPreview] = useState(allowPreview && initialShowPreview);
  const [showPrompt, setShowPrompt] = useState(false);

  const onSubmitForm = handleSubmit((formData) => {
    const validateResult = validateProfileGroupCount(getValues('submit'));
    if (!validateResult) {
      return;
    }

    if (!isEvent || !allowPreview || showPreview) {
      setShowPrompt(false);
      onSubmit({
        ...data,
        ...formData,
      });
    } else {
      // when clicking on 'Review'
      setShowPreview(true);
      setShowPrompt(true);
      const scheduleData = _pick(data, ['StartDate', 'SendTime', 'SendMode', 'RRule', 'IsEmergency']);
      const reviewedData = { ...formData, ...scheduleData };
      setData(reviewedData);
      reset(reviewedData);
    }
  });

  const validateProfileGroupCount = (value: string) => {
    // from comms-262, allow no profile or group when save
    if (value === 'save' && !isUrlLinkSurvey) {
      return true;
    }
    const sp = getValues('ProfileIds');
    const sg = getValues('GroupIds');
    if (sp?.length <= 0 && sg?.length <= 0) {
      setError('selectedGroupAndProfiles', {
        type: 'custom',
        message: 'Please select at least one profile or group to continue.',
      });
      return false;
    }
    clearErrors('selectedGroupAndProfiles');
    return true;
  };

  const messageObj = {
    title: 'Template Changes',
    messageText: 'You have unsaved changes, are you sure you want to leave?',
  };

  const IsDraft = data.IsDraft ?? true;
  const { isSubmitted, isSubmitting, isDirty } = formState;

  return (
    <>
      <Prompt
        when={showPrompt || (!isSubmitting && !isSubmitted && isDirty)}
        message={JSON.stringify(messageObj)}
      />
      <FormProvider {...methods}>
        <Form onSubmit={onSubmitForm} data-testid="event-template-form">
          <AutosaveDraftProvider
            enabled={isTemplate && templateType === 'template' && IsDraft}
            data={data as ITemplateExtendo}
          >
            <Input id="IsMarketing" control={control} type="hidden" name="IsMarketing" />
            <Input id="ID" defaultValue={0} control={control} type="hidden" name="ID" />
            <Input id="Type" control={control} type="hidden" name="Type" />
            {isEvent && (
              <>
                <Input id="TemplateID" control={control} type="hidden" name="TemplateID" />
                <Input id="EventType" control={control} type="hidden" name="EventType" />
              </>
            )}
            {isTemplate && templateType === 'template' && (
              <Checkbox
                className="d-none"
                name="IsDraft"
                ref={register}
                defaultChecked={IsDraft}
              />
            )}
            {showPreview ? (
              <EventTemplatePreview
                data={data}
                onEdit={() => {
                  const scheduleData = getValues(['StartDate', 'SendTime', 'SendMode', 'RRule', 'IsEmergency']);
                  setData({ ...(data as IEventExtendo), ...scheduleData });
                  setShowPreview(false);
                }}
                occurrenceDate={occurrenceDate}
                onSubmit={onSubmitForm}
                isEditEvent={!!data.ID && isEvent}
              />
            ) : (
              <EventTemplateStepper
                data={data}
                occurrenceDate={occurrenceDate}
                onCancel={onCancel}
                eventTemplateMode={eventTemplateMode}
                isNew={isNew}
                enableGenerateSmartContent={enableGenerateSmartContent}
                onSubmit={onSubmit}
              />
            )}
          </AutosaveDraftProvider>
        </Form>
      </FormProvider>
    </>
  );
};
