import DischargedPatientsView from 'pages/DischargedPatients/View';
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import confirmationIndexPage from '../pages/DischargedPatients/Index';

function DischargedPatientsRoutes() {
  return (
    <Switch>
      {' '}

      <Route path="/dischargedpatients/:id/call/:callId" component={DischargedPatientsView} />
      <Route
        path="/dischargedpatients/:id"
        component={DischargedPatientsView}
      />
      <Route path="/dischargedpatients" component={confirmationIndexPage} />

    </Switch>
  );
}

export default DischargedPatientsRoutes
