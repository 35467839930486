import React from 'react';
import useSWR, { SWRConfiguration, Fetcher } from 'swr';
import {
  useQueryParam, NumberParam, StringParam, BooleanParam,
} from 'use-query-params';
import moment from 'moment';

import { IndexPayload } from 'types/request';
import * as EventsApi from 'api/EventsAPI';
import { IBroadcastHistory } from 'types/IBroadcastHistory';

export const useBroadcastHistoryQuery = (
  options: SWRConfiguration<
  IndexPayload<IBroadcastHistory>, any, Fetcher<IndexPayload<IBroadcastHistory>> | undefined
  > = {},
) => {
  const [pageParam = 1] = useQueryParam('page', NumberParam);
  const [perPageParam = 10] = useQueryParam('perpage', NumberParam);
  const [sortFieldParam] = useQueryParam('sortField', StringParam);
  const [sortDirectionParam] = useQueryParam('sortDirection', StringParam);
  const [startDateParam = ''] = useQueryParam<string, string>('startDate');
  const [endDateParam = ''] = useQueryParam<string, string>('endDate');
  const [includeAutomaticParam = true] = useQueryParam<boolean, boolean>('includeAutomaticEvents', BooleanParam);

  const [validStartDate, validEndDate] = React.useMemo(() => {
    const _startDate = moment(startDateParam, 'MM/DD/YYYY');
    const _endDate = moment(endDateParam, 'MM/DD/YYYY');

    const startDateIsValid = _startDate.isValid();
    const finalStartDate = startDateIsValid
      ? _startDate.format('YYYY-MM-DD')
      : undefined;

    if (!_endDate.isValid()) {
      return [
        finalStartDate,
        undefined,
      ];
    }

    if (!startDateIsValid) {
      return [
        finalStartDate,
        _endDate.format('YYYY-MM-DD'),
      ]
    }

    return [
      finalStartDate,
      !_endDate.isBefore(_startDate)
        ? _endDate.format('YYYY-MM-DD')
        : undefined,
    ]
  }, [startDateParam, endDateParam]);

  return useSWR<IndexPayload<IBroadcastHistory>>([
    'broadcast-history',
    pageParam,
    perPageParam,
    validStartDate,
    validEndDate,
    includeAutomaticParam,
    (sortFieldParam && sortDirectionParam) ? sortFieldParam : undefined,
    (sortFieldParam && sortDirectionParam) ? sortDirectionParam : undefined,
  ], ([
    ,
    page,
    perpage,
    startDate,
    endDate,
    includeAutomaticEvents,
    sortField,
    sortDirection,
  ]) => EventsApi.getBroadcastHistoryIndex({
    page,
    perpage,
    startDate,
    endDate,
    includeAutomaticEvents,
    sortField,
    sortDirection,
  }), options)
}
