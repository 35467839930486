import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { BroadcastForm } from './BroadcastForm';
import { PromptModal } from './PromptModal';

interface BroadcastModalProps {
  onHide: () => void
  show: boolean
}

export const BroadcastModal: React.FC<BroadcastModalProps> = ({ show, onHide: hideModal }) => {
  const [showPromptModal, setShowPromptModal] = React.useState(false);
  const formStateRef = React.useRef<null|any>(null);

  const handleHide = () => {
    if (formStateRef.current) {
      const { isSubmitting, isSubmitted, isDirty } = formStateRef.current;

      if (!isSubmitting && !isSubmitted && isDirty) {
        setShowPromptModal(true);
        return;
      }
    }
    hideModal();
  }

  return (
    <Modal
      show={show}
      onHide={handleHide}
      backdrop="static"
      centered
      size="xl"
    >
      <Modal.Header
        closeButton
        className="mb-3"
      >
        <Modal.Title>
          New Broadcast
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BroadcastForm
          hideModal={hideModal}
          interactionEnabled={show}
          ref={formStateRef}
        />
        <PromptModal
          show={showPromptModal}
          onCancel={() => {
            setShowPromptModal(false);
          }}
          onAbandonChanges={() => {
            setShowPromptModal(false);
            hideModal();
          }}
        />
      </Modal.Body>
    </Modal>
  )
}
