import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'app/appThunk';
import { RootState } from '../types/rootState';
import { ISamlData } from './ISamlData';

export const INITIAL_SAML_DATA : ISamlData = {
  logoutUrl: '/login?src=logout',
}

const SamlDataSlice = createSlice({
  name: 'SamlData',
  initialState: INITIAL_SAML_DATA,
  reducers: {
    setLogoutUrl(draftReducerState, action: PayloadAction<string>) {
      draftReducerState.logoutUrl = action.payload;
    },
  },
});

export const {
  setLogoutUrl,
} = SamlDataSlice.actions;

export default SamlDataSlice.reducer;

export const updateLogoutUrl = (
  logoutUrl: string,
): AppThunk => async (dispatch) => {
  dispatch(setLogoutUrl(logoutUrl));
};

export const getLogoutUrl = (state: RootState): string => state.SamlData.logoutUrl
