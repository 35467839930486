import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { deleteDischargedPatient, patientDataUpdated } from 'reducers/DischargedPatients';
import { IDischargedPatient } from 'types/IDischargedPatient';
import { IPatientFormData } from 'types/IPatientFormData';

interface IDeleteButtonProps{
  data: IPatientFormData
  setSelectedPatient: (patient: IDischargedPatient) => void
}

export const DeleteButton = ({ data, setSelectedPatient }:IDeleteButtonProps):React.ReactElement => {
  const dispatch = useDispatch();

  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const canDeleteDischargedPatients = useHasPermissions('Discharged Patients:Delete');

  const canDeletePatient = isAdmin || isSuper || canDeleteDischargedPatients;

  const deletePatient = useCallback(() => {
    dispatch(deleteDischargedPatient(data.dischargedPatient.DischargePatientId, () => {
      setSelectedPatient(null);
    }));
    dispatch(patientDataUpdated());
  }, [data.dischargedPatient.DischargePatientId, dispatch, setSelectedPatient]);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  return canDeletePatient && (
    <>
      <button
        type="button"
        className="btn btn-link right"
        onClick={(e) => {
          setShowDeleteModal(true);
        }}
      >
        <FontAwesomeIcon icon="trash-alt" color="red" />
      </button>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        className="DeleteActionButtonModal"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header />
        <Modal.Body>
          <FontAwesomeIcon
            icon="trash-alt"
            size="4x"
            color="#FF7052"
          />
          <div className="message">
            <h4>Are you sure?</h4>
            <p>
              This action will delete this patient.
            </p>
          </div>
          <button
            type="button"
            className="btn btn-block btn-danger"
            onClick={() => deletePatient()}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-block btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}
