import produce from 'immer';
import uuidv4 from 'uuid/v4';

import API from './API';
import ProfilesTransformer from './transformers/ProfilesTransformer';
import { IProfilesPayload } from '../types/IProfilesPayload';
import { IProfilesIndexParams } from '../types/IProfilesIndexParams';
import { IProfile, UserType } from '../reducers/IProfile';

export const API_PATH = '/api/v2/Profiles';

export async function profilesIndex({
  filterType = '',
  filterGroup = '',
  searchField = '',
  customFieldName = '',
  searchValue = '',
  sortField = '',
  sortDirection = '',
  page,
  perpage,
}: IProfilesIndexParams): Promise<IProfilesPayload> {
  const params = {
    filterType: (filterType.length > 0 ? filterType : undefined),
    filterGroup: (filterGroup.length > 0 ? filterGroup : undefined),
    searchField: (searchField.length > 0 ? searchField : undefined),
    customFieldName: (customFieldName.length > 0 ? customFieldName : undefined),
    searchValue: (searchValue.trim().length > 0 ? searchValue : undefined),
    sortField: (sortField.length > 0 ? sortField : undefined),
    sortDirection: (sortDirection.length > 0 ? sortDirection : undefined),
    page: (page || undefined),
    perpage: (perpage || undefined),
  };
  const response = await API.get<IProfilesPayload>(API_PATH, { params });
  return produce<IProfilesPayload>(response.data, (draftPayload) => {
    draftPayload.Profiles = draftPayload.Profiles.map((profile) => (
      { ...profile, ...ProfilesTransformer.toState(profile) }));
  });
}

export async function profilesGet(CustomerProfileID: string): Promise<IProfile> {
  const response = await API.get<IProfile>(`${API_PATH}/${CustomerProfileID}/`);
  return { ...response.data, ...ProfilesTransformer.toState(response.data) };
}

export async function profileCustomFieldsGet(CustomerProfileID: string): Promise<{[key: string]: string}> {
  const response = await API.get<{[key: string]: string}>(`${API_PATH}/${CustomerProfileID}/customfields`);
  return response.data;
}

export async function profileCustomFieldsSet(CustomerProfileID: string, customFields: {[key: string]: string}) {
  const response = await API.put(`${API_PATH}/${CustomerProfileID}/customfields`, customFields);
  return response.data;
}

export async function profilesGetByIds(cpids: string[]): Promise<IProfile[]> {
  const response = await API.post<IProfile[]>(`${API_PATH}/ByIds`, cpids);
  return response.data;
}

export async function profilesCreate(
  payload: Partial<IProfile>,
): Promise<Partial<IProfile> & { CustomerProfileID: string }> {
  const CustomerProfileID = payload.CustomerProfileID || uuidv4();
  const postData = ProfilesTransformer.toAPI({ CustomerProfileID, ...payload });

  await API.post<IProfile>(API_PATH, [postData]);
  return { CustomerProfileID, ...ProfilesTransformer.toState(postData) };
}

export async function profilesUpdate(payload: IProfile): Promise<Partial<IProfile>> {
  const data = ProfilesTransformer.toAPI(payload);
  await API.put<string>(`${API_PATH}/${payload.CustomerProfileID}/`, data);
  return payload;
}

export async function profilesDelete(CustomerProfileID: string): Promise<boolean> {
  await API.delete(`${API_PATH}/${CustomerProfileID}/`);
  return true;
}

// Relationship actions

export async function profilePutRelationships(CustomerProfileID: string,
  relatedIds: Array<string>): Promise<Array<string>> {
  await API.put(`${API_PATH}/${CustomerProfileID}/relationships`, relatedIds);
  return relatedIds;
}

export async function profilePostRelationships(CustomerProfileID: string,
  relatedIds: Array<string>): Promise<Array<string>> {
  await API.post(`${API_PATH}/${CustomerProfileID}/relationships`, relatedIds);
  return relatedIds;
}

export async function profileAddGroups(CustomerProfileID: string, groupIDs: Array<number>): Promise<Array<number>> {
  await API.post(`${API_PATH}/${CustomerProfileID}/groups`, groupIDs);
  return groupIDs;
}

export async function profileSetGroups(CustomerProfileID: string, groupIDs: Array<number>): Promise<Array<number>> {
  await API.put(`${API_PATH}/${CustomerProfileID}/groups`, groupIDs);
  return groupIDs;
}

// CM api methods

interface GetCaremergeTenantAccountProfilesParams {
  excludeSharedProfiles?: boolean
  userFilterType?: UserType
  excludeUserFilterType?: UserType
  searchValue?: string
  // searchField: (searchQuery ? SearchField.FULLNAME : undefined),
  page: number
  perpage?: number
}
export async function getCaremergeTenantAccountProfiles(
  accountId: number,
  params: GetCaremergeTenantAccountProfilesParams = { page: 1 },
): Promise<IProfilesPayload> {
  const response = await API.get<IProfilesPayload>(
    `api/v2/caremerge/tenant/account/${accountId}/profiles`,
    { params },
  );
  return response.data;
}

export const copyProfileFromCaremergeTenantAccount = async (profileId: string, accountId: number) => {
  await API.post(`api/v2/caremerge/tenant/account/${accountId}/copy-profile/${profileId}`);
}
