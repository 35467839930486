/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, ReactElement } from 'react';
import useSWRImmutable from 'swr/immutable';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import _once from 'lodash/once';
import _orderBy from 'lodash/orderBy';
import clsx from 'clsx';

import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { useHasFeatures } from 'hooks/useHasFeatures';
import * as InboxApi from 'api/InboxAPI';
import { getUserAccountsSuccess, tryChangeCurrentAccount } from 'reducers/UserInfo';
import { useUserAccounts } from 'components/hooks/useUserAccounts';
import { useCurrentAccount } from 'components/hooks/useCurrentAccount';
import { IUserAccount } from 'reducers/IUserInfoState';

const formatOptionLabel = ({ label, hasNotification }) => (
  <div className={clsx('option-label', hasNotification && 'hasNotification')}>
    <span className="option-label-text" title={label}>
      {label}
    </span>
  </div>
)

interface AccountOption {
  id: number
  label: string
  value: IUserAccount
  hasNotification: boolean
}

function AccountDropDownSelector(): ReactElement | null {
  const containerRef = React.useRef<HTMLDivElement|null>(null);
  const dispatch = useDispatch();
  const [isChangingAccount, setIsChangingAccount] = useState(false);
  const [selectedOption, setSelectedOption] = React.useState<AccountOption|null>(null);

  const canReadInbox = useHasPermissions('Inbox:Read');
  const { hasFeature: hasSmsInboxFeature } = useHasFeatures('sms-inbox');
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);

  const canViewInbox = (canReadInbox || isAdmin || isSuper) && hasSmsInboxFeature;

  const saveAccounts = (userAccountsResponse) => {
    dispatch(getUserAccountsSuccess(userAccountsResponse))
  }
  // eslint-disable-next-line
  const saveAccountsOnce = React.useCallback(_once(saveAccounts), []);

  const { data: userAccounts, mutate: mutateAccounts } = useUserAccounts(
    {
      refreshInterval: canViewInbox ? 60000 : 0,
      onSuccess: saveAccountsOnce,
    },
  );

  const currentAccount = useCurrentAccount();

  useSWRImmutable(
    canViewInbox ? 'inboxUnreadMessageCount' : null,
    InboxApi.getUnreadMessageCount,
    {
      revalidateOnMount: false,
      onSuccess: (unreadConversationCountResponse) => {
        if (!currentAccount) {
          return;
        }
        if ((unreadConversationCountResponse ?? 0) !== (currentAccount.UnreadConversationCount ?? 0)) {
          mutateAccounts(
            userAccounts
              .map((acc) => {
                if (acc === currentAccount) {
                  return {
                    ...acc,
                    UnreadConversationCount: unreadConversationCountResponse ?? 0,
                  }
                }

                return acc;
              }),
            { revalidate: false });
        }
      },
    },
  );

  if (!currentAccount) {
    return null;
  }

  if (userAccounts.length < 2) {
    return (
      <div className="account-switcher">
        <div className="account-switcher__single">{currentAccount.Name}</div>
      </div>
    );
  }

  const accountOptions = _orderBy(
    userAccounts.map<AccountOption>((account) => ({
      id: account.Id,
      label: account.Name,
      value: account,
      hasNotification: !!account.UnreadConversationCount,
    })),
    ['hasNotification', (obj) => obj.label.toLowerCase()],
    ['desc', 'asc'],
  );

  const defaultSelectedOption = accountOptions.find((x) => x.id === currentAccount.Id);

  const redirectPostAccChange = (hasNotification: boolean) => () => {
    window.location.assign(hasNotification ? '/smsinbox' : '/calendar');
  };

  const totalNotificationCount = userAccounts.reduce(
    (sum, account) => sum + (account.UnreadConversationCount ?? 0),
    0,
  );

  const onTotalCountClick = () => {
    const mouseDownEvent = new MouseEvent('mousedown', {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    containerRef.current?.getElementsByClassName('account-switcher__select__control')?.
      [0]?.dispatchEvent(mouseDownEvent);
  }

  return (
    <div
      className="account-switcher"
      ref={containerRef}
    >
      {totalNotificationCount > 0 && (
        <div
          className="account-switcher__total-count"
          onClick={onTotalCountClick}
        >
          {totalNotificationCount > 99
            ? '99+'
            : totalNotificationCount}
        </div>
      )}
      <Select
        value={selectedOption ?? defaultSelectedOption}
        className="account-switcher__select"
        classNamePrefix="account-switcher__select"
        options={accountOptions}
        components={{
          IndicatorSeparator: () => null,
        }}
        formatOptionLabel={formatOptionLabel}
        onChange={(value: AccountOption) => {
          dispatch(tryChangeCurrentAccount(value.id, redirectPostAccChange(value.hasNotification), () => {
            setSelectedOption(selectedOption);
            setIsChangingAccount(false);
          }));
          setIsChangingAccount(true);
          setSelectedOption(value);
        }}
      />
      <p className="pt-3 text-center account-switcher__loading">
        {isChangingAccount && 'Loading new account...'}
      </p>
    </div>
  );
}
export default AccountDropDownSelector;
