import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { AppThunk } from 'app/appThunk';
import { IErrorState } from './IErrorState';

export const INITIAL_STATE: IErrorState = {
  error: null,
  isOpen: false,
};
const ErrorSlice = createSlice({
  name: 'Error',
  initialState: INITIAL_STATE,
  reducers: {
    setError(draftReducerState, error) {
      draftReducerState.isOpen = true;
      draftReducerState.error = error.payload;

      // on redirects some requests may be aborted and we don't want to show an error for that
      if (error.payload !== 'Request aborted') {
        toast.error(error.payload);
      }
    },
    hideError(draftReducerState) {
      draftReducerState.error = null;
      draftReducerState.isOpen = false;
    },
  },
});

export const {
  hideError,
} = ErrorSlice.actions;

export default ErrorSlice.reducer;

export const hasError = (error: AxiosError|Error): AppThunk => (dispatch) => {
  const message = (error as AxiosError).response?.data?.Message ?? error.message;
  dispatch(ErrorSlice.actions.setError(message))
}
