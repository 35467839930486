import React, { ReactElement, useState, useMemo } from 'react';
import useSWR from 'swr/immutable';
import _groupBy from 'lodash/groupBy';

import * as GroupsAPI from 'api/GroupsAPI';
import { useIsSyncedCustomer } from 'hooks/useIsSyncedCustomer';
import { IGroup, ICaremergeCategory } from 'types/IGroup';
import {
  Accordion, Row, Col,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RootState } from 'types/rootState';
import ToggleHeader from 'components/ToggleHeader';
import GroupItem from './GroupItem';

interface IProps {
  groups: IGroup[]
  setEditMode: (boolean) => void
  onGroupSelect: (groupId: string) => void
}

function GroupsList({ groups, setEditMode, onGroupSelect }: IProps): ReactElement {
  const selectedGroupId = useSelector((state: RootState) => state.Groups.selectedGroupId);
  const groupsLookup = useSelector((state: RootState) => state.Groups.byIds);
  const selectedGroup = groupsLookup[selectedGroupId];
  const defaultKey = selectedGroup?.ParentGroupID === 0 ? selectedGroupId
    : selectedGroup?.ParentGroupID;
  const [activeKey, setActiveKey] = useState(defaultKey?.toString());
  const [categoryActiveKey, setCategoryActiveKey] = useState(null);
  const getRowColor = (index: number): string => {
    if (index % 2 === 1) {
      return 'oddGroupItem';
    }
    return 'evenGroupItem';
  }
  const groupsLoading = useSelector((state: RootState) => state.Groups.loading)

  const isSyncedCustomer = useIsSyncedCustomer();
  const { data: caremergeCategories } = useSWR(
    isSyncedCustomer ? 'allCaremergeCategories' : null,
    GroupsAPI.getAllCaremergeCategories,
  );

  const programLevelCategorizedGroups: [ICaremergeCategory|null, IGroup[]][] = useMemo(() => {
    const programCategories = groups.filter((g) => g.ProgramLevelId > 0);
    if (!groups || !programCategories) {
      return [];
    }

    const groupsByProgramId = _groupBy(
      programCategories,
      (group) => (group.ProgramLevelId > 0),
    );
    return Object.entries(groupsByProgramId)
      .reduce((acc, [programId, programGroups]) => [[
        { CaremergeCategoryId: -1, CategoryName: 'Program Level' },
        programGroups,
      ], ...acc], [])
  }, [groups]);

  const serviceLevelCategorizedGroups: [ICaremergeCategory|null, IGroup[]][] = useMemo(() => {
    const serviceCategories = groups.filter((g) => g.ServiceLevelId > 0);
    if (!groups || !serviceCategories) {
      return [];
    }

    const groupsByServiceId = _groupBy(
      serviceCategories,
      (group) => (group.ServiceLevelId > 0),
    );
    return Object.entries(groupsByServiceId)
      .reduce((acc, [serviceId, serviceGroups]) => [[
        { CaremergeCategoryId: -2, CategoryName: 'Service Level' },
        serviceGroups,
      ], ...acc], [])
  }, [groups]);

  const categorizedGroups: [ICaremergeCategory|null, IGroup[]][] = useMemo(() => {
    if (!groups || !caremergeCategories) {
      return [];
    }

    const groupsByCategoryId = _groupBy(
      groups,
      (group) => (!group.CaremergeCategoryId ? 0 : group.CaremergeCategoryId),
    );

    const mainCaremergeCategories = Object.entries(groupsByCategoryId)
      .reduce((acc, [categoryId, categoryGroups]) => {
        let ccFindResult: ICaremergeCategory = caremergeCategories.find((cat) => `${cat.CaremergeCategoryId}` === categoryId);
        if (categoryId === '0') {
          return [...acc, [null, categoryGroups]];
        }
        if (!ccFindResult && categoryId !== '0') {
          ccFindResult = {
            CaremergeCategoryId: +categoryId,
            CategoryName: 'Unknown',
          }
        }
        return [[
          ccFindResult,
          categoryGroups,
        ], ...acc]
      }, [])

    return [...serviceLevelCategorizedGroups, ...programLevelCategorizedGroups, ...mainCaremergeCategories];
  }, [caremergeCategories, programLevelCategorizedGroups, serviceLevelCategorizedGroups, groups]);

  return (
    <Accordion
      activeKey={activeKey}
      onSelect={(e: any) => setActiveKey(e)}
    >
      {!groupsLoading && (groups === null || groups.length === 0) && (
        <span>There are no groups. Click &quot;Create Group&quot; to create a group.</span>
      )}

      {isSyncedCustomer ? (
        <Accordion
          activeKey={categoryActiveKey}
          onSelect={(e: any) => setCategoryActiveKey(e)}
        >
          {
            categorizedGroups.map(([category, categoryGroups], catIndex) => {
              const categoryId = category?.CaremergeCategoryId.toString() ?? '0';
              return (
                <div>
                  <div className="groupWrapper">
                    <ToggleHeader
                      eventKey={categoryId}
                      setActiveKey={setCategoryActiveKey}
                      className={`rounded-0 py-0 ${getRowColor(catIndex)}`}
                    >
                      <Row
                        className="consist-height"
                      >
                        <Col xs={1}>
                          {categoryActiveKey !== categoryId
                            ? <FontAwesomeIcon icon="caret-right" />
                            : <FontAwesomeIcon icon="caret-down" />}
                        </Col>
                        <Col xs={6} className="ellipsis">
                          <span
                            style={{ fontWeight: category?.CategoryName ? 'bold' : '' }}
                            title={category?.CategoryName ?? 'Uncategorized'}
                          >
                            {category?.CategoryName ?? 'Uncategorized'}
                          </span>
                        </Col>

                      </Row>
                    </ToggleHeader>
                    <Accordion.Collapse eventKey={categoryId}>
                      <div className="accordion">
                        {categoryGroups?.map((group, index) => {
                          // remove the program and service level group from uncategory
                          if (categoryId === '0' && group?.IsReadonly) return null;
                          return (
                            <GroupItem
                              key={group.Id}
                              group={group}
                              activeKey={activeKey}
                              setActiveKey={setActiveKey}
                              rowColor={getRowColor(index)}
                              setEditMode={setEditMode}
                              onGroupSelect={onGroupSelect}
                            />
                          )
                        })}
                      </div>
                    </Accordion.Collapse>
                  </div>
                </div>
              )
            },
            )
          }

        </Accordion>
      ) : (
        groups?.map((group, index) => (
          <GroupItem
            key={group.Id}
            group={group}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            rowColor={getRowColor(index)}
            setEditMode={setEditMode}
            onGroupSelect={onGroupSelect}
          />
        ))
      )}
    </Accordion>
  )
}

export default GroupsList;
