import React, { FC } from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { AudioPlayer } from 'components/AudioPlayer';
import { getGroupByIds } from 'reducers/Groups';
import { getProfilesByIds } from 'reducers/Profiles';
import { IEvent } from 'types/IEvent';
import { bin2string } from 'utils/stringUtils';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';

type ContentViewProps = {
  data: IEvent
};

export const ContentView: FC<ContentViewProps> = ({
  data,
}) => {
  const selectedGroups = useSelector(getGroupByIds(data.GroupIds || []));
  const eventGroups = selectedGroups.map((group) => group.Name);
  const eventProfiles = useSelector(
    getProfilesByIds(data.ProfileIds || []),
  ).map((profile) => `${profile.FirstName} ${profile.LastName}`);

  const groupRecipientCount = selectedGroups.reduce(
    (sum, group) => sum + group.ProfileCount,
    0,
  );

  const translateUserType = useUserTypeTranslation()
  const formatUserType = (ut: string) => translateUserType(ut);

  return (
    <div>
      <h3 className="pb-2">
        {data.Name}
      </h3>
      <p className="mb-2">{data.Description}</p>
      <p>{data.Location}</p>

      <Card className="mt-4">
        <Card.Header as="h5">Summary</Card.Header>
        <Card.Body>
          <Row className="pt-4">
            <Col>
              <Card>
                <Card.Header as="h6">Message Recipients</Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      {groupRecipientCount === 0 ? (
                        <h6>No Groups selected</h6>
                      ) : (
                        <>
                          <h6>
                            Groups (
                            {groupRecipientCount}
                            {' '}
                            recipients)
                          </h6>
                          {eventGroups.map((group, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <p className="m-0" key={index}>
                              {formatUserType(group)}
                            </p>
                          ))}
                        </>
                      )}
                    </Col>
                    <Col>
                      {eventProfiles.length === 0 ? (
                        <h6>No Profiles selected</h6>
                      ) : (
                        <>
                          <h6>
                            Profiles (
                            {eventProfiles.length}
                            {' '}
                            recipients)
                          </h6>
                          {eventProfiles.map((profile, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <p className="m-0" key={index}>
                              {profile}
                            </p>
                          ))}
                        </>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              {data.VoiceContent && (
                <Card>
                  <Card.Header as="h6">
                    <FontAwesomeIcon className="mr-1" icon="phone" />
                    {' '}
                    Voice Message
                  </Card.Header>
                  <Card.Body>
                    {data.VoiceContent.Content && (
                      <p className="pt-3">{data.VoiceContent.Content}</p>
                    )}
                    {data.VoiceContent.VoiceRecording && (
                      <AudioPlayer
                        src={`data:audio/wav;base64,${data.VoiceContent.VoiceRecording.Data}`}
                      />
                    )}
                  </Card.Body>
                </Card>
              )}
              {data.EmailContent && (
                <Card className={data.VoiceContent ? 'mt-4' : ''}>
                  <Card.Header as="h6">
                    <FontAwesomeIcon className="mr-1" icon="envelope-square" />
                    {' '}
                    Email Message
                  </Card.Header>
                  <Card.Body>
                    <p className="m-0">
                      <strong>Subject:</strong>
                      {' '}
                      {data.EmailContent.Subject}
                    </p>
                    <p className="m-0">
                      <strong>From:</strong>
                      {' '}
                      {data.EmailContent.From}
                      {' '}
                      (
                      {data.EmailContent.Display}
                      )
                    </p>
                    <p className="m-0">
                      <strong>Reply To:</strong>
                      {' '}
                      {data.EmailContent.ReplyTo}
                    </p>
                    <p
                      className={clsx('pt-3', !data.IsMarketing && 'fr-view')}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: data.EmailContent.Content
                          ? bin2string(data.EmailContent.Content)
                          : '',
                      }}
                    />
                    {data.EmailContent.Attachments && (
                      <>
                        <p className="m-0">
                          <strong>Attachments:</strong>
                        </p>
                        {data.EmailContent.Attachments.map(({ FileName, DocumentId }) => (
                          <p key={DocumentId}>{FileName}</p>
                        ))}
                      </>
                    )}
                  </Card.Body>
                </Card>
              )}
              {data.SMSContent && (
                <Card className={data.VoiceContent || data.EmailContent ? 'mt-4' : ''}>
                  <Card.Header as="h6">
                    <FontAwesomeIcon className="mr-1" icon="comment" />
                    {' '}
                    Text Message
                  </Card.Header>
                  <Card.Body>
                    <p>{data.SMSContent.Content}</p>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>

    </div>
  );
};
