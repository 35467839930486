import React, { FC } from 'react';
import { TimePicker } from 'components/TimePicker';
import { useFormContext, Controller } from 'react-hook-form';
import moment from 'moment';
import { isIE } from 'react-device-detect';

export interface TimePickerProps {
  name: string
  defaultValue?: any
  validate: (any) => boolean
}

export const ControlledTimePicker: FC<TimePickerProps> = ({
  name, validate, defaultValue,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <TimePicker
          className={isIE ? 'rc-time-picker-IE' : ''}
          popupClassName="event-template-preview"
          allowEmpty={false}
          value={value ? moment(value, 'HH:mm') : moment()}
          showSecond={false}
          onChange={(time) => {
            onChange(time.format('HH:mm'));
          }}
          use12Hours
        />
      )}
      rules={{
        required: true,
        validate,
      }}
    />
  )
};
