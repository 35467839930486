import React from 'react';
import { useFormContext } from 'react-hook-form';

import { RawDateInput, Checkbox } from 'components/FormControls';
import { IFormData } from 'types/IProfileForm';

export const InactivePeriodDates = () => {
  const {
    watch, setValue, control, errors,
  } = useFormContext<IFormData>();

  const isInactived = watch('IsInactive');

  React.useEffect(() => {
    if (!isInactived) {
      // When we uncheck the text box, the fields should be disabled and cleared
      setValue('InactiveStartDate', null, { shouldDirty: false, shouldValidate: true });
      setValue('InactiveEndDate', null, { shouldDirty: false, shouldValidate: true });
    }
  }, [isInactived, setValue]);

  return (
    <div>
      <Checkbox
        id="IsInactive"
        name="IsInactive"
        label="Inactive Period:"
        control={control}
      />
      <div className="row">
        <RawDateInput
          id="InactiveStartDate"
          name="InactiveStartDate"
          label="Start Date"
          control={control}
          className="col"
          useSingleMonth
          errors={errors.InactiveStartDate?.message}
          disabled={!isInactived}
        />
        <RawDateInput
          id="InactiveEndDate"
          name="InactiveEndDate"
          label="End Date"
          control={control}
          className="col"
          useSingleMonth
          errors={errors.InactiveEndDate?.message}
          disabled={!isInactived}
        />
      </div>
    </div>
  )
}
