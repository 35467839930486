import React, { forwardRef } from 'react';
import clsx from 'clsx';

import { ReactComponent as Loupe } from 'styles/images/loupe.svg';
import Input, { InputProps } from './Input';

export interface SearchInputProps extends Omit<InputProps, 'type'> {
  type?: string
  // todo: remove this
  onButtonClick?: VoidFunction
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({
    className, onButtonClick, type = 'text', ...props
  }, ref) => (
    <Input
      className={clsx('SearchInput', className)}
      type={type}
      icon={Loupe}
      ref={ref}
      {...props}
    />
  ),
);

export default SearchInput;
