import React, {
  useState, useEffect, useMemo, ReactElement, FC,
} from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { getCurrentNavItem, setCurrentNavItem } from 'reducers/UserInfo';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spinner } from 'react-bootstrap';
import { RootState } from 'types/rootState';
import { IUserInfoState } from 'reducers/IUserInfoState';

interface RenderLabelProps {
  label: string
  isOpen?: boolean // for parent navitem
  isParentOpen?: boolean // for child navitem
}

export interface INavItem {
  label: string
  renderLabel?: (renderLabelProps: RenderLabelProps) => React.ReactNode
  target: string
  icon?: IconProp
  children?: INavItem[]
  external?: boolean
  actionIcon?: IconProp
  matchLocations?: string[]
  useNewWindow?: boolean
  shouldDisplay?: (state: IUserInfoState) => boolean
  hasParent?: boolean
  callBack?: () => void
}

interface IProps {
  item: INavItem
  openMenuHandler: (open: boolean) => void
  isParentOpen?: boolean // only for child navitem
}

const NavItemSubnav: FC<IProps> = ({ item, openMenuHandler, isParentOpen }) => {
  const [openSubnav, setOpenSubnav] = useState<boolean>(false);
  const currentNavItem = useSelector(getCurrentNavItem);

  useEffect(() => {
    // just 2 level for now, should be support infinite
    const matched = item.children.find((i) => i.label === currentNavItem);
    const matchedTwoLevel = item.children.find((i) => {
      if (i.hasParent && i.children) {
        return i.children.find((j) => j.label === currentNavItem);
      }
      return null;
    });
    setOpenSubnav(matched != null || matchedTwoLevel != null);
  }, [currentNavItem, item.children, setOpenSubnav]);

  return (
    <div
      onClick={(event) => {
        if (item.hasParent) { event.stopPropagation(); }
        setOpenSubnav(!openSubnav);
      }}
      role="presentation"
    >
      <span className="title" id={`navbar-${item.label}`}>
        {item.icon && <FontAwesomeIcon icon={item.icon} />}
        {item.renderLabel?.({ isOpen: openSubnav, label: item.label }) ?? <span className="text">{item.label}</span>}
      </span>
      <ul className={`subnav ${openSubnav ? 'open' : ''}`}>
        {item.children.map((child, index) => (
          <NavItem
            // eslint-disable-next-line react/no-array-index-key
            key={`item-${item.label}-${index}`}
            item={child}
            openMenuHandler={openMenuHandler}
            isParentOpen={openSubnav}
          />
        ))}
      </ul>
      <div className="subnav-toggle">
        <FontAwesomeIcon icon={openSubnav ? 'caret-down' : 'caret-right'} />
      </div>
    </div>
  );
};

const LoadingLinknModal: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div
      onKeyPress={() => setIsLoading(true)}
      onClick={() => setIsLoading(true)}
      role="presentation"
    >
      {children}
      <Modal
        show={isLoading}
        className="DeleteActionButtonModal DeleteActionButtonModal__no-bg"
        backdrop="static"
        centered
        size="sm"
      >
        <Modal.Body>
          <Spinner animation="border" variant="primary" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default function NavItem({ item, openMenuHandler, isParentOpen }: IProps): ReactElement {
  const currentNavItem = useSelector(getCurrentNavItem);
  const userInfo = useSelector((state: RootState) => state.UserInfo);
  const shouldDisplay = useMemo(() => {
    if (!item.shouldDisplay) {
      return true;
    }
    if (!userInfo) {
      return false;
    }
    return item.shouldDisplay(userInfo);
  }, [item, userInfo])
  const dispatch = useDispatch();
  // hide this element if the user cannot access the feature
  if (!shouldDisplay) {
    return null;
  }

  if (item.children) {
    return (
      <li className="NavItem fa-fw">
        <NavItemSubnav item={item} openMenuHandler={openMenuHandler} />
      </li>
    );
  }

  const renderLinkContent = () => (
    <>
      {item.icon && <FontAwesomeIcon icon={item.icon} />}
      {item.renderLabel?.({ isParentOpen, label: item.label }) ?? <span className="text">{item.label}</span>}
    </>
  );

  const renderExternalLink = () => {
    if (item.useNewWindow) {
      return (
        <a
          href={item.target}
          onClick={() => {
            if (item.callBack) {
              item.callBack();
            }
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {renderLinkContent()}

        </a>
      )
    }
    return (
      <LoadingLinknModal>
        <a href={item.target}>{renderLinkContent()}</a>
      </LoadingLinknModal>
    )
  }

  if (item.external) {
    return (
      <li className="NavItem fa-fw">
        {renderExternalLink()}
      </li>
    )
  }

  return (
    <li className="NavItem fa-fw">
      <NavLink
        id={`navbar-${item.label.replace(/\s/g, '')}`}
        activeClassName="active"
        to={item.target}
        onClick={(event) => {
          event.stopPropagation();
          openMenuHandler(false);
          dispatch(setCurrentNavItem(item.label));
        }}
        isActive={!item.matchLocations
          ? undefined
          : (_, location) => item.matchLocations.includes(location.pathname) || item.label === currentNavItem}
      >
        {renderLinkContent()}
      </NavLink>
    </li>
  );
}
