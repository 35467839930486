import React, {
  useCallback, useEffect, ReactElement, forwardRef, useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { EventType, IEvent } from 'types/IEvent';
import {
  selectEventsLoading,
  getEventsIndex,
  selectEventsPage,
  selectPaginationData,
} from 'reducers/Events';
import Table from 'components/Table';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { IFilterableIndexParams } from 'types/ITemplatesIndexParams';
import IndexFilter, {
  ISearchQuery,
} from 'components/Inputs/IndexFilter_Id_Name';
import Pagination from 'components/Pagination';
import { CellProps } from 'react-table';
import { Tab, Tabs } from 'react-bootstrap';

const columns = [
  {
    Header: 'ID',
    accessor: 'ID',
    sortable: true,
  },
  {
    Header: 'Name',
    accessor: 'Name',
    sortable: true,
    Cell: (props: CellProps<IEvent>): ReactElement => {
      const {
        row: { original },
      } = props;
      if (original?.Status !== 'Stopped') return (<span>{original?.Name}</span>);
      return (
        <>
          <span style={{
            fontWeight: 700,
            fontSize: 20,
            color: '#F88225',
          }}
          >
            <i title="stopped" className="fas fa-ban" />
          </span>
          {' '}
          {original?.Name}
        </>
      );
    },
  },
  { Header: 'Description', accessor: 'Description', sortable: true },
];

type Props = {
  showActions?: boolean
  rowActionItem: (id: string|number, entity: IEvent) => ReactElement
  eventType?: string
  filterTitle?:string
  onRowClick: (id: string) => void
};

export const EventsTable = forwardRef(({
  showActions, rowActionItem, eventType, filterTitle, onRowClick,
}: Props, ref: any) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useQueryParam('page', NumberParam);
  const [searchQuery, setSearchQuery] = useQueryParam(
    'searchQuery',
    StringParam,
  );
  const [sortField, setSortField] = useQueryParam('sortField', StringParam);
  const [sortDirection, setSortDirection] = useQueryParam(
    'sortDirection',
    StringParam,
  );
  const [selectedTab, setSelectedTab] = useState<string>('active');

  const resetFilters = (): void => {
    setSearchQuery('', 'pushIn');
    setCurrentPage(1, 'pushIn');
  };

  const getSearchQuery = useCallback((): ISearchQuery => {
    const query = searchQuery?.split(',');
    if (!query) return {};
    return { searchField: query[0], searchValue: query[1] };
  }, [searchQuery]);

  const getEventsIndexParams = useCallback(() => {
    const params: IFilterableIndexParams = {
      page: currentPage,
      perpage: 10,
    };

    const query = getSearchQuery();
    if (query.searchField && query.searchValue) {
      params.searchField = query.searchField;
      params.searchValue = query.searchValue;
    }

    if (sortField && sortDirection) {
      params.sortField = sortField;
      params.sortDirection = sortDirection;
    }
    params.type = eventType;
    params.statusType = selectedTab;

    return params;
  }, [currentPage, getSearchQuery, sortField, sortDirection, eventType, selectedTab]);

  const dispatchEventsIndex = useCallback((): void => {
    dispatch(getEventsIndex(getEventsIndexParams()));
  }, [getEventsIndexParams, dispatch]);

  useEffect(() => {
    dispatchEventsIndex();
  }, [dispatchEventsIndex]);

  useEffect(() => {
    if (ref) {
      // eslint-disable-next-line no-param-reassign
      ref.current = dispatchEventsIndex;
    }
  }, [ref, dispatchEventsIndex]);

  const events: IEvent[] = useSelector(selectEventsPage(getEventsIndexParams()));
  const paginationData = useSelector(selectPaginationData);
  const isLoading = useSelector(selectEventsLoading);

  const activeEvents = events.filter((x) => x.Status !== 'Complete')
  const completedEvents = events.filter((x) => x.Status === 'Complete')

  const onSearch = (searchingQuery: ISearchQuery): void => {
    if (searchingQuery.searchField || searchingQuery.searchValue) {
      setSearchQuery(
        `${searchingQuery.searchField || ''},${searchingQuery?.searchValue || ''}`,
      );
    } else {
      setSearchQuery('');
    }
    setCurrentPage(1, 'pushIn');
  };

  const renderIndexFilter = (): ReactElement => (
    <IndexFilter
      searchQuery={getSearchQuery()}
      onSearch={onSearch}
      onResetFilters={resetFilters}
      title={filterTitle}
      totalItems={paginationData.TotalItems}
      itemsLoading={isLoading}
      defaultField="name"
    />
  );

  return (
    <div>
      {renderIndexFilter()}

      {eventType === EventType.SURVEY && (
        <Tabs
          defaultActiveKey="active"
          onSelect={(val) => {
            setCurrentPage(1)
            setSelectedTab(val)
          }}
        >
          <Tab eventKey="active" title="Active Surveys">
            <Table<IEvent>
              columns={columns}
              data={activeEvents}
              rowIDAccessor="ID"
              rowActionItems={showActions ? rowActionItem : undefined}
              onRowClick={onRowClick}
              enableCheck={false}
              isLoading={isLoading}
              onColumnSort={(fieldName, direction) => {
                if (fieldName.length > 0) {
                  setSortField(fieldName);
                  setSortDirection(direction);
                } else {
                  setSortField('');
                  setSortDirection('');
                }
              }}
              sortedColumn={sortField}
              sortedColumnDirection={sortDirection}
            />
          </Tab>
          <Tab eventKey="completed" title="Completed Surveys">
            <Table<IEvent>
              columns={columns}
              data={completedEvents}
              rowIDAccessor="ID"
              rowActionItems={showActions ? rowActionItem : undefined}
              onRowClick={onRowClick}
              enableCheck={false}
              isLoading={isLoading}
              onColumnSort={(fieldName, direction) => {
                if (fieldName.length > 0) {
                  setSortField(fieldName);
                  setSortDirection(direction);
                } else {
                  setSortField('');
                  setSortDirection('');
                }
              }}
              sortedColumn={sortField}
              sortedColumnDirection={sortDirection}
            />
          </Tab>
        </Tabs>
      )}
      {eventType !== EventType.SURVEY && (
        <Table<IEvent>
          columns={columns}
          data={events}
          rowIDAccessor="ID"
          rowActionItems={showActions ? rowActionItem : undefined}
          onRowClick={onRowClick}
          enableCheck={false}
          isLoading={isLoading}
          onColumnSort={(fieldName, direction) => {
            if (fieldName.length > 0) {
              setSortField(fieldName);
              setSortDirection(direction);
            } else {
              setSortField('');
              setSortDirection('');
            }
          }}
          defaultSortedColumn={sortField}
          defaultSortedColumnDirection={sortDirection}
        />
      )}

      <Pagination
        currentPage={currentPage || 1}
        totalPages={paginationData.TotalPages}
        onPageChange={(page: number) => setCurrentPage(page, 'pushIn')}
        pageDelta={5}
      />
    </div>
  );
});
