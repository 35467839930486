import React, {
  FC, useEffect, useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import {
  getSurveyQuestionList,
  selectById,
  getSurveyQuestionListItemIndex,
} from 'reducers/SurveyQuestionList';
import { getLibraryContent } from 'reducers/LibraryContent';
import QuestionsCard from './components/QuestionsCard';
import { FarewellCard } from './components/FarewellCard';
import { GreetingCard } from './components/GreetingCard';
import EditModal from './components/EditModal';

const SurveyQuestionListView: FC = () => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const questionListData = useSelector(selectById);

  useEffect(() => {
    dispatch(getSurveyQuestionList(+id));
    dispatch(getSurveyQuestionListItemIndex(+id));
    dispatch(getLibraryContent());
  }, [dispatch, id]);

  return (
    <div className="SurveyQuestionList">
      <Helmet>
        <title>
          Survey Question List #
          {id}
        </title>
      </Helmet>

      <div className="header-bar mb-4">
        <h1>
          Survey Question List #
          {id}
        </h1>
        <div className="action-bar">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            Edit
          </button>
          <button
            type="button"
            className="btn btn-primary ml-3"
            onClick={() => {
              history.push('/surveyQuestionLists')
            }}
          >
            Done
          </button>
        </div>
      </div>
      <Row>
        <Col>
          <h5>
            Name:
          </h5>
          <p>
            {questionListData?.Name ?? ''}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <GreetingCard />
        </Col>
      </Row>
      <Row>
        <Col>
          <QuestionsCard />
        </Col>
      </Row>
      <Row>
        <Col>
          <FarewellCard />
        </Col>
      </Row>
      <EditModal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
      />
    </div>
  );
}

export default SurveyQuestionListView;
