import React, {
  FC, useState, ReactElement, ChangeEvent, useEffect, useRef, useCallback,
} from 'react';
import clsx from 'clsx';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  useTable, TableOptions, Row, ColumnInstance, useColumnOrder,
} from 'react-table';
import { ReactSortable } from 'react-sortablejs';
import { Button, Spinner } from 'react-bootstrap';
import _difference from 'lodash/difference';

import { useCentering } from 'hooks/useCentering';
import log from 'lib/logging';

interface IProps<T> extends TableOptions<any> {
  rowIDAccessor?: string
  selectedRowIds?: string[]
  enableCheck?: boolean
  selectButton?: string
  onColumnSort?: (fieldName: string, direction: 'asc' | 'desc') => void
  rowActionItems?: (itemId: string|number, item: T) => ReactElement
  onRowClick?: (itemId: string, item: any) => void
  onRowSelect?: (selectedRowIds: string[]) => void
  displayedColumns?: string[]
  columnOrder?: string[]
  updateData?: (newData: any[]) => void
  draggableRows?: boolean
  className?: string
  style?: React.CSSProperties
  isLoading?: boolean
  sortedColumn?: string
  sortedColumnDirection?: string
  defaultSortedColumn?: string
  defaultSortedColumnDirection?: string
}

interface ITableBodyProps extends Record<string, any> {
  updateData?: (newData: any[]) => void
  draggableRows?: boolean
  data: IProps<any>['data']
}

const TableBody: FC<ITableBodyProps> = ({
  draggableRows, data, updateData, children, ...props
}) => {
  const setListCalledOnceRef = useRef<boolean>(false);

  useEffect(() => {
    if (draggableRows && !updateData) {
      log.error('\'draggableRows\' = true, requires a \'updateData\' handler');
    }
  }, [draggableRows, updateData])

  if (!draggableRows) {
    return (
      <tbody {...props}>
        {children}
      </tbody>
    );
  }

  // fixes https://github.com/SortableJS/react-sortablejs/issues/197
  const filteredChildren = React.Children.toArray(children).filter((child) => child !== null);

  // handles 'no results' row
  const list = !data.length ? [{ ID: 0, filtered: true }] : data;

  const setList = (newList) => {
    if (!setListCalledOnceRef.current) {
      setListCalledOnceRef.current = true;

      return;
    }
    updateData(newList);
  }

  return (
    <ReactSortable
      list={list}
      setList={setList}
      animation={150}
      handle=".Table__draggable-handle"
      draggable="tr"
      filter=".Table__Row--sortable-ignored"
      tag="tbody"
      {...props}
    >
      {filteredChildren}
    </ReactSortable>
  );
}

// const Table: React.FC<IProps> = ({
function Table<T>({
  columns,
  data,
  rowIDAccessor = '',
  enableCheck = true,
  selectButton = null,
  rowActionItems,
  selectedRowIds,
  onRowClick,
  onRowSelect,
  onColumnSort,
  draggableRows = false, displayedColumns,
  columnOrder,
  updateData,
  className,
  isLoading,
  sortedColumn: sortedColumnProp,
  sortedColumnDirection: sortedColumnDirectionProp,
  defaultSortedColumn = '',
  defaultSortedColumnDirection = 'asc',
  style = {},
}: IProps<T>): ReactElement {
  const getRowId = useCallback((row, relativeIndex, parent?) => (
    row.ID ?? (parent ? [parent.id, relativeIndex].join('.') : relativeIndex)
  ), []);

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    headerGroups,
    prepareRow,
    setHiddenColumns,
    setColumnOrder,
  } = (useTable(
    {
      columns,
      data,
      getRowId,
    },
    useColumnOrder,
  )) as any;

  const [localSortedColumn, setLocalSortedColumn] = useState<string>(defaultSortedColumn);
  const [localSortedColumnDirection, setLocalSortedColumnDirection] = useState<'asc' | 'desc'>(
    defaultSortedColumnDirection as 'asc'|'desc',
  );

  const sortedColumn = sortedColumnProp ?? localSortedColumn;
  const sortedColumnDirection = sortedColumnDirectionProp ?? localSortedColumnDirection;

  useEffect(() => {
    if (Array.isArray(displayedColumns)) {
      setHiddenColumns(_difference(columns.map((item) => item.accessor), displayedColumns));
    }
  }, [setHiddenColumns, displayedColumns, columns]);

  useEffect(() => {
    if (Array.isArray(columnOrder)) {
      setColumnOrder(columnOrder);
    }
  }, [setColumnOrder, columnOrder]);

  const onHeaderChecked = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    const { checked } = event.target;

    if (checked) {
      onRowSelect?.call(
        undefined,
        rows.map((row) => row.original[rowIDAccessor]),
      );
    } else {
      onRowSelect?.call(undefined, []);
    }
  };

  const onRowChecked = (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ): void => {
    const { checked } = event.target;

    if (checked) {
      onRowSelect?.call(undefined, [...(selectedRowIds || []), id]);
    } else {
      onRowSelect?.call(
        undefined,
        (selectedRowIds || []).filter((item) => item !== id),
      );
    }
  };

  const sortableClick = (
    column: ColumnInstance<any> & { sortable?: boolean },
  ): void => {
    if (column.sortable && onColumnSort) {
      const columnName = column.id;

      if (
        sortedColumn === columnName
        && sortedColumnDirection === 'asc'
      ) {
        // if uncontrolled
        if (!sortedColumnProp) {
          setLocalSortedColumnDirection('desc');
        }

        onColumnSort(columnName, 'desc');
        return;
      }

      // if uncontrolled
      if (!sortedColumnProp) {
        setLocalSortedColumn(columnName);
        setLocalSortedColumnDirection('asc');
      }

      onColumnSort(columnName, 'asc');
    }
  };

  const renderActionsHeader = (): ReactElement | undefined => {
    if (rowIDAccessor.length > 0 && rowActionItems) {
      return <th className="actions">Actions</th>;
    }
    return null;
  };

  const renderActionsCell = (row: Row<any>): ReactElement | undefined => {
    if (rowIDAccessor.length > 0 && rowActionItems) {
      const item = row.original;
      const itemID = item[rowIDAccessor];
      return (
        <td
          className="actions"
        >
          {rowActionItems(itemID, item)}
        </td>
      );
    }
    return null;
  };

  const rowSelected = (row: Row<any>): boolean => {
    const entityID = row.original[rowIDAccessor];
    return selectedRowIds?.includes(entityID) || false;
  };

  const renderNoResults = (): ReactElement | null => {
    if (!rows.length && !isLoading) {
      return (
        <tr className="Table__Row--sortable-ignored">
          <td colSpan={columns.length + 2}>No records found.</td>
        </tr>
      );
    }

    return null;
  };

  const sortColumnClass = (
    column: ColumnInstance<any> & { sortable?: boolean },
  ): string => {
    if (!column.sortable) return '';
    return `sortable ${
      sortedColumn === column.id ? sortedColumnDirection : ''
    }`;
  };

  const containerRef = React.useRef<HTMLDivElement|null>(null);
  const loadingRef = React.useRef<HTMLDivElement|null>(null);

  const showLoading = !rows.length && isLoading;

  const centeringContainerProps = useCentering(
    loadingRef,
    containerRef,
    [showLoading],
  );

  const renderLoading = (): ReactElement | null => {
    if (showLoading) {
      return (
        <tr className="Table__Row--sortable-ignored">
          <td colSpan={columns.length + 2}>
            <div
              className="loading-text Table__Loading"
              role="status"
              ref={loadingRef}
            >
              <Spinner
                role="status"
                animation="border"
                as="span"
              />
              Loading...
            </div>
          </td>
        </tr>
      );
    }

    return null;
  };

  return (
    <Scrollbars
      className={clsx('Table', className)}
      data-testid="Table"
      style={{ ...style, overflow: 'visible', height: 'auto' }}
      renderView={({ style: viewStyle, ...viewProps }) => (
        <div
          {...viewProps}
          style={{ ...viewStyle, position: 'static' }}
        />
      )}
      renderTrackHorizontal={({ style: trackStyle, ...trackProps }) => (
        <div
          {...trackProps}
          style={{ ...trackStyle, position: 'sticky', height: 8 }}
          className="Table__horizontal-scrolltrack"
        />
      )}
      renderThumbHorizontal={(thumbProps) => (
        <div
          {...thumbProps}
          style={{ ...thumbProps.style }}
          className="Table__vertical-scrollthumb"
        />
      )}
      renderTrackVertical={({ style: trackStyle, ...trackProps }) => (
        <div
          {...trackProps}
          style={{ ...trackStyle, width: 8 }}
          className="Table__vertical-scrolltrack"
        />
      )}
      renderThumbVertical={(thumbProps) => (
        <div
          {...thumbProps}
          style={{ ...thumbProps.style }}
          className="Table__vertical-scrollthumb"
        />
      )}
      ref={(refProps) => {
        containerRef.current = refProps?.container ?? null;
      }}
      {...centeringContainerProps}
    >
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {enableCheck && (
                <th className="check">
                  <input
                    type="checkbox"
                    checked={
                      rows.length > 0 && selectedRowIds?.length === rows.length
                    }
                    onChange={onHeaderChecked}
                    className="form-check"
                  />
                </th>
              )}
              {headerGroup.headers.map(
                (column: ColumnInstance<any> & { sortable?: boolean }) => (
                  <th
                    {...column.getHeaderProps()}
                    className={sortColumnClass(column)}
                    onClick={() => sortableClick(column)}
                  >
                    <span>{column.render('Header')}</span>
                  </th>
                ),
              )}
              {selectButton && (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <th className="select" />
              )}
              {renderActionsHeader()}
            </tr>
          ))}
        </thead>
        <TableBody
          draggableRows={draggableRows}
          data={data}
          updateData={updateData}
          {...getTableBodyProps()}
        >
          {renderLoading()}
          {renderNoResults()}
          {rows.map((row, i) => {
            prepareRow(row);
            const rowItem = row.original;
            const rowItemId = rowItem[rowIDAccessor];
            return (
              <tr
                {...row.getRowProps()}
                className={rowSelected(row) ? 'selected' : ''}
              >
                {enableCheck && (
                  <td className="cell--select">
                    <input
                      type="checkbox"
                      checked={rowSelected(row)}
                      onChange={(e) => {
                        onRowChecked(e, rowItemId);
                      }}
                    />
                  </td>
                )}
                {row.cells.map((cell) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <td
                    {...cell.getCellProps()}
                    className={cell.column.className}
                    style={onRowClick ? { cursor: 'pointer' } : {}}
                    onClick={() => onRowClick?.call(undefined, rowItemId, rowItem)}
                    title={cell.column.showToolTip ? cell.value : ''}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}

                {selectButton && (
                  <td style={{ textAlign: 'center' }}>
                    <Button
                      onClick={() => {
                        onRowClick?.call(undefined, rowItemId, rowItem)
                      }}
                    >
                      {selectButton}
                    </Button>
                  </td>
                )}
                {renderActionsCell(row)}
              </tr>
            );
          })}
        </TableBody>
      </table>
    </Scrollbars>
  );
}

export default Table;
