/* eslint-disable */
import React, { forwardRef, useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as PasswordVisible } from 'styles/images/password-visible.svg';
import { ReactComponent as PasswordInvisible } from 'styles/images/password-invisible.svg';
import Input, { InputProps } from './Input';

export type PasswordInputProps = Omit<InputProps, 'type'>

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({
    className, ...props
  }, ref) => {
    const [type, setType] = useState<'password'|'text'>('password');

    const InputIcon = React.memo(({ className: _className }: { className: string }) => (type === 'text'
      ? (
        <PasswordVisible
          className={_className}
          onClick={() => {
            setType('password');
          }}
        />
      )
      : (
        <PasswordInvisible
          className={_className}
          onClick={() => {
            setType('text');
          }}
        />
      )));

    return (
      <Input
        className={clsx('PasswordInput', className)}
        type={type}
        icon={InputIcon}
        ref={ref}
        {...props}
      />
    )
  });

export default PasswordInput;
