import React from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentAccountId } from 'reducers/UserInfo';
import { useUserAccounts } from './useUserAccounts';

export const useCurrentAccount = () => {
  const currentAccountId = useSelector(selectCurrentAccountId);
  const { data: userAccounts } = useUserAccounts(
    {
      revalidateOnMount: false,
    },
  );

  const currentAccount = React.useMemo(() => {
    if (!userAccounts || !currentAccountId) {
      return null;
    }

    return userAccounts.find((item) => item.Id === currentAccountId) ?? null
  }, [userAccounts, currentAccountId]);

  return currentAccount;
}
