import React, { useState, ReactElement } from 'react';
import useSWRMutation from 'swr/mutation';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as UserInfoApi from 'api/UserInfoAPI';
import { useHasRoles } from 'hooks/useHasRoles';
import Input from 'components/Inputs/Input';
import { updateAccountCommunityNameSuccess } from 'reducers/UserInfo';

interface CommunityNameWarningModalProps {
  showModal: boolean
  onHide: () => void
  onSave: () => void
  disableSavingButton: boolean
  entityName?: string
  setNameButtonLabel?: string
  withoutSetNameButtonLabel?: string
}

const CommunityNameWarningModal: React.FC<CommunityNameWarningModalProps> = ({
  showModal, onHide, onSave, disableSavingButton, entityName = 'template', setNameButtonLabel = 'Set name and Save', withoutSetNameButtonLabel = 'Save without setting',
}) => {
  const dispatch = useDispatch();
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const canUpdateCommunityName = isAdmin || isSuper;
  const [communityName, setCommunityName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const updateAccountCommunityName = async (url, { arg }: { arg: string }) => {
    await UserInfoApi.updateAccountCommunityName(arg);
  }

  const { trigger } = useSWRMutation('userinfo', updateAccountCommunityName, {
    revalidate: true,
    populateCache: false,
    onSuccess: () => {
      dispatch(updateAccountCommunityNameSuccess(communityName));
    },
  });

  const handleCommunityNameChange = (event) => {
    setErrorMsg('');
    setCommunityName(event.target.value);
  };

  const handleHide = () => {
    setErrorMsg('');
    setCommunityName('');
    onHide();
  }

  const handleSaveAndSetName = () => {
    const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
    if (regex.test(communityName)) {
      setErrorMsg('Community Name can not include emojis');
      return;
    }

    setErrorMsg('');
    trigger(communityName);
    onSave();
  }

  const renderWarningMessageAndInput = (): ReactElement => {
    if (canUpdateCommunityName) {
      return (
        <>
          <p>
            This
            {' '}
            {entityName}
            {' '}
            is using the CommunityName merge field which has not been set.
            <br />
            Set the CommunityName and continue saving
          </p>
          <br />
          <div className="form-group" style={{ textAlign: 'left' }}>
            <Input
              type="text"
              id="communityName"
              name="communityName"
              maxLength={100}
              placeholder="Community Name"
              onChange={handleCommunityNameChange}
              value={communityName}
              errors={errorMsg ?? ''}
            />
          </div>
        </>
      )
    }
    return (
      <p>
        This
        {' '}
        {entityName}
        {' '}
        is using the CommunityName merge field.
        <br />
        This value needs to be set in Account Settings.
        <br />
        Do you want to continue saving?
      </p>
    )
  };

  return (
    <Modal
      show={showModal}
      onHide={onHide}
      className="DiscardChangeModal"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header />
      <Modal.Body>
        <FontAwesomeIcon
          icon="exclamation-circle"
          size="4x"
          color="#FFAE42"
        />

        <div className="message">
          <h4>Are you sure?</h4>
          {renderWarningMessageAndInput()}
        </div>
        <button
          className="btn btn-block btn-primary"
          disabled={disableSavingButton || !communityName.trim()}
          onClick={handleSaveAndSetName}
          type="button"
        >
          {setNameButtonLabel}
        </button>
        <button
          className="btn btn-block btn-primary"
          disabled={disableSavingButton}
          onClick={onSave}
          type="button"
        >
          {withoutSetNameButtonLabel}
        </button>
        <button
          className="btn btn-block btn-secondary"
          onClick={handleHide}
          type="button"
        >
          Cancel
        </button>
      </Modal.Body>
    </Modal>
  )
}

export default CommunityNameWarningModal;
