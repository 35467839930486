import React, { ReactElement, useCallback, useEffect } from 'react';

import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { ITemplate } from 'types/IEvent'
import { getTemplates, selectTemplate } from '../../reducers/Templates'
import Table from '../../components/Table'

function CalendarEventIndexPage(): ReactElement {
  const dispatch = useDispatch()
  const history = useHistory()
  const dispatchTemplates = useCallback((): void => {
    dispatch(getTemplates())
  }, [dispatch])

  useEffect(() => {
    dispatchTemplates()
  }, [dispatchTemplates])

  const templateData: ITemplate[] = useSelector(selectTemplate())
  const columns = [
    { Header: 'ID', accessor: 'ID', sortable: true },
    { Header: 'Name', accessor: 'Name', sortable: true },
    { Header: 'Description', accessor: 'Description', sortable: true },
  ]
  const rowActionItems = (entityId: string): ReactElement => (
    <>
      <li><Link to={`/LibraryContent/${entityId}`}>View</Link></li>
      <li><Link to={`/LibraryContent/${entityId}/edit`}>Edit</Link></li>
    </>
  )
  return (
    <div>
      <Helmet>
        <title>Create New Message</title>
      </Helmet>
      <div className="header-bar">
        <h1>Select Message Template</h1>
      </div>
      <div>
        <Table
          columns={columns}
          data={templateData}
          rowIDAccessor="Id"
          rowActionItems={rowActionItems}
          onRowClick={(id: string) => {
            history.push(`/CalendarEvent/${id}/edit`)
          }}
        />
      </div>
    </div>
  )
}
export default CalendarEventIndexPage
